import { ICouponHistory } from 'interfaces/coupon.interface';
import moment from 'moment';
import React from 'react';
import { getOrderStatus } from 'utils/utils';
import { DateFormat } from 'constants/enum';

interface Props {
  data: ICouponHistory;
  onViewDetail: (code: string) => void;
}

export const OrderHistoryItem: React.FC<Props> = (props) => {
  const { data, onViewDetail } = props;

  const {
    billingAddress,
    createdAt,
    customerName,
    customerGender,
    customerPhone,
    productName,
    sku,
    status,
    couponCode,
  } = data?.attributes;

  return (
    <div className="order-history-item">
      <div className="item__prefix">
        <span className="item__symbol">#</span>
        <span className="item__prefix-code">{couponCode?.toUpperCase()}</span>
      </div>

      <div className="item__order-info">
        <div className="item_order-info-wrap">
          <div className="item__order-code">Đơn hàng #{data?.id?.toUpperCase()} - </div>
          <div className="item__order-name">
            Mua “{productName}” <span>(SKU: {sku})</span>
          </div>
        </div>

        <div className="item__view-order-detail" onClick={() => onViewDetail(data?.id)}>
          <span>Xem chi tiết</span>
        </div>
      </div>

      <div className="item__customer-info">
        <div className="customer-info__name">
          {customerGender === 'male' ? 'Anh' : 'Chị'} {customerName}
        </div>
        <div className="customer-info__phone">Phone: {customerPhone}</div>
        <div className="customer-info__address">
          {billingAddress?.address &&
            billingAddress?.ward &&
            billingAddress?.district &&
            billingAddress?.city &&
            ` Địa chỉ: ${billingAddress.address}, ${billingAddress.ward}, ${billingAddress.district}, ${billingAddress.city}`}
        </div>
      </div>

      <div className="item__process-info">
        <div className="order-created">
          Đơn tạo lúc {moment(createdAt, DateFormat.serverSideWithTime).format('HH:mm DD-MM-YYYY')}{' '}
        </div>
        <div className={`process-info__label--${status}`}>{getOrderStatus(status)}</div>
      </div>
    </div>
  );
};
