import { Col, Row, Table } from 'antd';

// Components
import { clone, isEmpty, uniqBy, filter } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_GET_ATTRIBUTE_OPTIONS } from 'constants/api/attribute.api';
import { API_GET_SIZES } from 'constants/api/size.api';
import { IAttributeOption, IAttrAttributeOption } from 'interfaces/attr-option.interface';
import { IAttribute } from 'interfaces/attribute.interface';
import { IProduct, IProductAttribute, ISuperAttribute } from 'interfaces/product.interface';
import { ISystemColor } from 'interfaces/system-color.interface';
import { AppState } from 'redux/store';
import * as api from 'services/api.service';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';

import { BoxColors } from './BoxColors/BoxColors';

interface AddVariantProductsInterface {
  superAttributes: IAttribute[];
  superAttributesValues: ISuperAttribute[];
  onUpdateSuperAttributesValue?: (superAttributesValue: any) => void;
  variantProducts: IProduct[];
  isCreateNewChildProducts?: boolean;
}

interface DuplicateVariantProducts extends IProduct {
  superValues: ISuperAttribute[];
}

export interface DataAttributesInterface {
  id: string;
  title: string;
  attributes: IAttrAttributeOption[] & { label?: string; disabled?: boolean };
}

const productState = (state: AppState) => state.productReducers;

export const AddVariantProducts: FC<AddVariantProductsInterface> = ({
  superAttributes,
  superAttributesValues,
  onUpdateSuperAttributesValue,
  variantProducts,
  isCreateNewChildProducts = false,
}) => {
  const { colors } = useSelector(productState);
  const [superAttributeData, setSuperAttributeData] = useState<IAttribute[]>([]);
  const [dataAttributes, setDataAttributes] = useState<DataAttributesInterface[]>([]);
  const [dataColorAttributes, setDataColorAttributes] = useState<DataAttributesInterface[]>([]);
  const [duplicateVariantProducts, setDuplicateVariantProducts] = useState<IProduct[]>([]);
  const [attributeOptions, setAttributeOptions] = useState<IAttributeOption[]>([]);

  const getDataColorAttributes = useCallback(
    (dataColor) => {
      const clonedDataColorAttr = [];
      clonedDataColorAttr.push({
        id: dataColor.id,
        title: dataColor['attributes']['adminLabel'],
        attributes: colors.map((color: ISystemColor) => ({
          name: color.attributes.name,
          value: parseInt(color.id),
          label: color.attributes.name,
          disabled: false,
        })) as any,
      });
      setDataColorAttributes(clonedDataColorAttr);
    },
    [superAttributes],
  );
  const clonedDataAttrValues = [];
  const clonedAttributeOptions = [];
  const getDataForAttributes = useCallback(
    async (attribute) => {
      let url = '';
      if (attribute?.attributes?.type === 'size_group') {
        const sizeGroupFilter = `&sizeGroupId=${attribute.attributes.defaultValue}`;
        url = `${API_GET_SIZES}?perPage=all${sizeGroupFilter}`;
      } else {
        url = API_GET_ATTRIBUTE_OPTIONS.replace('{id}', attribute.id);
      }
      const data = await api
        .get({
          endpoint: url,
        })
        .then((res) => res?.data);
      clonedAttributeOptions.push(...data);
      clonedDataAttrValues.push({
        id: attribute.id,
        title: attribute.attributes.adminLabel,
        attributes: data.map((setectData: IAttributeOption) => ({
          name:
            attribute?.attributes?.type === 'size_group'
              ? setectData.attributes.title
              : setectData.attributes.name,
          value: parseInt(setectData.id),
          label:
            attribute?.attributes?.type === 'size_group'
              ? setectData.attributes.title
              : setectData.attributes.name,
          disabled: false,
        })),
      });
      setDataAttributes(uniqBy(clonedDataAttrValues, 'id'));
      setAttributeOptions(uniqBy(clonedAttributeOptions, 'id'));
    },
    [superAttributes],
  );

  useEffect(() => {
    setSuperAttributeData(uniqBy(superAttributes, 'id'));

    // get data for attributes color
    const superAttributesColorValues = filter(uniqBy(superAttributes, 'id'), function (o) {
      return o['attributes']['type'] == 'color';
    });

    if (!isEmpty(superAttributesColorValues)) {
      getDataColorAttributes(superAttributesColorValues[0]);
    }

    // get data for attributes except color
    const dataAttributesValues = filter(uniqBy(superAttributes, 'id'), function (o) {
      return o['attributes']['type'] != 'color' && !o.attributes.isHiddenOnAdmin;
    });

    if (!isEmpty(dataAttributesValues)) {
      dataAttributesValues.map((item) => {
        getDataForAttributes(item);
      });
    }

    return () => {
      setSuperAttributeData(uniqBy(superAttributes, 'id').map((item) => ({ ...item })));
      onUpdateSuperAttributesValue && onUpdateSuperAttributesValue([]);
    };
  }, [onUpdateSuperAttributesValue, superAttributes]);

  const handleUpdateSuperAttributes = useCallback(
    (id: string) => (attrOptions: number[]) => {
      const cloneAttrOptions = clone(attrOptions);
      cloneAttrOptions.forEach((attr) => {
        attr['attributeId'] = parseInt(id);
        attr['attributeOptionId'] = attr['id'];
      });
      onUpdateSuperAttributesValue && onUpdateSuperAttributesValue(cloneAttrOptions);
      const clonedSuperAttributes = clone(uniqBy(superAttributes, 'id'));
      const superValues = clonedSuperAttributes
        .map((item) => {
          if (item.id === id) {
            return {
              attributeId: parseInt(id),
              attributeOptionIds: attrOptions,
            };
          }
          return superAttributesValues.find((attr) => attr.attributeId.toString() === item.id);
        })
        .filter((item) => item && !isEmpty(item.attributeOptionIds));

      if (!isCreateNewChildProducts) {
        const clonedVariantProducts: DuplicateVariantProducts[] = variantProducts.map((item) => ({
          ...item,
          superValues: item.attributes.attributeValues.map((attrValue) => ({
            attributeId: attrValue.attributeId,
            attributeOptionIds: [parseInt(attrValue.value)],
          })),
        }));
        const dumbProducts: IProduct[] = [];

        superValues.forEach((superValue) => {
          clonedVariantProducts.forEach((product) => {
            if (
              product.attributes.attributeValues.some(
                (item) =>
                  item.attributeId === superValue.attributeId &&
                  parseInt(item.value) === superValue.attributeOptionIds[0],
              )
            ) {
              if (
                JSON.stringify(product.superValues).includes(
                  JSON.stringify(superValues).slice(1, -1),
                )
              ) {
                dumbProducts.push(product);
              }
            }
          });
        });
        setDuplicateVariantProducts(uniqBy(dumbProducts, 'id'));
      }
    },
    [
      superAttributeData,
      onUpdateSuperAttributesValue,
      superAttributesValues,
      isCreateNewChildProducts,
      variantProducts,
    ],
  );

  const renderExtraColumns = () => {
    return superAttributes.map((item) => {
      switch (item.attributes.type) {
        case 'color':
          return {
            title: item.attributes.adminLabel,
            dataIndex: 'attributes',
            render: (attr: IProductAttribute) => {
              const color = colors.find(
                (c) => c.id === attr.attributeValues.find((value) => value.value === c.id)?.value,
              );
              return color?.attributes?.name;
            },
          };
        case 'select':
          return {
            title: item.attributes.adminLabel,
            dataIndex: 'attributes',
            render: (attr: IProductAttribute) => {
              const data = attributeOptions.find(
                (c) => c.id === attr.attributeValues.find((value) => value.value === c.id)?.value,
              );
              return data?.attributes?.name;
            },
          };
        default:
          return {};
      }
    });
  };

  const duplicateProductsColumns = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute) => (
        <>
          <SanitizeHtml
            rawHtml={attr.name}
            className="existed-product-name-overflow"
            title={attr.name}
          />
          <div>{`SKU: ${attr.sku}`}</div>
        </>
      ),
    },
    ...renderExtraColumns(),
  ];

  return (
    <Row gutter={24}>
      <Col span={8}>
        <div>
          <Row gutter={16}>
            <Col span={24}>
              {!isCreateNewChildProducts && !isEmpty(duplicateVariantProducts) && (
                <>
                  <h5 className="mb-3 mt-3">Những sản phẩm đã tồn tại có cùng thuộc tính</h5>
                  <Table
                    columns={duplicateProductsColumns}
                    dataSource={duplicateVariantProducts}
                    pagination={false}
                    rowKey="id"
                  />
                </>
              )}
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24}>
        {dataColorAttributes.map((item) => (
          <BoxColors
            key={'attr-color-' + item.id}
            attributeOptions={item.attributes}
            attributeChildOptions={dataAttributes}
            onUpdateAttributeOptions={handleUpdateSuperAttributes(item.id)}
          />
        ))}
      </Col>
    </Row>
  );
};
