import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Button, Form, Col, Row, FormInstance } from 'antd';

import { IPageData } from 'interfaces/page-data.interface';
import { IBreadcrumb } from 'interfaces/page-data.interface';
import { FC, ReactNode, useCallback, useEffect, useState, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { updatePageData } from 'redux/pages/actions';
import { AppState } from 'redux/store';
import { setIsEditing } from 'redux/uiAction/actions';
import { onSetPageData } from 'services/pageData.service';
import { PageTitle } from 'ui/components/MaiNguyen/PageTitle';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import useKeyboardShortcut from 'use-keyboard-shortcut';

import './LayoutDetail.scss';

interface LayoutDetailInterface {
  pageTitle: string;
  breadcrumbs: IBreadcrumb[];
  pageClassName?: string;
  isAddNew?: boolean;
  children: ReactNode;
  formHook?: FormInstance<any>;
  handleValuesChange?: (changedValues: any, values: any) => void;
  onFieldsChange?: (changedFields: any[], allFields: any[]) => void;
  handleDelete?: () => void;
  handleSave?: () => void;
  saveLoading?: boolean;
  deleteLoading?: boolean;
  handleClickAddButton?: () => void;
  wrapperCard?: boolean;
  handleSaveNoEnter?: () => void;
  handleCancel?: () => void;
  showSaveButton?: boolean;
}

export const EditableContext = createContext(null);

const uiActionStates = (state: AppState) => state.uiActions;

const LayoutDetail: FC<LayoutDetailInterface> = ({
  pageTitle,
  breadcrumbs,
  pageClassName,
  isAddNew,
  children,
  formHook,
  handleValuesChange,
  onFieldsChange,
  handleDelete = null,
  handleSave = null,
  deleteLoading = false,
  handleClickAddButton = null,
  wrapperCard = true,
  handleSaveNoEnter = null,
  showSaveButton = true,
  handleCancel = null,
}) => {
  const { isEditing } = useSelector(uiActionStates);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSaveItem = useCallback(async () => {
    if (handleSaveNoEnter) {
      setLoading(true);
      await handleSaveNoEnter();
      setLoading(false);
    } else {
      setLoading(true);
      handleSave && (await handleSave());
      setLoading(false);
    }
  }, [handleSaveNoEnter, handleSave]);

  const onClickAddButton = useCallback(() => {
    dispatch(setIsEditing(false));
    handleClickAddButton && handleClickAddButton();
  }, [handleClickAddButton]);

  const pageData: IPageData = {
    title: (
      <PageTitle
        title={pageTitle}
        showButton={false}
        buttonText="Tạo mới"
        buttonIcon={<PlusOutlined />}
        onClick={() => handleSaveItem()}
        showSaveButton={showSaveButton}
        loading={loading}
        handleCancel={handleCancel}
      />
    ),
    breadcrumbs,
  };

  useEffect(() => {
    dispatch(onSetPageData(pageData));
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
  }, [isAddNew, isEditing, loading, handleSaveItem, onClickAddButton, handleCancel, pageTitle]);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (isEditing) {
        return 'Changes you made may not be saved.';
      }
    };
  }, [isEditing]);

  useEffect(() => {
    if (isAddNew) {
      dispatch(setIsEditing(false));
    } else {
      dispatch(setIsEditing(true));
    }
    return () => {
      dispatch(setIsEditing(false));
    };
  }, [isAddNew]);

  const renderDetailPage = (content) => {
    return wrapperCard ? (
      <Card className={`${pageClassName} detail-page`}>{content}</Card>
    ) : (
      <div className={`${pageClassName} detail-page`}>{content}</div>
    );
  };

  useKeyboardShortcut(
    ['Meta', 'S'],
    async () => {
      setLoading(true);
      await handleSave();
      setLoading(false);
    },
    { overrideSystem: true, ignoreInputFields: true },
  );

  useKeyboardShortcut(
    ['Control', 'S'],
    async () => {
      setLoading(true);
      await handleSave();
      setLoading(false);
    },
    { overrideSystem: true, ignoreInputFields: true },
  );

  const onSave = async () => {
    setLoading(true);
    await handleSave();
    setLoading(false);
  };

  return (
    <>
      {formHook &&
        renderDetailPage(
          <Form
            layout="vertical"
            form={formHook}
            onValuesChange={handleValuesChange}
            onFinish={onSave}
            onFieldsChange={onFieldsChange}
          >
            <EditableContext.Provider value={formHook}>
              <>
                {wrapperCard && (
                  <Row gutter={24}>
                    <Col span={24} className="mb-5 button-wrapper">
                      <ThemeButton
                        htmlType={handleSaveNoEnter ? 'button' : 'submit'}
                        loading={loading}
                        onClick={handleSaveNoEnter ? onSave : null}
                      >
                        Lưu
                      </ThemeButton>
                      &nbsp; &nbsp;
                      {handleCancel && <Button onClick={handleCancel}>Cancel</Button>}
                    </Col>
                  </Row>
                )}
                {children}
                {wrapperCard && (
                  <Row gutter={24}>
                    <Col span={24} className="button-wrapper">
                      {!isAddNew && handleDelete && (
                        <Button
                          onClick={handleDelete}
                          loading={deleteLoading}
                          icon={<DeleteOutlined />}
                        >
                          {!deleteLoading && 'Xóa'}
                        </Button>
                      )}
                      &nbsp; &nbsp;
                      <ThemeButton
                        htmlType={handleSaveNoEnter ? 'button' : 'submit'}
                        loading={loading}
                        onClick={handleSaveNoEnter ? onSave : null}
                      >
                        Lưu
                      </ThemeButton>
                      &nbsp; &nbsp;
                      {handleCancel && <Button onClick={handleCancel}>Cancel</Button>}
                    </Col>
                  </Row>
                )}
              </>
            </EditableContext.Provider>
          </Form>,
        )}
      {!formHook && renderDetailPage(<div>{children}</div>)}
      <Prompt when={isEditing} message="Những thay đổi sẽ bị mất." />
    </>
  );
};

export default LayoutDetail;
