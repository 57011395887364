import { Button, Col, Input, Row } from 'antd';
import React, { ChangeEvent, useState } from 'react';

type FilterBrandsProps = {
  onFilter?: (val) => void;
};

const FilterBrands: React.FC<FilterBrandsProps> = (props) => {
  const { onFilter } = props;

  const [searchVal, setSearchVal] = useState('');

  // on change input
  const changeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value);
  };

  // submit filter
  const submitFilter = () => {
    onFilter && onFilter(searchVal);
  };

  // clear filter
  const clearFilter = () => {
    setSearchVal('');
    onFilter && onFilter('');
  };

  return (
    <Row gutter={16}>
      <Col span={18}>
        <Input placeholder="Nhập tên thương hiệu" onChange={changeInput} value={searchVal} />
      </Col>
      <Col span={6} className="align-items-center justify-content-end" style={{ display: 'flex' }}>
        <Button className="secondary-button" onClick={submitFilter}>
          Filter
        </Button>
        <Button className="ml-2" onClick={clearFilter}>
          Clear
        </Button>
      </Col>
    </Row>
  );
};

export default FilterBrands;
