import { DeleteOutlined } from '@ant-design/icons';
import { Form, Select, Spin, message, Button } from 'antd';
import { clone, debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { API_GET_RELATED_RIDER_MODELS } from 'constants/api/product.api';
import { API_GET_RIDER_MODELS } from 'constants/api/riderType.api';
import { IRiderModels, IRiderModelsAttribute } from 'interfaces/riderType';
import { RiderModelsModel } from 'interfaces/riderTypes.interface';
import BaseProductsTable from 'pages/Products/ProductDetail/components/BaseProductsTable/BaseProductsTable';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { showErrorMsg } from 'utils/utils';

const newRiderModels = new RiderModelsModel({});

interface ProductVehicleModelProps {
  handleUpdateSelectedRiderModel?: (riderModel: IRiderModels[]) => void;
}

export const ProductVehicleModel: FC<ProductVehicleModelProps> = ({
  handleUpdateSelectedRiderModel,
}) => {
  const { id: productId } = useParams<{ id: string }>();
  const isAddNew = productId === 'new';

  const [riderModel, setRiderModel] = useState<IRiderModelsAttribute>(newRiderModels);
  const [riderModels, setRiderModels] = useState<IRiderModels[]>([]);
  const [selectedRiderModelId, setSelectedRiderModelId] = useState<number>(null);
  const [relaRiderModels, setRelaRiderModels] = useState<IRiderModels[]>([]);
  const [loadRiderModels, setLoadRiderModels] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAddNew) {
      api
        .get({ endpoint: `${API_GET_RELATED_RIDER_MODELS.replace('{id}', productId)}?perPage=all` })
        .then((res) => {
          const currentRiderModel = new RiderModelsModel({
            ...res?.data['attributes'],
          });
          setRelaRiderModels(res?.data);
          setRiderModel(currentRiderModel);
          form.setFieldsValue(currentRiderModel);
        })
        .catch((err) => {
          showErrorMsg(err);
        });
    }
  }, []);

  const handleSearchRiderModels = useMemo(() => {
    const fetchRiderModels = async (value: string) => {
      setLoadRiderModels(true);
      if (value) {
        await api
          .get({
            endpoint: `${API_GET_RIDER_MODELS}?perPage=all&name=${value}`,
          })
          .then((res) => {
            setLoadRiderModels(false);
            setRiderModels(res?.data);
          })
          .catch((err) => {
            setLoadRiderModels(false);
            setRiderModels([]);
            showErrorMsg(err);
          });
      } else {
        setLoadRiderModels(false);
        setRiderModels([]);
      }
    };
    return debounce(fetchRiderModels, 800);
  }, []);

  const handleSelectRiderModel = useCallback(
    (id: number) => {
      dispatch(setIsEditing(true));
      const currentRiderModel = new RiderModelsModel(riderModel);
      const clonedRelaRiderModels = clone(relaRiderModels);

      if (!clonedRelaRiderModels.find((item) => parseInt(item.id) === id)) {
        clonedRelaRiderModels.push(riderModels.find((item) => parseInt(item.id) === id));
        setSelectedRiderModelId(id);
        setRelaRiderModels(clonedRelaRiderModels);
        setRiderModel(currentRiderModel);
        handleUpdateSelectedRiderModel(clonedRelaRiderModels);
      } else {
        message.error('Dòng xe đã tồn tại trong danh sách', 3);
      }
    },
    [riderModel, riderModels, relaRiderModels],
  );

  const handleDeleteRiderModel = useCallback(
    (rowData: IRiderModels) => () => {
      dispatch(setIsEditing(true));
      const currentRiderModel = new RiderModelsModel(riderModel);
      const clonedRelaRiderModels = clone(relaRiderModels);
      const relaRiderModelIndex = clonedRelaRiderModels.findIndex((item) => item.id === rowData.id);
      if (relaRiderModelIndex > -1) {
        clonedRelaRiderModels.splice(relaRiderModelIndex, 1);
      }
      setRelaRiderModels(clonedRelaRiderModels);
      setRiderModel(currentRiderModel);
      handleUpdateSelectedRiderModel(clonedRelaRiderModels);
    },
    [riderModel, relaRiderModels],
  );

  const riderModelsColumns = [
    {
      title: 'STT',
      dataIndex: 'key',
      width: 70,
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tên Dòng xe',
      dataIndex: 'attributes',
      render: (attr: IRiderModelsAttribute) => <SanitizeHtml rawHtml={attr.name} />,
    },
    {
      title: 'Thao tác',
      width: 100,
      render: (row: IRiderModels) => (
        <Button title="Xóa" icon={<DeleteOutlined />} onClick={handleDeleteRiderModel(row)} />
      ),
    },
  ];

  const checkDisabledOption = useCallback(
    (id) => {
      const relaRiderModelsIndex = relaRiderModels.findIndex((item) => item.id === id);
      if (relaRiderModelsIndex > -1) return true;
    },
    [relaRiderModels],
  );

  return (
    <>
      <Form.Item>
        <Select
          size="large"
          showSearch
          optionFilterProp="children"
          allowClear
          notFoundContent={loadRiderModels ? <Spin size="small" /> : null}
          onSearch={handleSearchRiderModels}
          placeholder="Nhập tên để tìm dòng xe"
          onSelect={handleSelectRiderModel}
          value={selectedRiderModelId}
        >
          {riderModels.map((item) => (
            <Select.Option
              key={item.id}
              value={parseInt(item.id)}
              disabled={checkDisabledOption(item.id)}
            >
              {item?.attributes?.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <BaseProductsTable dataList={relaRiderModels} columns={riderModelsColumns} sortable={false} />
    </>
  );
};
