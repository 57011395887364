import { ICoupon } from 'interfaces/coupon.interface';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPageData } from 'services/pageData.service';
import useSWR from 'swr';
import { API_COUPON_VOUCHER } from 'constants/api/coupons.api';

export const useFetchCouponVoucher = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isValidating, mutate } = useSWR(
    `${API_COUPON_VOUCHER}?include=categories`,
    (url: string) => getPageData(url, dispatch),
  );

  useEffect(() => {
    if (data) {
      mutate(null, { revalidate: true });
    }
  }, []);

  return {
    isFetching: isLoading || isValidating,
    refetch: mutate,
    data: data?.data as ICoupon,
  };
};
