import { Card } from 'antd';
import { Star } from 'components/Svg/Star';
import { IProduct } from 'interfaces/product.interface';
import isEmpty from 'lodash/isEmpty';
import { isCurrentDateGreaterThan } from 'utils/dateHelpers';
import {
  formatNumber,
  getBasePrice,
  getDiscountPercent,
  getFinalSellPrice,
  getStickerByStatus,
} from 'utils/productHelpers';
import './index.styles.scss';

type ProductItemProps = {
  data: IProduct;
  onClick?: () => void;
};

const ProductItem: React.FC<ProductItemProps> = (props) => {
  const { data, onClick } = props;

  const productAttrs = data?.attributes;

  const {
    name,
    mainImage,
    sticker,
    preorder,
    lifestyleImage,
    price,
    salePrice,
    isSecondhand,
    promotion,
  } = productAttrs || {};

  const isPreOrder = !isEmpty(preorder) && !isCurrentDateGreaterThan(preorder?.delivery_date);
  const { basePrice, oldPrice } = getBasePrice(salePrice, price, promotion?.discountPrice);
  const { finalSellPrice, originPrice } = getFinalSellPrice(price, salePrice, promotion);
  const statusSticker = getStickerByStatus(
    sticker?.customize,
    sticker?.status?.name,
    sticker?.status?.code,
    isPreOrder,
  );
  const discountPercent = getDiscountPercent(productAttrs);

  return (
    <div className="col-md-6 col-lg-4 product-item" onClick={onClick}>
      <Card className="relative">
        {statusSticker?.title && (
          <div className="status">
            <div
              style={{
                background: statusSticker.background,
                border: `1px solid ${statusSticker.border}`,
              }}
              className="title flex items-center justify-center"
            >
              <span className="txt" style={{ color: statusSticker.text }}>
                {statusSticker.title}
              </span>
            </div>
          </div>
        )}

        <div
          className={`relative product-item__image ${
            !isEmpty(lifestyleImage) ? 'product-item__image--multiple' : ''
          }`}
        >
          <div>
            <div className="main-image flex items-center justify-center">
              <img className="object-cover" src={mainImage?.['256x256']} title={name} alt={name} />
            </div>
            {!isEmpty(lifestyleImage) && (
              <div className="second-image flex items-center justify-center">
                <img src={lifestyleImage?.['256x256']} title={name} alt={name} />
              </div>
            )}
          </div>
        </div>

        <div className="product-content">
          <div className="product-item__name">{name}</div>

          <div className="product-price flex">
            <div className="cur-price flex items-center justify-center gap-2">
              <div className="price">{finalSellPrice > 0 && formatNumber(finalSellPrice)}</div>
              {discountPercent > 0 && (
                <div className="discount-percent">
                  <div className="txt">{`-${discountPercent}%`}</div>
                </div>
              )}
            </div>

            <div className="text-center old-price">
              {!isSecondhand && oldPrice !== basePrice && originPrice > 0 && (
                <span>{formatNumber(originPrice)}</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 rating">
          <div className="flex items-center gap-1">
            {Array.from({ length: 5 }, (_, i: number) => (
              <div key={`${i}`} className="star-item">
                <Star />
              </div>
            ))}
          </div>
          <div className="count">(0)</div>
        </div>
      </Card>
    </div>
  );
};

export default ProductItem;
