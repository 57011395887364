import { Form, message } from 'antd';
import { IBlockOfEventPage } from 'interfaces/event-page.interface';
import { useCreateBlockTemplate, useUpdateBlockTemplate } from 'pages/EventPageDetail/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsEditing } from 'redux/uiAction/actions';
import { useSWRConfig } from 'swr';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { API_EVENT_PAGE_BY_ID } from 'constants/api/event-page.api';
import { EventPageBlockTemplates } from 'constants/enum';
import { getPositionByTemplate } from './helpers';
import { CommentTemplate } from './Templates/Comments';
import { ContactUsTemplate } from './Templates/ContactUs';
import { CountdownTemplate } from './Templates/Countdown';
import { HeroTemplate } from './Templates/Hero';
import { ListOfProductsTemplate } from './Templates/ListOfProducts';
import { MenuTemplate } from './Templates/Menu';
import { NewsTemplate } from './Templates/News';
import { ProductDescriptionTemplate } from './Templates/ProductDescription';
import { ProductImagesTemplate } from './Templates/ProductImages';
import { ProductVideosTemplate } from './Templates/ProductVideos';

interface Props {
  isOpen: boolean;
  eventPageId: string;
  blockData: IBlockOfEventPage;
  onClose: () => void;
  template: EventPageBlockTemplates;
}

export const PresentBlockDetailModal: React.FC<Props> = (props) => {
  const { isOpen, eventPageId, blockData, template, onClose } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { mutate } = useSWRConfig();

  const { isCreating, onCreate } = useCreateBlockTemplate();
  const { isUpdating, onUpdate } = useUpdateBlockTemplate();

  const [formData, setFormData] = useState<string | null>(null);

  const parseFormData = formData ? JSON.parse(formData) : undefined;

  /**
   * This useEffect hook manages the formData state based on the isOpen flag.
   * If the modal is closed (isOpen is false) and formData is not null, it resets the formData to null.
   * If the modal is opened (isOpen is true) and formData is null, it sets the formData using blockData's attributes data or null if not available.
   *
   * @returns {void}
   */
  useEffect((): void => {
    if (!isOpen && formData) {
      setFormData(null);
    }

    if (isOpen && !formData) {
      setFormData(blockData?.attributes?.data || null);
    }
  }, [isOpen]);

  /**
   * Handle the event of saving a block for an event page.
   * Depending on the presence of blockData's ID, it either updates an existing block or creates a new block.
   * After saving, it updates the data for the event page by using the mutate function with the new block data.
   * Displays a success message if the saving process is successful, otherwise, displays an error message.
   *
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onSave = useCallback(async (): Promise<void> => {
    try {
      let res: IBlockOfEventPage = null;
      const args = {
        id: eventPageId,
        data: {
          template,
          position: getPositionByTemplate(template),
          data: formData,
          active: true,
        },
      };
      if (blockData?.id) {
        res = await onUpdate({ ...args, blockId: blockData.id });
      } else {
        res = await onCreate(args);
      }

      await mutate(
        `${API_EVENT_PAGE_BY_ID.replace('{id}', eventPageId)}?include=blocks`,
        (currentData) => ({
          ...currentData,
          included: [...(currentData?.included?.filter((b) => b.id !== res?.id) || []), res],
        }),
        {
          revalidate: false,
          rollbackOnError: true,
        },
      );

      message.success('Lưu block thành công');
    } catch (error: any) {
      const msg = error?.message || error;
      message.error(msg);
    }
  }, [eventPageId, template, formData, blockData]);

  return (
    <CustomPopup
      title="Display block"
      formHook={form}
      isOpen={isOpen}
      onCloseModal={onClose}
      isSaving={isCreating || isUpdating}
      handleSaveButton={onSave}
      handleValuesChange={() => dispatch(setIsEditing(true))}
    >
      {template === EventPageBlockTemplates.hero && (
        <HeroTemplate blockId={blockData?.id} data={parseFormData} onChange={setFormData} />
      )}
      {template === EventPageBlockTemplates.menu && (
        <MenuTemplate data={parseFormData} onChange={setFormData} />
      )}
      {template === EventPageBlockTemplates.countdown && (
        <CountdownTemplate data={parseFormData} onChange={setFormData} />
      )}
      {template === EventPageBlockTemplates.productDescription && (
        <ProductDescriptionTemplate
          blockId={blockData?.id}
          data={parseFormData}
          onChange={setFormData}
        />
      )}
      {template === EventPageBlockTemplates.listOfProducts && (
        <ListOfProductsTemplate data={parseFormData} onChange={setFormData} />
      )}
      {template === EventPageBlockTemplates.productImageSlider && (
        <ProductImagesTemplate
          blockId={blockData?.id}
          data={parseFormData}
          onChange={setFormData}
        />
      )}
      {template === EventPageBlockTemplates.productVideos && (
        <ProductVideosTemplate
          blockId={blockData?.id}
          data={parseFormData}
          onChange={setFormData}
        />
      )}
      {template === EventPageBlockTemplates.comments && (
        <CommentTemplate data={parseFormData} onChange={setFormData} />
      )}
      {template === EventPageBlockTemplates.news && (
        <NewsTemplate data={parseFormData} onChange={setFormData} />
      )}
      {template === EventPageBlockTemplates.contactUs && (
        <ContactUsTemplate data={parseFormData} onChange={setFormData} />
      )}
    </CustomPopup>
  );
};
