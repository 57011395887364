import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import './components.styles.scss';

const menuList = [
  {
    title: 'Thông tin cá nhân',
    path: '/danh-sach-members/:id',
    type: 'information',
  },
  {
    title: 'Reviews',
    path: '/danh-sach-members/:id/reviews',
    type: 'review',
  },
  {
    title: 'Comments',
    path: '/danh-sach-members/:id/comments',
    type: 'comment',
  },
  {
    title: 'Đơn hàng',
    path: '/danh-sach-members/:id/orders',
    type: 'order',
  },
  {
    title: 'SP Yêu Thích',
    path: '/danh-sach-members/:id/wishlist',
    type: 'wishlist',
  },
];

type MemberDetailsMenuProps = {
  totalComment?: number;
  totalOrder?: number;
  totalReview?: number;
  totalWishlist?: number;
};

/**
 * Generate menu title
 * @param title
 * @param type
 * @param totalComment
 * @param totalOrder
 * @param totalReview
 * @returns
 */
const generateMenuTitle = (
  title: string,
  type: string,
  totalComment?: number,
  totalOrder?: number,
  totalReview?: number,
  totalWishlist?: number,
) => {
  switch (type) {
    case 'review':
      return `${title} <span>${totalReview || 0}</span>`;
    case 'comment':
      return `${title} <span>${totalComment || 0}</span>`;
    case 'order':
      return `${title} <span>${totalOrder || 0}</span>`;
    case 'wishlist':
      return `${title} <span>${totalWishlist || 0}</span>`;
    default:
      return `${title}`;
  }
};

const MemberDetailsMenu: React.FC<MemberDetailsMenuProps> = ({
  totalOrder,
  totalComment,
  totalReview,
  totalWishlist,
}) => {
  const { id: memberId } = useParams<{ id: string }>();

  return (
    <ul className="member-details-menu">
      {menuList.map((item) => (
        <li key={item.path}>
          <NavLink
            exact={true}
            to={{
              pathname: item.path.replace(':id', memberId),
            }}
          >
            <SanitizeHtml
              rawHtml={generateMenuTitle(
                item.title,
                item.type,
                totalComment,
                totalOrder,
                totalReview,
                totalWishlist,
              )}
            />
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default MemberDetailsMenu;
