import React, { useEffect, useState } from 'react';

import './index.styles.scss';

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  isViewOnly?: boolean;
}

export const Rating: React.FC<Props> = (props) => {
  const { value = 0, isViewOnly, onChange } = props;

  const [rating, setRating] = useState<number>(0);

  useEffect(() => {
    if (value && value > 0 && value !== rating) {
      setRating(value);
    }
  }, [value]);

  return (
    <div className={`rating-group ${isViewOnly ? 'view-only' : ''}`}>
      {Array.from({ length: 5 }, (_, i: number) => {
        const star = i + 1;
        const isLeftStarActive = value.toString()?.includes('.')
          ? star < rating || rating % star > 0
          : star <= rating;
        const isRightStarActive = value.toString()?.includes('.') ? star < rating : star <= rating;

        return (
          <div
            role="button"
            key={`${i}`}
            className="rating-group__item"
            onMouseOver={() => onChange && setRating(star)}
            onMouseLeave={() => {
              if (!onChange) return;

              if (value) {
                setRating(value);
                return;
              }

              setRating(0);
            }}
            onClick={() => {
              if (!onChange) return;

              setRating(star);
              onChange(star);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <g clipPath="url(#clip0_4205_6376)">
                <path
                  d="M19.9713 8.20557C19.9024 7.99339 19.719 7.83882 19.4983 7.80678L13.2982 6.90577L10.5254 1.28754C10.4267 1.0875 10.2231 0.960938 10 0.960938C9.777 0.960938 9.57329 1.0875 9.47458 1.28754L6.70162 6.90573L0.501674 7.80678C0.281011 7.83882 0.0975736 7.99339 0.0287066 8.20549C-0.0402386 8.41768 0.0172613 8.65053 0.176948 8.80616L4.66319 13.1794L3.60428 19.3545C3.56659 19.5743 3.65698 19.7964 3.83741 19.9276C4.01784 20.0586 4.25698 20.076 4.45448 19.9722L9.99997 17.0566L15.5453 19.9721C15.631 20.0172 15.7246 20.0394 15.8179 20.0394C15.9394 20.0394 16.0603 20.0017 16.1624 19.9276C16.3428 19.7964 16.4332 19.5743 16.3955 19.3545L15.3363 13.1794L19.823 8.80616C19.9827 8.65049 20.0402 8.41764 19.9713 8.20557Z"
                  fill={isLeftStarActive ? '#DB0002' : '#D9D9D9'}
                />
                <path
                  d="M19.9713 8.20557C19.9024 7.99338 19.719 7.83881 19.4983 7.80678L13.2982 6.90577L10.5254 1.28754C10.4267 1.0875 10.223 0.960938 10 0.960938V17.0566L15.5453 19.9722C15.6311 20.0172 15.7247 20.0395 15.818 20.0395C15.9394 20.0395 16.0604 20.0018 16.1624 19.9276C16.3428 19.7965 16.4332 19.5743 16.3955 19.3545L15.3363 13.1794L19.823 8.80616C19.9827 8.65049 20.0402 8.41764 19.9713 8.20557Z"
                  fill={isRightStarActive ? '#BA2A2A' : '#CFCFCF'}
                />
              </g>
              <defs>
                <clipPath id="clip0_4205_6376">
                  <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );
      })}
    </div>
  );
};
