import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { IImage } from 'interfaces/image.interface';
import React, { useCallback, useState } from 'react';

import './styles.scss';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { uploadFile } from 'utils/utils';
import { v4 as uuid } from 'uuid';

interface Props {
  blockId: string;
  data: {
    blockId: string;
    videos: VideoItem[];
  };
  onChange: (data: string) => void;
}

export interface VideoItem {
  title: string;
  url: string;
  thumbnail: IImage;
  position: string;
}

const INITIAL_DATA: Props['data'] = {
  blockId: '',
  videos: [],
};

export const ProductVideosTemplate: React.FC<Props> = (props) => {
  const { blockId, data = INITIAL_DATA, onChange } = props;

  const [idUploading, setIdUploading] = useState<number | null>(null);

  const _onChange = useCallback(
    (values: Props['data']) => {
      onChange(JSON.stringify(values));
    },
    [onChange],
  );

  const onAddVideoItem = useCallback(() => {
    _onChange({
      ...data,
      videos: [{ url: '', thumbnail: null, title: '', position: '' }, ...(data?.videos || [])],
    });
  }, [data, _onChange]);

  const onValuesChange = useCallback(
    (fieldKey: keyof VideoItem, value: string, itemIndex: number) => {
      const currentVideo = data.videos[itemIndex];
      currentVideo[fieldKey as any] = value;
      _onChange({ ...data, videos: [...data.videos] });
    },
    [data, _onChange],
  );

  const onUploadImage = useCallback(
    async (fieldKey: keyof VideoItem, value: RcFile, itemIndex: number) => {
      setIdUploading(itemIndex);

      await uploadFile(value, 'block', blockId, '', '0')
        .then((attr) =>
          onValuesChange(
            fieldKey,
            {
              ...attr,
              original: attr?.originUrl,
            },
            itemIndex,
          ),
        )
        .catch(() => onValuesChange(fieldKey, null, itemIndex));
      setIdUploading(null);
    },
    [onValuesChange, blockId],
  );

  return (
    <div className="product-videos-template">
      <Col span={24}>
        <Form.Item label="Block Id (Optional)">
          <Input
            value={data.blockId}
            onChange={(e) => _onChange({ ...data, blockId: e.target.value })}
          />
        </Form.Item>
      </Col>

      <div className="text-right">
        <Button onClick={onAddVideoItem} icon={<PlusOutlined />}>
          Thêm video
        </Button>
      </div>

      {data.videos.map((o, i) => {
        return (
          <div className="product-videos-template__item" key={`${i}`}>
            {i > 0 && <Divider />}
            <span className="product-videos-template__item-label">#{i + 1}</span>
            {idUploading === i && (
              <div className="loading-wrapper">
                <Spin />
                <span>Uploading...</span>
              </div>
            )}
            <Row gutter={16} className="mt-3 mb-3">
              <Col span={8}>
                <Form.Item label="Title">
                  <Input
                    value={o.title}
                    onChange={(e) => onValuesChange('title', e.target.value, i)}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Youtube URL">
                  <Input value={o.url} onChange={(e) => onValuesChange('url', e.target.value, i)} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Position">
                  <Input
                    value={o.position}
                    onChange={(e) => onValuesChange('position', e.target.value, i)}
                  />
                </Form.Item>
              </Col>

              {blockId && (
                <Col span={24}>
                  <Form.Item label="Thumbnail (1600 x 900)">
                    <UploadFile
                      uploadText="Click hoặc kéo thả hình (1600 x 900) để upload"
                      handleUploadImage={(file) => onUploadImage('thumbnail', file, i)}
                      typeId={uuid()}
                      handleDeleteImageSuccess={() => onValuesChange('thumbnail', null, i)}
                      previewImage={o?.thumbnail}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col className="text-right" span={24}>
                <Button
                  className="mt-2"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    _onChange({
                      ...data,
                      videos: data.videos.filter((_, pi) => pi !== i),
                    });
                  }}
                >
                  Xóa
                </Button>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};
