import { CopyOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Form,
  Row,
  Input,
  Button,
  Empty,
  Pagination,
  message,
  Spin,
  Select,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';

import { API_GET_POST_GALLERIES, API_UPDATE_DELETE_GALLERY } from 'constants/api/gallery.api';
import { defaultPagination } from 'constants/constants';
import { AlignType } from 'constants/enum';
import { IGallery } from 'interfaces/gallery.interface';
import { IImage } from 'interfaces/image.interface';
import { IPageData } from 'interfaces/page-data.interface';
import { GalleryModel } from 'models/gallery.model';
import * as api from 'services/api.service';
import { getPageData, onSetPageData } from 'services/pageData.service';
import { CustomModal } from 'ui/components/MaiNguyen/CustomModal';
import { PageTitle } from 'ui/components/MaiNguyen/PageTitle';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { deleteModal } from 'utils/modals';
import {
  showErrorMsg,
  uploadFile,
  getFileExtension,
  generateFileTypeFromExtension,
  transformAssetSize,
} from 'utils/utils';

import './Gallery.scss';

const newGalleryModel = new GalleryModel({});

const Gallery = () => {
  const [reloadList, setReloadList] = useState(false);
  const [galleries, setGalleries] = useState<IGallery[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState<IImage>(null);
  const [fileUpload, setFileUpload] = useState<RcFile>(null);
  const [assetId, setAssetId] = useState('');
  const [asset, setAsset] = useState(newGalleryModel);
  const [fileExtension, setFileExtension] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [sort, setSort] = useState('');
  const [keyword, setKeyword] = useState('');

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleOpenAddAssetModal = () => {
    setIsOpen(true);
    setAssetId('');
    setPreviewContent(null);
    setFileUpload(null);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const pageData: IPageData = {
    title: (
      <PageTitle
        title="Gallery"
        showButton
        buttonText="Tạo mới"
        buttonIcon={<PlusOutlined />}
        onClick={handleOpenAddAssetModal}
        showSaveButton={false}
      />
    ),
    breadcrumbs: [
      {
        title: 'Gallery',
        route: '/gallery',
      },
    ],
  };

  useEffect(() => {
    dispatch(onSetPageData(pageData));
  }, []);

  useEffect(() => {
    getPageData(`${API_GET_POST_GALLERIES}?page=1`, dispatch).then((res) => {
      const { pagination: pagin } = res?.meta;
      setPagination({
        current: pagin?.currentPage,
        pageSize: pagin?.perPage,
        total: pagin?.total,
      });
      setGalleries(res?.data);
    });
  }, []);

  useEffect(() => {
    if (reloadList) {
      const sortValue = sort ? `&sort=${sort}` : '';
      const keywordValue = keyword ? `&keyword=${keyword}` : '';
      api
        .get({
          endpoint: `${API_GET_POST_GALLERIES}?page=${pagination.current}${sortValue}${keywordValue}`,
        })
        .then((res) => {
          setGalleries(res?.data);
          setReloadList(false);
        });
    }
  }, [reloadList, pagination, sort, keyword]);

  const handleEditGallery = useCallback(
    (item: IGallery) => () => {
      const currentGallery = new GalleryModel(item.attributes);
      setIsOpen(true);
      setAssetId(item.id);
      setAsset(currentGallery);
      setFileUpload(null);
      setPreviewContent(item.attributes.images[0]);
      setFileExtension(
        generateFileTypeFromExtension(getFileExtension(item.attributes.images[0]?.original)),
      );
      form.setFieldsValue(currentGallery);
    },
    [],
  );

  const handleUploadFile = useCallback((file: RcFile) => {
    setFileUpload(file);
  }, []);

  const handleChangeValues = useCallback(
    (_, values) => {
      const currentGallery = new GalleryModel({ ...asset, ...values });
      setAsset(currentGallery);
      form.setFieldsValue(currentGallery);
    },
    [asset],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      setPagination({ ...pagination, current: page });
      setReloadList(true);
    },
    [pagination],
  );

  const handleDeleteImage = useCallback(() => {
    setPreviewContent(null);
    setFileUpload(null);
    setReloadList(true);
  }, []);

  const handleSaveAsset = useCallback(async () => {
    setLoading(true);
    try {
      if (assetId) {
        await api.put({
          endpoint: API_UPDATE_DELETE_GALLERY.replace('{id}', assetId),
          data: { name: asset.name },
        });
        await uploadFile(fileUpload, 'gallery', assetId, '', '0').then((attr) => {
          setPreviewContent({ ...attr?.resize, id: attr?.id });
        });
        setIsOpen(false);
        setLoading(false);
        setReloadList(true);
      } else {
        const assetContent = await api
          .post({
            endpoint: API_GET_POST_GALLERIES,
            data: { name: fileUpload.name, position: galleries.length + 1 },
          })
          .then((res) => res?.data);
        await uploadFile(fileUpload, 'gallery', assetContent?.id, '', '0').then((attr) => {
          setPreviewContent({ ...attr?.resize, id: attr?.id });
        });
        setIsOpen(false);
        setLoading(false);
        setReloadList(true);
      }
    } catch (err) {
      setLoading(false);
      showErrorMsg(err);
    }
  }, [assetId, fileUpload, asset]);

  const handleDeleteAsset = useCallback(
    (id: string) => () => {
      deleteModal({
        title: 'Bạn có muốn xóa asset này?',
        onDeleteCb: async () => {
          try {
            await api.remove({ endpoint: API_UPDATE_DELETE_GALLERY.replace('{id}', id) });
            setPreviewContent(null);
            setIsOpen(false);
            setReloadList(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [assetId],
  );

  const handleCopyName = useCallback((text: string, result) => {
    if (text && result) {
      message.success('Copy thành công!');
    }
  }, []);

  const handleChangeSortAndFilter = useCallback(
    (key: string) => (e) => {
      switch (key) {
        case 'sort':
          setSort(e);
          setReloadList(true);
          break;
        case 'keyword':
          setKeyword(e.target.value);
          setReloadList(true);
          break;
        default:
          break;
      }
    },
    [],
  );

  return (
    <div className="gallery-page">
      {reloadList && (
        <div className="loading-screen">
          <div className="bg-white" />
          <Spin />
        </div>
      )}
      <Card>
        <Form layout="vertical">
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label="Sort">
                <Select
                  size="large"
                  onChange={handleChangeSortAndFilter('sort')}
                  value={sort}
                  allowClear
                  placeholder="Sắp xếp theo..."
                >
                  <Select.Option value="asc">Sắp xếp theo A-Z</Select.Option>
                  <Select.Option value="desc">Sắp xếp theo Z-A</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Filter keyword">
                <Input
                  placeholder="Nhập keyword"
                  value={keyword}
                  onChange={handleChangeSortAndFilter('keyword')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className="custom-row">
        {galleries.map((item) => {
          const type = generateFileTypeFromExtension(
            getFileExtension(item.attributes.images[0]?.original),
          );
          const contentWidth = item.attributes.images[0]?.width;
          const contentHeight = item.attributes.images[0]?.height;
          return (
            <div className="item-col" key={item.id}>
              <Card
                hoverable
                onClick={handleEditGallery(item)}
                className="h-100"
                cover={
                  item.attributes.images[0] ? (
                    <embed
                      type={generateFileTypeFromExtension(
                        getFileExtension(item.attributes.images[0]?.original),
                      )}
                      className="preview-img m-auto"
                      src={item.attributes.images[0]?.original}
                    />
                  ) : (
                    <Empty className="m-auto" />
                  )
                }
              >
                <Card.Meta
                  title={
                    <>
                      <div className="mb-1 asset-title" title={item.attributes.name}>
                        <div className="title">{item.attributes.name}</div>
                        <div className="file-extension">{type.split('/')[0]}</div>
                      </div>
                      {type && (
                        <div className="image-info mt-2">{`${type.split('/')[1]}${
                          contentWidth && contentHeight ? ` - ${contentWidth}x${contentHeight}` : ''
                        } - ${transformAssetSize(item.attributes.images[0]?.size)}`}</div>
                      )}
                    </>
                  }
                />
              </Card>
              <Button
                icon={<DeleteOutlined />}
                className="mr-2 delete-btn"
                title="Xóa"
                onClick={handleDeleteAsset(item.id)}
              />
            </div>
          );
        })}
        {pagination.total > 15 && (
          <div className="item-full-col mt-2" style={{ textAlign: AlignType.right }}>
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={handleChangePage}
              disabled={reloadList}
            />
          </div>
        )}
      </div>
      <CustomModal
        position="bottom"
        isOpen={isOpen}
        onCloseModal={handleCloseModal}
        title="Upload asset"
        width={800}
        customFooter={
          <>
            <Button className="mr-2" onClick={handleCloseModal}>
              Cancel
            </Button>
            <ThemeButton loading={loading} onClick={handleSaveAsset}>
              Lưu
            </ThemeButton>
          </>
        }
      >
        {assetId && (
          <Form form={form} layout="vertical" onValuesChange={handleChangeValues}>
            <Form.Item label="Asset Name" name="name">
              <Input />
            </Form.Item>
            {previewContent && (
              <>
                <Row gutter={16} align="middle">
                  <Col span={22}>
                    <Form.Item label="Asset Path">
                      <div>{previewContent?.original}</div>
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <CopyToClipboard text={previewContent?.original} onCopy={handleCopyName}>
                      <Button title="Copy" className="mt-2" icon={<CopyOutlined />} />
                    </CopyToClipboard>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Created Date">
                      <div>{previewContent?.createdAt}</div>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Size">
                      <div>{transformAssetSize(previewContent?.size)}</div>
                    </Form.Item>
                  </Col>
                  {previewContent?.width && previewContent?.height && (
                    <Col span={8}>
                      <Form.Item label="Dimension">
                        <div>{`${previewContent?.width}x${previewContent?.height}`}</div>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </Form>
        )}
        <UploadFile
          handleUploadImage={handleUploadFile}
          imageSize="256x256"
          maxWidth={256}
          fileAccept=".jpg, .png, .gif, .webp, .pdf, .mp4"
          previewImage={previewContent}
          fileType={fileExtension}
          resetFileUpload={!fileUpload}
          handleDeleteImageSuccess={handleDeleteImage}
        />
      </CustomModal>
    </div>
  );
};

export default Gallery;
