import React, { SVGProps } from 'react';

export const Star: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    {...props}
  >
    <path
      d="M7.625 0.117188L9.95833 4.78385L14.625 5.36719L11.4206 8.96052L12.2917 14.1172L7.625 11.7839L2.95833 14.1172L3.83722 8.96052L0.625 5.36719L5.29167 4.78385L7.625 0.117188Z"
      fill="currentColor"
    />
  </svg>
);
