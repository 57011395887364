import { addVnCurrency } from 'utils/utils';
import { roundedRevenue } from 'constants/enum';

export default {
  title: {
    text: 'Thống kê',
  },
  tooltip: {
    trigger: 'axis',
    formatter: function (params) {
      const idxRevenue = params.findIndex((item) => item.seriesName === 'Doanh thu');
      const idxOrder = params.findIndex((item) => item.seriesName === 'Đơn hàng');

      if (idxRevenue !== -1 && idxOrder !== -1) {
        return `<div class="chart-tooltip revenue"><span class="lbl">Doanh thu:</span>${addVnCurrency(
          Math.round(params[idxRevenue]?.value * roundedRevenue),
        )}</div>
        <div class="chart-tooltip orders"><span class="lbl">Đơn hàng:</span>${
          params[idxOrder]?.value
        } đơn</div>`;
      } else if (idxOrder !== -1) {
        return `
        <div class="chart-tooltip orders"><span class="lbl">Đơn hàng:</span>${params[idxOrder]?.value} đơn</div>`;
      } else {
        return `<div class="chart-tooltip revenue"><span class="lbl">Doanh thu:</span>${addVnCurrency(
          Math.round(params[idxRevenue]?.value * roundedRevenue),
        )}</div>`;
      }
    },
  },
  legend: {
    data: ['Doanh thu', 'Đơn hàng'],
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
  ],
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        formatter: function (params) {
          return `${params >= 1500 ? `${params}m` : params}`;
        },
      },
    },
  ],
  series: [
    {
      name: 'Doanh thu',
      type: 'line',
      stack: 'revenue',
      itemStyle: { normal: { areaStyle: { type: 'default' } } },
      lineStyle: { color: '#bd0000' },
      data: [],
      color: 'rgba(189, 0, 0 , 0.3)',
    },
    {
      name: 'Đơn hàng',
      type: 'line',
      stack: 'order',
      itemStyle: { normal: { areaStyle: { type: 'default' } } },
      lineStyle: { color: '#1280f5' },
      data: [],
      color: 'rgba(18, 128, 245 , 0.3)',
    },
  ],
};
