import { DeleteOutlined } from '@ant-design/icons';
import { Button, Switch } from 'antd';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { API_YOUTUBE_VIDEOS, API_UPDATE_YOUTUBE_VIDEO } from 'constants/api/youtube-manager.api';
import { defaultPagination } from 'constants/constants';
import { AlignType } from 'constants/enum';

import { IPagination } from 'interfaces/pagination.interface';
import { IYoutubeVideo, IYoutubeVideoAttribute } from 'interfaces/youtube-video.interface';

import LayoutList from 'layouts/LayoutList/LayoutList';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';

import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';
import { TableFilterView } from './components/FilterView';
import { FormModal } from './components/FormModal';

// import './Brand.scss';

const YoutubeManager = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [reloadList, setReloadList] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const [youtubeVideos, setYoutubeVideos] = useState<IYoutubeVideo[]>([]);
  const [ytVideoSelected, setYtVideoSelected] = useState<IYoutubeVideo>(null);

  // Filter state
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [filterName, setFilterName] = useState<string>('');

  // Modal state
  const [formModalTitle, setFormModalTitle] = useState<
    'Tạo bìa Youtube' | 'Soạn bìa Youtube' | null
  >(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (firstLoad) {
      const parsedSearch = queryString.parse(location.search);
      getPageData(
        `${API_YOUTUBE_VIDEOS}?page=${
          parsedSearch?.page && typeof Number(parsedSearch?.page) === 'number'
            ? parsedSearch.page
            : 1
        }${parsedSearch?.filter ? `&title=${parsedSearch.filter}` : ''}`,
        dispatch,
      ).then((res) => {
        const pagin = res?.meta?.pagination;
        setYoutubeVideos(res?.data);
        setFilterName((parsedSearch?.filter || '') as string);
        setPagination({
          current: pagin.currentPage,
          pageSize: pagin.perPage,
          total: pagin.total,
        });
        setFirstLoad(false);
      });
    }
  }, [firstLoad]);

  useEffect(() => {
    if (reloadList) {
      api
        .get({
          endpoint: `${API_YOUTUBE_VIDEOS}?page=${pagination.current}&title=${filterName}`,
        })
        .then((res) => {
          const pagin = res?.meta?.pagination;
          setPagination({
            current: pagin.currentPage,
            pageSize: pagin.perPage,
            total: pagin.total,
          });
          setYoutubeVideos(res?.data);
          setReloadList(false);
        });
    }
  }, [reloadList]);

  useEffect(() => {
    if (!firstLoad) {
      history.push(
        queryString.stringifyUrl({
          url: location.pathname,
          query: {
            page: pagination.current,
            filter: filterName,
          },
        }),
      );
    }
  }, [firstLoad, pagination, filterName]);

  const handleChangePage = useCallback(
    (page: number) => {
      const clonePagination = { ...pagination };
      clonePagination.current = page;
      setPagination(clonePagination);
      setReloadList(true);
    },
    [pagination],
  );

  const onTableFilter = useCallback(() => {
    handleChangePage(1);
  }, [handleChangePage]);

  const onClearTableFilter = useCallback(() => {
    setFilterName('');
    onTableFilter();
  }, [onTableFilter]);

  const onCloseFormModal = useCallback(() => {
    setIsFormModalOpen(false);
    setFormModalTitle(null);
    setYtVideoSelected(null);
    dispatch(setIsEditing(false));
  }, []);

  const onCloseAndRefreshFormModal = useCallback(() => {
    onCloseFormModal();
    setReloadList(true);
  }, []);

  const onAddVideo = useCallback(() => {
    setFormModalTitle('Tạo bìa Youtube');
    setIsFormModalOpen(true);
    dispatch(setIsEditing(false));
  }, []);

  const onEdit = useCallback(
    (item: IYoutubeVideo) => () => {
      setFormModalTitle('Soạn bìa Youtube');
      setYtVideoSelected(item);
      setIsFormModalOpen(true);
    },
    [],
  );

  const onActiveChange = useCallback(
    async (item: IYoutubeVideo) => {
      const cloneItem = { ...item };
      cloneItem.attributes.active = !cloneItem.attributes.active;
      try {
        const itemIndex = youtubeVideos.findIndex((o: IYoutubeVideo) => o.id === item.id);
        await api.put({
          endpoint: API_UPDATE_YOUTUBE_VIDEO.replace('{id}', item.id),
          data: {
            active: cloneItem.attributes.active,
          },
        });
        youtubeVideos[itemIndex] = cloneItem;
        setYoutubeVideos([...youtubeVideos]);
      } catch (error) {
        showErrorMsg(error);
      }
    },
    [youtubeVideos],
  );

  const onDelete = useCallback(
    (id: string) => {
      deleteModal({
        title: 'Bạn có muốn xóa video này?',
        onDeleteCb: async () => {
          if (id) {
            try {
              await api.remove({ endpoint: API_UPDATE_YOUTUBE_VIDEO.replace('{id}', id) });
              setReloadList(true);
            } catch (err) {
              showErrorMsg(err);
            }
          }
        },
      });
    },
    [ytVideoSelected],
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: string) => <SanitizeHtml rawHtml={id} />,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'attributes',
      render: (itemAttr: IYoutubeVideoAttribute, item: IYoutubeVideo) => (
        <div onClick={onEdit(item)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          <SanitizeHtml rawHtml={itemAttr.title} />
        </div>
      ),
    },
    {
      title: 'Hình Thumb',
      dataIndex: 'attributes',
      render: (itemAttr: IYoutubeVideoAttribute, item: IYoutubeVideo) => {
        return itemAttr.image?.id ? (
          <span className="brand-logo" onClick={() => onEdit(item)}>
            <img src={itemAttr.image['128x128']} alt={itemAttr.title} />
          </span>
        ) : (
          <span>Không có hình</span>
        );
      },
    },
    {
      title: 'Hiển thị',
      dataIndex: 'attributes',
      render: (itemAttr: IYoutubeVideoAttribute, item: IYoutubeVideo) => (
        <Switch checked={itemAttr.active} onChange={() => onActiveChange(item)} />
      ),
    },
    {
      title: '',
      dataIndex: 'attributes',
      align: AlignType.right,
      render: (_: any, item: IYoutubeVideo) => (
        <Button title="Xóa" icon={<DeleteOutlined />} onClick={() => onDelete(item.id)} />
      ),
    },
  ];

  return (
    <>
      <LayoutList
        pageTitle="Quản lý Youtube"
        breadcrumbs={[
          {
            title: 'Quản lý Youtube',
            route: '/quan-ly-youtube',
          },
        ]}
        showAddButton
        handleClickAddButton={onAddVideo}
        columns={columns}
        dataList={youtubeVideos}
        tableClass="youtube-manager-table"
        pagination={pagination}
        handleChangePage={handleChangePage}
        topPagination
        loadingList={reloadList}
        filterNode={
          <TableFilterView
            searchValue={filterName}
            onSearchChange={setFilterName}
            onSearchEnter={onTableFilter}
            onFilter={onTableFilter}
            onClearFilter={onClearTableFilter}
          />
        }
      />

      {/* Add/Edit form modal */}
      <FormModal
        title={formModalTitle}
        isOpen={isFormModalOpen}
        data={ytVideoSelected}
        onClose={onCloseFormModal}
        onCloseAndRefresh={onCloseAndRefreshFormModal}
      />
    </>
  );
};

export default YoutubeManager;
