import { Empty } from 'antd';
import { Loading } from 'ui/components/MaiNguyen/Loading';
import CustomerInfo from './CustomerInfo';
import OrderInfo from './OrderInfo';
import OrderNotes from './OrderNotes';
import OrderNumber from './OrderNumber';
import { useFetchMemberOrder } from '../hook';
import '../index.styles.scss';

const OrderDetail: React.FC = () => {
  // Hook get order detail
  const { isFetching, data } = useFetchMemberOrder();

  return (
    <div className="member-orders-content relative">
      <Loading isLoading={isFetching} />

      {data ? (
        <div className="orders-detail relative">
          <div className="inner">
            <OrderNumber data={data} />

            <div className="ord-info">
              <CustomerInfo data={data?.attributes} />

              <OrderNotes data={data?.attributes} />

              <OrderInfo data={data?.attributes} />
            </div>
          </div>
        </div>
      ) : (
        <div className="blk-empty">
          <Empty description="Danh sách trống" />
        </div>
      )}
    </div>
  );
};

export default OrderDetail;
