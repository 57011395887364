import { DeleteOutlined } from '@ant-design/icons';
import { Button, Pagination, Table, TableProps } from 'antd';
import { ICategory, ICategoryAttribute } from 'interfaces/category.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { useEffect } from 'react';
import { AlignType } from 'constants/enum';

interface Props extends TableProps<any> {
  loading: boolean;
  data: ICategory[];
  pagination?: IPagination | false;
  onRemove?: (id: string) => void;
  onPageChange?: (page: number, pageSize: number) => void;
}

export const CategoryTable: React.FC<Props> = (props) => {
  const { loading, data, pagination, onRemove, onPageChange, ...rest } = props;

  /**
   * An effect that updates the empty list message when the fetching status of products changes.
   */
  useEffect((): void => {
    if (!loading) {
      const selector = '.products-table-wrapper table .ant-empty-description';
      const els = document.querySelectorAll(selector);
      if (els?.length) {
        els.forEach((el) => {
          el.innerHTML = 'Danh sách trống';
        });
      }
    }
  }, [loading]);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tên danh mục',
      dataIndex: 'attributes',
      render: (attr: ICategoryAttribute) => {
        return <div dangerouslySetInnerHTML={{ __html: attr.name }} className="product-name" />;
      },
    },
    {
      title: 'Mô tả',
      dataIndex: 'attributes',
      render: (attr: ICategoryAttribute) => {
        return <div dangerouslySetInnerHTML={{ __html: attr.description }} />;
      },
    },
    onRemove
      ? {
          title: 'Thao tác',
          dataIndex: '',
          align: AlignType.right,
          render: (row: ICategory) => (
            <Button
              title="Remove"
              className="mb-2"
              icon={<DeleteOutlined rev={null} />}
              onClick={() => onRemove(row.id)}
            />
          ),
        }
      : undefined,
  ].filter((c) => !!c);

  return (
    <>
      <div className="products-table-wrapper">
        <Table
          showHeader={false}
          columns={columns}
          dataSource={data}
          rowKey="id"
          loading={loading}
          pagination={false}
          {...rest}
        />
      </div>
      {pagination && (
        <div className="layout-list-pagination">
          <Pagination
            {...pagination}
            pageSizeOptions={[]}
            onChange={onPageChange}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
};
