import { DeleteOutlined } from '@ant-design/icons';
import { Button, Pagination, TableProps, Tag } from 'antd';
import { IImage } from 'interfaces/image.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { IProduct, IProductAttribute } from 'interfaces/product.interface';
import { IStatus } from 'interfaces/status.interface';
import { BrandLogoCell } from 'pages/Products/ProductDetail/components/BaseProductsTable/BaseProductsTable';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { SortableTable } from 'ui/components/SortableTable/SortableTable';
import { renderColorStatus } from 'utils/renderColorStatus';
import { addVnCurrency, getCurrentPrice, getOldPrice } from 'utils/utils';
import { AlignType } from 'constants/enum';

import './styles.scss';

interface Props extends TableProps<any> {
  loading: boolean;
  data: IProduct[];
  subStatus: IStatus[];
  pagination?: IPagination | false;
  onRemove?: (sku: string) => void;
  onChangePosition?: (products: any[]) => void;
  onPageChange?: (page: number, pageSize: number) => void;
}

export const ProductTable: React.FC<Props> = (props) => {
  const {
    loading,
    data,
    subStatus,
    pagination,
    onRemove,
    onPageChange,
    onChangePosition,
    ...rest
  } = props;

  /**
   * An effect that updates the empty list message when the fetching status of products changes.
   */
  useEffect((): void => {
    if (!loading) {
      const selector = '.products-table-wrapper table .ant-empty-description';
      const els = document.querySelectorAll(selector);
      if (els?.length) {
        els.forEach((el) => {
          el.innerHTML = 'Danh sách trống';
        });
      }
    }
  }, [loading]);

  const columns = [
    {
      title: 'Hình ảnh',
      dataIndex: 'attributes',
      width: 100,
      render: (attr: IProductAttribute) => {
        const imageValues = attr.attributeValues.find((item) => item.attributeCode === 'image');
        const image = imageValues?.value.find((item: IImage) => item.property === 'main');

        return (
          <div className="product-image">
            <BrandLogoCell image={image?.['128x128']} />
          </div>
        );
      },
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute, row: IProduct) => {
        return (
          <div>
            <NavLink to={`/san-pham/${row.id}`}>
              <div dangerouslySetInnerHTML={{ __html: attr.name }} className="product-name" />
            </NavLink>
            <div>{`SKU: ${attr.sku}`}</div>
            <div className="product-type">{`Type: ${attr.type}`}</div>
          </div>
        );
      },
    },
    {
      title: 'Giá hiện tại',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute) => {
        const currentPrice = attr.attributeValues.find(
          (item) => item.attributeCode === 'original_price',
        );
        const salePrice = attr.attributeValues.find((item) => item.attributeCode === 'sale_price');
        const newPrice = addVnCurrency(getCurrentPrice(currentPrice?.value, salePrice?.value));
        const oldPrice = addVnCurrency(getOldPrice(currentPrice?.value, salePrice?.value));

        return (
          <>
            <div className="product-price">{newPrice}</div>
            <div className={oldPrice !== '-' ? 'old-price' : ''}>{oldPrice}</div>
          </>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute) => {
        const status = subStatus?.find((item) => item.id === attr?.statusId?.toString());
        return (
          <div className="text-center">
            <div className={`${attr?.active ? 'product--published' : ''}`}>
              {attr?.active ? 'Published' : 'Unpublished'}
            </div>
            {status && (
              <Tag
                color={renderColorStatus(status?.attributes?.parentId)}
                style={{ whiteSpace: 'pre-line' }}
              >
                {status?.attributes?.name}
              </Tag>
            )}
          </div>
        );
      },
    },
    onRemove
      ? {
          title: 'Thao tác',
          dataIndex: '',
          align: AlignType.right,
          render: (row: IProduct) => (
            <Button
              title="Remove"
              className="mb-2"
              icon={<DeleteOutlined />}
              onClick={() => onRemove(row.attributes.sku)}
            />
          ),
        }
      : undefined,
  ].filter((c) => !!c);

  return (
    <>
      <div className="products-table-wrapper">
        <SortableTable
          showHeader={false}
          columns={columns}
          dataList={data}
          rowKey={'id' as any}
          loading={loading}
          onChanged={onChangePosition}
          {...rest}
        />
      </div>
      {pagination && (
        <div className="layout-list-pagination">
          <Pagination
            {...pagination}
            pageSizeOptions={[]}
            onChange={onPageChange}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
};
