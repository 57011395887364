/* eslint-disable @typescript-eslint/no-use-before-define */
import { ICoupon } from 'interfaces/coupon.interface';
import { Customer } from 'interfaces/customer.interface';
import { IOrderStats } from 'interfaces/order-statistics.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { IProduct } from 'interfaces/product.interface';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updatePageData } from 'redux/pages/actions';
import { fetcherWithArg } from 'services/swr.utils';
import useSWRMutation from 'swr/mutation';
import { buildParams } from 'utils/utils';
import {
  API_CUSTOMER_TOP_ORDERS,
  API_TOP_PRODUCT_COMMENTS,
  API_TOP_PRODUCT_REVIEWS,
  API_TOP_PRODUCT_WISHLIST,
  API_TOP_PRODUCT_ORDERS,
  API_ORDERS_STATISTICS,
  API_TOP_COUPONS_USED,
} from 'constants/api/dashboard.api';
import { defaultDashboardPagination } from 'constants/constants';
import { StatsBysMonth, StatsByType } from 'constants/enum';

/**
 * Custom hook that fetches and returns top orders data
 */
export const useFetchCustomerTopOrders = () => {
  const dispatch = useDispatch();

  const { data, isMutating, trigger } = useSWRMutation(API_CUSTOMER_TOP_ORDERS, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultDashboardPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetch();
  }, []);

  const fetch = useCallback(
    async (params?: { page?: number }): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const { page = pagination.current, ...rest } = params || {};

      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;
      const query = {
        ...locationParams,
        page: calcPage,
        perPage: defaultDashboardPagination.pageSize,
        ...rest,
      };

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetching: isMutating,
    data: data?.data as Customer[],
    pagination,
    setPagination,
    fetchCustomerTopOrders: fetch,
  };
};

/**
 * Custom hook that fetches and returns top product reviews data
 */
export const useFetchTopProductReviews = () => {
  const dispatch = useDispatch();

  const { data, isMutating, trigger } = useSWRMutation(API_TOP_PRODUCT_REVIEWS, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultDashboardPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetch();
  }, []);

  const fetch = useCallback(
    async (params?: { page?: number }): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const { page = pagination.current, ...rest } = params || {};

      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;
      const query = {
        ...locationParams,
        page: calcPage,
        perPage: defaultDashboardPagination.pageSize,
        ...rest,
      };

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetching: isMutating,
    data: data?.data as IProduct[],
    pagination,
    setPagination,
    fetchTopProductReviews: fetch,
  };
};

/**
 * Custom hook that fetches and returns top product comments data
 */
export const useFetchTopProductComments = () => {
  const dispatch = useDispatch();

  const { data, isMutating, trigger } = useSWRMutation(API_TOP_PRODUCT_COMMENTS, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultDashboardPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetch();
  }, []);

  const fetch = useCallback(
    async (params?: { page?: number }): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const { page = pagination.current, ...rest } = params || {};

      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;
      const query = {
        ...locationParams,
        page: calcPage,
        perPage: defaultDashboardPagination.pageSize,
        ...rest,
      };

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetching: isMutating,
    data: data?.data as IProduct[],
    pagination,
    setPagination,
    fetchTopProductComments: fetch,
  };
};

/**
 * Custom hook that fetches and returns top product wishlist data
 */
export const useFetchTopProductWishlist = () => {
  const dispatch = useDispatch();

  const { data, isMutating, trigger } = useSWRMutation(API_TOP_PRODUCT_WISHLIST, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultDashboardPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetch();
  }, []);

  const fetch = useCallback(
    async (params?: { page?: number }): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const { page = pagination.current, ...rest } = params || {};

      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;
      const query = {
        ...locationParams,
        page: calcPage,
        perPage: defaultDashboardPagination.pageSize,
        ...rest,
      };

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetching: isMutating,
    data: data?.data as IProduct[],
    pagination,
    setPagination,
    fetchTopProductWishlist: fetch,
  };
};

/**
 * Custom hook that fetches and returns top product orders data
 */
export const useFetchTopProductOrders = () => {
  const dispatch = useDispatch();

  const { data, isMutating, trigger } = useSWRMutation(API_TOP_PRODUCT_ORDERS, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultDashboardPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetch();
  }, []);

  const fetch = useCallback(
    async (params?: { page?: number }): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const { page = pagination.current, ...rest } = params || {};

      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;
      const query = {
        ...locationParams,
        page: calcPage,
        perPage: defaultDashboardPagination.pageSize,
        ...rest,
      };

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetching: isMutating,
    data: data?.data as IProduct[],
    pagination,
    setPagination,
    fetchTopProductOrders: fetch,
  };
};

/**
 * Custom hook that fetches and returns orders statistics data
 */
export const useFetchOrdersStatistics = () => {
  const dispatch = useDispatch();

  const { data, isMutating, trigger } = useSWRMutation(API_ORDERS_STATISTICS, fetcherWithArg);

  useEffect(() => {
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetch();
  }, []);

  const fetch = useCallback(
    async (params?: { month?: string; type?: string }): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const { month = StatsBysMonth[1].value, type = StatsByType[1].value, ...rest } = params || {};

      const query = {
        ...locationParams,
        month,
        type,
        ...rest,
      };

      await trigger(buildParams(query));
    },
    [data?.meta?.pagination?.currentPage],
  );

  return {
    isFetching: isMutating,
    data: data?.data as IOrderStats[],
    fetchOrdersStatistics: fetch,
  };
};

/**
 * Custom hook that fetches and returns top coupons used data
 */
export const useFetchTopCouponsUsed = () => {
  const dispatch = useDispatch();

  const { data, isMutating, trigger } = useSWRMutation(API_TOP_COUPONS_USED, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultDashboardPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetch();
  }, []);

  const fetch = useCallback(
    async (params?: { page?: number }): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const { page = pagination.current, ...rest } = params || {};

      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;
      const query = {
        ...locationParams,
        page: calcPage,
        perPage: defaultDashboardPagination.pageSize,
        ...rest,
      };

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetching: isMutating,
    data: data?.data as ICoupon[],
    pagination,
    setPagination,
    fetchTopCouponsUsed: fetch,
  };
};
