import { IAttributeGallery } from 'interfaces/gallery.interface';

export class GalleryModel implements IAttributeGallery {
  name?: string;

  position?: number;

  constructor({ name, position }: IAttributeGallery) {
    this.name = name || '';
    this.position = position;
  }
}
