/* eslint-disable import/order */
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { memo, useCallback, useState } from 'react';
import { ReviewList } from 'pages/Members/Review/components/ReviewList';
import { usePostReview } from 'pages/Members/Review/hook';

import './index.styles.scss';
import { useParams } from 'react-router-dom';
import { htmlEncode, uploadFile } from 'utils/utils';
import { ProductReviewDesc } from './components/Description';
import { ReviewFormValuesProps, ReviewSeedingForm } from './components/Form';

const ProductReview: React.FC = memo(() => {
  const { id: productId } = useParams<{ id: string }>();
  const { isLoading, onSubmit } = usePostReview();

  const [idLoading, setIdLoading] = useState<string | null>(null);
  const [imgLoading, setImgLoading] = useState<boolean>(false);

  /**
   * Handle upload image
   */
  const handleUploadImage = useCallback((selectedImages: RcFile[], reviewId: string) => {
    const actions = selectedImages.map(async (item) => uploadFile(item, 'review', reviewId));
    const results = Promise.all(actions);

    setImgLoading(true);
    results.then(() => {
      message.success('Upload review image(s) success!', 3);
      message.success('Thêm review thành công');
      setIdLoading(reviewId);
      setImgLoading(false);
    });
  }, []);

  /**
   * Handle submit form
   */
  const handleSubmitForm = useCallback(
    async (formValues: ReviewFormValuesProps) => {
      const { score, content, posterName, selectedImages, storeId } = formValues;
      try {
        const res = await onSubmit({
          data: {
            score: score,
            posterName: posterName,
            content: htmlEncode(content),
            status: 'published',
            productId: productId,
            storeId: storeId,
            isBought: true,
            isSharing: true,
          },
        });
        if (res) {
          handleUploadImage(selectedImages, res.id);
        }
      } catch (error: any) {
        message.error(error?.message || error);
      }
    },
    [onSubmit, productId],
  );

  return (
    <div className="product-review-wrap">
      <ProductReviewDesc />
      {/* END: DESC */}

      <ReviewSeedingForm
        isLoading={isLoading || imgLoading}
        isResetForm={idLoading}
        onSubmit={handleSubmitForm}
      />
      {/* END: FORM */}

      <ReviewList idReviewLoading={idLoading} />
      {/* END: LIST */}
    </div>
  );
});

export default ProductReview;
