import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Typography, Form, Collapse, List, message } from 'antd';
import { isEmpty, unionBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  API_GET_POST_ATTRIBUTE_SETS,
  API_PUT_DELETE_ATTRIBUTE_SET,
  API_GET_ATTRIBUTE_GROUPS,
  API_GET_POST_ATTRIBUTES,
  API_GET_ATTRIBUTE_SUB_GROUPS,
} from 'constants/api/attribute.api';
import { IAttributeGroup } from 'interfaces/attr-group.interface';
import { IAttributeSet, IAttrAttributeSet } from 'interfaces/attr-set.interface';
import { IAttribute } from 'interfaces/attribute.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { AttributeSetModel } from 'models/attribute-set.model';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomModal } from 'ui/components/MaiNguyen/CustomModal';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';

import { ProductAttrGroup } from './ProductAttrGroup';

import './AttrGroup.scss';

const AttrGroup = () => {
  const newAttributeSet = new AttributeSetModel({});

  const [reloadList, setReloadList] = useState(false);
  const [attributeSets, setAttributeSets] = useState<IAttributeSet[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAttr, setSelectedAttr] = useState<IAttrAttributeSet>(newAttributeSet);
  const [attrSetId, setAttrSetId] = useState('');

  const [attributeGroups, setAttributeGroups] = useState<IAttributeGroup[]>([]);
  const [attributeSubGroups, setAttributeSubGroups] = useState<IAttributeGroup[]>([]);

  const [attributes, setAttributes] = useState<IAttribute[]>([]);

  const [form] = Form.useForm();
  const [formCreatAttrSet] = Form.useForm();
  const dispatch = useDispatch();

  const handleCloseAttrSetModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenAddAttrSetModal = useCallback(() => {
    setOpenModal(true);
    formCreatAttrSet.resetFields();
    setSelectedAttr(newAttributeSet);
  }, []);

  useEffect(() => {
    getPageData(`${API_GET_POST_ATTRIBUTE_SETS}?perPage=all`, dispatch).then((res) => {
      setAttributeSets(res?.data);
    });
  }, []);

  useEffect(() => {
    if (reloadList) {
      api
        .get({ endpoint: `${API_GET_POST_ATTRIBUTE_SETS}?perPage=all` })
        .then((res) => {
          setReloadList(false);
          setAttributeSets(res?.data);
          dispatch(setIsEditing(false));
        })
        .catch((err) => {
          setReloadList(false);
          showErrorMsg(err);
        });
    }
  }, [reloadList]);

  useEffect(() => {
    api
      .get({ endpoint: `${API_GET_POST_ATTRIBUTES}?perPage=all` })
      .then((res) => {
        setAttributes(res?.data.filter((item: IAttribute) => item.attributes.active));
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, []);

  const selectAttrSet = useCallback(async (attrSet: IAttributeSet) => {
    const attr = new AttributeSetModel(attrSet.attributes);
    setSelectedAttr(attr);
    setAttrSetId(attrSet.id);
    form.setFieldsValue(attr);
    await api
      .get({
        endpoint: `${API_GET_ATTRIBUTE_GROUPS.replace(
          '{id}',
          attrSet.id,
        )}?perPage=all&include=children,attributes`,
      })
      .then((res) => {
        setAttributeGroups(res?.data);
        res?.data?.forEach(async (item) => {
          await api
            .get({
              endpoint: `${API_GET_ATTRIBUTE_SUB_GROUPS.replace('{id}', item.id)}?perPage=all`,
            })
            .then(({ data }) => {
              setAttributeSubGroups(data);
            });
        });
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, []);

  const handleUpdateAttributeGroup = useCallback(async () => {
    await api
      .get({
        endpoint: `${API_GET_ATTRIBUTE_GROUPS.replace(
          '{id}',
          attrSetId,
        )}?perPage=all&include=children,attributes`,
      })
      .then((res) => {
        setAttributeGroups(res?.data);
        dispatch(setIsEditing(false));
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, [attrSetId]);

  const handleSelectAttrSet = useCallback(
    (attrSet: IAttributeSet) => () => {
      selectAttrSet(attrSet);
    },
    [selectAttrSet],
  );

  const handleValuesChange = useCallback(
    (isEdit: boolean) => (_, values) => {
      dispatch(setIsEditing(true));
      const currentAttrSet = new AttributeSetModel(values);
      setSelectedAttr(currentAttrSet);
      if (isEdit) {
        form.setFieldsValue(currentAttrSet);
      } else {
        formCreatAttrSet.setFieldsValue(currentAttrSet);
      }
    },
    [],
  );

  const handleCreateAttrSet = useCallback(async () => {
    const attrSet = new AttributeSetModel(selectedAttr);
    try {
      await api.post({ endpoint: API_GET_POST_ATTRIBUTE_SETS, data: attrSet }).then((res) => {
        selectAttrSet(res?.data);
      });
      setOpenModal(false);
      setReloadList(true);
    } catch (err) {
      showErrorMsg(err);
    }
  }, [selectedAttr, selectAttrSet]);

  const handleUpdateAttrSet = useCallback(async () => {
    const attrSet = new AttributeSetModel(selectedAttr);
    setReloadList(true);
    try {
      await api
        .put({
          endpoint: API_PUT_DELETE_ATTRIBUTE_SET.replace('{id}', attrSetId),
          data: attrSet,
        })
        .then((res) => {
          selectAttrSet(res?.data);
        });
      message.success('Update attribute set thành công', 3);
    } catch (err) {
      showErrorMsg(err);
      setReloadList(false);
    }
  }, [selectedAttr, attrSetId]);

  const handleDeleteAttrSet = useCallback(
    (attrSet: IAttributeSet) => () => {
      deleteModal({
        title: `Bạn có muốn xóa Attribute set: <strong>${attrSet.attributes.name}</strong> này?`,
        onDeleteCb: async () => {
          try {
            await api.remove({
              endpoint: API_PUT_DELETE_ATTRIBUTE_SET.replace('{id}', attrSet.id),
            });
            setReloadList(true);
            setSelectedAttr(newAttributeSet);
            setAttrSetId('');
            formCreatAttrSet.setFieldsValue(newAttributeSet);
            form.setFieldsValue(newAttributeSet);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const renderListItem = useCallback(
    (itemList: IAttributeSet) => {
      return (
        <List.Item
          className={`attr-list-item ${attrSetId === itemList.id ? 'active' : ''}`}
          onClick={handleSelectAttrSet(itemList)}
        >
          <List.Item.Meta title={itemList.attributes.name} />
          {itemList.attributes.isUserDefined && (
            <Button
              className="small"
              icon={<DeleteOutlined />}
              onClick={handleDeleteAttrSet(itemList)}
            />
          )}
        </List.Item>
      );
    },
    [selectedAttr, attrSetId],
  );

  return (
    <>
      <LayoutDetail
        pageTitle="Nhóm thuộc tính sản phẩm"
        breadcrumbs={[
          {
            title: 'Nhóm thuộc tính sản phẩm',
            route: '/nhom-thuoc-tinh-san-pham',
          },
        ]}
        formHook={form}
        isAddNew={false}
        handleValuesChange={handleValuesChange(true)}
        wrapperCard={false}
        pageClassName="attr-page"
        handleClickAddButton={handleOpenAddAttrSetModal}
        handleSave={handleUpdateAttrSet}
        showSaveButton={false}
      >
        <Row>
          <Col span={24} className="text-right mb-5">
            <Button icon={<PlusOutlined />} onClick={handleOpenAddAttrSetModal}>
              Tạo mới
            </Button>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={8}>
            <Card>
              <List
                itemLayout="horizontal"
                dataSource={attributeSets}
                renderItem={renderListItem}
              />
            </Card>
          </Col>
          <Col span={16}>
            <Card
              title={
                <Row justify="center">
                  <Col span={8}>
                    <Typography.Title level={3}>
                      {selectedAttr?.name
                        ? `Attrbutes Set: ${selectedAttr?.name}`
                        : 'Thông tin Attribute Set'}
                    </Typography.Title>
                  </Col>
                  <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                    <ThemeButton
                      onClick={handleUpdateAttrSet}
                      loading={reloadList}
                      disabled={!attrSetId}
                    >
                      Lưu
                    </ThemeButton>
                  </Col>
                </Row>
              }
            >
              <Row gutter={24}>
                <Col span={24}>
                  <Row gutter={12}>
                    <Col span={8}>
                      <Form.Item name="name" label="Attribute Set Name">
                        <Input disabled={!attrSetId} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="code" label="Attribute Set Code">
                        <Input disabled={!attrSetId} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="skuPrefix" label="Sku Prefix">
                        <Input disabled={!attrSetId} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                {attrSetId && (
                  <Col span={24}>
                    <Collapse>
                      <Collapse.Panel key="1" header="Nhóm thuộc tính của sản phẩm">
                        <ProductAttrGroup
                          attributeGroups={attributeGroups.filter(
                            (item) => !item.attributes.parentId,
                          )}
                          attributeSubGroups={attributeGroups
                            .filter((item) => isEmpty(item.relationships.children.data))
                            .concat(unionBy(attributeSubGroups, 'id'))}
                          attributes={attributes}
                          handleUpdateAttributeGroup={handleUpdateAttributeGroup}
                          attributeSetId={attrSetId}
                        />
                      </Collapse.Panel>
                    </Collapse>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </LayoutDetail>
      <CustomModal
        title="Tạo Attribute set"
        isOpen={openModal}
        onCloseModal={handleCloseAttrSetModal}
        onSave={handleCreateAttrSet}
      >
        <Form layout="vertical" form={formCreatAttrSet} onValuesChange={handleValuesChange(false)}>
          <Form.Item label="Attribute Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Attribute Code" name="code">
            <Input />
          </Form.Item>
          <Form.Item name="skuPrefix" label="Sku Prefix">
            <Input />
          </Form.Item>
        </Form>
      </CustomModal>
    </>
  );
};

export default AttrGroup;
