import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import { API_GET_SINGLE_PAGES, API_GET_SINGLE_PAGE_DETAIL } from 'constants/api/single-page.api';
import { defaultPagination } from 'constants/constants';
import { ISinglePage, ISinglePageAttribute } from 'interfaces/single-page.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';

const SinglePages = () => {
  const [reloadList, setReloadList] = useState(false);
  const [singlePages, setSinglePages] = useState<ISinglePage[]>([]);
  const [pagination, setPagination] = useState(defaultPagination);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    Promise.all([getPageData(API_GET_SINGLE_PAGES, dispatch)])
      .then(([resSinglePage]) => {
        const pagin = resSinglePage?.meta?.pagination;
        setPagination({
          current: pagin?.currentPage,
          pageSize: pagin?.perPage,
          total: pagin?.total,
        });
        setSinglePages(resSinglePage?.data);
        if (reloadList) {
          setReloadList(false);
        }
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, [reloadList]);

  const handleChangePage = useCallback(
    (page: number) => {
      setPagination({ ...pagination, current: page });
      setReloadList(true);
    },
    [pagination],
  );

  const handleDeletePage = useCallback(
    (pageId: string) => () => {
      deleteModal({
        title: 'Bạn có muốn xóa trang này?',
        onDeleteCb: async () => {
          try {
            await api.remove({ endpoint: API_GET_SINGLE_PAGE_DETAIL.replace('{id}', pageId) });
            setReloadList(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tiêu đề trang',
      dataIndex: 'attributes',
      render: (attr: ISinglePageAttribute, row: ISinglePage) => (
        <NavLink
          to={{
            pathname: `/trang-don/${row.id}`,
          }}
        >
          {attr?.title}
        </NavLink>
      ),
    },
    {
      title: 'Route path',
      dataIndex: 'attributes',
      render: (attr: ISinglePageAttribute) => {
        return `/trang/${attr?.slug}`;
      },
    },
    {
      title: 'Thao tác',
      render: (row: ISinglePage) => (
        <Button title="Xóa" icon={<DeleteOutlined />} onClick={handleDeletePage(row.id)} />
      ),
    },
  ];

  const handleAddNewPage = useCallback(() => {
    history.push('/trang-don/new');
  }, []);

  return (
    <div>
      <LayoutList
        pageTitle="Trang đơn"
        dataList={singlePages}
        showAddButton
        handleClickAddButton={handleAddNewPage}
        breadcrumbs={[
          {
            title: 'Trang đơn',
            route: '/trang-don',
          },
        ]}
        columns={columns}
        tableClass="product-table"
        topPagination
        pagination={pagination}
        loadingList={reloadList}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default SinglePages;
