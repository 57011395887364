import { IBrand } from 'interfaces/brand.interface';
import { IImage } from 'interfaces/image.interface';
import { IOs } from 'interfaces/os.interface';
import { IPrdCategory } from 'interfaces/product-category.interface';
import { IProduct } from 'interfaces/product.interface';
import { IStatus } from 'interfaces/status.interface';
import { ISystemColor } from 'interfaces/system-color.interface';

import {
  SET_BRANDS,
  SET_CATEGORIES,
  SET_OS,
  SET_PRODUCTS,
  SET_STATUSES,
  SET_PRODUCT_DETAIL,
  SET_OPEN_SELECT_PRODUCT_SECTION,
  SET_COLORS,
  SET_SUB_STATUSES,
  SET_NEW_IMAGE_LIST,
  SelectProductSectionInterface,
} from './types';

export const setBrands = (payload: IBrand[]) => ({
  type: SET_BRANDS,
  payload,
});

export const setCategories = (payload: IPrdCategory[]) => ({
  type: SET_CATEGORIES,
  payload,
});

export const setOses = (payload: IOs[]) => ({
  type: SET_OS,
  payload,
});

export const setProducts = (payload: IProduct[]) => ({
  type: SET_PRODUCTS,
  payload,
});

export const setStatuses = (payload: IStatus[]) => ({
  type: SET_STATUSES,
  payload,
});

export const setProductDetail = (payload: IProduct) => ({
  type: SET_PRODUCT_DETAIL,
  payload,
});

export const setOpenSelectProductSection = (payload: SelectProductSectionInterface) => ({
  type: SET_OPEN_SELECT_PRODUCT_SECTION,
  payload,
});

export const setColors = (payload: ISystemColor[]) => ({
  type: SET_COLORS,
  payload,
});

export const setSubStatuses = (payload: IStatus[]) => ({
  type: SET_SUB_STATUSES,
  payload,
});

export const setNewImageList = (payload: IImage[]) => ({
  type: SET_NEW_IMAGE_LIST,
  payload,
});
