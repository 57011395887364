import { Form } from 'antd';
import React from 'react';

// Hook
import { useRiderPresentBlocks } from 'hooks/useRiderPresentBlocks';

// Components
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import FormSinglePage from './components/Form/FormSinglePage';
import SinglePageList from './components/List/SinglePageList';

// Styles
import './SinglePagePresent.scss';

const RiderSinglePage: React.FC = () => {
  // Hook: Rider present blocks
  const {
    isOpen,
    block,
    reloadList,
    handleOpenModalBlock,
    handleSaveBlock,
    handleClosePopup,
    handleUploadFileContent,
    handleDeleteImage,
    handleChangeValues,
  } = useRiderPresentBlocks();

  // Form
  const [form] = Form.useForm();

  return (
    <>
      <LayoutDetail
        pageTitle="Trình bày trang Rider - Trang đơn"
        breadcrumbs={[
          {
            title: 'Trình bày trang Rider - Trang đơn',
            route: '/trinh-bay-rider-trang-don',
          },
        ]}
        isAddNew
        wrapperCard={false}
        handleValuesChange={null}
        formHook={null}
        pageClassName="rider-single-page-present"
      >
        {/* List */}
        <SinglePageList onOpenModalBlock={handleOpenModalBlock} />
      </LayoutDetail>

      {/* Pop up */}
      <CustomPopup
        title="Display Block"
        isOpen={isOpen}
        onCloseModal={handleClosePopup}
        handleSaveButton={handleSaveBlock}
        formHook={form}
        handleValuesChange={handleChangeValues}
        isSaving={reloadList}
      >
        {/* Form */}
        <FormSinglePage
          block={block}
          handleUploadFileContent={handleUploadFileContent}
          handleDeleteImage={handleDeleteImage}
        />
      </CustomPopup>
    </>
  );
};

export default RiderSinglePage;
