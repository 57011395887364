import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { IHomeBlock } from 'interfaces/home-block.interface';
import React, { useCallback } from 'react';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { CouponPresentPageTemplates, RiderCouponPresentPageTemplates } from 'constants/enum';
import { CouponsTemplate } from './CouponsTemplate';
import { MainSliderTemplate } from './MainSliderTemplate';
import { SEOTemplate } from './SEOTemplate';

type Props = {
  isOpen: boolean;
  isSaving: boolean;
  data: IHomeBlock;
  onClose: () => void;
  onSave: () => void;
  onAttributesChange: (newAttributes: IHomeBlock['attributes']) => void;
};

export const DetailBlockModal: React.FC<Props> = (props) => {
  const [form] = useForm();
  const { isOpen, isSaving, data, onClose, onSave, onAttributesChange } = props;

  const { attributes } = data || {};

  const renderTemplate = useCallback((): React.ReactNode => {
    if (
      attributes?.template === CouponPresentPageTemplates.seo ||
      attributes?.template === RiderCouponPresentPageTemplates.seo
    ) {
      return <SEOTemplate blockAttributes={attributes} onChange={onAttributesChange} />;
    }

    if (
      attributes?.template === CouponPresentPageTemplates.mainSlider ||
      attributes?.template === RiderCouponPresentPageTemplates.mainSlider
    ) {
      return (
        <MainSliderTemplate
          blockId={data?.attributes?._id?.toString()}
          blockAttributes={attributes}
          onChange={onAttributesChange}
        />
      );
    }

    if (
      attributes?.template === CouponPresentPageTemplates.coupons ||
      attributes?.template === RiderCouponPresentPageTemplates.coupons
    ) {
      return <CouponsTemplate blockAttributes={attributes} onChange={onAttributesChange} />;
    }
  }, [attributes, onAttributesChange]);

  return (
    <CustomPopup
      isOpen={isOpen}
      title="Display Block"
      onCloseModal={onClose}
      handleSaveButton={onSave}
      formHook={form}
      containerCls="coupon-present__detail-block-modal"
      isSaving={isSaving}
    >
      <>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Name">
              <div>{attributes?.name}</div>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Position">
              <div>{attributes?.position}</div>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Template">
              <div>{attributes?.template}</div>
            </Form.Item>
          </Col>
          <Col span={24}>{renderTemplate()}</Col>
        </Row>
      </>
    </CustomPopup>
  );
};
