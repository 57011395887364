export const SETTINGS = {
  boxed: false, // boolean
  layout: 'vertical', // horizontal | vertical
  sidebarBg: '#323042', // string
  sidebarBg2: '#000000', // string
  sidebarColor: '#ffffff', // string
  sidebarAccentColor: '#bd0000', // string
  sidebarAccentContrastColor: '#ffffff', // string
  sidebarOpened: false, // boolean
  topbarBg: '#ffffff', // string
  topbarColor: '#4a505c', // string
};
