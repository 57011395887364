import { fetcherWithArg } from 'services/swr.utils';
import useSWRMutation from 'swr/mutation';
import { API_GET_PRODUCTS } from 'constants/api/product.api';

export const useFetchProducts = () => {
  const { isMutating, data, trigger, reset } = useSWRMutation(API_GET_PRODUCTS, fetcherWithArg);

  return {
    isFetchingProducts: isMutating,
    products: data?.data || [],
    fetchProducts: trigger,
    reset,
  };
};
