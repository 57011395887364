import { Form, message } from 'antd';
import { useFetchProductCategories } from 'hooks/useFetchProductCategories';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';

import moment from 'moment';
import { useUpdateCoupon } from 'pages/CouponDetail/hooks';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsEditing } from 'redux/uiAction/actions';
import { confirmModal } from 'utils/modals';
import { CouponStatus, DateFormat, StoreIds } from 'constants/enum';
import ManageForm from './components/Form';
import ManageStatus from './components/Status';
import { useFetchCouponBirthday } from './hook';

import './index.styles.scss';

const ManageBirthday: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({});

  const { isFetching, data, refetch: refetchCoupon } = useFetchCouponBirthday();
  const { data: productCategories } = useFetchProductCategories();

  const { isUpdating, onUpdate } = useUpdateCoupon();

  const couponId = data?.id;

  const isCouponExpired = data?.attributes?.endDate
    ? moment(data?.attributes?.endDate, DateFormat.serverSideWithTime).isBefore(moment())
    : false;

  /**
   * A hook that sets the form fields values based on the data attributes when the data ID changes.
   *
   * @param {Object} data - The data object containing the attributes.
   */
  useEffect(() => {
    if (data?.id) {
      const { ...rest } = data.attributes || {};
      const _initialValues = {
        ...rest,
      };
      form.setFieldsValue(_initialValues);
      setInitialValues(_initialValues);
      dispatch(setIsEditing(false));
    }
  }, [data?.id]);

  // TODO: handle submit update api
  const onSubmitApiUpdate = async (payload) => {
    const newData = await onUpdate({
      method: 'put',
      id: couponId,
      data: queryString.parse(
        queryString.stringify(payload, { skipNull: true, skipEmptyString: true }),
        { parseBooleans: true },
      ),
    });

    await refetchCoupon(newData, {
      revalidate: false,
      rollbackOnError: true,
    });
    message.success('Cập nhật coupon thành công');

    // refresh coupon
    refetchCoupon();
  };

  /**
   * A callback function that saves a coupon by updating its data using the form values.
   *
   * @throws {Error} - An error that occurred during the coupon update.
   */
  const onSave = useCallback(async (): Promise<void> => {
    try {
      const values = form.getFieldsValue();
      const changedFields = Object.keys(values)
        .filter((fieldName) => values[fieldName] !== initialValues[fieldName])
        .reduce((prev, curr) => ({ ...prev, [curr]: values[curr] }), {} as any);

      const { prefixCode: _prefixCode, hasOverridePromotion, ...rest } = changedFields || {};

      const prefixCode = _prefixCode ? _prefixCode.toUpperCase() : null;

      const payload = {
        ...rest,
        prefixCode: prefixCode?.toUpperCase(),
        hasOverridePromotion: Boolean(hasOverridePromotion),
        storeIds: [StoreIds.mainguyen, StoreIds.rider],
      };
      onSubmitApiUpdate(payload);
    } catch (error: any) {
      if (error?.prefixCode?.length) {
        message.error('Prefix Code đã được sử dụng');
        return;
      }
      if (error?.startDate?.length) {
        message.error('Ngày bắt đầu phải sau hoặc bằng ngày hôm nay');
        return;
      }
      const msg = error?.message || error;
      message.error(msg);
    } finally {
      dispatch(setIsEditing(false));
    }
  }, [couponId, initialValues]);

  /**
   * A callback function that saves a coupon by updating its data using the form values.
   *
   * @throws {Error} - An error that occurred during the coupon update.
   */
  const onUpdateStatus = useCallback(
    async (status: CouponStatus): Promise<void> => {
      if (isUpdatingStatus) return;
      form.validateFields().then(async (values) => {
        try {
          setIsUpdatingStatus(true);

          const changedFields = Object.keys(values)
            .filter((fieldName) => values[fieldName] !== initialValues[fieldName])
            .reduce((prev, curr) => ({ ...prev, [curr]: values[curr] }), {} as any);

          const { prefixCode: _prefixCode, hasOverridePromotion, ...rest } = changedFields || {};

          const prefixCode = _prefixCode ? _prefixCode.toUpperCase() : null;

          const payload = {
            ...rest,
            prefixCode: prefixCode?.toUpperCase(),
            status,
            hasOverridePromotion: Boolean(hasOverridePromotion),
          };
          onSubmitApiUpdate(payload);
        } catch (error: any) {
          if (error?.prefixCode?.length) {
            message.error('Prefix Code đã được sử dụng');
            return;
          }
          const msg = error?.message || error;
          message.error(msg);
        } finally {
          setIsUpdatingStatus(false);
          dispatch(setIsEditing(false));
        }
      });
    },
    [couponId, isUpdatingStatus, initialValues],
  );

  if (isFetching || !data) return null;

  return (
    <LayoutDetail
      pageTitle="Quản lý chương trình sinh nhật"
      isAddNew={false}
      pageClassName="manage-birthday-page"
      breadcrumbs={[
        {
          title: 'Quản lý chương trình sinh nhật',
          route: '/quan-ly-coupon-sinh-nhat',
        },
      ]}
      formHook={form}
      handleSave={async () => {
        const discountUnit = form.getFieldValue('discountUnit');
        const discount = form.getFieldValue('discount');

        if (
          discountUnit != data?.attributes?.discountUnit ||
          discount != data?.attributes?.discount
        ) {
          confirmModal({
            title: 'Chắc chắn là bạn muốn thay đổi giá trị giảm giá?',
            buttonText: 'Có',
            onSaveCb: async () => await onSave(),
          });
          return;
        }

        await onSave();
      }}
      saveLoading={isUpdating}
    >
      <ManageStatus
        data={data}
        isUpdatingStatus={isUpdatingStatus}
        isCouponExpired={isCouponExpired}
        onUpdateStatus={onUpdateStatus}
      />

      <ManageForm
        data={data}
        isCouponExpired={isCouponExpired}
        form={form}
        productCategories={productCategories}
        refetchCoupon={refetchCoupon}
      />
    </LayoutDetail>
  );
};

export default ManageBirthday;
