import { IStatusAttributes } from 'interfaces/status.interface';

export class StatusModel implements IStatusAttributes {
  name?: string;

  active?: boolean;

  parentId?: number;

  code?: string;

  constructor({ name, active, parentId, code }: IStatusAttributes) {
    this.name = name || '';
    this.active = active !== undefined && active !== null ? active : true;
    this.parentId = parentId || null;
    this.code = code;
  }
}
