import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Row, Form, Input, Select, Switch, Radio, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RcFile } from 'antd/lib/upload';

import { clone, sortBy } from 'lodash';
import qs from 'qs';
import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  API_GET_POST_ATTRIBUTES,
  API_GET_ATTRIBUTE_TYPES,
  API_GET_ATTRIBUTE_VALIDATIONS,
  API_PUT_DELETE_ATTRIBUTE,
  API_GET_ATTRIBUTE_OPTIONS,
  API_POST_ATTRIBUTE_OPTION,
  API_UPDATE_DELETE_ATTRIBUTE_OPTION,
  API_RESTORE_DELETED_ATTRIBUTE,
  API_GET_ATTRIBUTE_DETAIL,
} from 'constants/api/attribute.api';
import { defaultPagination } from 'constants/constants';
import { AlignType } from 'constants/enum';
import { IAttributeOption, IAttrAttributeOption } from 'interfaces/attr-option.interface';
import { IAttribute, IAttrAttribute } from 'interfaces/attribute.interface';
import { IImage } from 'interfaces/image.interface';
import { IPagination } from 'interfaces/pagination.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import { AttributeOptionModel } from 'models/attribute-option.mode';
import { AttributeModel } from 'models/attribute.model';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomModal } from 'ui/components/MaiNguyen/CustomModal';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { deleteModal } from 'utils/modals';
import { filterInt, showErrorMsg, uploadFile } from 'utils/utils';

import './Attributes.scss';

const newAttribute = new AttributeModel({});
const newAttrOption = new AttributeOptionModel({});

const Attributes = () => {
  const [reloadList, setReloadList] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openModalAttrOption, setOpenModalAttrOption] = useState(false);
  const [attrData, setAttrData] = useState<IAttribute[]>([]);
  const [attrTypes, setAttrTypes] = useState<string[]>([]);
  const [attrValidations, setAttrValidations] = useState<string[]>([]);
  const [attribute, setAttribute] = useState(newAttribute);
  const [attributeId, setAttributeId] = useState('');
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [isDeleteable, setIsDeleteable] = useState(false);
  const [attributeOptions, setAttributeOptions] = useState<IAttributeOption[]>([]);
  const [attributeOption, setAttributeOption] = useState<IAttrAttributeOption>(newAttrOption);
  const [attributeOptionId, setAtributeOptionId] = useState('');
  const [filterAttribute, setFilterAttribute] = useState('');
  const [filterDeleted, setFilterDeleted] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [allAttrData, setAllAttrData] = useState<IAttribute[]>([]);
  const [attributeType, setAttributeType] = useState('');
  const [imageFile, setImageFile] = useState<RcFile>(null);
  const [previewImage, setPreviewImage] = useState<IImage>(null);

  const [form] = Form.useForm();
  const [formAttrOption] = Form.useForm();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const getAttributeDetail = async (attrId: string) => {
    return await api
      .get({ endpoint: API_GET_ATTRIBUTE_DETAIL.replace('{id}', attrId) })
      .then((res) => res?.data);
  };

  const onEditAttr = async (attr: IAttribute) => {
    const { attributes, id } = attr;
    setIsOpen(true);
    const currentAttr = new AttributeModel(attributes);
    setAttribute(currentAttr);
    setAttributeId(id);
    setPreviewImage(attr?.attributes?.image);
    form.setFieldsValue(currentAttr);
    setIsDeleteable(attr.attributes.isUserDefined);
    if (attributes.type === 'select' || attributes.type === 'multiple_select') {
      await api
        .get({ endpoint: `${API_GET_ATTRIBUTE_OPTIONS.replace('{id}', id)}?perPage=all` })
        .then((res) => {
          setAttributeOptions(res?.data);
        });
    } else if (attributes.type === 'filter_range') {
      await api
        .get({ endpoint: `${API_GET_ATTRIBUTE_OPTIONS.replace('{id}', id)}?perPage=all` })
        .then((res) => {
          setAttributeOptions(res?.data);
        });
      await api
        .get({ endpoint: `${API_GET_POST_ATTRIBUTES}?perPage=all&type=text` })
        .then((res) => {
          setAllAttrData(
            res?.data?.filter(
              (item) =>
                item.attributes.validation === 'number' || item.attributes.validation === 'decimal',
            ),
          );
        });
    }
  };

  useEffect(() => {
    const { search } = location;
    const parseQs: any = qs.parse(search, { ignoreQueryPrefix: true });
    if (parseQs?.attributeId) {
      setAttributeId(parseQs.attributeId);
      getAttributeDetail(parseQs.attributeId).then((res) => {
        onEditAttr(res);
      });
    }
  }, []);

  useEffect(() => {
    getPageData(`${API_GET_POST_ATTRIBUTES}?page=1`, dispatch)
      .then((res) => {
        const { pagination: pagin } = res?.meta;
        setAttrData(res?.data);
        setPagination({
          current: pagin?.currentPage,
          pageSize: pagin?.perPage,
          total: pagin?.total,
        });
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, []);

  useEffect(() => {
    if (reloadList) {
      const filterByName = filterAttribute ? `&name=${filterAttribute}` : '';
      api
        .get({
          endpoint: `${API_GET_POST_ATTRIBUTES}?page=${
            pagination.current
          }${filterByName}&isDeleted=${filterDeleted ? 1 : 0}`,
        })
        .then((res) => {
          const { pagination: pagin } = res?.meta;
          setAttrData(res?.data);
          setPagination({
            current: pagin?.currentPage,
            pageSize: pagin?.perPage,
            total: pagin?.total,
          });
          setReloadList(false);
          setIsDeleted(filterDeleted);
        })
        .catch((err) => {
          setReloadList(false);
          setIsDeleted(filterDeleted);
          showErrorMsg(err);
        });
    }
  }, [reloadList, filterAttribute, filterDeleted, pagination]);

  useEffect(() => {
    Promise.all([
      api.get({ endpoint: `${API_GET_ATTRIBUTE_TYPES}?perPage=all` }),
      api.get({ endpoint: `${API_GET_ATTRIBUTE_VALIDATIONS}?perPage=all` }),
    ])
      .then(([attrTypeRes, attrValidateRes]) => {
        setAttrTypes(attrTypeRes?.data);
        setAttrValidations(attrValidateRes?.data);
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, []);

  useEffect(() => {
    if (attributeType === 'filter_range') {
      api.get({ endpoint: `${API_GET_POST_ATTRIBUTES}?perPage=all&type=text` }).then((res) => {
        setAllAttrData(
          res?.data?.filter(
            (item) =>
              item.attributes.validation === 'number' || item.attributes.validation === 'decimal',
          ),
        );
      });
    }
  }, [attributeType]);

  const handleAddAttribute = useCallback(() => {
    setIsOpen(true);
    setAttributeId('');
    setAttributeOptions([]);
    setAttribute(newAttribute);
    setIsDeleteable(false);
    form.resetFields();
  }, []);

  const handleClosePopup = useCallback(() => {
    const { search } = location;
    if (search) {
      history.replace('/thuoc-tinh-san-pham');
      setTimeout(() => {
        setIsOpen(false);
      }, 100);
    } else {
      setIsOpen(false);
    }
  }, []);

  const handleEditAttr = useCallback(
    (rowData: IAttribute) => async () => {
      onEditAttr(rowData);
    },
    [onEditAttr],
  );

  const handleValueChanges = useCallback(async (_, values) => {
    const currentAttr = new AttributeModel(values);
    setAttributeType(currentAttr.type);
    form.setFieldsValue(currentAttr);
    setAttribute(currentAttr);
  }, []);

  const handleSaveAttribute = useCallback(async () => {
    const { search } = location;
    try {
      if (attributeId) {
        await api.put({
          endpoint: API_PUT_DELETE_ATTRIBUTE.replace('{id}', attributeId),
          data: attribute,
        });
        await uploadFile(imageFile, 'attribute', attributeId, '', '0').then((res) => {
          setPreviewImage({ ...res?.resize, original: res?.originUrl, id: res?.id });
        });
        attributeOptions.forEach(async (item) => {
          if (!isNaN(filterInt(item.id))) {
            await api
              .put({
                endpoint: API_UPDATE_DELETE_ATTRIBUTE_OPTION.replace('{id}', item.id),
                data: item.attributes,
              })
              .then()
              .catch((err) => {
                showErrorMsg(err);
              });
          } else {
            await api
              .post({
                endpoint: API_POST_ATTRIBUTE_OPTION,
                data: { ...item.attributes, attributeId: parseInt(attributeId) },
              })
              .then()
              .catch((err) => {
                showErrorMsg(err);
              });
          }
        });
        if (search) {
          history.replace('/thuoc-tinh-san-pham');
          setTimeout(() => {
            setIsOpen(false);
          }, 100);
        } else {
          setIsOpen(false);
        }
        setReloadList(true);
      } else {
        await api.post({ endpoint: API_GET_POST_ATTRIBUTES, data: attribute }).then(async (res) => {
          await uploadFile(imageFile, 'attribute', res?.data?.id, '', '0').then((attr) => {
            setPreviewImage({ ...attr?.resize, id: attr?.id });
          });
          attributeOptions.forEach(async (item) => {
            await api
              .post({
                endpoint: API_POST_ATTRIBUTE_OPTION,
                data: { ...item.attributes, attributeId: parseInt(res?.data.id) },
              })
              .then()
              .catch((err) => {
                showErrorMsg(err);
              });
          });
        });
        if (search) {
          history.replace('/thuoc-tinh-san-pham');
          setTimeout(() => {
            setIsOpen(false);
          }, 100);
        } else {
          setIsOpen(false);
        }
        setReloadList(true);
      }
    } catch (err) {
      showErrorMsg(err);
    }
  }, [attributeId, attribute, attributeOptions, imageFile]);

  const handleChangePage = useCallback(
    (page: number) => {
      setPagination({ ...pagination, current: page });
      setReloadList(true);
    },
    [pagination],
  );

  const handleFilterAttributes = useCallback(() => {
    handleChangePage(1);
  }, [handleChangePage]);

  const onDeleteAttribute = useCallback(
    (attrId: string) => {
      deleteModal({
        title: 'Bạn có muốn xóa thuộc tính này?',
        onDeleteCb: async () => {
          try {
            await api.remove({
              endpoint: API_PUT_DELETE_ATTRIBUTE.replace('{id}', attrId),
            });
            handleChangePage(pagination.current);
            setReloadList(true);
            setIsOpen(false);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [handleChangePage, pagination],
  );

  const handleDeleteAttribute = useCallback(
    (rowData: IAttribute) => () => {
      onDeleteAttribute(rowData.id);
    },
    [onDeleteAttribute],
  );

  const handleDeleteAttributeInside = useCallback(() => {
    onDeleteAttribute(attributeId);
  }, [onDeleteAttribute, attributeId]);

  const handleRestoreDeletedAttr = useCallback(
    (rowData: IAttribute) => async () => {
      try {
        const deletedAttr = new AttributeModel(rowData.attributes);
        await api.patch({
          endpoint: API_RESTORE_DELETED_ATTRIBUTE.replace('{id}', rowData.id),
          data: deletedAttr,
        });
        handleChangePage(pagination.current);
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [],
  );

  const handleClearFilter = useCallback(() => {
    setFilterAttribute('');
    setFilterDeleted(false);
    handleFilterAttributes();
  }, []);

  const handleChangeNameAttributeFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilterAttribute(e.target.value);
  }, []);

  const handleSelectDeletedList = useCallback((e: CheckboxChangeEvent) => {
    setFilterDeleted(e.target.checked);
  }, []);

  const handlePressEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleFilterAttributes();
      }
    },
    [handleFilterAttributes],
  );

  const handleOpenAddAttributeOptionsModal = useCallback(() => {
    setOpenModalAttrOption(true);
    setAttributeOption({});
    setAtributeOptionId('');
    formAttrOption.resetFields();
  }, []);

  const handleCloseAddAttributeOptionsModal = useCallback(() => {
    setOpenModalAttrOption(false);
  }, []);

  const handleEditAttributeOption = useCallback(
    (rowData: IAttributeOption) => () => {
      const currentAttrOption = new AttributeOptionModel(rowData.attributes);
      formAttrOption.setFieldsValue(currentAttrOption);
      setAttributeOption(currentAttrOption);
      setOpenModalAttrOption(true);
      setAtributeOptionId(rowData.id);
    },
    [],
  );

  const handleAddAttributeOptions = useCallback(() => {
    const currentAttrOption = new AttributeOptionModel(attributeOption);
    const clonedAttrOptions = clone(attributeOptions);
    if (attributeOptionId) {
      const attrOption = clonedAttrOptions.find((item) => item.id === attributeOptionId);
      const attrOptionIndex = clonedAttrOptions.indexOf(attrOption);
      if (attrOptionIndex > -1) {
        clonedAttrOptions.splice(attrOptionIndex, 1, {
          ...attrOption,
          attributes: attributeOption,
        });
      }
    } else {
      clonedAttrOptions.push({
        id: uuidv4(),
        type: 'attributeOptions',
        attributes: currentAttrOption,
      });
    }
    setAttributeOptions(sortBy(clonedAttrOptions, 'attributes.position'));
    handleCloseAddAttributeOptionsModal();
  }, [attributeOptions, attributeOption, attributeOptionId, handleCloseAddAttributeOptionsModal]);

  const handleDeleteAttributeOption = useCallback(
    (rowData: IAttributeOption) => async () => {
      const clonedAttrOptions = clone(attributeOptions);
      if (!isNaN(filterInt(rowData.id))) {
        await api
          .remove({ endpoint: API_UPDATE_DELETE_ATTRIBUTE_OPTION.replace('{id}', rowData.id) })
          .then(() => {
            const currentAttrOptionIndex = clonedAttrOptions.findIndex(
              (item) => item.id === rowData.id,
            );
            if (currentAttrOptionIndex > -1) {
              clonedAttrOptions.splice(currentAttrOptionIndex, 1);
            }
          });
      } else {
        const currentAttrOptionIndex = clonedAttrOptions.findIndex(
          (item) => item.id === rowData.id,
        );
        if (currentAttrOptionIndex > -1) {
          clonedAttrOptions.splice(currentAttrOptionIndex, 1);
        }
      }
      setAttributeOptions(clonedAttrOptions);
    },
    [attributeOptions],
  );

  const handleUploadImage = useCallback((file: RcFile) => {
    setImageFile(file);
  }, []);

  const handleDeleteImage = () => {
    setReloadList(true);
    setPreviewImage(null);
  };

  const handleChangeAttrOption = useCallback(
    (_, values) => {
      const currentAttrOption = new AttributeOptionModel({ ...attributeOption, ...values });
      currentAttrOption.value = currentAttrOption.value.trim();
      currentAttrOption.position = parseInt(currentAttrOption.position.toString());
      setAttributeOption(currentAttrOption);
    },
    [attributeOption],
  );

  const handleChangeActiveStatus = useCallback(
    (rowData: IAttribute) => async (checked: boolean) => {
      const clonedAttribute = clone(attrData);
      const currentProduct = new AttributeModel(rowData.attributes);
      currentProduct.active = checked;
      const product = clonedAttribute.find((item) => item.id === rowData.id);
      const productIndex = clonedAttribute.indexOf(product);

      try {
        await api.put({
          endpoint: API_GET_ATTRIBUTE_DETAIL.replace('{id}', rowData.id),
          data: currentProduct,
        });
        clonedAttribute.splice(productIndex, 1, { ...product, attributes: currentProduct });
        setAttrData(clonedAttribute);
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [attribute, attrData],
  );

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Code',
      dataIndex: 'attributes',
      render: (attr: IAttrAttribute, rowData: IAttribute) => (
        <div className="attr-col" onClick={handleEditAttr(rowData)}>
          {attr.code}
        </div>
      ),
    },
    {
      title: 'Admin label',
      dataIndex: 'attributes',
      render: (attr: IAttrAttribute, rowData: IAttribute) => (
        <div className="attr-col" onClick={handleEditAttr(rowData)}>
          {attr.adminLabel}
        </div>
      ),
    },
    {
      title: 'FrontStore label',
      dataIndex: 'attributes',
      render: (attr: IAttrAttribute, rowData: IAttribute) => (
        <div className="attr-col" onClick={handleEditAttr(rowData)}>
          {attr.showLabel}
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'attributes',
      render: (attr: IAttrAttribute) => attr.type,
    },
    {
      title: 'Hiển thị',
      dataIndex: 'attributes',
      render: (attr: IAttrAttribute, row: IAttribute) => {
        return (
          <Switch
            checked={attr.active}
            onChange={handleChangeActiveStatus(row)}
            disabled={isDeleted}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      align: AlignType.right,
      render: (rowData: IAttribute) =>
        rowData.attributes.isUserDefined &&
        (isDeleted ? (
          <Button
            title="Khôi phục"
            icon={<PlusOutlined />}
            onClick={handleRestoreDeletedAttr(rowData)}
          />
        ) : (
          <Button title="Xóa" icon={<DeleteOutlined />} onClick={handleDeleteAttribute(rowData)} />
        )),
    },
  ];

  const attributeOptionColumns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'attributes',
      render: (attr: IAttrAttributeOption, row: IAttributeOption) => (
        <div className="attr-col" onClick={handleEditAttributeOption(row)}>
          {attr.name}
        </div>
      ),
    },
    {
      title: 'Value',
      dataIndex: 'attributes',
      render: (attr: IAttrAttributeOption) => attr.value,
    },
    {
      title: 'Vị trí',
      dataIndex: 'attributes',
      render: (attr: IAttrAttributeOption) => attr.position,
    },
    {
      title: '',
      dataIndex: '',
      align: AlignType.right,
      render: (rowData: IAttributeOption) => (
        <Button
          title="Xóa"
          icon={<DeleteOutlined />}
          onClick={handleDeleteAttributeOption(rowData)}
        />
      ),
    },
  ];

  return (
    <>
      <LayoutList
        pageTitle="Thuộc tính sản phẩm"
        breadcrumbs={[
          {
            title: 'Thuộc tính sản phẩm',
            route: '/thuoc-tinh-san-pham',
          },
        ]}
        showAddButton
        handleClickAddButton={handleAddAttribute}
        columns={columns}
        dataList={attrData}
        tableClass="attributes-page"
        pagination={pagination}
        handleChangePage={handleChangePage}
        loadingList={reloadList}
        topPagination
        filterNode={
          <Row gutter={16}>
            <Col span={16}>
              <Input
                placeholder="Nhập tên hoặc code"
                value={filterAttribute}
                onChange={handleChangeNameAttributeFilter}
                onKeyUp={handlePressEnter}
              />
            </Col>
            <Col span={3}>
              <div>
                <strong>Đã bị xóa</strong>
              </div>
              <Checkbox checked={filterDeleted} onChange={handleSelectDeletedList} />
            </Col>
            <Col span={2}>
              <Button className="secondary-button" onClick={handleFilterAttributes}>
                Filter
              </Button>
            </Col>
            <Col span={2} className="ml-3">
              <Button onClick={handleClearFilter}>Clear</Button>
            </Col>
          </Row>
        }
      />
      <CustomPopup
        handleSaveButton={handleSaveAttribute}
        title="Thông tin thuộc tính"
        isOpen={isOpen}
        onCloseModal={handleClosePopup}
        formHook={form}
        handleValuesChange={handleValueChanges}
        showDeleteButton={isDeleteable && !isDeleted}
        handleDeleteButton={handleDeleteAttributeInside}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Attribute Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập code cho thuộc tính',
                },
              ]}
            >
              {!attributeId ? (
                <Input />
              ) : (
                <div style={{ height: 48, display: 'flex', alignItems: 'center' }}>
                  {attribute.code}
                </div>
              )}
            </Form.Item>
            <Form.Item
              label="Attribute Label (Admin)"
              name="adminLabel"
              rules={[{ required: true, message: 'Vui lòng nhập admin label cho thuộc tính' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Attribute Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn type cho thuộc tính',
                },
              ]}
            >
              <Select size="large" showSearch optionFilterProp="children">
                {attrTypes.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Attribute Label (FrontStore)"
              name="showLabel"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập FronStore label cho thuộc tính',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Input Validation" name="validation">
              <Select size="large" showSearch optionFilterProp="children" allowClear>
                {attrValidations.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Is Required" name="isRequired" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Hiển thị" name="active" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Is Featured (Thuộc tính tiêu biểu)"
              name="isFeatured"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          {(attribute.type === 'select' ||
            attribute.type === 'multiple_select' ||
            attribute.type === 'filter_color_palette' ||
            attribute.type === 'filter_range' ||
            attribute.type === 'size_group') && (
            <>
              <Col span={3}>
                <Form.Item label="Is Filterable" name="isFilterable" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Hidden Admin" name="isHiddenOnAdmin" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={12}>
            <Form.Item label="Mô tả (Là câu hướng dẫn sử dụng trong admin ui)" name="description">
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={`Default value ${attribute.type === 'size_group' ? '(Điền SizeGroupId)' : ''}`}
              name="defaultValue"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Configurable Type" name="isConfigurable">
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {attribute.type === 'filter_range' && (
            <Col span={12}>
              <Form.Item label="Áp dụng lọc cho thuộc tính nào?" name="attributeId">
                <Select
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  placeholder="Chọn attribute type select"
                >
                  {allAttrData.map((item) => (
                    <Select.Option key={item.id} value={parseInt(item.id)}>
                      {item.attributes.adminLabel}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <span className="note-text">
                **Chỉ chọn được thuộc tính có type là text và validation là number hoặc decimal
              </span>
            </Col>
          )}
          <Col span={24}>
            <Form.Item label="Attribute Icon (Tạo sản phẩm, simple - variant)">
              <UploadFile
                uploadText="Chọn hoặc kéo thả vào đây để upload (svg)"
                imageSize="original"
                previewImage={previewImage}
                handleUploadImage={handleUploadImage}
                handleDeleteImageSuccess={handleDeleteImage}
              />
            </Form.Item>
          </Col>
          {(attribute.type === 'select' ||
            attribute.type === 'multiple_select' ||
            attribute.type === 'filter_range') && (
            <>
              <Col span={24} className="mb-4">
                <Button onClick={handleOpenAddAttributeOptionsModal}>Thêm Attribute Option</Button>
              </Col>
              <Col span={24}>
                <Table
                  rowKey="id"
                  columns={attributeOptionColumns}
                  dataSource={attributeOptions}
                  pagination={false}
                />
              </Col>
            </>
          )}
        </Row>
      </CustomPopup>
      <CustomModal
        title="Attribute Option"
        isOpen={openModalAttrOption}
        onCloseModal={handleCloseAddAttributeOptionsModal}
        onSave={handleAddAttributeOptions}
      >
        <Form layout="vertical" form={formAttrOption} onValuesChange={handleChangeAttrOption}>
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Value" name="value">
            <Input />
          </Form.Item>
          <Form.Item label="Vị trí" name="position">
            <Input type="number" />
          </Form.Item>
        </Form>
      </CustomModal>
    </>
  );
};

export default Attributes;
