import { DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useCallback, useState } from 'react';
import TokenService from 'services/token.service';
import { downloadFile } from 'utils/utils';
import './index.styles.scss';

interface ButtonDownloadProps {
  buttonTitle?: string;
  urlDownload?: string;
  fileName?: string;
}

const tokenService = new TokenService();

const ButtonDownload: React.FC<ButtonDownloadProps> = ({
  buttonTitle = 'Export data',
  urlDownload,
  fileName,
}) => {
  const { accessToken } = tokenService.getAccessToken();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  // TODO: export data members to excel
  const handleExport = useCallback(() => {
    setIsDownloading(true);
    const xhr = new XMLHttpRequest();

    xhr.open('GET', urlDownload, true);
    xhr.setRequestHeader('authorization', `Bearer ${accessToken}`);
    xhr.responseType = 'blob';

    xhr.onload = () => {
      if (xhr.status === 200) {
        downloadFile(fileName, xhr.response);
      }

      setIsDownloading(false);
    };

    xhr.onerror = () => {
      message.error('Tải file về không thành công, vui lòng thử lại!', 3);
      setIsDownloading(false);
    };

    xhr.send();
  }, [accessToken, urlDownload, fileName]);

  return (
    <Button
      onClick={handleExport}
      className="btn-export"
      icon={<DownloadOutlined rev={''} />}
      loading={isDownloading}
    >
      {buttonTitle}
    </Button>
  );
};

export default ButtonDownload;
