/* eslint-disable camelcase */
import { Button, Col, Form, Input, Row, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { COUPON_STATUS_LIST, STORE_IDS } from 'constants/constants';
import { CouponStatus } from 'constants/enum';

export interface FilterCoupons {
  title: string;
  displayTitle: string;
  prefixCode: string;
  sku: string;
  status: string;
}

interface Props {
  onFilter: (filter: FilterCoupons) => void;
}

export const FilterView: React.FC<Props> = (props) => {
  const { onFilter } = props;
  const [form] = Form.useForm();

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (!isEmpty(params)) {
      form.setFieldsValue({
        ...params,
        storeId: params?.storeId ? Number(params.storeId) : null,
      });
    }
  }, [params]);

  const onClear = useCallback(() => {
    form.resetFields();
    onFilter({
      title: '',
      displayTitle: '',
      prefixCode: '',
      sku: '',
      status: '',
    } as FilterCoupons);
  }, [onFilter]);

  return (
    <Form layout="vertical" form={form} onFinish={onFilter}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Tiêu đề" name="title">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tiêu đề trình bày" name="displayTitle">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Prefix" name="prefixCode">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="SKU / Tên sản phẩm" name="sku">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tình trạng" name="status">
            <Select
              className="ant-select-lg"
              options={COUPON_STATUS_LIST.filter((c) => c.value !== CouponStatus.expired)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Cửa hàng" name="storeId">
            <Select className="ant-select-lg" options={STORE_IDS} />
          </Form.Item>
        </Col>
        <Col
          span={24}
          className="align-items-center justify-content-end"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button htmlType="submit" className="secondary-button">
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={onClear}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
