import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Row, Col, Switch } from 'antd';
import { clone } from 'lodash';
import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  API_GET_STATUS,
  API_GET_SUB_STATUS,
  API_POST_SUB_STATUS,
  API_PUT_DELETE_SUB_STATUS,
} from 'constants/api/status.api';
import { defaultPagination } from 'constants/constants';
import { AlignType } from 'constants/enum';
import { IPagination } from 'interfaces/pagination.interface';
import { IStatus, IStatusAttributes } from 'interfaces/status.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import { StatusModel } from 'models/status.model';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';

import './SubStatus.scss';

const SubStatus = () => {
  const newSubState = new StatusModel({});

  const [isOpen, setIsOpen] = useState(false);
  const [statuses, setStatuses] = useState<IStatus[]>([]);
  const [subStatuses, setSubStatuses] = useState<IStatus[]>([]);
  const [subStatus, setSubStatus] = useState(newSubState);
  const [subStatusId, setSubStatusId] = useState(null);
  const [reloadList, setReloadList] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [mainStatusCode, setMainStatusCode] = useState('');

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (firstLoad) {
      getPageData(`${API_GET_SUB_STATUS}?page=1`, dispatch).then((res) => {
        const pagin = res?.meta?.pagination;
        setSubStatuses(res?.data);
        setPagination({
          current: pagin?.currentPage,
          pageSize: pagin?.perPage,
          total: pagin?.total,
        });
        setFirstLoad(false);
      });
    }
  }, [firstLoad]);

  useEffect(() => {
    if (reloadList) {
      api.get({ endpoint: `${API_GET_SUB_STATUS}?page=${pagination.current}` }).then((res) => {
        setSubStatuses(res?.data);
        setReloadList(false);
      });
    }
  }, [reloadList, pagination]);

  useEffect(() => {
    api.get({ endpoint: `${API_GET_STATUS}?perPage=all` }).then((res) => {
      setStatuses(res?.data);
    });
  }, []);

  const handleOpenCloseModal = useCallback(
    (subSttRow: IStatus, mainStatus: IStatus) => () => {
      setIsOpen(!isOpen);
      const currentSubStatus = new StatusModel(subSttRow ? subSttRow?.attributes : newSubState);
      setSubStatusId(subSttRow?.id);
      setSubStatus(currentSubStatus);
      setMainStatusCode(mainStatus?.attributes?.code);
      form.setFieldsValue(currentSubStatus);
      dispatch(setIsEditing(false));
    },
    [isOpen],
  );

  const handleValuesChange = useCallback(
    (field, values) => {
      const fieldKey = Object.keys(field);
      const currentSubStatus = new StatusModel(values);
      switch (fieldKey[0]) {
        case 'code':
          currentSubStatus.code = values.code.toUpperCase().replaceAll(' ', '_');
          break;
        case 'parentId':
          const selectedMainStatus = statuses.find(
            (item) => parseInt(item.id) === currentSubStatus.parentId,
          );
          currentSubStatus.code = selectedMainStatus?.attributes?.code;
          setMainStatusCode(selectedMainStatus?.attributes?.code);
          break;
        default:
          break;
      }
      setSubStatus(currentSubStatus);
      form.setFieldsValue(currentSubStatus);
      dispatch(setIsEditing(true));
    },
    [mainStatusCode],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      const pagin = pagination;
      pagin.current = page;
      setPagination(pagin);
      setReloadList(true);
    },
    [pagination],
  );

  const handleSaveSubStatus = useCallback(async () => {
    try {
      if (subStatusId) {
        await api.put({
          endpoint: API_PUT_DELETE_SUB_STATUS.replace('{id}', subStatusId),
          data: subStatus,
        });
        setIsOpen(false);
        setReloadList(true);
        dispatch(setIsEditing(false));
      } else {
        await api.post({ endpoint: API_POST_SUB_STATUS, data: subStatus }).then((res) => {
          setSubStatusId(res?.data?.id);
        });
        setIsOpen(false);
        setReloadList(true);
        dispatch(setIsEditing(false));
      }
    } catch (err) {
      showErrorMsg(err);
    }
  }, [subStatus, subStatusId]);

  const handleDeleteSubStatus = useCallback(() => {
    deleteModal({
      title: `Bạn có muốn xóa trạng thái: <strong>${subStatus.name}</strong> không?`,
      onDeleteCb: async () => {
        await api.remove({
          endpoint: API_PUT_DELETE_SUB_STATUS.replace('{id}', subStatusId),
        });
        setIsOpen(false);
        setReloadList(true);
        dispatch(setIsEditing(false));
      },
    });
  }, [subStatus, subStatusId]);

  const handleDeleteSubStatusOutside = useCallback(
    (rowData: IStatus) => () => {
      deleteModal({
        title: `Bạn có muốn xóa trạng thái: <strong>${rowData.attributes.name}</strong> không?`,
        onDeleteCb: async () => {
          await api.remove({
            endpoint: API_PUT_DELETE_SUB_STATUS.replace('{id}', rowData.id),
          });
          setReloadList(true);
        },
      });
    },
    [],
  );

  const handleCheckDisplayOutside = useCallback(
    (rowData: IStatus) => async (checked: boolean) => {
      const { id, attributes } = rowData;
      const clonedSubStatuses: IStatus[] = clone(subStatuses);
      const currentSubStatus = new StatusModel(attributes);
      currentSubStatus.active = checked;
      try {
        const subStatusItem = clonedSubStatuses.find((item) => item.id === id);
        const brandIndex = clonedSubStatuses.findIndex((item) => item.id === id);
        if (brandIndex > -1) {
          await api.put({
            endpoint: API_PUT_DELETE_SUB_STATUS.replace('{id}', id),
            data: currentSubStatus,
          });
          subStatusItem.attributes.active = checked;
          clonedSubStatuses.splice(brandIndex, 1, subStatusItem);
          setSubStatuses(clonedSubStatuses);
        }
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [subStatuses],
  );

  const columns = [
    {
      title: 'STT',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Trạng thái chính',
      dataIndex: 'attributes',
      render: (attr: IStatusAttributes, row: IStatus) => {
        const mainStatus = statuses.find((item) => item.id === attr.parentId.toString());
        return (
          <span className="sub-status-item" onClick={handleOpenCloseModal(row, mainStatus)}>
            {mainStatus?.attributes?.name}
          </span>
        );
      },
    },
    {
      title: 'Trạng thái phụ',
      dataIndex: 'attributes',
      render: (attr: IStatusAttributes, row: IStatus) => {
        const mainStatus = statuses.find((item) => item.id === attr.parentId.toString());
        return (
          <span className="sub-status-item" onClick={handleOpenCloseModal(row, mainStatus)}>
            {attr.name}
          </span>
        );
      },
    },
    {
      title: 'Trạng thái phụ',
      dataIndex: 'attributes',
      render: (attr: IStatusAttributes, row: IStatus) => {
        const mainStatus = statuses.find((item) => item.id === attr.parentId.toString());
        return (
          <span className="sub-status-item" onClick={handleOpenCloseModal(row, mainStatus)}>
            {attr.code}
          </span>
        );
      },
    },
    {
      title: 'Hiển thị',
      dataIndex: 'attributes',
      render: (attr: IStatusAttributes, rowData: IStatus) => (
        <Switch checked={attr.active} onChange={handleCheckDisplayOutside(rowData)} />
      ),
    },
    {
      title: '',
      dataIndex: '',
      align: AlignType.right,
      render: (row: IStatus) => (
        <Button title="Xoá" icon={<DeleteOutlined />} onClick={handleDeleteSubStatusOutside(row)} />
      ),
    },
  ];

  return (
    <div className="sub-status-page">
      <LayoutList
        tableClass="sub-status-table"
        pageTitle="Trạng thái phụ"
        showAddButton
        handleClickAddButton={handleOpenCloseModal(null, null)}
        handleChangePage={handleChangePage}
        pagination={pagination}
        loadingList={reloadList}
        topPagination
        breadcrumbs={[
          {
            title: 'Trạng thái phụ',
            route: '/trang-thai-phu',
          },
        ]}
        columns={columns}
        dataList={subStatuses}
      />
      <CustomPopup
        isOpen={isOpen}
        title="Thông tin trạng thái phụ"
        onCloseModal={handleOpenCloseModal(null, null)}
        handleSaveButton={handleSaveSubStatus}
        handleDeleteButton={handleDeleteSubStatus}
        handleValuesChange={handleValuesChange}
        formHook={form}
        showDeleteButton={!!subStatusId}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Trạng thái chính"
              name="parentId"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn trạng thái chính',
                },
              ]}
            >
              <Select className="ant-select-lg">
                {statuses.map((item) => (
                  <Select.Option key={item.id} value={parseInt(item.id)}>
                    {item.attributes.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Trạng thái chính (Code)">
              <div>{mainStatusCode}</div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tên trạng thái phụ"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền tên trạng thái phụ',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Trạng thái phụ (Code)"
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền code trạng thái phụ',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Hiển thị" name="active" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </CustomPopup>
    </div>
  );
};

export default SubStatus;
