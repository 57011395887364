import { Spin } from 'antd';
import './index.styles.scss';

interface LoadingProps {
  isLoading: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ isLoading }) => {
  return (
    <div className={`mn-loading text-center ${isLoading ? 'show' : ''}`}>
      <Spin />
    </div>
  );
};
