import { IBrand } from 'interfaces/brand.interface';
import { IImage } from 'interfaces/image.interface';
import { IOs } from 'interfaces/os.interface';
import { IPrdCategory } from 'interfaces/product-category.interface';
import { IProduct } from 'interfaces/product.interface';
import { IStatus } from 'interfaces/status.interface';
import { ISystemColor } from 'interfaces/system-color.interface';

export const SET_BRANDS = 'SET_BRANDS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_OS = 'SET_OS';
export const SET_STATUSES = 'SET_STATUSES';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL';
export const SET_OPEN_SELECT_PRODUCT_SECTION = 'SET_OPEN_SELECT_PRODUCT_SECTION';
export const SET_COLORS = 'SET_COLORS';
export const SET_SUB_STATUSES = 'SET_SUB_STATUSES';
export const SET_NEW_IMAGE_LIST = 'SET_NEW_IMAGE_LIST';

export interface SelectProductSectionInterface {
  title: string;
  productGroupKey: string;
}

interface SetBrandsInterface {
  type: typeof SET_BRANDS;
  payload: IBrand[];
}

interface SetCategoriesInterface {
  type: typeof SET_CATEGORIES;
  payload: IPrdCategory[];
}

interface SetOsInterface {
  type: typeof SET_OS;
  payload: IOs[];
}

interface SetStatusesInterface {
  type: typeof SET_STATUSES;
  payload: IStatus[];
}

interface SetProductsInterface {
  type: typeof SET_PRODUCTS;
  payload: IProduct[];
}

interface SetProductDetailInterface {
  type: typeof SET_PRODUCT_DETAIL;
  payload: IProduct;
}

interface SetOpenSelectProductSection {
  type: typeof SET_OPEN_SELECT_PRODUCT_SECTION;
  payload: SelectProductSectionInterface;
}

interface SetColorsInterface {
  type: typeof SET_COLORS;
  payload: ISystemColor[];
}

interface SetSubStatusesInterface {
  type: typeof SET_SUB_STATUSES;
  payload: IStatus[];
}

interface SetNewImageList {
  type: typeof SET_NEW_IMAGE_LIST;
  payload: IImage[];
}

export type ActionProductType =
  | SetBrandsInterface
  | SetCategoriesInterface
  | SetOsInterface
  | SetStatusesInterface
  | SetProductsInterface
  | SetProductDetailInterface
  | SetOpenSelectProductSection
  | SetColorsInterface
  | SetSubStatusesInterface
  | SetNewImageList;
