import { ISizeGroup, ISizeGroupAttr } from 'interfaces/size.interface';

export class SizeGroupModel implements ISizeGroup {
  id?: string;

  type?: string;

  attributes?: SizeGroupAttrModel;

  constructor({ id, type, attributes }: ISizeGroup) {
    this.id = id || '';
    this.type = type || '';
    this.attributes = attributes || {
      title: '',
      slug: '',
      content: '',
      active: false,
      fields: [],
    };
  }
}

export class SizeGroupAttrModel implements ISizeGroupAttr {
  title?: string;

  content?: string;

  active?: boolean;

  slug?: string;

  fields?: any[];

  constructor({ title, content, active, slug, fields }: ISizeGroupAttr) {
    this.title = title || '';
    this.slug = slug || '';
    this.content = content || '';
    this.active = active !== undefined && active !== null ? active : false;
    this.fields = fields || [];
  }
}
