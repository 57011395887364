/* eslint-disable camelcase */
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DateFormat } from 'constants/enum';
import { SpentList } from 'constants/constants';

export interface FilterMembersPage {
  name: string;
  email: string;
  phone: string;
  status: string;
  spent: string;
  sku: string;
  productName: string;
  startDate: string;
  endDate: string;
}

interface Props {
  onFilter: (filter: FilterMembersPage) => void;
}

const { RangePicker } = DatePicker;
export const FilterView: React.FC<Props> = (props) => {
  const { onFilter } = props;
  const [form] = Form.useForm();

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (!isEmpty(params)) {
      const { startDate, endDate, ...rest } = params || {};
      form.setFieldsValue({
        ...rest,
        startDate:
          startDate && endDate
            ? [moment(startDate, DateFormat.clientSide), moment(endDate, DateFormat.clientSide)]
            : null,
      });
    }
  }, [params]);

  const onClear = useCallback(() => {
    form.resetFields();
    onFilter({
      name: '',
      phone: '',
      email: '',
      status: '',
      spent: '',
      sku: '',
      productName: '',
      startDate: '',
      endDate: '',
    } as FilterMembersPage);
  }, [onFilter]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => {
        onFilter({
          ...values,
          startDate: values?.startDate?.length
            ? moment(values.startDate[0]).format(DateFormat.clientSide)
            : '',
          endDate: values?.startDate?.length
            ? moment(values.startDate[1]).format(DateFormat.clientSide)
            : '',
        });
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Tên khách hàng" name="name">
            <Input placeholder="Nhập tên khách hàng" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Số điện thoại" name="phone">
            <Input placeholder="Nhập SĐT khách hàng" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Email" name="email">
            <Input placeholder="Nhập email khách hàng" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Sản Phẩm SKU" name="sku">
            <Input placeholder="Nhập SKU" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Tên Sản Phẩm" name="productName">
            <Input placeholder="Nhập tên sản phẩm" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Đã chi tiêu" name="spent">
            <Select placeholder="Chọn hạn mức" className="ant-select-lg" options={SpentList} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tình trạng" name="status">
            <Select
              placeholder="Chọn tình trạng"
              className="ant-select-lg"
              options={[
                {
                  label: 'Chưa kích hoạt',
                  value: '0',
                },
                {
                  label: 'Đang hoạt động',
                  value: '1',
                },
                {
                  label: 'Đã bị khoá',
                  value: '2',
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Có mua hàng trong thời gian"
            name="startDate"
            rules={[
              {
                validator: (_, value: any[]) => {
                  if (!value) return Promise.resolve();

                  const [start, end] = value;

                  if (end && end < start) {
                    return Promise.reject('Vui lòng chọn ngày kết thúc hợp lệ');
                  }

                  return Promise.resolve();
                },
                validateTrigger: 'onChange',
              },
            ]}
          >
            <RangePicker
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              format={DateFormat.clientSide}
              showTime={false}
            />
          </Form.Item>
        </Col>

        <Col
          span={24}
          className="align-items-center justify-content-end"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button htmlType="submit" className="secondary-button">
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={onClear}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
