import { FilterFilled } from '@ant-design/icons';
import { Button, Card, Empty, Pagination, Spin } from 'antd';
import { ICoupon } from 'interfaces/coupon.interface';
import { useFetchCouponHistory } from 'pages/CouponDetail/hooks';
import React, { useCallback, useEffect, useState } from 'react';

import './styles.scss';
import { FilterCouponOrderHistory, FilterView } from './FilterView';
import { OrderDetailModal } from './OrderDetailModal';
import { OrderHistoryItem } from './OrderHistoryItem';

interface Props {
  coupon: ICoupon;
  onBack: () => void;
}

export const OrderHistory: React.FC<Props> = (props) => {
  const { coupon, onBack } = props;

  const { data, isFetching, refetch, pagination, setPagination } = useFetchCouponHistory(
    coupon?.id,
  );

  const [showOrderDetailModal, setShowOrderDetailModal] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedOrderCode, setSelectedOrderCode] = useState<string | null>(null);

  const totalCoupon = coupon?.attributes?.numberOfCoupon;
  const totalCouponAvailable = coupon?.attributes?.countActive;

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (selectedOrderCode && !showOrderDetailModal) setShowOrderDetailModal(true);
  }, [selectedOrderCode]);

  /**
   * A callback function that handles page changes in pagination.
   *
   * @param {number} page - The new page number.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await refetch({ page });
    },
    [refetch],
  );

  /**
   * A callback function that handles filter changes for coupon history.
   *
   * @param {FilterCouponOrderHistory} filter - The new filter values.
   */
  const onFilterChange = useCallback(
    async (filter: FilterCouponOrderHistory): Promise<void> => {
      await refetch({ page: 1, ...filter });
    },
    [refetch],
  );

  return (
    <div className="coupon-order-history">
      <div>
        <div className="coupon-order-history__toolbar">
          <Button onClick={onBack}>Quay lại</Button>
          <Button
            className="filter-button"
            title="Filter"
            icon={<FilterFilled />}
            onClick={() => setShowFilter((prev) => !prev)}
          >
            Filter
          </Button>
        </div>
        <Card hidden={!showFilter}>
          <FilterView onFilter={onFilterChange} />
        </Card>
      </div>

      <div>
        <Card>
          <div className="order-history__header">
            <div className="header__coupon-used-label">
              Đã có {totalCouponAvailable || 0} mã sử dụng
            </div>
            <div className="header__coupon-statistic">
              Còn lại <span>{totalCoupon - totalCouponAvailable}</span> / {totalCoupon} mã phát hành
            </div>
          </div>

          <div className="order-history__list">
            <div className={`list__loading list__loading--${isFetching ? 'show' : 'hide'}`}>
              <Spin />
            </div>

            {!data?.length && !isFetching && (
              <div className="order-history__list-empty">
                <Empty description="Danh sách trống" />
              </div>
            )}

            {data?.map((o, i) => {
              return (
                <OrderHistoryItem
                  data={o}
                  key={`${i}`}
                  onViewDetail={(code) => {
                    setSelectedOrderCode(code);
                    setShowOrderDetailModal(true);
                  }}
                />
              );
            })}

            <div className="order-history__list-pagination">
              <Pagination {...pagination} onChange={onPageChange} />
            </div>
          </div>
        </Card>

        <OrderDetailModal
          visible={showOrderDetailModal}
          orderCode={selectedOrderCode}
          onClose={() => {
            setSelectedOrderCode(null);
            setShowOrderDetailModal(false);
          }}
        />
      </div>
    </div>
  );
};
