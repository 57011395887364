import { domainUrl } from '../constants';

export const API_GET_POST_ATTRIBUTES = `${domainUrl}attributes`;
export const API_GET_ATTRIBUTE_DETAIL = `${domainUrl}attributes/{id}`;
export const API_PUT_DELETE_ATTRIBUTE = `${domainUrl}attributes/{id}`;
export const API_GET_ATTRIBUTE_TYPES = `${domainUrl}attributes/types`;
export const API_GET_ATTRIBUTE_VALIDATIONS = `${domainUrl}attributes/validations`;
export const API_GET_ATTRIBUTE_OPTIONS = `${domainUrl}attributes/{id}/options`;
export const API_RESTORE_DELETED_ATTRIBUTE = `${domainUrl}attributes/{id}/restore`;

export const API_POST_ATTRIBUTE_OPTION = `${domainUrl}attribute-options`;
export const API_UPDATE_DELETE_ATTRIBUTE_OPTION = `${domainUrl}attribute-options/{id}`;

export const API_GET_POST_ATTRIBUTE_SETS = `${domainUrl}attribute-sets`;
export const API_PUT_DELETE_ATTRIBUTE_SET = `${domainUrl}attribute-sets/{id}`;
export const API_GET_ATTRIBUTE_GROUPS = `${domainUrl}attribute-sets/{id}/groups`;
export const API_GET_SUPER_ATTRIBUTES = `${domainUrl}attribute-sets/{id}/super-attributes`;
export const API_GET_ATTRIBUTE_SET_DEFAULT = `${domainUrl}attribute-sets/default`;

export const API_POST_ATTRIBUTE_GROUP = `${domainUrl}attribute-groups`;
export const API_PUT_DELETE_ATTRIBUTE_GROUP = `${domainUrl}attribute-groups/{id}`;
export const API_GET_ATTRIBUTE_MAPPING = `${domainUrl}attribute-groups/{id}/mappings`;
export const API_GET_ATTRIBUTES_IN_GROUP = `${domainUrl}attribute-groups/{id}/attributes`;
export const API_GET_ATTRIBUTE_SUB_GROUPS = `${domainUrl}attribute-groups/{id}/sub-groups`;

export const API_POST_ATTRIBUTE_MAPPING = `${domainUrl}attribute-group-mappings`;
export const API_PUT_DELETE_ATTRIBUTE_MAPPING = `${domainUrl}attribute-group-mappings/{id}`;
