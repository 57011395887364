import React, { CSSProperties } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { IMenuItem } from 'interfaces/menu.interface';
import { hasParams, locationHasParams } from 'utils/utils';

interface MenuLink extends IMenuItem {
  color?: string;
  accentColor?: string;
  accentContrast?: string;
}

const MenuLink: React.FunctionComponent<MenuLink | any> = (props) => {
  const { icon, title, routing, location, color, accentColor, accentContrast, params } = props;

  const style: CSSProperties = {
    color: color,
  };

  const activeStyle: CSSProperties = {
    color: accentContrast,
    backgroundColor: accentColor,
  };

  return (
    <li
      className={`menu-item ${
        routing &&
        (locationHasParams(location) === `${hasParams(routing, params)}` ||
          location.pathname.split('/')[1] === routing)
          ? 'active'
          : ''
      }`}
    >
      <NavLink
        to={`/${hasParams(routing, params)}`}
        className="item-link"
        style={style}
        activeStyle={
          (location.pathname || routing) &&
          (locationHasParams(location) === `${hasParams(routing, params)}` ||
            location.pathname.split('/')[1] === routing)
            ? activeStyle
            : {}
        }
        activeClassName="active"
        replace
      >
        {icon ? (
          <span
            className={`link-icon ${icon.class}`}
            style={{ backgroundColor: icon.bg, color: icon.color }}
          />
        ) : (
          <></>
        )}
        <span className="link-text">{title}</span>
      </NavLink>
    </li>
  );
};

export default withRouter(MenuLink);
