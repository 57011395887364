import { CopyOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Input, Row, Form, Switch, Button } from 'antd';
import React, { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Hook
import { useFormSizeGrp } from 'hooks/useSizeGroups';
// Models
import { ISizeGroup } from 'interfaces/size.interface';

// Components
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';

// Helper
import { clickCopyUrlPath } from 'utils/utils';

interface FormGroupSizeProps {
  openModal?: boolean;
  sizeGroupItem?: ISizeGroup;
  onCloseModal?: () => void;
  onRefreshList?: () => void;
}

const FormSizeGroup: React.FC<FormGroupSizeProps> = (props) => {
  const { openModal, sizeGroupItem, onCloseModal, onRefreshList } = props;

  // hook form
  const {
    form,
    slugPath,
    previewImage,
    errorForm,
    isSuccessForm,
    isLoadingForm,
    resetForm,
    submitForm,
    uploadImage,
    deleteImage,
    changeEditor,
  } = useFormSizeGrp(sizeGroupItem);

  // click close modal
  const clickCloseModal = () => {
    // reset form
    resetForm();

    onCloseModal && onCloseModal();
  };

  // submitted form success
  useEffect(() => {
    if (isSuccessForm) {
      clickCloseModal();
      onRefreshList && onRefreshList();
    }
  }, [isSuccessForm]);

  const { fields: fieldList, content } = sizeGroupItem.attributes;
  return (
    <CustomPopup
      handleSaveButton={submitForm}
      title="Tạo nhóm size"
      isOpen={openModal}
      onCloseModal={clickCloseModal}
      formHook={form}
      isSaving={isLoadingForm}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Tên nhóm"
            name="title"
            rules={[
              {
                required: true,
                message: 'Vui lòng điền tên nhóm',
              },
            ]}
          >
            <Input placeholder="Tên nhóm" autoComplete="off" />
          </Form.Item>
          <Form.Item label="Hiển thị" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item className={`${errorForm.uploadFile ? 'form-error' : ''}`}>
            <UploadFile
              uploadText="Click hoặc kéo thả hình để upload"
              previewImage={previewImage}
              imageSize="256x256"
              maxWidth={256}
              handleUploadImage={uploadImage}
              onRemoveImageCallback={deleteImage}
              handleDeleteImageSuccess={deleteImage}
            />
            {errorForm.uploadFile && <span className="msg msg-img mt-1">Vui lòng upload hình</span>}
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="url-path-input">
            <Form.Item name="slug" label="Slug">
              <Input readOnly />
            </Form.Item>
            <CopyToClipboard text={slugPath} onCopy={clickCopyUrlPath}>
              <Button
                title="Copy"
                disabled={!slugPath}
                className="copy-button"
                icon={<CopyOutlined />}
              />
            </CopyToClipboard>
          </div>
          {/* Add Fields */}
          <Form.List name="fields" initialValue={fieldList}>
            {(fields, { add, remove }) => (
              <>
                <Button className="mb-3" onClick={() => add()} icon={<PlusOutlined />}>
                  Thêm field
                </Button>
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={24} key={key}>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'field']}
                        rules={[
                          { required: true, message: 'Vui lòng điền Field' },
                          {
                            pattern: /^[a-zA-Z0-9]+$/,
                            message: 'Vui lòng không viết dấu, khoảng trắng và ký tự đặc biệt',
                          },
                        ]}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'label']}
                        rules={[{ required: true, message: 'Vui lòng điền Label' }]}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Button
                        className="btn-field-del"
                        onClick={() => remove(name)}
                        icon={<DeleteOutlined />}
                      ></Button>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Col>
        <Col span={24}>
          <Form.Item label="Nội dung" className={`${errorForm.content ? 'form-error' : ''}`}>
            <CustomEditor
              editorId="size-group-desc"
              mainContent={content}
              handleChangeMainContent={changeEditor}
            />
            {errorForm.content && <span className="msg msg-img mt-1">Vui lòng nhập nội dung</span>}
          </Form.Item>
        </Col>
      </Row>
    </CustomPopup>
  );
};

export default FormSizeGroup;
