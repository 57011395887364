import { fetcher } from 'services/swr.utils';
import useSWR from 'swr';
import { API_GET_SUB_STATUS } from 'constants/api/status.api';

/**
 * Custom hook that fetches and returns product categories data in a tree structure.
 */
export const useFetchSubStatus = () => {
  const { data, isLoading } = useSWR(`${API_GET_SUB_STATUS}?perPage=all`, fetcher);

  return {
    isFetching: isLoading,
    data,
  };
};
