import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_GET_2FACTOR_ENABLE } from 'constants/api/users.api';
import { getPageData } from 'services/pageData.service';
import './QRCode.scss';

const QRCode = () => {
  const [qrCodeImg, setQRCodeImg] = useState('');
  const [qrCode, setQRCode] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    getPageData(API_GET_2FACTOR_ENABLE, dispatch).then((res) => {
      const img = 'data:' + res?.data?.qrCode?.type + ';base64,' + res?.data?.qrCode?.content;
      setQRCodeImg(img);
      setQRCode(res?.data.google2faSecret);
    });
  }, []);

  return (
    <div className="qr-code-wrap">
      <h2 className="ttl">Quét mã QR bằng ứng dụng Goodgle Authenticator</h2>
      <figure className="qr-code-img">
        <img src={qrCodeImg} alt="MN QR Code" />
      </figure>
      <h3 className="code text-uppercase">{qrCode}</h3>
      <div className="guide">- Nếu bạn không thẻ quét mã QR, hãy nhập mã này vào ứng dụng.</div>
      <div className="guide">
        - Tải App Google Auth trên{' '}
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=vi&gl=US"
          target="_blank"
          rel="noreferrer"
        >
          Android
        </a>{' '}
        /{' '}
        <a
          href="https://apps.apple.com/vn/app/google-authenticator/id388497605"
          target="_blank"
          rel="noreferrer"
        >
          iOS
        </a>
      </div>
      <div className="guide">
        - Account Name trong Google Auth là tên gợi nhớ <b>VD: MaiNguyenADMIN_Auth</b>. như vậy mình
        biết đây là key cho MAINGUYEN chứ không phải key cho APP khác.
      </div>
      <div className="guide">
        - Xem Video hướng dẫn:{' '}
        <a href="https://www.youtube.com/watch?v=hYafQvEuGnU" target="_blank" rel="noreferrer">
          https://www.youtube.com/watch?v=hYafQvEuGnU
        </a>
      </div>
    </div>
  );
};

export default QRCode;
