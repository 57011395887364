import { Col, Row, Form, Select, Spin, DatePicker, Switch, FormInstance } from 'antd';
import { DebouncedFunc, isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';

// Constants
import { DateFormat } from 'constants/enum';

// Hook
import { useFetchPreOrderContent } from 'hooks/useFetchPreOrderContent';

// Interface
import { IBranch } from 'interfaces/branch.interface';
import { IProduct } from 'interfaces/product.interface';
import { IPromotion } from 'interfaces/promotion.interface';

// Components
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { SelectVariantProduct } from '../SelectVariantProduct';

interface FormPreOrderProps {
  openModal?: boolean;
  isLoadingProducts?: boolean;
  products?: IProduct[];
  isLoadingVariantProds?: boolean;
  variantProducts?: IProduct[];
  selectedVariantProducts?: IProduct[];
  promotions?: IPromotion[];
  branches?: IBranch[];
  form?: FormInstance<any>;
  closeModal?: () => void;
  onSubmit?: () => void;
  onChangeVariantProds?: (prods: IProduct[]) => void;
  onChangeValues?: (field, values) => void;
  onClearProducts?: () => void;
  onSearchProduct?: DebouncedFunc<(value: string) => Promise<void>>;
}

const FormPreOrder: React.FC<FormPreOrderProps> = (props) => {
  const {
    form,
    openModal,
    isLoadingProducts,
    products,
    isLoadingVariantProds,
    variantProducts,
    selectedVariantProducts,
    closeModal,
    onSubmit,
    onChangeVariantProds,
    onChangeValues,
    onClearProducts,
    onSearchProduct,
  } = props;

  // Hook
  const { promotions, branches } = useFetchPreOrderContent();

  return (
    <CustomPopup
      formHook={form}
      title="Chi tiết Pre-Order"
      isOpen={openModal}
      onCloseModal={closeModal}
      handleValuesChange={onChangeValues}
      handleSaveButton={() => onSubmit()}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Sản phẩm"
            name="productId"
            rules={[{ required: true, message: 'Vui lòng chọn sản phẩm' }]}
          >
            <Select
              size="large"
              showSearch
              optionFilterProp="children"
              onSearch={onSearchProduct}
              onClear={onClearProducts}
              allowClear
              notFoundContent={isLoadingProducts ? <Spin size="small" /> : null}
              placeholder="Nhập để tìm sản phẩm theo SKU/Tên"
            >
              {products?.map((item) => (
                <Select.Option
                  key={item?.id}
                  value={parseInt(item?.id)}
                >{`${item?.attributes?.sku} - ${item?.attributes?.name}`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <label className="note-text">{`** Ghi chú: Nhập SKU của 1 SP cha (Configurable) hệ thống sẽ tự add tất cả SP con (Simple)`}</label>
        </Col>
        <Col span={24}>
          <SelectVariantProduct
            variantProducts={variantProducts}
            selectedVariantProducts={selectedVariantProducts}
            loading={isLoadingVariantProds}
            onSelectProduct={onChangeVariantProds}
          />
        </Col>
        <Col span={24}>
          <Form.Item label="Chương trình khuyến mãi" name="promotionId">
            <Select
              size="large"
              showSearch
              optionFilterProp="children"
              loading={isEmpty(promotions)}
              disabled={isEmpty(promotions)}
              allowClear
            >
              {promotions.map((item) => (
                <Select.Option key={item.id} value={parseInt(item.id)}>
                  {item.attributes.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Các chi nhánh giao hàng" name="branchIds">
            <Select
              size="large"
              showSearch
              optionFilterProp="children"
              mode="multiple"
              loading={isEmpty(branches)}
              disabled={isEmpty(branches)}
            >
              {branches.map((item) => (
                <Select.Option key={item.id} value={parseInt(item.id)}>
                  {item.attributes.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <label className="note-text">
            {`** Nếu không nhập "Chi nhánh giao hàng" thì hệ thống sẽ hiểu là giao ở tất cả Chi Nhánh.`}
          </label>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Ngày giao hàng"
            name="deliveryDate"
            rules={[{ required: true, message: 'Vui chọn ngày giao hàng' }]}
          >
            <DatePicker placeholder="Chọn ngày" format={DateFormat.clientSide} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Số tiền cọc" name="moneyDeposit">
            <NumberFormat className="ant-input" decimalSeparator="." thousandSeparator />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Đăng ký nhận tin" valuePropName="checked" name="prebooking">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Hiển thị" valuePropName="checked" name="active">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </CustomPopup>
  );
};

export default FormPreOrder;
