import { Button } from 'antd';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const ButtonBack: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  /**
   * Navigates to the member list pages with the provided query parameters.
   *
   * @returns {void}
   */
  const onBackToMembers = useCallback((): void => {
    history.push(
      queryString.stringifyUrl(
        {
          url: '/danh-sach-members',
          query: location.state as any,
        },
        { skipEmptyString: true, skipNull: true },
      ),
    );
  }, []);

  return (
    <div className="member-info-toolbar">
      <Button className="btn-close" onClick={onBackToMembers}>
        ĐÓNG
      </Button>
    </div>
  );
};

export default ButtonBack;
