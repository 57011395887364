import { Order } from 'interfaces/order.interface';
import moment from 'moment';
import { useMemo } from 'react';
import { genStatusOrder } from 'utils/utils';
interface OrderNumberProps {
  data: Order;
}

const OrderNumber: React.FC<OrderNumberProps> = ({ data }) => {
  const { createdAt, status } = data?.attributes || {};

  const orderDate = useMemo(() => {
    return moment(createdAt).format('DD/MM/YYYY HH:mm');
  }, [createdAt]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 col-xl-6">
          {' '}
          No. <b className="order-number">#{data?.id}</b>
        </div>
        <div className="col-12 col-xl-6 text-right">
          Ngày: <b>{orderDate}</b>
        </div>
      </div>
      <div className={`ord-sts ${genStatusOrder(status, 'value')}`}>
        Tình trạng: {genStatusOrder(status)}
      </div>
    </>
  );
};

export default OrderNumber;
