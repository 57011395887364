import { Col, Form, Input, Row, Switch } from 'antd';
import React from 'react';

interface InstagramBlockProps {
  data: {
    blockTitle: string;
    blockDesc: string;
    display: boolean;
    channelId: string;
    photoNumber: string | number;
  };
}

export const InstagramBlock: React.FC<InstagramBlockProps> = (props) => {
  const { data } = props;
  const { display, channelId, photoNumber, blockTitle, blockDesc } = data || {};
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item label="Block Title" name="blockTitle" initialValue={blockTitle}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Block Description" name="blockDesc" initialValue={blockDesc}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="ChannelId" name="channelId" initialValue={channelId}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Số lượng ảnh" name="photoNumber" initialValue={photoNumber}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Hiển thị" name="display" valuePropName="checked" initialValue={display}>
          <Switch />
        </Form.Item>
      </Col>
    </Row>
  );
};
