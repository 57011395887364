import { IAttrAttributeMapping } from 'interfaces/attr-mapping.interface';

export class AttributeMappingModel implements IAttrAttributeMapping {
  attributeGroupId?: number;

  attributeId?: number;

  position?: number;

  constructor({ attributeGroupId, attributeId, position }: IAttrAttributeMapping) {
    this.attributeGroupId = attributeGroupId;
    this.attributeId = attributeId;
    this.position = position;
  }
}
