import { Col, Row } from 'antd';
import ButtonBackOrders from './ButtonBack';

interface OrderHeaderInfoProps {
  isOrderDetailPage?: boolean;
}

const OrderHeaderInfo: React.FC<OrderHeaderInfoProps> = ({ isOrderDetailPage = false }) => {
  return (
    <div className="member-review-info">
      <Row className="items-center">
        <Col span={12}>
          <h5 className="m-0">THÔNG TIN ĐƠN HÀNG</h5>
        </Col>
        <Col span={12}>
          <Row className="justify-content-end">
            <ButtonBackOrders isOrderDetailPage={isOrderDetailPage} />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OrderHeaderInfo;
