import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Input, Button, Form, Typography, Col, Row, Switch, Radio, Select } from 'antd';
import { clone } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  API_CREATE_ARTICLE_CATEGORY,
  API_GET_ARTICLE_CATEGORIES,
  API_UPDATE_DELETE_ARTICLE_CATEGORY,
} from 'constants/api/article.api';
import { IArticleCategoryAttribute } from 'interfaces/article.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { ArticleCategoryModel } from 'models/article-category.model';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { TreeView } from 'ui/components/MaiNguyen/TreeView';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { deleteModal } from 'utils/modals';
import { convertToTreeLevel, showErrorMsg, generateSlug } from 'utils/utils';

import './Categories.scss';

const newCategory = new ArticleCategoryModel({});

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<IArticleCategoryAttribute>(newCategory);
  const [reloadList, setReloadList] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getPageData(API_GET_ARTICLE_CATEGORIES, dispatch).then((res) => {
      const dataCate = clone(convertToTreeLevel(res?.data));
      setCategories(dataCate);
      setCategoriesList(res?.data);
    });
  }, []);

  useEffect(() => {
    if (reloadList) {
      try {
        api.get({ endpoint: API_GET_ARTICLE_CATEGORIES }).then((res) => {
          const dataCate = clone(convertToTreeLevel(res?.data));
          setCategories(dataCate);
          setCategoriesList(res?.data);
          dispatch(setIsEditing(false));
          setReloadList(false);
        });
      } catch (error) {
        showErrorMsg(error);
        setReloadList(false);
      }
    }
  }, [reloadList]);

  const handleDeleteCategory = useCallback(() => {
    deleteModal({
      title: 'Bạn có muốn xóa chuyên mục này?',
      onDeleteCb: async () => {
        try {
          await api.remove({
            endpoint: API_UPDATE_DELETE_ARTICLE_CATEGORY.replace('{id}', categoryId),
          });
          setSelectedCategory(newCategory);
          form.setFieldsValue(newCategory);
          setReloadList(true);
        } catch (error) {
          showErrorMsg(error);
        }
      },
    });
  }, [categoryId]);

  const handleClickAddButton = () => {
    setCategoryId('');
    setSelectedCategory(newCategory);
    form.setFieldsValue(newCategory);
    localStorage.setItem('currentCategory-new', JSON.stringify(newCategory));
  };

  useEffect(() => {
    return () => {
      // remove curent article
      localStorage.removeItem('currentCategory-new');
      localStorage.removeItem('currentCategory-' + categoryId);
    };
  }, [categoryId]);

  const handleValuesChange = useCallback(
    (field, values) => {
      dispatch(setIsEditing(true));
      const fieldKey = Object.keys(field);
      let cloneCategory;
      if (categoryId) {
        cloneCategory = JSON.parse(localStorage.getItem('currentCategory-' + categoryId));
      } else {
        cloneCategory = JSON.parse(localStorage.getItem('currentCategory-new'));
      }
      const articleCate = new ArticleCategoryModel({ ...cloneCategory, ...values });
      switch (fieldKey[0]) {
        case 'slug':
          articleCate.slug = generateSlug(articleCate.slug);
          break;
        default:
          break;
      }
      setSelectedCategory(articleCate);
      form.setFieldsValue(articleCate);
      if (categoryId) {
        localStorage.setItem('currentCategory-' + categoryId, JSON.stringify(articleCate));
      } else {
        localStorage.setItem('currentCategory-new', JSON.stringify(articleCate));
      }
    },
    [categoryId],
  );

  const handleSaveCategory = useCallback(async () => {
    try {
      let cloneCategory;
      if (categoryId) {
        cloneCategory = JSON.parse(localStorage.getItem('currentCategory-' + categoryId));
      } else {
        cloneCategory = JSON.parse(localStorage.getItem('currentCategory-new'));
      }
      const dataPayload = new ArticleCategoryModel({ ...selectedCategory, ...cloneCategory });

      if (categoryId) {
        await api.put({
          endpoint: API_UPDATE_DELETE_ARTICLE_CATEGORY.replace('{id}', categoryId),
          data: dataPayload,
        });
        form.resetFields();
        setReloadList(true);
      } else {
        await api.post({ endpoint: API_CREATE_ARTICLE_CATEGORY, data: dataPayload });
        form.resetFields();
        setReloadList(true);
      }
    } catch (error) {
      const errStatus = error['response']['status'];
      if (errStatus !== 422) {
        showErrorMsg(error);
      }
      setReloadList(true);
    }
  }, [categoryId]);

  const handleSelectLeaf = useCallback(
    (category) => {
      const { selected } = category;
      if (!selected) {
        const currentCategory = new ArticleCategoryModel(category.attributes);
        setCategoryId(category.id);
        setSelectedCategory(currentCategory);
        form.setFieldsValue(currentCategory);
        // set localstorage current category
        localStorage.setItem('currentCategory-' + category.id, JSON.stringify(currentCategory));
      } else {
        handleClickAddButton();
      }
    },
    [categories],
  );

  const handleDropContent = useCallback(async (parentId: number, dragNode) => {
    const { attributes, id } = dragNode;
    const dragCategory = new ArticleCategoryModel({ ...attributes, parentId });

    try {
      await api.put({
        endpoint: API_UPDATE_DELETE_ARTICLE_CATEGORY.replace('{id}', id),
        data: dragCategory,
      });
      setReloadList(true);
    } catch (err) {
      showErrorMsg(err);
    }
  }, []);

  return (
    <LayoutDetail
      pageTitle="Chuyên mục"
      breadcrumbs={[
        {
          title: 'Chuyên mục',
          route: '/chuyen-muc',
        },
      ]}
      formHook={form}
      isAddNew={false}
      pageClassName="article-category-page"
      handleValuesChange={handleValuesChange}
      wrapperCard={false}
      showSaveButton={false}
      handleClickAddButton={handleClickAddButton}
      handleSave={() => {}}
      saveLoading={reloadList}
    >
      <Button
        className="btn-close-promotion r-15"
        icon={<PlusOutlined />}
        onClick={handleClickAddButton}
      >
        Tạo mới
      </Button>
      <Row gutter={16}>
        <Col span={8}>
          <Card>
            <h3 className="title" style={{ fontSize: 16 }}>
              Tên chuyên mục tin tức
            </h3>
            {/* <List itemLayout="horizontal" dataSource={categories} renderItem={renderListItem} /> */}
            <TreeView
              treeData={categories}
              handleSelectLeaf={handleSelectLeaf}
              handleDropContent={handleDropContent}
            />
          </Card>
        </Col>
        <Col span={16}>
          <Card
            title={
              <Row justify="center">
                <Col span={8}>
                  <Typography.Title level={3}>{selectedCategory.name}</Typography.Title>
                </Col>
                <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                  {categoryId && (
                    <Button onClick={handleDeleteCategory} icon={<DeleteOutlined />}>
                      Xóa
                    </Button>
                  )}
                  &nbsp; &nbsp;
                  <ThemeButton htmlType="submit" onClick={handleSaveCategory} loading={reloadList}>
                    Lưu
                  </ThemeButton>
                </Col>
              </Row>
            }
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Tên chuyên mục"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng điền tên chuyên mục',
                    },
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
                <Form.Item label="Slug" name="slug">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Loại chuyên mục"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng điền loại chuyên mục',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio key="Thường" value={1}>
                      <strong>Thường</strong>
                    </Radio>
                    <Radio key="Tổng hợp" value={2}>
                      <strong>Tổng hợp</strong>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Chuyên mục cha" name="parentId">
                  <Select showSearch optionFilterProp="children" allowClear>
                    {categoriesList
                      .filter((item) => item.id !== categoryId)
                      .map((item) => (
                        <Select.Option key={item.id} value={parseInt(item.id)}>
                          {item.attributes.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Mô tả" name="description">
                  <Input.TextArea rows={6} />
                </Form.Item>
                <Form.Item label="Keywords" name="keywords">
                  <Input.TextArea rows={3} />
                </Form.Item>
                <Form.Item label="Hiển thị" name="active" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </LayoutDetail>
  );
};

export default Categories;
