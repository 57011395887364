import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Button, Select, Form, Input, InputNumber } from 'antd';
import { clone, isEmpty } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  API_POST_ATTRIBUTE_GROUP,
  API_PUT_DELETE_ATTRIBUTE_GROUP,
  API_GET_ATTRIBUTE_MAPPING,
  API_POST_ATTRIBUTE_MAPPING,
  API_PUT_DELETE_ATTRIBUTE_MAPPING,
  API_GET_ATTRIBUTE_SUB_GROUPS,
} from 'constants/api/attribute.api';
import { AlignType } from 'constants/enum';
import { IAttrAttributeGroup, IAttributeGroup } from 'interfaces/attr-group.interface';
import { IAttrAttributeMapping, IAttributeMapping } from 'interfaces/attr-mapping.interface';
import { IAttribute } from 'interfaces/attribute.interface';
import { AttributeGroupModel } from 'models/attribute-group.model';
import { AttributeMappingModel } from 'models/attribute-mapping.model';

import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { CustomModal } from 'ui/components/MaiNguyen/CustomModal';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';

import './ProductAttrGroup.scss';

interface ProductAttrGroupInterface {
  attributeGroups: IAttributeGroup[];
  attributeSubGroups: IAttributeGroup[];
  attributes: IAttribute[];
  attributeSetId: string;
  handleUpdateAttributeGroup: () => void;
}

export const ProductAttrGroup: FC<ProductAttrGroupInterface> = ({
  attributeGroups,
  attributeSubGroups,
  attributes,
  attributeSetId,
  handleUpdateAttributeGroup,
}) => {
  const newAttrGroup = new AttributeGroupModel({});
  const newAttrMapping = new AttributeMappingModel({});

  const [opeModalAttrGroup, setOpenModalAttrGroup] = useState(false);
  const [openModalAddAttribute, setOpenModalAddAttribute] = useState(false);
  const [attrGroup, setAttrGroup] = useState(newAttrGroup);
  const [attrGroupId, setAttrGroupId] = useState('');
  const [expanedRows, setExpandedRows] = useState<string[]>([]);
  const [attrMappings, setAttrMappings] = useState<IAttributeMapping[]>([]);
  const [attrMapping, setAttrMapping] = useState(newAttrMapping);
  const [attributeSubGroupsFromGroup, setAttributeSubGroupsFromGroup] = useState<IAttributeGroup[]>(
    [],
  );
  const [expanedSubGroupRows, setExpandedSubGroupRows] = useState<string[]>([]);
  const [hasSubGroup, setHasSubGroup] = useState(true);
  const [loadingSubGroups, setLoadingSubGroups] = useState(false);
  const [loadingMappings, setLoadingMapping] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formAttribute] = Form.useForm();

  useEffect(() => {
    setExpandedRows([]);
    setExpandedSubGroupRows([]);
  }, [attributeSetId]);

  const handleOpenAddAttrGroup = useCallback(() => {
    setOpenModalAttrGroup(true);
    setAttrGroup(newAttrGroup);
    setAttrGroupId('');
    form.resetFields();
    dispatch(setIsEditing(false));
  }, []);

  const handleCloseAddAttrGroup = useCallback(() => {
    setOpenModalAttrGroup(false);
    dispatch(setIsEditing(false));
  }, []);

  const handleOpenAddAttributeMapping = useCallback(() => {
    setOpenModalAddAttribute(true);
    setAttrMapping(newAttrMapping);
    formAttribute.resetFields();
    dispatch(setIsEditing(false));
  }, []);

  const handleCloseAddAttributeMapping = useCallback(() => {
    setOpenModalAddAttribute(false);
    dispatch(setIsEditing(false));
  }, []);

  const handleEditAttrGroup = useCallback(
    (rowData: IAttributeGroup) => () => {
      dispatch(setIsEditing(true));
      const currentAttrGroup = new AttributeGroupModel(rowData.attributes);
      setAttrGroup(currentAttrGroup);
      form.setFieldsValue(currentAttrGroup);
      setAttrGroupId(rowData.id);
      setOpenModalAttrGroup(true);
      setHasSubGroup(true);
    },
    [],
  );

  const handleSaveAttrGroup = useCallback(async () => {
    try {
      if (attrGroupId) {
        await api.put({
          endpoint: API_PUT_DELETE_ATTRIBUTE_GROUP.replace('{id}', attrGroupId),
          data: { ...attrGroup, attributeSetId },
        });
        setOpenModalAttrGroup(false);
        handleUpdateAttributeGroup();
        dispatch(setIsEditing(false));
      } else {
        await api.post({
          endpoint: API_POST_ATTRIBUTE_GROUP,
          data: { ...attrGroup, attributeSetId },
        });
        setOpenModalAttrGroup(false);
        handleUpdateAttributeGroup();
        dispatch(setIsEditing(false));
      }
    } catch (err) {
      showErrorMsg(err);
    }
  }, [attrGroup, attrGroupId, handleUpdateAttributeGroup]);

  const handleDeleteAttrGroup = useCallback(
    (rowData: IAttributeGroup) => () => {
      deleteModal({
        title: `Bạn có muốn xóa nhóm thuộc tính: <strong>${rowData.attributes.name}</strong> không?`,
        onDeleteCb: async () => {
          try {
            await api.remove({
              endpoint: API_PUT_DELETE_ATTRIBUTE_GROUP.replace('{id}', rowData.id),
            });
            handleUpdateAttributeGroup();
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [handleUpdateAttributeGroup],
  );

  const handleValueChanges = useCallback((_, values) => {
    const changedValues = new AttributeGroupModel(values);
    setAttrGroup(changedValues);
    form.setFieldsValue(changedValues);
    dispatch(setIsEditing(true));
  }, []);

  const handleChangePosition = useCallback(
    (rowData: IAttributeGroup) => async (pos: number) => {
      try {
        await api.put({
          endpoint: API_PUT_DELETE_ATTRIBUTE_GROUP.replace('{id}', rowData.id),
          data: { position: pos, attributeSetId },
        });
        handleUpdateAttributeGroup();
        dispatch(setIsEditing(false));
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [handleUpdateAttributeGroup],
  );

  const handleExpanedSub = useCallback((expanedRow) => {
    const rows = clone(expanedRow);
    setExpandedRows(rows.length > 1 ? rows.splice(1, 1) : rows);
  }, []);

  const handleExpanedSubGroup = useCallback(
    (expanedRow) => {
      const rows = clone(expanedRow);
      setExpandedSubGroupRows(rows.length > 1 ? rows.splice(1, 1) : rows);
    },
    [handleExpanedSub, hasSubGroup],
  );

  const setAttributeSubGroups = useCallback(async (attributeGroupId: string) => {
    await api
      .get({
        endpoint: `${API_GET_ATTRIBUTE_SUB_GROUPS.replace('{id}', attributeGroupId)}?perPage=all`,
      })
      .then((res) => {
        setHasSubGroup(!isEmpty(res?.data));
        setAttributeSubGroupsFromGroup(res?.data);
        setLoadingSubGroups(false);
      });
  }, []);

  const setAttributeMappings = useCallback(async (attributeGroupId: string) => {
    await api
      .get({
        endpoint: `${API_GET_ATTRIBUTE_MAPPING.replace('{id}', attributeGroupId)}?perPage=all`,
      })
      .then((res) => {
        setAttrMappings(res?.data);
        setLoadingMapping(false);
        setLoadingSubGroups(false);
      });
  }, []);

  const handleExpanedSubGroupRow = useCallback(
    (expanded: boolean, record: IAttributeGroup) => {
      const {
        relationships: { attributes: attr, children },
      } = record;
      if (expanded) {
        setLoadingSubGroups(true);
        if (!isEmpty(attr.data)) {
          setAttrMappings([]);
          setAttributeMappings(record.id);
          setHasSubGroup(false);
        }
        if (!isEmpty(children.data)) {
          setHasSubGroup(true);
          setAttributeSubGroupsFromGroup([]);
          setAttributeSubGroups(record.id);
        }
      }
    },
    [setAttributeSubGroups, setAttributeMappings],
  );

  const handleExpandRow = useCallback(
    async (expanded: boolean, record: IAttributeGroup) => {
      if (expanded) {
        setLoadingMapping(true);
        setAttrMappings([]);
        setAttributeMappings(record.id);
      }
    },
    [setAttributeMappings],
  );

  const handleValuesMappingChange = useCallback((_, values) => {
    const currentAttrMapping = new AttributeMappingModel(values);
    formAttribute.setFieldsValue(currentAttrMapping);
    setAttrMapping(currentAttrMapping);
    dispatch(setIsEditing(true));
  }, []);

  const handleSaveAttrMapping = useCallback(async () => {
    try {
      await api.post({ endpoint: API_POST_ATTRIBUTE_MAPPING, data: attrMapping });
      setAttributeMappings(attrMapping.attributeGroupId.toString());
      setOpenModalAddAttribute(false);
      dispatch(setIsEditing(false));
    } catch (err) {
      showErrorMsg(err);
    }
  }, [attrMapping, setAttributeMappings]);

  const handleDeleteAttributeMapping = useCallback(
    (rowData: IAttributeMapping) => () => {
      const attribute = attributes.find(
        (item) => item.id === rowData.attributes.attributeId.toString(),
      );
      deleteModal({
        title: `Bạn có muốn xóa <strong>${attribute.attributes.adminLabel}</strong> khỏi nhóm thuộc tính này không?`,
        onDeleteCb: async () => {
          try {
            await api.remove({
              endpoint: API_PUT_DELETE_ATTRIBUTE_MAPPING.replace('{id}', rowData.id),
            });
            setAttributeMappings(rowData.attributes.attributeGroupId.toString());
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [attributes, setAttributeMappings],
  );

  const handleChangeAttributePosition = useCallback(
    (rowData: IAttributeMapping) => async (pos: number) => {
      try {
        await api.put({
          endpoint: API_PUT_DELETE_ATTRIBUTE_MAPPING.replace('{id}', rowData.id),
          data: {
            position: pos,
            attributeGroupId: rowData.attributes.attributeGroupId,
            attributeId: rowData.attributes.attributeId,
          },
        });
        dispatch(setIsEditing(false));
        setAttributeMappings(rowData.attributes.attributeGroupId.toString());
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [setAttributeMappings],
  );

  const columnAttrGroup = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tên nhóm',
      dataIndex: 'attributes',
      render: (attr: IAttrAttributeGroup, row: IAttributeGroup) => (
        <div
          onClick={handleEditAttrGroup(row)}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {attr.name}
        </div>
      ),
    },
    {
      title: 'Vị trí',
      dataIndex: 'attributes',
      render: (attr: IAttrAttributeGroup, row: IAttributeGroup) => (
        <Select value={attr.position} onChange={handleChangePosition(row)}>
          {attributeGroups.map((_, index) => (
            <Select.Option key={index + 1} value={index + 1}>
              {index + 1}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: '',
      dataIndex: '',
      align: AlignType.right,
      render: (_, row: IAttributeGroup) =>
        row.attributes.isUserDefined && (
          <Button title="Xóa" icon={<DeleteOutlined />} onClick={handleDeleteAttrGroup(row)} />
        ),
    },
  ];

  const columnsAttrs = [
    {
      title: 'Tên thuộc tính',
      dataIndex: 'attributes',
      render: (attr: IAttrAttributeMapping) => {
        const attribute = attributes.find((item) => item.id === attr.attributeId.toString());
        return (
          <NavLink
            className="attr-name"
            to={`/thuoc-tinh-san-pham?attributeId=${attr.attributeId}`}
          >
            <div className="mb-1">
              <strong>{attribute?.attributes?.adminLabel}</strong>
            </div>
            <span className="attr-code">{attribute?.attributes?.code}</span>
            <span className="attr-is-featured">
              {attribute?.attributes?.isFeatured ? 'Is Featured' : ''}
            </span>
            <span className="attr-is-filterable">
              {attribute?.attributes?.isFilterable ? 'Is Filterable' : ''}
            </span>
          </NavLink>
        );
      },
    },
    {
      title: 'Tên thuộc tính (FrontStore)',
      dataIndex: 'attributes',
      render: (attr: IAttrAttributeMapping) => {
        const attribute = attributes.find((item) => item.id === attr.attributeId.toString());
        return attribute?.attributes.showLabel;
      },
    },
    {
      title: 'Vị trí',
      dataIndex: 'attributes',
      render: (attr: IAttrAttributeMapping, row: IAttributeMapping) => (
        <Select value={attr.position} onChange={handleChangeAttributePosition(row)}>
          {attrMappings.map((_, index) => (
            <Select.Option key={index + 1} value={index + 1}>
              {index + 1}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: '',
      dataIndex: '',
      align: AlignType.right,
      render: (_, row: IAttributeMapping) => (
        <Button
          title="Xóa Attribute"
          icon={<DeleteOutlined />}
          onClick={handleDeleteAttributeMapping(row)}
        />
      ),
    },
  ];

  return (
    <>
      {attributeSetId && (
        <>
          <div className="add-group-btn-wrapper">
            <Button icon={<PlusOutlined />} onClick={handleOpenAddAttrGroup}>
              Tạo group
            </Button>
          </div>
          {!isEmpty(attributeGroups) && (
            <div className="attr-group-wrapper">
              <Table
                columns={columnAttrGroup}
                dataSource={attributeGroups}
                rowKey="id"
                pagination={false}
                expandable={{
                  expandedRowRender: (record: IAttributeGroup) => {
                    const {
                      relationships: { attributes: attr, children },
                    } = record;
                    return !isEmpty(attr.data) || !isEmpty(children.data) ? (
                      <Table
                        columns={hasSubGroup ? columnAttrGroup : columnsAttrs}
                        rowKey="id"
                        dataSource={
                          hasSubGroup ? attributeSubGroupsFromGroup : (attrMappings as any)
                        }
                        pagination={false}
                        loading={loadingSubGroups}
                        expandable={{
                          expandedRowRender: () => {
                            return (
                              <Table
                                columns={columnsAttrs}
                                rowKey="id"
                                dataSource={attrMappings}
                                loading={loadingMappings}
                                pagination={false}
                              />
                            );
                          },
                          rowExpandable: (rc) => {
                            return !!rc.relationships;
                          },
                          expandedRowKeys: expanedRows,
                          onExpandedRowsChange: handleExpanedSub,
                          onExpand: handleExpandRow,
                        }}
                      />
                    ) : (
                      <></>
                    );
                  },
                  rowExpandable: (record: IAttributeGroup) => {
                    const {
                      relationships: { attributes: attr, children },
                    } = record;
                    return !(isEmpty(attr.data) && isEmpty(children.data));
                  },
                  expandedRowKeys: expanedSubGroupRows,
                  onExpandedRowsChange: handleExpanedSubGroup,
                  onExpand: handleExpanedSubGroupRow,
                }}
              />
              <div className="add-attr-group-button">
                <Button onClick={handleOpenAddAttributeMapping}>Thêm thuộc tính</Button>
              </div>
            </div>
          )}
        </>
      )}
      <CustomModal
        title="Tạo nhóm"
        isOpen={opeModalAttrGroup}
        onCloseModal={handleCloseAddAttrGroup}
        onSave={handleSaveAttrGroup}
      >
        <Form layout="vertical" form={form} onValuesChange={handleValueChanges}>
          <Form.Item label="Tên nhóm" name="name">
            <Input />
          </Form.Item>
          {!attrGroupId && (
            <Form.Item label="Code" name="code">
              <Input />
            </Form.Item>
          )}
          <Form.Item label="Nhóm cha" name="parentId">
            <Select size="large" showSearch optionFilterProp="children" allowClear>
              {attributeGroups
                .filter((item) => isEmpty(item.relationships.attributes.data))
                .map((item) => (
                  <Select.Option key={parseInt(item.id)} value={parseInt(item.id)}>
                    {item.attributes.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Vị trí" name="position">
            <Input type="number" />
          </Form.Item>
        </Form>
      </CustomModal>
      <CustomModal
        title="Mapping thuộc tính vào nhóm thuộc tính"
        isOpen={openModalAddAttribute}
        onCloseModal={handleCloseAddAttributeMapping}
        onSave={handleSaveAttrMapping}
      >
        <Form layout="vertical" form={formAttribute} onValuesChange={handleValuesMappingChange}>
          <Form.Item label="Chọn nhóm thuộc tính" name="attributeGroupId">
            <Select size="large" showSearch optionFilterProp="children">
              {attributeSubGroups.map((item) => (
                <Select.Option key={item.id} value={parseInt(item.id)}>
                  {item.attributes.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Chọn thuộc tính" name="attributeId">
            <Select size="large" showSearch optionFilterProp="children">
              {attributes.map((item) => (
                <Select.Option key={item.id} value={parseInt(item.id)}>
                  {item.attributes.adminLabel}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Vị trí" name="position">
            <InputNumber width="100%" />
          </Form.Item>
        </Form>
      </CustomModal>
    </>
  );
};
