import { Button } from 'antd';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

type ButtonBackOrdersProps = {
  isOrderDetailPage?: boolean;
};

const ButtonBackOrders: React.FC<ButtonBackOrdersProps> = ({ isOrderDetailPage }) => {
  const history = useHistory();
  const location = useLocation();
  const { id: memberId } = useParams<{ id: string }>();
  const params = queryString.parse(location.search) as any;

  /**
   * Navigates to the member list pages with the provided query parameters.
   *
   * @returns {void}
   */
  const onBackToMembers = useCallback((): void => {
    history.push(
      queryString.stringifyUrl(
        {
          url: isOrderDetailPage ? '/danh-sach-don-hang' : `/danh-sach-members/${memberId}/orders`,
          query: params as any,
        },
        { skipEmptyString: true, skipNull: true },
      ),
    );
  }, []);

  return (
    <div className="member-info-toolbar">
      <Button className="btn-close" onClick={onBackToMembers}>
        ĐÓNG
      </Button>
    </div>
  );
};

export default ButtonBackOrders;
