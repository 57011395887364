import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Switch, Table } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  API_GET_MENU_LINKS_IN_MENU,
  API_POST_MENU_LINK,
  API_UPDATE_DELETE_MENU_LINK,
} from 'constants/api/menu.api';
import { AlignType } from 'constants/enum';
import { IMenu, IAttributeMenu } from 'interfaces/product-menu.interface';
import { MenuLinkModel } from 'models/menu-link.model';
import { setMenuLinks } from 'redux/menu/actions';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { CustomModal } from 'ui/components/MaiNguyen/CustomModal';
import { deleteModal } from 'utils/modals';
import { showErrorMsg, generateSlug } from 'utils/utils';

interface MenuLinksLevel1Props {
  menuLinks: IMenu[];
  menuId: number;
}

const newLink = new MenuLinkModel({});

export const MenuLinksLevel1: FC<MenuLinksLevel1Props> = ({ menuLinks, menuId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [links, setLinks] = useState(menuLinks);
  const [menuLink, setMenuLink] = useState(newLink);
  const [linkId, setLinkId] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    setLinks(menuLinks);
  }, [menuLinks]);

  useEffect(() => {
    if (reloadList) {
      api
        .get({
          endpoint: API_GET_MENU_LINKS_IN_MENU.replace('{id}', menuId.toString()),
        })
        .then((res) => {
          setLinks(res?.data);
          setReloadList(false);
          dispatch(setMenuLinks(res?.data));
        })
        .catch((err) => {
          showErrorMsg(err);
        });
    }
  }, [menuId, reloadList]);

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
    setMenuLink({ ...newLink, menuId });
    setLinkId('');
    form.resetFields();
  }, [menuId]);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleEditLink = useCallback(
    (link: IMenu) => () => {
      setIsOpen(true);
      const currentLink = new MenuLinkModel(link.attributes);
      setLinkId(link.id);
      setMenuLink(currentLink);
      form.setFieldsValue(currentLink);
    },
    [],
  );

  const handleChangeValues = useCallback(
    (field, values) => {
      dispatch(setIsEditing(true));
      const fieldKey = Object.keys(field);
      const currentLink = new MenuLinkModel({ ...menuLink, ...values });
      currentLink.menuId = menuId;
      switch (fieldKey[0]) {
        case 'title':
          currentLink.url = generateSlug(currentLink.title.trim());
          break;
        default:
          break;
      }
      form.setFieldsValue(currentLink);
      setMenuLink(currentLink);
    },
    [menuLink, menuId],
  );

  const handleSaveMenuLink = useCallback(async () => {
    setLoadingButton(true);
    try {
      if (linkId) {
        await api.put({
          endpoint: API_UPDATE_DELETE_MENU_LINK.replace('{id}', linkId),
          data: menuLink,
        });
        dispatch(setIsEditing(false));
        setLoadingButton(false);
        setReloadList(true);
        setIsOpen(false);
      } else {
        await api.post({ endpoint: API_POST_MENU_LINK, data: menuLink });
        dispatch(setIsEditing(false));
        setLoadingButton(false);
        setReloadList(true);
        setIsOpen(false);
      }
    } catch (err) {
      setLoadingButton(false);
      showErrorMsg(err);
    }
  }, [menuLink, linkId]);

  const handleDeleteLink = useCallback(
    (id: string) => () => {
      deleteModal({
        title: 'Bạn có muốn xóa link này?',
        onDeleteCb: async () => {
          try {
            await api.remove({ endpoint: API_UPDATE_DELETE_MENU_LINK.replace('{id}', id) });
            setReloadList(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tiêu đề Link',
      dataIndex: 'attributes',
      render: (attr: IAttributeMenu, row: IMenu) => (
        <div className="menu-link" onClick={handleEditLink(row)}>
          {attr.title}
        </div>
      ),
    },
    {
      title: 'Link',
      dataIndex: 'attributes',
      render: (attr: IAttributeMenu, row: IMenu) => (
        <div className="menu-link" onClick={handleEditLink(row)}>
          {attr.url}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: '',
      align: AlignType.right,
      render: (_, row: IMenu) => (
        <Button title="Xóa link" icon={<DeleteOutlined />} onClick={handleDeleteLink(row.id)} />
      ),
    },
  ];

  return (
    <>
      <Row gutter={24}>
        <Col span={24} className="mb-3">
          {links.length <= 2 && <Button onClick={handleOpenModal}>Thêm link</Button>}
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={links}
            rowKey="id"
            loading={reloadList}
            pagination={false}
          />
        </Col>
      </Row>
      <CustomModal
        title="Thêm link"
        isOpen={isOpen}
        onCloseModal={handleCloseModal}
        onSave={handleSaveMenuLink}
        loadingButton={loadingButton}
      >
        <Form layout="vertical" form={form} onValuesChange={handleChangeValues}>
          <Form.Item label="Tiêu đề link mới" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Link mới" name="url">
            <Input />
          </Form.Item>
          <Form.Item label="Vị trí" name="position">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Hiển thị" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </CustomModal>
    </>
  );
};
