/* eslint-disable @typescript-eslint/no-use-before-define */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, message } from 'antd';
import { useFetchArticles } from 'hooks/useFetchArticles';
import uniq from 'lodash/uniq';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';

import './styles.scss';
import { confirmModal } from 'utils/modals';
import { AddNewsModal } from './AddNewsModal';
import { NewsTable } from './NewsTable';

interface Props {
  data: {
    blockId: string;
    blockTitle: string;
    listOfNews: string[];
  };
  onChange: (data: string) => void;
}

const INITIAL_DATA: Props['data'] = {
  blockId: '',
  blockTitle: '',
  listOfNews: [],
};

export const NewsTemplate: React.FC<Props> = (props) => {
  const { data = INITIAL_DATA, onChange } = props;

  const { isFetching, refetch, data: articles, reset: resetArticles } = useFetchArticles();

  const [isOpenAddNewModal, setIsOpenAddNewModal] = useState<boolean>(false);

  useEffect(() => {
    !articles?.length && data?.listOfNews?.length && fetchArticles(data.listOfNews);
  }, [data]);

  const fetchArticles = useCallback(
    async (articleIds?: string[]): Promise<void> => {
      try {
        if (!articleIds?.length) {
          resetArticles();
          return;
        }

        await refetch({
          perPage: 'all',
          ...queryString.parse(
            queryString.stringify(
              {
                articleIds: [...(articleIds || [])],
              },
              { skipNull: true, skipEmptyString: true, arrayFormat: 'bracket' },
            ),
            {
              arrayFormat: 'bracket',
            },
          ),
        });
      } catch (error: any) {
        message.error(error?.message || error);
      }
    },
    [refetch],
  );

  const _onChange = useCallback(
    (field: keyof Props['data'], value: string | string[]) => {
      const newData = { ...(data || {}), [field]: value };
      onChange(JSON.stringify(newData));
    },
    [onChange, data],
  );

  return (
    <div className="product-list-template">
      <Row gutter={16} className="mt-3 mb-3">
        <Col span={24}>
          <Form.Item label="Block Id (Optional)">
            <Input value={data.blockId} onChange={(e) => _onChange('blockId', e.target.value)} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Tiêu đề block">
            <Input
              value={data?.blockTitle}
              onChange={(e) => _onChange('blockTitle', e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className="product-list-template__toolbar">
        <div>
          <div className="toolbar-title">DANH SÁCH BÀI VIẾT</div>
          <span>Tối đa 5 bài viết</span>
        </div>
        <div className="toolbar-action-button">
          <Button icon={<PlusOutlined />} onClick={() => setIsOpenAddNewModal(true)}>
            Thêm bài viết
          </Button>
        </div>
      </div>
      <div>
        <NewsTable
          hasSort
          loading={isFetching}
          data={articles}
          onChangePosition={(newData) =>
            _onChange(
              'listOfNews',
              newData?.map((n) => n.id),
            )
          }
          onRemove={(newId: string) => {
            confirmModal({
              title: 'Bạn có chắc muốn xoá bài viết này?',
              buttonText: 'Xoá',
              onSaveCb: async () => {
                const newData = data?.listOfNews?.filter((s: string) => s != newId);
                await fetchArticles(newData);
                _onChange('listOfNews', newData);
                message.success('Đã xoá bài viết');
              },
            });
          }}
        />
      </div>

      <AddNewsModal
        isOpen={isOpenAddNewModal}
        initialArticleIds={data?.listOfNews || []}
        onClose={() => setIsOpenAddNewModal(false)}
        onChange={async (articleIds) => {
          const params = uniq([...articleIds, ...(data?.listOfNews || [])]);
          _onChange('listOfNews', params);
          setIsOpenAddNewModal(false);
          await fetchArticles(params);
        }}
      />
    </div>
  );
};
