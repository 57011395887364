import { CopyOutlined } from '@ant-design/icons';
import { Col, Input, Row, Form, Switch, Button, Select } from 'antd';
import React, { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Hooks
import { useFormSizeItem } from 'hooks/useSizeItems';

// Models
import { ISizeAttr, ISizeGroup } from 'interfaces/size.interface';

// Components
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';

// Helper
import { clickCopyUrlPath } from 'utils/utils';

interface FormSizeItemsProps {
  openModal?: boolean;
  sizeGroups?: ISizeGroup[];
  sizeItem?: ISizeAttr;
  onCloseModal?: () => void;
  onRefreshList?: () => void;
}

const FormSizeItems: React.FC<FormSizeItemsProps> = (props) => {
  const { openModal, sizeGroups, sizeItem, onCloseModal, onRefreshList } = props;

  // hook
  const {
    form,
    slugPath,
    fieldList,
    isLoadingForm,
    isSuccessForm,
    changeSizeGroup,
    submitForm,
    resetForm,
  } = useFormSizeItem(sizeItem);

  // submitted form success
  useEffect(() => {
    if (isSuccessForm) {
      onCloseModal && onCloseModal();
      onRefreshList && onRefreshList();
    }
  }, [isSuccessForm]);

  // on click close modal
  const clickCloseModal = () => {
    // reset form
    resetForm();
    onCloseModal && onCloseModal();
  };

  return (
    <CustomPopup
      handleSaveButton={submitForm}
      title="Thông tin size"
      isOpen={openModal}
      onCloseModal={clickCloseModal}
      formHook={form}
      isSaving={isLoadingForm}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Tên size"
            name="title"
            rules={[
              {
                required: true,
                message: 'Vui lòng điền tên size',
              },
            ]}
          >
            <Input placeholder="Tên size" autoComplete="off" />
          </Form.Item>
          <Form.Item label="Hiển thị" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="url-path-input">
            <Form.Item name="slug" label="Slug">
              <Input readOnly />
            </Form.Item>
            <CopyToClipboard text={slugPath} onCopy={clickCopyUrlPath}>
              <Button
                title="Copy"
                disabled={!slugPath}
                className="copy-button"
                icon={<CopyOutlined />}
              />
            </CopyToClipboard>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Chọn nhóm size"
            name="sizeGroupId"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn nhóm size',
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              onChange={changeSizeGroup}
              listHeight={450}
              optionFilterProp="title"
            >
              {sizeGroups.map((item) => {
                const { id, attributes } = item;
                return (
                  <Select.Option key={id} value={parseInt(id)} title={attributes.title}>
                    {attributes.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          {/* Fields list */}
          <Form.List name="data">
            {(fields) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Form.Item
                    key={key}
                    {...restField}
                    name={[name, 'value']}
                    label={fieldList[key] ? fieldList[key]['label'] : ''}
                    rules={[{ required: true, message: 'Vui lòng điền thông tin' }]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                ))}
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </CustomPopup>
  );
};

export default FormSizeItems;
