import { IUser, IUserAttribute } from 'interfaces/user.interface';

export class UserAttributeModel implements IUserAttribute {
  active: boolean;

  email: string;

  firstName: string;

  lastName: string;

  role: string[];

  password?: string;

  google2faSecret: string;

  constructor({
    active,
    email,
    firstName,
    lastName,
    role,
    password,
    google2faSecret,
  }: IUserAttribute) {
    this.active = active !== undefined && active !== null ? active : true;
    this.email = email || '';
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.role = role || [];
    this.password = password || '';
    this.google2faSecret = google2faSecret;
  }
}

export class UserModel implements IUser {
  type: string;

  id: string;

  attributes: IUserAttribute;

  constructor({ type, id, attributes }: IUser) {
    this.type = type;
    this.id = id;
    this.attributes = new UserAttributeModel(attributes);
  }
}
