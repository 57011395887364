import { IBreadcrumb, IPageData } from 'interfaces/page-data.interface';
import { toast } from 'react-toastify';
import { setPageData, updatePageData } from 'redux/pages/actions';
import { showErrorMsg } from 'utils/utils';
import * as api from './api.service';

export const getPageData = async (url: string, dispatch?: any) => {
  dispatch && dispatch(updatePageData({ loaded: false, fullFilled: true }));
  try {
    const result = await api.get({ endpoint: url });
    dispatch && dispatch(updatePageData({ loaded: true, fullFilled: true }));
    return result;
  } catch (err: any) {
    dispatch && dispatch(updatePageData({ loaded: true, fullFilled: true }));
    toast.error(err?.response?.data?.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      progress: null,
    });
    showErrorMsg(err);
  }
};

export const onSetPageData = (data: IPageData) => (dispatch) => {
  const { breadcrumbs } = data;
  const defaultPath: IBreadcrumb[] = [
    {
      title: 'Trang chủ',
      route: '/',
    },
  ];

  const pageData = {
    ...data,
    breadcrumbs: defaultPath.concat(breadcrumbs),
    fullFilled: true,
    loaded: false,
  };
  dispatch(setPageData(pageData));

  return () => {
    const offPageData: IPageData = {
      loaded: false,
    };
    dispatch(setPageData(offPageData));
  };
};
