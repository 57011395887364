import { domainUrl } from '../constants';

export const API_GET_POST_ARTICLES = `${domainUrl}articles`;
export const API_GET_ARTICLE_DETAIL = `${domainUrl}articles/{id}`;
export const API_UPDATE_DELETE_ARTICLE = `${domainUrl}articles/{id}`;

// article category
export const API_GET_ARTICLE_CATEGORIES = `${domainUrl}article_categories`;
export const API_CREATE_ARTICLE_CATEGORY = `${domainUrl}article_categories`;
export const API_UPDATE_DELETE_ARTICLE_CATEGORY = `${domainUrl}article_categories/{id}`;
