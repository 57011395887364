import { PlusOutlined, FilterFilled } from '@ant-design/icons';
import { Button, Card, Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { IPageData } from 'interfaces/page-data.interface';
import { IBreadcrumb } from 'interfaces/page-data.interface';
import { IPagination } from 'interfaces/pagination.interface';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useLocation } from 'react-router-dom';
import { AppState } from 'redux/store';
import { setIsEditing } from 'redux/uiAction/actions';
import { onSetPageData } from 'services/pageData.service';
import { PageTitle } from 'ui/components/MaiNguyen/PageTitle';
import { SortableTable } from 'ui/components/SortableTable/SortableTable';

import './LayoutList.scss';

interface LayoutListInterface {
  pageTitle: string;
  showAddButton?: boolean;
  sortableTable?: boolean;
  handleClickAddButton?: () => void;
  rowSelection?: TableRowSelection<any>;
  breadcrumbs: IBreadcrumb[];
  columns: ColumnsType<any>;
  dataList: any[];
  filterNode?: ReactNode;
  rowKey?: string;
  tableClass?: string;
  pagination?: IPagination;
  topPagination?: boolean;
  loadingList?: boolean;
  handleChangePage?: (page: number) => void;
  rightToolbarComponent?: ReactNode;
  sortToolbarComponent?: ReactNode;
}

const LayoutList: FC<LayoutListInterface> = ({
  pageTitle,
  showAddButton = false,
  sortableTable = false,
  handleClickAddButton = null,
  breadcrumbs,
  columns,
  dataList,
  rowSelection,
  filterNode = null,
  rightToolbarComponent = null,
  rowKey = 'id',
  tableClass = '',
  pagination = null,
  topPagination = false,
  loadingList = false,
  sortToolbarComponent = null,
  handleChangePage,
}) => {
  const search = useLocation().search;
  const { isEditing } = useSelector((state: AppState) => state.uiActions);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const qsName = new URLSearchParams(search).get('name');
    const qsBrandId = new URLSearchParams(search).get('brandId');
    const qsCategoryId = new URLSearchParams(search).get('categoryId');
    const qsIsDelete = new URLSearchParams(search).get('isDeleted');
    const qsIsSecondhand = new URLSearchParams(search).get('isSecondhand');
    const qsProductType = new URLSearchParams(search).get('productType');
    const qsStatusId = new URLSearchParams(search).get('statusId');

    if (
      qsName ||
      qsBrandId ||
      qsCategoryId ||
      qsIsDelete ||
      qsIsSecondhand ||
      qsProductType ||
      qsStatusId
    ) {
      setShowFilter(!showFilter);
    }
  }, []);

  const pageData: IPageData = {
    title: (
      <PageTitle
        title={pageTitle}
        showButton={showAddButton}
        buttonText="Tạo mới"
        buttonIcon={<PlusOutlined rev={''} />}
        onClick={handleClickAddButton}
        showSaveButton={false}
      />
    ),
    breadcrumbs,
  };

  useEffect(() => {
    if (firstLoad) {
      dispatch(onSetPageData(pageData));
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    dispatch(setIsEditing(false));
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (isEditing) {
        return 'Changes you made may not be saved.';
      }
    };
  }, [isEditing]);

  const handleShowFilter = useCallback(() => {
    setShowFilter(!showFilter);
  }, [showFilter]);

  return (
    <>
      <div className="layout-list">
        {(filterNode || rightToolbarComponent) && (
          <>
            <div className="mb-3 layout-list__toolbar">
              <div className="filter-toolbar">
                <Button
                  className="filter-button"
                  title="Filter"
                  icon={<FilterFilled rev={''} />}
                  onClick={handleShowFilter}
                >
                  Filter
                </Button>
                {React.isValidElement(sortToolbarComponent) &&
                  React.cloneElement(sortToolbarComponent)}
              </div>
              {React.isValidElement(rightToolbarComponent) &&
                React.cloneElement(rightToolbarComponent)}
            </div>
            <Card hidden={!showFilter}>{filterNode}</Card>
          </>
        )}

        {pagination && topPagination && (
          <div className="mb-3 layout-list-pagination">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={handleChangePage}
              disabled={loadingList}
            />
          </div>
        )}

        {sortableTable ? (
          <SortableTable
            className={tableClass}
            columns={columns}
            dataList={dataList}
            rowKey={rowKey}
            loading={loadingList}
          />
        ) : (
          <Table
            className={tableClass}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={dataList}
            rowKey={rowKey}
            pagination={false}
            loading={loadingList}
          />
        )}

        {pagination && (
          <div className="layout-list-pagination">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={handleChangePage}
              disabled={loadingList}
            />
          </div>
        )}
      </div>
      <Prompt when={isEditing} message="Những thay đổi sẽ bị mất." />
    </>
  );
};

export default LayoutList;
