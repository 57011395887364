import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button, Pagination, Table } from 'antd';
import { debounce } from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Models
import emptyImg from 'assets/img/empty.png';
import { defaultPagination } from 'constants/constants';
import { AlignType } from 'constants/enum';
import { useSizeGroups, useDelSizeGroup } from 'hooks/useSizeGroups';
import { IPagination } from 'interfaces/pagination.interface';
import { ISizeGroup, ISizeGroupAttr } from 'interfaces/size.interface';

// Components
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { SizeGroupModel } from 'models/size-groups.model';
import FilterGroupSize from './components/Filter/FilterSizeGroup';
import FormSizeGroup from './components/Form/FormSizeGroup';
import GroupSizeMenu from '../components/Menu/Menu';

// styles
import './SizeGroups.scss';

const newSizeGroup = new SizeGroupModel({});

const SystemSizeGroups: React.FC = () => {
  const location = useLocation();

  const parsedSearch = useMemo(() => queryString.parse(location.search), [location]);

  // search value
  const [searchValue, setSearchValue] = useState<string>(
    () => (parsedSearch.search as string) || '',
  );
  // pagination
  const [paging, setPaging] = useState<IPagination>(() => {
    return {
      ...defaultPagination,
      current: parsedSearch.page ? Number(parsedSearch.page) : defaultPagination.current,
    };
  });

  // Hook:  get list of sizeGroups
  const { loading, sizeGroups, pagination, getSizeGroups } = useSizeGroups(
    paging.current,
    searchValue,
    true,
  );
  // Hook: actions of sizeGroups
  const { sizeGroup, openModal, deleteSizeGrp, showModal, closeModal } =
    useDelSizeGroup(getSizeGroups);

  // on change page
  const onChangePage = useCallback(
    (page: number) => {
      setPaging({ ...pagination, current: page });
    },
    [pagination],
  );

  // Filter size group
  const doFilter = useCallback(
    debounce((val: string) => {
      setSearchValue(val);
      onChangePage(1);
    }, 600),
    [],
  );

  // config table
  const groupSizeTable = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'attributes',
      render: (attr: ISizeGroupAttr, rowData: ISizeGroup) => {
        const { image, title } = attr;
        return (
          <div className="size-img">
            <img
              src={(image && image['128x128']) || emptyImg}
              alt={title}
              onClick={() => showModal(rowData)}
            />
          </div>
        );
      },
    },
    {
      title: 'Tên nhóm size',
      dataIndex: 'attributes',
      render: (attr: ISizeGroupAttr, rowData: ISizeGroup) => {
        const { title } = attr;
        return (
          <div className="attr-col" onClick={() => showModal(rowData)}>
            {title}
          </div>
        );
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: string) => {
        return <div>{id}</div>;
      },
    },
    {
      title: '',
      dataIndex: '',
      align: AlignType.right,
      render: (attr: ISizeGroup) => {
        const { id } = attr;
        return <Button title="Xóa" icon={<DeleteOutlined />} onClick={() => deleteSizeGrp(id)} />;
      },
    },
  ];

  return (
    <LayoutDetail
      isAddNew
      pageTitle="Hệ Size"
      breadcrumbs={[
        {
          title: 'Hệ Size',
          route: '/he-size',
        },
      ]}
      pageClassName="product-category-page bg-transparent"
    >
      <Row gutter={16}>
        <Col span={6}>
          {/* MENU */}
          <GroupSizeMenu />
        </Col>

        <Col span={18}>
          {/* FILTER */}
          <Row gutter={24}>
            <Col span={18}>
              <FilterGroupSize searchValue={searchValue} onFilterChange={doFilter} />
            </Col>
            <Col span={6} className="text-right">
              <Button icon={<PlusOutlined />} onClick={() => showModal(newSizeGroup)}>
                Tạo mới
              </Button>
            </Col>
          </Row>
          {/* LIST VIEW */}
          {pagination && (
            <div className="layout-list-pagination mb-3">
              <Pagination
                {...pagination}
                pageSizeOptions={[]}
                onChange={onChangePage}
                disabled={loading}
              />
            </div>
          )}
          <Table
            rowKey="id"
            columns={groupSizeTable}
            dataSource={sizeGroups}
            pagination={false}
            loading={loading}
          />
          {pagination && (
            <div className="layout-list-pagination mt-3">
              <Pagination
                {...pagination}
                pageSizeOptions={[]}
                onChange={onChangePage}
                disabled={loading}
              />
            </div>
          )}
        </Col>
      </Row>

      {/* Form Add/Edit Size Group */}
      {sizeGroup && (
        <FormSizeGroup
          openModal={openModal}
          sizeGroupItem={sizeGroup}
          onCloseModal={closeModal}
          onRefreshList={getSizeGroups}
        />
      )}
    </LayoutDetail>
  );
};

export default SystemSizeGroups;
