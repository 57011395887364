import { Customer } from 'interfaces/customer.interface';
import { useDispatch } from 'react-redux';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { API_MEMBER } from 'constants/api/member.api';

export const useFetchMember = (id: string) => {
  const dispatch = useDispatch();
  const { data, isLoading, mutate } = useSWR(
    id ? `${API_MEMBER.replace('{id}', id)}` : null,
    (url: string) => getPageData(url, dispatch),
  );

  return {
    isFetching: isLoading,
    data: data?.data as Customer,
    mutate,
  };
};

/**
 *
 */
export const useUpdateMember = () => {
  const { isMutating, trigger } = useSWRMutation(
    API_MEMBER,
    async (endpoint: string, { arg }: { arg: { id: string; data: any } }) => {
      const res = await api.put({
        endpoint: endpoint.replace('{id}', arg.id),
        data: arg.data,
      });
      return res?.data;
    },
  );

  return {
    isUpdating: isMutating,
    onUpdate: trigger,
  };
};
