/* eslint-disable @typescript-eslint/no-use-before-define */
import { IPagination } from 'interfaces/pagination.interface';
import { FilterCoupons } from 'pages/Coupons/components/FilterView';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as api from 'services/api.service';
import { fetcherWithArg } from 'services/swr.utils';
import useSWRMutation from 'swr/mutation';
import { buildParams } from 'utils/utils';
import { API_COUPONS } from 'constants/api/coupons.api';
import {
  API_MEMBER_LEVELS,
  API_MEMBER_LEVEL_DETAIL,
  API_MEMBER_GROUP_LEVELS,
} from 'constants/api/member.api';
import { defaultPagination } from 'constants/constants';

/**
 * Custom hook that fetches and returns coupons data.
 */
export const useFetchCoupons = () => {
  const location = useLocation();
  const { data, isMutating, trigger } = useSWRMutation(API_COUPONS, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  const fetchCoupons = useCallback(
    async (
      params?: Partial<Omit<FilterCoupons, 'page'>> & {
        page?: number;
        ignoreIds?: string[];
      },
    ): Promise<void> => {
      const { page = pagination.current, ignoreIds, ...rest } = params || {};
      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;

      const query = {
        'ignoreIds[]': ignoreIds,
        page: calcPage,
        ...rest,
      };

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage, location.search],
  );

  return {
    isFetching: isMutating,
    data: data?.data,
    pagination,
    setPagination,
    refetch: fetchCoupons,
  };
};

/**
 * Custom hook that fetches and returns member level
 */
export const useFetchLevels = () => {
  const history = useHistory();
  const location = useLocation();
  const { data, isMutating, trigger } = useSWRMutation(API_MEMBER_GROUP_LEVELS, fetcherWithArg);

  useEffect(() => {
    fetchLevels();
  }, []);

  const fetchLevels = useCallback(async (): Promise<void> => {
    await trigger();
  }, []);

  // TODO: add params for url
  const addUrlParams = useCallback(
    async (params?: { levelId?: number; rankId?: number }): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const query = {
        ...locationParams,
        ...params,
      };

      history.push(
        queryString.stringifyUrl(
          {
            url: location.pathname,
            query,
          },
          { skipEmptyString: true, skipNull: true },
        ),
      );
    },
    [location.search],
  );

  return {
    isFetching: isMutating,
    data: data?.data,
    addUrlParams,
    refetch: fetchLevels,
  };
};

/**
 * Custom hook that fetches and returns member level detail
 */
export const useFetchLevel = (levelId) => {
  const { data, isMutating, trigger } = useSWRMutation(
    levelId ? `${API_MEMBER_LEVEL_DETAIL.replace('{id}', levelId)}` : null,
    fetcherWithArg,
  );

  return {
    isFetching: isMutating,
    data: data?.data,
    refetch: trigger,
  };
};

/**
 * Custom hook that create member level
 */
export const useCreateLevel = () => {
  const { isMutating, trigger } = useSWRMutation(
    API_MEMBER_LEVELS,
    async (endpoint: string, { arg }: { arg: { data: any } }) => {
      const res = await api.post({
        endpoint: endpoint,
        data: arg.data,
      });
      return res?.data;
    },
  );

  return {
    isCreating: isMutating,
    onCreate: trigger,
  };
};

/**
 * Custom hook that update member level
 */
export const useUpdateLevel = () => {
  const { isMutating, trigger } = useSWRMutation(
    API_MEMBER_LEVEL_DETAIL,
    async (endpoint: string, { arg }: { arg: { id: string; data: any } }) => {
      const res = await api.post({
        endpoint: endpoint.replace('{id}', arg.id),
        data: arg.data,
      });
      return res?.data;
    },
  );

  return {
    isUpdating: isMutating,
    onUpdate: trigger,
  };
};
