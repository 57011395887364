/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/order */
import { RcFile } from 'antd/lib/upload';
import { IPrdCategory } from 'interfaces/product-category.interface';
import { useCallback, useState } from 'react';
import { fetcher, updater, poster } from 'services/swr.utils';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { deleteModal } from 'utils/modals';
import { showErrorMsg, uploadFile } from 'utils/utils';
import { API_GET_POST_ATTRIBUTE_SETS } from 'constants/api/attribute.api';
import { API_PRODUCT_CATEGORIES, API_PRODUCT_CATEGORY } from 'constants/api/product-category.api';
import { ImageTypes } from './components/EditForm';

/**
 * Custom hook that fetches and returns the attribute groups data.
 */
export const useFetchAttributeGroup = () => {
  const { data } = useSWR(`${API_GET_POST_ATTRIBUTE_SETS}?perPage=all`, fetcher);
  return { data };
};

/**
 * Custom hook that provides functions to create, update, and remove product categories.
 */
export const useProductCategory = () => {
  const { isMutating: isCreating, trigger: onCreate } = useSWRMutation(
    API_PRODUCT_CATEGORIES,
    poster,
  );
  const { isMutating: isUpdating, trigger: triggerProductCategory } = useSWRMutation(
    API_PRODUCT_CATEGORY,
    updater,
  );

  const [isUploadImage, setIsUploadImage] = useState<boolean>(false);

  const onUpdate = useCallback(async (category, images: ImageTypes) => {
    try {
      setIsUploadImage(true);

      // TODO - images.smallImage
      let imageRes = null;
      const { id, attributes } = category;

      const res = await triggerProductCategory({ method: 'put', id, data: attributes });
      const resCate = res?.data;

      if (images?.ogImage?.file) {
        imageRes = await uploadFile(images.ogImage.file as RcFile, 'category', id, 'thumb');
      }

      return {
        ...resCate,
        attributes: {
          ...resCate.attributes,
          parentId: `${resCate.attributes.parentId}`,
          image: imageRes ? { ...imageRes?.resize, id: imageRes?.id } : {},
        },
      };
    } catch (error) {
      showErrorMsg(error);
    } finally {
      setIsUploadImage(false);
    }
  }, []);

  const _onRemove = useCallback(
    ({ id, name }: { id: string; name: string }, cbSuccess: () => void) => {
      deleteModal({
        title: `Bạn chắc chắn xóa nhóm: <strong>${name}</strong> này?`,
        onDeleteCb: async () => {
          try {
            await triggerProductCategory({ method: 'remove', id });
            cbSuccess();
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [triggerProductCategory],
  );

  const onUpdateCategoryPosition = useCallback(async (parentId: number, dragNode: IPrdCategory) => {
    try {
      const { attributes, id } = dragNode;
      await triggerProductCategory({
        method: 'put',
        id,
        data: { ...attributes, parentId },
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, []);

  return {
    isCreating,
    isUpdating: isUpdating || isUploadImage,
    onCreate,
    onUpdate,
    onRemove: _onRemove,
    onUpdateCategoryPosition,
  };
};
