import { Input, Row, Col, Form } from 'antd';

import { usePresentBlocks } from 'hooks/usePresentBlocks';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { TemplateBlock } from 'ui/components/MaiNguyen/TemplateBlock';

import './HomePresent.scss';

const Badge = ({ isCircle = false }) => {
  return (
    <div className="badge">
      {isCircle ? <div className="badge-circle" /> : <div className="badge-square" />}
      <div className="badge-line" />
      <div className="badge-line" />
    </div>
  );
};

const ProductItem = () => {
  return (
    <div className="product-item">
      <div className="product-image">photo</div>
      <div className="product-line-1" />
      <div className="product-line-2" />
    </div>
  );
};

const NewsItem = () => {
  return (
    <div className="news-item">
      <div className="news-image">Photo</div>
      <div className="news-line">
        <div className="line" />
        <div className="line" />
      </div>
    </div>
  );
};

const HomePresent = () => {
  const {
    reloadList,
    block,
    isOpen,
    handleClosePopup,
    handleOpenModalBlock,
    handleUploadFileContent,
    handleChangeValues,
    handleSaveBlock,
    handleDeleteImage,
    handleChangeJsonData,
    handleUpdateHighendBlock,
    handleUpdateMultipleImages,
    handleUpdateShockpriceBlock,
    handleChangeActive,
    handleUpdateBlockLogo,
    handleUpdateCollections,
    onUpdateTopHeaderBlock,
  } = usePresentBlocks();

  const [form] = Form.useForm();

  return (
    <>
      <LayoutDetail
        pageTitle="Trình bày trang home"
        breadcrumbs={[
          {
            title: 'Trình bày trang home',
            route: '/trinh-bay-trang-home',
          },
        ]}
        isAddNew
        wrapperCard={false}
        handleValuesChange={null}
        formHook={null}
        pageClassName="home-present-page"
        saveLoading={reloadList}
      >
        <Row gutter={16}>
          <Col span={24}>
            <div
              className="block-item seo"
              onClick={handleOpenModalBlock('metaDataHomePage', 'metaData')}
            >
              Quản lý SEO (home page)
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div
              className="block-item top-header"
              onClick={handleOpenModalBlock('topHeaderMsg', 'topHeaderMsg')}
            >
              Thông báo (Top Header)
            </div>
          </Col>
        </Row>
        <div className="header-home">
          <div
            className="headerLogo block-item"
            onClick={handleOpenModalBlock('headerLogo', 'logo')}
          >
            mai nguyen logo
          </div>
          <div
            className="headerSearch block-item"
            onClick={handleOpenModalBlock('headerSearch', 'headerSearch')}
          >
            <Input.Search />
          </div>
        </div>
        <div className="main-content">
          <Row gutter={16} align="stretch">
            <Col span={16}>
              <div
                className="homeSpotlightBanner block-item"
                onClick={handleOpenModalBlock('homeSpotlightBanner', 'homeSpotlightBanner')}
              >
                main slider - home page
              </div>
            </Col>
            <Col span={8}>
              <div
                className="homeSpotlightRightBanner block-item"
                onClick={handleOpenModalBlock(
                  'homeSpotlightRightBanner',
                  'homeSpotlightRightBanner',
                )}
              >
                right banner home page
              </div>
            </Col>
            <Col span={24}>
              <div
                className="homeSpotlightBadges block-item"
                onClick={handleOpenModalBlock('homeSpotlightBadges', 'homeSpotlightBadges')}
              >
                <Badge />
                <Badge />
                <Badge />
                <Badge />
                <Badge />
                <Badge />
                <Badge />
              </div>
            </Col>
            <Col span={24}>
              <div
                className="homeBannerMid block-item"
                onClick={handleOpenModalBlock('homeBannerMid', 'logo')}
              >
                banner
              </div>
            </Col>
            <Col span={24}>
              <div
                className="homeShockPrice block-item"
                onClick={handleOpenModalBlock('homeShockPrice', 'homeShockPrice')}
              >
                <div className="shock-price-title">Giá sốc hôm nay</div>
                <div className="shock-price">
                  <ProductItem />
                  <ProductItem />
                  <ProductItem />
                  <ProductItem />
                  <ProductItem />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div
                className="bannerMidSmall1 block-item"
                onClick={handleOpenModalBlock('bannerMidSmall1', 'logo')}
              >
                banner
              </div>
            </Col>
            <Col span={8}>
              <div
                className="bannerMidSmall2 block-item"
                onClick={handleOpenModalBlock('bannerMidSmall2', 'logo')}
              >
                banner
              </div>
            </Col>
            <Col span={8}>
              <div
                className="bannerMidSmall3 block-item"
                onClick={handleOpenModalBlock('bannerMidSmall3', 'logo')}
              >
                banner
              </div>
            </Col>
            <Col span={24}>
              <div
                className="categoriesIconMenu block-item"
                onClick={handleOpenModalBlock('categoriesIconMenu', 'categoriesIconMenu')}
              >
                <div className="category-title">Danh mục sản phẩm</div>
                <div className="category">
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                  <Badge isCircle />
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div
                className="homeVideos block-item"
                onClick={handleOpenModalBlock('homeCollections', 'homeCollections')}
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <div className="main-video">Bộ sưu tập 1</div>
                  </Col>
                  <Col span={8}>
                    <div className="main-video">Bộ sưu tập 2</div>
                  </Col>
                  <Col span={8}>
                    <div className="main-video">Bộ sưu tập 3</div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24}>
              <div
                className="productTabs block-item"
                onClick={handleOpenModalBlock('productTabs', 'productTabs')}
              >
                Hàng mới về
              </div>
            </Col>
            <Col span={24}>
              <div
                className="luxuryBlock block-item"
                onClick={handleOpenModalBlock('luxuryBlock', 'luxuryBlock')}
              >
                Luxury Block
              </div>
            </Col>
            <Col span={18}>
              <div
                className="homeVideos block-item"
                onClick={handleOpenModalBlock('homeVideos', 'homeVideos')}
              >
                <Row gutter={16}>
                  <Col span={18}>
                    <div className="main-video">video</div>
                  </Col>
                  <Col span={6}>
                    <div className="main-video">video</div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={6}>
              <div
                className="homeNews block-item"
                onClick={handleOpenModalBlock('homeNews', 'homeNews')}
              >
                <div className="home-news-title">NEWS_HOMES</div>
                <div className="news-contents">
                  <NewsItem />
                  <NewsItem />
                  <NewsItem />
                  <NewsItem />
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div
                className="footerMenus block-item"
                onClick={handleOpenModalBlock('footerMenus', 'footerMenus')}
              >
                footer menus
              </div>
            </Col>
            <Col span={24}>
              <div className="footer block-item" onClick={handleOpenModalBlock('footer', 'footer')}>
                <div>footer trên wireframe</div>
                <div className="header-home">
                  <div
                    className="footerLogo block-item"
                    onClick={handleOpenModalBlock('footerLogo', 'logo')}
                  >
                    mai nguyen logo
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </LayoutDetail>
      <CustomPopup
        title="Display Block"
        isOpen={isOpen}
        onCloseModal={handleClosePopup}
        handleSaveButton={handleSaveBlock}
        formHook={form}
        handleValuesChange={handleChangeValues}
      >
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name">
                <div>{block?.attributes?.name}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Position">
                <div>{block?.attributes?.position}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Template">
                <div>{block?.attributes?.template}</div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <TemplateBlock
                block={block}
                template={block?.attributes?.template}
                onAddImage={handleUploadFileContent}
                onDeleteImage={handleDeleteImage}
                onChangeJsonData={handleChangeJsonData}
                onUpdateSlider={handleUpdateMultipleImages}
                onUpdateHighendBlock={handleUpdateHighendBlock}
                onUpdateShockPriceBlock={handleUpdateShockpriceBlock}
                onChangeActive={handleChangeActive}
                handleUpdateBlockLogo={handleUpdateBlockLogo}
                handleUpdateCollections={handleUpdateCollections}
                onUpdateTopHeaderBlock={onUpdateTopHeaderBlock}
              />
            </Col>
          </Row>
        </>
      </CustomPopup>
    </>
  );
};

export default HomePresent;
