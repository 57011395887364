import { Badge, Menu, Dropdown, Avatar } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import { IProduct } from 'interfaces/product.interface';
import { useFetchReviewPending } from 'pages/Members/Review/hook';
import { AppState } from 'redux/store';
import { logout } from 'services/auth.service';
import { TimeAgo } from 'ui/components/MaiNguyen/TimeAgo';

import './Actions.scss';
import { truncateWords } from 'utils/utils';

const userState = (state: AppState) => state.user;
const commentState = (state: AppState) => state.comment;
const reviewSelector = (state: AppState) => state.review;

const renderReviewProdName = (includedProducts: IProduct[], productId: number) => {
  const filterData = includedProducts.find((item) => Number(item.id) === productId);

  if (!isEmpty(filterData)) {
    const { name } = filterData.attributes || {};

    return name;
  }
};

const Actions: React.FC = () => {
  const { user } = useSelector(userState);
  const { comments } = useSelector(commentState);
  const { reloadAllReviews } = useSelector(reviewSelector);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    data: reviewList,
    totalItems,
    includedProducts,
    fetchReviewList,
  } = useFetchReviewPending();

  // reload review pending list
  useEffect(() => {
    if (reloadAllReviews) {
      fetchReviewList();
    }
  }, [reloadAllReviews]);

  const handleLogout = useCallback(async () => {
    await dispatch(logout());
    history.push('/dang-nhap');
  }, []);

  const accountMenu = (
    <Menu style={{ minWidth: '220px' }}>
      <Menu.Item>
        <NavLink
          to={{
            pathname: `/users/${user?.slug}`,
          }}
        >
          Trang cá nhân
        </NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <span className="d-flex justify-content-between align-item-center">
          <a role="button" className="d-flex w-100" onClick={handleLogout}>
            Đăng xuất
          </a>
          <span className="d-flex align-items-center icofont-logout" />
        </span>
      </Menu.Item>
    </Menu>
  );

  const notificationsMenu = (
    <Menu style={{ minWidth: '280px', maxWidth: '350px' }}>
      <h3 className="dropdown-title">Bình luận mới</h3>
      {comments.map((item) => (
        <Menu.Item key={`comment-${item.id}`}>
          <div className="d-flex">
            <div className="text d-flex flex-column">
              <NavLink
                to={{ pathname: '/comments', search: `url=${item.url}` }}
                className="notify-link"
              >
                {truncateWords(item.comment, 20)}
              </NavLink>
              <span className="sub-title">
                <TimeAgo date={new Date(item.createdAt)} />
              </span>
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  {
    /* Menu reviews */
  }
  const reviewsMenu = (
    <Menu style={{ minWidth: '280px', maxWidth: '350px' }}>
      <h3 className="dropdown-title">Review mới</h3>
      {reviewList?.slice(0, 5).map((item) => {
        const { content, createdAt, productId } = item.attributes;

        return (
          <Menu.Item key={`mn-review-${item.id}`}>
            <div className="d-flex">
              <NavLink
                className="text d-flex flex-column notify-review-item"
                to={{ pathname: '/member-reviews' }}
              >
                <span className="title">{truncateWords(content, 20)}</span>
                {renderReviewProdName(includedProducts, productId) && (
                  <span className="prod-name">
                    {renderReviewProdName(includedProducts, productId)}
                  </span>
                )}
                <span className="sub-title">
                  <TimeAgo date={new Date(createdAt)} />
                </span>
              </NavLink>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className="actions">
      {/* Review */}
      <Dropdown className="item mr-3" overlay={reviewsMenu} trigger={['click']}>
        <Badge
          style={{
            alignItems: 'center',
            background: 'linear-gradient(250deg, #bd0000, #db0000 65%, #ff1717)',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center',
            height: 18,
            minWidth: 18,
          }}
          className={`${totalItems === 0 ? 'blocked' : ''}`}
          count={totalItems}
        >
          <span className="icon notification-icon icofont-star" style={{ fontSize: '24px' }} />
        </Badge>
      </Dropdown>
      {/* Notifications */}
      <Dropdown className="item mr-3" overlay={notificationsMenu} trigger={['click']}>
        <Badge
          style={{
            alignItems: 'center',
            background: 'linear-gradient(250deg, #bd0000, #db0000 65%, #ff1717)',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center',
            height: 18,
            minWidth: 18,
          }}
          count={comments.length}
        >
          <span
            className="icon notification-icon icofont-notification"
            style={{ fontSize: '24px' }}
          />
        </Badge>
      </Dropdown>
      {/* Account */}
      <Dropdown overlay={accountMenu} trigger={['click']}>
        <div className="item">
          <Avatar className="mr-1" style={{ background: '#d0d2d8', color: 'black' }}>
            {`${user?.firstName?.substring(0, 1)}${user?.lastName?.substring(0, 1)}`}
          </Avatar>
          <span className="icofont-simple-down" />
        </div>
      </Dropdown>
    </div>
  );
};

export default Actions;
