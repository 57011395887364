import { CheckCircleTwoTone, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { ICollection, ICollectionAttributes } from 'interfaces/collection.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';

import './Collections.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteModal } from 'utils/modals';
import { AlignType, StoreIds } from 'constants/enum';
import { CreateCollectionModal } from './components/CreateCollectionModal';
import { FilterCollections, FilterView } from './components/FilterView';
import { useFetchCollections, useRemoveCollections } from './hooks';

const Collections: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    isFetching,
    data,
    pagination,
    setPagination,
    refetch: refetchCollections,
  } = useFetchCollections();
  const { onRemove } = useRemoveCollections();

  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);

  /**
   * A callback function that handles page changes in pagination.
   *
   * @param {number} page - The new page number.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await refetchCollections({ page });
    },
    [refetchCollections],
  );

  /**
   * A callback function that handles filter changes for collections.
   *
   * @param {FilterCollections} filter - The new filter values.
   */
  const onFilterChange = useCallback(
    async (filter: FilterCollections): Promise<void> => {
      await refetchCollections({ page: 1, ...filter });
    },
    [refetchCollections],
  );

  /**
   * A callback function that handles the removal of a collection.
   *
   * @param {string} id - The ID of the collection to remove.
   */
  const onRemoveCollection = useCallback(
    (id: string): void => {
      deleteModal({
        title: 'Bạn có muốn xóa collection này?',
        onDeleteCb: async () => {
          try {
            await onRemove({ method: 'remove', id });
            await refetchCollections();
            message.success('Xoá collection thành công');
          } catch (error: any) {
            message.error(error?.message || error);
          }
        },
      });
    },
    [refetchCollections],
  );

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_: ICollectionAttributes, __: ICollection, index: number) => index + 1,
    },
    {
      title: 'Collection',
      dataIndex: 'attributes',
      render: (attr: ICollectionAttributes, row: ICollection) => {
        return (
          <div
            className="collection-name"
            onClick={() => {
              history.push(`/bo-suu-tap/${row.id}`, queryString.parse(location.search));
            }}
          >
            <strong>{attr?.title}</strong>
          </div>
        );
      },
    },
    {
      title: 'Số lượng',
      dataIndex: 'attributes',
      render: (attr: ICollectionAttributes) => {
        const { productIds } = attr;
        const total = productIds?.split(',')?.filter((o) => o)?.length || 0;
        return total;
      },
    },
    {
      title: 'SEO',
      dataIndex: 'attributes',
      render: (attr: ICollectionAttributes) => {
        if (attr.seo.keywords && attr.seo.description && !isEmpty(attr.seo.image)) {
          return <CheckCircleTwoTone twoToneColor="#52c41a" />;
        }
        return <CloseCircleOutlined twoToneColor="#333" />;
      },
    },
    {
      title: 'Status',
      dataIndex: 'attributes',
      render: (attr: ICollectionAttributes) => {
        return attr?.active ? 'Publish' : 'Unpublish';
      },
    },
    {
      title: 'Cửa hàng',
      dataIndex: 'attributes',
      render: (attr: ICollectionAttributes) => {
        return attr?.storeId === StoreIds.mainguyen ? 'Mai Nguyen' : 'Mai Nguyen Rider';
      },
    },
    {
      title: 'Thao tác',
      align: AlignType.right,
      dataIndex: '',
      render: (row: ICollection) => (
        <Button onClick={() => onRemoveCollection(row.id)} title="Xóa" icon={<DeleteOutlined />} />
      ),
    },
  ];

  return (
    <>
      <LayoutList
        pageTitle="Quản lý Collection"
        breadcrumbs={[
          {
            title: 'Quản lý Collection',
            route: '/bo-suu-tap',
          },
        ]}
        showAddButton
        handleClickAddButton={() => setIsOpenCreateModal(true)}
        loadingList={isFetching}
        columns={columns}
        dataList={data}
        tableClass="collections-table"
        pagination={pagination}
        handleChangePage={onPageChange}
        topPagination
        filterNode={<FilterView onFilter={onFilterChange} />}
      />
      <CreateCollectionModal
        isOpen={isOpenCreateModal}
        onClose={() => setIsOpenCreateModal(false)}
      />
    </>
  );
};

export default Collections;
