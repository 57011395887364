/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Card, Col, Form, Input, message } from 'antd';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setIsEditing } from 'redux/uiAction/actions';
import isEmpty from 'lodash/isEmpty';
import { useFetchAccessToken, useSaveAccessToken } from './hooks';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';

const InstagramTokenManager: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { isFetching, data } = useFetchAccessToken();
  const { isSaving, onSaveToken } = useSaveAccessToken();

  /**
   * A hook that sets the form fields values based on the data attributes when the data ID changes.
   *
   * @param {Object} data - The data object containing the attributes.
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const { instagramToken } = data?.attributes;
      form.setFieldsValue({ instagramToken });
      dispatch(setIsEditing(false));
    }
  }, [data]);

  /**
   * A callback function that saves a coupon by updating its data using the form values.
   *
   * @throws {Error} - An error that occurred during the coupon update.
   */
  const onSave = useCallback(async (values): Promise<void> => {
    try {
      await onSaveToken({ data: values }, { revalidate: false });
      message.success('Cập nhật accessToken thành công');
    } catch (error: any) {
      const msg = error?.message || error;
      message.error(msg);
    } finally {
      dispatch(setIsEditing(false));
    }
  }, []);

  if (isFetching || !data) return null;

  return (
    <>
      <LayoutDetail
        pageTitle="Quản lý Instagram Token"
        isAddNew={false}
        pageClassName="instagram-token-manager"
        breadcrumbs={[
          {
            title: 'Quản lý Instagram Token',
            route: '/quan-ly-instagram-token',
          },
        ]}
        wrapperCard={false}
      >
        <Form form={form} layout="vertical" onFinish={onSave}>
          <Card>
            <Col span={24}>
              <Form.Item
                label="Access Token"
                name="instagramToken"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập accessToken',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <ThemeButton htmlType="submit" loading={isSaving}>
              Lưu
            </ThemeButton>
          </Card>
        </Form>
      </LayoutDetail>
    </>
  );
};

export default InstagramTokenManager;
