/* eslint-disable camelcase */
import { Button, Col, Form, Input, Row, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import React, { memo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReviewStatus, StoreIds, scoreList } from 'constants/enum';

export interface FilterReviewPage {
  customerName: string;
  storeId: string;
  score: string;
  status?: ReviewStatus;
}

interface Props {
  onFilter: (filter: FilterReviewPage) => void;
}

export const FilterView: React.FC<Props> = memo((props) => {
  const { onFilter } = props;
  const [form] = Form.useForm();

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (!isEmpty(params)) {
      const { ...rest } = params || {};
      form.setFieldsValue({
        ...rest,
      });
    }
  }, [params]);

  const onClear = useCallback(() => {
    form.resetFields();
    onFilter({
      customerName: '',
      storeId: '',
      score: '',
    } as FilterReviewPage);
  }, [onFilter]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => {
        onFilter({
          ...values,
        });
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Tên người dùng" name="customerName">
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Cửa hàng" name="storeId">
            <Select
              className="ant-select-lg"
              options={[
                { value: `${StoreIds.mainguyen}`, label: 'Mai Nguyen' },
                { value: `${StoreIds.rider}`, label: 'Mai Nguyen Rider' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Sao" name="score">
            <Select className="ant-select-lg" options={scoreList} />
          </Form.Item>
        </Col>
        <Col
          span={24}
          className="align-items-center justify-content-end"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button htmlType="submit" className="secondary-button">
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={onClear}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
