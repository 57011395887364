import { domainUrl } from '../constants';

export const API_COUPONS = `${domainUrl}coupons`;
export const API_COUPON_BY_ID = `${domainUrl}coupons/{id}`;
export const API_PRODUCTS_OF_COUPON = `${domainUrl}coupons/{id}/products`;
export const API_COUPON_HISTORY_LIST = `${domainUrl}coupons/{id}/histories`;
export const API_COUPON_DOWNLOAD = `${domainUrl}coupons/{id}/download`;
export const API_ORDER_DETAILS = `${domainUrl}frontend/orders/{code}`;
export const API_COUPON_BIRTHDAY = `${domainUrl}coupons/birthday`;
export const API_COUPON_VOUCHER = `${domainUrl}coupons/voucher`;
