import { DeleteOutlined } from '@ant-design/icons';
import { Button, Switch, Form, Input, Row, Col } from 'antd';
import { clone } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import {
  API_GET_POST_PROMOTIONS,
  API_GET_UPDATE_DELETE_PROMOTION,
} from 'constants/api/promotion.api';
import { defaultPagination } from 'constants/constants';
import { AlignType, DateFormat, DiscountUnit } from 'constants/enum';
import { IPagination } from 'interfaces/pagination.interface';
import { IAttrPromotion, IPromotion, IFilterPromotion } from 'interfaces/promotion.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import { PromotionModel, FilterPromotionModel } from 'models/promotion.model';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { deleteModal } from 'utils/modals';
import { formatNumber, showErrorMsg } from 'utils/utils';

const defaultFilterPromotion: IFilterPromotion = {
  name: '',
  sku: '',
};

import './Promotion.scss';

const Promotion = () => {
  const search = useLocation().search;
  const location = useLocation();
  const parsedSearch = useMemo(() => queryString.parse(location.search), [location]);

  const [firstLoad, setFirstLoad] = useState(true);
  const [reloadList, setReloadList] = useState(false);
  const [pagination, setPagination] = useState<IPagination>(() => {
    return {
      ...defaultPagination,
      current: parsedSearch?.page ? Number(parsedSearch?.page) : defaultPagination.current,
    };
  });
  const [promotions, setPromotions] = useState<IPromotion[]>([]);
  const [filterContent, setFilterContent] = useState<IFilterPromotion>(() => {
    return {
      name: (parsedSearch?.name as string) || defaultFilterPromotion.name,
      sku: (parsedSearch?.sku as string) || defaultFilterPromotion.sku,
    };
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const [filterForm] = Form.useForm();

  const getPromotionsApiParams = useMemo(
    () =>
      queryString.stringify(
        {
          page: pagination.current,
          name: filterContent.name,
          sku: filterContent.sku,
        },
        { skipEmptyString: true },
      ),
    [pagination.current, filterContent],
  );

  const assignFilterValueToURL = useCallback((): void => {
    history.push(
      queryString.stringifyUrl({
        url: location.pathname,
        query: {
          page: pagination.current,
          name: filterContent?.name,
          sku: filterContent?.sku,
        },
      }),
    );
  }, [pagination.current, filterContent.name, filterContent.sku]);

  useEffect(() => {
    if (firstLoad) {
      getPageData(`${API_GET_POST_PROMOTIONS}?${getPromotionsApiParams}`, dispatch).then((res) => {
        const pagin = res?.meta?.pagination;
        setPromotions(res?.data);
        setPagination({
          current: pagin.currentPage,
          pageSize: pagin.perPage,
          total: pagin.total,
        });
        setFirstLoad(false);
        assignFilterValueToURL();
      });
    }
  }, [firstLoad]);

  useEffect(() => {
    if (reloadList) {
      api
        .get({
          endpoint: `${API_GET_POST_PROMOTIONS}?${getPromotionsApiParams}`,
        })
        .then((res) => {
          const pagin = res?.meta?.pagination;
          setPromotions(res?.data);
          setReloadList(false);
          setPagination({
            current: pagin.currentPage,
            pageSize: pagin.perPage,
            total: pagin.total,
          });
          assignFilterValueToURL();
        });
    }
  }, [reloadList, pagination, filterContent]);

  const handleAddNewPromotion = useCallback(() => {
    history.push('/khuyen-mai/new');
  }, []);

  const handleRemovePromotion = useCallback(
    (id: string) => () => {
      deleteModal({
        title: 'Bạn có muốn xóa khuyến mãi này?',
        onDeleteCb: async () => {
          try {
            await api.remove({ endpoint: API_GET_UPDATE_DELETE_PROMOTION.replace('{id}', id) });
            setReloadList(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      const pagin = pagination;
      pagin.current = page;
      // set filter
      if (search) {
        const qsName = new URLSearchParams(search).get('name');
        const qsSku = new URLSearchParams(search).get('sku');

        const nameFilter = qsName ? `&name=${qsName}` : '';
        const skuFilter = qsSku ? `&sku=${qsSku}` : '';
        history.push(`/khuyen-mai?page=${page}${nameFilter}${skuFilter}`);
      }
      setPagination(pagin);
      setReloadList(true);
    },
    [pagination, search],
  );

  const handleFilterChange = useCallback((_, values) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const filter = new FilterPromotionModel(values);
    setFilterContent(filter);
    filterForm.setFieldsValue(filter);
  }, []);

  const handleFilterPromotion = useCallback(() => {
    handleChangePage(1);
  }, [handleChangePage]);

  const handleClearFilter = useCallback(() => {
    // remove query params
    if (search) {
      history.replace({
        search: '',
      });
    }
    setFilterContent(defaultFilterPromotion);
    filterForm.resetFields();
    setReloadList(true);
  }, [handleChangePage]);

  const handleChangeActiveStatus = useCallback(
    (row: IPromotion) => async (checked: boolean) => {
      const clonedPromotions = clone(promotions);
      const index = clonedPromotions.findIndex((item) => item.id === row.id);

      try {
        const promotion = new PromotionModel(row.attributes);
        promotion.active = checked;
        if (index > -1) {
          clonedPromotions.splice(index, 1, { ...row, attributes: promotion });
        }
        setPromotions(clonedPromotions);
        await api.put({
          endpoint: API_GET_UPDATE_DELETE_PROMOTION.replace('{id}', row.id),
          data: promotion,
        });
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [promotions],
  );

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tên chương trình khuyến mãi',
      dataIndex: 'attributes',
      render: (attr: IAttrPromotion, row: IPromotion) => {
        const { name, sku } = filterContent;
        const nameFilter = name ? `&name=${name}` : '';
        const skuFilter = sku ? `&sku=${sku}` : '';

        return (
          <NavLink
            className="promotion-name"
            to={`/khuyen-mai/${row.id}?page=${pagination.current}${nameFilter}${skuFilter}`}
          >
            {attr?.name}
          </NavLink>
        );
      },
    },
    {
      title: 'Giảm giá',
      dataIndex: 'attributes',
      render: (attr: IAttrPromotion) =>
        attr?.discountUnit === DiscountUnit.VND
          ? formatNumber(attr?.discount)
          : `${attr?.discount}%`,
    },
    {
      title: 'Bắt đầu',
      dataIndex: 'attributes',
      render: (attr: IAttrPromotion) =>
        moment(attr?.startDate, DateFormat.serverSideWithTime).format(
          DateFormat.clientSideWithTime,
        ),
    },
    {
      title: 'Kết thúc',
      dataIndex: 'attributes',
      render: (attr: IAttrPromotion) =>
        moment(attr?.endDate, DateFormat.serverSideWithTime).format(DateFormat.clientSideWithTime),
    },
    {
      title: 'Status',
      dataIndex: 'attributes',
      align: AlignType.center,
      render: (attr: IAttrPromotion, row: IPromotion) => (
        <Switch checked={attr.active} onChange={handleChangeActiveStatus(row)} />
      ),
    },
    {
      title: 'Thao tác',
      align: AlignType.right,
      dataIndex: '',
      render: (row: IPromotion) => (
        <Button onClick={handleRemovePromotion(row.id)} title="Xóa" icon={<DeleteOutlined />} />
      ),
    },
  ];
  return (
    <>
      <LayoutList
        pageTitle="Khuyến mãi - Promotion"
        breadcrumbs={[
          {
            title: 'Khuyến mãi - Promotion',
            route: '/khuyen-mai',
          },
        ]}
        showAddButton
        handleClickAddButton={handleAddNewPromotion}
        loadingList={reloadList}
        columns={columns}
        dataList={promotions}
        tableClass="promotion-table"
        pagination={pagination}
        handleChangePage={handleChangePage}
        topPagination
        filterNode={
          <Form
            layout="vertical"
            form={filterForm}
            onFinish={handleFilterPromotion}
            onValuesChange={handleFilterChange}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  initialValue={filterContent.name}
                  label="Nhập tên chương trình khuyến mãi"
                  name="name"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item initialValue={filterContent.sku} label="Nhập SKU" name="sku">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <Form.Item label={<br />}>
                  <Button className="secondary-button" htmlType="submit">
                    Filter
                  </Button>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label={<br />}>
                  <Button onClick={handleClearFilter}>Clear</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
    </>
  );
};

export default Promotion;
