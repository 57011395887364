import { Col, Divider, Row, Pagination, Empty, message } from 'antd';
import { IReview } from 'interfaces/review.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import isEmpty from 'lodash/isEmpty';
import FilterStatus from 'pages/Members/Review/components/FilterStatus';
import { ReviewItem } from 'pages/Members/Review/components/ReviewItem';
import {
  usePostStatusPublished,
  usePostStatusPending,
  usePostStatusLocked,
  useRemoveReview,
} from 'pages/Members/Review/hook';
import { getReviewLabelStatus } from 'pages/Members/utils';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setReloadAllReviews } from 'redux/review/actions';
import { setIsEditing } from 'redux/uiAction/actions';
import { Loading } from 'ui/components/MaiNguyen/Loading';
import { confirmModal } from 'utils/modals';
import { ReviewStatus, sortList } from 'constants/enum';
import { useFetchReviewByMemmber } from './hook';
import ButtonBack from '../components/ButtonBack';
import MemberDetailsMenu from '../components/Menu';
import Sort from '../components/Sort';
import { useFetchMember } from '../hooks';
import '../index.styles.scss';
import './reviews.styles.scss';

const MemberReviews: React.FC = () => {
  const dispatch = useDispatch();
  const { id: memberId } = useParams<{ id: string }>();
  const { data: memberData } = useFetchMember(memberId);

  const [filterParams, setFilterParams] = useState({
    status: ReviewStatus.published,
    sort: sortList[0].value,
  });
  const [idLoading, setIdLoading] = useState<string | null>(null);

  const memberAttributes = useMemo(() => {
    return memberData?.attributes;
  }, [memberData]);

  useEffect(() => {
    memberData?.id && dispatch(setIsEditing(false));
  }, [memberData?.id]);

  const params = queryString.parse(location.search) as any;
  useEffect(() => {
    if (!isEmpty(params)) {
      setFilterParams((prev) => ({
        ...prev,
        status: params?.status || ReviewStatus.pending,
        sort: params?.sort || sortList[0].value,
      }));
    }
  }, [JSON.stringify(params)]);

  const { onPostPublished } = usePostStatusPublished();
  const { onPostPending } = usePostStatusPending();
  const { onPostLocked } = usePostStatusLocked();
  const { onRemove } = useRemoveReview();

  // Hook get member reviews
  const {
    isFetching,
    data,
    pagination,
    setPagination,
    fetchReviewList,
    includedProducts,
    totalItems,
  } = useFetchReviewByMemmber();

  /**
   * Handle the feedback list of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onPageChange = useCallback(
    async (page: number) => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchReviewList({ page });
    },
    [fetchReviewList],
  );

  /**
   * Handle the event change status
   */
  const onSetViewMode = useCallback(
    async (mode: ReviewStatus) => {
      await fetchReviewList({ page: 1, status: mode });
    },
    [fetchReviewList],
  );

  /**
   * Handle order change
   */
  const sortChange = useCallback(
    async (dataSort: string) => {
      await fetchReviewList({ page: 1, sort: dataSort });
    },
    [fetchReviewList],
  );

  /**
   * Handle the event update review status
   */
  const onUpdateStatus = useCallback(
    async (review: IReview, status: string) => {
      try {
        setIdLoading(review.id);

        switch (status) {
          case ReviewStatus.pending:
            await onPostPending({
              data: {
                ids: [review.id],
              },
            });
            break;
          case ReviewStatus.published:
            await onPostPublished({
              data: {
                ids: [review.id],
              },
            });

            await dispatch(setReloadAllReviews(true));

            break;
          case ReviewStatus.locked:
            await onPostLocked({
              data: {
                ids: [review.id],
              },
            });
            break;
        }

        message.success('Cập nhật thành công');
        await fetchReviewList();
      } catch (error: any) {
        message.error(error?.message || error);
      } finally {
        await dispatch(setReloadAllReviews(false));

        setIdLoading(null);
      }
    },
    [onPostPending, onPostPublished, onPostLocked, fetchReviewList],
  );

  const onRemoveReview = useCallback(
    async (reviewId: string) => {
      try {
        confirmModal({
          title: 'Bạn muốn xoá đánh giá này?',
          buttonText: 'Đồng ý',
          onSaveCb: async () => {
            await onRemove({ id: reviewId });
            message.success('Xoá đánh giá thành công');
            await fetchReviewList();
          },
        });
      } catch (error: any) {
        message.error(error?.message || error);
      } finally {
        dispatch(setReloadAllReviews(false));
      }
    },
    [onRemove, fetchReviewList],
  );

  return (
    <LayoutDetail
      pageTitle={`Member #${memberId || ''}`}
      isAddNew={false}
      pageClassName="member-details"
      breadcrumbs={[
        {
          title: 'Danh sách members',
          route: '/danh-sach-members',
        },
        {
          title: 'Reviews',
          route: `/danh-sach-members/${memberId}/reviews`,
        },
      ]}
    >
      {/* Menu */}
      <MemberDetailsMenu
        totalOrder={memberAttributes?.totalOrder}
        totalComment={memberAttributes?.totalComment}
        totalReview={memberAttributes?.totalReview}
        totalWishlist={memberAttributes?.totalWishlist}
      />

      {/* List */}
      <div className="member-content">
        <div className="member-review-info">
          <Row>
            <Col span={12}>
              <div className="count-review">
                <NumberFormat
                  className="count"
                  thousandSeparator
                  displayType="text"
                  value={totalItems}
                />
                Reviews {getReviewLabelStatus(filterParams.status).toLowerCase()}
              </div>
            </Col>
            <Col span={12}>
              <Row className="justify-content-end mb-3">
                <ButtonBack />
              </Row>
              <Row className="align-items-center">
                <Col span={18}>
                  <FilterStatus onChange={onSetViewMode} className="justify-content-end mr-3" />
                </Col>
                <Col span={6}>
                  <Sort
                    sortList={sortList}
                    defaultValue={filterParams.sort}
                    onChange={sortChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Divider />

        {!!data?.length && (
          <div className="mt-2 mb-3 text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}

        <div className="member-review-content">
          <Loading isLoading={isFetching} />

          {data?.length ? (
            <div className="review-wrap">
              <div className="review-list">
                {data.map((o: IReview) => {
                  return (
                    <ReviewItem
                      key={`review-item-${o.id}`}
                      review={o}
                      idLoading={idLoading}
                      hideProductLink={false}
                      includedProducts={includedProducts}
                      onUpdate={onUpdateStatus}
                      onRemove={onRemoveReview}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="blk-empty">
              <Empty description="Danh sách trống" />
            </div>
          )}
        </div>

        {!!data?.length && (
          <div className="mt-3 mb-2 text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}
      </div>
    </LayoutDetail>
  );
};

export default MemberReviews;
