import { Tree, TreeProps } from 'antd';
import { Key } from 'antd/lib/table/interface';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

interface TreeViewCheckboxInterface {
  treeData: any[];
  selectedKeys?: Key[];
  handleSelecteKeys?: (keys: Key[]) => void;
}

export const TreeViewCheckbox: React.FC<TreeViewCheckboxInterface> = ({
  treeData,
  selectedKeys = [],
  handleSelecteKeys = null,
}) => {
  const [data, setData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);

  useEffect(() => {
    setData(treeData);
  }, [treeData]);

  useEffect(() => {
    if (!isEmpty(selectedKeys)) {
      setCheckedKeys(selectedKeys);
    }
  }, [selectedKeys]);

  // TODO: handle select change
  const onCheck: TreeProps['onCheck'] = useCallback(
    (checkedKeysValue) => {
      setCheckedKeys(checkedKeysValue as Key[]);
      handleSelecteKeys && handleSelecteKeys(checkedKeysValue as Key[]);
    },
    [handleSelecteKeys],
  );

  return !isEmpty(data) ? (
    <Tree
      checkable
      defaultExpandAll
      blockNode
      treeData={data}
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      checkStrictly
    />
  ) : (
    <></>
  );
};
