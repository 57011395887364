import { ICoupon } from 'interfaces/coupon.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getPageData } from 'services/pageData.service';
import { fetcherWithArg } from 'services/swr.utils';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { buildParams } from 'utils/utils';
import { API_COUPON_BIRTHDAY } from 'constants/api/coupons.api';
import { API_PRODUCT_CATEGORIES } from 'constants/api/product-category.api';
import { defaultPagination } from 'constants/constants';

interface FilterCategories {
  name?: string;
  direction?: string;
  ids?: string[];
}

export const useFetchCouponBirthday = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isValidating, mutate } = useSWR(
    `${API_COUPON_BIRTHDAY}?include=categories`,
    (url: string) => getPageData(url, dispatch),
  );

  useEffect(() => {
    if (data) {
      mutate(null, { revalidate: true });
    }
  }, []);

  return {
    isFetching: isLoading || isValidating,
    refetch: mutate,
    data: data?.data as ICoupon,
  };
};

/**
 * Custom hook that fetches and returns coupons data.
 */
export const useFetchCategoriesByCoupon = () => {
  const location = useLocation();
  const { data, isMutating, trigger } = useSWRMutation(API_PRODUCT_CATEGORIES, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  const fetchCategories = useCallback(
    async (
      params?: Partial<Omit<FilterCategories, 'page'>> & {
        page?: number;
        ids?: string[];
      },
    ): Promise<void> => {
      const { page = pagination.current, ids, ...rest } = params || {};
      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;

      const query = {
        'ids[]': ids,
        page: calcPage,
        ...rest,
      };

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage, location.search],
  );

  return {
    isFetching: isMutating,
    data: data?.data,
    pagination,
    totalItems: pagination.total,
    setPagination,
    refetch: fetchCategories,
  };
};
