import { IBrand } from 'interfaces/brand.interface';
import { fetcher, updater } from 'services/swr.utils';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { API_GET_POST_BRANCH } from 'constants/api/branch.api';
import { API_GET_PRODUCT_DETAIL } from 'constants/api/product.api';

export const useFetchStoresProdExp = () => {
  const { data, isLoading, isValidating } = useSWR(`${API_GET_POST_BRANCH}?perPage=all`, fetcher);

  return {
    isFetching: isLoading || isValidating,
    data: data
      ? data.filter((item: IBrand) => item.attributes.active && !item.attributes.isOnlyStore)
      : [],
  };
};

export const useUpdateProdExperience = () => {
  const { isMutating, trigger } = useSWRMutation(API_GET_PRODUCT_DETAIL, updater);

  return {
    isUpdating: isMutating,
    onUpdate: trigger,
  };
};
