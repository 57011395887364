import md5 from 'md5';
import { MeModel } from 'models/me.model';
import { TokenModel } from 'models/token.model';
import moment from 'moment';
import { toast } from 'react-toastify';
import { startLogin, loginSuccessful, loginFailed } from 'redux/auth/actions';
import { fetchUser, fetchUserSuccessful, fetchUserFailed } from 'redux/user/actions';
import TokenService from 'services/token.service';
import { API_LOGIN, API_GET_ME, API_LOGOUT } from 'constants/api/auth.api';

import { DEV_KEY, IS_DEV } from 'constants/constants';
import * as api from './api.service';

const tokenService = new TokenService();

export const getMe = () => async (dispatch) => {
  const { accessToken } = tokenService.getAccessToken();
  if (accessToken) {
    dispatch(fetchUser());
    try {
      const result = await api.get({
        endpoint: API_GET_ME,
      });
      const user = new MeModel(result?.data);
      dispatch(fetchUserSuccessful(user));
    } catch (e) {
      dispatch(fetchUserFailed());
    }
  }
};

export const login =
  ({ email, password, authKey }) =>
  async (dispatch) => {
    dispatch(startLogin());
    try {
      await api
        .post({
          endpoint: API_LOGIN,
          data: {
            email: email.trim(),
            password,
            otp: authKey,
            guestKey: IS_DEV ? md5(DEV_KEY + moment().format('DDMMYYYY')) : undefined,
          },
          requiresAuth: false,
        })
        .then(async (result) => {
          toast.success('Đăng nhập thành công!', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: null,
          });
          const token = new TokenModel(result.data);
          tokenService.setToken(token);
          dispatch(loginSuccessful({ isLogin: true, token: token.accessToken }));
          dispatch(getMe());
        });
    } catch (e) {
      dispatch(loginFailed(e?.response?.data));
    }
  };

export const logout = () => async (dispatch) => {
  try {
    await api.get({
      endpoint: API_LOGOUT,
      params: {
        guestKey: IS_DEV ? md5(DEV_KEY + moment().format('DDMMYYYY')) : undefined,
      },
    });
    tokenService.clearToken();
    dispatch(fetchUserSuccessful(null));
  } catch (e) {
    throw Error(e?.message);
  }
};
