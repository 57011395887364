import { IMenuLink } from 'interfaces/menu-link.interface';
import { SET_MENU_LINKS, MenuActionType } from './types';

interface InitStateInterface {
  menuLinks: IMenuLink[];
}

const initState: InitStateInterface = {
  menuLinks: [],
};

export const menuReducer = (state: InitStateInterface = initState, actions: MenuActionType) => {
  switch (actions.type) {
    case SET_MENU_LINKS:
      return {
        ...state,
        menuLinks: actions.payload,
      };
    default:
      return state;
  }
};
