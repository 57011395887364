import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Input, Button, Form, Select, Col, Row, Switch, Modal } from 'antd';
import pwdGenerator from 'generate-password';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { API_GET_ROLES } from 'constants/api/role.api';
import {
  API_GET_POST_USERS,
  API_GET_PUT_DELETE_USER_DETAIL,
  API_GET_2FACTOR_DISABLE,
} from 'constants/api/users.api';
import { IRole } from 'interfaces/role.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { UserModel, UserAttributeModel } from 'models/user.model';
import { AppState } from 'redux/store';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { validateEmailAntInput, validatePasswordAntInput } from 'utils/utils';
import QRCode from './QRCode/QRCode';

const userState = (state: AppState) => state.user;

import './UserDetail.scss';

const UserDetails = () => {
  const { user } = useSelector(userState);
  const { id: userId } = useParams<{ id: string }>();

  const [isAddNew, setIsAddNew] = useState(userId === 'new');
  const [userDetail, setUserDetail] = useState<UserAttributeModel>(null);
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState<IRole[]>([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [is2factorChecked, setIs2factorChecked] = useState(false);

  const [form] = Form.useForm();
  const { confirm } = Modal;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAddNew) {
      const newUser = new UserAttributeModel({});
      setUserDetail(newUser);
    }
  }, [isAddNew]);

  useEffect(() => {
    if (userId && userId !== 'new') {
      getPageData(API_GET_PUT_DELETE_USER_DETAIL.replace('{slug}', userId), dispatch)
        .then((res) => {
          const userModel = new UserModel(res?.data);
          const userAttr = new UserAttributeModel(userModel.attributes);
          setUserDetail(userAttr);
          form.setFieldsValue({
            ...userAttr,
            active: userAttr.active,
          });
          // show QR Code
          if (userModel.id === user.slug) {
            setShowQRCode(true);
            if (userAttr.google2faSecret !== null) {
              setIs2factorChecked(true);
            } else {
              setIs2factorChecked(false);
            }
          } else {
            setShowQRCode(false);
          }
        })
        .catch(() => {
          history.push('/users');
        });
    }
    getPageData(API_GET_ROLES, dispatch).then((res) => {
      setRoles(res?.data);
    });
  }, []);

  const handleAddNewUser = useCallback(() => {
    history.replace('/users/new');
    setIsAddNew(true);
    const newUser = new UserAttributeModel({});
    form.setFieldsValue({
      ...newUser,
    });
  }, []);

  const showDeleteConfirm = useCallback(() => {
    confirm({
      title: 'Bạn chắc chắn xóa user này?',
      icon: <ExclamationCircleOutlined style={{ color: '#666' }} />,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Không',
      onOk: async () => {
        setDeleteLoading(true);
        try {
          await api.remove({ endpoint: API_GET_PUT_DELETE_USER_DETAIL.replace('{slug}', userId) });
          setDeleteLoading(false);
          history.push('/users');
        } catch (err) {
          setDeleteLoading(false);
        }
      },
    });
  }, []);

  const handleSaveUser = useCallback(() => {
    confirm({
      title: 'Bạn chắc chắn lưu user này?',
      icon: <ExclamationCircleOutlined style={{ color: '#666' }} />,
      okText: 'Lưu',
      okType: 'default',
      cancelText: 'Không',
      onOk: async () => {
        setSaveLoading(true);
        if (isAddNew) {
          try {
            await api.post({
              endpoint: API_GET_POST_USERS,
              data: {
                ...userDetail,
                password,
                passwordConfirmation: password,
              },
            });
            setSaveLoading(false);
            history.push('/users');
          } catch (err) {
            setSaveLoading(false);
          }
        } else {
          const pwd = password ? { password } : {};
          try {
            await api.put({
              endpoint: API_GET_PUT_DELETE_USER_DETAIL.replace('{slug}', userId),
              data: {
                firstName: userDetail.firstName,
                lastName: userDetail.lastName,
                active: userDetail.active,
                role: userDetail.role,
                ...pwd,
              },
            });
            setSaveLoading(false);
            history.push('/users');
          } catch (err) {
            setSaveLoading(false);
          }
        }
      },
    });
  }, [userDetail]);

  const handleValuesChange = useCallback((_, values) => {
    const userModel = new UserAttributeModel(values);
    setUserDetail(userModel);
  }, []);

  const handleGeneratePassword = useCallback(() => {
    const pwd = pwdGenerator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      strict: true,
    });
    setPassword(pwd);
    form.setFieldsValue({ password: pwd });
  }, []);

  const handleChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const handle2FactorChange = (value: boolean) => {
    setIs2factorChecked(value);
    if (value == false) {
      getPageData(API_GET_2FACTOR_DISABLE, dispatch);
    }
  };

  return (
    <LayoutDetail
      pageTitle={isAddNew ? 'Tạo mới' : 'Chi tiết'}
      isAddNew={isAddNew}
      pageClassName="user-detail-page"
      breadcrumbs={[
        {
          title: 'Danh sách',
          route: '/users',
        },
        {
          title: isAddNew ? 'Tạo mới' : 'Chi tiết',
          route: `/users/${userId}`,
        },
      ]}
      formHook={form}
      handleValuesChange={handleValuesChange}
      handleDelete={showDeleteConfirm}
      handleSave={handleSaveUser}
      saveLoading={saveLoading}
      deleteLoading={deleteLoading}
      handleClickAddButton={handleAddNewUser}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              () => ({
                validator(_, value: string) {
                  return validateEmailAntInput(value);
                },
              }),
            ]}
          >
            <Input type="email" disabled={!isAddNew} />
          </Form.Item>
          <div className="generate-pwd">
            <Form.Item
              label="Password"
              name="password"
              rules={[
                () => ({
                  validator(_, value: string) {
                    return validatePasswordAntInput(value, false);
                  },
                }),
              ]}
            >
              <Input value={password} onChange={handleChangePassword} />
            </Form.Item>
            <Button onClick={handleGeneratePassword}>Tạo password</Button>
          </div>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label={userDetail?.active ? 'Hoạt động' : 'Khóa'}
                name="active"
                valuePropName="checked"
                initialValue={true}
              >
                <Switch />
              </Form.Item>
            </Col>
            {showQRCode && (
              <Col span={18}>
                <Form.Item label="Bật bảo mật 2Factor">
                  <Switch checked={is2factorChecked} onChange={handle2FactorChange} />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập first name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập last name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Role" name="role">
            <Select className="ant-select-lg" mode="multiple">
              {roles.map((item) => (
                <Select.Option key={item.attributes.name} value={item.attributes.name}>
                  {item.attributes.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {is2factorChecked && (
          <Col span={24}>
            <Row gutter={16}>
              <QRCode />
            </Row>
          </Col>
        )}
      </Row>
    </LayoutDetail>
  );
};

export default UserDetails;
