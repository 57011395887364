import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { IImage } from 'interfaces/image.interface';
import React, { useCallback, useEffect, useState } from 'react';
import { uploadFile } from 'utils/utils';
import { v4 as uuid } from 'uuid';
import { UploadFile } from '../../UploadFile';

import './styles.scss';

interface PaymentOrderBlockProps {
  blockId: string;
  data: BankAccount[];
  onChange: (data: BankAccount[]) => void;
}

export interface BankAccount {
  code: string;
  bankName: string;
  bankBranch: string;
  bankAccount: string;
  bankLogo: IImage;
  bankQRCode: IImage;
}

export const PaymentOrderBlock: React.FC<PaymentOrderBlockProps> = (props) => {
  const { blockId, data, onChange } = props;
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>(data || []);
  const [idUploading, setIdUploading] = useState<number | null>(null);

  useEffect(() => {
    onChange(bankAccounts);
  }, [JSON.stringify(bankAccounts)]);

  const onAddBankAccount = useCallback(() => {
    setBankAccounts((prev) => [
      {
        code: '',
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        bankLogo: null,
        bankQRCode: null,
      },
      ...prev,
    ]);
  }, []);

  const onValuesChange = useCallback(
    (fieldKey: keyof BankAccount, value: string | IImage, itemIndex: number) => {
      const currentBankAccount = bankAccounts[itemIndex];
      currentBankAccount[fieldKey] = value as any;
      setBankAccounts([...bankAccounts]);
    },
    [bankAccounts],
  );

  const onUploadImage = useCallback(
    async (fieldKey: keyof BankAccount, value: RcFile, itemIndex: number) => {
      setIdUploading(itemIndex);

      await uploadFile(value, 'block', blockId, '', '0')
        .then((attr) =>
          onValuesChange(
            fieldKey,
            {
              ...attr,
              original: attr?.originUrl,
            },
            itemIndex,
          ),
        )
        .catch(() => onValuesChange(fieldKey, null, itemIndex));
      setIdUploading(null);
    },
    [onValuesChange],
  );

  return (
    <div className="payment-order-block">
      <div className="toolbar">
        <div>
          Danh sách mã ngân hàng, tham khảo tại{' '}
          <a target="_blank" href="https://vietqr.co/banks" rel="noreferrer">
            đây
          </a>
        </div>
        <div className="text-right">
          <Button onClick={onAddBankAccount} icon={<PlusOutlined />}>
            Thêm tài khoản
          </Button>
        </div>
      </div>

      {bankAccounts.map((o, i) => {
        return (
          <div className="bank-block" key={`${i}`}>
            {i > 0 && <Divider />}
            {idUploading === i && (
              <div className="loading-wrapper">
                <Spin />
                <span>Uploading...</span>
              </div>
            )}
            <Row gutter={16} className="mt-3 mb-3">
              <Col span={6}>
                <Form.Item label="Tên ngân hàng">
                  <Input
                    value={o.bankName}
                    onChange={(e) => onValuesChange('bankName', e.target.value, i)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Mã ngân hàng">
                  <Input
                    value={o.code}
                    onChange={(e) => onValuesChange('code', e.target.value, i)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Chi nhánh">
                  <Input
                    value={o.bankBranch}
                    onChange={(e) => onValuesChange('bankBranch', e.target.value, i)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Số tài khoản">
                  <Input
                    value={o.bankAccount}
                    onChange={(e) => onValuesChange('bankAccount', e.target.value, i)}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Logo ngân hàng">
                  <UploadFile
                    uploadText="Click hoặc kéo thả hình để upload"
                    handleUploadImage={(file) => onUploadImage('bankLogo', file, i)}
                    typeId={uuid()}
                    handleDeleteImageSuccess={() => onValuesChange('bankLogo', null, i)}
                    previewImage={o.bankLogo}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Hình ảnh QRCode">
                  <UploadFile
                    uploadText="Click hoặc kéo thả hình để upload"
                    handleUploadImage={(file) => onUploadImage('bankQRCode', file, i)}
                    typeId={uuid()}
                    handleDeleteImageSuccess={() => onValuesChange('bankQRCode', null, i)}
                    previewImage={o.bankQRCode}
                  />
                </Form.Item>
              </Col>

              <Col className="text-right" span={24}>
                <Button
                  className="mt-2"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setBankAccounts((prev) => prev.filter((_, pi) => pi !== i));
                  }}
                >
                  Xóa
                </Button>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};
