import { Row, Col, Form } from 'antd';

// Import Components
import { useRiderPresentBlocks } from 'hooks/useRiderPresentBlocks';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { useCallback } from 'react';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { RiderTemplateBlock } from 'ui/components/MaiNguyen/RiderTemplateBlock';
import { RiderBlockPositionTypes, RiderBlockTemplateTypes } from 'constants/enum';

import './RiderProductDetailPresent.scss';

const RiderProductDetailPresent = () => {
  const {
    reloadList,
    block,
    isOpen,
    handleClosePopup,
    handleOpenModalBlock,
    handleChangeValues,
    handleSaveBlock,
    handleChangeJsonData,
    handleUploadFileContent,
    handleDeleteImage,
    handleUpdateEditorBlock,
  } = useRiderPresentBlocks();

  const [form] = Form.useForm();

  const handleChangeMainContent = useCallback(
    (value: string) => {
      handleUpdateEditorBlock(value);
    },
    [block],
  );

  return (
    <>
      <LayoutDetail
        pageTitle="Trình bày trang Rider - Chi tiết sản phẩm"
        breadcrumbs={[
          {
            title: 'Trình bày trang Rider - Chi tiết sản phẩm',
            route: '/trinh-bay-rider-chi-tiet-san-pham',
          },
        ]}
        isAddNew
        wrapperCard={false}
        handleValuesChange={null}
        formHook={null}
        pageClassName="rider-product-detail-present"
        saveLoading={reloadList}
      >
        <Row gutter={16}>
          <Col span={6}>
            <div
              className="block-item"
              onClick={handleOpenModalBlock(
                RiderBlockPositionTypes.productDetailShip,
                RiderBlockTemplateTypes.riderEditor,
              )}
            >
              Ship
            </div>
          </Col>
          <Col span={6}>
            <div
              className="block-item"
              onClick={handleOpenModalBlock(
                RiderBlockPositionTypes.productDetailBestPrice,
                RiderBlockTemplateTypes.riderEditor,
              )}
            >
              GIÁ TỐT
            </div>
          </Col>
          <Col span={6}>
            <div
              className="block-item"
              onClick={handleOpenModalBlock(
                RiderBlockPositionTypes.productDetaillConsultant,
                RiderBlockTemplateTypes.productDetaillConsultant,
              )}
            >
              TƯ VẤN KHÁCH HÀNG
            </div>
          </Col>
          <Col span={6}>
            <div
              className="block-item"
              onClick={handleOpenModalBlock(
                RiderBlockPositionTypes.productDetailWarrantyInfo,
                RiderBlockTemplateTypes.productDetailWarrantyInfo,
              )}
            >
              THÔNG TIN BẢO HÀNH
            </div>
          </Col>
        </Row>
      </LayoutDetail>
      <CustomPopup
        title="Display Block"
        isOpen={isOpen}
        onCloseModal={handleClosePopup}
        handleSaveButton={handleSaveBlock}
        formHook={form}
        handleValuesChange={handleChangeValues}
      >
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name">
                <div>{block?.attributes?.name}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Position">
                <div>{block?.attributes?.position}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Template">
                <div>{block?.attributes?.template}</div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <RiderTemplateBlock
                block={block}
                template={block?.attributes?.template}
                onChangeJsonData={handleChangeJsonData}
                onAddImage={handleUploadFileContent}
                onDeleteImage={handleDeleteImage}
                handleChangeEditorContent={handleChangeMainContent}
              />
            </Col>
          </Row>
        </>
      </CustomPopup>
    </>
  );
};

export default RiderProductDetailPresent;
