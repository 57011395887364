import { Button } from 'antd';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { defaultJsonData as MaiNguyenDefaultJsonData } from 'constants/constants';

import 'jsoneditor-react/es/editor.min.css';
import { showErrorMsg } from 'utils/utils';

interface CustomJsonInputInterface {
  value: Record<string, any>;
  onChange: (objData: any) => void;
  height?: string | number;
  width?: string | number;
  template: string;
  defaultJsonData?: Record<string, unknown>;
}

export const CustomJsonInput: FC<CustomJsonInputInterface> = ({
  value,
  onChange,
  height = '35vh',
  width = '100%',
  template,
  defaultJsonData = MaiNguyenDefaultJsonData,
}) => {
  const [mode, setMode] = useState('code');
  const [defaultValue, setDefaultValue] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      if (defaultValue) {
        ref.current.jsonEditor.set(defaultValue);
        onChange(defaultValue);
        setDefaultValue(null);
      }
    }
  }, [defaultValue, onChange]);

  const handleChangeJsonText = useCallback(
    (jsonText: string) => {
      try {
        const data = JSON.parse(jsonText);
        onChange(data);
      } catch (error: any) {
        showErrorMsg(error);
      }
    },
    [onChange],
  );

  const handleChangeMode = useCallback((m) => {
    setMode(m);
  }, []);

  const setDefault = () => {
    setDefaultValue(defaultJsonData[template] || {});
  };

  return (
    <>
      <Button className="mb-3" onClick={setDefault}>
        Set default
      </Button>
      <Editor
        ref={ref}
        htmlElementProps={{ style: { height, width } }}
        mode={mode}
        value={value}
        allowedModes={['tree', 'form', 'code']}
        onModeChange={handleChangeMode}
        onChangeText={handleChangeJsonText}
        history
      />
    </>
  );
};
