import { DeleteOutlined } from '@ant-design/icons';
import { Button, Pagination, Table, TableProps } from 'antd';
import { ICoupon, ICouponAttributes } from 'interfaces/coupon.interface';
import { IPagination } from 'interfaces/pagination.interface';
import moment from 'moment';
import React, { useEffect } from 'react';
import { SortableTable } from 'ui/components/SortableTable/SortableTable';
import { formatNumber } from 'utils/utils';
import { COUPON_STATUS_LIST } from 'constants/constants';
import { AlignType, CouponStatus, DateFormat, DiscountUnit } from 'constants/enum';

import './styles.scss';

interface Props extends TableProps<any> {
  loading: boolean;
  data: ICoupon[];
  pagination?: IPagination | false;
  onRemove?: (id: string) => void;
  onChangePosition?: (products: any[]) => void;
  onPageChange?: (page: number, pageSize: number) => void;
}

export const CouponsTable: React.FC<Props> = (props) => {
  const { loading, data, pagination, onRemove, onPageChange, onChangePosition, ...rest } = props;

  /**
   * An effect that updates the empty list message when the fetching status of products changes.
   */
  useEffect((): void => {
    if (!loading) {
      const selector = '.products-table-wrapper table .ant-empty-description';
      const els = document.querySelectorAll(selector);
      if (els?.length) {
        els.forEach((el) => {
          el.innerHTML = 'Danh sách trống';
        });
      }
    }
  }, [loading]);

  const columns = [
    {
      title: 'Thông tin',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => (
        <div
          className="coupon-cell__info"
          // onClick={() => {
          //   history.push(`/ma-giam-gia/${row.id}`, queryString.parse(location.search));
          // }}
        >
          <div className="coupon-info__title">{o?.title || '-'}</div>
          <div className="coupon-info__desc">{o?.displayTitle || '-'}</div>
          <div className="coupon-info__prefix">{o?.prefixCode || '-'}</div>
        </div>
      ),
    },
    {
      title: 'Số mã',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        const totalCouponAvailable = o?.numberOfCoupon - o?.countActive;
        return (
          <div className="coupon-cell__total">
            <div className="coupon-available">{totalCouponAvailable}</div>
            <div className="coupon-total">{o?.numberOfCoupon}</div>
          </div>
        );
      },
    },
    {
      title: 'Discount',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        return (
          <div className="coupon-cell__discount">
            <div className="coupon-discount">
              {' '}
              {parseFloat(o?.discount as any) > 0
                ? `${
                    o?.discountUnit === DiscountUnit.VND
                      ? formatNumber(o?.discount)
                      : `${o?.discount}%`
                  }`
                : '-'}
            </div>
            <div className="coupon-discount-auto">{o?.rule}</div>
          </div>
        );
      },
    },
    {
      title: 'Ngày hiệu lực',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        return (
          <div className="coupon-cell__date">
            <div className="coupon__start-date">
              {o?.startDate
                ? moment(o?.startDate, DateFormat.serverSideWithTime).format(
                    DateFormat.clientSideWithTime,
                  )
                : '-'}
            </div>
            <div className="coupon__end-date">
              {' '}
              {o?.endDate
                ? moment(o?.endDate, DateFormat.serverSideWithTime).format(
                    DateFormat.clientSideWithTime,
                  )
                : '-'}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Tình trạng',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        const isCouponExpired = o?.endDate
          ? moment(o.endDate, DateFormat.serverSideWithTime).isBefore(moment())
          : false;
        const statusLabel = COUPON_STATUS_LIST.find(
          (c) => c.value === (isCouponExpired ? CouponStatus.expired : o?.status),
        )?.label;
        const prefix = isCouponExpired ? CouponStatus.expired : o?.status;
        return <div className={`coupon-status--${prefix}`}>{statusLabel}</div>;
      },
    },
    onRemove
      ? {
          title: 'Thao tác',
          dataIndex: '',
          align: AlignType.right,
          render: (row: ICoupon) => (
            <Button
              title="Remove"
              className="mb-2"
              icon={<DeleteOutlined />}
              onClick={() => onRemove(row.id)}
            />
          ),
        }
      : undefined,
  ].filter((c) => !!c);

  return (
    <>
      <div className="coupons-table-wrapper coupons-page">
        {onChangePosition ? (
          <SortableTable
            showHeader={false}
            columns={columns}
            dataList={data}
            rowKey={'id' as any}
            loading={loading}
            onChanged={onChangePosition}
            {...rest}
          />
        ) : (
          <Table
            showHeader={false}
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={loading}
            pagination={false}
            {...rest}
          />
        )}
      </div>
      {pagination && (
        <div className="layout-list-pagination">
          <Pagination
            {...pagination}
            pageSizeOptions={[]}
            onChange={onPageChange}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
};
