import { Row, Col, Form, Divider } from 'antd';

// Import Components
import { RiderBlockPositionTypes, RiderBlockTemplateTypes } from 'constants/enum';
import { useRiderPresentBlocks } from 'hooks/useRiderPresentBlocks';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { useCallback } from 'react';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';

import './RiderHeaderFooterPresent.scss';
import { RiderTemplateBlock } from 'ui/components/MaiNguyen/RiderTemplateBlock';

const RiderHeaderFooterPresent = () => {
  const {
    reloadList,
    block,
    isOpen,
    handleClosePopup,
    handleOpenModalBlock,
    handleChangeValues,
    handleSaveBlock,
    handleChangeJsonData,
    handleUploadFileContent,
    handleDeleteImage,
    handleUpdateEditorBlock,
    handleUpdateMenuItems,
    handleUpdateHeaderLogo,
  } = useRiderPresentBlocks();

  const [form] = Form.useForm();

  const handleChangeMainContent = useCallback(
    (value: string) => {
      handleUpdateEditorBlock(value);
    },
    [block],
  );

  return (
    <>
      <LayoutDetail
        pageTitle="Trình bày Rider - Header & Footer"
        breadcrumbs={[
          {
            title: 'Trình bày Rider - Header & Footer',
            route: '/trinh-bay-rider-header-footer',
          },
        ]}
        isAddNew
        wrapperCard={false}
        handleValuesChange={null}
        formHook={null}
        pageClassName="tracking-order-present rider-header-footer__present"
        saveLoading={reloadList}
      >
        <div>
          <div className="header-block__wrapper">
            <div className="block-title">Header</div>
            <Row gutter={16}>
              <Col span={24}>
                <div
                  className="block-item seo"
                  onClick={handleOpenModalBlock(
                    RiderBlockPositionTypes.headerLogo,
                    RiderBlockTemplateTypes.headerLogo,
                  )}
                >
                  RIDER LOGO
                </div>
              </Col>
            </Row>
          </div>

          <Divider />

          <div>
            <div className="block-title">Footer</div>
            <Row gutter={16}>
              <Col span={24}>
                <div
                  className="block-item seo"
                  onClick={handleOpenModalBlock(
                    RiderBlockPositionTypes.quickConsultation,
                    RiderBlockTemplateTypes.quickConsultation,
                  )}
                >
                  Tư vấn nhanh
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="block-item seo"
                  onClick={handleOpenModalBlock(
                    RiderBlockPositionTypes.showroom,
                    RiderBlockTemplateTypes.showroom,
                  )}
                >
                  Showroom
                </div>
              </Col>
              <Col span={8}>
                <div
                  className="block-item"
                  onClick={handleOpenModalBlock(
                    RiderBlockPositionTypes.footerMenu1,
                    RiderBlockTemplateTypes.footerMenu1,
                  )}
                >
                  MENU 1
                </div>
              </Col>
              <Col span={8}>
                <div
                  className="block-item"
                  onClick={handleOpenModalBlock(
                    RiderBlockPositionTypes.footerMenu2,
                    RiderBlockTemplateTypes.footerMenu2,
                  )}
                >
                  MENU 2
                </div>
              </Col>
              <Col span={8}>
                <div
                  className="block-item"
                  onClick={handleOpenModalBlock(
                    RiderBlockPositionTypes.footerSocials,
                    RiderBlockTemplateTypes.footerSocials,
                  )}
                >
                  SOCIAL
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="block-item seo"
                  onClick={handleOpenModalBlock(
                    RiderBlockPositionTypes.footerCopyrite,
                    RiderBlockTemplateTypes.footerCopyrite,
                  )}
                >
                  Copyright
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </LayoutDetail>
      <CustomPopup
        title="Display Block"
        isOpen={isOpen}
        onCloseModal={handleClosePopup}
        handleSaveButton={handleSaveBlock}
        formHook={form}
        handleValuesChange={handleChangeValues}
      >
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name">
                <div>{block?.attributes?.name}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Position">
                <div>{block?.attributes?.position}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Template">
                <div>{block?.attributes?.template}</div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <RiderTemplateBlock
                block={block}
                template={block?.attributes?.template}
                onChangeJsonData={handleChangeJsonData}
                onAddImage={handleUploadFileContent}
                onDeleteImage={handleDeleteImage}
                handleChangeEditorContent={handleChangeMainContent}
                handleUpdateHeaderLogo={handleUpdateHeaderLogo}
                onUpdateMenu={handleUpdateMenuItems}
              />
            </Col>
          </Row>
        </>
      </CustomPopup>
    </>
  );
};

export default RiderHeaderFooterPresent;
