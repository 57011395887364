import { IArticleAttribute } from 'interfaces/article.interface';

export class ArticleModel implements IArticleAttribute {
  name?: string;
  slug?: string;
  description?: string;
  keywords?: string;
  content?: string;
  summaryContent?: string;
  active?: boolean;
  categoryId?: number;
  isFeatured?: boolean;
  relatedArticles?: {
    articleId: number;
    position: number;
  }[];
  relatedProducts?: {
    productId: number;
    position: number;
  }[];

  constructor({
    name,
    slug,
    description,
    keywords,
    content,
    summaryContent,
    active,
    categoryId,
    isFeatured,
    relatedArticles,
    relatedProducts,
  }: IArticleAttribute) {
    this.name = name;
    this.slug = slug;
    this.description = description || '';
    this.keywords = keywords || '';
    this.content = content || '';
    this.summaryContent = summaryContent || '';
    this.active = active !== undefined && active !== null ? active : false;
    this.isFeatured = isFeatured !== undefined && isFeatured !== null ? isFeatured : false;
    this.categoryId = categoryId;
    this.relatedArticles = relatedArticles || [];
    this.relatedProducts = relatedProducts || [];
  }
}
