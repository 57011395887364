import { Col, Form, Input, Row, Switch } from 'antd';
import React, { useCallback } from 'react';

interface Props {
  data: CommentTemplateData;
  onChange: (data: string) => void;
}

export interface CommentTemplateData {
  blockId: string;
  show: boolean;
}

const INITIAL_DATA: Props['data'] = {
  blockId: '',
  show: true,
};

export const CommentTemplate: React.FC<Props> = (props) => {
  const { data = INITIAL_DATA, onChange } = props;

  const _onChange = useCallback(
    (values: Props['data']) => {
      onChange(JSON.stringify(values));
    },
    [onChange],
  );

  const onValuesChange = useCallback(
    (fieldKey: keyof CommentTemplateData, value: any) => {
      _onChange({ ...data, [fieldKey]: value });
    },
    [data, _onChange],
  );

  return (
    <div className="comment-template">
      <Row gutter={16} className="mt-3 mb-3">
        <Col span={20}>
          <Form.Item label="Block Id (Optional)">
            <Input
              value={data.blockId}
              onChange={(e) => onValuesChange('blockId', e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Hiển thị">
            <Switch checked={data?.show} onChange={(checked) => onValuesChange('show', checked)} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
