/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-use-before-define */
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import {
  API_CUSTOMER_REVIEWS,
  API_CUSTOMER_REVIEWS_ID,
  API_CUSTOMER_REVIEW_LOCKED,
  API_CUSTOMER_REVIEW_PENDING,
  API_CUSTOMER_REVIEW_PUBLISHED,
} from 'constants/api/review.api';
import { defaultPagination } from 'constants/constants';
import { ReviewStatus } from 'constants/enum';
import { IPagination } from 'interfaces/pagination.interface';
import { IProduct } from 'interfaces/product.interface';
import { IReview } from 'interfaces/review.interface';
import { updatePageData } from 'redux/pages/actions';
import { fetcherWithArg, poster, updater } from 'services/swr.utils';
import { FilterReviewPage } from './components/FilterView';
import { buildParams } from 'utils/utils';

/**
 * Custom hook that fetches and returns events page data.
 */
export const useFetchReviewList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id: productId } = useParams<{ id: string }>(); // productId !== null -> get reviews by product id

  const { data, isMutating, trigger } = useSWRMutation(API_CUSTOMER_REVIEWS, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetch(buildParams(isEmpty(params) && !productId ? { status: ReviewStatus.pending } : params));
  }, []);

  const fetch = useCallback(
    async (
      params?: Partial<Omit<FilterReviewPage, 'page'>> & {
        page?: number;
        status?: ReviewStatus;
      },
    ): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const { page = pagination.current, ...rest } = params || {};
      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;
      const query = {
        ...locationParams,
        page: calcPage,
        perPage: defaultPagination.pageSize,
        include: 'product',
        productId: productId ? productId : '',
        ...rest,
      };

      if (!productId) {
        history.push(
          queryString.stringifyUrl(
            {
              url: location.pathname,
              query,
            },
            { skipEmptyString: true, skipNull: true },
          ),
        );
      }

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage, location.search],
  );

  return {
    isFetching: isMutating,
    data: data?.data as IReview[],
    pagination,
    setPagination,
    fetchReviewList: fetch,
    totalItems: pagination.total,
    includedProducts: data?.included as IProduct[],
  };
};

export const useFetchReviewPending = () => {
  const { data, isMutating, trigger } = useSWRMutation(API_CUSTOMER_REVIEWS, fetcherWithArg);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = useCallback(async () => {
    const query = {
      page: defaultPagination.current,
      perPage: defaultPagination.pageSize,
      include: 'product',
      status: 'pending',
    };

    await trigger(query);
  }, []);

  return {
    isFetching: isMutating,
    data: data?.data as IReview[],
    fetchReviewList: fetch,
    totalItems: data?.meta?.pagination?.total,
    includedProducts: data?.included as IProduct[],
  };
};

export const usePostStatusPublished = () => {
  const { isMutating, trigger } = useSWRMutation(API_CUSTOMER_REVIEW_PUBLISHED, poster);

  return {
    isLoading: isMutating,
    onPostPublished: trigger,
  };
};

export const usePostStatusPending = () => {
  const { isMutating, trigger } = useSWRMutation(API_CUSTOMER_REVIEW_PENDING, poster);

  return {
    isLoading: isMutating,
    onPostPending: trigger,
  };
};

export const usePostStatusLocked = () => {
  const { isMutating, trigger } = useSWRMutation(API_CUSTOMER_REVIEW_LOCKED, poster);

  return {
    isLoading: isMutating,
    onPostLocked: trigger,
  };
};

export const usePostReview = () => {
  const { isMutating, trigger } = useSWRMutation(API_CUSTOMER_REVIEWS, poster);

  return {
    isLoading: isMutating,
    onSubmit: trigger,
  };
};

export const useRemoveReview = () => {
  const { isMutating, trigger } = useSWRMutation(
    API_CUSTOMER_REVIEWS_ID,
    (endpoint: string, { arg }: { arg: { id: string } }) =>
      updater(endpoint, {
        arg: {
          method: 'remove',
          id: arg.id,
        },
      }),
  );

  return {
    isRemoving: isMutating,
    onRemove: trigger,
  };
};
