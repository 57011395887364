import { IMe } from 'interfaces/me.interface';

export class MeModel implements IMe {
  id: string;

  email: string;

  firstName: string;

  lastName: string;

  slug: string;

  constructor({ id, email, firstName, lastName, slug }: IMe) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.slug = slug;
  }
}
