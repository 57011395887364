import { DeleteOutlined } from '@ant-design/icons';
import { Form, Button, Input, Row, Col, Select } from 'antd';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { API_GET_BRANDS } from 'constants/api/brand.api';
import {
  API_GET_POST_SERVICE_CENTER,
  API_PUT_DELETE_SERVICE_CENTER,
} from 'constants/api/service-center.api';
import { defaultPagination } from 'constants/constants';
import { AlignType } from 'constants/enum';
import { IBrand } from 'interfaces/brand.interface';
import { ICity } from 'interfaces/city.interface';
import { IPagination } from 'interfaces/pagination.interface';
import {
  IServiceCenter,
  IServiceCenterAttribute,
  IFilterServiceCenter,
} from 'interfaces/service-center.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import { FilterServiceCenterModel, ServiceCenterModel } from 'models/service-center.model';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { deleteModal } from 'utils/modals';
import { filterSelectNoUnicode, showErrorMsg } from 'utils/utils';

const newServiceCenter = new ServiceCenterModel({});

const defaultFilterServiceCenter: IFilterServiceCenter = {
  name: '',
  brandId: '',
};

const ServiceCenter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [modalTitle, setModalTile] = useState('Thêm Mới TT Bảo Hành');
  const [serviceCenters, setServiceCenters] = useState<IServiceCenter[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [serviceCenter, setServiceCenter] = useState(newServiceCenter);
  const [serviceCenterId, setServiceCenterId] = useState('');
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [filterContent, setFilterContent] = useState<IFilterServiceCenter>(
    defaultFilterServiceCenter,
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
    setModalTile('Thêm Mới TT Bảo Hành');
    setServiceCenter(newServiceCenter);
    setServiceCenterId('');
    form.setFieldsValue(newServiceCenter);
  }, []);

  useEffect(() => {
    if (firstLoad) {
      getPageData(`${API_GET_POST_SERVICE_CENTER}?page=1`, dispatch).then((res) => {
        const pagin = res?.meta?.pagination;
        setServiceCenters(res?.data);
        setPagination({
          current: pagin?.currentPage,
          pageSize: pagin?.perPage,
          total: pagin?.total,
        });
        setFirstLoad(false);
      });
    }
  }, [firstLoad]);

  useEffect(() => {
    const { name, brandId } = filterContent;
    const nameFilter = name ? `&name=${name}` : '';
    const brandFilter = brandId ? `&brandId=${brandId}` : '';
    if (reloadList) {
      api
        .get({
          endpoint: `${API_GET_POST_SERVICE_CENTER}?page=${pagination.current}${nameFilter}${brandFilter}`,
        })
        .then((res) => {
          const pagin = res?.meta?.pagination;
          setServiceCenters(res?.data);
          setPagination({
            current: pagin?.currentPage,
            pageSize: pagin?.perPage,
            total: pagin?.total,
          });
          setReloadList(false);
        });
    }
  }, [reloadList, pagination, filterContent]);

  useEffect(() => {
    Promise.all([
      api.get({ endpoint: `${API_GET_BRANDS}?perPage=all` }),
      getPageData('./data/city-code.json', dispatch),
    ]).then(([resBrandData, resCity]) => {
      setBrands(resBrandData?.data);
      setCities(sortBy(resCity, 'cityName'));
    });
  }, [dispatch]);

  const handleEditService = useCallback(
    (rowData: IServiceCenter) => () => {
      const { attributes, id, relationships } = rowData;
      const currentServiceCenter = new ServiceCenterModel({
        ...attributes,
        brandId: parseInt(relationships.brand?.data?.id),
      });

      setIsOpen(true);
      setServiceCenterId(id);
      setModalTile(`Trung tâm bảo hành: ${currentServiceCenter.name}`);
      setServiceCenter(currentServiceCenter);
      form.setFieldsValue(currentServiceCenter);
    },
    [],
  );

  const handleChangeValues = useCallback((_, values) => {
    const currentServiceCenter = new ServiceCenterModel(values);
    setServiceCenter(currentServiceCenter);
    form.setFieldsValue(currentServiceCenter);
    dispatch(setIsEditing(true));
  }, []);

  const handleSaveServiceCenter = useCallback(async () => {
    try {
      if (serviceCenterId) {
        await api.put({
          endpoint: API_PUT_DELETE_SERVICE_CENTER.replace('{id}', serviceCenterId),
          data: serviceCenter,
        });
        setIsOpen(false);
        setReloadList(true);
        dispatch(setIsEditing(false));
      } else {
        await api
          .post({ endpoint: API_GET_POST_SERVICE_CENTER, data: serviceCenter })
          .then((res) => {
            setServiceCenterId(res.data?.id);
            setModalTile(`Trung tâm bảo hành: ${serviceCenter.name}`);
          });
        setIsOpen(false);
        setReloadList(true);
        dispatch(setIsEditing(false));
      }
    } catch (err) {
      showErrorMsg(err);
    }
  }, [serviceCenter, serviceCenterId]);

  const handleDeleteServiceCenter = useCallback(() => {
    deleteModal({
      title: `Ban có muốn xóa trung tâm bảo hành: <strong>${serviceCenter.name}</strong> không?`,
      onDeleteCb: async () => {
        try {
          await api.remove({
            endpoint: API_PUT_DELETE_SERVICE_CENTER.replace('{id}', serviceCenterId),
          });
          setReloadList(true);
          setIsOpen(false);
          dispatch(setIsEditing(false));
        } catch (err) {
          showErrorMsg(err);
        }
      },
    });
  }, [serviceCenterId, serviceCenter]);

  const handleDeletOutside = useCallback(
    (rowData: IServiceCenter) => () => {
      deleteModal({
        title: `Ban có muốn xóa trung tâm bảo hành: <strong>${rowData.attributes.name}</strong> không?`,
        onDeleteCb: async () => {
          try {
            await api.remove({
              endpoint: API_PUT_DELETE_SERVICE_CENTER.replace('{id}', rowData.id),
            });
            setReloadList(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      const pagin = pagination;
      pagin.current = page;
      setPagination(pagin);
      setReloadList(true);
    },
    [pagination],
  );

  const handleFilterChange = useCallback((_, values) => {
    const filter = new FilterServiceCenterModel(values);
    setFilterContent(filter);
    filterForm.setFieldsValue(filter);
  }, []);

  const handleFilterServiceCenter = useCallback(() => {
    handleChangePage(1);
  }, [handleChangePage]);

  const handleClearFilter = useCallback(() => {
    setFilterContent(defaultFilterServiceCenter);
    handleChangePage(1);
  }, [handleChangePage]);

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tên Trung Tâm Bảo Hành',
      dataIndex: 'attributes',
      render: (attr: IServiceCenterAttribute, row: IServiceCenter) => (
        <div
          onClick={handleEditService(row)}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {attr.name}
        </div>
      ),
    },
    {
      title: 'Thương hiệu',
      dataIndex: 'attributes',
      render: (attr: IServiceCenterAttribute) => attr.brand,
    },
    {
      title: 'Tỉnh, thành',
      dataIndex: 'attributes',
      render: (attr: IServiceCenterAttribute) => {
        const city = cities.find((item) => item.cityCode === attr.province);
        return city?.cityName;
      },
    },
    {
      title: 'Điện thoại',
      dataIndex: 'attributes',
      render: (attr: IServiceCenterAttribute) => attr.phone,
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      align: AlignType.right,
      render: (rowData: IServiceCenter) => (
        <Button title="Xoá" icon={<DeleteOutlined />} onClick={handleDeletOutside(rowData)} />
      ),
    },
  ];

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    form.resetFields();
    dispatch(setIsEditing(false));
  }, []);

  return (
    <>
      <LayoutList
        pageTitle="Trung tâm bảo hành"
        showAddButton
        handleClickAddButton={handleOpenModal}
        columns={columns}
        dataList={serviceCenters}
        breadcrumbs={[
          {
            title: 'Trung tâm bảo hành',
            route: '/trung-tam-bao-hanh',
          },
        ]}
        pagination={pagination}
        handleChangePage={handleChangePage}
        topPagination
        loadingList={reloadList}
        filterNode={
          <Form
            layout="vertical"
            form={filterForm}
            onFinish={handleFilterServiceCenter}
            onValuesChange={handleFilterChange}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Nhập tên trung tâm bảo hành" name="name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Chọn thương hiệu" name="brandId">
                  <Select className="ant-select-lg" showSearch optionFilterProp="children">
                    {brands?.map(({ attributes, id }) => (
                      <Select.Option value={id} key={id}>
                        {attributes.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <Form.Item label={<br />}>
                  <Button className="secondary-button" htmlType="submit">
                    Filter
                  </Button>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label={<br />}>
                  <Button onClick={handleClearFilter}>Clear</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
      <CustomPopup
        title={modalTitle}
        isOpen={isOpen}
        onCloseModal={handleCloseModal}
        showDeleteButton={!!serviceCenterId}
        handleSaveButton={handleSaveServiceCenter}
        handleValuesChange={handleChangeValues}
        handleDeleteButton={handleDeleteServiceCenter}
        formHook={form}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Tên trung tâm bảo hành"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền tên trung tâm bảo hành',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Thương hiệu"
              name="brandId"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn thương hiệu',
                },
              ]}
            >
              <Select className="ant-select-lg" showSearch optionFilterProp="children">
                {brands?.map(({ attributes, id }) => (
                  <Select.Option value={parseInt(id)} key={id}>
                    {attributes.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tỉnh thành"
              name="province"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn tỉnh/thành',
                },
              ]}
            >
              <Select
                className="ant-select-lg"
                showSearch
                optionFilterProp="children"
                filterOption={filterSelectNoUnicode}
              >
                {cities?.map((city) => (
                  <Select.Option value={city.cityCode} key={city.cityCode}>
                    {city.cityName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Điện thoại TT bảo hành"
              name="phone"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền số điện thoại trung tâm',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Địa chỉ TT bảo hành"
              name="address"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập địa chỉ trung tâm bảo hành',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </CustomPopup>
    </>
  );
};

export default ServiceCenter;
