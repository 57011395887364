import { Col, Input, Row, Form, Button, Select } from 'antd';
import { FilterCoupons } from 'pages/Coupons/components/FilterView';
import { useCallback, useEffect } from 'react';
import { COUPON_STATUS_LIST, STORE_IDS } from 'constants/constants';
import { CouponStatus } from 'constants/enum';

interface FilterCouponsProps {
  onFilter: (filter: FilterCoupons) => void;
}

const INIT_FILTER = {
  title: '',
  displayTitle: '',
  prefixCode: '',
  sku: '',
  status: '',
};

const FilterView: React.FC<FilterCouponsProps> = ({ onFilter }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(INIT_FILTER);
  }, []);

  /**
   * TODO: action reset form
   */
  const onClear = useCallback(() => {
    form.resetFields();
    onFilter(INIT_FILTER as FilterCoupons);
  }, [onFilter]);

  /**
   * TODO: action submit filter
   */
  const onSubmitFilter = useCallback(() => {
    onFilter(form.getFieldsValue() as FilterCoupons);
  }, []);

  return (
    <Form layout="vertical" form={form}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Tiêu đề" name="title">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tiêu đề trình bày" name="displayTitle">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Prefix" name="prefixCode">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="SKU / Tên sản phẩm" name="sku">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tình trạng" name="status">
            <Select
              className="ant-select-lg"
              options={COUPON_STATUS_LIST.filter((c) => c.value !== CouponStatus.expired)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Cửa hàng" name="storeId">
            <Select className="ant-select-lg" options={STORE_IDS} />
          </Form.Item>
        </Col>
        <Col
          span={24}
          className="align-items-center justify-content-end"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button htmlType="button" className="secondary-button" onClick={onSubmitFilter}>
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={onClear}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterView;
