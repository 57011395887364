import { Card, Col, Pagination, message, Row, Spin, Empty } from 'antd';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setReloadAllComments } from 'redux/comment/actions';
import { AppState } from 'redux/store';
import * as api from 'services/api.service';
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { CustomModal } from 'ui/components/MaiNguyen/CustomModal';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';
import { API_GET_POST_COMMENTS, API_UPDATE_DELETE_COMMENTS } from 'constants/api/comment.api';

import { BaseComment } from './BaseComment';
import { CommentorGroup } from './CommentorGroup';

import { Filter, FilterCommentsPage } from './Filter';
import { useFetchComments } from '../hooks';

const commentState = (state: AppState) => state.comment;

const CommentList: React.FC = () => {
  const { newCommentLength } = useSelector(commentState);
  const { isFetching, fetch, pagination, setPagination, data } = useFetchComments();
  const location = useLocation();

  const params = queryString.parse(location.search) as any;

  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [parentId, setParentId] = useState(null);
  const dispatch = useDispatch();

  const handleScrollToTop = () => {
    const mainContent = document.getElementById('main-content');
    mainContent.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleOpenAnswerBox = useCallback(
    (commentId: string) => () => {
      setIsOpen(!isOpen);
      setParentId(commentId ? parseInt(commentId) : null);
    },
    [isOpen],
  );

  const handleUpdateComment = (value: string) => {
    setComment(value);
  };

  const handleReply = useCallback(async () => {
    if (comment && parentId) {
      try {
        await api.post({ endpoint: API_GET_POST_COMMENTS, data: { comment, parentId } });
        setIsOpen(false);
        await fetch();
        dispatch(setReloadAllComments(true));
      } catch (error) {
        showErrorMsg(error);
      }
    }
  }, [comment, parentId, fetch]);

  const handleApproveOrDeined = useCallback(
    (commentId: string, isApproved: boolean) => async () => {
      try {
        await api.put({
          endpoint: API_UPDATE_DELETE_COMMENTS.replace('{id}', commentId),
          data: { active: isApproved ? 1 : -1 },
        });
        dispatch(setReloadAllComments(true));
        await fetch();
        message.success(isApproved ? 'Duyệt bình luận thành công' : 'Từ chối bình luận thành công');
      } catch (error) {
        showErrorMsg(error);
      }
    },
    [fetch],
  );

  const handleDeleteComment = (commentId: string) => () => {
    deleteModal({
      title: 'Bạn có muốn xóa bình luận này',
      onDeleteCb: async () => {
        try {
          await api.remove({ endpoint: API_UPDATE_DELETE_COMMENTS.replace('{id}', commentId) });
          dispatch(setReloadAllComments(true));
          await fetch();
        } catch (error) {
          showErrorMsg(error);
        }
      },
    });
  };

  const onFilter = useCallback(
    async (filter: FilterCommentsPage) => {
      await fetch({ page: 1, ...filter });
    },
    [fetch],
  );

  const onPageChange = useCallback(
    async (page) => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetch({ page });
      handleScrollToTop();
    },
    [fetch],
  );

  return (
    <>
      <Filter totalNewComments={newCommentLength} onFilter={onFilter} />

      <div>
        {data.length > 0 && (
          <div className="mt-2 mb-3 text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}

        <div className="comment-list-wrapper">
          {isFetching && (
            <div className="comment-list__loading">
              <Spin />
            </div>
          )}
          {data.length > 0 ? (
            <div className="comment-list">
              {data.map((item) => (
                <Card key={item.id}>
                  <Row gutter={24}>
                    <Col span={18}>
                      <BaseComment
                        name={item.attributes.customer?.name || item.attributes.name}
                        url={item.attributes.url}
                        comment={item.attributes.comment}
                        createdAt={item.attributes.createdAt}
                        onFindByUrl={async (url) => await fetch({ page: 1, url })}
                      />
                    </Col>
                    <Col span={6}>
                      <CommentorGroup
                        name={item.attributes.customer?.name || item.attributes.name}
                        email={
                          item.attributes?.customer?.phone ||
                          item.attributes?.customer?.email ||
                          item.attributes?.phone ||
                          item.attributes.email
                        }
                        showAnwserBtn
                        isShowDeniedBtn={item.attributes.active !== -1}
                        isShowApprovedBtn={item.attributes.active !== 1}
                        onClickOpenModal={handleOpenAnswerBox(item.id)}
                        onApprove={handleApproveOrDeined(item.id, true)}
                        onDenied={handleApproveOrDeined(item.id, false)}
                        onDelete={handleDeleteComment(item.id)}
                      />
                    </Col>
                  </Row>
                  {item.attributes.replies?.filter((o) => {
                    if (params?.status == 0 && o.active != 0) return false;
                    if (params?.status == 1 && o.active != 1) return false;
                    return true;
                  })?.length > 0 && (
                    <>
                      <br />
                      <hr />
                      <br />
                      <Row gutter={15}>
                        <Col span={4}>
                          <div className="comment-number">
                            <strong>{`Bình luận trả lời (${item.attributes.replies.length})`}</strong>
                          </div>
                        </Col>
                        <Col span={20}>
                          <div className="children-comments">
                            {item.attributes.replies.map((child, index) => {
                              if (params?.status == 0 && child.active != 0) return null;
                              if (params?.status == 1 && child.active != 1) return null;
                              return (
                                <Row
                                  key={index}
                                  gutter={30}
                                  style={{ marginLeft: 0, marginRight: 0 }}
                                >
                                  <Col span={17}>
                                    <BaseComment
                                      name={
                                        child.owner && !child.name && isEmpty(child.customer)
                                          ? `${child.owner.firstName} ${child.owner.lastName}`
                                          : child.customer?.name || child.name
                                      }
                                      comment={child.comment}
                                      createdAt={child.createdAt}
                                      isAdmin={
                                        !!child.owner && !child.name && isEmpty(child.customer)
                                      }
                                      showName
                                      hideUrl
                                    />
                                  </Col>
                                  <Col span={7}>
                                    <CommentorGroup
                                      name={child?.customer?.name || child.name}
                                      email={
                                        child?.customer?.phone ||
                                        child?.customer?.email ||
                                        child?.phone ||
                                        child.email
                                      }
                                      isShowApprovedBtn={child.active !== 1}
                                      isAdmin={
                                        !!child.owner && !child.name && isEmpty(child.customer)
                                      }
                                      onDelete={handleDeleteComment(child.id)}
                                      onApprove={handleApproveOrDeined(child.id, true)}
                                      onDenied={handleApproveOrDeined(child.id, false)}
                                    />
                                  </Col>
                                </Row>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>
              ))}
            </div>
          ) : (
            <div className="blk-empty">
              <Empty description="Danh sách trống" />
            </div>
          )}
        </div>

        {data.length > 0 && (
          <div className="mt-2 mb-3 text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}
      </div>

      <CustomModal
        title="Trả lời khách hàng"
        confirmText="Xong"
        isOpen={isOpen}
        onCloseModal={handleOpenAnswerBox(null)}
        onSave={handleReply}
      >
        <CustomEditor
          listContent={false}
          noVideo
          mainContent={comment}
          handleChangeMainContent={handleUpdateComment}
        />
      </CustomModal>
    </>
  );
};

export default CommentList;
