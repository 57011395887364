/* eslint-disable camelcase */
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PAYMENT_METHOD_LIST, SpentList } from 'constants/constants';
import { DateFormat, STATUS_LIST, directionList } from 'constants/enum';

export interface FilterOrdersPage {
  code: string;
  paymentMethod: string;
  customerPhone: string;
  status: string;
  startDate: string;
  endDate: string;
  hasVat: number | string;
  total: string;
  direction?: string;
  column?: string;
  sortBy?: string;
}

interface Props {
  onFilter: (filter: FilterOrdersPage) => void;
}

const INIT_FILTER: FilterOrdersPage = {
  code: '',
  customerPhone: '',
  paymentMethod: '',
  status: '',
  startDate: '',
  endDate: '',
  hasVat: '',
  total: '',
  direction: directionList[0].value,
  column: 'id',
  sortBy: directionList[0].value,
};

const { RangePicker } = DatePicker;
export const FilterOrders: React.FC<Props> = (props) => {
  const { onFilter } = props;
  const [form] = Form.useForm();

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (!isEmpty(params)) {
      const { startDate, endDate, ...rest } = params || {};
      form.setFieldsValue({
        ...rest,
        startDate:
          startDate && endDate
            ? [moment(startDate, DateFormat.serverSide), moment(endDate, DateFormat.serverSide)]
            : null,
      });
    }
  }, [params]);

  const onClear = useCallback(() => {
    form.resetFields();
    onFilter({ ...INIT_FILTER } as FilterOrdersPage);
  }, [onFilter]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => {
        onFilter({
          ...values,
          startDate: values?.startDate?.length
            ? moment(values.startDate[0]).format(DateFormat.serverSide)
            : '',
          endDate: values?.startDate?.length
            ? moment(values.startDate[1]).format(DateFormat.serverSide)
            : '',
        });
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Mã đơn hàng" name="code">
            <Input placeholder="Nhập mã đơn hàng" autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Số điện thoại khách hàng" name="customerPhone">
            <Input placeholder="Nhập SĐT khách hàng" autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Xuất Hóa đơn" name="hasVat">
            <Select
              placeholder="Chọn xuất hóa đơn"
              className="ant-select-lg"
              listHeight={300}
              options={[
                {
                  label: 'Tất cả',
                  value: '',
                },
                {
                  label: 'Có',
                  value: '1',
                },
                {
                  label: 'Không',
                  value: '0',
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Tình trạng" name="status">
            <Select
              placeholder="Chọn tình trạng"
              className="ant-select-lg"
              options={STATUS_LIST}
              listHeight={300}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Hình thức thanh toán" name="paymentMethod">
            <Select
              placeholder="Chọn tình trạng"
              className="ant-select-lg"
              options={[
                {
                  label: 'Tất cả',
                  value: '',
                },
                ...PAYMENT_METHOD_LIST,
              ]}
              listHeight={300}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Giá trị đơn" name="total">
            <Select
              placeholder="Chọn hạn mức"
              className="ant-select-lg"
              listHeight={300}
              options={[
                {
                  label: 'Dưới 5tr',
                  value: '<5000000',
                },
                ...SpentList,
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Có mua hàng trong thời gian"
            name="startDate"
            rules={[
              {
                validator: (_, value: any[]) => {
                  if (!value) return Promise.resolve();

                  const [start, end] = value;

                  if (end && end < start) {
                    return Promise.reject('Vui lòng chọn ngày kết thúc hợp lệ');
                  }

                  return Promise.resolve();
                },
                validateTrigger: 'onChange',
              },
            ]}
          >
            <RangePicker
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              format={DateFormat.serverSide}
              showTime={false}
            />
          </Form.Item>
        </Col>

        <Col
          span={24}
          className="align-items-center justify-content-end"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button htmlType="submit" className="secondary-button">
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={onClear}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
