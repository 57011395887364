import { IAttrAttributeSet } from 'interfaces/attr-set.interface';

export class AttributeSetModel implements IAttrAttributeSet {
  code?: string;

  name?: string;

  skuPrefix?: string;

  constructor({ code, name, skuPrefix }: IAttrAttributeSet) {
    this.code = code || '';
    this.name = name || '';
    this.skuPrefix = skuPrefix || '';
  }
}
