import { Col, Empty, Row, Pagination } from 'antd';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setIsEditing } from 'redux/uiAction/actions';
import { Loading } from 'ui/components/MaiNguyen/Loading';
import { getFullUrlWithSlug } from 'utils/utils';
import ProductItem from './components/ProductItem';
import { useFetchFavoriteProdsByMember } from './hook';
import ButtonBack from '../components/ButtonBack';
import MemberDetailsMenu from '../components/Menu';
import { useFetchMember } from '../hooks';
import '../index.styles.scss';

const MemberWishList: React.FC = () => {
  const dispatch = useDispatch();
  const { id: memberId } = useParams<{ id: string }>();
  const { data: memberData } = useFetchMember(memberId);

  const memberAttributes = useMemo(() => {
    return memberData?.attributes;
  }, [memberData]);

  useEffect(() => {
    memberData?.id && dispatch(setIsEditing(false));
  }, [memberData?.id]);

  // Hook get member reviews
  const { isFetching, data, pagination, setPagination, fetchWishList } =
    useFetchFavoriteProdsByMember();

  /**
   * Handle the feedback list of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onPageChange = useCallback(
    async (page: number) => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchWishList({ page });
    },
    [fetchWishList],
  );

  return (
    <LayoutDetail
      pageTitle={`Member #${memberId || ''}`}
      isAddNew={false}
      pageClassName="member-details member-wishlist"
      breadcrumbs={[
        {
          title: 'Danh sách members',
          route: '/danh-sach-members',
        },
        {
          title: 'Sản phẩm Yêu Thích',
          route: `/danh-sach-members/${memberId}/wishlist`,
        },
      ]}
    >
      {/* Menu */}
      <MemberDetailsMenu
        totalOrder={memberAttributes?.totalOrder}
        totalComment={memberAttributes?.totalComment}
        totalReview={memberAttributes?.totalReview}
        totalWishlist={memberAttributes?.totalWishlist}
      />

      {/* List */}
      <div className="member-content">
        <div className="member-review-info">
          <Row>
            <Col span={24}>
              <Row className="justify-content-end mb-3">
                <ButtonBack />
              </Row>
            </Col>
          </Row>
        </div>

        {!!data?.length && (
          <div className="mt-2 mb-3 text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}

        <div className="member-wishlist-content relative">
          <Loading isLoading={isFetching} />

          {data?.length ? (
            <div className="wishlist-wrap row">
              {data.map((o: any) => {
                const { storeIds, slug } = o?.attributes ?? {};
                return (
                  <ProductItem
                    key={`wishlist-item-${o.id}`}
                    data={o}
                    onClick={() =>
                      window.open(`${getFullUrlWithSlug(storeIds[0], `/${slug}`)}`, '_blank')
                    }
                  />
                );
              })}
            </div>
          ) : (
            <div className="blk-empty">
              <Empty description="Danh sách trống" />
            </div>
          )}
        </div>

        {!!data?.length && (
          <div className="mt-3 mb-2 text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}
      </div>
    </LayoutDetail>
  );
};

export default MemberWishList;
