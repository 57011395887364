import { IAttribute } from 'interfaces/attribute.interface';

export const SET_SHIPPING_FIELD = 'SET_SHIPPING_FIELD';
export const RESET_SHIPPING_FIELDS = 'RESET_SHIPPING_FIELDS';

export interface SetShippingFieldInterface {
  type: typeof SET_SHIPPING_FIELD;
  payload: IAttribute[];
}

export interface ResetShippingFields {
  type: typeof RESET_SHIPPING_FIELDS;
}

export type ActionShippingType = SetShippingFieldInterface | ResetShippingFields;
