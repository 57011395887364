import { Col, Form, Input, Row, Select, message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { STORE_IDS } from 'constants/constants';
import { useCreateEventPage } from '../hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateEventModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;
  const [form] = Form.useForm();
  const history = useHistory();

  const { isCreating, onCreate } = useCreateEventPage();

  const onSubmit = useCallback(async () => {
    try {
      const values = form.getFieldsValue();
      const { id } = await onCreate({
        data: {
          ...values,
          publish: false,
        },
      });
      message.success('Thêm Event Page thành công');
      history.push(`/event-page/${id}`);
    } catch (error: any) {
      message.error(error?.message || error);
    }
  }, []);

  return (
    <CustomPopup
      title="Tạo Event Page mới"
      formHook={form}
      isOpen={isOpen}
      onCloseModal={onClose}
      isSaving={isCreating}
      handleSaveButton={onSubmit}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Tên event page"
            name="title"
            rules={[{ required: true, message: 'Vui lòng nhập tên event page' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Chọn cửa hàng"
            name="storeIds"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn cửa hàng',
              },
            ]}
          >
            <Select mode="multiple" className="ant-select-lg" options={STORE_IDS} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Description (SEO)" name="metaDescription">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Keywords (SEO)" name="metaKeywords">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </CustomPopup>
  );
};
