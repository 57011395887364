/* eslint-disable @typescript-eslint/no-use-before-define */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Select, message } from 'antd';
import { useFetchProductCategories } from 'hooks/useFetchProductCategories';
import { useFetchProducts } from 'hooks/useFetchProducts';
import { useFetchSubStatus } from 'hooks/useFetchSubStatus';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';

import './styles.scss';
import { confirmModal } from 'utils/modals';
import { AddProductModal } from './AddProductModal';
import { ProductTable } from './ProductTable';

interface Props {
  data: {
    blockId: string;
    blockTitle: string;
    featuredSku: string;
    listOfSku: string[];
  };
  onChange: (data: string) => void;
}

const INITIAL_DATA: Props['data'] = {
  blockId: '',
  blockTitle: '',
  featuredSku: null,
  listOfSku: [],
};

export const ListOfProductsTemplate: React.FC<Props> = (props) => {
  const { data = INITIAL_DATA, onChange } = props;

  const { data: subStatus } = useFetchSubStatus();
  const { data: productCategories } = useFetchProductCategories();
  const { isFetchingProducts, products, fetchProducts } = useFetchProducts();

  const [isOpenAddProductModal, setIsOpenAddProductModal] = useState<boolean>(false);

  useEffect(() => {
    !products?.length && data?.listOfSku?.length && _fetchProducts(data.listOfSku);
  }, [data]);

  const _fetchProducts = useCallback(
    async (skus?: string[]): Promise<void> => {
      try {
        await fetchProducts({
          perPage: 'all',
          type: 'configurable',
          ...queryString.parse(
            queryString.stringify(
              {
                skus: [...(skus || [])],
              },
              { skipNull: true, skipEmptyString: true, arrayFormat: 'bracket' },
            ),
            {
              arrayFormat: 'bracket',
            },
          ),
        });
      } catch (error: any) {
        message.error(error?.message || error);
      }
    },
    [data, fetchProducts],
  );

  const _onChange = useCallback(
    (field: keyof Props['data'], value: string | string[]) => {
      const newData = { ...(data || {}), [field]: value };
      onChange(JSON.stringify(newData));
    },
    [onChange, data],
  );

  return (
    <div className="product-list-template">
      <Col span={24}>
        <Form.Item label="Block Id (Optional)">
          <Input value={data.blockId} onChange={(e) => _onChange('blockId', e.target.value)} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Tiêu đề danh sách sản phẩm">
          <Input
            value={data.blockTitle}
            onChange={(e) => _onChange('blockTitle', e.target.value)}
          />
        </Form.Item>
      </Col>
      {data?.listOfSku?.length > 1 && (
        <div className="featured-product-wrapper">
          <div className="featured-product-title">CHỌN SẢN PHẨM NỔI BẬT</div>
          <div className="featured-product-note">{`* Nếu danh sách có nhiều hơn một sản phẩm, vui lòng chọn ít nhất một sản phẩm nối bật`}</div>
          <Select
            value={data?.featuredSku}
            placeholder="Chọn sản phẩm nổi bật"
            options={products?.map((p) => ({
              value: p.attributes.sku,
              label: p.attributes.name,
            }))}
            onSelect={(value) => _onChange('featuredSku', value as string)}
          />
        </div>
      )}
      <div className="product-list-template__toolbar">
        <div className="toolbar-title">DANH SÁCH SẢN PHẨM</div>
        <div className="toolbar-action-button">
          <Button icon={<PlusOutlined />} onClick={() => setIsOpenAddProductModal(true)}>
            Thêm sản phẩm
          </Button>
        </div>
      </div>
      <div>
        <ProductTable
          loading={isFetchingProducts}
          subStatus={subStatus}
          data={products}
          pagination={null}
          onChangePosition={(newData) =>
            _onChange(
              'listOfSku',
              newData?.map((p) => p.attributes.sku),
            )
          }
          onRemove={(sku: string) => {
            confirmModal({
              title: 'Bạn có chắc muốn xoá sản phẩm này?',
              buttonText: 'Xoá',
              onSaveCb: async () => {
                const newData = data?.listOfSku?.filter((s: string) => s !== sku);
                await _fetchProducts(newData);
                _onChange('listOfSku', newData);
                message.success('Đã xoá sản phẩm');
              },
            });
          }}
        />
      </div>

      <AddProductModal
        isOpen={isOpenAddProductModal}
        subStatus={subStatus}
        productCategories={productCategories}
        initialProductSkus={data?.listOfSku || []}
        onClose={() => setIsOpenAddProductModal(false)}
        onChange={async (productSkus) => {
          const newSkus = [...productSkus, ...(data?.listOfSku || [])];
          _onChange('listOfSku', newSkus);
          setIsOpenAddProductModal(false);
          await _fetchProducts(newSkus);
        }}
      />
    </div>
  );
};
