import { Modal } from 'antd';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './styles.scss';
import { CopyOutlined } from '@ant-design/icons';
import { clickCopyUrlPath } from 'utils/utils';

interface Props {
  visible: boolean;
  onClose: () => void;
  data: string[];
}

export const CouponListModal: React.FC<Props> = (props) => {
  const { visible, data, onClose } = props;
  return (
    <Modal
      footer={null}
      visible={visible}
      title="Danh sách mã coupon"
      onCancel={onClose}
      className="coupon-list-modal"
      centered
      width={1000}
    >
      <div className="view-coupons-list">
        {data?.map((c) => {
          return (
            <CopyToClipboard key={c} text={c} onCopy={clickCopyUrlPath}>
              <div className="coupon-item">
                <div>{c}</div>
                <div className="coupon-item__copy">
                  <CopyOutlined />
                </div>
              </div>
            </CopyToClipboard>
          );
        })}
      </div>
    </Modal>
  );
};
