import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Constants
import { defaultPagination } from 'constants/constants';
import { DateFormat } from 'constants/enum';
// Hook
import {
  FormFilterPreOrder,
  defaultFilterParams,
  useDeletePreOrder,
  useGetPreOrders,
  useGetProducts,
  useGetVariantProducts,
  useSetPreOrder,
  useFormPreOrder,
} from 'hooks/usePreOrders';

// Interfaces
import { IPagination } from 'interfaces/pagination.interface';
import { IPreOrder, IAttributePreOrder } from 'interfaces/pre-order.interface';

// Components
import LayoutList from 'layouts/LayoutList/LayoutList';
import { updatePageData } from 'redux/pages/actions';
import { formatNumber } from 'utils/utils';
import FilterPreOrder from './Filter';
import FormPreOrder from './Form';

import './PreOrderList.scss';

const PreOrderList = () => {
  const location = useLocation();

  const parsedSearch = useMemo(() => queryString.parse(location.search), [location]);

  const [paging, setPaging] = useState<IPagination>(defaultPagination);
  const [openModal, setOpenModal] = useState(false);
  const [isReloading, setIsReloading] = useState(false);

  // filter
  const [formFilter, setFormFilter] = useState<FormFilterPreOrder>(() => {
    return {
      title: (parsedSearch?.search as string) || defaultFilterParams.title,
      sku: (parsedSearch?.sku as string) || defaultFilterParams.sku,
    };
  });

  // Hook pre-orders
  const { isLoading, preOrders, pagination } = useGetPreOrders(
    paging.current,
    isReloading,
    formFilter,
  );
  const {
    variantProducts,
    selectedVariantProducts,
    attrVariantProds,
    isLoading: isLoadingVariantProds,
    resetVariantProducts,
    getVariantProducts,
  } = useGetVariantProducts();
  const {
    products,
    isLoading: isLoadingProducts,
    resetProducts,
    getProductById,
    onSearchProduct,
  } = useGetProducts();
  const { isDeleting, deletePreOrder } = useDeletePreOrder();
  const { preOrder, createPreOrder, handleSetPreOrder } = useSetPreOrder(
    variantProducts,
    attrVariantProds,
  );
  const {
    form,
    isSubmitting,
    onSubmit,
    onEditPreOrder,
    addFormValues,
    resetForm,
    onChangeValue,
    selectedVariantProds,
  } = useFormPreOrder(handleSetPreOrder, getProductById, getVariantProducts, preOrder);

  const dispatch = useDispatch();

  // loading page only first time
  useEffect(() => {
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
  }, []);

  // refresh pre-order list
  useEffect(() => {
    if (isDeleting || isSubmitting) {
      setIsReloading(true);
      setOpenModal(false);
    } else {
      setIsReloading(false);
    }
  }, [isDeleting, isSubmitting]);

  const onChangePage = useCallback(
    (page: number) => {
      setPaging({ ...paging, current: page });
    },
    [paging],
  );

  // Filter pre orders
  const doFilter = useCallback((val) => {
    setFormFilter(val);
    onChangePage(1);
  }, []);

  const addPreOrder = () => {
    setOpenModal(true);

    createPreOrder();
    addFormValues();
    resetProducts();
    resetVariantProducts();
  };

  const closeModal = () => {
    setOpenModal(false);

    resetForm();
    resetVariantProducts();
  };

  const editPreOrder = useCallback(
    (row: IPreOrder) => () => {
      onEditPreOrder(row);
      setOpenModal(true);
    },
    [],
  );

  const submitForm = useCallback(() => {
    onSubmit(preOrder);
  }, [preOrder]);

  // Table config
  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'attributes',
      render: (attr: IAttributePreOrder, row: IPreOrder) => {
        return (
          <div className="pre-order-item" onClick={editPreOrder(row)}>
            {attr.product.sku}
          </div>
        );
      },
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'attributes',
      render: (attr: IAttributePreOrder, row: IPreOrder) => {
        return (
          <div className="pre-order-item" onClick={editPreOrder(row)}>
            {attr.product.name}
          </div>
        );
      },
    },
    {
      title: 'Ngày giao hàng',
      dataIndex: 'attributes',
      render: (attr: IAttributePreOrder) => {
        return moment(attr.deliveryDate).format(DateFormat.clientSide);
      },
    },
    {
      title: 'Số tiền đặt cọc',
      dataIndex: 'attributes',
      render: (attr: IAttributePreOrder) => {
        return formatNumber(attr.moneyDeposit);
      },
    },
    {
      title: '',
      dataIndex: '',
      render: (_, row: IPreOrder) => {
        return (
          <Button icon={<DeleteOutlined />} title="Xóa" onClick={() => deletePreOrder(row.id)} />
        );
      },
    },
  ];

  return (
    <div className="pre-order-page">
      <LayoutList
        pageTitle="Pre-Orders"
        showAddButton
        breadcrumbs={[
          {
            title: 'Pre-Orders',
            route: '/preorders',
          },
        ]}
        topPagination
        columns={columns}
        dataList={preOrders}
        pagination={pagination}
        handleClickAddButton={addPreOrder}
        handleChangePage={onChangePage}
        loadingList={isLoading}
        filterNode={<FilterPreOrder formFilter={formFilter} onFilterChange={doFilter} />}
      />

      <FormPreOrder
        form={form}
        openModal={openModal}
        isLoadingProducts={isLoadingProducts}
        products={products}
        isLoadingVariantProds={isLoadingVariantProds}
        variantProducts={variantProducts}
        selectedVariantProducts={selectedVariantProducts}
        closeModal={closeModal}
        onSubmit={submitForm}
        onChangeVariantProds={selectedVariantProds}
        onChangeValues={onChangeValue}
        onClearProducts={resetProducts}
        onSearchProduct={onSearchProduct}
      />
    </div>
  );
};

export default PreOrderList;
