import { Col, Empty, Row, Pagination } from 'antd';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setIsEditing } from 'redux/uiAction/actions';
import { Loading } from 'ui/components/MaiNguyen/Loading';
import Filter, { FilterOrdersProps } from './components/Filter';
import OrderItem from './components/OrderItem';
import { useFetchMemberOrders } from './hook';
import ButtonBack from '../components/ButtonBack';
import MemberDetailsMenu from '../components/Menu';
import { useFetchMember } from '../hooks';
import '../index.styles.scss';

const MemberOrders: React.FC = () => {
  const dispatch = useDispatch();
  const { id: memberId } = useParams<{ id: string }>();
  const { data: memberData } = useFetchMember(memberId);

  const memberAttributes = useMemo(() => {
    return memberData?.attributes;
  }, [memberData]);

  useEffect(() => {
    memberData?.id && dispatch(setIsEditing(false));
  }, [memberData?.id]);

  // Hook get orders reviews
  const { isFetching, data, pagination, setPagination, fetchMemberOrders } = useFetchMemberOrders();

  /**
   * Handle the orders list of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onPageChange = useCallback(
    async (page: number) => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchMemberOrders({ page });
    },
    [fetchMemberOrders],
  );

  // TODO: fetch data member orders
  const fetchData = async (filter) => {
    await fetchMemberOrders({ page: 1, ...filter });
  };

  /**
   * TODO: handle filter member orders
   */
  const onFilter = useCallback(
    debounce((filter: FilterOrdersProps) => {
      fetchData(filter);
    }, 300),
    [fetchMemberOrders],
  );

  return (
    <LayoutDetail
      pageTitle={`Member #${memberId || ''}`}
      isAddNew={false}
      pageClassName="member-details member-orders member-wishlist"
      breadcrumbs={[
        {
          title: 'Danh sách members',
          route: '/danh-sach-members',
        },
        {
          title: 'Đơn hàng',
          route: `/danh-sach-members/${memberId}/orders`,
        },
      ]}
    >
      {/* Menu */}
      <MemberDetailsMenu
        totalOrder={memberAttributes?.totalOrder}
        totalComment={memberAttributes?.totalComment}
        totalReview={memberAttributes?.totalReview}
        totalWishlist={memberAttributes?.totalWishlist}
      />

      {/* List */}
      <div className="member-content">
        <div className="member-review-info">
          <Row>
            <Col span={18} className="flex items-center flex-wrap gap-2">
              <Filter onFilter={onFilter} />
            </Col>
            <Col span={6}>
              <Row className="justify-content-end mb-3">
                <ButtonBack />
              </Row>
            </Col>
          </Row>
        </div>

        <div className="mt-2 mb-3 text-right order-pagi">
          {!!data?.length && (
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          )}
        </div>

        <div className="member-orders-content relative">
          <Loading isLoading={isFetching} />

          {data?.length ? (
            <div className="orders-list">
              {data.map((o: any) => {
                return <OrderItem key={`order-item-${o.id}`} data={o} />;
              })}
            </div>
          ) : (
            <div className="blk-empty">
              <Empty description="Danh sách trống" />
            </div>
          )}
        </div>

        {!!data?.length && (
          <div className="mt-3 mb-2 text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}
      </div>
    </LayoutDetail>
  );
};

export default MemberOrders;
