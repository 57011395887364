import React, { memo } from 'react';

export const ProductReviewDesc: React.FC = memo(() => {
  return (
    <div className="desc mb-4">
      <p>
        <b>Tạo review dữ liệu mồi</b>
      </p>
      <p>
        Form này là để tạo dữ liệu mồi, chỉ có admin mới được phép tạo dữ liệu tại đây, Các tên USER
        có thể là Fake, không cần có data thật trong hệ thống.
      </p>
      <ul>
        <li>
          Hệ thống sẽ tự gắn các Ribbon: Đã mua tại STORE nào? Đã chia sẻ bạn bè? Thời gian sử dụng
          và ngày đăng trong vòng 1 tháng.
        </li>
      </ul>
    </div>
  );
});
