/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button, Checkbox, Col, Form, Input, Pagination, Row, Select, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ICollection } from 'interfaces/collection.interface';
import { IProduct } from 'interfaces/product.interface';
import { IStatus } from 'interfaces/status.interface';
import { ProductTable } from 'pages/CollectionDetail/components/ProductTable';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsEditing } from 'redux/uiAction/actions';
import { fetcher, fetcherWithArg } from 'services/swr.utils';
import useSWR, { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { CustomTreeSelect } from 'ui/components/MaiNguyen/CustomTreeSelect';
import { confirmModal } from 'utils/modals';
import { API_COLLECTIONS } from 'constants/api/collection.api';
import { API_COUPON_BY_ID } from 'constants/api/coupons.api';
import { API_GET_PRODUCTS } from 'constants/api/product.api';
import { SelectedProductsModal } from './SelectedProductsModal';
import { useFetchProducts, useUpdateCoupon } from '../../hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  productCategories: any;
  subStatus: IStatus[];
  couponId: string;
  productIdsOfCoupon: string[];
  fetchProductsOfCoupon: () => Promise<void>;
}

export const AddProductModal: React.FC<Props> = (props) => {
  const {
    isOpen,
    couponId,
    productIdsOfCoupon,
    productCategories,
    subStatus,
    fetchProductsOfCoupon,
    onClose,
  } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  const {
    isFetchingProducts,
    products,
    fetchProducts,
    reset: resetProducts,
    pagination,
    setPagination,
  } = useFetchProducts();
  const { onUpdate } = useUpdateCoupon();
  const { isLoading: isFetchingCollections, data } = useSWR(
    `${API_COLLECTIONS}?page=1&perPage=all&active=1`,
    fetcher,
  );
  // This API is used when selecting all products
  const { trigger: fetchAllProducts, reset: resetAllProducts } = useSWRMutation(
    API_GET_PRODUCTS,
    fetcherWithArg,
  );

  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [openSelectedProductsModal, setOpenSelectedProductsModal] = useState<boolean>(false);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);

  const selectedProductIds = selectedProducts?.map((p) => p.id);
  const combineProductIds = [...selectedProductIds, ...productIdsOfCoupon];

  const queryParams = () => {
    const values = form.getFieldsValue();
    return {
      perPage: 20,
      type: 'configurable',
      inStock: true,
      ...queryString.parse(
        queryString.stringify(
          {
            ...values,
            ignoreIds: productIdsOfCoupon?.length > 1 ? productIdsOfCoupon : undefined,
            ignoreId: productIdsOfCoupon?.length === 1 ? productIdsOfCoupon[0] : undefined,
          },
          { skipNull: true, skipEmptyString: true },
        ),
      ),
    };
  };

  /**
   * A callback function that performs a search for products based on the form values and selected product IDs.
   */
  const onSearch = useCallback(async (): Promise<void> => {
    await fetchProducts({ page: 1, ...queryParams() });
  }, [combineProductIds, queryParams]);

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === 'Enter') {
        await onSearch();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onSearch]);

  /**
   * An effect that resets the form fields, products, and selected product IDs when the modal is closed.
   */
  useEffect((): void => {
    if (!isOpen) {
      form.resetFields();
      resetProducts();
      resetAllProducts();
      setSelectedProducts([]);
      setIsCheckAll(false);
    }
  }, [isOpen]);

  /**
   * A callback function that saves the selected product IDs to the collection.
   */
  const onSave = useCallback(async (): Promise<void> => {
    try {
      setIsSaving(true);
      const newData = await onUpdate({
        method: 'put',
        id: couponId,
        data: { productIds: combineProductIds },
      });
      await mutate(API_COUPON_BY_ID.replace('{id}', couponId), newData, {
        revalidate: false,
        rollbackOnError: true,
      });
      dispatch(setIsEditing(false));
      setSelectedProducts([]);
      await fetchProductsOfCoupon();
      await onSearch();
      message.success(`Thêm sản phẩm vào coupon thành công`);
    } catch (error: any) {
      message.error(`${error?.message || error}`);
    } finally {
      setIsSaving(false);
      setIsCheckAll(false);
    }
  }, [combineProductIds, onSearch, fetchProductsOfCoupon, couponId]);

  /**
   * A callback function that selects products based on the provided product IDs.
   *
   * @param {React.Key[]} productIds - An array of product IDs to select.
   */
  const onSelectProducts = useCallback((_, _products: IProduct[]) => {
    setSelectedProducts(_products);
    dispatch(setIsEditing(true));
  }, []);

  /**
   * A callback function that handles page changes in pagination.
   *
   * @param {number} page - The new page number.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchProducts({ page, ...queryParams() });
    },
    [fetchProducts, queryParams],
  );

  const onCheckAll = useCallback(
    (e: CheckboxChangeEvent) => {
      if (e.target.checked) {
        confirmModal({
          title: `Bạn có chắc là muốn chọn tất cả ${pagination.total} sản phẩm?`,
          buttonText: 'OK',
          onSaveCb: async () => {
            await fetchAllProducts({ page: 1, ...queryParams(), perPage: 'all' }).then((res) => {
              if (res?.data) {
                setSelectedProducts(res.data);
                setIsCheckAll(true);
                dispatch(setIsEditing(true));
              }
            });
          },
        });

        return;
      } else {
        setIsCheckAll(false);
        setSelectedProducts([]);
      }
    },
    [pagination.total, queryParams],
  );

  return (
    <CustomPopup
      title="Thêm sản phẩm"
      containerCls="add-product-modal"
      isOpen={isOpen}
      onCloseModal={() => {
        dispatch(setIsEditing(false));
        onClose();
      }}
      isSaving={isSaving}
      handleSaveNoEnter={onSave}
    >
      <div className="add-product-modal__content">
        <Form form={form} layout="vertical">
          <Row gutter={24}>
            <Col span={7}>
              <Form.Item label="SKU / Tên sản phẩm" name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Bộ sưu tập / Collections" name="collectionId">
                <Select size="large" loading={isFetchingCollections} allowClear>
                  {data?.map((c: ICollection) => (
                    <Select.Option key={c.id} value={parseInt(c.id)}>
                      {c.attributes.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Nhóm sản phẩm"
                name="categoryId"
                valuePropName="selectedValue"
                trigger="handleSelect"
              >
                <CustomTreeSelect data={productCategories} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Tìm" className="form-item__search">
                <Button className="search-btn" onClick={onSearch}>
                  Tìm
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {pagination.total > 0 && (
          <Checkbox
            checked={isCheckAll}
            className="check-all"
            style={{
              fontWeight: '600',
              marginBottom: 12,
            }}
            onChange={onCheckAll}
          >
            Chọn tất cả {pagination.total} sản phẩm
          </Checkbox>
        )}

        {!!selectedProductIds?.length && (
          <div
            className="selected-products-label"
            onClick={() => setOpenSelectedProductsModal(true)}
          >
            Xem danh sách đã chọn ({selectedProductIds.length})
          </div>
        )}

        <ProductTable
          loading={isFetchingProducts}
          data={products}
          subStatus={subStatus}
          rowSelection={{
            selectedRowKeys: selectedProductIds,
            onChange: onSelectProducts,
          }}
          scroll={{ y: 400, x: 500 }}
        />

        {!!products?.length && (
          <div className="pagination-wrapper">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              showSizeChanger={false}
              onChange={onPageChange}
              disabled={isFetchingProducts}
            />
          </div>
        )}

        <SelectedProductsModal
          visible={openSelectedProductsModal}
          onClose={() => setOpenSelectedProductsModal(false)}
          data={selectedProducts}
          subStatus={subStatus}
          onRemove={(productId) =>
            setSelectedProducts((prev) => prev.filter((p) => p.id !== productId))
          }
        />
      </div>
    </CustomPopup>
  );
};
