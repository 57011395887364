/* eslint-disable import/order */
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { IAttributeSet } from 'interfaces/attr-set.interface';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CustomTreeSelect } from 'ui/components/MaiNguyen/CustomTreeSelect';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { TreeLevelInterface, generateSlug } from 'utils/utils';
import { CreateProductCategory } from '../types';
import { MNStoreList } from 'constants/enum';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
// Helper
import { clickCopyUrlPath } from 'utils/utils';
import { useFetchProdCatesByParentId } from 'hooks/useFetchProductCategories';

interface EditFormProps {
  isUpdating: boolean;
  selectedCategory: any;
  productCategories: TreeLevelInterface[];
  attributeGroup: IAttributeSet[];
  onFinish: (values: any, images: ImageTypes) => void;
  onRemove: ({ id, name }: { id: string; name: string }) => void;
}

export type ImageTypes = {
  ogImage: {
    file: null;
  };
  smallImage: {
    file: null;
  };
};

export const EditForm: React.FC<EditFormProps> = (props) => {
  const { isUpdating, selectedCategory, productCategories, attributeGroup, onFinish, onRemove } =
    props;
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [form] = Form.useForm();

  const [images, setImages] = useState<ImageTypes>({
    ogImage: { file: null },
    smallImage: { file: null },
  });

  // filter category by parent id
  const { attributes, id } = selectedCategory || {};
  const { parentId } = attributes;

  const selectedCategoryId = id;

  const { isFetching, data: catesList } = useFetchProdCatesByParentId(selectedParentId || parentId);

  useEffect(() => {
    if (selectedCategory) {
      form.setFieldsValue({
        ...attributes,
        storeIds: attributes.storeIds ? attributes.storeIds : [], // if storeIds is null and then set storeIds = []
      });
      // Reset image files
      setImages({ ogImage: { file: null }, smallImage: { file: null } });

      // reset selected parent id
      setSelectedParentId(null);
    }
  }, [selectedCategory]);

  const _onFinish = useCallback(
    (values: CreateProductCategory) => {
      const urlPath =
        values.urlPath?.trim() !== selectedCategory.attributes.urlPath
          ? values.urlPath?.trim()
          : values.name?.trim();
      // remove all null values from form values
      Object.keys(values).forEach((key) => {
        if (values[key] === null || values[key] === 'null') {
          delete values[key];
        }
      });

      // reset position = 0 when change parent category
      if (values.parentId !== parentId) {
        values.position = 0;
      }

      onFinish(
        {
          id: selectedCategoryId,
          attributes: { ...values, urlPath: generateSlug(urlPath) },
        },
        images,
      );
    },
    [selectedCategoryId, onFinish, images, selectedCategory?.attributes],
  );

  const onImageFieldChange = useCallback((fieldName: keyof ImageTypes, file) => {
    setImages((prev) => ({ ...prev, [fieldName]: { file } }));
  }, []);

  const handleChangeParentId = (pId: string) => {
    setSelectedParentId(pId);
  };

  return (
    <Form layout="vertical" form={form} onFinish={_onFinish}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Tên nhóm"
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng điền tên nhóm',
              },
            ]}
          >
            <Input placeholder="Tên nhóm" />
          </Form.Item>

          <div className="url-path-input">
            <Form.Item name="urlPath" label="URL Path">
              <Input />
            </Form.Item>
            <CopyToClipboard text={selectedCategory?.attributes?.urlPath} onCopy={clickCopyUrlPath}>
              <Button
                title="Copy"
                disabled={!selectedCategory?.attributes?.urlPath}
                className="copy-button"
                icon={<CopyOutlined />}
              />
            </CopyToClipboard>
          </div>

          <Form.Item
            label="Nhóm cha"
            name="parentId"
            valuePropName="selectedValue"
            trigger="handleSelect"
          >
            <CustomTreeSelect data={productCategories} handleSelect={handleChangeParentId} />
          </Form.Item>
          <Form.Item label="Nhóm thuộc tính" name="attributeSetId">
            <Select
              loading={isEmpty(attributeGroup)}
              size="large"
              allowClear
              showSearch
              optionFilterProp="children"
            >
              {attributeGroup?.map((item) => (
                <Select.Option key={item.id} value={parseInt(item.id)}>
                  {item.attributes.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="storeIds"
            label="Chọn cửa hàng"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn cửa hàng',
              },
            ]}
          >
            <Select showSearch optionFilterProp="title" mode="multiple" allowClear size="large">
              {MNStoreList.map((item) => {
                const { id: idStore, title } = item;
                return (
                  <Select.Option
                    value={idStore}
                    key={`mn-store-${idStore}`}
                    title={title}
                    className="select-store"
                  >
                    <SanitizeHtml rawHtml={title} />
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {catesList?.length > 0 && (
            <Form.Item name="position" label="Vị trí">
              <Select
                showSearch
                allowClear
                optionFilterProp="value"
                listHeight={450}
                loading={isFetching}
              >
                {catesList?.map((_, index) => (
                  <Select.Option key={index} value={index}>
                    {index}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item initialValue={true} label="Hiển thị" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Mô tả" name="description">
            <Input.TextArea rows={6} />
          </Form.Item>
          <Form.Item label="Keywords" name="keywords">
            <Input.TextArea rows={6} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <UploadFile
              uploadText="Click hoặc kéo thả hình để upload OG:Image (1200x630)"
              previewImage={selectedCategory?.attributes?.image}
              imageSize="256x256"
              maxWidth={256}
              handleUploadImage={(file) => onImageFieldChange('ogImage', file)}
              typeId={selectedCategoryId}
              handleDeleteImageSuccess={() => onImageFieldChange('ogImage', null)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <UploadFile
              uploadText="Click hoặc kéo thả hình để upload (256x256)"
              previewImage={null}
              imageSize="256x256"
              maxWidth={256}
              handleUploadImage={(file) => onImageFieldChange('smallImage', file)}
              typeId={selectedCategoryId}
              handleDeleteImageSuccess={() => onImageFieldChange('smallImage', null)}
            />
          </Form.Item>
        </Col>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<DeleteOutlined />}
            onClick={() =>
              onRemove({ id: selectedCategoryId, name: selectedCategory.attributes.name })
            }
          >
            Xóa
          </Button>
          &nbsp; &nbsp;
          <ThemeButton loading={isUpdating} htmlType="submit">
            Lưu
          </ThemeButton>
        </Col>
      </Row>
    </Form>
  );
};
