import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ICouponAttributes } from 'interfaces/coupon.interface';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { COUPON_STATUS_LIST } from 'constants/constants';
import { CouponStatus, DateFormat } from 'constants/enum';
import ModalSelectCoupon from './ModalSelectCoupon';

interface ExtraCouponsProps {
  list?: ICouponAttributes[];
  isError?: boolean;
  isDisabledBtn?: boolean;
  onChange?: (coupons: ICouponAttributes[]) => void;
}

const ExtraCoupons: React.FC<ExtraCouponsProps> = ({ list, isError, isDisabledBtn, onChange }) => {
  const [coupons, setCoupons] = useState<ICouponAttributes[]>(list || []);
  const [openModal, setOpenModal] = useState(false);
  const [couponIndex, setCouponIndex] = useState(null);

  useEffect(() => {
    setCoupons(list);
  }, [list]);

  useEffect(() => {
    onChange(coupons);
  }, [coupons]);

  // TODO: add more coupon
  const addConpon = () => {
    setCoupons([
      ...coupons,
      {
        id: uuidv4(),
        title: '',
      },
    ]);
  };

  // TODO: show modal find coupon
  const showModal = (idx: number) => {
    setOpenModal(true);
    setCouponIndex(idx);
  };

  // TODO: close modal find coupon
  const closeModal = () => {
    setOpenModal(false);
    setCouponIndex(null);
  };

  // TODO: select coupon
  const selectCounpon = useCallback(
    (coupon: ICouponAttributes) => {
      closeModal();

      // update coupon
      coupons[couponIndex] = coupon;
      setCoupons([...coupons]);
    },
    [coupons, couponIndex],
  );

  // TODO: remove coupon
  const removeCoupon = (id: string) => {
    setCoupons(coupons.filter((c) => c.id !== id));
  };

  return (
    <div className="extra-coupons">
      <div className="coupons-lst">
        {coupons.map((coupon, i) => {
          const { id, endDate, status, title } = coupon;
          const isCouponExpired = endDate
            ? moment(endDate, DateFormat.serverSideWithTime).isBefore(moment())
            : false;
          const statusLabel = COUPON_STATUS_LIST.find(
            (c) => c.value === (isCouponExpired ? CouponStatus.expired : status),
          )?.label;

          return (
            <div className="c-itm" key={`extra-coupon-${id}-${i}`}>
              <div className="c-info">
                <b className="num">Ưu đãi {i + 1}</b>
                <NavLink to={`/ma-giam-gia/${id}`} className="title" target="_blank">
                  {title}
                </NavLink>
                {isCouponExpired && <span className="status-expired">({statusLabel})</span>}
              </div>
              <div className="c-buttons">
                <Button onClick={() => showModal(i)} className="mr-2">
                  Chọn Coupon
                </Button>
                <Button onClick={() => removeCoupon(id)} icon={<DeleteOutlined rev={null} />} />
              </div>
            </div>
          );
        })}
      </div>
      <Button
        onClick={addConpon}
        className={`btn-add-coupons ${isError ? 'error-btn' : ''}`}
        disabled={isDisabledBtn}
      >
        + THÊM ƯU ĐÃI
      </Button>
      {isError && <span className="msg mt-1">Vui lòng thêm ưu đãi</span>}

      <ModalSelectCoupon
        ignoreIds={coupons.filter((o) => o.title !== '').map((c) => c.id)}
        openModal={openModal}
        onCloseModal={closeModal}
        onSelect={selectCounpon}
      />
    </div>
  );
};

export default ExtraCoupons;
