import { IAuth } from 'interfaces/auth.interface';
import { START_LOGIN, LOGIN_SUCCESSFUL, LOGIN_FAILED, AuthActionTypes } from './types';

const initialState: IAuth = {
  isLogin: false,
  token: '',
  loading: false,
  errorMessage: '',
};

export const authReducer = (state: IAuth = initialState, action: AuthActionTypes) => {
  switch (action.type) {
    case START_LOGIN:
      return { ...state, loading: true };
    case LOGIN_SUCCESSFUL:
      return { ...action.payload, loading: false };
    case LOGIN_FAILED:
      return { ...state, loading: false, errorMessage: action.payload };
    default:
      return { ...state };
  }
};
