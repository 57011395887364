import { DeleteOutlined } from '@ant-design/icons';
import { Input, Switch, Button, Col, Row, Form, TreeSelect } from 'antd';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import {
  API_GET_POST_ARTICLES,
  API_GET_ARTICLE_CATEGORIES,
  API_UPDATE_DELETE_ARTICLE,
} from 'constants/api/article.api';
import { defaultPagination } from 'constants/constants';
import { IArticle, IArticleAttribute, IArticleCategory } from 'interfaces/article.interface';
import { IPagination } from 'interfaces/pagination.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
// import { ArticleModel } from 'models/article.model';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { deleteModal } from 'utils/modals';
import { groupArticleCategoriesByParent, showErrorMsg } from 'utils/utils';

const Articles = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const parsedSearch = useMemo(() => queryString.parse(location.search), [location]);

  const [reloadList, setReloadList] = useState(false);
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [categories, setCategories] = useState<IArticleCategory[]>([]);
  const [pagination, setPagination] = useState<IPagination>(() => {
    return {
      ...defaultPagination,
      current: parsedSearch?.page ? Number(parsedSearch?.page) : defaultPagination.current,
    };
  });
  const [filter, setFilter] = useState(() => ({
    name: (parsedSearch?.name as string) || '',
    categoryId: (parsedSearch?.categoryId as string) || '',
  }));

  const groupCategoriesByParent = useMemo(
    () => groupArticleCategoriesByParent(categories),
    [categories],
  );

  const getArticlesApiParams = useMemo(
    () =>
      queryString.stringify(
        {
          page: pagination.current,
          name: filter.name,
          categoryId: filter.categoryId,
        },
        { skipEmptyString: true },
      ),
    [pagination, filter],
  );

  const assignFilterValueToURL = useCallback((): void => {
    history.push(
      queryString.stringifyUrl({
        url: location.pathname,
        query: {
          page: pagination.current,
          name: filter.name,
          categoryId: filter.categoryId,
        },
      }),
    );
  }, [pagination, filter]);

  useEffect(() => {
    Promise.all([
      getPageData(`${API_GET_POST_ARTICLES}?${getArticlesApiParams}`, dispatch),
      api.get({ endpoint: API_GET_ARTICLE_CATEGORIES }),
    ])
      .then(([resArticles, resCategories]) => {
        const pagin = resArticles?.meta?.pagination;
        setPagination({
          current: pagin?.currentPage,
          pageSize: pagin?.perPage,
          total: pagin?.total,
        });
        setArticles(resArticles?.data);
        setCategories(resCategories?.data);
        assignFilterValueToURL();
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, []);

  useEffect(() => {
    if (reloadList) {
      api
        .get({
          endpoint: `${API_GET_POST_ARTICLES}?${getArticlesApiParams}`,
        })
        .then((res) => {
          const pagin = res?.meta?.pagination;
          setPagination({
            current: pagin?.currentPage,
            pageSize: pagin?.perPage,
            total: pagin?.total,
          });
          setArticles(res?.data);
          setReloadList(false);
          assignFilterValueToURL();
        })
        .catch((err) => {
          showErrorMsg(err);
        });
    }
  }, [reloadList, pagination, filter]);

  const handleChangePage = useCallback(
    (page: number) => {
      setPagination({ ...pagination, current: page });
      setReloadList(true);
    },
    [pagination],
  );

  const handleDeleteArticle = useCallback(
    (articleId: string) => () => {
      deleteModal({
        title: 'Bạn có muốn xóa bài này?',
        onDeleteCb: async () => {
          try {
            await api.remove({ endpoint: API_UPDATE_DELETE_ARTICLE.replace('{id}', articleId) });
            setReloadList(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const handleActiveArticle = useCallback(
    (article: IArticle) => async (checked: boolean) => {
      const currentArticle = {
        name: article?.attributes?.name,
        slug: article?.attributes?.slug,
        active: checked,
      };
      try {
        await api.put({
          endpoint: API_UPDATE_DELETE_ARTICLE.replace('{id}', article.id),
          data: currentArticle,
        });
        setReloadList(true);
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [],
  );

  const handleChangeFeatured = useCallback(
    (article: IArticle) => async (checked: boolean) => {
      const currentArticle = {
        name: article?.attributes?.name,
        slug: article?.attributes?.slug,
        isFeatured: checked,
      };
      try {
        await api.put({
          endpoint: API_UPDATE_DELETE_ARTICLE.replace('{id}', article.id),
          data: currentArticle,
        });
        setReloadList(true);
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [],
  );

  const onChangeValueFilter = useCallback((_, values) => {
    setFilter({
      name: values?.name || '',
      categoryId: values?.categoryId || '',
    });
    setPagination({ ...pagination, current: 1 });
    form.setFieldsValue(values);
  }, []);

  const handleFilter = () => {
    setReloadList(true);
  };

  const handleClear = () => {
    setFilter({ name: '', categoryId: '' });
    form.setFieldsValue({ name: '', categoryId: '' });
    setPagination({ ...pagination, current: 1 });
    setReloadList(true);
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tiêu đề bài viết',
      dataIndex: 'attributes',
      render: (attr: IArticleAttribute, row: IArticle) => (
        <NavLink
          to={{
            pathname: `/bai-viet/${row.id}`,
          }}
        >
          {attr.name}
        </NavLink>
      ),
    },
    {
      title: 'Chuyên mục chính',
      dataIndex: 'attributes',
      render: (attr: IArticleAttribute) => {
        const category = categories?.find((item) => parseInt(item.id) === attr?.categoryId);
        return category?.attributes?.name;
      },
    },
    {
      title: 'Featured Article',
      dataIndex: 'attributes',
      render: (attr: IArticleAttribute, row: IArticle) => (
        <Switch checked={attr.isFeatured} onChange={handleChangeFeatured(row)} />
      ),
    },
    {
      title: 'Hiển thị',
      dataIndex: 'attributes',
      render: (attr: IArticleAttribute, row: IArticle) => (
        <Switch checked={attr.active} onChange={handleActiveArticle(row)} />
      ),
    },
    {
      title: 'Thao tác',
      render: (row: IArticle) => (
        <Button title="Xóa" icon={<DeleteOutlined />} onClick={handleDeleteArticle(row.id)} />
      ),
    },
  ];

  return (
    <div>
      <LayoutList
        pageTitle="Bài viết"
        dataList={articles}
        breadcrumbs={[
          {
            title: 'Bài viết',
            route: '/bai-viet',
          },
        ]}
        handleClickAddButton={() => history.push('/soan-bai-viet')}
        showAddButton
        columns={columns}
        tableClass="product-table"
        topPagination
        pagination={pagination}
        loadingList={reloadList}
        handleChangePage={handleChangePage}
        filterNode={
          <Form
            form={form}
            layout="vertical"
            onValuesChange={onChangeValueFilter}
            onFinish={handleFilter}
          >
            <Row gutter={26}>
              <Col span={18}>
                <Form.Item label="Tiêu đề" name="name" initialValue={filter.name}>
                  <Input placeholder="Tiêu đề" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Chuyên mục"
                  name="categoryId"
                  initialValue={filter.categoryId ? Number(filter.categoryId) : null}
                >
                  <TreeSelect
                    size="large"
                    placeholder="Lọc theo chuyên mục"
                    allowClear
                    treeDefaultExpandAll
                    treeData={groupCategoriesByParent}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                className="align-items-center justify-content-end mt-4"
                style={{ display: 'flex' }}
              >
                <Form.Item>
                  <Button htmlType="submit" className="secondary-button">
                    Filter
                  </Button>
                </Form.Item>
                <Form.Item className="ml-2">
                  <Button onClick={handleClear}>Clear</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
    </div>
  );
};

export default Articles;
