/* eslint-disable react/jsx-no-bind */
import React from 'react';
import classNameUtil from 'utils/classNames';
import ColorUtils from 'utils/ColorUtils';
import ColorCircle from './Circle';

import './PickerField.scss';

interface PickerFieldProps {
  savedColor: string;
  secondColor?: string;
  onOpenPicker: (color: string) => void;
  onOpenSecond: (color: string) => void;
  className?: string;
}

const getContrast = (hexColor: string) => {
  const rgb = ColorUtils.hexToRgb(hexColor);
  if (rgb) {
    const [r, g, b] = ColorUtils.hexToRgb(hexColor);
    return ColorUtils.checkContrastColor(r, g, b);
  }
  return '#ffffff';
};

const PickerField: React.FC<PickerFieldProps> = ({
  savedColor,
  secondColor,
  onOpenPicker,
  onOpenSecond,
  className = '',
}) => {
  const handleOpenPicker = () => onOpenPicker(savedColor);

  const handleOpenSecond = () => onOpenSecond(secondColor);

  const value = secondColor ? `${savedColor} - ${secondColor}` : savedColor;

  const fieldClasses = classNameUtil({
    'color-field': true,
    gradient: !!secondColor,
  });

  return (
    <div className={`${fieldClasses} ${className}`}>
      <ColorCircle
        color={savedColor}
        onClick={handleOpenPicker}
        contrastColor={getContrast(savedColor)}
      />

      {secondColor && (
        <ColorCircle
          color={secondColor}
          onClick={handleOpenSecond}
          contrastColor={getContrast(secondColor)}
        />
      )}

      <div className="selected-color-value">{value}</div>
    </div>
  );
};

export default PickerField;
