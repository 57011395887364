export const renderColorStatus = (statusId: number) => {
  switch (statusId) {
    case 1:
      return 'green';
    case 2:
      return 'blue';
    default:
      return '';
  }
};
