import { Tooltip } from 'antd';
import ButtonDownload from 'components/ButtonDownload';
import { Order } from 'interfaces/order.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import Sort from 'pages/Members/Details/components/Sort';
import { useFetchMemberOrders } from 'pages/Members/Details/Orders/hook';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NumericFormat from 'react-number-format';
import { NavLink } from 'react-router-dom';
import { formatPrice } from 'utils/productHelpers';
import { genPaymentStatus, genStatusOrder } from 'utils/utils';
import { API_DOWNLOAD_ORDERS } from 'constants/api/member.api';
import './index.styles.scss';
import { SexType, directionList } from 'constants/enum';
import { FilterOrders, FilterOrdersPage } from './components/Filter';

const OrdersPage: React.FC = () => {
  const params = queryString.parse(location.search);
  // Hook get orders reviews
  const { isFetching, data, pagination, setPagination, fetchMemberOrders, totalItems } =
    useFetchMemberOrders();

  const [sortOrder, setSortOrder] = useState(directionList[0].value);

  // Download orders
  const fileName = `orders-${moment().format('DD-MM-YYYY')}.zip`;
  const urlDownload = useMemo(() => {
    return API_DOWNLOAD_ORDERS + `?${queryString.stringify({ ...params })}`;
  }, [params]);

  useEffect(() => {
    if (!isEmpty(params)) {
      const { sortBy, direction } = params || {};

      setSortOrder((sortBy || direction) as string);
    }
  }, [params]);

  /**
   * Handle the member of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchMemberOrders({ page });
    },
    [fetchMemberOrders],
  );

  /**
   * Handle the event of changing the filter for members page.
   *
   * @param {FilterMembersPage} filter - The new filter options for members page.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onFilterChange = useCallback(
    async (filter: FilterOrdersPage): Promise<void> => {
      await fetchMemberOrders({ page: 1, ...filter });
    },
    [fetchMemberOrders],
  );

  /**
   * Handle the event of changing the sort for orders page.
   */
  const onSortChange = useCallback(
    async (sort: string) => {
      setSortOrder(sort);

      let _direction = directionList[0].value;
      let _column = 'id';

      // set sort
      if (sort === 'desc' || sort === 'highPrice') {
        _direction = directionList[0].value;
      } else {
        _direction = directionList[1].value;
      }

      // set column
      if (sort === 'highPrice' || sort === 'lowPrice') {
        _column = 'total';
      } else {
        _column = 'id';
      }

      await fetchMemberOrders({ page: 1, direction: _direction, column: _column, sortBy: sort });
    },
    [fetchMemberOrders],
  );

  // column table
  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Thông tin',
      render: (order: Order) => {
        const { items, customer, customerName, customerPhone, customerGender } =
          order?.attributes || {};
        // TODO: Find order has coupon
        const counpon = useMemo(() => {
          return items?.find((item) => item?.couponCode.length > 0);
        }, items);

        return (
          <div className="order-info">
            <NavLink
              className="d-block"
              target="_blank"
              to={{
                pathname: `/danh-sach-don-hang/${order?.id}`,
                search: queryString.stringify({ ...params }),
              }}
            >
              <span className="lbl">Mã đơn:</span> <b className="num">{order?.id || '-'}</b>
            </NavLink>
            <NavLink
              className={`order-info__name d-block ${
                isEmpty(customer) || !customer?.uuid ? 'locked' : ''
              }`}
              target="_blank"
              to={{
                pathname: `/danh-sach-members/${customer?.uuid}`,
              }}
            >
              <span className="lbl">Tên khách hàng:</span>{' '}
              <b className="name pl-1">
                {SexType[customerGender]} {customer?.name || customerName}
              </b>
            </NavLink>
            <div>
              <span className="lbl">Số điện thoại:</span> {customerPhone || '-'}
            </div>
            {!isEmpty(counpon) && <span className="order-coupon">(Có COUPON)</span>}
          </div>
        );
      },
    },
    {
      title: 'Giá trị',
      dataIndex: '',
      render: (order: Order) => (
        <div className="order-price">{formatPrice(order?.attributes?.total?.toString())}</div>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: '',
      render: (order: Order) => {
        const { createdAt } = order?.attributes || {};
        const orderDate = useMemo(() => {
          return moment(createdAt).format('DD/MM/YYYY HH:mm');
        }, [createdAt]);

        return <div>{orderDate}</div>;
      },
    },
    {
      title: 'Tình trạng',
      dataIndex: '',
      render: (order: Order) => {
        const { status, paymentMethod: _paymentMethod } = order?.attributes || {};
        const paymentMethod = useMemo(() => {
          return genPaymentStatus(_paymentMethod);
        }, [_paymentMethod]);

        return (
          <div>
            <span className={`status ${genStatusOrder(status, 'value')}`}>
              {genStatusOrder(status)}
            </span>
            <div>
              <span className="lbl">Hình thức thanh toán</span>: <br /> <b>{paymentMethod}</b>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Xuất hoá đơn công ty',
      dataIndex: '',
      render: (order: Order) => {
        const { additional } = order?.attributes || {};
        const { companyInvoice = {} } = useMemo(() => {
          return !isEmpty(additional) ? additional[0] : {};
        }, [additional]);

        return !isEmpty(companyInvoice) ? (
          <Tooltip title={'Có xuất hoá đơn'} placement="top">
            <i className="icofont-check-circled vat-icon"></i>
          </Tooltip>
        ) : (
          <Tooltip title={'Không xuất hoá đơn'} placement="top">
            <i className="icofont-not-allowed vat-icon"></i>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div className="orders-page">
      <LayoutList
        pageTitle="Danh sách đơn hàng"
        breadcrumbs={[
          {
            title: 'Danh sách đơn hàng',
            route: '/danh-sach-don-hang',
          },
        ]}
        showAddButton={false}
        loadingList={isFetching}
        columns={columns}
        dataList={data}
        tableClass="order-table"
        pagination={pagination}
        handleChangePage={onPageChange}
        topPagination
        filterNode={<FilterOrders onFilter={onFilterChange} />}
        rightToolbarComponent={
          <div className="total-orders">
            <div className="total">
              Tổng số đơn hàng:{' '}
              <NumericFormat
                value={totalItems}
                allowLeadingZeros
                thousandSeparator=","
                displayType="text"
              />
            </div>
            <ButtonDownload fileName={fileName} urlDownload={urlDownload} />
          </div>
        }
        sortToolbarComponent={
          <div className="order-sort ">
            <Sort
              sortList={[
                ...directionList,
                { value: 'highPrice', label: 'Giá trị cao nhất' },
                { value: 'lowPrice', label: 'Giá trị thất nhất' },
              ]}
              defaultValue={sortOrder}
              onChange={onSortChange}
            />
          </div>
        }
      />
    </div>
  );
};

export default OrdersPage;
