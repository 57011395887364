import { Tree } from 'antd';
import { Key } from 'antd/lib/table/interface';
import { isEmpty, clone } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

interface TreeViewInterface {
  treeData: any[];
  handleSelectLeaf: (node) => void;
  maxDropLevel?: number;
  selectedKeys?: Key[];
  handleSelecteKeys?: (keys: Key[]) => void;
  handleDropContent?: (
    parentId: number,
    dragNode: any,
    nodePosition?: number,
    dropPosition?: number,
  ) => void;
}

const loop = (data: any, key: string, callback: (obj, i: number, d) => void) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].key === key) {
      return callback(data[i], i, data);
    }
    if (data[i].children) {
      loop(data[i].children, key, callback);
    }
  }
};

export const TreeView: React.FC<TreeViewInterface> = ({
  treeData,
  handleSelectLeaf,
  maxDropLevel = 3,
  selectedKeys = [],
  handleSelecteKeys = null,
  handleDropContent = null,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(treeData);
  }, [treeData]);

  const handleDrop = useCallback(
    (info) => {
      const {
        node: { key: dropKey, pos: dropPos, dragOver },
        dragNode: { key: dragKey },
        dropToGap,
        dropPosition,
      } = info;

      const nodeDropPos = dropPos.split('-');
      const itemPosition = dropPosition - Number(nodeDropPos[nodeDropPos.length - 1]);
      const conditionDragDropLevel = nodeDropPos.length <= maxDropLevel || !dragOver;

      const treeD = clone(data);

      // Find drag obj
      let dragObj;
      loop(treeD, dragKey, (item, index, arr) => {
        if (conditionDragDropLevel) {
          arr.splice(index, 1);
        }
        dragObj = item;
      });

      if (!dropToGap) {
        // Drop on the content
        if (conditionDragDropLevel) {
          handleDropContent && handleDropContent(parseInt(dropKey), dragObj, itemPosition);
          loop(treeD, dropKey, (item) => {
            item.children = item.children || [];
            item.children?.unshift(dragObj);
          });
        }
        setData(treeD);
      } else {
        let ar: any;
        let i: number;
        loop(treeD, dropKey, (_, index, arr) => {
          ar = arr;
          i = index;
        });
        handleDropContent &&
          handleDropContent(ar[0]?.parentId, dragObj, itemPosition, dropPosition);

        if (itemPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      setData(treeD);
    },
    [data, handleDropContent],
  );

  const handleClickChild = useCallback(
    (keys: Key[], { node }) => {
      handleSelectLeaf(node);
      handleSelecteKeys && handleSelecteKeys(keys);
    },
    [handleSelectLeaf, handleSelecteKeys],
  );

  return !isEmpty(data) ? (
    <Tree
      showLine
      defaultExpandAll
      draggable
      blockNode
      onDrop={handleDrop}
      treeData={data}
      onSelect={handleClickChild}
      selectedKeys={selectedKeys}
    />
  ) : (
    <></>
  );
};
