import { IColorAttribute } from 'interfaces/system-color.interface';

export class SystemColorModel implements IColorAttribute {
  name?: string;

  position?: number;

  constructor({ name, position }: IColorAttribute) {
    this.name = name || '';
    this.position = position || 0;
  }
}
