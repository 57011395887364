import { Row, Col } from 'antd';
import { IHomeBlock } from 'interfaces/home-block.interface';

import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { showErrorMsg } from 'utils/utils';
import {
  CouponPresentPageTemplates,
  RiderCouponPresentPageTemplates,
  StoreIds,
} from 'constants/enum';

import './styles.scss';
import { DetailBlockModal } from './components/DetailBlockModal';
import { useCreateBlock, useFetchCouponPresent, useUpdateBlock } from './hooks';

const CouponPresent = () => {
  const { isFetching: isFetchingBlocks, blocks, fetch: fetchBlocks } = useFetchCouponPresent();
  const { createBlock } = useCreateBlock();
  const { updateBlock } = useUpdateBlock();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [blockSelected, setBlockSelected] = useState<IHomeBlock | null>(null);

  const location = useLocation();
  const storeId = location.pathname.includes('rider') ? StoreIds.rider : StoreIds.mainguyen;

  // automatically set blockSelected to the latest value every time the blocks changes
  useEffect(() => {
    if (blocks?.length && blockSelected) {
      setBlockSelected(
        blocks?.find((b) => b.attributes.template === blockSelected.attributes.template),
      );
    }
  }, [blocks]);

  const getDefaultBlockData = useCallback(
    (blockTemplate: CouponPresentPageTemplates | RiderCouponPresentPageTemplates): IHomeBlock => {
      return {
        id: '',
        type: '',
        attributes: {
          template: blockTemplate,
          name: blockTemplate,
          position: blockTemplate,
          active: true,
          data: null,
        },
      };
    },
    [],
  );

  const onOpenModal = useCallback(
    (blockTemplate: CouponPresentPageTemplates | RiderCouponPresentPageTemplates): void => {
      const _blockSelected =
        blocks?.find((b) => b.attributes.template === blockTemplate) ||
        getDefaultBlockData(blockTemplate);
      setBlockSelected(_blockSelected);
      setIsOpenModal(true);
    },
    [blocks],
  );

  const onCloseModal = useCallback((): void => {
    setBlockSelected(null);
    setIsOpenModal(false);
  }, []);

  const onSaveBlock = useCallback(async (): Promise<void> => {
    const callbackSuccess = async () => {
      await fetchBlocks();
      toast.success('Lưu thành công!');
    };

    try {
      setIsSaving(true);
      if (blockSelected.id) {
        await updateBlock(blockSelected.id, blockSelected.attributes, callbackSuccess);
      } else {
        await createBlock(blockSelected.attributes, callbackSuccess);
      }
    } catch (error: any) {
      showErrorMsg(error?.message || error);
    } finally {
      setIsSaving(false);
    }
  }, [blockSelected, createBlock, updateBlock, fetchBlocks]);

  return (
    <>
      <LayoutDetail
        pageTitle="Trình bày trang Coupon"
        breadcrumbs={[
          {
            title: 'Trình bày trang Coupon',
            route: '/trinh-bay-trang-coupon',
          },
        ]}
        isAddNew
        wrapperCard={false}
        handleValuesChange={null}
        formHook={null}
        pageClassName="coupon-present-page"
        saveLoading={isFetchingBlocks}
      >
        <Row gutter={16}>
          <Col span={24}>
            <div
              className="block-item seo"
              onClick={() =>
                onOpenModal(
                  storeId === StoreIds.mainguyen
                    ? CouponPresentPageTemplates.seo
                    : RiderCouponPresentPageTemplates.seo,
                )
              }
            >
              SEO
            </div>
          </Col>
        </Row>
        <div className="main-content">
          <Row gutter={16} align="stretch">
            <Col span={24}>
              <div
                className="block-item main-slider-block"
                onClick={() =>
                  onOpenModal(
                    storeId === StoreIds.mainguyen
                      ? CouponPresentPageTemplates.mainSlider
                      : RiderCouponPresentPageTemplates.mainSlider,
                  )
                }
              >
                <div className="shock-price-title">Main Slider</div>
              </div>
            </Col>

            <Col span={24}>
              <div
                className="block-item main-slider-block"
                onClick={() =>
                  onOpenModal(
                    storeId === StoreIds.mainguyen
                      ? CouponPresentPageTemplates.coupons
                      : RiderCouponPresentPageTemplates.coupons,
                  )
                }
              >
                <div className="shock-price-title">Danh sách coupon</div>
              </div>
            </Col>
          </Row>
        </div>
      </LayoutDetail>

      <DetailBlockModal
        isOpen={isOpenModal && !!blockSelected}
        isSaving={isSaving}
        onClose={onCloseModal}
        onSave={onSaveBlock}
        data={blockSelected}
        onAttributesChange={(newAttributes) =>
          setBlockSelected((prev) => ({ ...prev, attributes: newAttributes }))
        }
      />
    </>
  );
};

export default CouponPresent;
