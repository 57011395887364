import { Card } from 'antd';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loading } from 'ui/components/MaiNguyen/Loading';

export type LevelItem = {
  id: number;
  name: string;
  rank: {
    id: number;
    name: string;
  };
};

type MenuProps = {
  isLoading?: boolean;
  menuList?: LevelItem[];
  onChange?: (policy: LevelItem) => void;
};

const Menu: React.FC<MenuProps> = ({ menuList, isLoading, onChange }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [stdLevelId, setStdLevelId] = useState<number>(null);

  useEffect(() => {
    if (!isEmpty(params)) {
      setStdLevelId(Number(params?.levelId));
    }

    return () => {
      setStdLevelId(null);
    };
  }, [params]);

  // TODO: handle change policy
  const handleChange = useCallback(
    (level: LevelItem) => {
      setStdLevelId(level.id);

      if (onChange) {
        onChange(level);
      }
    },
    [onChange],
  );

  return (
    <Card className="relative">
      <Loading isLoading={isLoading} />

      <ul className="menu-policies">
        {menuList?.map((item, i) => (
          <li
            key={item.id}
            className={stdLevelId === item.id ? 'active' : ''}
            onClick={() => handleChange(item)}
          >
            <h3 className="title">
              {Number(i + 1)}. {item.name}
            </h3>
            <p className="rank">{item.rank && item.rank.id ? `Hạng ${item.rank.name}` : ''}</p>
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default Menu;
