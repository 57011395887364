import { getReviewLabelStatus } from 'pages/Members/utils';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { ReviewStatus } from 'constants/enum';
import '../index.styles.scss';

interface FilterStatusProps {
  onChange: (mode: ReviewStatus) => void;
  className?: string;
}

const Tab: React.FC<{
  isActive: boolean;
  onClick: () => void;
  children: React.ReactNode;
  className: string;
}> = (props) => (
  <span
    className={`status ${props.className} ${props.isActive ? 'active' : ''}`}
    onClick={props.onClick}
  >
    {props.children}
  </span>
);

const FilterStatus: React.FC<FilterStatusProps> = ({ onChange, className }) => {
  const [viewMode, setViewMode] = useState<ReviewStatus>(ReviewStatus.pending);

  const params = queryString.parse(location.search);
  useEffect(() => {
    if (
      params?.status &&
      [ReviewStatus.pending, ReviewStatus.published, ReviewStatus.locked].includes(
        params.status as ReviewStatus,
      )
    ) {
      setViewMode(params.status as ReviewStatus);
    }
  }, [params]);

  const onSetViewMode = useCallback(async (mode: ReviewStatus) => {
    setViewMode(mode);
    onChange(mode);
  }, []);

  return (
    <div className={`review-page__tabs d-flex align-items-center ${className}`}>
      <Tab
        className={ReviewStatus.pending}
        isActive={viewMode === ReviewStatus.pending}
        onClick={() => onSetViewMode(ReviewStatus.pending)}
      >
        {getReviewLabelStatus(ReviewStatus.pending)}
      </Tab>
      <span className="symb">|</span>
      <Tab
        className={ReviewStatus.published}
        isActive={viewMode === ReviewStatus.published}
        onClick={() => onSetViewMode(ReviewStatus.published)}
      >
        {getReviewLabelStatus(ReviewStatus.published)}
      </Tab>
      <span className="symb">|</span>
      <Tab
        className={ReviewStatus.locked}
        isActive={viewMode === ReviewStatus.locked}
        onClick={() => onSetViewMode(ReviewStatus.locked)}
      >
        {getReviewLabelStatus(ReviewStatus.locked)}
      </Tab>
    </div>
  );
};

export default FilterStatus;
