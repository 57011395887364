/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select, Switch, message } from 'antd';
import arrowRightImg from 'assets/img/arrow-right.svg';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import moment from 'moment/moment';
import { UploadImage } from 'pages/CollectionDetail/components/UploadImage';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import './styles.scss';
import { setIsEditing } from 'redux/uiAction/actions';
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { confirmModal } from 'utils/modals';
import { calculateDaysDifference, downloadCsvFile, formatNumber } from 'utils/utils';
import { COUPON_STATUS_LIST, STORE_IDS } from 'constants/constants';
import { CouponRules, CouponStatus, DateFormat, DiscountUnit } from 'constants/enum';
import { CouponListModal } from './components/CouponListModal';
import { OrderHistory } from './components/OrderHistory';
import { ProductList } from './components/ProductList';
import { useFetchCoupon, useDownloadCoupons, useUpdateCoupon } from './hooks';
import isEmpty from 'lodash/isEmpty';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const CouponDetail: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id: couponId } = useParams<{ id: string }>();

  const { isFetching, data, refetch: refetchCoupon } = useFetchCoupon(couponId);
  const { isUpdating, onUpdate } = useUpdateCoupon();

  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [isShowOrderHistory, setIsShowOrderHistory] = useState<boolean>(false);
  const [isShowCouponListModal, setIsShowCouponListModal] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({});

  const totalCouponAvailable = data?.attributes?.countActive;
  const createdAt = moment(data?.attributes?.createdAt, DateFormat.serverSideWithTime).format(
    DateFormat.clientSideWithTime,
  );
  const updatedAt = moment(data?.attributes?.updatedAt, DateFormat.serverSideWithTime).format(
    DateFormat.clientSideWithTime,
  );
  const remainingDays = data?.attributes?.endDate
    ? calculateDaysDifference(data?.attributes?.endDate, DateFormat.serverSideWithTime)
    : null;
  const isCouponExpired = data?.attributes?.endDate
    ? moment(data?.attributes?.endDate, DateFormat.serverSideWithTime).isBefore(moment())
    : false;
  const statusLabel = COUPON_STATUS_LIST.find(
    (c) => c.value === (isCouponExpired ? CouponStatus.expired : data?.attributes?.status),
  )?.label;

  useEffect(() => {
    if (data?.id && queryString.parse(location.search)?.view === 'couponUsedHistory') {
      setIsShowOrderHistory(true);
    }
  }, [data, location.search]);

  /**
   * A hook that sets the form fields values based on the data attributes when the data ID changes.
   *
   * @param {Object} data - The data object containing the attributes.
   */
  useEffect(() => {
    if (data?.id) {
      const { startDate, endDate, storeId, ...rest } = data.attributes || {};
      const _initialValues = {
        ...rest,
        storeId: Number(storeId),
        dateRange: [
          startDate ? moment(startDate, DateFormat.serverSideWithTime) : null,
          endDate ? moment(endDate, DateFormat.serverSideWithTime) : null,
        ],
      };
      form.setFieldsValue(_initialValues);
      setInitialValues(_initialValues);
      dispatch(setIsEditing(false));
    }
  }, [data?.id]);

  /**
   * A callback function that saves a coupon by updating its data using the form values.
   *
   * @throws {Error} - An error that occurred during the coupon update.
   */
  const onSave = useCallback(async (): Promise<void> => {
    try {
      const values = form.getFieldsValue();
      const changedFields = Object.keys(values)
        .filter((fieldName) => values[fieldName] !== initialValues[fieldName])
        .reduce((prev, curr) => ({ ...prev, [curr]: values[curr] }), {} as any);

      const {
        dateRange,
        prefixCode: _prefixCode,
        hasOverridePromotion,
        ...rest
      } = changedFields || {};

      const startDate = dateRange?.[0]
        ? moment(dateRange[0], DateFormat.serverSideWithTime).format(DateFormat.clientSideWithTime)
        : null;

      const endDate = dateRange?.[1]
        ? moment(dateRange[1], DateFormat.serverSideWithTime).format(DateFormat.clientSideWithTime)
        : null;

      const prefixCode = _prefixCode ? _prefixCode.toUpperCase() : null;

      const newData = await onUpdate({
        method: 'put',
        id: couponId,
        data: queryString.parse(
          queryString.stringify(
            {
              ...rest,
              startDate,
              endDate,
              prefixCode: prefixCode?.toUpperCase(),
              hasOverridePromotion: Boolean(hasOverridePromotion),
            },
            { skipNull: true, skipEmptyString: true },
          ),
          { parseBooleans: true },
        ),
      });

      await refetchCoupon(newData, {
        revalidate: false,
        rollbackOnError: true,
      });
      message.success('Cập nhật coupon thành công');
    } catch (error: any) {
      if (error?.prefixCode?.length) {
        message.error('Prefix Code đã được sử dụng');
        return;
      }
      if (error?.startDate?.length) {
        message.error('Ngày bắt đầu phải sau hoặc bằng ngày hôm nay');
        return;
      }
      const msg = error?.message || error;
      message.error(msg);
    } finally {
      dispatch(setIsEditing(false));
    }
  }, [couponId, initialValues]);

  /**
   * A callback function that saves a coupon by updating its data using the form values.
   *
   * @throws {Error} - An error that occurred during the coupon update.
   */
  const onUpdateStatus = useCallback(
    async (status: CouponStatus): Promise<void> => {
      if (isUpdatingStatus) return;
      form.validateFields().then(async (values) => {
        try {
          setIsUpdatingStatus(true);

          const changedFields = Object.keys(values)
            .filter((fieldName) => values[fieldName] !== initialValues[fieldName])
            .reduce((prev, curr) => ({ ...prev, [curr]: values[curr] }), {} as any);

          const {
            dateRange,
            prefixCode: _prefixCode,
            hasOverridePromotion,
            ...rest
          } = changedFields || {};

          const startDate = dateRange?.[0]
            ? moment(dateRange[0], DateFormat.serverSideWithTime).format(
                DateFormat.clientSideWithTime,
              )
            : null;

          const endDate = dateRange?.[1]
            ? moment(dateRange[1], DateFormat.serverSideWithTime).format(
                DateFormat.clientSideWithTime,
              )
            : null;

          const prefixCode = _prefixCode ? _prefixCode.toUpperCase() : null;

          const newData = await onUpdate({
            method: 'put',
            id: couponId,
            data: queryString.parse(
              queryString.stringify(
                {
                  ...rest,
                  startDate,
                  endDate,
                  prefixCode: prefixCode?.toUpperCase(),
                  status,
                  hasOverridePromotion: Boolean(hasOverridePromotion),
                },
                { skipNull: true, skipEmptyString: true },
              ),
              { parseBooleans: true },
            ),
          });

          await refetchCoupon(newData, {
            revalidate: false,
            rollbackOnError: true,
          });
          message.success('Cập nhật coupon thành công');
        } catch (error: any) {
          if (error?.prefixCode?.length) {
            message.error('Prefix Code đã được sử dụng');
            return;
          }
          const msg = error?.message || error;
          message.error(msg);
        } finally {
          setIsUpdatingStatus(false);
          dispatch(setIsEditing(false));
        }
      });
    },
    [couponId, isUpdatingStatus, initialValues],
  );

  /**
   * Navigates to the coupons page with the provided query parameters.
   *
   * @returns {void}
   */
  const onGotoCoupons = useCallback((): void => {
    history.push(
      queryString.stringifyUrl(
        {
          url: '/ma-giam-gia',
          query: location.state as any,
        },
        { skipEmptyString: true, skipNull: true },
      ),
    );
  }, []);

  /**
   * Download Coupon
   */
  const [fetchDownload, setFetchDownload] = useState<boolean>(false);
  const { isFetching: isDownloading, data: dataDownload } = useDownloadCoupons(
    couponId,
    fetchDownload,
  );

  useEffect(() => {
    if (!isEmpty(dataDownload)) {
      downloadCsvFile({
        data: dataDownload,
        fileName: `cp_${couponId}.csv`,
        fileType: 'text/csv;charset=utf-8',
      });
      setFetchDownload(false);
    }
  }, [dataDownload]);

  const downloadCoupons = () => {
    setFetchDownload(true);
  };

  if (isFetching || !data) return null;

  return (
    <>
      <LayoutDetail
        pageTitle={`Coupon #${data?.id || ''}`}
        isAddNew={false}
        pageClassName="coupon-detail"
        breadcrumbs={[
          {
            title: 'Coupons',
            route: '/ma-giam-gia',
          },
          {
            title: 'Chi tiết',
            route: `/ma-giam-gia/${couponId}`,
          },
        ]}
        formHook={form}
        handleSave={async () => {
          const discountUnit = form.getFieldValue('discountUnit');
          const discount = form.getFieldValue('discount');

          if (
            discountUnit != data?.attributes?.discountUnit ||
            discount != data?.attributes?.discount
          ) {
            confirmModal({
              title: 'Chắc chắn là bạn muốn thay đổi giá trị giảm giá?',
              buttonText: 'Có',
              onSaveCb: async () => await onSave(),
            });
            return;
          }

          await onSave();
        }}
        saveLoading={isUpdating}
        wrapperCard={!isShowOrderHistory}
      >
        {isShowOrderHistory ? (
          <OrderHistory
            coupon={data}
            onBack={() => {
              setIsShowOrderHistory(false);
              history.push(
                queryString.stringifyUrl(
                  {
                    url: location.pathname,
                    query: {
                      ...queryString.parse(location.search),
                      view: null,
                    },
                  },
                  { skipEmptyString: true, skipNull: true },
                ),
              );
            }}
          />
        ) : (
          <div>
            <div className="coupon-btn-group">
              <Button className="detail-toolbar__cancel-btn" onClick={onGotoCoupons}>
                Cancel
              </Button>
              {data?.attributes?.status === CouponStatus.active && !isCouponExpired && (
                <Button
                  className={`detail-toolbar__download-btn ${isDownloading ? 'show-loading' : ''}`}
                  onClick={downloadCoupons}
                  loading={isDownloading}
                >
                  Download CSV
                </Button>
              )}
            </div>

            {data?.attributes?.status === CouponStatus.draft && !isCouponExpired && (
              <Button
                loading={isUpdatingStatus}
                className={`detail-toolbar__public-btn ${isUpdatingStatus ? 'show-loading' : ''}`}
                onClick={() => {
                  confirmModal({
                    title: 'Bảo đảm thông tin chính xác trước khi phát hành công khai?',
                    buttonText: 'Public',
                    onSaveCb: () => onUpdateStatus(CouponStatus.active),
                  });
                }}
              >
                Public
              </Button>
            )}

            {(data?.attributes?.status === CouponStatus.close || isCouponExpired) && (
              <Button
                loading={isUpdatingStatus}
                className={`detail-toolbar__draft-btn ${isUpdatingStatus ? 'show-loading' : ''}`}
                onClick={() => onUpdateStatus(CouponStatus.draft)}
              >
                Draft
              </Button>
            )}

            {data?.attributes?.status === CouponStatus.active && !isCouponExpired && (
              <Button
                loading={isUpdatingStatus}
                className={`detail-toolbar__close-btn ${isUpdatingStatus ? 'show-loading' : ''}`}
                onClick={() => {
                  confirmModal({
                    title: 'Bạn có chắc là muốn đóng coupon này?',
                    buttonText: 'Đóng',
                    onSaveCb: () => onUpdateStatus(CouponStatus.close),
                  });
                }}
              >
                Đóng Chương Trình
              </Button>
            )}

            <Collapse defaultActiveKey={['1', '2']}>
              <Panel header="Tracking Information" key="1">
                <div className="coupon-tracking-info">
                  <div className="coupon-general-info">
                    <div className="coupon-title-wrapper">
                      <span>Coupon: </span>
                      <span className="coupon-title">{data?.attributes?.title}</span>
                      <span className="coupon-discount">
                        {parseFloat(data?.attributes?.discount as any) > 0
                          ? `(-${
                              data?.attributes?.discountUnit === DiscountUnit.VND
                                ? formatNumber(data?.attributes?.discount)
                                : `${data?.attributes?.discount}%`
                            })`
                          : ''}
                      </span>
                    </div>

                    <div className="coupon-date-range">
                      <div>
                        <span>Tạo ngày: </span>
                        <span className="coupon-date-display">{createdAt}</span>
                      </div>
                      <div>
                        <span>Cập nhật ngày: </span>
                        <span className="coupon-date-display">{updatedAt}</span>
                      </div>
                    </div>
                  </div>
                  <div className="coupon-status">
                    <span>Tình trạng: </span>
                    <span
                      className={`coupon-status--${
                        isCouponExpired ? 'expired' : data?.attributes?.status
                      }`}
                    >
                      {statusLabel}
                    </span>
                  </div>
                  <div className="coupon-statistic">
                    <div>
                      <span>Đã có </span>
                      <span className="coupon-used">{totalCouponAvailable} code được dùng </span>
                      <span className="coupon-total">
                        / {data?.attributes?.numberOfCoupon} code.
                      </span>
                      <span
                        className="coupon-view-order-history"
                        onClick={() => {
                          setIsShowOrderHistory(true);
                          history.push(
                            queryString.stringifyUrl(
                              {
                                url: location.pathname,
                                query: {
                                  ...queryString.parse(location.search),
                                  view: 'couponUsedHistory',
                                },
                              },
                              { skipEmptyString: true, skipNull: true },
                            ),
                          );
                        }}
                      >
                        Xem lịch sử đơn hàng
                      </span>
                      {data?.attributes?.rule === CouponRules.auto && (
                        <span
                          className="view-coupons"
                          onClick={() => setIsShowCouponListModal(true)}
                        >
                          <img src={arrowRightImg} /> COUPON cần phát hành
                        </span>
                      )}
                    </div>
                    <div className="coupon-remaining-available-days">
                      {data?.attributes?.startDate && data?.attributes?.endDate
                        ? `Còn ${remainingDays} ngày`
                        : '-'}
                    </div>
                  </div>
                </div>
              </Panel>

              <Panel header="Coupon" key="2">
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item
                      label="Tên chương trình"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập tên chương trình',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      label="Tiêu đề trình bày ngoài Store"
                      name="displayTitle"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập tiêu đề trình bày',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      valuePropName="checked"
                      label="Ghi đè promotion"
                      name="hasOverridePromotion"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Prefix Code"
                      name="prefixCode"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập Prefix Code',
                        },
                      ]}
                    >
                      <Input
                        disabled={data?.attributes?.status === CouponStatus.active}
                        style={{ textTransform: 'uppercase' }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Số lượng phát hành"
                      name="numberOfCoupon"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập Prefix Code',
                        },
                      ]}
                    >
                      <Input disabled={data?.attributes?.status === CouponStatus.active} />
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item label="Kiểu giảm giá" name="discountUnit">
                      <Select
                        className="ant-select-lg"
                        options={[
                          { value: DiscountUnit.PERCENT, label: '%' },
                          { value: DiscountUnit.VND, label: 'VND' },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={9}>
                    <Form.Item
                      label="Giảm giá"
                      name="discount"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập giảm giá',
                        },
                        {
                          validator: (_, value) => {
                            const discountUnit = form.getFieldValue('discountUnit');
                            if (discountUnit === DiscountUnit.PERCENT) {
                              if (value && Number(value) > 100) {
                                return Promise.reject(
                                  'Phần trăm giảm giá phải nhỏ hơn hoặc bằng 100',
                                );
                              }
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Chọn cửa hàng" name="storeId">
                      <Select className="ant-select-lg" options={STORE_IDS} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Ngày bắt đầu - Ngày kết thúc"
                      name="dateRange"
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            const [start, end] = value;
                            const today = moment();

                            if (end && (end < start || end <= today)) {
                              return Promise.reject('Vui lòng chọn ngày kết thúc hợp lệ');
                            }

                            if (!start || !end) {
                              return Promise.reject('Vui lòng chọn ngày bắt đầu và ngày kết thúc');
                            }

                            return Promise.resolve();
                          },
                          validateTrigger: 'onChange',
                        },
                      ]}
                    >
                      <RangePicker
                        placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                        format={DateFormat.clientSideWithTime}
                        disabledDate={(current) => {
                          const today = moment().startOf('day');
                          return current && current < today;
                        }}
                        showTime={{
                          format: 'HH:mm:ss',
                          defaultValue: [
                            moment('00:00:00', 'HH:mm:ss'),
                            moment('23:59:59', 'HH:mm:ss'),
                          ] as any,
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Mô tả" name="desc" trigger="handleChangeMainContent">
                      <CustomEditor mainContent={data?.attributes?.desc} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="image"
                      valuePropName="initialImage"
                      trigger="onChange"
                      label="Logo"
                    >
                      <UploadImage
                        uploadInfo={{
                          type: 'coupon',
                          typeId: data?.id,
                        }}
                        uploadText="Click hoặc kéo thả hình để upload logo"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <ProductList coupon={data} />
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
        )}
      </LayoutDetail>

      <CouponListModal
        visible={isShowCouponListModal}
        onClose={() => setIsShowCouponListModal(false)}
        data={data?.attributes?.couponCodes?.filter((c) => c.active === 0)?.map((c) => c.code)}
      />
    </>
  );
};

export default CouponDetail;
