import { Button, Input } from 'antd';
import isEmpty from 'lodash/isEmpty';
import Sort from 'pages/Members/Details/components/Sort';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { directionList } from 'constants/enum';

export interface FilterCommentsPage {
  status: 0 | 1 | -1;
  url: string;
  direction?: string;
}

interface Props {
  onFilter: (filter: FilterCommentsPage) => void;
  totalNewComments: number;
}

export const Filter: React.FC<Props> = (props) => {
  const { onFilter, totalNewComments } = props;
  const { id: memberId } = useParams<{ id: string }>();

  const location = useLocation();
  const params = queryString.parse(location.search) as any;

  const [filter, setFilter] = useState<FilterCommentsPage>({ status: 0, url: '', direction: '' });

  useEffect(() => {
    if (!isEmpty(params)) {
      setFilter((prev) => ({
        ...prev,
        status: params?.status || 0,
        url: params?.url || '',
        direction: params?.direction || directionList[0].value,
      }));
    }
  }, [JSON.stringify(params)]);

  const onFilterChange = useCallback(
    (key: keyof FilterCommentsPage, value: any) => {
      const newFilter = { ...filter, [key]: value };
      setFilter(newFilter);
      onFilter(newFilter);
    },
    [filter, onFilter],
  );

  return (
    <div className="comments-tabs-input">
      <div className="comments-tabs-sort">
        {/* Status */}
        <div className="comments-tabs">
          <div
            className={`tab ${filter.status == 0 ? 'active' : ''}`}
            onClick={() => onFilterChange('status', 0)}
          >
            {`Mới ${!memberId ? `(${totalNewComments})` : ''}`}
          </div>
          <div
            className={`tab ${filter.status == 1 ? 'active' : ''}`}
            onClick={() => onFilterChange('status', 1)}
          >
            Đã Duyệt
          </div>
          <div
            className={`tab ${filter.status == -1 ? 'active' : ''}`}
            onClick={() => onFilterChange('status', -1)}
          >
            Từ Chối
          </div>
        </div>
        {/* Sort */}
        {memberId && (
          <div className="sort-input">
            <Sort
              sortList={directionList}
              defaultValue={filter.direction}
              onChange={(e) => onFilterChange('direction', e)}
            />
          </div>
        )}
      </div>
      {/* Urls */}
      <div className="search-input">
        <Input
          className="mr-3 input"
          placeholder="Chép URL vào đây để tìm bình luận"
          value={filter.url}
          onChange={(e) => setFilter((prev) => ({ ...prev, url: e.target.value }))}
        />
        <Button
          className="mr-3"
          disabled={!filter.url}
          onClick={() => onFilterChange('url', filter.url)}
        >
          Tìm
        </Button>
        <Button disabled={!filter.url} onClick={() => onFilterChange('url', '')}>
          Clear URL
        </Button>
      </div>
    </div>
  );
};
