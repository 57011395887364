import * as api from 'services/api.service';

export const fetcher = async (endpoint: string) => {
  const res = await api.get({ endpoint });
  return res?.data ? res?.data : res;
};

export const fetcherWithArg = async (endpoint: string, { arg }) => {
  const res = await api.get({ endpoint, params: arg });
  return res;
};

export const poster = async (endpoint: string, { arg }: { arg: { data: any } }) => {
  const res = await api.post({ endpoint, data: arg.data });
  return res?.data;
};

export const updater = async (
  endpoint: string,
  { arg }: { arg: { method: 'put' | 'remove'; id: string; data?: any } },
) => {
  const res = await api[arg.method](
    arg?.data
      ? {
          endpoint: endpoint.replace('{id}', arg.id),
          data: arg.data,
        }
      : { endpoint: endpoint.replace('{id}', arg.id) },
  );
  return res;
};
