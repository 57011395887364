import { IAttribute } from 'interfaces/attribute.interface';
import { SET_SHIPPING_FIELD, RESET_SHIPPING_FIELDS, ActionShippingType } from './types';

type InitialStateType = {
  shippingFields: IAttribute[];
  loading: boolean;
};

const initStates: InitialStateType = {
  shippingFields: [],
  loading: true,
};

export const shippingFieldReducer = (state = initStates, action: ActionShippingType) => {
  switch (action.type) {
    case SET_SHIPPING_FIELD:
      return {
        shippingFields: action.payload,
        loading: false,
      };
    case RESET_SHIPPING_FIELDS:
      return initStates;
    default:
      return state;
  }
};
