import { Button } from 'antd';
import React, { memo } from 'react';

import ThemeButton from 'ui/components/ThemeButton/ThemeButton';

import './PageTitle.scss';

interface PageTitleInterface {
  title: string;
  showButton?: boolean;
  showSaveButton?: boolean;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  handleCancel?: () => void;
  loading?: boolean;
}

export const PageTitle: React.FC<PageTitleInterface> = memo(
  ({
    title,
    buttonText = '',
    showButton = true,
    showSaveButton = true,
    buttonIcon = '',
    onClick = null,
    loading = false,
    handleCancel = null,
  }) => {
    return (
      <div className="page-title">
        <div>{title}</div>
        {showButton && (
          <div className="btn">
            {showSaveButton ? (
              <ThemeButton loading={loading} onClick={onClick}>
                Lưu
              </ThemeButton>
            ) : (
              <Button icon={buttonIcon} onClick={onClick}>
                {buttonText}
              </Button>
            )}
            &nbsp; &nbsp;
            {handleCancel && <Button onClick={handleCancel}>Cancel</Button>}
          </div>
        )}
      </div>
    );
  },
);
