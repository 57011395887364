import { FilterFilled } from '@ant-design/icons';
import { Button, Card, Divider, Empty, Pagination, Spin, message } from 'antd';
import { IFeedback } from 'interfaces/feedback.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';

import './index.styles.scss';
import { useLocation } from 'react-router-dom';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { DateFormat, FeedbackStatus } from 'constants/enum';
import { FilterFeedbackPage, FilterView } from './components/FilterView';
import { useFetchFeedbackList, usePostFeedbackSolved } from './hooks';

const Tab: React.FC<{ isActive: boolean; onClick: () => void; children: React.ReactNode }> = (
  props,
) =>
  props.isActive ? (
    <ThemeButton onClick={props.onClick}>{props.children}</ThemeButton>
  ) : (
    <Button onClick={props.onClick}>{props.children}</Button>
  );

const FeedbackPage: React.FC = () => {
  const location = useLocation();

  const { onPostSolved } = usePostFeedbackSolved();
  const { isFetching, data, pagination, setPagination, fetchFeedbackList } = useFetchFeedbackList();

  const [viewMode, setViewMode] = useState<FeedbackStatus>(FeedbackStatus.new);
  const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
  const [idLoading, setIdLoading] = useState<string | null>(null);

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (
      params?.status &&
      [FeedbackStatus.new, FeedbackStatus.solved].includes(params.status as FeedbackStatus)
    ) {
      setViewMode(params.status as FeedbackStatus);
    }
  }, []);

  /**
   * Handle the feedback list of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchFeedbackList({ page });
    },
    [fetchFeedbackList],
  );

  /**
   * Handle the event of changing the filter for feedback page.
   *
   * @param {FilterFeedbackPage} filter - The new filter options for feedback page.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onFilterChange = useCallback(
    async (filter: FilterFeedbackPage): Promise<void> => {
      await fetchFeedbackList({ page: 1, ...filter });
    },
    [fetchFeedbackList],
  );

  const onSetViewMode = useCallback(
    async (mode: FeedbackStatus) => {
      setViewMode(mode);
      await fetchFeedbackList({ page: 1, status: mode });
    },
    [fetchFeedbackList],
  );

  return (
    <LayoutDetail
      pageTitle="Danh sách góp ý"
      breadcrumbs={[
        {
          title: 'Danh sách góp ý',
          route: '/danh-sach-gop-y',
        },
      ]}
      pageClassName="feedback-page"
      isAddNew={true}
      formHook={null}
      handleValuesChange={null}
      handleSave={null}
      handleCancel={null}
      handleClickAddButton={null}
      handleDelete={null}
      wrapperCard={false}
    >
      <div>
        <div className="feedback-page__filter">
          <div className="mb-4">
            <Button
              className="filter-button"
              title="Filter"
              icon={<FilterFilled rev={null} />}
              onClick={() => setOpenFilter((prev) => !prev)}
            >
              Filter
            </Button>
          </div>
          <Card hidden={!isOpenFilter}>
            <FilterView onFilter={onFilterChange} />
          </Card>
        </div>

        <Divider />

        <div className="feedback-page__tabs">
          <Tab
            isActive={viewMode === FeedbackStatus.new}
            onClick={() => onSetViewMode(FeedbackStatus.new)}
          >
            Mới
          </Tab>
          <Tab
            isActive={viewMode === FeedbackStatus.solved}
            onClick={() => onSetViewMode(FeedbackStatus.solved)}
          >
            Đã xem
          </Tab>
        </div>

        {!!data?.length && (
          <div className="mt-2 mb-3 text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}

        <div className="feedback-list">
          <div
            className={`feedback-list__loading ${isFetching ? 'feedback-list__loading--show' : ''}`}
          >
            <Spin />
          </div>
          {data?.length ? (
            data.map((o: IFeedback) => {
              const { content, url, createdAt, customer, status, images, admin } = o.attributes;
              const { name, phone, email } = customer || {};

              const fromVal = `${name} ${
                email && !email.includes('@mainguyen.vn') ? `(Email: ${email})` : ''
              } (Phone: ${phone})`;

              return (
                <Card key={o.id}>
                  <div className="feedback-item">
                    <div className="feedback-item__main-content">
                      <div className="feedback-item__from">
                        <span className="feedback-item__from-label">Từ:</span>
                        <span className="feedback-item__from-info">{fromVal}</span>
                      </div>
                      <div className="feedback-item__createdAt">
                        <span className="feedback-item__createdAt-label">Ngày gửi:</span>
                        <span className="feedback-item__createdAt-info">
                          {moment(createdAt).format(DateFormat.clientSideWithTime)}
                        </span>
                      </div>
                      <div className="feedback-item__content">{content}</div>
                      {url && (
                        <div className="feedback-item__url">
                          <span className="feedback-item__url-label">Ref link:</span>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={url}
                            className="feedback-item__url-link"
                          >
                            {url}
                          </a>
                        </div>
                      )}
                      {!!images?.length && (
                        <div className="feedback-item__images">
                          {images.map((image) => (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={image.originUrl}
                              key={image.id}
                            >
                              <div className="feedback-image">
                                <img src={image.originUrl} alt={image.title} />
                              </div>
                            </a>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="feedback-item__actions">
                      {status === FeedbackStatus.new ? (
                        <ThemeButton
                          loading={idLoading === o.id}
                          className="theme-button feedback-item__actions-solved"
                          onClick={async () => {
                            try {
                              setIdLoading(o.id);
                              await onPostSolved({
                                data: {
                                  ids: [o.id],
                                },
                              });
                              message.success('Cập nhật thành công');
                              await fetchFeedbackList();
                            } catch (error: any) {
                              message.error(error?.message || error);
                            } finally {
                              setIdLoading(null);
                            }
                          }}
                        >
                          Đã trả lời khách
                        </ThemeButton>
                      ) : (
                        <div>
                          <strong>
                            {admin?.firstName} {admin?.lastName}
                          </strong>
                          {` đã xử lý feedback này.`}
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              );
            })
          ) : (
            <div className="feedback-list__empty">
              <Empty description="Danh sách trống" />
            </div>
          )}
        </div>

        {!!data?.length && (
          <div className="mt-3 mb-2 text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}
      </div>
    </LayoutDetail>
  );
};

export default FeedbackPage;
