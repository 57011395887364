import * as constants from 'constants/constants';
import { IToken } from 'interfaces/token.interface';
import { TokenModel } from 'models/token.model';

class TokenServices {
  constructor() {}

  setToken(tokenObj: IToken) {
    const { accessToken, refreshToken, tokenType } = new TokenModel(tokenObj);

    localStorage.setItem(constants.ACESS_TOKEN_NAME, accessToken);
    localStorage.setItem(constants.REFRESH_TOKEN_NAME, refreshToken);
    localStorage.setItem(constants.TOKEN_TYPE, tokenType);
  }

  getAccessToken() {
    const accessJson = localStorage.getItem(constants.ACESS_TOKEN_NAME);
    const tokenType = localStorage.getItem(constants.TOKEN_TYPE);

    return {
      accessToken: accessJson,
      tokenType,
    };
  }

  getRefreshToken() {
    const refreshJson = localStorage.getItem(constants.REFRESH_TOKEN_NAME);
    return refreshJson;
  }

  clearToken() {
    localStorage.removeItem(constants.ACESS_TOKEN_NAME);
    localStorage.removeItem(constants.REFRESH_TOKEN_NAME);
    localStorage.removeItem(constants.TOKEN_TYPE);
  }
}

export default TokenServices;
