/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Row, Select, Switch, message } from 'antd';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import moment from 'moment';
import { useUpdateEventPage } from 'pages/EventPage/hooks';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import './EventPageDetail.scss';
import { setIsEditing } from 'redux/uiAction/actions';
import { STORE_IDS } from 'constants/constants';
import { DateFormat, EventPageBlockTemplates, StoreIds } from 'constants/enum';
import { PresentBlockDetailModal } from './components/PresentBlockDetailModal/PresentBlockDetailModal';
import { SEOEditorModal } from './components/SEOEditorModal';
import { useFetchEventPage } from './hooks';
import { getFullUrlWithSlug } from 'utils/utils';

const { Panel } = Collapse;

const EventPageDetail: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id: eventPageId } = useParams<{ id: string }>();

  const { isFetching, data: eventPageData, blocksOfEventPage } = useFetchEventPage(eventPageId);
  const { isUpdating, onUpdate } = useUpdateEventPage();

  const [openSEOEditorModal, setOpenSEOEditorModal] = useState<boolean>(false);
  const [openPresentModal, setOpenPresentModal] = useState<EventPageBlockTemplates | null>(null);

  const { createdAt, updatedAt } = eventPageData?.attributes || {};

  const formatCreatedAt = createdAt
    ? moment(createdAt, DateFormat.serverSideWithTime).format(DateFormat.clientSideWithTime)
    : null;

  const formatUpdatedAt = updatedAt
    ? moment(updatedAt, DateFormat.serverSideWithTime).format(DateFormat.clientSideWithTime)
    : null;

  const selectedBlockTemplateData = useMemo(
    () => blocksOfEventPage?.find((o) => o.attributes.template === openPresentModal),
    [blocksOfEventPage, openPresentModal],
  );

  /**
   * A hook that sets the form fields values based on the eventPageData attributes when the eventPageData ID changes.
   *
   * @param {Object} eventPageData - The eventPageData object containing the attributes.
   */
  const [slugPath, setSlugPath] = useState<string>('');
  const [stdStoreIds, setStdStoreIds] = useState<number[]>([]);
  useEffect(() => {
    if (eventPageData?.id) {
      const { title, slug, publish, storeIds } = eventPageData.attributes;
      form.setFieldsValue({ title, slug, publish, storeIds: storeIds?.map((s) => parseInt(s)) });
      setSlugPath(slug);
      setStdStoreIds(storeIds?.map((s) => parseInt(s)));
      dispatch(setIsEditing(false));
    }

    return () => {
      setSlugPath('');
      setStdStoreIds([]);
    };
  }, [eventPageData?.id]);

  /**
   * Handle the event of saving an event page.
   *
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onSave = useCallback(async (): Promise<void> => {
    try {
      const values = form.getFieldsValue();
      await onUpdate({ method: 'put', id: eventPageData?.id, data: values });
      message.success('Cập nhật event page thành công');
    } catch (error: any) {
      if (error?.slug?.length) {
        message.error('Slug đã bị trùng, vui lòng nhập slug khác');
        return;
      }
      const msg = error?.message || error;
      message.error(msg);
    } finally {
      dispatch(setIsEditing(false));
    }
  }, [eventPageData?.id]);

  /**
   * Handle form change values
   */
  const onChangeValues = useCallback((field, values) => {
    const fieldKey = Object.keys(field);
    switch (fieldKey[0]) {
      case 'slug':
        setSlugPath(values.slug);
        break;
      case 'storeIds':
        setStdStoreIds(values.storeIds);
        break;
      default:
        break;
    }
  }, []);

  /**
   * Toggles the SEO Editor modal by toggling the state of `openSEOEditorModal`.
   *
   * @returns {void}
   */
  const toggleSEOEditorModal = useCallback((): void => {
    setOpenSEOEditorModal((prev) => !prev);
  }, []);

  /**
   * Navigates to the event pages with the provided query parameters.
   *
   * @returns {void}
   */
  const onGotoEventPages = useCallback((): void => {
    history.push(
      queryString.stringifyUrl(
        {
          url: '/event-page',
          query: location.state as any,
        },
        { skipEmptyString: true, skipNull: true },
      ),
    );
  }, []);

  if (isFetching || !eventPageData) return null;

  return (
    <>
      <LayoutDetail
        pageTitle={`Event Page #${eventPageId || ''}`}
        isAddNew={false}
        pageClassName="event-page-detail"
        breadcrumbs={[
          {
            title: 'Event Page',
            route: '/event-page',
          },
          {
            title: 'Chi tiết',
            route: `/event-page/${eventPageId}`,
          },
        ]}
        formHook={form}
        handleSave={onSave}
        saveLoading={isUpdating}
        handleValuesChange={onChangeValues}
      >
        <Button className="btn-close-promotion" onClick={onGotoEventPages}>
          ĐÓNG
        </Button>

        <Collapse defaultActiveKey={['1', '2']}>
          <Panel header="Thông tin cơ bản" key="1">
            <div className="event-page__date-info">
              <span className="date-info__label">Ngày tạo:</span>
              <span className="date-info__value">{formatCreatedAt}</span>
              <span className="date-info__divider">|</span>
              <span className="date-info__label">Ngày cập nhật:</span>
              <span className="date-info__value">{formatUpdatedAt}</span>
            </div>
            <Row gutter={24}>
              <Col span={10}>
                <Form.Item
                  label="Tiêu đề"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập tiêu đề',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={10}>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập slug',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item label=" ">
                  <Button icon={<EditOutlined />} onClick={toggleSEOEditorModal}>
                    Nội dung SEO
                  </Button>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Chọn cửa hàng"
                  name="storeIds"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn cửa hàng',
                    },
                  ]}
                >
                  <Select mode="multiple" className="ant-select-lg" options={STORE_IDS} />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item label="Publish" name="publish" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
              {slugPath !== '' && (
                <Col span={8}>
                  <Form.Item label=" ">
                    {stdStoreIds.map((store) => (
                      <a
                        href={getFullUrlWithSlug(store, `/event/${slugPath}`)}
                        target="_blank"
                        rel="noreferrer"
                        key={`btn-link-${store}`}
                        className="mr-2"
                      >
                        <Button>{store === StoreIds.mainguyen ? 'MN Link' : 'RIDER Link'}</Button>
                      </a>
                    ))}
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Panel>
          <Panel header="Trình bày trang" key="2">
            <Row>
              <Col span={24}>
                <div
                  className="block-item"
                  onClick={() => setOpenPresentModal(EventPageBlockTemplates.hero)}
                >
                  Hero
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="block-item block-item__menu"
                  onClick={() => setOpenPresentModal(EventPageBlockTemplates.menu)}
                >
                  Menu
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="block-item block-item__menu"
                  onClick={() => setOpenPresentModal(EventPageBlockTemplates.countdown)}
                >
                  Countdown
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="block-item"
                  onClick={() => setOpenPresentModal(EventPageBlockTemplates.productDescription)}
                >
                  Hình mô tả sản phẩm
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="block-item"
                  onClick={() => setOpenPresentModal(EventPageBlockTemplates.listOfProducts)}
                >
                  Danh sách sản phẩm
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="block-item"
                  onClick={() => setOpenPresentModal(EventPageBlockTemplates.contactUs)}
                >
                  Liên hệ với chúng tôi
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="block-item"
                  onClick={() => setOpenPresentModal(EventPageBlockTemplates.productImageSlider)}
                >
                  Hình ảnh sản phẩm - Slider
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="block-item"
                  onClick={() => setOpenPresentModal(EventPageBlockTemplates.productVideos)}
                >
                  Video sản phẩm
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="block-item"
                  onClick={() => setOpenPresentModal(EventPageBlockTemplates.comments)}
                >
                  Comments
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="block-item"
                  onClick={() => setOpenPresentModal(EventPageBlockTemplates.news)}
                >
                  News
                </div>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </LayoutDetail>

      <SEOEditorModal
        isOpen={openSEOEditorModal}
        data={eventPageData}
        onClose={toggleSEOEditorModal}
      />

      <PresentBlockDetailModal
        isOpen={!!openPresentModal}
        eventPageId={eventPageData?.id}
        blockData={selectedBlockTemplateData}
        template={openPresentModal}
        onClose={() => setOpenPresentModal(null)}
      />
    </>
  );
};

export default EventPageDetail;
