/* eslint-disable camelcase */
import { Button, Col, Form, Input, Row, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ORDER_STATUS_LIST } from 'constants/constants';

export interface FilterCouponOrderHistory {
  orderCode: string;
  couponCode: string;
  customerEmail: string;
  customerPhone: string;
  orderStatus: string;
}

interface Props {
  onFilter: (filter: FilterCouponOrderHistory) => void;
}

export const FilterView: React.FC<Props> = (props) => {
  const { onFilter } = props;
  const [form] = Form.useForm();

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (!isEmpty(params)) {
      form.setFieldsValue(params);
    }
  }, [params]);

  const onClear = useCallback(() => {
    form.resetFields();
    onFilter({
      orderCode: '',
      couponCode: '',
      customerEmail: '',
      customerPhone: '',
      orderStatus: '',
    } as FilterCouponOrderHistory);
  }, [onFilter]);

  return (
    <Form layout="vertical" form={form}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Mã đơn hàng" name="orderCode">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Mã coupon" name="couponCode">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Email khách hàng" name="customerEmail">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Số điện thoại khách hàng" name="customerPhone">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Tình trạng đơn hàng" name="orderStatus">
            <Select className="ant-select-lg" options={ORDER_STATUS_LIST} />
          </Form.Item>
        </Col>
        <Col
          span={24}
          className="align-items-center justify-content-end"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button onClick={() => onFilter(form.getFieldsValue())} className="secondary-button">
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={onClear}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
