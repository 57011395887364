import { message } from 'antd';
import useSWRMutation from 'swr/mutation';
import { API_VARIANT_PRODUCTS_POSITIONS } from 'constants/api/product.api';
import { poster } from 'services/swr.utils';

export const useUpdateVariantsPosition = (productId: string) => {
  const { isMutating, trigger } = useSWRMutation(
    API_VARIANT_PRODUCTS_POSITIONS.replace('{id}', productId),
    poster,
    {
      onSuccess: () => {
        message.success('Lưu sản phẩm con thành công', 3);
      },
      onError: () => {
        message.error('Có lỗi server, thử lại sau', 3);
      },
    },
  );

  return {
    isUpdating: isMutating,
    onUpdate: trigger,
  };
};
