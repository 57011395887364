import { Col, Form, Input, Row, Switch } from 'antd';
import { RcFile } from 'antd/lib/upload';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { API_UPDATE_YOUTUBE_VIDEO, API_YOUTUBE_VIDEOS } from 'constants/api/youtube-manager.api';
import { IYoutubeVideo, IYoutubeVideoAttribute } from 'interfaces/youtube-video.interface';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';

// Components
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { showErrorMsg, generateSlug, uploadFile } from 'utils/utils';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onCloseAndRefresh: () => void;
  data: IYoutubeVideo;
}

export const FormModal: React.FC<Props> = (props) => {
  const { title, isOpen, data, onClose, onCloseAndRefresh } = props;

  const [form] = Form.useForm<IYoutubeVideoAttribute>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(data)) {
      form.setFieldsValue({ ...data.attributes });
    } else {
      form.resetFields();
    }
  }, [data]);

  const onSave = useCallback(async () => {
    try {
      const values = form.getFieldsValue();
      const payloadData: IYoutubeVideoAttribute = {
        title: values.title,
        slug: generateSlug(values.title.trim()),
        description: values.description,
        youtubeUrl: values.youtubeUrl,
        active: typeof values.active !== 'boolean' ? false : values.active,
      };
      if (data?.id) {
        // Edit existing video
        await api.put({
          endpoint: API_UPDATE_YOUTUBE_VIDEO.replace('{id}', data.id),
          data: payloadData,
        });
        // If the video already has a thumbnail image, then skip upload
        values.image &&
          !values.image['128x128'] &&
          (await uploadFile(values.image, 'youtube', data.id));
      } else {
        // Add a new video
        const result = await api.post({ endpoint: API_YOUTUBE_VIDEOS, data: payloadData });
        await uploadFile(values.image, 'youtube', result.data.id);
      }

      form.resetFields();
      dispatch(setIsEditing(false));
      onCloseAndRefresh();
    } catch (err) {
      showErrorMsg(err);
    }
  }, [form, data]);

  return (
    <CustomPopup
      title={title}
      isOpen={isOpen}
      formHook={form}
      showDeleteButton={false}
      onCloseModal={onClose}
      handleSaveButton={onSave}
    >
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  name="title"
                  label={`Tiêu đề ${data?.id ? `( ID: ${data.id} )` : ''}`}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng điền tên thương hiệu',
                    },
                  ]}
                >
                  <Input placeholder="Tên thương hiệu" autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  name="youtubeUrl"
                  label="Youtube URL"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng điền youtube URL',
                    },
                  ]}
                >
                  <Input placeholder="Youtube URL" autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Mô tả"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng điền mô tả',
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    style={{ resize: 'none' }}
                    placeholder="Mô tả"
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item name="active" label="Hiển thị" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item
              name="image"
              label="Bìa video (Tỉ lệ 16:9 - 1600x900)"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng thêm bìa video',
                },
              ]}
            >
              <UploadFile
                maxWidth={400}
                previewImage={data?.attributes?.image}
                handleUploadImage={(file: RcFile) => {
                  form.setFields([
                    {
                      name: 'image',
                      value: file,
                      errors: null,
                    },
                  ]);
                }}
                onRemoveImageCallback={() => {
                  form.setFields([
                    {
                      name: 'image',
                      value: null,
                      errors: ['Vui lòng thêm bìa video'],
                    },
                  ]);
                }}
                handleDeleteImageSuccess={() => {
                  form.setFields([
                    {
                      name: 'image',
                      value: null,
                      errors: ['Vui lòng thêm bìa video'],
                    },
                  ]);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    </CustomPopup>
  );
};
