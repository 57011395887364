type TitleProps = {
  text: string;
};

const Title: React.FC<TitleProps> = ({ text }) => {
  return (
    <div className="card-header mb-3">
      <div className="title-box">
        <h5 className="title">{text}</h5>
      </div>
    </div>
  );
};

export default Title;
