import { useEffect, useState } from 'react';
import { API_GET_RIDER_TYPES } from 'constants/api/riderType.api';
import { IRiderTypes } from 'interfaces/riderType';
import * as api from 'services/api.service';

export const useFetchDrivingStyles = (type: string) => {
  const [drivingStyles, setDrivingStyles] = useState<IRiderTypes[]>([]);

  useEffect(() => {
    const fetch = async () => {
      if (type === 'ride_style' && !drivingStyles?.length) {
        api
          .get({
            endpoint: `${API_GET_RIDER_TYPES}?page=1`,
          })
          .then((res) => {
            setDrivingStyles(res?.data);
          });
      }
    };

    fetch();
  }, [type]);

  return { drivingStyles };
};
