import { ICollection } from 'interfaces/collection.interface';
import { IPagination } from 'interfaces/pagination.interface';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPageData } from 'services/pageData.service';
import { fetcherWithArg } from 'services/swr.utils';
import { updater } from 'services/swr.utils';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { buildParams } from 'utils/utils';
import { API_COLLECTION, API_PRODUCTS_OF_COLLECTION } from 'constants/api/collection.api';
import { API_GET_PRODUCTS } from 'constants/api/product.api';
import { defaultPagination } from 'constants/constants';

export const useFetchCollection = (id: string) => {
  const dispatch = useDispatch();
  const { data, isLoading, isValidating, mutate } = useSWR(
    id ? API_COLLECTION.replace('{id}', id) : null,
    (url: string) => getPageData(url, dispatch),
  );

  useEffect(() => {
    if (data) {
      mutate(null, { revalidate: true });
    }
  }, [id]);

  return {
    isFetching: isLoading || isValidating,
    data: data?.data as ICollection,
  };
};

/**
 *
 * @returns
 */
export const useUpdateCollection = () => {
  const { isMutating, trigger } = useSWRMutation(API_COLLECTION, updater);

  return {
    isUpdating: isMutating,
    onUpdate: trigger,
  };
};

/**
 *
 */
export const useFetchProducts = () => {
  const { isMutating, data, trigger, reset } = useSWRMutation(API_GET_PRODUCTS, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        pageSize: data.meta.pagination.perPage,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
      }));
    }
  }, [data?.meta?.pagination]);

  const fetchProducts = useCallback(
    async (params?: any): Promise<void> => {
      const { page = pagination.current, ...rest } = params || {};
      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;

      const query = { page: calcPage, ...rest };
      await trigger(query);
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetchingProducts: isMutating,
    products: data?.data || [],
    fetchProducts,
    pagination,
    setPagination,
    reset,
  };
};

export const useFetchProductsOfCollection = (collectionId: string) => {
  const history = useHistory();
  const { isMutating, data, trigger } = useSWRMutation(
    API_PRODUCTS_OF_COLLECTION.replace('{id}', collectionId),
    fetcherWithArg,
  );
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        pageSize: data.meta.pagination.perPage,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
      }));
    }
  }, [data?.meta?.pagination]);

  const fetchProductsOfCollection = useCallback(
    async (params?: {
      page?: number;
      name?: string;
      categoryId?: string;
      brandId?: string;
      direction?: string;
    }): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const { page = pagination.current, direction = 'desc', ...rest } = params || {};
      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;

      const query = { ...locationParams, page: calcPage, direction, ...rest };

      history.push(
        queryString.stringifyUrl(
          {
            url: location.pathname,
            query,
          },
          { skipEmptyString: true, skipNull: true },
        ),
      );

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetchingProductsOfCollection: isMutating,
    productsOfCollection: data?.data || [],
    fetchProductsOfCollection,
    pagination,
    setPagination,
  };
};
