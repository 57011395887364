/* eslint-disable import/order */
import { Col, Form, Input, Row, Select, Switch } from 'antd';
import { IAttributeSet } from 'interfaces/attr-set.interface';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState } from 'react';
import { CustomTreeSelect } from 'ui/components/MaiNguyen/CustomTreeSelect';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { TreeLevelInterface, generateSlug } from 'utils/utils';
import { CreateProductCategory } from '../types';
import { MNStoreList } from 'constants/enum';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { useFetchProdCatesByParentId } from 'hooks/useFetchProductCategories';

interface CreateFormProps {
  isCreating: boolean;
  productCategories: TreeLevelInterface[];
  attributeGroup: IAttributeSet[];
  onFinish: (values: CreateProductCategory) => void;
}

export const CreateForm: React.FC<CreateFormProps> = (props) => {
  const { isCreating, productCategories, attributeGroup, onFinish } = props;
  const [selectedParentId, setSelectedParentId] = useState(null);

  const { isFetching, data: catesList } = useFetchProdCatesByParentId(selectedParentId);

  const [form] = Form.useForm();

  const _onFinish = useCallback(
    (values: CreateProductCategory) => {
      onFinish({
        ...values,
        urlPath: generateSlug(values.name.trim()),
      });
    },
    [onFinish],
  );

  const handleChangeParentId = (id: string) => {
    setSelectedParentId(id);
  };

  return (
    <Form layout="vertical" form={form} onFinish={_onFinish}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Tên nhóm"
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng điền tên nhóm',
              },
            ]}
          >
            <Input placeholder="Tên nhóm" />
          </Form.Item>
          <Form.Item
            label="Nhóm cha"
            name="parentId"
            valuePropName="selectedValue"
            trigger="handleSelect"
          >
            <CustomTreeSelect data={productCategories} handleSelect={handleChangeParentId} />
          </Form.Item>
          <Form.Item label="Nhóm thuộc tính" name="attributeSetId">
            <Select
              loading={isEmpty(attributeGroup)}
              size="large"
              allowClear
              showSearch
              optionFilterProp="children"
            >
              {attributeGroup?.map((item) => (
                <Select.Option key={item.id} value={parseInt(item.id)}>
                  {item.attributes.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="storeIds"
            label="Chọn cửa hàng"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn cửa hàng',
              },
            ]}
          >
            <Select showSearch optionFilterProp="title" mode="multiple" allowClear size="large">
              {MNStoreList.map((item) => {
                const { id, title } = item;
                return (
                  <Select.Option
                    value={id}
                    key={`mn-store-${id}`}
                    title={title}
                    className="select-store"
                  >
                    <SanitizeHtml rawHtml={title} />
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {catesList?.length > 0 && (
            <Form.Item name="position" label="Vị trí">
              <Select
                showSearch
                allowClear
                optionFilterProp="value"
                listHeight={450}
                loading={isFetching}
              >
                {catesList?.map((_, index) => (
                  <Select.Option key={index} value={index}>
                    {index}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item initialValue={true} label="Hiển thị" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Mô tả" name="description">
            <Input.TextArea rows={6} />
          </Form.Item>
          <Form.Item label="Keywords" name="keywords">
            <Input.TextArea rows={6} />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <ThemeButton loading={isCreating} htmlType="submit">
            Lưu
          </ThemeButton>
        </Col>
      </Row>
    </Form>
  );
};
