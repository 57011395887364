import { IBrand } from 'interfaces/brand.interface';
import { IImage } from 'interfaces/image.interface';
import { IOs } from 'interfaces/os.interface';
import { IPrdCategory } from 'interfaces/product-category.interface';
import { IProduct } from 'interfaces/product.interface';
import { IStatus } from 'interfaces/status.interface';
import { ISystemColor } from 'interfaces/system-color.interface';

import {
  SET_BRANDS,
  SET_CATEGORIES,
  SET_OS,
  SET_PRODUCTS,
  SET_STATUSES,
  SET_PRODUCT_DETAIL,
  SET_OPEN_SELECT_PRODUCT_SECTION,
  SET_COLORS,
  SET_SUB_STATUSES,
  SET_NEW_IMAGE_LIST,
  ActionProductType,
  SelectProductSectionInterface,
} from './types';

export type InitialStateTypes = {
  brands: IBrand[];
  categories: IPrdCategory[];
  oses: IOs[];
  statuses: IStatus[];
  products: IProduct[];
  productDetail: IProduct;
  loading: boolean;
  selectProductSection: SelectProductSectionInterface;
  colors: ISystemColor[];
  subStatuses: IStatus[];
  imageList: IImage[];
};

const initState: InitialStateTypes = {
  brands: [],
  categories: [],
  oses: [],
  statuses: [],
  products: [],
  productDetail: null,
  loading: true,
  selectProductSection: {
    title: '',
    productGroupKey: '',
  },
  colors: [],
  subStatuses: [],
  imageList: [],
};

export const productReducers = (state = initState, actions: ActionProductType) => {
  switch (actions.type) {
    case SET_BRANDS:
      return { ...state, brands: actions.payload, loading: false };
    case SET_CATEGORIES:
      return { ...state, categories: actions.payload, loading: false };
    case SET_OS:
      return { ...state, oses: actions.payload, loading: false };
    case SET_PRODUCTS:
      return { ...state, products: actions.payload, loading: false };
    case SET_STATUSES:
      return { ...state, statuses: actions.payload, loading: false };
    case SET_PRODUCT_DETAIL:
      return { ...state, productDetail: actions.payload, loading: false };
    case SET_OPEN_SELECT_PRODUCT_SECTION:
      return {
        ...state,
        loading: false,
        selectProductSection: actions.payload,
      };
    case SET_COLORS:
      return {
        ...state,
        loading: false,
        colors: actions.payload,
      };
    case SET_SUB_STATUSES:
      return {
        ...state,
        loading: false,
        subStatuses: actions.payload,
      };
    case SET_NEW_IMAGE_LIST:
      return {
        ...state,
        loading: false,
        imageList: actions.payload,
      };
    default:
      return state;
  }
};
