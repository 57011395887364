/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useFetchCoupons } from 'hooks/useFetchCoupons';
import { ICoupon } from 'interfaces/coupon.interface';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setIsEditing } from 'redux/uiAction/actions';
import './styles.scss';
import { CouponStatus, StoreIds } from 'constants/enum';
import { SelectedCouponsModal } from './SelectedCouponsModal';
import { CouponsTable } from '../CouponsTable';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  initialCouponIds: string[];
  onChange: (couponIds: string[]) => void;
}

export const AddCouponModal: React.FC<Props> = (props) => {
  const { isOpen, initialCouponIds, onClose, onChange } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { isFetchingCoupons, couponList, fetchCoupons, reset: resetProducts } = useFetchCoupons();

  const [selectedCoupons, setSelectedCoupons] = useState<ICoupon[]>([]);
  const [openSelectedProductsModal, setOpenSelectedProductsModal] = useState<boolean>(false);

  const selectedCouponIds = selectedCoupons?.map((p) => p.id);
  const combineCouponIds = [...selectedCouponIds, ...initialCouponIds];

  const location = useLocation();
  const storeId = location.pathname.includes('rider') ? StoreIds.rider : StoreIds.mainguyen;

  /**
   * A callback function that performs a search for coupons based on the form values and selected coupon ids.
   */
  const onSearch = useCallback(async (): Promise<void> => {
    const values = form.getFieldsValue();

    await fetchCoupons({
      perPage: 20,
      status: CouponStatus.active,
      storeId,
      ...queryString.parse(
        queryString.stringify(
          {
            ...values,
            ignoreIds: combineCouponIds?.length > 1 ? combineCouponIds : undefined,
            ignoreId: combineCouponIds?.length === 1 ? combineCouponIds[0] : undefined,
          },
          { skipNull: true, skipEmptyString: true },
        ),
      ),
    });
  }, [combineCouponIds, storeId]);

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === 'Enter') {
        await onSearch();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onSearch]);

  /**
   * An effect that resets the form fields, products, and selected coupon IDs when the modal is closed.
   */
  useEffect((): void => {
    if (!isOpen) {
      form.resetFields();
      resetProducts();
      setSelectedCoupons([]);
    }
  }, [isOpen]);

  /**
   * A callback function that selects products based on the provided coupon IDs.
   *
   * @param {React.Key[]} productIds - An array of coupon IDs to select.
   */
  const onSelectCoupons = useCallback((_, _coupons: ICoupon[]) => {
    setSelectedCoupons(_coupons);
    dispatch(setIsEditing(true));
  }, []);

  return (
    <Modal
      footer={null}
      visible={isOpen}
      title="Thêm coupon"
      onCancel={onClose}
      centered
      width={1280}
      className="base-modal"
    >
      <div className="add-product-modal__content">
        <Form form={form} layout="vertical">
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label="Tiêu đề" name="title">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Tiêu đề trình bày" name="displayTitle">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Prefix" name="prefixCode">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="SKU / Tên sản phẩm" name="sku">
                <Input />
              </Form.Item>
            </Col>
            <Col className="form-item__search" span={3}>
              <Button className="search-btn" onClick={onSearch}>
                Tìm
              </Button>
            </Col>
          </Row>
        </Form>

        {!!selectedCouponIds?.length && (
          <div
            className="selected-products-label"
            onClick={() => setOpenSelectedProductsModal(true)}
          >
            Xem danh sách đã chọn ({selectedCouponIds.length})
          </div>
        )}

        <CouponsTable
          loading={isFetchingCoupons}
          data={couponList}
          rowKey={(r) => r.id}
          rowSelection={{
            selectedRowKeys: selectedCouponIds,
            onChange: onSelectCoupons,
          }}
          scroll={{ y: 400, x: 500 }}
        />

        {!!selectedCoupons?.length && (
          <div className="save-button__wrapper">
            <Button type="primary" onClick={() => onChange(combineCouponIds)}>
              Lưu
            </Button>
          </div>
        )}

        <SelectedCouponsModal
          visible={openSelectedProductsModal}
          onClose={() => setOpenSelectedProductsModal(false)}
          data={selectedCoupons}
          onRemove={(productId) =>
            setSelectedCoupons((prev) => prev.filter((p) => p.id !== productId))
          }
        />
      </div>
    </Modal>
  );
};
