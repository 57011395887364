import { Form, Row, Col, Input, Radio } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FC, useCallback } from 'react';
import { defaultDataForRiderHomePresent } from 'constants/constants';
import { RiderBlockPositionTypes, RiderBlockTemplateTypes } from 'constants/enum';
import { IHomeBlock } from 'interfaces/home-block.interface';

// Constant
import { CustomJsonInput } from 'ui/components/MaiNguyen/CustomJsonEditor';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { showErrorMsg } from 'utils/utils';

// Interface

// Components
import { CollectionsBlock, CollectionsTemplateBlock } from './CollectionsBlock';
import { HeaderLogoBlock } from './HeaderLogoBlock';
import { RiderHeaderLogoTypes } from './HeaderLogoBlock/HeaderLogoBlock';
import { HighEndBlock } from './HighEndBlock';
import { InstagramBlock } from './InstagramBlock';
import { MenuBlock } from './MenuBlock';
import { ShockPriceBlock } from './ShockPriceBlock';
import { SpotLight } from './SpotLightBlock';
import { CustomEditor } from '../CustomEditor';

import './RiderTemplateBlock.scss';

interface RiderTemplateBlockInterface {
  template: string;
  block: IHomeBlock;
  onAddImage?: (file: RcFile) => void;
  onDeleteImage?: () => void;
  onChangeJsonData?: (jsonData: any) => void;
  onUpdateHighendBlock?: (data: any) => void;
  onUpdateShockPriceBlock?: (data: any) => void;
  handleChangeEditorContent?: (data: any) => void;
  handleUpdateHeaderLogo?: (data: RiderHeaderLogoTypes) => void;
  onUpdateMenu?: (data: any) => void;
  handleUpdateCollections?: (data: CollectionsTemplateBlock) => void;
  onUpdateSlider?: (slider: any[]) => void;
}

export const RiderTemplateBlock: FC<RiderTemplateBlockInterface> = ({
  template,
  block,
  onAddImage,
  onDeleteImage,
  onChangeJsonData,
  onUpdateHighendBlock,
  onUpdateShockPriceBlock,
  handleChangeEditorContent,
  handleUpdateHeaderLogo,
  onUpdateMenu,
  handleUpdateCollections,
  onUpdateSlider,
}) => {
  const handleChangeJsonValue = useCallback((value) => {
    onChangeJsonData(value);
  }, []);

  const getDefaultTemplateData = useCallback(
    (key: RiderBlockTemplateTypes) => {
      let data = defaultDataForRiderHomePresent[key] || [];
      if (block?.attributes?.data) {
        try {
          data = JSON.parse(block.attributes.data);
        } catch (err) {
          showErrorMsg(err);
        }
      }
      return data;
    },
    [block],
  );

  const renderTemplateContent = useCallback(
    (tmpl: string) => {
      switch (tmpl) {
        case RiderBlockTemplateTypes.largeBanner:
        case RiderBlockTemplateTypes.riderSinglePage:
          const { title, subTitle, target, link } = getDefaultTemplateData(
            tmpl as RiderBlockTemplateTypes,
          );

          const isSinglePage = tmpl === RiderBlockTemplateTypes.riderSinglePage; // check is template rider single page
          const imageTitle = isSinglePage ? 'Image' : 'Image (2800x200)';

          return (
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Tiêu đề" name="title" initialValue={title}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Link" name="link" initialValue={link}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Target" name="target" initialValue={target}>
                  <Radio.Group>
                    <Radio value="_blank">_blank</Radio>
                    <Radio value="not">not</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {!isSinglePage && (
                <Col span={24}>
                  <Form.Item label="Tiêu đề phụ" name="subTitle" initialValue={subTitle}>
                    <Input />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item label={imageTitle}>
                  <UploadFile
                    previewImage={block?.attributes?.image}
                    handleUploadImage={onAddImage}
                    handleDeleteImageSuccess={onDeleteImage}
                    maxWidth={600}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        case RiderBlockTemplateTypes.heroBanner:
          return (
            <SpotLight
              blockId={block?.attributes?._id?.toString()}
              dataSlider={getDefaultTemplateData(tmpl as RiderBlockTemplateTypes)}
              onUpdateSlider={onUpdateSlider}
              template={RiderBlockTemplateTypes.heroBanner}
              uploadTextDesktop="Click hoặc kéo thả hình để upload (1200x1200)"
              hasMobileImage={false}
              isChangePosition
            />
          );
        case RiderBlockTemplateTypes.headerLogo:
          return (
            <HeaderLogoBlock
              blockId={block?.attributes?._id?.toString()}
              data={getDefaultTemplateData(tmpl as RiderBlockTemplateTypes)}
              onChange={handleUpdateHeaderLogo}
            />
          );
        case RiderBlockTemplateTypes.riderCollections:
          return (
            <CollectionsBlock
              blockId={block?.attributes?._id?.toString()}
              data={getDefaultTemplateData(tmpl as RiderBlockTemplateTypes)}
              onChange={handleUpdateCollections}
            />
          );
        case RiderBlockTemplateTypes.shockPriceToday:
          return (
            <ShockPriceBlock
              blockId={block.id}
              blockName={RiderBlockPositionTypes.shockPriceToday}
              blockData={block?.attributes?.data}
              handleUpdateBlockData={onUpdateShockPriceBlock}
            />
          );

        case RiderBlockTemplateTypes.justInStock:
          return (
            <HighEndBlock
              blockId={block.id}
              blockName={RiderBlockPositionTypes.justInStock}
              blockData={block?.attributes?.data}
              handleUpdateBlockData={onUpdateHighendBlock}
            />
          );

        case RiderBlockTemplateTypes.youtubeVideos:
          const { countItems, channelUrl, blockTitle } = (
            block?.attributes?.data ? JSON.parse(block.attributes.data) : {}
          ) as { blockTitle: string; countItems: string | number; channelUrl: string };
          return (
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Block title" name="blockTitle" initialValue={blockTitle}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Channel Url" name="channelUrl" initialValue={channelUrl}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Number of videos" name="countItems" initialValue={countItems}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          );

        case RiderBlockTemplateTypes.metadataHomePage:
          return (
            <>
              <Form.Item label="Image">
                <UploadFile
                  previewImage={block?.attributes?.image}
                  handleUploadImage={onAddImage}
                  handleDeleteImageSuccess={onDeleteImage}
                  maxWidth={600}
                />
              </Form.Item>
              <CustomJsonInput
                value={getDefaultTemplateData(tmpl as RiderBlockTemplateTypes)}
                onChange={handleChangeJsonValue}
                template={tmpl}
                defaultJsonData={defaultDataForRiderHomePresent}
              />
            </>
          );

        case RiderBlockTemplateTypes.footerCopyrite:
        case RiderBlockTemplateTypes.riderEditor:
          return (
            <>
              <CustomEditor
                mainContent={block?.attributes?.data}
                handleChangeMainContent={handleChangeEditorContent}
              />
            </>
          );

        case RiderBlockTemplateTypes.footerMenu1:
        case RiderBlockTemplateTypes.footerMenu2:
          const { headingTitle, menuItems } = getDefaultTemplateData(
            tmpl as RiderBlockTemplateTypes,
          );
          return (
            <MenuBlock
              blockId={block?.attributes?._id?.toString()}
              headingTitle={headingTitle}
              dataMenuItems={menuItems}
              onUpdateMenu={onUpdateMenu}
            />
          );
        case RiderBlockTemplateTypes.productDetaillConsultant:
          const { label, phone, email } = getDefaultTemplateData(
            RiderBlockTemplateTypes.productDetaillConsultant,
          );
          return (
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Label" name="label" initialValue={label}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Phone" name="phone" initialValue={phone}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="ZaloLink" name="email" initialValue={email}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'Avatar'}>
                  <UploadFile
                    previewImage={block?.attributes?.image}
                    handleUploadImage={onAddImage}
                    handleDeleteImageSuccess={onDeleteImage}
                    maxWidth={600}
                  />
                </Form.Item>
              </Col>
            </Row>
          );

        case RiderBlockTemplateTypes.riderInstagram:
          const data = getDefaultTemplateData(RiderBlockTemplateTypes.riderInstagram);
          return <InstagramBlock data={data} />;

        case RiderBlockTemplateTypes.productDetailWarrantyInfo:
          const { refundContent, returnAndExchangeContent, warrantyGuide } = getDefaultTemplateData(
            RiderBlockTemplateTypes.productDetailWarrantyInfo,
          );

          return (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Nội dung hoàn tiền"
                  name="refundContent"
                  initialValue={refundContent}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Nội dung đổi trả"
                  name="returnAndExchangeContent"
                  initialValue={returnAndExchangeContent}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Nội dung hướng dẫn bảo hành"
                  name="warrantyGuide"
                  trigger="handleChangeMainContent"
                  valuePropName="mainContent"
                  initialValue={warrantyGuide}
                >
                  <CustomEditor />
                </Form.Item>
              </Col>
            </Row>
          );
        default:
          return (
            <CustomJsonInput
              value={getDefaultTemplateData(tmpl as RiderBlockTemplateTypes)}
              onChange={handleChangeJsonValue}
              template={tmpl}
              defaultJsonData={defaultDataForRiderHomePresent}
            />
          );
      }
    },
    [block],
  );

  return <div className="rider-template-block-wrapper">{renderTemplateContent(template)}</div>;
};
