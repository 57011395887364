import { DeleteOutlined, InboxOutlined, RedoOutlined } from '@ant-design/icons';
import { Upload, Button, Spin, Table, Radio, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RcFile } from 'antd/lib/upload';

import { IImage, IPdfFile } from 'interfaces/image.interface';
import { clone, isEmpty } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import * as api from 'services/api.service';
import { SortableTable } from 'ui/components/SortableTable/SortableTable';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';
import { API_DELETE_IMAGE_BY_ID } from 'constants/api/auth.api';
import { AlignType } from 'constants/enum';

import './UploadFile.scss';
import { isVideoLink } from './helpers';

interface UploadFileInterface {
  uploadText?: string;
  previewImage?: IImage | IImage[];
  previewFile?: IPdfFile | IPdfFile[];
  maxWidth?: number;
  imageSize?: string;
  typeId?: string;
  multiple?: boolean;
  description?: string;
  handleUploadImage?: (file: RcFile) => void;
  handleUploadMultipleImages?: (files: RcFile) => void;
  handleChangeImagesPosition?: (newList: IImage[]) => void;
  handleSelectMainImage?: (image: IImage[]) => void;
  handleSelectSecondImage?: (image: IImage[]) => void;
  handleDeleteImageSuccess?: (nodeId: string) => void;
  fileAccept?: string;
  fileType?: string;
  resetFileUpload?: boolean;
  onRemoveImageCallback?: () => void;
  resetFile?: boolean;
  imageLifestyle?: boolean;
  isSelectLifestyleImg?: boolean;
}

export const UploadFile: FC<UploadFileInterface> = ({
  uploadText = 'Click or drag file to this area to upload',
  previewImage,
  previewFile: previewFileFromProps,
  maxWidth = 128,
  imageSize = '128x128',
  handleUploadImage = null,
  handleUploadMultipleImages = null,
  typeId = '',
  multiple = false,
  description = '',
  handleDeleteImageSuccess = null,
  handleChangeImagesPosition = null,
  handleSelectMainImage = null,
  handleSelectSecondImage = null,
  fileAccept = 'image/*',
  fileType = '',
  resetFileUpload = false,
  onRemoveImageCallback,
  resetFile = false,
  imageLifestyle = false,
  isSelectLifestyleImg = false,
}) => {
  const [preview, setPreviewImage] = useState(
    multiple
      ? previewImage
      : previewImage?.['original']
      ? previewImage?.['original']
      : previewImage?.[imageSize],
  );

  const [previewFiles, setPreviewFiles] = useState(previewFileFromProps || []);
  const [selectedMainImage, setSelectedMainImage] = useState<React.Key[]>([]);
  const [selectedSecondImage, setSelectedSecondImage] = useState(null);
  const [fileUpload, setFileUpload] = useState<RcFile>(null);
  const [reset, setReset] = useState(resetFileUpload);
  const [refreshImg, setRefreshImg] = useState(false);
  const [lifestyleImg, setLifestyleImg] = useState([]);

  useEffect(() => {
    setPreviewImage(
      multiple
        ? previewImage
        : previewImage?.['original']
        ? previewImage?.['original']
        : previewImage?.[imageSize],
    );
  }, [previewImage, multiple]);

  useEffect(() => {
    if (!isEmpty(previewFileFromProps)) setPreviewFiles(previewFileFromProps);
  }, [previewFileFromProps]);

  useEffect(() => {
    setReset(resetFileUpload);
  }, [resetFileUpload]);

  useEffect(() => {
    if (reset) {
      setFileUpload(null);
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (resetFile) {
      setFileUpload(null);
      setPreviewImage('');
    }
  }, [resetFile]);

  useEffect(() => {
    if (multiple) {
      const clonedPreviewImage: IImage[] = clone(previewImage as IImage[]);
      clonedPreviewImage?.forEach((item) => {
        if (item.property === 'main') {
          setSelectedMainImage([item.id]);
        }
        if (item.property === 'lifestyle') {
          setSelectedSecondImage(item.id);
        }
      });

      // TODO: set select image lifestyle
      const findItemsLiftStyle = clonedPreviewImage
        ?.filter((o) => o.properties && o.properties.some((k) => k.isLifeStyle === true))
        ?.map((a) => a.id);
      setLifestyleImg(findItemsLiftStyle);
    }
  }, [multiple, previewImage]);

  const uploadApi = useCallback(
    (file: RcFile) => {
      setFileUpload(file);
      if (multiple) {
        handleUploadMultipleImages && handleUploadMultipleImages(file);
      } else {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          setPreviewImage(fileReader.result as string);
        };
        handleUploadImage && handleUploadImage(file);
      }
      return true;
    },
    [handleUploadImage, multiple, handleUploadMultipleImages],
  );

  const handleDeleteImage = useCallback(() => {
    const isBase64 = preview?.includes('base64');
    if (isBase64) {
      setPreviewImage('');
      onRemoveImageCallback && onRemoveImageCallback();
    } else {
      deleteModal({
        title: 'Sau khi xóa sẽ không thể phục hồi được.<br>Bạn có chắc muốn xóa hình này?',
        onDeleteCb: async () => {
          if (previewImage) {
            try {
              await api.remove({
                endpoint: API_DELETE_IMAGE_BY_ID.replace('{id}', previewImage['id']),
              });
              setPreviewImage('');
              handleDeleteImageSuccess && handleDeleteImageSuccess(typeId);
            } catch (err) {
              showErrorMsg(err);
            }
          }
        },
      });
    }
  }, [previewImage, preview, typeId, handleDeleteImageSuccess]);

  const onDeleteMediaFile = useCallback(
    (id: string, type: 'image' | 'file') => () => {
      deleteModal({
        title: `Sau khi xóa sẽ không thể phục hồi được.<br>Bạn có chắc muốn xóa ${
          type === 'image' ? 'hình' : 'file'
        } này?`,
        onDeleteCb: async () => {
          try {
            const clonePreviewList = clone(type === 'image' ? preview : previewFiles);
            const imgIndex = clonePreviewList.findIndex((item) => item.id === id);
            await api.remove({
              endpoint: API_DELETE_IMAGE_BY_ID.replace('{id}', id),
            });
            if (imgIndex > -1) {
              clonePreviewList.splice(imgIndex, 1);
            }
            type === 'image'
              ? setPreviewImage(clonePreviewList)
              : setPreviewFiles(clonePreviewList);
            handleDeleteImageSuccess && handleDeleteImageSuccess(typeId);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [typeId, preview],
  );

  const onChangeImagePosition = useCallback(
    (newList: IImage[]) => {
      handleChangeImagesPosition && handleChangeImagesPosition(newList);
      setPreviewImage(newList);
    },
    [handleChangeImagesPosition],
  );

  // select main image
  const onSelectMainImage = useCallback(
    (selectedRowKeys: React.Key[], selectedRows: IImage[]) => {
      setSelectedMainImage(selectedRowKeys);
      const selectRow = selectedRows[0];

      // reset second image
      if (selectRow.id === selectedSecondImage) {
        setSelectedSecondImage(null);
      }

      preview.map((item: IImage) => {
        // reset main image
        if (item.property !== 'lifestyle' || selectRow.id === selectedSecondImage) {
          item.property = '';
        }

        if (item.id === selectRow.id) {
          item.property = 'main';
        }
      });

      handleSelectMainImage && handleSelectMainImage(preview);
    },
    [preview, selectedSecondImage, handleSelectMainImage],
  );

  // select second image
  const onSelectSecondImage = useCallback(
    (selectedRow: IImage) => {
      preview.map((item: IImage) => {
        // reset second image
        if (item.property !== 'main') {
          item.property = '';
        }

        if (item.id === selectedRow.id) {
          item.property = 'lifestyle';
        }
      });

      handleSelectSecondImage && handleSelectSecondImage(preview);
      setSelectedSecondImage(selectedRow.id);
    },
    [preview, handleSelectSecondImage],
  );

  // TODO: handle select image lifestyle
  const onSelectLifestyleImg = (e: CheckboxChangeEvent, id: string) => {
    const checked = e.target.checked;
    if (checked) {
      setLifestyleImg((prev) => [...prev, id]);
    } else {
      setLifestyleImg((prev) => prev.filter((item) => item !== id));
    }
    preview.map((item: IImage) => {
      if (item.id === id) {
        if (item.properties === null) item.properties = [];

        if (isEmpty(item.properties)) {
          item.properties.push({ isLifeStyle: checked });
        } else {
          const idxLifeStyle = item.properties.findIndex((p) => p.isLifeStyle || !p.isLifeStyle);
          if (idxLifeStyle !== -1) {
            item.properties[idxLifeStyle] = { isLifeStyle: checked };
          }
        }
      }
    });
    handleSelectSecondImage && handleSelectSecondImage(preview);
  };

  const renderReviewContent = () => {
    if (fileUpload) {
      return (
        <>
          {fileUpload?.type.includes('image') && (
            <img className="preview-img" style={{ maxWidth }} src={preview as string} />
          )}
          {fileUpload?.type.includes('video') && (
            <video controls className="preview-img" style={{ maxWidth }} src={preview as string}>
              <source src={preview as string} />
            </video>
          )}
          {fileUpload?.type.includes('pdf') && (
            <embed
              type="application/pdf"
              className="preview-img"
              style={{ maxWidth }}
              src={fileUpload ? URL.createObjectURL(fileUpload) : (preview as string)}
            />
          )}
        </>
      );
    } else if (fileType) {
      return (
        <embed
          type={fileType}
          className="preview-img"
          style={{ maxWidth }}
          src={preview as string}
        />
      );
    }

    return isVideoLink(preview) ? (
      <video controls className="preview-img" style={{ maxWidth }} src={preview as string}>
        <source src={preview as string} />
      </video>
    ) : (
      <img className="preview-img" style={{ maxWidth }} src={preview as string} />
    );
  };

  const previewImageColumns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Hình ảnh',
      dataIndex: '',
      render: (row: IImage) => {
        return (
          <div className="product-image" style={{ maxWidth }}>
            <img src={row[imageSize]} alt={row.title} style={{ height: '100%' }} />
          </div>
        );
      },
    },
    ...(imageLifestyle
      ? [
          {
            title: 'Hình khi hover',
            dataIndex: '',
            render: (row: IImage) => {
              return (
                <Radio
                  disabled={row.property === 'main'}
                  checked={selectedSecondImage === row.id}
                  onChange={() => onSelectSecondImage(row)}
                />
              );
            },
          },
        ]
      : []),
    ...(isSelectLifestyleImg
      ? [
          {
            title: 'Hình LifeStyle',
            dataIndex: '',
            render: (row: IImage) => {
              return (
                <Checkbox
                  onChange={(e) => onSelectLifestyleImg(e, row.id)}
                  checked={lifestyleImg.includes(row.id)}
                />
              );
            },
          },
        ]
      : []),
    {
      title: 'Thao tác',
      dataIndex: '',
      align: AlignType.right,
      render: (row: IImage) => (
        <Button
          title="Xoá"
          icon={<DeleteOutlined rev={null} />}
          onClick={onDeleteMediaFile(row.id, 'image')}
        />
      ),
    },
  ];

  const previewFilecolumns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Files',
      dataIndex: '',
      render: (row: IPdfFile) => {
        return (
          <a target="_blank" rel="noreferrer" href={row.original}>
            {row.title}
          </a>
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      align: AlignType.right,
      render: (row: IPdfFile) => (
        <Button
          title="Xoá"
          icon={<DeleteOutlined rev={null} />}
          onClick={onDeleteMediaFile(row.id, 'file')}
        />
      ),
    },
  ];

  const handleRefreshImage = () => {
    setRefreshImg(true);
    setTimeout(() => {
      setRefreshImg(false);
    }, 1000);
  };

  return multiple ? (
    <div>
      <Upload.Dragger
        name="file"
        accept={fileAccept}
        beforeUpload={uploadApi}
        customRequest={() => true}
        multiple={multiple}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined rev={null} style={{ color: '#0e0e0e' }} />
        </p>
        <p className="ant-upload-text">{uploadText}</p>
      </Upload.Dragger>
      {description && (
        <div className="mt-3 mb-3 d-flex justify-content-between">
          {description}
          {!previewFiles && (
            <Button icon={<RedoOutlined rev={null} />} onClick={handleRefreshImage}>
              Refresh
            </Button>
          )}
        </div>
      )}
      {refreshImg && (
        <div className="loading-wrapper">
          <Spin />
        </div>
      )}
      {!isEmpty(preview) && !refreshImg && isEmpty(previewFiles) && (
        <SortableTable
          columns={previewImageColumns}
          dataList={preview}
          rowKey="id"
          onChanged={onChangeImagePosition}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedMainImage,
            onChange: onSelectMainImage,
          }}
          scroll={{ y: 300 }}
        />
      )}
      {!isEmpty(previewFiles) && (
        <Table
          columns={previewFilecolumns}
          dataSource={previewFiles as any}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      )}
    </div>
  ) : preview ? (
    <div className={'preview-image-wrapper preview-image-' + typeId}>
      <div className="preview-image" style={{ maxWidth }}>
        <Button
          title="Xóa hình"
          className="delete-icon"
          onClick={handleDeleteImage}
          icon={<DeleteOutlined rev={null} />}
        />
        {renderReviewContent()}
      </div>
    </div>
  ) : (
    <>
      <Upload.Dragger
        name="file"
        accept={fileAccept}
        beforeUpload={uploadApi}
        customRequest={() => true}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined rev={null} style={{ color: '#0e0e0e' }} />
        </p>
        <p className="ant-upload-text">{uploadText}</p>
      </Upload.Dragger>
      {description && <div className="mt-3 mb-3">{description}</div>}
    </>
  );
};
