/* eslint-disable @typescript-eslint/no-use-before-define */
import { PlusOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useFetchCoupons } from 'hooks/useFetchCoupons';
import { IHomeBlock } from 'interfaces/home-block.interface';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';

import './styles.scss';
import { confirmModal } from 'utils/modals';
import { CouponStatus } from 'constants/enum';
import { AddCouponModal } from './AddCouponModal';
import { CouponsTable } from './CouponsTable';

interface Props {
  blockAttributes: IHomeBlock['attributes'];
  onChange: (newAttributes: IHomeBlock['attributes']) => void;
}

export const CouponsTemplate: React.FC<Props> = (props) => {
  const { blockAttributes, onChange } = props;

  const { data } = blockAttributes || {};
  const parsedData = data ? JSON.parse(data) : [];

  const { isFetchingCoupons, couponList, fetchCoupons, reset } = useFetchCoupons();

  const [isOpenAddCouponModal, setIsOpenCouponModal] = useState<boolean>(false);

  useEffect(() => {
    !couponList?.length && parsedData?.length && _fetchCoupons(parsedData);
  }, []);

  const _fetchCoupons = useCallback(
    async (couponIds?: string[]): Promise<void> => {
      try {
        if (!couponIds?.length) {
          reset();
          return;
        }
        await fetchCoupons({
          perPage: 'all',
          status: CouponStatus.active,
          ...queryString.parse(
            queryString.stringify(
              {
                ids: [...(couponIds || [])],
              },
              { skipNull: true, skipEmptyString: true, arrayFormat: 'bracket' },
            ),
            {
              arrayFormat: 'bracket',
            },
          ),
        });
      } catch (error: any) {
        message.error(error?.message || error);
      }
    },
    [data, fetchCoupons, reset],
  );

  const _onChange = useCallback(
    (value: string | string[]) => {
      onChange({ ...blockAttributes, data: JSON.stringify(value) });
    },
    [onChange, blockAttributes],
  );

  return (
    <div className="product-list-template">
      <div className="product-list-template__toolbar">
        <div className="toolbar-title">DANH SÁCH COUPON</div>
        <div className="toolbar-action-button">
          <Button icon={<PlusOutlined />} onClick={() => setIsOpenCouponModal(true)}>
            Thêm coupon
          </Button>
        </div>
      </div>
      <div>
        <CouponsTable
          loading={isFetchingCoupons}
          data={couponList}
          pagination={null}
          onChangePosition={(newData) => _onChange(newData?.map((p) => p.id))}
          onRemove={(id: string) => {
            confirmModal({
              title: 'Bạn có chắc muốn xoá coupon này?',
              buttonText: 'Xoá',
              onSaveCb: async () => {
                const newData = parsedData?.filter((s: string) => s != id);
                await _fetchCoupons(newData);
                _onChange(newData);
                message.success('Đã xoá coupon');
              },
            });
          }}
        />
      </div>

      <AddCouponModal
        isOpen={isOpenAddCouponModal}
        initialCouponIds={parsedData || []}
        onClose={() => setIsOpenCouponModal(false)}
        onChange={async (couponIds) => {
          const newData = [...couponIds, ...parsedData];
          _onChange(newData);
          setIsOpenCouponModal(false);
          await _fetchCoupons(newData);
        }}
      />
    </div>
  );
};
