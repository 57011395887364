import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col, Radio } from 'antd';
import { clone } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

interface MenuBlockInterface {
  dataMenuItems?: any[];
  headingTitle?: string;
  blockId: string;
  onUpdateMenu: (data: any) => void;
  maxCount?: number;
}

export const MenuBlock: FC<MenuBlockInterface> = ({
  dataMenuItems = [],
  headingTitle,
  onUpdateMenu,
  blockId,
  maxCount = 999,
}) => {
  const [menuItems, setMenuItems] = useState(dataMenuItems);
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(headingTitle);
  }, [headingTitle]);

  const handleAddMoreMenuItem = useCallback(() => {
    const tempMenu = clone(menuItems);
    tempMenu.unshift({
      id: uuid(),
      title: ``,
      link: '/',
      target: 'not',
    });
    setMenuItems(tempMenu);
    onUpdateMenu({
      headingTitle: title,
      menuItems: tempMenu,
    });
  }, [menuItems, onUpdateMenu, headingTitle]);

  const handleDeleteSlider = useCallback(
    (id: number) => () => {
      const tempMenu = clone(menuItems);
      const selectedMenu = tempMenu.find((item) => id == item.id);
      const selectedMenuIndex = tempMenu.indexOf(selectedMenu);
      if (selectedMenuIndex > -1) {
        tempMenu.splice(selectedMenuIndex, 1);
      }
      setMenuItems(tempMenu);
      onUpdateMenu({
        headingTitle: title,
        menuItems: tempMenu,
      });
    },
    [menuItems, onUpdateMenu, headingTitle],
  );

  const handleChangeValue = useCallback(
    (id: string, key: string) => (e) => {
      const tempMenu = clone(menuItems);
      const curMenu = tempMenu.find((item) => item.id === id);
      const index = tempMenu.indexOf(curMenu);
      switch (key) {
        case 'title':
          curMenu.title = e.target.value;
          break;
        case 'link':
          curMenu.link = e.target.value;
          break;
        case 'target':
          curMenu.target = e.target.value;
          break;
        default:
          break;
      }
      if (index > -1) {
        tempMenu.splice(index, 1, curMenu);
      }
      setMenuItems(tempMenu);
      onUpdateMenu({
        headingTitle: title,
        menuItems: tempMenu,
      });
    },
    [menuItems, onUpdateMenu, headingTitle],
  );

  const handleChangeHeadingTitle = useCallback(
    () => (e) => {
      onUpdateMenu({
        headingTitle: e.target.value,
        menuItems: menuItems,
      });
      setTitle(e.target.value);
    },
    [menuItems, onUpdateMenu, headingTitle],
  );

  return (
    <>
      <Row>
        <Col span={8}>
          <Form.Item label="Heading Title">
            <Input onChange={handleChangeHeadingTitle()} value={title} />
          </Form.Item>
        </Col>
      </Row>
      {menuItems?.length < maxCount && (
        <div className="text-right">
          <Button className="mb-3" onClick={handleAddMoreMenuItem} icon={<PlusOutlined />}>
            Thêm menu item
          </Button>
        </div>
      )}
      {menuItems?.map((item, idx) => (
        <Row gutter={16} className="mt-3 mb-3" key={blockId + '-' + item.id + '-' + idx}>
          <Col span={8}>
            <Form.Item label="Text">
              <Input value={item.title} onChange={handleChangeValue(item.id, 'title')} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Link">
              <Input value={item.link} onChange={handleChangeValue(item.id, 'link')} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Target">
              <Radio.Group value={item.target} onChange={handleChangeValue(item.id, 'target')}>
                <Radio value="_blank">_blank</Radio>
                <Radio value="not">not</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col className="text-right" span={24}>
            <Button
              className="mt-2"
              icon={<DeleteOutlined />}
              onClick={handleDeleteSlider(item.id)}
            >
              Xóa
            </Button>
          </Col>
        </Row>
      ))}
    </>
  );
};
