import { PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Tag } from 'antd';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AlignType } from 'constants/enum';
import { IImage } from 'interfaces/image.interface';
import { IProduct, IProductAttribute } from 'interfaces/product.interface';
import BaseProductsTable, {
  BrandLogoCell,
} from 'pages/Products/ProductDetail/components/BaseProductsTable/BaseProductsTable';
import { AppState } from 'redux/store';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';

import { renderColorStatus } from 'utils/renderColorStatus';
import { addVnCurrency, getCurrentPrice, getOldPrice } from 'utils/utils';

import './SearchedTable.scss';

interface Props {
  loading?: boolean;
  data?: IProduct[];
  rowClassName?: string | ((record: IProduct, index: number) => string);
  onSelectProduct: (product: IProduct) => void;
}

const poroductState = (state: AppState) => state.productReducers;

export const SearchedTable: FC<Props> = ({ data, loading, rowClassName, onSelectProduct }) => {
  const { subStatuses, productDetail } = useSelector(poroductState);

  const handleSelectProduct = useCallback(
    (row: IProduct) => () => {
      onSelectProduct(row);
    },
    [onSelectProduct],
  );

  const columns = [
    {
      title: 'Hình ảnh',
      dataIndex: 'attributes',
      width: 70,
      render: (attr: IProductAttribute) => {
        const imageValues = attr?.attributeValues?.find((item) => item.attributeCode === 'image');
        const image = imageValues?.value.find((item: IImage) => item.property === 'main');

        return (
          <div className="product-image">
            <BrandLogoCell image={image?.['128x128']} />
          </div>
        );
      },
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'attributes',
      width: 180,
      render: (attr: IProductAttribute) => (
        <>
          <SanitizeHtml
            rawHtml={`<strong>${attr.name}</strong>`}
            className="product-name"
            title={attr.name}
          />
          <div className="product-name" title={attr.sku}>{`SKU: ${attr.sku}`}</div>
          <div className="product-name" title={attr?.type}>
            {`Type: ${attr?.type}`}
          </div>
        </>
      ),
    },
    {
      title: 'Giá',
      dataIndex: 'attributes',
      width: 120,
      render: (attr: IProductAttribute) => {
        const currentPrice = attr.attributeValues.find(
          (item) => item.attributeCode === 'original_price',
        );
        const salePrice = attr.attributeValues.find((item) => item.attributeCode === 'sale_price');
        const newPrice = addVnCurrency(getCurrentPrice(currentPrice?.value, salePrice?.value));
        const oldPrice = addVnCurrency(getOldPrice(currentPrice?.value, salePrice?.value));

        return (
          <>
            <div className="product-price">{newPrice}</div>
            <div className={oldPrice !== '-' ? 'old-price' : ''}>{oldPrice}</div>
          </>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'attributes',
      width: 180,
      render: (attr: IProductAttribute) => {
        const status = subStatuses?.find((item) => item.id === attr?.statusId?.toString());
        return (
          <div className="text-center">
            {status && (
              <Tag
                color={renderColorStatus(status?.attributes?.parentId)}
                style={{ whiteSpace: 'pre-line' }}
              >
                {status?.attributes?.name}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      width: 70,
      fixed: 'right',
      align: AlignType.right,
      render: (row: IProduct) => (
        <Button title="Thêm" icon={<PlusOutlined />} onClick={handleSelectProduct(row)} />
      ),
    },
  ];

  return (
    <>
      {loading && (
        <div className="loading-wrapper">
          <Spin />
        </div>
      )}
      <div className={`${loading ? 'hidden' : 'visible'}`}>
        <BaseProductsTable
          className="searched-table"
          sortable={false}
          showHeader={false}
          rowClassName={rowClassName}
          columns={columns}
          dataList={data.filter((item) => item.id !== productDetail?.id)}
        />
      </div>
    </>
  );
};
