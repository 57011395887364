import { EyeFilled, LinkOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { FC } from 'react';

import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { DateFormat } from 'constants/enum';

interface BaseCommentProps {
  name: string;
  comment: string;
  createdAt: string;
  url?: string;
  hideUrl?: boolean;
  showName?: boolean;
  isAdmin?: boolean;
  onFindByUrl?: (selectedUrl: string) => void;
}

export const BaseComment: FC<BaseCommentProps> = ({
  name,
  comment,
  createdAt,
  url = '',
  hideUrl = false,
  showName = false,
  isAdmin = false,
  onFindByUrl = null,
}) => {
  const handleFindByUrl = () => {
    onFindByUrl && onFindByUrl(url);
  };

  return (
    <div className="base-comment-wrapper">
      <div className="comment-time">
        <ClockCircleOutlined />
        &nbsp;
        {moment(createdAt).format(DateFormat.clientSideWithTime)}
        {showName && (
          <span className={`commentor-name ${isAdmin ? 'admin-name' : ''}`}>{name}</span>
        )}
      </div>
      <div className="comment-text">
        <SanitizeHtml
          allowedTags={['a', 'img']}
          allowedAttributes={{ a: ['href'], img: ['src'] }}
          rawHtml={comment}
        />
      </div>
      {!hideUrl && url && (
        <div className="comment-url">
          <LinkOutlined />
          &nbsp;URL&nbsp;&nbsp;&nbsp;
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
          &nbsp; &nbsp;
          <EyeFilled
            style={{ cursor: 'pointer', fontSize: 16, color: '#000' }}
            onClick={handleFindByUrl}
          />
        </div>
      )}
    </div>
  );
};
