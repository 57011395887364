import React, { CSSProperties, useEffect, useState } from 'react';
import posed from 'react-pose';
import { NavLink, withRouter } from 'react-router-dom';

import { IMenuItem, IMenuItemSub } from 'interfaces/menu.interface';
import className from 'utils/classNames';
import { hasParams } from 'utils/utils';

const Sub = posed.div({
  closed: { height: 0, opacity: 0, overflow: 'hidden' },
  open: { height: 'auto', opacity: 1 },
  transition: { ease: 'ease-in-out', duration: 250 },
});

const haveActive = (sub: IMenuItemSub[], location: Location): boolean => {
  const isActive = (item: IMenuItemSub) => item.routing === location.pathname.split('/')[1];
  return !!sub.find(isActive);
};

interface WithSubProps extends IMenuItem {
  color?: string;
  contrast: string;
  accentColor?: string;
  accentContrast?: string;
}

const WithSub: React.FunctionComponent<WithSubProps | any> = (props) => {
  const { icon, title, sub, location, color, accentColor, contrast } = props;

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(haveActive(sub, location));
  }, [location]);

  const itemClasses = className({
    'menu-item': true,
    'has-sub': true,
    active: opened,
  });

  const subItemClass = (routing: string) =>
    className({
      'menu-item': true,
      active: routing === location.pathname.substring(1),
    });

  const subLinkStyles: CSSProperties = {
    color: color,
  };

  const activeSubLinkStyle: CSSProperties = {
    color: accentColor,
    fontWeight: 'bold',
  };

  const handleSubItemClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpened(true);
  };

  const handleClick = () => {
    setOpened(!opened);
  };

  const itemSub = sub.map((item, i) => (
    <li style={{ color }} className={subItemClass(item.routing)} key={i}>
      <NavLink
        to={`/${hasParams(item.routing, item.params)}`}
        className="item-link"
        activeClassName="active"
        style={subLinkStyles}
        activeStyle={activeSubLinkStyle}
        onClick={handleSubItemClick}
        replace
      >
        <span className="link-text">{item.title}</span>
      </NavLink>
    </li>
  ));

  const itemIcon = icon && (
    <span
      className={`link-icon ${icon.class}`}
      style={{ backgroundColor: icon.bg, color: color || icon.color }}
    />
  );

  return (
    <li className={itemClasses} onClick={handleClick}>
      <span className="item-link">
        {itemIcon}
        <span className="link-text" style={{ color }}>
          {title}
        </span>
        <span style={{ color }} className="link-caret icofont-rounded-right" />
      </span>
      {!(window.innerWidth >= 992) ? (
        <ul
          style={{ backgroundColor: contrast }}
          className="sub"
          onClick={(e) => e.stopPropagation()}
        >
          {itemSub}
        </ul>
      ) : (
        <Sub
          style={{ transform: 'translateY(5px)' }}
          onClick={(e) => e.stopPropagation()}
          pose={opened ? 'open' : 'closed'}
        >
          <ul className="sub">{itemSub}</ul>
        </Sub>
      )}
    </li>
  );
};

WithSub.defaultProps = {
  active: false,
};

export default withRouter(WithSub);
