import { Button, Col, Input, Row } from 'antd';
import React, { ChangeEvent, memo } from 'react';

interface Props {
  searchValue: string;
  onSearchChange: (text: string) => void;
  onSearchEnter: () => void;
  onFilter: () => void;
  onClearFilter: () => void;
}

export const TableFilterView: React.FC<Props> = memo((props) => {
  const { searchValue, onSearchChange, onSearchEnter, onFilter, onClearFilter } = props;

  return (
    <Row gutter={16}>
      <Col span={19}>
        <Input
          placeholder="Nhập tên tiêu đề"
          onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)}
          value={searchValue}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13) {
              onSearchEnter();
            }
          }}
        />
      </Col>
      <Col span={2}>
        <Button className="secondary-button" onClick={onFilter}>
          Filter
        </Button>
      </Col>
      <Col span={2} className="ml-3">
        <Button onClick={onClearFilter}>Clear</Button>
      </Col>
    </Row>
  );
});
