import { Card, Input } from 'antd';
import React, { useCallback, ChangeEvent, useState } from 'react';

interface FilterGroupSizeProps {
  searchValue?: string;
  onFilterChange?: (string) => void;
}

const FilterGroupSize: React.FC<FilterGroupSizeProps> = (props) => {
  const { searchValue, onFilterChange } = props;
  const [searchVal, setSearchVal] = useState(searchValue);

  const onFilterSizeGroup = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setSearchVal(val);
    onFilterChange && onFilterChange(val);
  }, []);

  return (
    <Card>
      <Input value={searchVal} placeholder="Tìm theo tên nhóm size" onChange={onFilterSizeGroup} />
    </Card>
  );
};

export default FilterGroupSize;
