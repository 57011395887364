import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Card, Input, Form, Switch, Button, Select } from 'antd';
import { clone, sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { API_GET_POST_OS, API_PUT_DELETE_OS } from 'constants/api/os.api';
import { IOs, IOsAttribute } from 'interfaces/os.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { OsModel } from 'models/os.model';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { TreeView } from 'ui/components/MaiNguyen/TreeView';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { deleteModal } from 'utils/modals';
import { convertToTreeLevel, showErrorMsg } from 'utils/utils';

export default function OSGroupPage() {
  const newOs = new OsModel({});

  const [osData, setOsData] = useState<IOs[]>([]);
  const [currentOs, setCurrentOs] = useState<IOsAttribute>(newOs);
  const [osId, setOsId] = useState('');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [reloadList, setReloadList] = useState(true);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (reloadList) {
      form.setFieldsValue(newOs);
      getPageData(`${API_GET_POST_OS}?perPage=all`, dispatch).then((res) => {
        const os = clone(convertToTreeLevel(res?.data));
        os.forEach((item) => {
          item.title = <strong>{item.attributes.name}</strong>;
          item.children = sortBy(item.children, 'attributes.position');
        });
        setOsData(os as IOs[]);
      });
      setReloadList(false);
      dispatch(setIsEditing(false));
    }
  }, [reloadList]);

  const handleAddNew = useCallback(() => {
    setOsId('');
    setCurrentOs(newOs);
    form.setFieldsValue(newOs);
    setSelectedKeys([]);
    dispatch(setIsEditing(false));
  }, []);

  const handleSelectLeaf = useCallback(
    (node) => {
      dispatch(setIsEditing(false));
      const { attributes, selected, id } = node;
      if (!selected) {
        const osModel = new OsModel(attributes);
        setOsId(id);
        form.setFieldsValue(osModel);
        setCurrentOs(osModel);
      } else {
        handleAddNew();
      }
    },
    [handleAddNew],
  );

  const handleValuesChange = useCallback((_, values) => {
    const osModel = new OsModel(values);
    setCurrentOs(osModel);
    form.setFieldsValue(osModel);
    dispatch(setIsEditing(true));
  }, []);

  const handleSelectedTreeKeys = useCallback((keys) => {
    setSelectedKeys(keys);
  }, []);

  const handleSaveOs = useCallback(async () => {
    try {
      if (osId) {
        await api.put({ endpoint: API_PUT_DELETE_OS.replace('{id}', osId), data: currentOs });
        setReloadList(true);
        setOsId(osId);
        setCurrentOs(currentOs);
        form.setFieldsValue(currentOs);
        setSelectedKeys([osId]);
      } else {
        await api.post({ endpoint: API_GET_POST_OS, data: currentOs }).then((res) => {
          setReloadList(true);
          setOsId(res?.data?.id);
          setCurrentOs(currentOs);
          form.setFieldsValue(currentOs);
          setSelectedKeys([res?.data?.id]);
        });
      }
    } catch (err) {
      showErrorMsg(err);
    }
  }, [currentOs, osId]);

  const handleDeleteOs = useCallback(() => {
    deleteModal({
      title: `Bạn chắc chắn xóa hệ điều hành: <strong>${currentOs.name}</strong> này?`,
      onDeleteCb: async () => {
        try {
          await api.remove({ endpoint: API_PUT_DELETE_OS.replace('{id}', osId) });
          setReloadList(true);
          handleAddNew();
        } catch (err) {
          showErrorMsg(err);
        }
      },
    });
  }, [osId, currentOs, handleAddNew]);

  const handleDropContent = useCallback(async (parentId: number, dragNode, position: number) => {
    const { attributes, id } = dragNode;
    const dragOs = new OsModel({ ...attributes, position, parentId });
    try {
      await api.put({ endpoint: API_PUT_DELETE_OS.replace('{id}', id), data: dragOs });
      setReloadList(true);
    } catch (err) {
      showErrorMsg(err);
      setReloadList(true);
    }
  }, []);

  return (
    <LayoutDetail
      pageTitle="Hệ điều hành"
      breadcrumbs={[
        {
          title: 'Hệ điều hành',
          route: '/he-dieu-hanh',
        },
      ]}
      isAddNew={!!osId}
      handleClickAddButton={handleAddNew}
      wrapperCard={false}
      formHook={form}
      handleValuesChange={handleValuesChange}
      handleSave={handleSaveOs}
      showSaveButton={false}
    >
      <Row gutter={8}>
        <Col span={8}>
          <Card className="ant-card-with-scroll">
            <TreeView
              treeData={osData}
              handleSelectLeaf={handleSelectLeaf}
              maxDropLevel={2}
              handleSelecteKeys={handleSelectedTreeKeys}
              handleDropContent={handleDropContent}
              selectedKeys={selectedKeys}
            />
          </Card>
        </Col>
        <Col span={16}>
          <Card
            title={<Typography.Title level={3}>{osId ? 'Chi tiết' : 'Tạo mới'}</Typography.Title>}
          >
            <Form.Item
              label="Hệ điều hành"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập hệ điều hành',
                },
              ]}
            >
              <Input placeholder="Hệ điều hành" />
            </Form.Item>
            <Form.Item label="HĐH cha" name="parentId">
              <Select className="ant-select-lg" showSearch optionFilterProp="children" allowClear>
                {osData.map((item) => (
                  <Select.Option key={item.id} value={parseInt(item.id)}>
                    {item.attributes.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Position" name="position">
              <Input placeholder="Position" type="number" />
            </Form.Item>
            <Form.Item label="Hiển thị" name="active" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Col span={24} style={{ textAlign: 'right' }}>
              {osId && (
                <Button onClick={handleDeleteOs} icon={<DeleteOutlined />}>
                  Xóa
                </Button>
              )}
              &nbsp; &nbsp;
              <ThemeButton htmlType="submit">Lưu</ThemeButton>
            </Col>
          </Card>
        </Col>
      </Row>
    </LayoutDetail>
  );
}
