import { Form, Input, Row, Col, Select, Button } from 'antd';
import React from 'react';

// Hook
import { FormFilterProduct, useFilterSizeItems } from 'hooks/useSizeItems';

// Models
import { ISizeGroup } from 'interfaces/size.interface';

// Components
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';

interface FiltetSizeItemsProps {
  sizeGroups?: ISizeGroup[];
  formFilter?: FormFilterProduct;
  loading?: boolean;
  onFilterChange?: (val) => void;
}

const FiltetSizeItems: React.FC<FiltetSizeItemsProps> = (props) => {
  const { sizeGroups, formFilter, loading, onFilterChange } = props;

  // hook
  const { frmFilterSizeItems, submitFilter, clearFilter } = useFilterSizeItems(
    formFilter,
    onFilterChange,
  );

  return (
    <Form
      className="form-size-filter"
      layout="vertical"
      form={frmFilterSizeItems}
      onFinish={submitFilter}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Tìm theo tên size" name="title">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Chọn nhóm size" name="sizeGroupId">
            <Select showSearch loading={loading} optionFilterProp="title" listHeight={450}>
              {sizeGroups.map((item) => {
                const { id, attributes } = item;
                return (
                  <Select.Option value={parseInt(id)} key={id} title={attributes.title}>
                    <SanitizeHtml rawHtml={attributes.title} />
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={8}
          className="align-items-center justify-content-end mt-4"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button htmlType="submit" className="secondary-button">
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={clearFilter}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FiltetSizeItems;
