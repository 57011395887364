/* eslint-disable import/order */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Switch } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// constants
import { defaultPagination } from 'constants/constants';
import { AlignType, BrandImageType, MNStoreList } from 'constants/enum';

// Hook
import {
  useModalBrand,
  useGetBrands,
  useUploadImagesBrand,
  useFormBrand,
  useUpdateBrandContent,
} from 'hooks/useBrands';

// Interface
import { IBrand, IBrandAttribute } from 'interfaces/brand.interface';
import { IImage } from 'interfaces/image.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { BrandModel } from 'models/brand.model';
import { updatePageData } from 'redux/pages/actions';
import { setIsEditing } from 'redux/uiAction/actions';

// Components
import LayoutList from 'layouts/LayoutList/LayoutList';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import FilterBrands from './components/Filter/Filter';
import FormBrand from './components/Form/Form';

import './Brand.scss';

const newBrand = new BrandModel({});

const Brands: React.FC = () => {
  const [paging, setPaging] = useState<IPagination>(defaultPagination);

  // Filter
  const [filterName, setFilterName] = useState('');

  // Form
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // Hook: get list of brands
  const { brands, reloadList, pagination, getBrands } = useGetBrands(paging.current, filterName);
  // Hook: delete brand
  const { isOpen, modalTitle, onOpenModal, onCloseModal, changeModalTitle, deleteBrand } =
    useModalBrand(getBrands);
  // Hook: upload images brand
  const {
    brand,
    mainLogoPreviewImg,
    logoMenuPreviewImg,
    bannerPreviewImg,
    mainLogoFile,
    logoMenuFile,
    bannerFile,
    resetUploadImages,
    onUploadImage,
    onDeleteImage,
    submitImages,
    setDataBrand,
  } = useUploadImagesBrand();

  // Hook: update brand content
  const {
    urlPath,
    slugCode,
    description,
    changeDescription,
    resetContent,
    onChangeSlug,
    changeContent,
  } = useUpdateBrandContent();

  // Hook: Form Brand
  const { isLoadingForm, onSubmitForm } = useFormBrand(
    brand,
    description,
    slugCode,
    getBrands,
    onCloseModal,
    submitImages,
  );

  // loading page only first time
  useEffect(() => {
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
  }, []);

  // on change page
  const onChangePage = useCallback(
    (page: number) => {
      setPaging({ ...pagination, current: page });
    },
    [pagination],
  );

  // filter brands
  const doFilter = useCallback((val) => {
    setFilterName(val);
    onChangePage(1);
  }, []);

  // close modal brand
  const closeModal = useCallback(() => {
    onCloseModal();
    changeModalTitle('');

    dispatch(setIsEditing(false));
  }, []);

  // open modal add brand
  const addBrand = useCallback(() => {
    onOpenModal();
    changeModalTitle('Tạo mới');
    // reset form
    resetUploadImages();
    resetContent();

    form.setFieldsValue(newBrand);
    dispatch(setIsEditing(false));
  }, []);

  // open modal edit brand
  const editBrand = (rowData: IBrand) => () => {
    const { attributes } = rowData;

    setDataBrand(rowData);
    changeContent(rowData);
    onOpenModal();
    changeModalTitle(`Chỉnh sửa: ${attributes.name}`);
    form.setFieldsValue({
      ...attributes,
      storeIds: attributes.storeIds ? attributes.storeIds : [], // if storeIds is null and then set storeIds = []
    });
  };

  // submit form
  const submitForm = useCallback(() => {
    onSubmitForm(form.getFieldsValue());
  }, [form, description, slugCode, mainLogoFile, logoMenuFile, bannerFile]);

  // config table
  const brandsTable = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Thương hiệu',
      dataIndex: 'attributes',
      render: (attr: IBrandAttribute, rowBrand: IBrand) => (
        <div
          onClick={editBrand(rowBrand)}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          <SanitizeHtml rawHtml={attr.name} />
        </div>
      ),
    },
    {
      title: 'Logo',
      dataIndex: 'attributes',
      render: (attr: IBrandAttribute, rowData: IBrand) => {
        const mainLogo = attr.logo.find((item: IImage) => item.property === BrandImageType.logo);

        return mainLogo ? (
          <span className="brand-logo" onClick={editBrand(rowData)}>
            <img src={mainLogo['128x128']} alt={attr.name} />
          </span>
        ) : (
          <span>Không có hình</span>
        );
      },
    },
    {
      title: 'Logo B/W',
      dataIndex: 'attributes',
      render: (attr: IBrandAttribute, rowData: IBrand) => {
        const logoMenu = attr.logo.find(
          (item: IImage) => item.property === BrandImageType.logoMenu,
        );

        return logoMenu ? (
          <span className="brand-logo" onClick={editBrand(rowData)}>
            <img src={logoMenu['128x128']} alt={attr.name} />
          </span>
        ) : (
          <span>Không có hình</span>
        );
      },
    },
    {
      title: 'Cửa hàng',
      dataIndex: 'attributes',
      render: (attr: IBrandAttribute) => {
        const { storeIds } = attr;
        const arrStores = [];

        MNStoreList.map((store) => {
          storeIds?.map((item) => {
            if (item === store.id) {
              arrStores.push(store.title);
              return (attr['stores'] = arrStores);
            }
          });
        });

        return <SanitizeHtml rawHtml={(attr['stores'] && attr['stores'].join(', ')) || '-'} />;
      },
    },
    {
      title: 'Hiển thị',
      dataIndex: 'attributes',
      render: (attr: IBrandAttribute, rowData: IBrand) => (
        <Switch checked={attr.active} onChange={() => deleteBrand(rowData.id)} />
      ),
    },
    {
      title: '',
      dataIndex: 'attributes',
      align: AlignType.right,
      render: (_, rowData: IBrand) => (
        <Button title="Xóa" icon={<DeleteOutlined />} onClick={() => deleteBrand(rowData.id)} />
      ),
    },
  ];

  return (
    <>
      <LayoutList
        pageTitle="Thương hiệu"
        breadcrumbs={[
          {
            title: 'Thương hiệu',
            route: '/thuong-hieu',
          },
        ]}
        showAddButton
        handleClickAddButton={addBrand}
        columns={brandsTable}
        dataList={brands}
        tableClass="brands-table"
        pagination={pagination}
        handleChangePage={onChangePage}
        topPagination
        loadingList={reloadList}
        filterNode={<FilterBrands onFilter={doFilter} />}
      />

      <CustomPopup
        title={modalTitle}
        isOpen={isOpen}
        formHook={form}
        showDeleteButton={!!brand.id}
        onCloseModal={closeModal}
        handleSaveButton={submitForm}
        handleValuesChange={onChangeSlug}
        handleDeleteButton={() => deleteBrand(brand.id)}
        isSaving={isLoadingForm}
      >
        <FormBrand
          urlPath={urlPath}
          slugCode={slugCode}
          mainLogoPreviewImg={mainLogoPreviewImg}
          logoMenuPreviewImg={logoMenuPreviewImg}
          bannerPreviewImg={bannerPreviewImg}
          description={description}
          brand={brand}
          onChangeEditor={changeDescription}
          onUploadImage={onUploadImage}
          onDeleteImage={onDeleteImage}
        />
      </CustomPopup>
    </>
  );
};

export default Brands;
