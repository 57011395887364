import { MenuOutlined } from '@ant-design/icons';
import { SpinProps, Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import arrayMove from 'array-move';
import { isEmpty } from 'lodash';
import { FC, useCallback } from 'react';
import { useState, useEffect } from 'react';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
  SortableHandle as sortableHandle,
} from 'react-sortable-hoc';

import './SortableTable.scss';

interface SortableTableInterface {
  className?: string;
  columns: any[];
  dataList: any[];
  rowKey: string;
  editableCell?: any;
  onChanged?: (newDataList: any[]) => void;
  scroll?: {
    x?: string | number | true;
    y?: string | number;
  };
  rowSelection?: TableRowSelection<any>;
  loading?: boolean | SpinProps;
}

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const defaultColumn = [
  {
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
];

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const SortableTable: FC<SortableTableInterface> = ({
  className,
  columns,
  dataList,
  rowKey,
  onChanged,
  scroll = {},
  rowSelection = {},
  loading = false,
  editableCell = null,
}) => {
  const [dataSource, setDataSource] = useState<any[]>([]);

  useEffect(() => {
    setDataSource(dataList);
  }, [dataList]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter((el) => !!el);
        setDataSource(newData);
        onChanged && onChanged(newData);
      }
    },
    [dataSource, onChanged],
  );

  function DraggableContainer(props) {
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  }

  function DraggableBodyRow({ ...restProps }) {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x[rowKey] === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }

  return (
    <Table
      className={`sortable-table ${className}`}
      pagination={false}
      dataSource={dataSource}
      columns={[...defaultColumn, ...columns]}
      rowKey={rowKey}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
          cell: editableCell,
        },
      }}
      rowSelection={isEmpty(rowSelection) ? null : rowSelection}
      scroll={scroll}
      loading={loading}
    />
  );
};
