import { Select } from 'antd';

interface SortProps {
  defaultValue?: string;
  sortList?: { label?: string; value?: string; key?: string }[];
  onChange?: (sort: string) => void;
}

const Sort: React.FC<SortProps> = ({ sortList, defaultValue, onChange }) => {
  return (
    <Select value={defaultValue} onChange={onChange} listHeight={300}>
      {sortList.map((o) => {
        return (
          <Select.Option key={o.value} value={o.value}>
            {o.label}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default Sort;
