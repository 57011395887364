import { OrderAttrs } from 'interfaces/order.interface';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml/SanitizeHtml';

interface OrderNotesProps {
  data: OrderAttrs;
}

const OrderNotes: React.FC<OrderNotesProps> = ({ data }) => {
  const { notes } = data || {};

  return (
    <div className="order-note">
      <p>
        <b>GHI CHÚ KHI GIAO HÀNG</b>
      </p>
      <SanitizeHtml rawHtml={notes} />
    </div>
  );
};

export default OrderNotes;
