import { SET_RELOAD_ALL_REVIEWS, ReviewAction } from './types';

type InitStateType = {
  reloadAllReviews: boolean;
};

const initState: InitStateType = {
  reloadAllReviews: false,
};

export const reviewReducer = (state: InitStateType = initState, action: ReviewAction) => {
  switch (action.type) {
    case SET_RELOAD_ALL_REVIEWS: {
      return {
        ...state,
        reloadAllReviews: action.payload,
      };
    }
    default:
      return state;
  }
};
