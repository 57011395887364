import { Button, Col, Form, Input, Row, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { StoreIds } from 'constants/enum';

export interface FilterCollections {
  title: string;
  sku: string;
  storeId: string;
}

interface Props {
  onFilter: (filter: FilterCollections) => void;
}

export const FilterView: React.FC<Props> = (props) => {
  const { onFilter } = props;
  const [form] = Form.useForm();

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (!isEmpty(params)) {
      form.setFieldsValue(params);
    }
  }, [params]);

  const onClear = useCallback(() => {
    form.resetFields();
    onFilter({ title: '', sku: '', storeId: '' });
  }, [onFilter]);

  return (
    <Form layout="vertical" form={form} onFinish={onFilter}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Tên collection" name="title">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="SKU / Tên sản phẩm" name="sku">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Cửa hàng" name="storeId">
            <Select
              className="ant-select-lg"
              options={[
                { value: `${StoreIds.mainguyen}`, label: 'Mai Nguyen' },
                { value: `${StoreIds.rider}`, label: 'Mai Nguyen Rider' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col
          span={24}
          className="mt-4 align-items-center justify-content-end"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button htmlType="submit" className="secondary-button">
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={onClear}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
