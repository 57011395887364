import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { API_GET_POST_COLOR, API_PUT_DELETE_COLOR } from 'constants/api/system-color.api';
import { defaultPagination } from 'constants/constants';
import { AlignType } from 'constants/enum';
import { IImage } from 'interfaces/image.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { IColorAttribute, ISystemColor } from 'interfaces/system-color.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import { SystemColorModel } from 'models/system-color.model';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomModal } from 'ui/components/MaiNguyen/CustomModal';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { deleteModal } from 'utils/modals';
import { showErrorMsg, uploadFile } from 'utils/utils';

import './SystemColor.scss';

const SystemColor = () => {
  const newSystemColor = new SystemColorModel({});

  const [isOpen, setIsOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [systemColors, setSystemColors] = useState<ISystemColor[]>([]);
  const [systemColor, setSystemColor] = useState(newSystemColor);
  const [systemColorId, setSystemColorId] = useState('');
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  const [imgColorFile, setImgColorFile] = useState(null);
  const [previewImage, setPreviewImage] = useState<IImage>(null);
  const [loadingButton, setLoadingButton] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (firstLoad) {
      getPageData(`${API_GET_POST_COLOR}?page=1`, dispatch).then((res) => {
        const pagin = res?.meta?.pagination;
        setSystemColors(res?.data);
        setPagination({
          current: pagin.currentPage,
          pageSize: pagin.perPage,
          total: pagin.total,
        });
        setFirstLoad(false);
      });
    }
  }, [firstLoad]);

  useEffect(() => {
    if (reloadList) {
      api.get({ endpoint: `${API_GET_POST_COLOR}?page=${pagination.current}` }).then((res) => {
        setSystemColors(res?.data);
        setReloadList(false);
      });
    }
  }, [reloadList, pagination]);

  const handleClosePopup = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleAddColor = useCallback(() => {
    setIsOpen(true);
    setSystemColor(newSystemColor);
    setSystemColorId('');
    setImgColorFile(null);
    setPreviewImage(null);
    form.resetFields();
  }, []);

  const handleChangePage = useCallback(
    (page: number) => {
      const pagin = pagination;
      pagin.current = page;
      setPagination(pagin);
      setReloadList(true);
    },
    [pagination],
  );

  const handleEditColor = useCallback(
    (rowData: ISystemColor) => () => {
      const { attributes, id } = rowData;
      const currentColor = new SystemColorModel(attributes);
      setIsOpen(true);
      setSystemColorId(id);
      setSystemColor(currentColor);
      setPreviewImage(attributes.image);
      form.setFieldsValue(currentColor);
    },
    [],
  );

  const handleChangeValues = useCallback((_, values) => {
    const changedFields = new SystemColorModel(values);
    setSystemColor({ ...changedFields, position: parseInt(changedFields.position.toString()) });
    form.setFields([
      {
        name: 'name',
        errors: !changedFields.name ? ['Vui lòng nhập tên màu'] : [],
        value: changedFields.name,
      },
      {
        name: 'position',
        errors:
          parseInt(changedFields.position.toString()) <= 0 ? ['Vui lòng nhập vị trí màu'] : [],
        value: parseInt(changedFields.position.toString()),
      },
    ]);
  }, []);

  const handleUploadImage = useCallback((file: RcFile) => {
    setImgColorFile(file);
  }, []);

  const handleDeleteImageSuccess = useCallback(() => {
    setReloadList(true);
    setPreviewImage(null);
  }, []);

  const handleSaveSystemColor = useCallback(async () => {
    if (!systemColor.name && parseInt(systemColor.position.toString()) <= 0) {
      form.setFields([
        {
          name: 'name',
          errors: !systemColor.name ? ['Vui lòng nhập tên màu'] : [],
        },
        {
          name: 'position',
          errors:
            parseInt(systemColor.position.toString()) <= 0 ? ['Vui lòng nhập vị trí màu'] : [],
        },
      ]);
      return;
    }

    setLoadingButton(true);
    try {
      if (systemColorId) {
        await api
          .put({ endpoint: API_PUT_DELETE_COLOR.replace('{id}', systemColorId), data: systemColor })
          .then(async () => {
            await uploadFile(imgColorFile, 'color', systemColorId).then((attr) => {
              setPreviewImage({ ...attr?.resize, id: attr?.id });
            });
            setIsOpen(false);
            setReloadList(true);
            setLoadingButton(false);
          });
      } else {
        await api.post({ endpoint: API_GET_POST_COLOR, data: systemColor }).then(async (res) => {
          await uploadFile(imgColorFile, 'color', res?.data?.id).then((attr) => {
            setPreviewImage({ ...attr?.resize, id: attr?.id });
          });
          setIsOpen(false);
          setReloadList(true);
          setLoadingButton(false);
        });
      }
    } catch (err) {
      setLoadingButton(false);
      showErrorMsg(err);
    }
  }, [systemColorId, systemColor, imgColorFile]);

  const handleChangePosition = useCallback(
    (id: string) => async (pos: number) => {
      await api.put({
        endpoint: API_PUT_DELETE_COLOR.replace('{id}', id),
        data: { position: pos },
      });
      setReloadList(true);
    },
    [],
  );

  const handleDeleteColor = useCallback(
    (id: string) => () => {
      deleteModal({
        title: 'Bạn có muốn xóa màu này?',
        onDeleteCb: async () => {
          await api.remove({ endpoint: API_PUT_DELETE_COLOR.replace('{id}', id) });
          setReloadList(true);
        },
      });
    },
    [],
  );

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Image',
      dataIndex: 'attributes',
      render: (attr: IColorAttribute, rowData: ISystemColor) => (
        <div className="color-image" onClick={handleEditColor(rowData)}>
          <img src={attr.image['128x128']} width={64} height={64} />
        </div>
      ),
    },
    {
      title: 'Tên màu',
      dataIndex: 'attributes',
      render: (attr: IColorAttribute, rowData: ISystemColor) => (
        <div className="color-name" onClick={handleEditColor(rowData)}>
          {attr.name}
        </div>
      ),
    },
    {
      title: 'Vị trí',
      dataIndex: 'attributes',
      render: (attr: IColorAttribute, rowData: ISystemColor) => (
        <Select value={attr.position} onChange={handleChangePosition(rowData.id)}>
          {systemColors.map((_, index) => (
            <Select.Option key={index + 1} value={index + 1}>
              {index + 1}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: '',
      dataIndex: '',
      align: AlignType.right,
      render: (row: ISystemColor) => (
        <Button title="Xóa" icon={<DeleteOutlined />} onClick={handleDeleteColor(row.id)} />
      ),
    },
  ];

  return (
    <>
      <LayoutList
        pageTitle="Màu hệ thống"
        breadcrumbs={[
          {
            title: 'Màu hệ thống',
            route: '/mau-he-thong',
          },
        ]}
        columns={columns}
        dataList={systemColors}
        rowKey="id"
        showAddButton
        handleClickAddButton={handleAddColor}
        tableClass="system-color-page"
        sortableTable={false}
        pagination={pagination}
        loadingList={reloadList}
        handleChangePage={handleChangePage}
      />
      <CustomModal
        title="Thông tin màu"
        isOpen={isOpen}
        onCloseModal={handleClosePopup}
        onSave={handleSaveSystemColor}
        position="bottom"
        loadingButton={loadingButton}
      >
        <Form layout="vertical" form={form} onValuesChange={handleChangeValues}>
          <Form.Item label="Tên màu" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Vị trí" name="position">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Hình ảnh cho màu">
            <UploadFile
              maxWidth={64}
              handleUploadImage={handleUploadImage}
              previewImage={previewImage}
              handleDeleteImageSuccess={handleDeleteImageSuccess}
            />
          </Form.Item>
        </Form>
      </CustomModal>
    </>
  );
};

export default SystemColor;
