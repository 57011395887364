import { fetcherWithArg } from 'services/swr.utils';
import useSWRMutation from 'swr/mutation';
import { API_COUPONS } from 'constants/api/coupons.api';

export const useFetchCoupons = () => {
  const { isMutating, data, trigger, reset } = useSWRMutation(API_COUPONS, fetcherWithArg);

  return {
    isFetchingCoupons: isMutating,
    couponList: data?.data || [],
    fetchCoupons: trigger,
    reset,
  };
};
