import { Row, Col, Form } from 'antd';

// Import Components
import { usePresentBlocks } from 'hooks/usePresentBlocks';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { TemplateBlock } from 'ui/components/MaiNguyen/TemplateBlock';

import './Warranty.scss';

const WarrantyPresent = () => {
  const {
    reloadList,
    block,
    isOpen,
    handleClosePopup,
    handleOpenModalBlock,
    handleChangeValues,
    handleSaveBlock,
    handleChangeJsonData,
    handleDeleteImage,
    handleUploadFileContent,
  } = usePresentBlocks();

  const [form] = Form.useForm();

  return (
    <>
      <LayoutDetail
        pageTitle="Trình bày trang Thông tin bảo hành"
        breadcrumbs={[
          {
            title: 'Trình bày trang Thông tin bảo hành',
            route: '/trinh-bay-thong-tin-bao-hanh',
          },
        ]}
        isAddNew
        wrapperCard={false}
        handleValuesChange={null}
        formHook={null}
        pageClassName="warranty-present"
        saveLoading={reloadList}
      >
        <Row gutter={16}>
          <Col span={24}>
            <div
              className="block-item seo"
              onClick={handleOpenModalBlock('metaDataWarranty', 'metaData')}
            >
              Quản lý SEO (thông tin bảo hành)
            </div>
          </Col>
          <Col span={8}>
            <div
              className="block-item"
              onClick={handleOpenModalBlock('warrantyMenu', 'warrantyMenu')}
            >
              Menu thông tin bảo hành
            </div>
          </Col>
          <Col span={16}>
            <div
              className="block-item h-130"
              onClick={handleOpenModalBlock('warrantyGeneral', 'warrantyGeneral')}
            >
              QUY ĐỊNH CHUNG VỀ BẢO HÀNH CHÍNH HÃNG
            </div>
            <div
              className="block-item h-130"
              onClick={handleOpenModalBlock('warrantyTerms', 'warrantyTerms')}
            >
              ĐIỀU KIỆN BẢO HÀNH CHÍNH HÃNG
            </div>
            <div
              className="block-item h-130"
              onClick={handleOpenModalBlock('warrantyCenter', 'warrantyCenter')}
            >
              TRA CỨU TRUNG TÂM BẢO HÀNH CHÍNH HÃNG
            </div>
          </Col>
        </Row>
      </LayoutDetail>
      <CustomPopup
        title="Display Block"
        isOpen={isOpen}
        onCloseModal={handleClosePopup}
        handleSaveButton={handleSaveBlock}
        formHook={form}
        handleValuesChange={handleChangeValues}
      >
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name">
                <div>{block?.attributes?.name}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Position">
                <div>{block?.attributes?.position}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Template">
                <div>{block?.attributes?.template}</div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <TemplateBlock
                block={block}
                template={block?.attributes?.template}
                onChangeJsonData={handleChangeJsonData}
                onAddImage={handleUploadFileContent}
                onDeleteImage={handleDeleteImage}
              />
            </Col>
          </Row>
        </>
      </CustomPopup>
    </>
  );
};

export default WarrantyPresent;
