import { Col, Row } from 'antd';

// Constant
import { RiderBlockPositionTypes, RiderBlockTemplateTypes } from 'constants/enum';

interface SinglePageListProps {
  onOpenModalBlock?: (pos, temp) => void;
}

const SinglePageList: React.FC<SinglePageListProps> = (props) => {
  const { onOpenModalBlock } = props;

  return (
    <Row gutter={16}>
      {/* Cơ hội việc làm */}
      <Col span={12}>
        <div
          className="block-item"
          onClick={
            onOpenModalBlock(
              RiderBlockPositionTypes.riderSinglePgJobs,
              RiderBlockTemplateTypes.riderSinglePage,
            ) as any
          }
        >
          Cơ hội việc làm
        </div>
      </Col>
      {/* Hướng dẫn đặt hàng */}
      <Col span={12}>
        <div
          className="block-item"
          onClick={
            onOpenModalBlock(
              RiderBlockPositionTypes.riderSinglePgGuideOrder,
              RiderBlockTemplateTypes.riderSinglePage,
            ) as any
          }
        >
          Hướng dẫn đặt hàng
        </div>
      </Col>
      {/* Liên hệ */}
      <Col span={12}>
        <div
          className="block-item"
          onClick={
            onOpenModalBlock(
              RiderBlockPositionTypes.riderSinglePgContact,
              RiderBlockTemplateTypes.riderSinglePage,
            ) as any
          }
        >
          Liên lạc
        </div>
      </Col>
      {/* Youtube */}
      <Col span={12}>
        <div
          className="block-item"
          onClick={
            onOpenModalBlock(
              RiderBlockPositionTypes.riderSinglePgYoutube,
              RiderBlockTemplateTypes.riderSinglePage,
            ) as any
          }
        >
          Youtube Mainguyen Rider
        </div>
      </Col>
    </Row>
  );
};

export default SinglePageList;
