import { IMe } from 'interfaces/me.interface';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESSFULL = 'FETCH_USER_SUCCESSFULL';
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';

export interface FetchUser {
  type: typeof FETCH_USER;
}

export interface FetchUserSuccessful {
  type: typeof FETCH_USER_SUCCESSFULL;
  payload: IMe;
}

export interface FetchUserFailed {
  type: typeof FETCH_USER_FAILED;
}

export type ActionUserType = FetchUser | FetchUserSuccessful | FetchUserFailed;
