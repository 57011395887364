import { UserOutlined, LockOutlined, KeyOutlined } from '@ant-design/icons';
import { Alert, Form, Input } from 'antd';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from 'redux/store';
import { login } from 'services/auth.service';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { validateEmailAntInput, validatePasswordAntInput, validateAuthKeyInput } from 'utils/utils';

import './SignInForm.scss';

const authSelector = (state: AppState) => state.auth;

export const SingInForm: React.FC = () => {
  const { loading, errorMessage } = useSelector(authSelector);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authKey, setAuthKey] = useState('');
  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage) {
      setPassword('');
      setAuthKey('');
      form.setFieldsValue({
        password: '',
        authKey: '',
      });
      // set error message
      if (errorMessage['otp']) {
        if (errorMessage['otp'][0] === 'Invalid OTP.') {
          setErrMsg('Mã bảo mật không chính xác!');
        } else {
          setErrMsg('Vui lòng điền Mã bảo mật');
        }
      } else {
        setErrMsg('Email hoặc mật khẩu không chính xác, vui lòng thử lại!');
      }
    }
    setShowError(!!errorMessage);
  }, [errorMessage]);

  const handleLogin = useCallback(() => {
    dispatch(login({ email, password, authKey }));
  }, [email, password, authKey]);

  const handleCloseAlert = useCallback(() => {
    setShowError(!showError);
  }, [showError]);

  const handleChangeValue = useCallback(
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      switch (key) {
        case 'email':
          setEmail(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'authKey':
          setAuthKey(value);
          break;
        default:
          break;
      }
    },
    [],
  );

  return (
    <Form className="form-signin" style={{ width: '100%' }} onFinish={handleLogin} form={form}>
      {showError && (
        <Form.Item>
          <Alert message={errMsg} closable type="error" showIcon onClose={handleCloseAlert} />
        </Form.Item>
      )}
      <Form.Item
        name="email"
        rules={[
          () => ({
            validator(_, value: string) {
              return validateEmailAntInput(value);
            },
          }),
        ]}
      >
        <Input
          type="email"
          prefix={<UserOutlined />}
          placeholder="Email"
          value={email}
          onChange={handleChangeValue('email')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          () => ({
            validator(_, value: string) {
              return validatePasswordAntInput(value);
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Mật khẩu"
          value={password}
          onChange={handleChangeValue('password')}
        />
      </Form.Item>
      <Form.Item
        name="authKey"
        rules={[
          () => ({
            validator(_, value: string) {
              return validateAuthKeyInput(value);
            },
          }),
        ]}
      >
        <Input
          type="number"
          prefix={<KeyOutlined />}
          placeholder="Mã bảo mật"
          value={authKey}
          onChange={handleChangeValue('authKey')}
        />
      </Form.Item>
      <Form.Item>
        <div className="span d-flex">
          <ThemeButton block htmlType="submit" loading={loading}>
            Đăng Nhập
          </ThemeButton>
        </div>
      </Form.Item>
    </Form>
  );
};
