import { Switch, message } from 'antd';
import { IEventPage, IEventPageAttributes } from 'interfaces/event-page.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';

import './EventPage.scss';
import { useHistory } from 'react-router-dom';
import { confirmModal } from 'utils/modals';
import { DateFormat } from 'constants/enum';
import { CreateEventModal } from './components/CreateEventModal';
import { FilterEventPage, FilterView } from './components/FilterView';
import { useFetchEvents, useUpdateEventPage } from './hooks';

const EventPage: React.FC = () => {
  const history = useHistory();

  const { isFetching, data, pagination, setPagination, refetch: refetchEvents } = useFetchEvents();
  const { onUpdate } = useUpdateEventPage();

  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);

  /**
   * Handle the event of updating the coupon status.
   *
   * @param {string} id - The ID of the coupon to update its status.
   * @param {CouponStatus} status - The new status of the coupon.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onUpdateEventPageStatus = useCallback(
    async (id: string, publish: boolean): Promise<void> => {
      try {
        await onUpdate({ method: 'put', id, data: { publish } });
        message.success('Đã cập nhật event page thành công');
        await refetchEvents();
      } catch (error: any) {
        message.error(error?.message || error);
      }
    },
    [refetchEvents, onUpdate],
  );

  /**
   * Handle the event of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await refetchEvents({ page });
    },
    [refetchEvents],
  );

  /**
   * Handle the event of changing the filter for event pages.
   *
   * @param {FilterEventPage} filter - The new filter options for event pages.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onFilterChange = useCallback(
    async (filter: FilterEventPage): Promise<void> => {
      await refetchEvents({ page: 1, ...filter });
    },
    [refetchEvents],
  );

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tiêu đề',
      render: (o: IEventPage) => (
        <div
          className="event-page__title"
          onClick={() => {
            history.push(`/event-page/${o.id}`, queryString.parse(location.search));
          }}
        >
          {o.attributes.title}
        </div>
      ),
    },
    {
      title: 'Slug',
      dataIndex: 'attributes',
      render: (o: IEventPageAttributes) => {
        return <div>{o.slug}</div>;
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'attributes',
      render: (o: IEventPageAttributes) => {
        return (
          <div>
            {moment(o?.createdAt, DateFormat.serverSideWithTime).format(
              DateFormat.clientSideWithTime,
            )}
          </div>
        );
      },
    },
    {
      title: 'Ngày cập nhật mới nhất',
      dataIndex: 'attributes',
      render: (o: IEventPageAttributes) => {
        return (
          <div>
            {moment(o?.updatedAt, DateFormat.serverSideWithTime).format(
              DateFormat.clientSideWithTime,
            )}
          </div>
        );
      },
    },
    {
      title: 'Trạng thái',
      render: (o: IEventPage) => {
        return (
          <Switch
            checked={o.attributes.publish}
            onChange={(checked: boolean) => {
              confirmModal({
                title: `Bạn muốn ${checked ? 'hiển thị' : 'ẩn'} event page này?`,
                buttonText: checked ? 'Hiển thị' : 'Ẩn',
                onSaveCb: async () => await onUpdateEventPageStatus(o.id, checked),
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="event-page">
      <LayoutList
        pageTitle="Event Page"
        breadcrumbs={[
          {
            title: 'Event page',
            route: '/event-page',
          },
        ]}
        showAddButton
        handleClickAddButton={() => setIsOpenCreateModal(true)}
        loadingList={isFetching}
        columns={columns}
        dataList={data}
        tableClass="event-table"
        pagination={pagination}
        handleChangePage={onPageChange}
        topPagination
        filterNode={<FilterView onFilter={onFilterChange} />}
      />
      <CreateEventModal isOpen={isOpenCreateModal} onClose={() => setIsOpenCreateModal(false)} />
    </div>
  );
};

export default EventPage;
