import { domainUrl } from '../constants';

export const API_LOGIN = `${domainUrl}auth/login`;
export const API_REFRESH_TOKEN = `${domainUrl}auth/refresh`;
export const API_LOGOUT = `${domainUrl}auth/logout`;
export const API_GET_ME = `${domainUrl}auth/me`;

export const API_UPLOAD = `${domainUrl}media`;
export const API_DELETE_IMAGE_BY_TYPE = `${domainUrl}media/{type}/{typeId}`;
export const API_DELETE_IMAGE_BY_ID = `${domainUrl}media/{id}`;
