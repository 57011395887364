import { Button, Form, Input, Row, Col } from 'antd';
import { FormFilterPreOrder, useFilterPreOrders } from 'hooks/usePreOrders';

interface FilterPreOrderProps {
  formFilter?: FormFilterPreOrder;
  onFilterChange?: (val) => void;
}

const FilterPreOrder: React.FC<FilterPreOrderProps> = (props) => {
  const { formFilter, onFilterChange } = props;
  // HOok
  const { formFilterPreOrders, submitFilter, clearFilter } = useFilterPreOrders(
    formFilter,
    onFilterChange,
  );

  return (
    <Form layout="vertical" form={formFilterPreOrders} onFinish={submitFilter}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Nhập tên sản phẩm" name="title">
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Nhập SKU" name="sku">
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Form.Item label={<br />}>
            <Button className="secondary-button" htmlType="submit">
              Filter
            </Button>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label={<br />}>
            <Button onClick={clearFilter}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default FilterPreOrder;
