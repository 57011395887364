export enum AlignType {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum BrandImageType {
  logo = 'logo',
  logoMenu = 'logo_menu',
  banner = 'banner',
}

export enum ProductSection {
  relatedProducts = 'relatedProducts',
  relatedProducts2 = 'relatedProducts2',
  crossSells = 'crossSells',
  variantProducts = 'variantProducts',
  secondhandProducts = 'secondhandProductIds',
  groupedProducts = 'groupedProducts',
}

export enum DiscountUnit {
  VND = 'vnd',
  PERCENT = 'percent',
}

export enum DateFormat {
  clientSide = 'DD-MM-YYYY',
  serverSide = 'YYYY-MM-DD',
  clientYear = 'YYYY',
  clientSideWithTime = 'DD-MM-YYYY HH:mm:ss',
  serverSideWithTime = 'YYYY-MM-DD HH:mm:ss',
}

export enum BlockType {
  list = 'list',
  logo = 'logo',
  banner = 'banner',
  iconAndText = 'banner_text',
}

export enum StoreIds {
  mainguyen = 1,
  rider = 2,
}

export enum RiderBlockPositionTypes {
  headerLogo = 'headerRiderLogo',
  metadataHomePage = 'riderMetadataHomePage',
  leftHeroBanner = 'riderLeftHeroBanner',
  rightHeroBanner = 'riderRightHeroBanner',
  leftHeroBanners = 'riderLeftHeroBanners',
  rightHeroBanners = 'riderRightHeroBanners',
  shockPriceToday = 'riderShockPriceToday',
  categoryList = 'riderCategoryList',
  banner1 = 'riderBanner1',
  justInStock = 'riderJustInStock',
  banner2 = 'riderBanner2',
  featuredCategories = 'riderFeaturedCategories',
  youtubeVideos = 'riderYoutubeVideos',
  blog = 'riderBlog',
  quickConsultation = 'footerRiderQuickConsultation',
  riderInstagram = 'riderInstagram',
  riderCollections = 'riderCollections',

  productDetailShip = 'productDetailShipLabel',
  productDetailBestPrice = 'productDetailBestPriceLabel',
  productDetaillConsultant = 'productDetailConsultant',
  productDetailWarrantyInfo = 'productDetailWarrantyInfo',
  showroom = 'footerRiderShowroom',
  footerMenu1 = 'footerRiderMenu1',
  footerMenu2 = 'footerRiderMenu2',
  footerSocials = 'footerRiderSocial',
  footerCopyrite = 'footerRiderCopyright',
  // rider single page
  riderSinglePgJobs = 'riderSinglePgJobs',
  riderSinglePgGuideOrder = 'riderSinglePgGuideOrder',
  riderSinglePgContact = 'riderSinglePgContact',
  riderSinglePgYoutube = 'riderSinglePgYoutube',
}

export enum RiderBlockTemplateTypes {
  headerLogo = 'headerRiderLogo',
  metadataHomePage = 'metadataHomePage',
  heroBanner = 'heroBanner',
  largeBanner = 'largeBanner',
  shockPriceToday = 'shockPriceToday',
  categoryList = 'categoryList',
  justInStock = 'justInStock',
  featuredCategories = 'featuredCategories',
  youtubeVideos = 'youtubeVideos',
  quickConsultation = 'quickConsultation',
  showroom = 'riderShowroom',
  riderInstagram = 'riderInstagram',
  riderCollections = 'riderCollections',
  footerMenu1 = 'footerRiderMenu1',
  footerMenu2 = 'footerRiderMenu2',
  footerSocials = 'footerRiderSocial',
  footerCopyrite = 'riderCopyright',
  riderEditor = 'editor',
  productDetaillConsultant = 'riderProdDetailConsultant',
  productDetailWarrantyInfo = 'productDetailWarrantyInfo',
  // rider single page
  riderSinglePage = 'riderSinglePage',
}

export enum EventPageBlockTemplates {
  hero = 'hero',
  menu = 'menu',
  countdown = 'countdown',
  productDescription = 'productDescription',
  listOfProducts = 'listOfProducts',
  productImageSlider = 'productImageSlider',
  productVideos = 'productVideos',
  contactUs = 'contactUs',
  comments = 'comments',
  news = 'news',
}

export enum CouponPresentPageTemplates {
  seo = 'couponPresentSeo',
  mainSlider = 'couponPresentMainSlider',
  coupons = 'couponPresentCoupons',
}

export enum RiderCouponPresentPageTemplates {
  seo = 'riderCouponPresentSeo',
  mainSlider = 'riderCouponPresentMainSlider',
  coupons = 'riderCouponPresentCoupons',
}

// Constant MN list store
export const MNStoreList = [
  {
    id: 1,
    title: 'MaiNguyen Store',
  },
  {
    id: 2,
    title: 'MaiNguyen Rider',
  },
];

export enum CouponRules {
  auto = 'auto',
  one = 'one',
}

export enum CouponStatus {
  active = 'active',
  draft = 'draft',
  expired = 'expired',
  close = 'close',
}

export enum OrderStatus {
  order = 'order',
  processing = 'processing',
  delivered = 'delivered',
  done = 'done',
  cancel = 'cancel',
  deposit = 'deposit',
  preorder = 'preorder',
}

export enum FeedbackStatus {
  new = 'new',
  solved = 'solved',
}

export enum ReviewStatus {
  pending = 'pending',
  published = 'published',
  locked = 'locked',
}

export const scoreList = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
];

export const sortList = [
  {
    label: 'Mới nhất',
    value: 'newest',
  },
  {
    label: 'Cũ hơn',
    value: 'oldest',
  },
];

export const directionList = [
  {
    label: 'Mới nhất',
    value: 'desc',
  },
  {
    label: 'Cũ hơn',
    value: 'asc',
  },
];

export enum StatusCode {
  OUTSTOCK = 'OUTSTOCK',
  OUTSTOCK_TEMP = 'OUTSTOCK_TEMP',
  OUTSTOCK_NO_SELLING = 'OUTSTOCK_NO_SELLING',
  OUTSTOCK_DATATRANSFER = 'OUTSTOCK_DATATRANSFER',
  OUTSTOCK_OUTDATE = 'OUTSTOCK_OUTDATE',
  OUTSTOCK_CALL = 'OUTSTOCK_CALL',
  COMING = 'COMING',
  COMING_UP = 'COMING_UP',
  COMING_LEAK = 'COMING_LEAK',
  COMING_NEARLY = 'COMING_NEARLY',
  COMING_OUTSTOCK = 'COMING_OUTSTOCK',
  INSTOCK = 'INSTOCK',
  INSTOCK_PHONE = 'INSTOCK_PHONE',
  INSTOCK_OUTTEMP = 'INSTOCK_OUTTEMP',
  INSTOCK_COMING = 'INSTOCK_COMING',
  INSTOCK_NEW = 'INSTOCK_NEW',
  INSTOCK_PHONECALL = 'INSTOCK_PHONECALL',
  INSTOCK_CALLSTORE = 'INSTOCK_CALLSTORE',
  INSTOCK_READY = 'INSTOCK_READY',
  COMING_REGISTER_ORDER = 'COMING_REGISTER_ORDER',
  COMING_PREORDER = 'COMING_PREORDER',
}

export const StatsBysMonth = [
  {
    label: '3 tháng',
    value: '3',
  },
  {
    label: '6 tháng',
    value: '6',
  },
  {
    label: '1 năm',
    value: '12',
  },
];

export const StatsByType = [
  {
    label: 'Theo tuần',
    value: 'week',
  },
  {
    label: 'Theo tháng',
    value: 'month',
  },
];

export const STATUS_LIST = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: 'preorder',
    label: 'Đặt hàng trước',
  },
  {
    value: 'reception-order',
    label: 'Tiếp nhận đơn hàng',
  },
  {
    value: 'processing',
    label: 'Đang xử lý',
  },
  {
    value: 'deposit',
    label: 'Đã Đặt cọc',
  },
  {
    value: 'order',
    label: 'Đặt hàng',
  },
  {
    value: 'support',
    label: 'Khách cần hỗ trợ',
  },
  {
    value: 'shipping',
    label: 'Đang vận chuyển',
  },
  {
    value: 'delivered',
    label: 'Đã giao hàng',
  },
  {
    value: 'packed',
    label: 'Đã đóng gói',
  },
  {
    value: 'done',
    label: 'Hoàn thành',
  },
  {
    value: 'cancel',
    label: 'Huỷ',
  },
];

export enum SexType {
  male = 'Anh',
  female = 'Chị',
}

export const orderList = [
  {
    label: 'Mới nhất',
    value: 'newest',
  },
  {
    label: 'Đang giao hàng trước',
    value: 'delivery',
  },
];

export const roundedRevenue = 1000000;
