import { FilterFilled } from '@ant-design/icons';
import { Button, Pagination, Table } from 'antd';
import { ICoupon, ICouponAttributes } from 'interfaces/coupon.interface';
import moment from 'moment';
import { FilterCoupons } from 'pages/Coupons/components/FilterView';
import React, { useCallback, useEffect, useState } from 'react';

// Components
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';

// Helper
import { formatNumber } from 'utils/utils';
import { COUPON_STATUS_LIST } from 'constants/constants';
import { AlignType, CouponStatus, DateFormat, DiscountUnit } from 'constants/enum';
import FilterView from './Filter';
import { useFetchCoupons } from '../hook';

interface FormGroupSizeProps {
  openModal?: boolean;
  ignoreIds?: string[];
  onCloseModal?: () => void;
  onSelect: (coupon: ICouponAttributes) => void;
}

const ModalSelectCoupon: React.FC<FormGroupSizeProps> = (props) => {
  const { openModal, ignoreIds, onCloseModal, onSelect } = props;
  const [showFilter, setShowFilter] = useState(false);

  const {
    isFetching,
    data,
    pagination,
    setPagination,
    refetch: refetchCoupons,
  } = useFetchCoupons();

  useEffect(() => {
    if (openModal) {
      setShowFilter(false);
      refetchCoupons({ ignoreIds });
    }
  }, [openModal]);

  /**
   * A callback function that handles page changes in pagination.
   *
   * @param {number} page - The new page number.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await refetchCoupons({ page, ignoreIds });
    },
    [refetchCoupons],
  );

  // click close modal
  const clickCloseModal = () => {
    onCloseModal && onCloseModal();
  };

  /**
   * TODO: action filter
   */
  const onFilterChange = useCallback(
    async (filter: FilterCoupons): Promise<void> => {
      await refetchCoupons({ page: 1, ...filter, ignoreIds });
    },
    [refetchCoupons],
  );

  // TODO: actions show filter
  const handleShowFilter = useCallback(() => {
    setShowFilter(!showFilter);
  }, [showFilter]);

  // config table
  const columns = [
    {
      title: 'Thông tin',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => (
        <div className="coupon-cell__info">
          <div className="coupon-info__title">{o?.title || '-'}</div>
          <div className="coupon-info__desc">{o?.displayTitle || '-'}</div>
          <div className="coupon-info__prefix">{o?.prefixCode || '-'}</div>
        </div>
      ),
    },
    {
      title: 'Số mã',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        const totalCouponAvailable = o?.numberOfCoupon - o?.countActive;
        return (
          <div className="coupon-cell__total">
            <div className="coupon-available">{totalCouponAvailable}</div>
            <div className="coupon-total">{o?.numberOfCoupon}</div>
          </div>
        );
      },
    },
    {
      title: 'Discount',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        return (
          <div className="coupon-cell__discount">
            <div className="coupon-discount">
              {' '}
              {parseFloat(o?.discount as any) > 0
                ? `${
                    o?.discountUnit === DiscountUnit.VND
                      ? formatNumber(o?.discount)
                      : `${o?.discount}%`
                  }`
                : '-'}
            </div>
            <div className="coupon-discount-auto">{o?.rule}</div>
          </div>
        );
      },
    },
    {
      title: 'Ngày hiệu lực',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        return (
          <div className="coupon-cell__date">
            <div className="coupon__start-date">
              {o?.startDate
                ? moment(o?.startDate, DateFormat.serverSideWithTime).format(
                    DateFormat.clientSideWithTime,
                  )
                : '-'}
            </div>
            <div className="coupon__end-date">
              {' '}
              {o?.endDate
                ? moment(o?.endDate, DateFormat.serverSideWithTime).format(
                    DateFormat.clientSideWithTime,
                  )
                : '-'}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Tình trạng',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        const isCouponExpired = o?.endDate
          ? moment(o.endDate, DateFormat.serverSideWithTime).isBefore(moment())
          : false;
        const statusLabel = COUPON_STATUS_LIST.find(
          (c) => c.value === (isCouponExpired ? CouponStatus.expired : o?.status),
        )?.label;
        const prefix = isCouponExpired ? CouponStatus.expired : o?.status;

        return <div className={`coupon-status--${prefix}`}>{statusLabel}</div>;
      },
    },
    {
      title: 'Thao tác',
      align: AlignType.right,
      dataIndex: 'attributes',
      render: (o: ICouponAttributes, row: ICoupon) => {
        const coupon = { id: row.id, ...o };
        return (
          <Button title="Chỉnh sửa" style={{ marginRight: 12 }} onClick={() => onSelect(coupon)}>
            Chọn
          </Button>
        );
      },
    },
  ];

  return (
    <CustomPopup
      title="Chọn ưu đãi - Coupons"
      isOpen={openModal}
      onCloseModal={clickCloseModal}
      formHook={null}
      handleSaveButton={null}
      isShowSaveButton={false}
    >
      <div className="filter-toolbar mb-4">
        <Button
          className="filter-button"
          title="Filter"
          icon={<FilterFilled rev={''} />}
          onClick={handleShowFilter}
        >
          Filter
        </Button>
      </div>

      {showFilter && <FilterView onFilter={onFilterChange} />}

      <div className="mt-2 mb-3 text-right order-pagi">
        <Pagination
          {...pagination}
          pageSizeOptions={[]}
          onChange={onPageChange}
          disabled={isFetching}
        />
      </div>

      <Table
        className="coupons-table"
        columns={columns}
        dataSource={data}
        rowKey={'id'}
        pagination={false}
        loading={isFetching}
      />

      <div className="mt-3 mb-2 text-right">
        <Pagination
          {...pagination}
          pageSizeOptions={[]}
          onChange={onPageChange}
          disabled={isFetching}
        />
      </div>
    </CustomPopup>
  );
};

export default ModalSelectCoupon;
