import { Select } from 'antd';

type FilterStatsProps = {
  onFilterChange: (value: string) => void;
  defaultValue?: string;
  filterList: {
    label: string;
    value: string;
  }[];
};

const FilterStats: React.FC<FilterStatsProps> = ({ defaultValue, filterList, onFilterChange }) => {
  return (
    <Select onChange={onFilterChange} value={defaultValue} className="sl-order-type text-left">
      {filterList.map((o) => {
        return (
          <Select.Option key={o.value} value={o.value}>
            {o.label}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default FilterStats;
