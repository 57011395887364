/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Select, Switch, message } from 'antd';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import './styles.scss';
import { StoreIds } from 'constants/enum';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { setIsEditing } from 'redux/uiAction/actions';
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { deleteModal } from 'utils/modals';
import { ProductList } from './components/ProductList';
import { SEOEditorModal } from './components/SEOEditorModal';
import { UploadImage } from './components/UploadImage';
import { useFetchCollection, useUpdateCollection } from './hooks';
import { useRemoveCollections } from '../Collections/hooks';
import { getFullUrlWithSlug } from 'utils/utils';

const RiderCollectionDetail: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id: collectionId } = useParams<{ id: string }>();

  const { isFetching, data } = useFetchCollection(collectionId);
  const { isUpdating, onUpdate } = useUpdateCollection();
  const { onRemove: removeCollection } = useRemoveCollections();

  const [openSEOEditorModal, setOpenSEOEditorModal] = useState<boolean>(false);
  const [copySlugText, setCopySlugText] = useState<string>('');
  const [storeId, setStoreId] = useState<number>();

  /**
   * A hook that sets the form fields values based on the data attributes when the data ID changes.
   *
   * @param {Object} data - The data object containing the attributes.
   */
  useEffect(() => {
    if (data?.id) {
      const { title, slug, active, description, image, storeId: _storeId } = data.attributes;
      form.setFieldsValue({ title, slug, active, description, image, storeId: _storeId });
      setCopySlugText(slug);
      setStoreId(_storeId);
      dispatch(setIsEditing(false));
    }
  }, [data?.id]);

  /**
   * A callback function that saves a collection by updating its data using the form values.
   *
   * @throws {Error} - An error that occurred during the collection update.
   */
  const onSave = useCallback(async (): Promise<void> => {
    try {
      const values = form.getFieldsValue();
      await onUpdate({ method: 'put', id: collectionId, data: values });
      message.success('Cập nhật collection thành công');
    } catch (error: any) {
      let msg = error?.message || error;
      if (error?.message === 'Request failed with status code 422') {
        msg = 'Slug đã bị trùng, vui lòng nhập slug khác';
      }
      message.error(msg);
    } finally {
      dispatch(setIsEditing(false));
    }
  }, [collectionId]);

  /**
   * A callback function that handles the removal of a collection.
   *
   * @throws {Error} - An error that occurred during the collection removal.
   */
  const onRemove = useCallback(async (): Promise<void> => {
    deleteModal({
      title: 'Bạn có muốn xóa collection này?',
      onDeleteCb: async () => {
        try {
          dispatch(setIsEditing(false));
          await removeCollection({ method: 'remove', id: collectionId });
          message.success('Xoá collection thành công');
          onGotoCollections();
        } catch (error: any) {
          message.error(error?.message || error);
        }
      },
    });
  }, []);

  /**
   * Toggles the SEO Editor modal by toggling the state of `openSEOEditorModal`.
   *
   * @returns {void}
   */
  const toggleSEOEditorModal = useCallback((): void => {
    setOpenSEOEditorModal((prev) => !prev);
  }, []);

  /**
   * Navigates to the collections page with the provided query parameters.
   *
   * @returns {void}
   */
  const onGotoCollections = useCallback((): void => {
    history.push(
      queryString.stringifyUrl(
        {
          url: '/bo-suu-tap',
          query: location.state as any,
        },
        { skipEmptyString: true, skipNull: true },
      ),
    );
  }, []);

  const onCopySlug = useCallback((text: string, result) => {
    if (text && result) {
      message.success('Copy thành công!');
    }
  }, []);

  if (isFetching || !data) return null;

  return (
    <>
      <LayoutDetail
        pageTitle={`Collection #${data?.id || ''}`}
        isAddNew={false}
        pageClassName="collection-detail"
        breadcrumbs={[
          {
            title: 'Collections',
            route: '/bo-suu-tap',
          },
          {
            title: 'Chi tiết',
            route: `/bo-suu-tap/${collectionId}`,
          },
        ]}
        formHook={form}
        handleDelete={onRemove}
        handleSave={onSave}
        saveLoading={isUpdating}
        handleValuesChange={(values) => {
          dispatch(setIsEditing(true));
          values?.slug && setCopySlugText(values.slug);
          values?.storeId && setStoreId(values.storeId);
        }}
      >
        <Button className="btn-close-promotion" onClick={onGotoCollections}>
          ĐÓNG
        </Button>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Tiêu đề collection"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tiêu đề',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col className="slug-col" span={12}>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập slug',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <CopyToClipboard
              text={getFullUrlWithSlug(storeId, `/bo-suu-tap/${copySlugText}`)}
              onCopy={onCopySlug}
            >
              <Button title="Copy" className="copy-button" icon={<CopyOutlined />} />
            </CopyToClipboard>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Chọn cửa hàng"
              name="storeId"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn cửa hàng',
                },
              ]}
            >
              <Select
                className="ant-select-lg"
                options={[
                  { value: StoreIds.mainguyen, label: 'Mai Nguyen' },
                  { value: StoreIds.rider, label: 'Mai Nguyen Rider' },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Hiển thị" valuePropName="checked" name="active">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button icon={<EditOutlined />} onClick={toggleSEOEditorModal}>
              Nội dung SEO
            </Button>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Mô tả collection"
              name="description"
              trigger="handleChangeMainContent"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mô tả',
                },
              ]}
            >
              <CustomEditor mainContent={data?.attributes?.description} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="image"
              valuePropName="initialImage"
              trigger="onChange"
              label="Banner (1500 x 465)"
            >
              <UploadImage
                uploadInfo={{
                  type: 'collection',
                  typeId: collectionId,
                  property: 'banner',
                }}
                uploadText="Click hoặc kéo thả hình để upload OG:Image (1500 x 465)"
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <ProductList collection={data} />
      </LayoutDetail>
      <SEOEditorModal
        isOpen={openSEOEditorModal}
        collection={data}
        onClose={toggleSEOEditorModal}
      />
    </>
  );
};

export default RiderCollectionDetail;
