import { Col, Form, Input, Row, Switch } from 'antd';
import React, { useCallback } from 'react';

interface Props {
  data: ContactUsTemplate;
  onChange: (data: string) => void;
}

export interface ContactUsTemplate {
  blockId: string;
  title: string;
  description: string;
  hotline: string;
  zaloLink: string;
  show: boolean;
}

const INITIAL_DATA: Props['data'] = {
  blockId: '',
  title: '',
  description: '',
  hotline: '',
  zaloLink: '',
  show: true,
};

export const ContactUsTemplate: React.FC<Props> = (props) => {
  const { data = INITIAL_DATA, onChange } = props;

  const _onChange = useCallback(
    (values: Props['data']) => {
      onChange(JSON.stringify(values));
    },
    [onChange],
  );

  const onValuesChange = useCallback(
    (fieldKey: keyof ContactUsTemplate, value: any) => {
      _onChange({ ...data, [fieldKey]: value });
    },
    [data, _onChange],
  );

  return (
    <div className="contact-us-template">
      <Row gutter={16} className="mt-3 mb-3">
        <Col span={24}>
          <Form.Item label="Block Id (Optional)">
            <Input
              value={data.blockId}
              onChange={(e) => onValuesChange('blockId', e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tiêu đề">
            <Input value={data.title} onChange={(e) => onValuesChange('title', e.target.value)} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Hotline">
            <Input
              value={data.hotline}
              onChange={(e) => onValuesChange('hotline', e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Zalo Link">
            <Input
              value={data.zaloLink}
              onChange={(e) => onValuesChange('zaloLink', e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item label="Hiển thị">
            <Switch checked={data?.show} onChange={(checked) => onValuesChange('show', checked)} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
