/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-use-before-define */
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import { API_MEMBER_WISHLIST } from 'constants/api/member.api';
import { defaultPagination } from 'constants/constants';
import { IPagination } from 'interfaces/pagination.interface';
import { IProduct } from 'interfaces/product.interface';
import { updatePageData } from 'redux/pages/actions';
import { fetcherWithArg } from 'services/swr.utils';
import { buildParams } from 'utils/utils';

interface FilterMemberWishlistPage {
  direction?: string;
}

/**
 * Custom hook that fetches and returns member wishlist page data.
 */
export const useFetchFavoriteProdsByMember = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: memberId } = useParams<{ id: string }>();

  const { data, isMutating, trigger } = useSWRMutation(
    `${API_MEMBER_WISHLIST.replace('{id}', memberId)}`,
    fetcherWithArg,
  );

  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetch(buildParams(isEmpty(params) ? {} : params));
  }, []);

  const fetch = useCallback(
    async (
      params?: Partial<Omit<FilterMemberWishlistPage, 'page'>> & {
        page?: number;
      },
    ): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const { page = pagination.current, ...rest } = params || {};

      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;
      const query = {
        ...locationParams,
        page: calcPage,
        perPage: defaultPagination.pageSize,
        ...rest,
      };

      history.push(
        queryString.stringifyUrl(
          {
            url: location.pathname,
            query,
          },
          { skipEmptyString: true, skipNull: true },
        ),
      );

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetching: isMutating,
    data: data?.data as IProduct[],
    pagination,
    setPagination,
    fetchWishList: fetch,
  };
};
