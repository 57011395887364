import { IComment } from 'interfaces/comment.interface';
import {
  SET_ALL_COMMENTS,
  SetAllComments,
  SET_RELOAD_ALL_COMMENTS,
  SetReloadAllComments,
} from './types';

export const setAllComments = (payload: IComment[]): SetAllComments => ({
  type: SET_ALL_COMMENTS,
  payload,
});

export const setReloadAllComments = (payload: boolean): SetReloadAllComments => ({
  type: SET_RELOAD_ALL_COMMENTS,
  payload,
});
