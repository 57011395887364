/* eslint-disable import/order */
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Table, TableProps, Tag } from 'antd';
import { debounce } from 'lodash';
import { IImage } from 'interfaces/image.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { IProduct, IProductAttribute } from 'interfaces/product.interface';
import { IStatus } from 'interfaces/status.interface';
import { BrandLogoCell } from 'pages/Products/ProductDetail/components/BaseProductsTable/BaseProductsTable';
import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { renderColorStatus } from 'utils/renderColorStatus';
import { addVnCurrency, getCurrentPrice, getOldPrice } from 'utils/utils';
import { AlignType } from 'constants/enum';
import { toast } from 'react-toastify';

import './styles.scss';

interface Props extends TableProps<any> {
  loading: boolean;
  data: IProduct[];
  subStatus: IStatus[];
  pagination?: IPagination | false;
  productLength?: number;
  onPosChange?: (pos: number, productId: string) => void;
  onRemove?: (productId: string) => void;
  onPageChange?: (page: number, pageSize: number) => void;
}

export const ProductTable: React.FC<Props> = (props) => {
  const {
    loading,
    data,
    subStatus,
    pagination,
    productLength,
    onRemove,
    onPageChange,
    onPosChange,
    ...rest
  } = props;

  /**
   * An effect that updates the empty list message when the fetching status of products changes.
   */
  useEffect((): void => {
    if (!loading) {
      const selector = '.products-table-wrapper table .ant-empty-description';
      const els = document.querySelectorAll(selector);
      if (els?.length) {
        els.forEach((el) => {
          el.innerHTML = 'Danh sách trống';
        });
      }
    }
  }, [loading]);

  const columns = [
    {
      title: 'Hình ảnh',
      dataIndex: 'attributes',
      width: 80,
      render: (attr: IProductAttribute) => {
        const imageValues = attr.attributeValues.find((item) => item.attributeCode === 'image');
        const image = imageValues?.value.find((item: IImage) => item.property === 'main');

        return (
          <div className="product-image">
            <BrandLogoCell image={image?.['128x128']} />
          </div>
        );
      },
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute, row: IProduct) => {
        return (
          <div>
            <NavLink to={`/san-pham/${row.id}`}>
              <div dangerouslySetInnerHTML={{ __html: attr.name }} className="product-name" />
            </NavLink>
            <div>{`SKU: ${attr.sku}`}</div>
            <div className="product-type">{`Type: ${attr.type}`}</div>
            <div>Tồn kho: {attr.quantity}</div>
          </div>
        );
      },
    },
    {
      title: 'Giá hiện tại',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute) => {
        const currentPrice = attr.attributeValues.find(
          (item) => item.attributeCode === 'original_price',
        );
        const salePrice = attr.attributeValues.find((item) => item.attributeCode === 'sale_price');
        const newPrice = addVnCurrency(getCurrentPrice(currentPrice?.value, salePrice?.value));
        const oldPrice = addVnCurrency(getOldPrice(currentPrice?.value, salePrice?.value));

        return (
          <>
            <div className="product-price">{newPrice}</div>
            <div className={oldPrice !== '-' ? 'old-price' : ''}>{oldPrice}</div>
          </>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute) => {
        const status = subStatus?.find((item) => item.id === attr?.statusId?.toString());
        return (
          <div className="text-center">
            <div className={`${attr?.active ? 'product--published' : ''}`}>
              {attr?.active ? 'Published' : 'Unpublished'}
            </div>
            {status && (
              <Tag
                color={renderColorStatus(status?.attributes?.parentId)}
                style={{ whiteSpace: 'pre-line' }}
              >
                {status?.attributes?.name}
              </Tag>
            )}
          </div>
        );
      },
    },
    onPosChange
      ? {
          title: 'Vị trí',
          dataIndex: '',
          align: AlignType.right,
          render: (row: IProduct) => {
            const MIN = 0;
            const MAX = productLength - 1;
            const { attributes } = row;
            const posRef = useRef<HTMLDivElement>(null);

            const changePos = debounce((e) => {
              const val = e.target.value;

              if (val > MAX || val < MIN) {
                // add class input error
                posRef.current.classList.add('err');
                toast.error(
                  `Vui lòng nhập vị trí phải lớn hơn hoặc bằng ${MIN} và nhỏ hơn hoặc bằng ${MAX}`,
                  {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    progress: null,
                  },
                );
                return;
              }

              // remove class input error
              posRef.current.classList.remove('err');
              attributes.position = Number(val);
            }, 300);

            const savePos = () => {
              if (attributes.position > MAX || attributes.position < MIN) return;

              onPosChange(attributes.position, row.id);
            };

            return (
              <div className="collect-prods-position" ref={posRef}>
                <Input
                  type="number"
                  placeholder="Vị trí"
                  max={MAX}
                  min={MIN}
                  defaultValue={attributes.position}
                  key={attributes.position}
                  onChange={changePos}
                />
                <Button
                  title="Cập nhật vị trí sản phẩm"
                  icon={<SaveOutlined />}
                  onClick={savePos}
                />
              </div>
            );
          },
        }
      : undefined,
    onRemove
      ? {
          title: 'Thao tác',
          dataIndex: '',
          align: AlignType.right,
          render: (row: IProduct) => (
            <Button
              title="Remove"
              className="mb-2"
              icon={<DeleteOutlined />}
              onClick={() => onRemove(row.id)}
            />
          ),
        }
      : undefined,
  ].filter((c) => !!c);

  return (
    <>
      <div className="products-table-wrapper">
        <Table
          showHeader={false}
          columns={columns}
          dataSource={data}
          rowKey="id"
          loading={loading}
          pagination={false}
          {...rest}
        />
      </div>
      {pagination && (
        <div className="layout-list-pagination">
          <Pagination
            {...pagination}
            pageSizeOptions={[]}
            onChange={onPageChange}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
};
