import { Select, Row, Col, Spin, Form, message } from 'antd';
import clone from 'lodash/clone';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { API_GET_PRODUCTS } from 'constants/api/product.api';
import { IProduct } from 'interfaces/product.interface';
import * as api from 'services/api.service';
import { SelectedTable } from 'ui/components/SelectProductsSection/SelectedTable';
import { showErrorMsg, transformApiForRider } from 'utils/utils';

import '../HighEndBlock/HighEndBlock.scss';

interface ShockPriceProps {
  blockName: string;
  blockId: string;
  blockData: string;
  handleUpdateBlockData: (data: any) => void;
}

type BlockType = {
  blockName: string;
  data: {
    items: string[];
  };
};

export const ShockPriceBlock: FC<ShockPriceProps> = ({
  blockName,
  blockId,
  handleUpdateBlockData,
  blockData,
}) => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [loadProducts, setLoadProducts] = useState(false);
  const [productSku, setProductSku] = useState<string>(null);
  const [block, setBlock] = useState<BlockType>({
    blockName,
    data: { items: [] },
  });
  const [chooseProductList, setChooseProductList] = useState<IProduct[]>([]);
  const [blocks, setBlocks] = useState<BlockType[]>([]);
  const [loadProductList, setLoadProductList] = useState(false);

  const getProductbySku = (arrSkus) => {
    try {
      setLoadProductList(true);
      let skus = '';
      for (let i = 0; i < arrSkus.length; i++) {
        skus += '&skus[' + i + ']=' + arrSkus[i];
      }
      api
        .get({
          endpoint: `${transformApiForRider(
            `${API_GET_PRODUCTS}?type=configurable&perPage=all${skus}`,
          )}`,
        })
        .then((res) => {
          setChooseProductList(res?.data);
          setLoadProductList(false);
        })
        .catch((err) => {
          setChooseProductList([]);
          setLoadProductList(false);
          showErrorMsg(err);
        });
    } catch (error) {
      setChooseProductList([]);
      setLoadProductList(false);
      showErrorMsg(error);
    }
  };

  useEffect(() => {
    try {
      const jsonBlock = JSON.parse(blockData) || [];

      if (!isEmpty(jsonBlock)) {
        if (jsonBlock[0].data.items.length > 0) {
          getProductbySku(jsonBlock[0].data.items);
        }

        const blockByName = jsonBlock.find((item: BlockType) => item.blockName === blockName);
        if (blockByName) {
          setBlock(blockByName);
        }
      }

      setBlocks(jsonBlock);
    } catch (error) {
      showErrorMsg(error);
    }
  }, [blockData, blockName]);

  const handleSearchSmartPhone = useMemo(() => {
    const fetchProducts = async (value: string) => {
      setLoadProducts(true);
      if (value) {
        await api
          .get({
            endpoint: `${transformApiForRider(
              `${API_GET_PRODUCTS}?page=1&name=${value}&type=configurable`,
            )}`,
          })
          .then((res) => {
            setLoadProducts(false);
            setProducts(res?.data);
          })
          .catch((err) => {
            setLoadProducts(false);
            setProducts([]);
            showErrorMsg(err);
          });
      } else {
        setLoadProducts(false);
        setProducts([]);
      }
    };
    return debounce(fetchProducts, 800);
  }, [block]);

  const handleSelectSmartPhone = useCallback(
    (sku: string) => {
      if (sku) {
        const clonedProductTable = clone(chooseProductList || []);
        const clonedProductSkus = clone(block?.data?.items || []);

        if (
          !clonedProductSkus.some((item) => item.toLocaleLowerCase() === sku.toLocaleLowerCase()) ||
          !clonedProductTable.some(
            (item) => item.attributes.sku.toLocaleLowerCase() === sku.toLocaleLowerCase(),
          )
        ) {
          setProductSku(sku);
          clonedProductSkus.unshift(sku);
          clonedProductTable.unshift(
            products.find(
              (item) => item.attributes.sku.toLocaleLowerCase() === sku.toLocaleLowerCase(),
            ),
          );

          const newBlockData = {
            ...block.data,
            items: clonedProductSkus,
          };

          // chose product list
          const prods = clonedProductTable.map((item) => ({
            id: item.id,
            type: item.type,
            attributes: item.attributes,
          }));
          setChooseProductList(prods);

          setBlock({
            ...block,
            data: newBlockData,
          });
          if (blockId) {
            handleUpdateBlockData(
              !isEmpty(blocks)
                ? blocks.map((item) => {
                    if (item.blockName === blockName) {
                      return {
                        ...block,
                        data: newBlockData,
                      };
                    }
                    return item;
                  })
                : [
                    {
                      ...block,
                      data: newBlockData,
                    },
                  ],
            );
          } else {
            handleUpdateBlockData(
              blocks.map((item) => {
                if (item.blockName !== blockName) {
                  return {
                    ...block,
                    data: newBlockData,
                  };
                }
                return item;
              }),
            );
          }
        } else {
          message.error('Sản phẩm đã tồn tại trong danh sách.');
        }
      }
    },
    [products, handleUpdateBlockData, block, blocks, blockName, blockId],
  );

  const handleRemoveProduct = useCallback(
    (product: IProduct) => {
      const clonedProductTable = clone(chooseProductList);
      const clonedProductSkus = clone(block?.data?.items);
      const productIndex = clonedProductTable.findIndex((item) => item.id === product.id);
      const productSkuIndex = clonedProductSkus.findIndex(
        (item) => item.toLowerCase() === product.attributes.sku.toLocaleLowerCase(),
      );

      if (productIndex > -1 && productSkuIndex > -1) {
        clonedProductTable.splice(productIndex, 1);
        clonedProductSkus.splice(productSkuIndex, 1);
        const newBlockData = {
          ...block.data,
          items: clonedProductSkus,
        };
        // chose product list
        setChooseProductList(clonedProductTable);
        setBlock({
          ...block,
          data: newBlockData,
        });
        handleUpdateBlockData(
          blocks.map((item) => {
            if (item.blockName === blockName) {
              return {
                ...block,
                data: newBlockData,
              };
            }
            return item;
          }),
        );
      }
    },
    [block, blocks, blockName, chooseProductList],
  );

  const handleChangeDataPosition = useCallback(
    (dataType: string) => (changedData: IProduct[]) => {
      switch (dataType) {
        case 'products':
          const arrSkus = [];
          changedData.map((item) => {
            arrSkus.push(item?.attributes?.sku);
          });
          const newBlockData = {
            ...block.data,
            items: arrSkus,
          };
          setBlock({
            ...block,
            data: newBlockData,
          });
          handleUpdateBlockData([
            {
              ...block,
              data: newBlockData,
            },
          ]);
          break;
        default:
          break;
      }
    },
    [],
  );

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Form.Item label="Tab Name">
          <div>{block?.blockName || blockName}</div>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Chọn sản phẩm">
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            allowClear
            notFoundContent={loadProducts ? <Spin size="small" /> : null}
            onSearch={handleSearchSmartPhone}
            placeholder="Nhập để tìm sản phẩm theo SKU/Tên"
            onSelect={handleSelectSmartPhone}
            value={productSku}
          >
            {products.map((item) => (
              <Select.Option key={item.id} value={item.attributes.sku}>
                {`${item?.attributes?.sku} - ${item?.attributes?.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <SelectedTable
          data={chooseProductList}
          loading={loadProductList}
          onRemoveSelectedProd={handleRemoveProduct}
          className="selected-phone"
          imageWidth={120}
          priceWidth={150}
          sortable={true}
          handleChangePosition={handleChangeDataPosition('products')}
        />
      </Col>
    </Row>
  );
};
