/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from 'antd';
import { ICategory } from 'interfaces/category.interface';
import { isEmpty } from 'lodash';
import { useUpdateCoupon } from 'pages/CouponDetail/hooks';
import { useCallback, useEffect, useState } from 'react';
import { mutate } from 'swr';
import { deleteModal } from 'utils/modals';
import { API_COUPON_BY_ID } from 'constants/api/coupons.api';
import { CategoryTable } from './CategoryTable';
import ModalSelectCategory from './ModalSelectCategory';
import { useFetchCategoriesByCoupon } from '../hook';

type CategoryListProps = {
  cateIdsOfCoupons?: string[];
  couponId?: string;
  productCategories?: any;
  onSelectCate?: (data: ICategory[]) => void;
  fetchCategoriesOfCoupon: () => Promise<void>;
};

const CategoryList: React.FC<CategoryListProps> = ({
  cateIdsOfCoupons,
  couponId,
  productCategories,
  fetchCategoriesOfCoupon,
}) => {
  const [sort, setSort] = useState<'desc' | 'asc'>('desc');
  const [openModal, setOpenModal] = useState(false);

  const {
    isFetching,
    data,
    pagination,
    totalItems,
    setPagination,
    refetch: fetchCategories,
  } = useFetchCategoriesByCoupon();
  const { onUpdate } = useUpdateCoupon();

  useEffect(() => {
    return () => {
      setOpenModal(false);
    };
  }, []);

  // TODO: show modal find coupon
  const showModal = () => {
    setOpenModal(true);
  };

  // TODO: close modal find coupon
  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (!isEmpty(cateIdsOfCoupons)) {
      fetchCategories({ ids: cateIdsOfCoupons });
    }
  }, []);

  /**
   * A callback function that handles page changes in pagination.
   *
   * @param {number} page - The new page number.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchCategories({ page, ids: cateIdsOfCoupons });
    },
    [fetchCategories],
  );

  /**
   * A callback function that handles changes in sorting direction.
   *
   * @param {'desc' | 'asc'} type - The new sorting direction ('desc' for descending, 'asc' for ascending).
   */
  const onSortChange = useCallback(
    async (type: 'desc' | 'asc') => {
      setSort(type);
      await fetchCategories({ page: 1, direction: type, ids: cateIdsOfCoupons });
    },
    [fetchCategories],
  );

  /**
   * A callback function that handles the removal of a product from a coupon.
   *
   * @param {string} productId - The ID of the product to be removed.
   * @throws {Error} - An error that occurred during the product removal.
   */
  const onRemove = useCallback(
    (cateId: string): void => {
      deleteModal({
        title: 'Bạn có muốn xóa nhóm áp dụng này?',
        onDeleteCb: async () => {
          try {
            const newData = await onUpdate({
              method: 'put',
              id: couponId,
              data: {
                categoryIds: cateIdsOfCoupons?.filter((p) => p != cateId),
              },
            });
            await mutate(API_COUPON_BY_ID.replace('{id}', couponId), newData, {
              revalidate: false,
              rollbackOnError: true,
            });
            message.success(`Xoá nhóm áp dụng thành công`);
            fetchCategoriesOfCoupon();
          } catch (error: any) {
            message.error(`${error?.message || error}`);
          }
        },
      });
    },
    [couponId, cateIdsOfCoupons, fetchCategoriesOfCoupon],
  );

  return (
    <div className="coupon__products">
      <div className="coupon__products-header">
        <div className="products-header__title">
          NHÓM ÁP DỤNG COUPON <span>{`(${totalItems})`}</span>
        </div>

        <div className="products-header__toolbar">
          <div className="products-header__toolbar-sort">
            <div
              className={`toolbar-sort__option ${
                sort === 'asc' ? 'toolbar-sort__option--active' : ''
              }`}
              onClick={() => onSortChange('asc')}
            >
              CŨ TRƯỚC
            </div>
            <div className="toolbar-sort__separate">|</div>
            <div
              className={`toolbar-sort__option ${
                sort === 'desc' ? 'toolbar-sort__option--active' : ''
              }`}
              onClick={() => onSortChange('desc')}
            >
              MỚI TRƯỚC
            </div>
          </div>
          <div className="products-header__add-btn" onClick={() => showModal()}>
            + Chọn nhóm Áp dụng
          </div>
        </div>
      </div>

      <CategoryTable
        loading={isFetching}
        data={data}
        pagination={pagination}
        onRemove={onRemove}
        onPageChange={onPageChange}
      />

      <ModalSelectCategory
        productCategories={productCategories}
        openModal={openModal}
        onCloseModal={closeModal}
        cateIdsOfCoupons={cateIdsOfCoupons}
        couponId={couponId}
        fetchCategoriesOfCoupon={fetchCategoriesOfCoupon}
      />
    </div>
  );
};

export default CategoryList;
