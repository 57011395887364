import { Row, Col, Form } from 'antd';

import { usePresentBlocks } from 'hooks/usePresentBlocks';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { TemplateBlock } from 'ui/components/MaiNguyen/TemplateBlock';

import './ArticleDetailPresent.scss';

const ArticleDetailPresent = () => {
  const {
    reloadList,
    block,
    isOpen,
    handleClosePopup,
    handleOpenModalBlock,
    handleUploadFileContent,
    handleChangeValues,
    handleUpdateMultipleImages,
    handleSaveBlock,
    handleDeleteImage,
  } = usePresentBlocks('[]');

  const [form] = Form.useForm();

  return (
    <>
      <LayoutDetail
        pageTitle="Trình bày trang Chi tiết bài viết"
        breadcrumbs={[
          {
            title: 'Trình bày trang Chi tiết bài viết',
            route: '/trinh-bay-trang-bai-viet',
          },
        ]}
        isAddNew
        wrapperCard={false}
        handleValuesChange={null}
        formHook={null}
        pageClassName="article-detail-present"
        saveLoading={reloadList}
      >
        <Row gutter={16}>
          <Col span={14}>
            <div className="block-item h-100">Chi tiết bài viết</div>
          </Col>
          <Col span={10}>
            <div
              className="banner-block block-item"
              onClick={handleOpenModalBlock('articleDetailBanner', 'banner')}
            >
              Banners
            </div>
          </Col>
        </Row>
      </LayoutDetail>
      <CustomPopup
        title="Display Block"
        isOpen={isOpen}
        onCloseModal={handleClosePopup}
        handleSaveButton={handleSaveBlock}
        formHook={form}
        handleValuesChange={handleChangeValues}
      >
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name">
                <div>{block?.attributes?.name}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Position">
                <div>{block?.attributes?.position}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Template">
                <div>{block?.attributes?.template}</div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <TemplateBlock
                block={block}
                template={block?.attributes?.template}
                onAddImage={handleUploadFileContent}
                onDeleteImage={handleDeleteImage}
                onUpdateSlider={handleUpdateMultipleImages}
              />
            </Col>
          </Row>
        </>
      </CustomPopup>
    </>
  );
};

export default ArticleDetailPresent;
