/* eslint-disable import/order */
/* eslint-disable no-useless-escape */
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { IArticleCategory } from 'interfaces/article.interface';
import { IResponseImage } from 'interfaces/image.interface';
import queryString from 'query-string';
import { ReactNode } from 'react';
import * as api from 'services/api.service';
import { API_UPLOAD } from 'constants/api/auth.api';
import { OrderStatus, STATUS_LIST, StoreIds } from 'constants/enum';
import moment from 'moment';
import {
  IS_DEV,
  MN_STORE_DEV_URL,
  MN_STORE_PROD_URL,
  PAYMENT_METHOD_LIST,
  RIDER_STORE_DEV_URL,
  RIDER_STORE_PROD_URL,
} from 'constants/constants';
import { find, isEmpty } from 'lodash';

type AttributeType = {
  name?: string;
  parentId?: number;
};

export interface TreeLevelInterface {
  id?: string;
  key?: string;
  attributes?: AttributeType;
  title?: ReactNode;
  filterKey?: string;
  children?: TreeLevelInterface[];
}

export const validatePasswordAntInput = (pwd: string, noCheckEmpty: boolean = true) => {
  if (pwd) {
    if (pwd.search(/[a-z]/) < 0) {
      return Promise.reject(new Error('Mật khẩu phải có 1 ký tự viết thường!'));
    } else if (pwd.search(/[A-Z]/) < 0) {
      return Promise.reject(new Error('Mật khẩu phải có 1 ký tự viết hoa!'));
    } else if (pwd.search(/[0-9]/) < 0) {
      return Promise.reject(new Error('Mật khẩu phải có 1 chữ số!'));
    } else if (pwd.search(/[$&+,:;=?@#|'<>.^*()%!-]/) < 0) {
      return Promise.reject(new Error('Mật khẩu phải có 1 ký tự đặc biệt!'));
    } else if (pwd.search(/[ ]/) > 0) {
      return Promise.reject(new Error('Mật khẩu không đc có dấu cách!'));
    } else if (pwd.length < 8) {
      return Promise.reject(new Error('Mật khẩu phải có ít nhất 8 ký tự!'));
    }
    return Promise.resolve();
  } else if (!noCheckEmpty) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Vui lòng nhập mật khẩu!'));
};

export const validateEmailAntInput = (email: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email) {
    if (!regex.test(email)) {
      return Promise.reject(new Error('Email không hợp lệ!'));
    }
    if (!email.includes('@mainguyen.vn')) {
      return Promise.reject(new Error('Email phải là @mainguyen.vn'));
    }
    return Promise.resolve();
  }
  return Promise.reject(new Error('Vui lòng điền tài khoản email!'));
};

export const validateNoWhiteSpace = (input) => {
  return /^\S+$/.test(input);
};

export const validateNoVietnamese = (input) => {
  const regex = /[àáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễđìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹ]/u;
  return input.match(regex);
};

export const validateAuthKeyInput = (authKey: string) => {
  if (authKey) {
    if (authKey.length > 6) {
      return Promise.reject(new Error('Mã bảo mật tối đa 6 ký tự!'));
    }
  }
  return Promise.resolve();
};

export const replaceVie = (input: string) => {
  let output = input;
  output = output.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  output = output.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  output = output.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  output = output.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  output = output.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  output = output.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  output = output.replace(/đ/g, 'd');
  output = output.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  output = output.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  output = output.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  output = output.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  output = output.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  output = output.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  output = output.replace(/Đ/g, 'D');
  return output;
};

export const generateSlug = (str: string) => {
  //Đổi chữ hoa thành chữ thường
  let slug = str.toLowerCase();

  //Đổi ký tự có dấu thành không dấu
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
  slug = slug.replace(/đ/gi, 'd');
  //Xóa các ký tự đặt biệt
  slug = slug.replace(
    /\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|\[|\]|\{|\}|_/gi,
    '',
  );
  //Đổi khoảng trắng thành ký tự gạch ngang
  slug = slug.replace(/ /gi, '-');
  //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
  //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
  slug = slug.replace(/\-\-\-\-\-/gi, '-');
  slug = slug.replace(/\-\-\-\-/gi, '-');
  slug = slug.replace(/\-\-\-/gi, '-');
  slug = slug.replace(/\-\-/gi, '-');
  //Xóa các ký tự gạch ngang ở đầu và cuối
  slug = '@' + slug + '@';
  slug = slug.replace(/\@\-|\-\@|\@/gi, '');
  return slug;
};

export const addSlashToUrl = (url: string): string => {
  return url?.charAt(0) === '/' ? url : `${url ? '/' : ''}${url}`;
};

export const convertToTreeLevel = (data: TreeLevelInterface[]) => {
  const getChildrenCategory = (category?: TreeLevelInterface) => {
    if (!category) {
      const rootCategory = data.filter((c) => !c.attributes.parentId);
      return rootCategory.map((m) => ({
        ...m,
        title: <strong>{m?.attributes?.name}</strong>,
        key: m.id,
        filterKey: replaceVie(m?.attributes?.name),
        parentId: m?.attributes?.parentId,
        children: getChildrenCategory(m),
      }));
    }
    const children = data.filter((c) => `${c.attributes.parentId}` == `${category.id}`);
    return children.map((m) => ({
      ...m,
      title: m?.attributes?.name,
      key: m.id,
      filterKey: replaceVie(m?.attributes?.name),
      parentId: m?.attributes?.parentId,
      children: getChildrenCategory(m),
    }));
  };

  return getChildrenCategory();
};

export const filterInt = (value: string) => {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  } else {
    return NaN;
  }
};

export const formatNumber = (value: number) => {
  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'VND',
  });
  return formatted.format(value).replace('₫', '').concat(' ₫').split(',').join('.');
};

export const addVnCurrency = (value: number | string) => {
  if (value) {
    if (typeof value === 'string') {
      return value.includes('.')
        ? `${value} ₫`
        : value.includes(',')
        ? `${value.replaceAll(',', '.')} ₫`
        : formatNumber(parseInt(value));
    }
    return formatNumber(value);
  }
  return '-';
};

export const fileUploadFormData = (
  file: RcFile,
  type: string,
  typeId: string,
  property?: string,
  overide?: string,
  attributeId?: string,
) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('type', type);
  formData.append('typeId', typeId);
  formData.append('title', file?.name?.split('.').slice(0, -1).join('.'));
  property && formData.append('property', property);
  overide && formData.append('overide', overide);
  attributeId && formData.append('attributeId', attributeId);

  return formData;
};

export const uploadFile = async (
  file: RcFile,
  type: string,
  typeId: string,
  property: string = '',
  overide: string = '',
  attributeId: string = '',
) => {
  if (file) {
    return await api
      .post({
        endpoint: API_UPLOAD,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: fileUploadFormData(file, type, typeId, property, overide, attributeId),
      })
      .then(({ data }: { data: IResponseImage }) => {
        const { attributes, id } = data;
        return {
          ...attributes,
          id,
        };
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : 'Upload image failed, please contact admin!!',
          3,
        );
        return null;
      });
  }
  return null;
};

export const filterSelectNoUnicode = (input: string, option: any) =>
  replaceVie(option.children.toLowerCase()).indexOf(replaceVie(input.toLowerCase())) >= 0;

export const hasParams = (routing: string, params: string = '') => {
  return params ? `${routing}${params ? `/${params}` : ''}` : routing;
};

export const locationHasParams = (location: any) => {
  const pathName = location.pathname.split('/')[1];
  const params = location.pathname.split('/')[2];

  return params ? `${pathName}${params ? `/${params}` : ''}` : pathName;
};

export const filteredTreeData = (originalData: any[], searchValue: string) => {
  const valueWithoutUnicode = replaceVie(searchValue.toLowerCase());
  const hasSearchTerm = (value: string) => value.toLowerCase().indexOf(valueWithoutUnicode) !== -1;

  const filterData = (arr: any[]) =>
    arr?.filter(
      (item) =>
        hasSearchTerm(item.filterKey.toLowerCase()) || filterData(item.children)?.length > 0,
    );
  const filteredData = filterData(originalData).map((item: any) => {
    return {
      ...item,
      children: filterData(item.children),
    };
  });

  return filteredData;
};

export const showErrorMsg = (err: any) => {
  if (typeof err === 'string') {
    message.error(err, 3);
  } else {
    message.error(
      err?.response?.data
        ? err?.response?.data?.message
          ? err?.response?.data?.message
          : JSON.stringify(err?.response?.data)
        : err?.message,
      3,
    );
  }
};

export const getFileExtension = (url: string) => {
  return url?.split(/[#?]/)[0].split('.')?.pop()?.trim();
};

export const calculateDaysDifference = (endDate: string, formatEndDate: string) => {
  const today = moment().startOf('day');
  const end = moment(endDate, formatEndDate).startOf('day');
  const daysDifference = Math.abs(end.diff(today, 'days'));
  return daysDifference;
};

export const generateFileTypeFromExtension = (ext: string) => {
  switch (ext) {
    case 'jpg':
    case 'png':
    case 'jpeg':
    case 'webp':
      return `image/${ext}`;
    case 'mp4':
      return `video/mp4`;
    case 'pdf':
      return 'application/pdf';
    default:
      return '';
  }
};

export const transformAssetSize = (size: number) => {
  const toKb = size / 1024;
  if (toKb >= 900) {
    return `${(toKb / 1024).toFixed(2)}MB`;
  }
  return `${toKb.toFixed(2)}KB`;
};

export const getCurrentPrice = (originPrice: string, salePrice: string) => {
  if (salePrice && parseInt(salePrice) > 0 && parseInt(salePrice) < parseInt(originPrice)) {
    return parseInt(salePrice);
  }
  return parseInt(originPrice);
};

export const getOldPrice = (originPrice: string, salePrice: string) => {
  if (salePrice) {
    return parseInt(salePrice) > 0
      ? parseInt(salePrice) < parseInt(originPrice)
        ? parseInt(originPrice)
        : parseInt(salePrice)
      : null;
  }
  return null;
};

const scrollToPreviewImageItem = (item) => {
  const previewImage: HTMLElement = document.querySelector('.preview-image-' + item.id);
  if (typeof previewImage != 'undefined' && previewImage != null) {
    setTimeout(() => {
      previewImage.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 500);
  }
};

export const orderByPositionMainSlider = (tempSlider, upOrder, index) => {
  const steps = upOrder - (index + 1);

  let i = index;
  if (steps >= 0) {
    while (i <= index + steps) {
      if (tempSlider[i]) {
        tempSlider[i].position = tempSlider[i].position - 1;
      }
      if (i == index) {
        tempSlider[i].position = upOrder;
        scrollToPreviewImageItem(tempSlider[i]);
      }
      i++;
    }
  } else {
    while (i >= index + steps) {
      if (tempSlider[i]) {
        tempSlider[i].position = tempSlider[i].position + 1;
      }
      if (i == index) {
        tempSlider[i].position = upOrder;
        scrollToPreviewImageItem(tempSlider[i]);
      }
      i--;
    }
  }
  return tempSlider;
};

export const noWhitespaceValidator = (control) => {
  return /\s/g.test(control);
};

export const removeVietnameseTones = (str) => {
  /* eslint-disable no-useless-escape */
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' ',
  );
  return str;
};

export const getViewParamInSystemSize = (param: string | null, compareValues: string[]) => {
  if (!param) {
    return compareValues[0];
  }
  return compareValues.includes(param) ? param : compareValues[0];
};

export const getApiParamsInGroupSystemSize = (currentState: {
  page: number;
  searchName: string;
}): string => {
  const params = {
    page: currentState.page,
    title: currentState.searchName || '',
  } as {
    page?: number;
    title?: string;
  };

  return queryString.stringify(params, { skipEmptyString: true });
};

export const transformApiForRider = (endpoint: string): string => {
  if (endpoint?.includes('?')) {
    return `${endpoint}&storeId=${StoreIds.rider}`;
  }
  return `${endpoint}?storeId=${StoreIds.rider}`;
};

export const groupArticleCategoriesByParent = (categories: IArticleCategory[]) => {
  if (!categories?.length) return [];

  const transformCategories = categories.map((c) => ({
    value: Number(c.id),
    parentId: Number(c.attributes.parentId),
    title: c.attributes.name,
    children: [],
  }));

  const grouppedCategories = transformCategories
    .filter((c) => !c.parentId)
    .map((parentCategory) => {
      const children = transformCategories.filter(
        (childCategory) => childCategory.parentId === parentCategory.value,
      );
      parentCategory.children = children;
      return parentCategory;
    });

  return grouppedCategories;
};

// Helper: Show message after copy slug is success
export const clickCopyUrlPath = (text, result) => {
  if (text && result) {
    message.success('Copy thành công!');
  }
};

export const getOrderStatus = (status: string) => {
  if (status === OrderStatus.order) {
    return 'Đã đặt hàng';
  }

  if (status === OrderStatus.processing) {
    return 'Đang xử lý';
  }

  if (status === OrderStatus.delivered) {
    return 'Đang giao hàng';
  }

  if (status === OrderStatus.done) {
    return 'Đã hoàn thành';
  }

  if (status === OrderStatus.cancel) {
    return 'Đã huỷ';
  }

  if (status === OrderStatus.deposit) {
    return 'Đã đặt cọc';
  }

  if (status === OrderStatus.preorder) {
    return 'Đặt hàng trước';
  }

  return 'Đang xử lý';
};

export function extractNumbersFromString(str: string): string {
  const numbersOnly = str.replace(/\D/g, '');
  return numbersOnly;
}
export const downloadCsvFile = ({ data, fileName, fileType }) => {
  const BOM = '\uFEFF';
  const blob = new Blob([BOM + data], { type: fileType });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const downloadFile = async (filename, data) => {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename; //filename
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getFullUrlWithSlug = (storeId: number, slugPath: string) => {
  const domainUrl =
    IS_DEV && storeId === StoreIds.rider
      ? RIDER_STORE_DEV_URL
      : !IS_DEV && storeId === StoreIds.rider
      ? RIDER_STORE_PROD_URL
      : IS_DEV && storeId === StoreIds.mainguyen
      ? MN_STORE_DEV_URL
      : MN_STORE_PROD_URL;
  return `${domainUrl}${slugPath}`;
};

export const sortItemInArray = (arr, fromIndex, toIndex) => {
  const itemRemoved = arr.splice(fromIndex, 1); // assign the removed item as an array
  arr.splice(toIndex, 0, itemRemoved[0]); // insert itemRemoved into the target index
  return arr;
};

export const truncateText = (value: string, startLength: number, endLength: number) => {
  if (!value) return '';

  if (value.length <= startLength + endLength) {
    return value;
  }

  const start = value.slice(0, startLength);
  const end = value.slice(value.length - endLength);

  return `${start}...${end}`;
};

export const truncateWords = (value: string, maxLength: number = 10) => {
  if (!value) return '';

  const array = value.trim().split(' ');
  const ellipsis = array.length > maxLength ? '...' : '';

  return `${value.split(' ').splice(0, maxLength).join(' ')}${ellipsis}`;
};

export const htmlEncode = (str: string) => {
  return str
    .replace(/(http:|)(^|\/\/)(.*?\/)/g, '')
    .replace(/[&\/\\#+=()$~`\[\]%'"@^!|*?+<>{}]/g, '');
};

export const checkMinMaxScore = (rule, value, callback) => {
  if (!isNaN(value) && value > 0 && value <= 5) {
    callback();
    return;
  }

  callback('Vui lòng chọn sao');
};

export const checkTagHtmlInString = (rule, value, callback) => {
  if (!value.match(/<[^>]+>/g)) {
    callback();
    return;
  }

  callback('Vui lòng không điền ký tự đặc biệt');
};

export const genStatusOrder = (status, type = 'label') => {
  const resStatus = find(STATUS_LIST, function (o) {
    return o.value == status;
  });
  return type === 'label' ? resStatus?.label : resStatus?.value;
};

export const capitalizeFirstLetter = (w: string): string => {
  return w.charAt(0).toUpperCase() + w.slice(1);
};

export const genPaymentStatus = (paymentMethod: string) => {
  const idxPayment = PAYMENT_METHOD_LIST.findIndex((item) => item.value === paymentMethod);

  if (idxPayment !== -1) {
    return PAYMENT_METHOD_LIST[idxPayment].label;
  }

  return '-';
};

export const buildParams = (params: any) =>
  !isEmpty(params)
    ? queryString.parse(queryString.stringify(params, { skipEmptyString: true, skipNull: true }))
    : {};
