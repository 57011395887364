import { IBrand } from 'interfaces/brand.interface';
import { fetcher } from 'services/swr.utils';
import useSWR from 'swr';
import { API_GET_BRANDS } from 'constants/api/brand.api';

export const useFetchBrands = () => {
  const { data, isLoading, isValidating, mutate } = useSWR(
    `${API_GET_BRANDS}?perPage=all`,
    fetcher,
  );

  return {
    isFetching: isLoading || isValidating,
    data: data ? data.filter((item: IBrand) => item.attributes.active) : [],
    refetch: mutate,
  };
};
