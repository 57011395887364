/* eslint-disable import/order */
import { fetcher } from 'services/swr.utils';
import useSWR from 'swr';
import { convertToTreeLevel } from 'utils/utils';
import { API_PRODUCT_CATEGORIES } from 'constants/api/product-category.api';

/**
 * Custom hook that fetches and returns product categories data in a tree structure.
 */
export const useFetchProductCategories = () => {
  const { data, isLoading, isValidating, mutate } = useSWR(
    `${API_PRODUCT_CATEGORIES}?perPage=all`,
    fetcher,
  );

  return {
    isFetching: isLoading || isValidating,
    data: data ? convertToTreeLevel(data) : [],
    refetch: mutate,
  };
};

export const useFetchProdCatesByParentId = (parentId) => {
  const { data, isLoading, isValidating } = useSWR(
    parentId ? `${API_PRODUCT_CATEGORIES}?parentId=${parentId}` : null,
    fetcher,
  );

  return {
    isFetching: isLoading || isValidating,
    data: data,
  };
};
