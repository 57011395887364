import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { API_GET_POST_COMMENTS } from 'constants/api/comment.api';
import { IMenuItem } from 'interfaces/menu.interface';
import { IPageData } from 'interfaces/page-data.interface';
import { IAppSettings } from 'interfaces/settings';
import BaseLayout from 'layouts/BaseLayout/BaseLayout';
import Actions from 'layouts/components/Actions/Actions';
import Footer from 'layouts/components/Footer/Foooter';
import Logo from 'layouts/components/Logo/Logo';
import Menu from 'layouts/components/Menu/Menu';
import Navbar from 'layouts/components/Navbar/Navbar';
import NavbarSkeleton from 'layouts/components/NavbarSkeleton/NavbarSkeleton';
import Search from 'layouts/components/Search/Search';
import Settings from 'layouts/components/Settings/Settings';
import { setAllComments } from 'redux/comment/actions';
import { resetPageData } from 'redux/pages/actions';
import { toggleSidebar } from 'redux/settings/actions';
import { AppState } from 'redux/store';
import * as api from 'services/api.service';
import { showErrorMsg } from 'utils/utils';

import 'layouts/BaseLayout/BaseLayout.scss';

import './PrivateLayout.scss';

const getGradientString = (firstColor: string, secondColor: string): string =>
  `linear-gradient(188deg, ${firstColor}, ${secondColor} 65%)`;

const settingsSelector = (state: AppState) => state.settings;
const pageDataSelector = (state: AppState) => state.pageData;

const PrivateLayout: React.FC = (props) => {
  const settings: IAppSettings = useSelector(settingsSelector);
  const pageData: IPageData = useSelector(pageDataSelector);

  const dispatch = useDispatch();

  const {
    sidebarAccentColor,
    sidebarColor,
    topbarColor,
    topbarBg,
    sidebarBg2,
    sidebarBg,
    sidebarAccentContrastColor,
    sidebarOpened,
    boxed,
  } = settings;
  const [menuData, setMenuData] = useState<IMenuItem[]>([]);
  const [settingsVisibility, setSettingsVisibility] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const result = await axios('./data/menu.json');
      setMenuData(result as any);
    }

    if (firstLoad) {
      fetchData();
      setFirstLoad(false);
    }
  }, []);

  useEffect(() => {
    try {
      api.get({ endpoint: `${API_GET_POST_COMMENTS}?perPage=all` }).then((res) => {
        dispatch(setAllComments(res?.data));
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, []);

  const handleToggleSidebar = useCallback(() => {
    dispatch(toggleSidebar());
  }, []);

  const handleResetPage = useCallback(() => {
    dispatch(resetPageData());
  }, []);

  const handleSettingsModalClose = useCallback(() => {
    setSettingsVisibility(false);
  }, []);

  // const handleSettingsClick = useCallback(() => setSettingsVisibility(true), []);

  return (
    <div className="layout vertical">
      <div className={`app-container ${boxed && 'boxed'}`}>
        <Navbar
          style={{ backgroundImage: getGradientString(sidebarBg, sidebarBg2) }}
          orientation="vertical"
          opened={sidebarOpened}
          onCloseNavbar={handleToggleSidebar}
        >
          <button
            className="no-style navbar-close icofont-close-line d-lg-none"
            onClick={handleToggleSidebar}
          />
          <Logo style={{ backgroundColor: topbarBg }} height={33} width={147} />
          <Menu
            color={sidebarColor}
            accentContrast={sidebarAccentContrastColor}
            accentColor={sidebarAccentColor}
            data={menuData}
          />
          <NavbarSkeleton type="vertical" loaded={pageData.fullFilled} />
        </Navbar>

        <Navbar
          boxed={boxed}
          style={{ backgroundColor: topbarBg, color: topbarColor }}
          orientation="horizontal"
          className="horizontal-nav"
          minHeight={60}
        >
          <button className="navbar-toggle d-lg-none" onClick={handleToggleSidebar}>
            <span />
            <span />
            <span />
          </button>

          <Search
            style={{ color: topbarColor }}
            data={menuData}
            dataKey="title"
            className="d-none d-md-block"
          />

          <Actions />

          <NavbarSkeleton type="horizontal" loaded={pageData.fullFilled} />
        </Navbar>

        <BaseLayout
          pageData={pageData}
          settings={settings}
          onPageReset={handleResetPage}
          onSidebarToggle={handleToggleSidebar}
        >
          {props.children}
        </BaseLayout>

        <Footer boxed={boxed} loaded={pageData.fullFilled} />

        {/* <button className="no-style settings-btn" onClick={handleSettingsClick}>
          <span className="pulse" />
          <span className="icofont-bucket2" />
        </button> */}
      </div>

      <Settings opened={settingsVisibility} onClose={handleSettingsModalClose} />
    </div>
  );
};

export default PrivateLayout;
