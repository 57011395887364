import { Card, Empty } from 'antd';
import ReactEcharts from 'echarts-for-react';
import { IOrderStats } from 'interfaces/order-statistics.interface';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from 'ui/components/MaiNguyen/Loading';
import { roundedRevenue, StatsBysMonth, StatsByType } from 'constants/enum';
import FilterStats from './FilterStats';
import statsChart from '../../chart/statsChart';
import { useFetchOrdersStatistics } from '../../hook';
import Title from '../Title';

const OrdersStatistics: React.FC = () => {
  const [filterMonth, setFilterMonth] = useState(StatsBysMonth[1].value);
  const [filterType, setFilterType] = useState(StatsByType[1].value);
  const [option, setOption] = useState(statsChart);

  // Hook get orders statistics
  const { isFetching, data, fetchOrdersStatistics } = useFetchOrdersStatistics();

  // update data of chart
  useEffect(() => {
    const newOption = cloneDeep(option); // immutable

    const arrXAxis: string[] = [];
    const arrOrders: number[] = [];
    const arrRevenue: number[] = [];
    if (data) {
      data.forEach((item: IOrderStats) => {
        const { attributes } = item || {};

        arrXAxis.push(attributes.month || `Tuần ${attributes.week}`);
        arrOrders.push(attributes.totalOrders);
        arrRevenue.push(Math.round(attributes.totalAmount / roundedRevenue));
      });
    }
    newOption.xAxis[0].data = arrXAxis;
    newOption.series[0].data = arrRevenue;
    newOption.series[1].data = arrOrders;

    setOption(newOption);
  }, [data, statsChart]);

  /**
   * Handle the month of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onFilterMonthChange = useCallback(
    (month: string) => {
      setFilterMonth(month);
    },
    [fetchOrdersStatistics],
  );

  /**
   * Handle the type of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onFilterTypeChange = useCallback(
    (type: string) => {
      setFilterType(type);
    },
    [fetchOrdersStatistics],
  );

  // fecth data
  const getOrderStats = async () => {
    fetchOrdersStatistics({ month: filterMonth, type: filterType });
  };

  useEffect(() => {
    getOrderStats();
  }, [fetchOrdersStatistics, filterMonth, filterType]);

  return (
    <Card className="dashboard-card">
      <div className="top-product-list orders-amout-blk">
        <div>
          <div className="dashboard-header mb-3">
            <div className="row">
              <div className="col-12 col-xl-6">
                <Title text="Đơn hàng và Doanh Thu" />
              </div>
              <div className="col-12 col-xl-6 text-right filter-stats">
                <FilterStats
                  filterList={StatsByType}
                  defaultValue={filterType}
                  onFilterChange={onFilterTypeChange}
                />
                <FilterStats
                  filterList={StatsBysMonth}
                  defaultValue={filterMonth}
                  onFilterChange={onFilterMonthChange}
                />
              </div>
            </div>
          </div>

          <div className="relative">
            <Loading isLoading={isFetching} />

            <div className="chart-stats">
              {data?.length ? (
                <ReactEcharts className="chart-container container-h-350" option={option} />
              ) : (
                <div className="blk-empty">
                  <Empty description="Danh sách trống" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OrdersStatistics;
