import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Form, Input, Radio, Row } from 'antd';

import React, { useCallback, useState } from 'react';

import './styles.scss';
import { ColorChangeHandler, SketchPicker } from 'react-color';

interface Props {
  data: MenuItem;
  onChange: (data: string) => void;
}

export interface MenuItem {
  links: LinkItem[];
  buttons: ButtonItem[];
}

type LinkItem = {
  title: string;
  link: string;
  position: string;
  target: '_blank' | 'not';
  scrolling: 'true' | 'false';
};

type ButtonItem = {
  title: string;
  link: string;
  position: string;
  target: '_blank' | 'not';
  backgroundColor: string | null;
  textColor: string | null;
};

export const MenuTemplate: React.FC<Props> = (props) => {
  const {
    data = {
      links: [],
      buttons: [],
    },
    onChange,
  } = props;

  const [displayColorPicker, setDisplayColorPicker] = useState<string | null>(null);

  const _onChange = useCallback(
    (values: Props['data']) => {
      onChange(JSON.stringify(values));
    },
    [onChange],
  );

  const onAddLinkItem = useCallback(() => {
    _onChange({
      ...data,
      links: [
        {
          title: '',
          link: '/',
          position: '',
          target: '_blank',
          scrolling: 'false',
        },
        ...data.links,
      ],
    });
  }, [_onChange, data]);

  const onAddButtonItem = useCallback(() => {
    _onChange({
      ...data,
      buttons: [
        {
          title: '',
          link: '/',
          position: '',
          target: '_blank',
          backgroundColor: null,
          textColor: null,
        },
        ...data.buttons,
      ],
    });
  }, [_onChange, data]);

  const onLinkValuesChange = useCallback(
    (fieldKey: keyof LinkItem, value: string, itemIndex: number) => {
      const currentMenu = data.links[itemIndex];
      currentMenu[fieldKey as any] = value;
      _onChange({ ...data });
    },
    [data, _onChange],
  );

  const onButtonValuesChange = useCallback(
    (fieldKey: keyof ButtonItem, value: string, itemIndex: number) => {
      const currentMenu = data.buttons[itemIndex];
      currentMenu[fieldKey as any] = value;
      _onChange({ ...data });
    },
    [data, _onChange],
  );

  const renderColorPicker = useCallback(
    (key: typeof displayColorPicker, value: string, onColorChange: ColorChangeHandler) => {
      return (
        <div>
          <div className="color-picker__swatch" onClick={() => setDisplayColorPicker(key)}>
            <div className="color-picker__swatch-inner" style={{ background: value }} />
          </div>
          {displayColorPicker === key ? (
            <div className="color-picker__popover">
              <div className="color-picker__cover" onClick={() => setDisplayColorPicker(null)} />
              <SketchPicker color={value || '#FFFFFF'} onChange={onColorChange} />
            </div>
          ) : null}
        </div>
      );
    },
    [displayColorPicker],
  );

  return (
    <Collapse defaultActiveKey={['menu-links', 'menu-buttons']}>
      <Collapse.Panel header="Menu Links" key="menu-links">
        <div className="menu-template">
          <div>Lưu ý:</div>
          <div>
            Trường hợp muốn click vào menu item và scroll xuống một block, nhập link bằng blockId
            (#...) và chọn scrolling = true
          </div>

          <div className="text-right">
            <Button onClick={onAddLinkItem} icon={<PlusOutlined />}>
              Thêm Link
            </Button>
          </div>

          {data?.links?.map((o, i) => {
            return (
              <div className="menu-template__item" key={`${i}`}>
                {i > 0 && <Divider />}
                <span className="menu-template__item-label">#{i + 1}</span>
                <Row gutter={16} className="mt-3 mb-3">
                  <Col span={6}>
                    <Form.Item label="Title">
                      <Input
                        value={o.title}
                        onChange={(e) => onLinkValuesChange('title', e.target.value, i)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Link">
                      <Input
                        value={o.link}
                        onChange={(e) => onLinkValuesChange('link', e.target.value, i)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Target">
                      <Radio.Group
                        onChange={(e) => onLinkValuesChange('target', e.target.value, i)}
                        value={o.target}
                      >
                        <Radio value="_target">_target</Radio>
                        <Radio value="not">not</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Scrolling">
                      <Radio.Group
                        onChange={(e) => onLinkValuesChange('scrolling', e.target.value, i)}
                        value={o.scrolling}
                      >
                        <Radio value="true">true</Radio>
                        <Radio value="false">false</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Position">
                      <Input
                        value={o.position}
                        onChange={(e) => onLinkValuesChange('position', e.target.value, i)}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="text-right" span={24}>
                    <Button
                      className="mt-2"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        _onChange({ ...data, links: data.links.filter((_, pi) => pi !== i) });
                      }}
                    >
                      Xóa
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      </Collapse.Panel>
      <Collapse.Panel header="CTA Buttons" key="menu-buttons">
        <div className="menu-template">
          <div className="text-right">
            <Button onClick={onAddButtonItem} icon={<PlusOutlined />}>
              Thêm button
            </Button>
          </div>

          {data?.buttons?.map((o, i) => {
            return (
              <div className="menu-template__item" key={`${i}`}>
                {i > 0 && <Divider />}
                <span className="menu-template__item-label">#{i + 1}</span>
                <Row gutter={16} className="mt-3 mb-3">
                  <Col span={8}>
                    <Form.Item label="Background Color">
                      {renderColorPicker(`bgColor-${i}`, o?.backgroundColor, (color) =>
                        onButtonValuesChange('backgroundColor', color.hex, i),
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item label="Text Color">
                      {renderColorPicker(`textColor-${i}`, o?.textColor, (color) =>
                        onButtonValuesChange('textColor', color.hex, i),
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Title">
                      <Input
                        value={o.title}
                        onChange={(e) => onButtonValuesChange('title', e.target.value, i)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Link">
                      <Input
                        value={o.link}
                        onChange={(e) => onButtonValuesChange('link', e.target.value, i)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Target">
                      <Radio.Group
                        onChange={(e) => onButtonValuesChange('target', e.target.value, i)}
                        value={o.target}
                      >
                        <Radio value="_target">_target</Radio>
                        <Radio value="not">not</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Position">
                      <Input
                        value={o.position}
                        onChange={(e) => onButtonValuesChange('position', e.target.value, i)}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="text-right" span={24}>
                    <Button
                      className="mt-2"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        _onChange({ ...data, buttons: data.buttons.filter((_, pi) => pi !== i) });
                      }}
                    >
                      Xóa
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};
