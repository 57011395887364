/* eslint-disable import/order */
import { FilterFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import { useFetchBrands } from 'hooks/useFetchBrands';
import { useFetchProductCategories } from 'hooks/useFetchProductCategories';
import { useFetchSubStatus } from 'hooks/useFetchSubStatus';
import { ICollection } from 'interfaces/collection.interface';
import { orderBy, isEmpty } from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { CustomTreeSelect } from 'ui/components/MaiNguyen/CustomTreeSelect';
import { deleteModal } from 'utils/modals';
import { API_COLLECTION } from 'constants/api/collection.api';
import { AddProductModal } from './AddProductModal';
import { useFetchProductsOfCollection, useUpdateCollection } from '../../hooks';
import { ProductTable } from '../ProductTable';
import { buildParams, sortItemInArray } from 'utils/utils';
import { IProduct } from 'interfaces/product.interface';

interface Props {
  collection: ICollection;
}

export const ProductList: React.FC<Props> = (props) => {
  const { collection } = props;

  const collectionId = collection?.id;
  const productIdsOfCollection = collection?.attributes?.productIds?.split(',')?.filter((v) => !!v);
  const totalProducts = productIdsOfCollection?.length;

  const [form] = Form.useForm();
  const location = useLocation();
  const { mutate } = useSWRConfig();
  const { data: productCategories } = useFetchProductCategories();
  const { data: brands } = useFetchBrands();
  const { data: subStatus } = useFetchSubStatus();
  const {
    isFetchingProductsOfCollection,
    productsOfCollection,
    pagination,
    setPagination,
    fetchProductsOfCollection,
  } = useFetchProductsOfCollection(collectionId);
  const { onUpdate } = useUpdateCollection();

  const [openAddProductModal, setOpenAddProductModal] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [sort, setSort] = useState<'desc' | 'asc'>('desc');

  const selectedProductIds = useMemo(() => selectedProducts?.map((p) => p.id), [selectedProducts]);

  /**
   * An effect that fetches the products of the collection when the collection ID changes.
   */
  useEffect((): void => {
    if (collectionId) {
      const params = buildParams(queryString.parse(location.search));
      setSort((params?.direction as any) || 'desc');
      form.setFieldsValue({
        name: params?.name,
        categoryId: params?.categoryId,
        brandId: params?.brandId,
      });
      fetchProductsOfCollection(params);
    }
  }, [collectionId]);

  /**
   * Toggles the Add Product modal by toggling the state of `openAddProductModal`.
   */
  const onToggleAddProductModal = useCallback((): void => {
    setOpenAddProductModal((prev) => !prev);
  }, []);

  /**
   * A callback function that handles the removal of a product from a collection.
   *
   * @param {string} productId - The ID of the product to be removed.
   * @throws {Error} - An error that occurred during the product removal.
   */
  const onRemove = useCallback(
    async (productId: string | string[]): Promise<void> => {
      try {
        const newData = await onUpdate({
          method: 'put',
          id: collectionId,
          data: {
            productIds: productIdsOfCollection
              ?.filter((p) => {
                if (productId?.length) {
                  return !productId.includes(p);
                }
                return p !== productId;
              })
              ?.map((v, idx) => ({ productId: v, position: idx })),
          },
        });
        await mutate(API_COLLECTION.replace('{id}', collectionId), newData, {
          revalidate: false,
          rollbackOnError: true,
        });
        message.success(`Xoá sản phẩm thành công`);
        fetchProductsOfCollection();
      } catch (error: any) {
        message.error(`${error?.message || error}`);
      }
    },
    [collectionId, productIdsOfCollection, fetchProductsOfCollection],
  );

  /**
   * A callback function that handles page changes in pagination.
   *
   * @param {number} page - The new page number.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchProductsOfCollection({ page, direction: sort });
    },
    [fetchProductsOfCollection, sort],
  );

  /**
   * A callback function that handles changes in sorting direction.
   *
   * @param {'desc' | 'asc'} type - The new sorting direction ('desc' for descending, 'asc' for ascending).
   */
  const onSortChange = useCallback(
    async (type: 'desc' | 'asc') => {
      setSort(type);
      await fetchProductsOfCollection({ page: 1, direction: type });
    },
    [fetchProductsOfCollection],
  );

  /**
   * A callback function that handles changes position
   *
   * @param {number} position - The position of the product to be changed.
   * @param {string} productId - The ID of the product to be updated.
   */
  const onPosChange = useCallback(
    async (position: number, productId: string) => {
      // change position in productIds list
      const index = productIdsOfCollection.findIndex((item) => item === productId);
      const newProdIds = sortItemInArray(productIdsOfCollection, index, position);

      try {
        const newData = await onUpdate({
          method: 'put',
          id: collectionId,
          data: {
            productIds: orderBy(
              newProdIds?.map((v, idx) => ({
                productId: v,
                position: idx,
              })),
              ['position'],
              ['asc'],
            ),
          },
        });

        await mutate(API_COLLECTION.replace('{id}', collectionId), newData, {
          revalidate: false,
          rollbackOnError: true,
        });
        message.success(`Cập nhật sản phẩm thành công`);
        await fetchProductsOfCollection();
      } catch (error: any) {
        message.error(`${error?.message || error}`);
      }
    },
    [productIdsOfCollection],
  );

  /**
   * A callback function that selects products based on the provided product IDs.
   *
   * @param {React.Key[]} productIds - An array of product IDs to select.
   */
  const onSelectProducts = useCallback((_, _products: IProduct[]) => {
    setSelectedProducts(_products);
  }, []);

  const onRemoveSelectedProducts = useCallback(() => {
    deleteModal({
      title: `Bạn chắc chắn muốn xoá ${selectedProductIds?.length} sản phẩm khỏi collection?`,
      onDeleteCb: async () => {
        await onRemove(selectedProductIds);
        setSelectedProducts([]);
      },
    });
  }, [selectedProductIds, onRemove]);

  return (
    <div className="collection__products">
      <div className="collection__products-header">
        <div className="products-header__title">
          SẢN PHẨM TRONG COLLECTION <span>{`(${totalProducts || '0'})`}</span>{' '}
          <div className="filter-btn" onClick={() => setShowFilter((prev) => !prev)}>
            <FilterFilled rev={null} />
          </div>
        </div>

        <div className="products-header__toolbar">
          <div className="products-header__toolbar-sort">
            <div
              className={`toolbar-sort__option ${
                sort === 'asc' ? 'toolbar-sort__option--active' : ''
              }`}
              onClick={() => onSortChange('asc')}
            >
              CŨ TRƯỚC
            </div>
            <div className="toolbar-sort__separate">|</div>
            <div
              className={`toolbar-sort__option ${
                sort === 'desc' ? 'toolbar-sort__option--active' : ''
              }`}
              onClick={() => onSortChange('desc')}
            >
              MỚI TRƯỚC
            </div>
          </div>
          <div className="products-header__add-btn" onClick={onToggleAddProductModal}>
            + Thêm sản phẩm
          </div>
        </div>
      </div>
      {showFilter && (
        <div className="products-header__filter">
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="SKU / Tên sản phẩm" name="name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Nhóm sản phẩm"
                  name="categoryId"
                  valuePropName="selectedValue"
                  trigger="handleSelect"
                >
                  <CustomTreeSelect data={productCategories} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Thương hiệu" name="brandId">
                  <Select
                    className="ant-select-lg"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    filterOption={(input, option) => {
                      return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  >
                    {brands?.map((brand) => (
                      <Select.Option value={brand.id} key={brand.id} title={brand.attributes.name}>
                        {brand.attributes.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                span={24}
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}
              >
                <Button
                  className="secondary-button"
                  onClick={async () => {
                    const values = form.getFieldsValue();
                    await fetchProductsOfCollection({ page: 1, ...values });
                  }}
                >
                  Filter
                </Button>

                <Button
                  className="ml-2"
                  onClick={async () => {
                    form.resetFields();
                    await fetchProductsOfCollection({
                      page: 1,
                      name: null,
                      categoryId: null,
                      brandId: null,
                    });
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
      {!!selectedProductIds?.length && (
        <Button className="collections-product__remove" onClick={onRemoveSelectedProducts}>
          Xoá {selectedProductIds.length} sản phẩm đã chọn
        </Button>
      )}

      <ProductTable
        loading={isFetchingProductsOfCollection}
        subStatus={subStatus}
        data={productsOfCollection}
        pagination={pagination}
        productLength={totalProducts}
        onRemove={(productId) => {
          deleteModal({
            title: 'Bạn có muốn xóa sản phẩm này?',
            onDeleteCb: async () => {
              await onRemove(productId);
            },
          });
        }}
        onPageChange={onPageChange}
        onPosChange={onPosChange}
        rowSelection={{
          selectedRowKeys: selectedProductIds,
          onChange: onSelectProducts,
        }}
      />
      {!isEmpty(collection) && (
        <AddProductModal
          isOpen={openAddProductModal}
          collectionId={collectionId}
          brands={brands}
          productIdsOfCollection={productIdsOfCollection}
          productCategories={productCategories}
          subStatus={subStatus}
          onClose={onToggleAddProductModal}
          fetchProductsOfCollection={async () =>
            await fetchProductsOfCollection({ direction: sort })
          }
        />
      )}
    </div>
  );
};
