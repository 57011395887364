import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AlignType } from 'constants/enum';
import { IImage } from 'interfaces/image.interface';
import { IProduct, IProductAttribute } from 'interfaces/product.interface';
import BaseProductsTable, {
  BrandLogoCell,
} from 'pages/Products/ProductDetail/components/BaseProductsTable/BaseProductsTable';
import { AppState } from 'redux/store';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { renderColorStatus } from 'utils/renderColorStatus';
import { addVnCurrency, getCurrentPrice, getOldPrice } from 'utils/utils';

import './SearchedTable.scss';

interface Props {
  loading?: boolean;
  data?: IProduct[];
  onRemoveSelectedProd: (product: IProduct) => void;
  className?: string;
  imageWidth?: number;
  priceWidth?: number;
  statusWidth?: number;
  sortable?: boolean;
  handleChangePosition?: (data: any) => void;
}

const poroductState = (state: AppState) => state.productReducers;

export const SelectedTable: FC<Props> = ({
  data,
  loading,
  onRemoveSelectedProd,
  className = '',
  imageWidth = 70,
  priceWidth = 120,
  statusWidth = 180,
  sortable = false,
  handleChangePosition = null,
}) => {
  const { subStatuses } = useSelector(poroductState);

  const handleRemoveSelecedProduct = useCallback(
    (row: IProduct) => () => {
      onRemoveSelectedProd(row);
    },
    [onRemoveSelectedProd],
  );

  const columns = [
    {
      title: 'Hình ảnh',
      width: imageWidth,
      dataIndex: 'attributes',
      render: (attr: IProductAttribute, row: IProduct) => {
        const imageValues = attr?.attributeValues?.find((item) => item.attributeCode === 'image');
        const image = imageValues?.value.find((item: IImage) => item.property === 'main');

        return (
          <NavLink to={`/san-pham/${row.id}`} className="product-image">
            <BrandLogoCell image={image?.['128x128']} />
          </NavLink>
        );
      },
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'attributes',
      width: 180,
      render: (attr: IProductAttribute, row: IProduct) => (
        <>
          <NavLink to={`/san-pham/${row.id}`} className="product-name">
            <SanitizeHtml rawHtml={`<strong>${attr.name}</strong>`} title={attr.name} />
          </NavLink>
          <div className="product-name" title={attr.sku}>{`SKU: ${attr.sku}`}</div>
          <div className="product-name" title={attr?.type}>
            {`Type: ${attr?.type}`}
          </div>
        </>
      ),
    },
    {
      title: 'Giá',
      dataIndex: 'attributes',
      width: priceWidth,
      render: (attr: IProductAttribute) => {
        const currentPrice = attr?.attributeValues.find(
          (item) => item.attributeCode === 'original_price',
        );
        const salePrice = attr?.attributeValues.find((item) => item.attributeCode === 'sale_price');
        const newPrice = addVnCurrency(getCurrentPrice(currentPrice?.value, salePrice?.value));
        const oldPrice = addVnCurrency(getOldPrice(currentPrice?.value, salePrice?.value));
        return (
          <>
            <div className="product-price">{newPrice}</div>
            <div className={oldPrice !== '-' ? 'old-price' : ''}>{oldPrice}</div>
          </>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'attributes',
      width: statusWidth,
      render: (attr: IProductAttribute) => {
        const status = subStatuses?.find((item) => item.id === attr?.statusId?.toString());
        return (
          <div className="text-center">
            {status && (
              <Tag
                color={renderColorStatus(status?.attributes?.parentId)}
                style={{ whiteSpace: 'pre-line' }}
              >
                {status?.attributes?.name}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      width: 70,
      fixed: 'right',
      align: AlignType.right,
      render: (row: IProduct) => (
        <Button title="Thêm" icon={<DeleteOutlined />} onClick={handleRemoveSelecedProduct(row)} />
      ),
    },
  ];

  return (
    <BaseProductsTable
      className={`${className} searched-table`}
      sortable={sortable}
      showHeader={false}
      handleChangePosition={handleChangePosition}
      loading={loading}
      columns={columns}
      dataList={data}
    />
  );
};
