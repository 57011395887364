import TimeAgoJs from 'javascript-time-ago';
import vi from 'javascript-time-ago/locale/vi.json';
import { FC } from 'react';
import ReactTimeAgo from 'react-time-ago';

TimeAgoJs.addDefaultLocale(vi);

interface TimeAgoProps {
  date: Date;
}

export const TimeAgo: FC<TimeAgoProps> = ({ date }) => <ReactTimeAgo date={date} locale="vi" />;
