/* eslint-disable react/jsx-no-bind */
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import CustomerTopOrders from './components/CustomerTopOrders';
import OrdersStatistics from './components/OrdersStatistics';
import TopCouponsUsed from './components/TopCouponsUsed';
import TopProductComments from './components/TopProductComments';
import TopProductTopOrders from './components/TopProductOrders';
import TopProductReviews from './components/TopProductReviews';
import './index.styles.scss';
import TopProductWishlist from './components/TopProductWishlist';

const Dashboard = () => {
  return (
    <>
      <LayoutDetail
        pageTitle="Bảng điều khiển"
        breadcrumbs={[
          {
            title: 'Bảng điều khiển',
            route: '/',
          },
        ]}
        wrapperCard={false}
        isAddNew
        formHook={null}
        handleSave={() => {}}
        handleValuesChange={null}
        handleClickAddButton={() => {}}
        saveLoading
      >
        <div className="dashboard-blk">
          <div className="row">
            <div className="col-12 db-col col-xl-7 mb-4">
              <OrdersStatistics />
            </div>
            <div className="col-12 db-col col-xl-5 mb-4">
              <CustomerTopOrders />
            </div>
          </div>
        </div>
        <div className="dashboard-blk">
          <div className="row">
            <div className="col-12 db-col col-xl-6 mb-4">
              <TopProductReviews />
            </div>
            <div className="col-12 db-col col-xl-6 mb-4">
              <TopProductComments />
            </div>
          </div>
        </div>
        <div className="dashboard-blk">
          <div className="row">
            <div className="col-12 db-col col-xl-6 mb-4">
              <TopProductWishlist />
            </div>
            <div className="col-12 db-col col-xl-6 mb-4">
              <TopProductTopOrders />
            </div>
          </div>
        </div>
        <div className="dashboard-blk">
          <TopCouponsUsed />
        </div>
      </LayoutDetail>
    </>
  );
};

export default Dashboard;
