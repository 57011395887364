/* eslint-disable import/order */
import { DeleteOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Form, Row, Col, Input, Select, Switch, Checkbox, Tag, message } from 'antd';

import { IBrand } from 'interfaces/brand.interface';
import { IImage } from 'interfaces/image.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { IPrdCategory } from 'interfaces/product-category.interface';
import { IProductAttribute, IProduct } from 'interfaces/product.interface';
import { IStatus } from 'interfaces/status.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import { clone } from 'lodash';
import { ProductModel } from 'models/product.model';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomTreeSelect } from 'ui/components/MaiNguyen/CustomTreeSelect';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { confirmModal, deleteModal } from 'utils/modals';
import { renderColorStatus } from 'utils/renderColorStatus';
import {
  addVnCurrency,
  convertToTreeLevel,
  getCurrentPrice,
  getOldPrice,
  showErrorMsg,
} from 'utils/utils';
import { API_GET_BRANDS } from 'constants/api/brand.api';
import { API_PRODUCT_CATEGORIES } from 'constants/api/product-category.api';
import {
  API_GET_PRODUCTS,
  API_GET_PRODUCT_DETAIL,
  API_GET_PRODUCT_TYPES,
  API_RESTORE_DELETED_PRODUCT,
  API_DELETE_PRODUCT_FOREVER,
  API_COPY_PRODUCT,
  API_SETTINGS,
} from 'constants/api/product.api';
import { API_GET_SUB_STATUS } from 'constants/api/status.api';
import { defaultPagination } from 'constants/constants';
import { AlignType } from 'constants/enum';

import './ProductList.scss';

type FormFilterProduct = {
  name: string;
  categoryId: string;
  brandId: string;
  statusId: string;
  productType: string;
  isDeleted: boolean;
  isSecondhand: boolean;
};

const defaultFilterParams = {
  name: '',
  categoryId: '',
  brandId: '',
  statusId: '',
  productType: '',
  isDeleted: false,
  isSecondhand: false,
};

const ProductsList = () => {
  const search = useLocation().search;
  const qsPage = new URLSearchParams(search).get('page');
  const [reloadList, setReloadList] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [productCategories, setProductCategories] = useState<IPrdCategory[]>([]);
  const [allProductCategories, setAllProductCategories] = useState<IPrdCategory[]>([]);
  const [subStatuses, setSubStatuses] = useState<IStatus[]>([]);
  const [productTypes, setProductTypes] = useState<string[]>([]);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [categoryParentId, setCategoryParentId] = useState('');
  const [formFilterProduct, setFormFilterProduct] =
    useState<FormFilterProduct>(defaultFilterParams);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isSaleOnline, setIsSaleOnline] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleChangePage = useCallback(
    (page: number) => {
      const pagin = pagination;
      pagin.current = page;
      if (search) {
        const qsName = new URLSearchParams(search).get('name');
        const qsBrandId = new URLSearchParams(search).get('brandId');
        const qsCategoryId = new URLSearchParams(search).get('categoryId');
        const qsIsDelete = new URLSearchParams(search).get('isDeleted');
        const qsIsSecondhand = new URLSearchParams(search).get('isSecondhand');
        const qsProductType = new URLSearchParams(search).get('productType');
        const qsStatusId = new URLSearchParams(search).get('statusId');

        const nameFilter = qsName ? `&name=${qsName}` : '';
        const categoryFilter = qsCategoryId ? `&categoryId=${qsCategoryId}` : '';
        const brandFilter = qsBrandId ? `&brandId=${qsBrandId}` : '';
        const statusFilter = qsStatusId ? `&statusId=${qsStatusId}` : '';
        const typeFilter = qsProductType ? `&type=${qsProductType}` : '';
        const deletedPrdFilter = qsIsDelete ? '&isDeleted=1' : '';
        const secondhandPrdFilter = qsIsSecondhand ? '&isSecondhand=1' : '';

        history.push(
          `/san-pham?page=${page}${nameFilter}${categoryFilter}${brandFilter}${statusFilter}${typeFilter}${deletedPrdFilter}${secondhandPrdFilter}`,
        );
      }
      setPagination(pagin);
      setReloadList(true);
    },
    [pagination],
  );

  useEffect(() => {
    // set filter
    const qsName = new URLSearchParams(search).get('name');
    const qsBrandId = new URLSearchParams(search).get('brandId');
    const qsCategoryId = new URLSearchParams(search).get('categoryId');
    const qsIsDelete = new URLSearchParams(search).get('isDeleted');
    const qsIsSecondhand = new URLSearchParams(search).get('isSecondhand');
    const qsProductType = new URLSearchParams(search).get('productType');
    const qsStatusId = new URLSearchParams(search).get('statusId');

    if (search) {
      setFormFilterProduct({
        ...formFilterProduct,
        name: qsName,
        brandId: qsBrandId,
        categoryId: qsCategoryId,
        productType: qsProductType,
        statusId: qsStatusId,
        isDeleted: qsIsDelete === '1' ? true : false,
        isSecondhand: qsIsSecondhand === '1' ? true : false,
      });
      form.setFieldsValue({
        ...defaultFilterParams,
        name: qsName,
        brandId: qsBrandId,
        categoryId: qsCategoryId,
        productType: qsProductType,
        statusId: qsStatusId,
        isDeleted: qsIsDelete === '1' ? true : false,
        isSecondhand: qsIsSecondhand === '1' ? true : false,
      });
    }

    if (qsPage) {
      const pagin = pagination;
      pagin.current = parseInt(qsPage);
      setPagination(pagin);
    }
  }, [search, qsPage]);

  useEffect(() => {
    const qsName = new URLSearchParams(search).get('name');
    const qsBrandId = new URLSearchParams(search).get('brandId');
    const qsCategoryId = new URLSearchParams(search).get('categoryId');
    const qsIsDelete = new URLSearchParams(search).get('isDeleted');
    const qsIsSecondhand = new URLSearchParams(search).get('isSecondhand');
    const qsProductType = new URLSearchParams(search).get('productType');
    const qsStatusId = new URLSearchParams(search).get('statusId');

    const nameFilter = qsName ? `&name=${qsName}` : '';
    const categoryFilter = qsCategoryId ? `&categoryId=${qsCategoryId}` : '';
    const brandFilter = qsBrandId ? `&brandId=${qsBrandId}` : '';
    const statusFilter = qsStatusId ? `&statusId=${qsStatusId}` : '';
    const typeFilter = qsProductType ? `&type=${qsProductType}` : '';
    const deletedPrdFilter = qsIsDelete ? '&isDeleted=1' : '';
    const secondhandPrdFilter = qsIsSecondhand ? '&isSecondhand=1' : '';

    const urlProductList = `${API_GET_PRODUCTS}?page=${pagination.current}${nameFilter}${categoryFilter}${brandFilter}${statusFilter}${typeFilter}${deletedPrdFilter}${secondhandPrdFilter}`;

    getPageData(`${urlProductList}`, dispatch)
      .then((res) => {
        const pagin = res?.meta?.pagination;
        setProducts(res?.data);
        setPagination({
          current: pagin?.currentPage,
          pageSize: pagin?.perPage,
          total: pagin?.total,
        });
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, []);

  useEffect(() => {
    const {
      name,
      categoryId,
      brandId,
      statusId,
      productType,
      isDeleted: deleted,
      isSecondhand: secondhand,
    } = formFilterProduct;
    const nameFilter = name ? `&name=${name}` : '';
    const categoryFilter = categoryId ? `&categoryId=${categoryId}` : '';
    const brandFilter = brandId ? `&brandId=${brandId}` : '';
    const statusFilter = statusId ? `&statusId=${statusId}` : '';
    const typeFilter = productType ? `&type=${productType}` : '';
    const deletedPrdFilter = deleted ? '&isDeleted=1' : '';
    const secondhandPrdFilter = secondhand ? '&isSecondhand=1' : '';

    if (reloadList) {
      api
        .get({
          endpoint: `${API_GET_PRODUCTS}?page=${pagination.current}${nameFilter}${categoryFilter}${brandFilter}${statusFilter}${typeFilter}${deletedPrdFilter}${secondhandPrdFilter}`,
        })
        .then((res) => {
          const pagin = res?.meta?.pagination;
          setPagination({
            current: pagin?.currentPage,
            pageSize: pagin?.perPage,
            total: pagin?.total,
          });
          setProducts(res?.data);
          setIsDeleted(deleted);
          setReloadList(false);
        })
        .catch((err) => {
          setIsDeleted(deleted);
          setReloadList(false);
          showErrorMsg(err);
        });
    }
  }, [reloadList, formFilterProduct]);

  useEffect(() => {
    Promise.all([
      api.get({ endpoint: `${API_GET_BRANDS}?perPage=all` }),
      api.get({ endpoint: `${API_PRODUCT_CATEGORIES}?perPage=all` }),
      api.get({ endpoint: `${API_GET_SUB_STATUS}?perPage=all` }),
      api.get({ endpoint: `${API_GET_PRODUCT_TYPES}?perPage=all` }),
      api.get({ endpoint: `${API_SETTINGS}` }),
    ])
      .then(([brandRes, productCategoriesRes, statusRes, productTypeRes, settingsRes]) => {
        const categories = clone(
          convertToTreeLevel(productCategoriesRes?.data.filter((item) => item.attributes.active)),
        );
        categories.forEach((item) => {
          item['title'] = <strong>{item.attributes.name}</strong>;
        });
        setBrands(brandRes?.data.filter((item: IBrand) => item.attributes.active));
        setAllProductCategories(productCategoriesRes?.data);
        setProductCategories(categories as IPrdCategory[]);
        setSubStatuses(statusRes?.data.filter((item: IStatus) => item.attributes.active));
        setProductTypes(productTypeRes?.data);
        setIsSaleOnline(settingsRes?.data?.attributes?.saleOnline);
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, []);

  const handleChangeParentId = useCallback(
    (value: string) => {
      setCategoryParentId(value);
      setFormFilterProduct({ ...formFilterProduct, categoryId: value });
    },
    [formFilterProduct],
  );

  const handleChangeFormFilter = useCallback(
    (_, values) => {
      setFormFilterProduct({ ...values, categoryId: categoryParentId });
    },
    [categoryParentId],
  );

  const handleFilterProducts = useCallback(() => {
    handleChangePage(1);
  }, [handleChangePage]);

  const handleClearFilter = useCallback(() => {
    setFormFilterProduct(defaultFilterParams);
    form.setFieldsValue(defaultFilterParams);
    setCategoryParentId('');
    // remove query params
    if (search) {
      history.replace({
        search: '',
      });
    }
    handleChangePage(1);
  }, [handleChangePage]);

  const handleDeleteProduct = useCallback(
    (id: string) => () => {
      deleteModal({
        title: 'Bạn có muốn xóa sản phẩm này?',
        onDeleteCb: async () => {
          try {
            await api.remove({ endpoint: API_GET_PRODUCT_DETAIL.replace('{id}', id) });
            setReloadList(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const handleDeleteForever = useCallback(
    (id: string) => () => {
      deleteModal({
        title: 'Bạn có muốn xóa sản phẩm này vĩnh viễn, sau khi xóa sẽ không thể phục hồi?',
        onDeleteCb: async () => {
          try {
            await api.remove({ endpoint: API_DELETE_PRODUCT_FOREVER.replace('{id}', id) });
            setReloadList(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const handlePatchProduct = useCallback(
    (rowData: IProduct) => async () => {
      try {
        const restoreProduct = new ProductModel(rowData.attributes);
        await api.patch({
          endpoint: API_RESTORE_DELETED_PRODUCT.replace('{id}', rowData.id),
          data: restoreProduct,
        });
        handleChangePage(pagination.current);
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [handleChangePage, pagination],
  );

  const handleChangeActiveStatus = useCallback(
    (rowData: IProduct) => async (checked: boolean) => {
      const currentProduct = {
        name: rowData?.attributes?.name,
        slug: rowData?.attributes?.slug,
        active: checked,
      };
      try {
        await api.put({
          endpoint: API_GET_PRODUCT_DETAIL.replace('{id}', rowData.id),
          data: currentProduct,
        });
        setReloadList(true);
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [products],
  );

  const handleCopyProduct = (productId: string) => () => {
    confirmModal({
      title: 'Bạn có chắc muốn sao chép sản phẩm này ra 1 sản phẩm mới!',
      buttonText: 'OK',
      onSaveCb: async () => {
        try {
          await api.get({ endpoint: API_COPY_PRODUCT.replace('{id}', productId) });
          setReloadList(true);
          message.success('Copy sản phẩm thành công');
        } catch (error) {
          showErrorMsg(error);
        }
      },
    });
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute) => <div>{`${attr.sku}`}</div>,
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute, row: IProduct) => {
        const imageValues = attr.attributeValues.find((item) => item.attributeCode === 'image');
        const image = imageValues?.value.find((item: IImage) => item.property === 'main');
        const {
          name,
          categoryId,
          brandId,
          statusId,
          productType,
          isDeleted: deleted,
          isSecondhand: secondhand,
        } = formFilterProduct;
        const nameFilter = name ? `&name=${name}` : '';
        const categoryFilter = categoryId ? `&categoryId=${categoryId}` : '';
        const brandFilter = brandId ? `&brandId=${brandId}` : '';
        const statusFilter = statusId ? `&statusId=${statusId}` : '';
        const typeFilter = productType ? `&type=${productType}` : '';
        const deletedPrdFilter = deleted ? '&isDeleted=1' : '';
        const secondhandPrdFilter = secondhand ? '&isSecondhand=1' : '';

        return (
          <div className="product-image">
            {!isDeleted ? (
              <NavLink
                to={`/san-pham/${row.id}?page=${pagination.current}${nameFilter}${categoryFilter}${brandFilter}${statusFilter}${typeFilter}${deletedPrdFilter}${secondhandPrdFilter}`}
              >
                <img src={image?.['128x128']} alt={image?.title} />
              </NavLink>
            ) : (
              <img src={image?.['128x128']} alt={image?.title} />
            )}
          </div>
        );
      },
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute, row: IProduct) => {
        const {
          name,
          categoryId,
          brandId,
          statusId,
          productType,
          isDeleted: deleted,
          isSecondhand: secondhand,
        } = formFilterProduct;
        const nameFilter = name ? `&name=${name}` : '';
        const categoryFilter = categoryId ? `&categoryId=${categoryId}` : '';
        const brandFilter = brandId ? `&brandId=${brandId}` : '';
        const statusFilter = statusId ? `&statusId=${statusId}` : '';
        const typeFilter = productType ? `&type=${productType}` : '';
        const deletedPrdFilter = deleted ? '&isDeleted=1' : '';
        const secondhandPrdFilter = secondhand ? '&isSecondhand=1' : '';

        return !isDeleted ? (
          <NavLink
            to={`/san-pham/${row.id}?page=${pagination.current}${nameFilter}${categoryFilter}${brandFilter}${statusFilter}${typeFilter}${deletedPrdFilter}${secondhandPrdFilter}`}
          >
            <SanitizeHtml rawHtml={attr.name} className="product-name" title={attr.name} />
            <div>{`SKU: ${attr.sku}`}</div>
            <div className="product-type">{`${attr.type}`}</div>
          </NavLink>
        ) : (
          <div>
            <SanitizeHtml rawHtml={attr.name} className="deleted-product-name" title={attr.name} />
            <div>{`SKU: ${attr.sku}`}</div>
            <div className="product-type">{`${attr.type}`}</div>
          </div>
        );
      },
    },
    {
      title: 'Thương hiệu',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute) => {
        const brand = brands.find((item) => item.id === attr?.brandId?.toString());
        return <SanitizeHtml rawHtml={brand?.attributes?.name} />;
      },
    },
    {
      title: 'Nhóm sản phẩm',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute) => {
        const categorry = allProductCategories.find(
          (item) => item.id === attr?.categoryId?.toString(),
        );
        return categorry?.attributes?.name;
      },
    },
    {
      title: 'Giá hiện tại',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute) => {
        const currentPrice = attr.attributeValues.find(
          (item) => item.attributeCode === 'original_price',
        );
        const salePrice = attr.attributeValues.find((item) => item.attributeCode === 'sale_price');
        const newPrice = addVnCurrency(getCurrentPrice(currentPrice?.value, salePrice?.value));
        const oldPrice = addVnCurrency(getOldPrice(currentPrice?.value, salePrice?.value));

        return (
          <>
            <div className="product-price">{newPrice}</div>
            <div className={oldPrice !== '-' ? 'old-price' : ''}>{oldPrice}</div>
          </>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute) => {
        const status = subStatuses.find((item) => item.id === attr?.statusId?.toString());
        return (
          status && (
            <Tag color={renderColorStatus(status?.attributes?.parentId)}>
              {status?.attributes?.name}
            </Tag>
          )
        );
      },
    },
    {
      title: 'Hiển thị',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute, row: IProduct) => {
        return (
          <Switch
            checked={attr.active}
            onChange={handleChangeActiveStatus(row)}
            disabled={isDeleted}
          />
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      align: AlignType.right,
      render: (row: IProduct) =>
        isDeleted ? (
          <div style={{ display: 'flex' }}>
            <Button
              title="Khôi phục"
              className="mr-2"
              icon={<PlusOutlined />}
              onClick={handlePatchProduct(row)}
            />
            <Button
              title="Xóa vĩnh viễn"
              icon={<DeleteOutlined />}
              onClick={handleDeleteForever(row.id)}
            />
          </div>
        ) : (
          <>
            &nbsp;
            <Button
              title="Remove"
              className="mb-2"
              icon={<DeleteOutlined />}
              onClick={handleDeleteProduct(row.id)}
            />
            &nbsp;
            {row.attributes.type !== 'grouped' && (
              <Button title="Copy" icon={<CopyOutlined />} onClick={handleCopyProduct(row.id)} />
            )}
          </>
        ),
    },
  ];

  const handleAddNewProduct = useCallback(() => {
    history.push('/san-pham/new');
  }, []);

  const handleChangeSaleOnline = useCallback(
    () => async (checked: boolean) => {
      const postData = {
        saleOnline: checked,
      };
      setIsSaleOnline(checked);
      try {
        await api.post({
          endpoint: API_SETTINGS,
          data: postData,
        });
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [products],
  );

  return (
    <div className="products-page">
      <div className="d-flex align-items-center blk-saleonline mb-3 justify-content-end">
        <Switch checked={isSaleOnline} onChange={handleChangeSaleOnline()} className="mr-3" />
        Chức năng mua online đang: <b className="pl-1">{isSaleOnline ? 'Bật' : 'Tắt'}</b>
      </div>
      <LayoutList
        pageTitle="Sản phẩm"
        showAddButton
        dataList={products}
        breadcrumbs={[
          {
            title: 'Sản phẩm',
            route: '/san-pham',
          },
        ]}
        handleClickAddButton={handleAddNewProduct}
        columns={columns}
        tableClass="product-table"
        pagination={pagination}
        topPagination
        loadingList={reloadList}
        handleChangePage={handleChangePage}
        filterNode={
          <Form
            layout="vertical"
            form={form}
            onValuesChange={handleChangeFormFilter}
            onFinish={handleFilterProducts}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Tên sản phẩm/SKU" name="name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Nhóm sản phẩm" name="categoryId">
                  <CustomTreeSelect
                    data={productCategories}
                    selectedValue={categoryParentId}
                    handleSelect={handleChangeParentId}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Thương hiệu" name="brandId">
                  <Select
                    className="ant-select-lg"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    filterOption={(input, option) => {
                      return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  >
                    {brands?.map((brand) => (
                      <Select.Option value={brand.id} key={brand.id} title={brand.attributes.name}>
                        <SanitizeHtml rawHtml={brand.attributes.name} />
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Trạng thái" name="statusId">
                  <Select
                    className="ant-select-lg"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                  >
                    {subStatuses?.map((sts) => (
                      <Select.Option value={sts.id} key={sts.id}>
                        {sts.attributes.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Loại sản phẩm" name="productType">
                  <Select
                    className="ant-select-lg"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                  >
                    {productTypes?.map((type) => (
                      <Select.Option value={type} key={type}>
                        {type}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Đã bị xóa" name="isDeleted" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Sản phẩm dùng rồi" name="isSecondhand" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col
                span={24}
                className="align-items-center justify-content-end mt-4"
                style={{ display: 'flex' }}
              >
                <Form.Item>
                  <Button htmlType="submit" className="secondary-button">
                    Filter
                  </Button>
                </Form.Item>
                <Form.Item className="ml-2">
                  <Button onClick={handleClearFilter}>Clear</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
    </div>
  );
};

export default ProductsList;
