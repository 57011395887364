import { Modal } from 'antd';
import React, { useEffect } from 'react';

import './styles.scss';
import { useFetchOrderDetails } from 'pages/CouponDetail/hooks';
import isEmpty from 'lodash/isEmpty';
import Skeleton from 'react-loading-skeleton';
import { formatNumber, getOrderStatus } from 'utils/utils';

interface Props {
  orderCode: string;
  visible: boolean;
  onClose: () => void;
}

export const OrderDetailModal: React.FC<Props> = (props) => {
  const { orderCode, visible, onClose } = props;

  const { isFetching, data, fetch } = useFetchOrderDetails(orderCode);
  const {
    customerName,
    customerGender,
    customerPhone,
    shippingAddress,
    addition,
    total,
    items,
    status,
  } = data?.attributes || {};

  useEffect(() => {
    if (visible) fetch();
  }, [visible]);

  return (
    <Modal
      footer={null}
      visible={visible}
      className="coupon-order-detail-modal"
      title={`Đơn hàng #${data?.id?.toUpperCase()}`}
      onCancel={onClose}
      width={640}
    >
      {isFetching ? (
        <Skeleton count={9} />
      ) : (
        <>
          <div className="order-detail__info">
            <div className="order-detail__info-item">
              <div className="info-item__label">Người nhận</div>
              <div className="info-item__value">
                {customerGender === 'male' ? 'Anh' : 'Chị'} {customerName} - {customerPhone}
              </div>
            </div>
            <div className="order-detail__info-item">
              <div className="info-item__label">Giao đến</div>
              {shippingAddress?.address &&
                shippingAddress?.street &&
                shippingAddress?.ward &&
                shippingAddress?.district &&
                shippingAddress?.city && (
                  <div className="info-item__value">
                    {`${shippingAddress.address}, ${shippingAddress.ward}, ${shippingAddress.district}, ${shippingAddress.city}`}
                  </div>
                )}
            </div>
            {!isEmpty(addition?.callAnother) && (
              <div className="order-detail__info-item">
                <div className="info-item__label">Người nhận khác</div>
                <div className="info-item__value">
                  {addition.callAnother.customerGender} {addition.callAnother.customerName} -{' '}
                  {addition.callAnother.customerPhone}
                </div>
              </div>
            )}
            {!isEmpty(addition?.companyInvoice) && (
              <div className="order-detail__info-item">
                <div className="info-item__label">Xuất hoá đơn</div>
                <div className="info-item__value">
                  Công ty {addition.companyInvoice.companyName}
                </div>
              </div>
            )}
            {!isEmpty(addition?.companyInvoice) && (
              <div className="order-detail__info-item">
                <div className="info-item__label">Địa chỉ công ty</div>
                <div className="info-item__value">{addition.companyInvoice.companyAddress}</div>
              </div>
            )}
            {!isEmpty(addition?.companyInvoice) && (
              <div className="order-detail__info-item">
                <div className="info-item__label">MST</div>
                <div className="info-item__value">{addition.companyInvoice.companyMst}</div>
              </div>
            )}

            {/* <div className="order-detail__info-item">
          <div className="info-item__label">Dự kiến giao ngày</div>
          <div className="info-item__value info-item__value--bold">27/08/2021</div>
        </div> */}
          </div>

          <div className="order-detail__status">
            <div className="status-label">Trạng thái</div>
            <div className="status-value--processing">{getOrderStatus(status)}</div>
          </div>

          <div className="order-detail__products">
            {items?.map((p) => {
              return (
                <div key={p.id} className="product-item">
                  <div className="product-item__main-content">
                    {/* <img
                  alt="product-image"
                  src="https://images.unsplash.com/photo-1603791239531-1dda55e194a6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YXBwbGUlMjBpcGhvbmV8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                /> */}
                    <div className="product-item__info">
                      <div className="product-item__name">{p.name}</div>
                      <div className="product-item__color">Số lượng: {p.quantity}</div>

                      {!!p?.couponCode?.length && (
                        <div className="product-item__coupon">
                          Mã sử dụng:{' '}
                          <span>{p.couponCode.map((cou) => cou.coupon_code).join(', ')}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="product-item__price">
                    <div className="product-item__sale-price">Giá bán: {formatNumber(p.price)}</div>
                    <div className="product-item__final-sale-price">
                      Giá có mã giảm: {formatNumber(parseFloat(p.price) - parseFloat(p.discount))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="order-detail__total-price">
            Tổng số tiền: <span>{formatNumber(total)}</span>
          </div>
        </>
      )}
    </Modal>
  );
};
