import { IAttrAttribute } from 'interfaces/attribute.interface';

export class AttributeModel implements IAttrAttribute {
  active?: boolean;

  adminLabel?: string;

  code?: string;

  isRequired?: boolean;

  showLabel?: string;

  type?: string;

  validation?: string;

  description?: string;

  isConfigurable?: boolean;

  isFilterable?: boolean;

  isFeatured?: boolean;

  isHiddenOnAdmin?: boolean;

  attributeId?: number | null;

  defaultValue?: string;

  constructor({
    active,
    adminLabel,
    code,
    isRequired,
    showLabel,
    type,
    validation,
    description,
    isConfigurable,
    isFilterable,
    isFeatured,
    isHiddenOnAdmin,
    attributeId,
    defaultValue,
  }: IAttrAttribute) {
    this.active = active !== undefined && active !== null ? active : true;
    this.adminLabel = adminLabel || '';
    this.code = code || '';
    this.isRequired = isRequired !== undefined && isRequired !== null ? isRequired : false;
    this.showLabel = showLabel || '';
    this.type = type || '';
    this.validation = validation || '';
    this.description = description || '';
    this.isConfigurable =
      isConfigurable !== undefined && isConfigurable !== null ? isConfigurable : false;
    this.isFilterable = isFilterable !== undefined && isFilterable !== null ? isFilterable : false;
    this.isFeatured = isFeatured !== undefined && isFeatured !== null ? isFeatured : false;
    this.isHiddenOnAdmin =
      isHiddenOnAdmin !== undefined && isHiddenOnAdmin !== null ? isHiddenOnAdmin : false;
    this.attributeId = attributeId || null;
    this.defaultValue = defaultValue || '';
  }
}
