/* eslint-disable @typescript-eslint/no-use-before-define */
import { IPagination } from 'interfaces/pagination.interface';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePageData } from 'redux/pages/actions';
import { fetcherWithArg, poster, updater } from 'services/swr.utils';
import useSWRMutation from 'swr/mutation';
import { API_EVENT_PAGE, API_EVENT_PAGE_BY_ID } from 'constants/api/event-page.api';
import { defaultPagination } from 'constants/constants';
import { FilterEventPage } from './components/FilterView';

export const buildParams = (params: any) =>
  !isEmpty(params)
    ? queryString.parse(
        queryString.stringify(params, {
          skipEmptyString: true,
          skipNull: true,
          arrayFormat: 'bracket',
        }),
        { arrayFormat: 'bracket' },
      )
    : {};

/**
 * Custom hook that fetches and returns events page data.
 */
export const useFetchEvents = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data, isMutating, trigger } = useSWRMutation(API_EVENT_PAGE, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetchCoupons(buildParams(isEmpty(params) ? {} : params));
  }, []);

  const fetchCoupons = useCallback(
    async (
      params?: Partial<Omit<FilterEventPage, 'page'>> & {
        page?: number;
      },
    ): Promise<void> => {
      const locationParams = queryString.parse(location.search, { arrayFormat: 'bracket' });
      const { page = pagination.current, ...rest } = params || {};
      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;
      const query = {
        ...locationParams,
        page: calcPage,
        perPage: defaultPagination.pageSize,
        ...rest,
      };

      history.push(
        queryString.stringifyUrl(
          {
            url: location.pathname,
            query,
          },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' },
        ),
      );

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage, location.search],
  );

  return {
    isFetching: isMutating,
    data: data?.data,
    pagination,
    setPagination,
    refetch: fetchCoupons,
  };
};

/**
 *
 * @returns
 */
export const useCreateEventPage = () => {
  const { isMutating, trigger } = useSWRMutation(API_EVENT_PAGE, poster);

  return {
    isCreating: isMutating,
    onCreate: trigger,
  };
};

/**
 *
 * @returns
 */
export const useUpdateEventPage = () => {
  const { isMutating, trigger } = useSWRMutation(API_EVENT_PAGE_BY_ID, updater);

  return {
    isUpdating: isMutating,
    onUpdate: trigger,
  };
};
