import { OrderAttrs } from 'interfaces/order.interface';
import { useCallback, useMemo } from 'react';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { num2Word2 } from 'utils/number2word';
import { convertPriceToHTML, formatPrice, getBasePrice } from 'utils/productHelpers';
import { capitalizeFirstLetter } from 'utils/utils';
interface OrderInfoProps {
  data: OrderAttrs;
}

const OrderInfo: React.FC<OrderInfoProps> = ({ data }) => {
  const { total, items, subtotal, shippingAmount } = data || {};
  const { convert } = num2Word2();

  const totalPrice = useMemo(() => {
    return parseInt(total).toString();
  }, [total]);

  // render price HTML
  const renderPriceHtml = useCallback((prod) => {
    const { basePrice, oldPrice } = getBasePrice(prod?.price, prod?.basePrice, prod?.discount);
    const oldPriceNum = parseInt(prod?.basePrice);

    return (
      <>
        {oldPriceNum > 0 && parseInt(prod?.discount) > 0 && basePrice !== oldPrice && (
          <span className="old">
            <SanitizeHtml
              rawHtml={convertPriceToHTML(oldPrice)}
              htmlElement="span"
              allowedAttributes={{
                span: ['class'],
              }}
            />
          </span>
        )}
        <span className="new">
          <SanitizeHtml
            rawHtml={convertPriceToHTML(basePrice)}
            htmlElement="span"
            allowedAttributes={{
              span: ['class'],
            }}
          />
        </span>
      </>
    );
  }, []);

  return (
    <div className="order-table">
      <div className="text-right">Các đơn vị giá (VND), đã gồm VAT</div>
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <th className="text-center">No.</th>
              <th className="text-left">Tên sản phẩm</th>
              <th className="text-center">Số lượng</th>
              <th className="text-right">Giá bán</th>
              <th className="text-right">Thành tiền</th>
            </tr>
            {items?.map((prod, idx) => (
              <tr key={prod?.id} className="trline">
                <td className="text-center">{idx + 1}</td>
                <td className="td-name text-left">
                  <span className="name">{prod?.name}</span>
                  <span className="sku">
                    {prod?.sku}{' '}
                    {prod?.couponCode?.length > 0 && (
                      <span className="ml-2 has-coupon">(Có COUPON)</span>
                    )}
                  </span>
                </td>
                <td className="text-center">{prod?.quantity}</td>
                <td className="text-right">{renderPriceHtml(prod)}</td>
                <td className="text-right">{formatPrice(prod?.total)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="order-total">
        <table>
          <tbody>
            <tr>
              <td>Tổng tạm tính</td>
              <td className="text-right">{formatPrice(subtotal)}</td>
            </tr>
            <tr>
              <td>
                Phí giao hàng
                <br />
                <span>(Shipping &amp; Handling)</span>
              </td>
              <td className="text-right">{formatPrice(shippingAmount)}</td>
            </tr>
            <tr className="all-total">
              <td>Thành tiền</td>
              <td className="text-right total-price">{formatPrice(totalPrice)}</td>
            </tr>
            <tr>
              <td colSpan={2} className="text-right">
                <p>
                  <span>Tổng số tiền ghi bằng chữ: </span>
                </p>
                <p>{capitalizeFirstLetter(convert(parseFloat(totalPrice)))}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderInfo;
