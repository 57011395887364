import { DeleteOutlined } from '@ant-design/icons';
import {
  Input,
  Form,
  Select,
  Col,
  Row,
  Collapse,
  Switch,
  Spin,
  message,
  Button,
  TreeSelect,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { clone, debounce, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import {
  API_GET_POST_ARTICLES,
  API_GET_ARTICLE_DETAIL,
  API_GET_ARTICLE_CATEGORIES,
  API_UPDATE_DELETE_ARTICLE,
} from 'constants/api/article.api';
import { API_GET_PRODUCTS } from 'constants/api/product.api';
import { IArticle, IArticleAttribute, IArticleCategory } from 'interfaces/article.interface';
import { IImage } from 'interfaces/image.interface';
import { IProduct } from 'interfaces/product.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { ArticleModel } from 'models/article.model';
import BaseProductsTable from 'pages/Products/ProductDetail/components/BaseProductsTable/BaseProductsTable';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { CustomTag } from 'ui/components/MaiNguyen/CustomTag';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { SelectedTable } from 'ui/components/SelectProductsSection/SelectedTable';
import {
  groupArticleCategoriesByParent,
  showErrorMsg,
  generateSlug,
  uploadFile,
} from 'utils/utils';

import './ArticleDetails.scss';

const { Panel } = Collapse;

const newArticle = new ArticleModel({});

const getListWithPosition = (list: any[], sortedList: any[], conditionKey: string) => {
  const clonedList = clone(list);
  clonedList.forEach((item) => {
    if (sortedList.find((srt) => srt[conditionKey].toString() === item.id)) {
      item.position = sortedList.find((srt) => srt[conditionKey].toString() === item.id).position;
    }
  });

  return sortBy(clonedList, 'position');
};

const ArticleDetails = () => {
  const location = useLocation();
  const { id: articleId } = useParams<{ id: string }>();
  const isAddNew = location.pathname.includes('soan-bai-viet');
  const [article, setArticle] = useState<IArticleAttribute>(newArticle);
  const [categories, setCategories] = useState<IArticleCategory[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [productId, setProductId] = useState<number>(null);
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [selectedArticleId, setSelectedArticleId] = useState<number>(null);
  const [relaProducts, setRelaProducts] = useState<IProduct[]>([]);
  const [relaArticles, setRelaArticles] = useState<IArticle[]>([]);
  const [mainContent, setMainContent] = useState('');
  const [loadProducts, setLoadProducts] = useState(false);
  const [loadArticles, setLoadArticles] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState<RcFile>(null);
  const [previewImage, setPreviewImage] = useState<IImage>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const groupCategoriesByParent = useMemo(
    () => groupArticleCategoriesByParent(categories),
    [categories],
  );

  useEffect(() => {
    if (!isAddNew) {
      getPageData(API_GET_ARTICLE_DETAIL.replace('{id}', articleId), dispatch).then((res) => {
        const sortedRelatedArticles = sortBy(res?.data?.attributes?.relatedArticles, 'position');
        const sortedRelatedProducts = sortBy(res?.data?.attributes?.relatedProducts, 'position');
        const currentArticle = new ArticleModel({
          ...res?.data?.attributes,
          relatedArticles: sortedRelatedArticles,
          relatedProducts: sortedRelatedProducts,
        });
        const relatedArticles = res?.included?.filter((item) => item.type === 'articles') || [];
        const relatedProducts = res?.included?.filter((item) => item.type === 'products') || [];
        setRelaArticles(getListWithPosition(relatedArticles, sortedRelatedArticles, 'articleId'));
        setRelaProducts(getListWithPosition(relatedProducts, sortedRelatedProducts, 'productId'));
        setArticle(currentArticle);
        setPreviewImage(res?.data?.attributes?.images[0]);
        setMainContent(currentArticle.content);
        form.setFieldsValue(currentArticle);
        // set localstorage current article
        localStorage.setItem('currentArticle-' + articleId, JSON.stringify(currentArticle));
      });
    } else {
      // set localstorage current article
      localStorage.setItem('currentArticle-new', JSON.stringify(newArticle));
    }
  }, [articleId]);

  const handleSetArticle = (currentArticle) => {
    setArticle(currentArticle);
    // set current article
    if (isAddNew) {
      localStorage.setItem('currentArticle-new', JSON.stringify(currentArticle));
    } else {
      localStorage.setItem('currentArticle-' + articleId, JSON.stringify(currentArticle));
    }
  };

  useEffect(() => {
    api.get({ endpoint: API_GET_ARTICLE_CATEGORIES }).then((res) => {
      setCategories(res?.data);
    });
  }, []);

  const handleChangeValues = useCallback(
    (field, values) => {
      dispatch(setIsEditing(true));
      const fieldKey = Object.keys(field);
      let cloneArticle;
      if (!isAddNew) {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-' + articleId));
      } else {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-new'));
      }

      const currentArticle = new ArticleModel({ ...cloneArticle, ...values });
      if (isAddNew) {
        currentArticle.slug = generateSlug(currentArticle.name.trim());
      } else {
        switch (fieldKey[0]) {
          case 'name':
            currentArticle.slug = generateSlug(currentArticle.name.trim());
            break;
          case 'slug':
            currentArticle.slug = generateSlug(currentArticle.slug);
            break;
          default:
            break;
        }
      }
      handleSetArticle(currentArticle);
      form.setFieldsValue(currentArticle);
    },
    [article],
  );

  const handleChangeMainContent = useCallback(
    (value: string) => {
      dispatch(setIsEditing(true));
      let cloneArticle;
      if (!isAddNew) {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-' + articleId));
      } else {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-new'));
      }
      const currentArticle = new ArticleModel({ ...cloneArticle, content: value });
      form.setFieldsValue(currentArticle);
      setMainContent(value);
      handleSetArticle(currentArticle);
    },
    [article],
  );

  useEffect(() => {
    return () => {
      // remove curent article
      if (isAddNew) {
        localStorage.removeItem('currentArticle-new');
      } else {
        localStorage.removeItem('currentArticle-' + articleId);
      }
    };
  }, []);

  const handleDeleteTag = useCallback(
    (tags: string[]) => {
      dispatch(setIsEditing(true));
      let cloneArticle;
      if (!isAddNew) {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-' + articleId));
      } else {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-new'));
      }
      const currentArticle = new ArticleModel(cloneArticle);
      currentArticle.keywords = tags.join(',');
      handleSetArticle(currentArticle);
      form.setFieldsValue(currentArticle);
    },
    [article],
  );

  const handleSearchProducts = useMemo(() => {
    const fetchProducts = async (value: string) => {
      setLoadProducts(true);
      if (value) {
        await api
          .get({
            endpoint: `${API_GET_PRODUCTS}?page=1&name=${value}`,
          })
          .then((res) => {
            setLoadProducts(false);
            setProducts(res?.data);
          })
          .catch((err) => {
            setLoadProducts(false);
            setProducts([]);
            showErrorMsg(err);
          });
      } else {
        setLoadProducts(false);
        setProducts([]);
      }
    };
    return debounce(fetchProducts, 800);
  }, []);

  const handleSelectProduct = useCallback(
    (id: number) => {
      dispatch(setIsEditing(true));
      let cloneArticle;
      if (!isAddNew) {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-' + articleId));
      } else {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-new'));
      }
      const currentArticle = new ArticleModel(cloneArticle);
      const clonedRelaProducts = clone(relaProducts);

      if (
        !currentArticle.relatedProducts.find((item) => item.productId === id) &&
        !clonedRelaProducts.find((item) => parseInt(item.id) === id)
      ) {
        currentArticle.relatedProducts.push({
          productId: id,
          position: currentArticle.relatedProducts.length + 1,
        });
        clonedRelaProducts.push(products.find((item) => parseInt(item.id) === id));
        setProductId(id);
        setRelaProducts(clonedRelaProducts);
        handleSetArticle(currentArticle);
      } else {
        message.error('Sản phẩm đã tồn tại trong danh sách', 3);
      }
    },
    [article, products, relaProducts],
  );

  const handleRemoveProduct = useCallback(
    (product: IProduct) => {
      dispatch(setIsEditing(true));
      let cloneArticle;
      if (!isAddNew) {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-' + articleId));
      } else {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-new'));
      }
      const currentArticle = new ArticleModel(cloneArticle);
      const clonedRelaProducts = clone(relaProducts);

      const relaProductIndex = clonedRelaProducts.findIndex((item) => item.id === product.id);
      const articleRelaProductIndex = currentArticle.relatedProducts.findIndex(
        (item) => item.productId === parseInt(product.id),
      );
      if (relaProductIndex > -1 && articleRelaProductIndex > -1) {
        clonedRelaProducts.splice(relaProductIndex, 1);
        currentArticle.relatedProducts.splice(articleRelaProductIndex, 1);
      }
      setRelaProducts(clonedRelaProducts);
      handleSetArticle(currentArticle);
    },
    [article, relaProducts],
  );

  const handleChangeDataPosition = useCallback(
    (dataType: string) => (changedData: IProduct[] | IArticle[]) => {
      dispatch(setIsEditing(true));
      let cloneArticle;
      if (!isAddNew) {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-' + articleId));
      } else {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-new'));
      }
      const currentArticle = new ArticleModel(cloneArticle);
      switch (dataType) {
        case 'products':
          setRelaProducts(changedData as IProduct[]);
          currentArticle.relatedProducts = changedData.map((item, index) => ({
            productId: parseInt(item.id),
            position: index + 1,
          }));
          break;
        case 'articles':
          setRelaArticles(changedData as IArticle[]);
          currentArticle.relatedArticles = changedData.map((item, index) => ({
            articleId: parseInt(item.id),
            position: index + 1,
          }));
          break;
        default:
          break;
      }
      handleSetArticle(currentArticle);
    },
    [article],
  );

  const handleSearchArticles = useMemo(() => {
    const fetchArticles = async (value: string) => {
      setLoadArticles(true);
      if (value) {
        await api
          .get({
            endpoint: `${API_GET_POST_ARTICLES}?page=1&name=${value}`,
          })
          .then((res) => {
            setLoadArticles(false);
            setArticles(res?.data);
          })
          .catch((err) => {
            setLoadArticles(false);
            setArticles([]);
            showErrorMsg(err);
          });
      } else {
        setLoadArticles(false);
        setArticles([]);
      }
    };
    return debounce(fetchArticles, 800);
  }, []);

  const handleSelectArticle = useCallback(
    (id: number) => {
      dispatch(setIsEditing(true));
      let cloneArticle;
      if (!isAddNew) {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-' + articleId));
      } else {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-new'));
      }
      const currentArticle = new ArticleModel(cloneArticle);
      const clonedRelaArticles = clone(relaArticles);

      if (
        !currentArticle.relatedArticles.find((item) => item.articleId === id) &&
        !clonedRelaArticles.find((item) => parseInt(item.id) === id)
      ) {
        currentArticle.relatedArticles.push({
          articleId: id,
          position: currentArticle.relatedArticles.length + 1,
        });
        clonedRelaArticles.push(articles.find((item) => parseInt(item.id) === id));
        setSelectedArticleId(id);
        setRelaArticles(clonedRelaArticles);
        handleSetArticle(currentArticle);
      } else {
        message.error('Bài viết đã tồn tại trong danh sách', 3);
      }
    },
    [article, articles, relaArticles],
  );

  const handleDeleteArticle = useCallback(
    (deleteArticle: IArticle) => () => {
      dispatch(setIsEditing(true));
      let cloneArticle;
      if (!isAddNew) {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-' + articleId));
      } else {
        cloneArticle = JSON.parse(localStorage.getItem('currentArticle-new'));
      }
      const currentArticle = new ArticleModel(cloneArticle);
      const clonedRelaArticles = clone(relaArticles);

      const relaArticleIndex = clonedRelaArticles.findIndex((item) => item.id === deleteArticle.id);
      const articleRelaProductIndex = currentArticle.relatedArticles.findIndex(
        (item) => item.articleId === parseInt(deleteArticle.id),
      );
      if (relaArticleIndex > -1 && articleRelaProductIndex > -1) {
        clonedRelaArticles.splice(relaArticleIndex, 1);
        currentArticle.relatedArticles.splice(articleRelaProductIndex, 1);
      }
      setRelaArticles(clonedRelaArticles);
      handleSetArticle(currentArticle);
    },
    [article, relaArticles],
  );

  const handleSaveArticle = useCallback(async () => {
    setIsLoading(true);
    try {
      if (isAddNew) {
        await api.post({ endpoint: API_GET_POST_ARTICLES, data: article }).then(async (res) => {
          await uploadFile(thumbnailFile, 'article', res?.data?.id).then((attr) => {
            setPreviewImage({ ...attr?.resize, original: attr?.originUrl, id: attr?.id });
          });
          history.replace(`/bai-viet/${res?.data?.id}`);
        });
        dispatch(setIsEditing(false));
        setIsLoading(false);
        message.success('Lưu bài viết thành công', 3);
      } else {
        await api.put({
          endpoint: API_UPDATE_DELETE_ARTICLE.replace('{id}', articleId),
          data: article,
        });
        await uploadFile(thumbnailFile, 'article', articleId).then((attr) => {
          setPreviewImage({ ...attr?.resize, original: attr?.originUrl, id: attr?.id });
        });
        dispatch(setIsEditing(false));
        setIsLoading(false);
        message.success('Lưu bài viết thành công', 3);
      }
    } catch (err) {
      showErrorMsg(err);
      setIsLoading(false);
    }
  }, [articleId, article, thumbnailFile]);

  const handleUploadFile = useCallback((file: RcFile) => {
    dispatch(setIsEditing(true));
    setThumbnailFile(file);
  }, []);

  const handleDeleteImage = () => {
    dispatch(setIsEditing(true));
    setPreviewImage(null);
  };

  const handleCancel = () => {
    history.push('/bai-viet');
  };

  const relatedArticlesColumns = [
    {
      title: 'STT',
      dataIndex: 'key',
      width: 70,
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tiêu đề bài viết',
      dataIndex: 'attributes',
      render: (attr: IArticleAttribute) => <SanitizeHtml rawHtml={attr.name} />,
    },
    {
      title: 'Thao tác',
      width: 100,
      render: (row: IArticle) => (
        <Button title="Xóa" icon={<DeleteOutlined />} onClick={handleDeleteArticle(row)} />
      ),
    },
  ];

  return (
    <LayoutDetail
      pageTitle={isAddNew ? 'Tạo mới' : 'Chi tiết'}
      pageClassName="article-detail-page"
      isAddNew={!articleId}
      breadcrumbs={[
        {
          title: 'Bài viết',
          route: '/bai-viet',
        },
        {
          title: isAddNew ? 'Thêm mới' : 'Chi tiết',
          route: isAddNew ? '/soan-bai-viet' : `/bai-viet/${articleId}`,
        },
      ]}
      formHook={form}
      handleValuesChange={handleChangeValues}
      handleSave={handleSaveArticle}
      handleCancel={handleCancel}
      handleClickAddButton={null}
      handleDelete={null}
      saveLoading={isLoading}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Tiêu đề bài viết"
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng điền tiêu đề bài viết',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Chuyên mục" name="categoryId">
            <TreeSelect
              size="large"
              placeholder="Lọc theo chuyên mục"
              allowClear
              treeDefaultExpandAll
              treeData={groupCategoriesByParent}
            />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item label="Featured Article" name="isFeatured" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Hiển thị" name="active" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Form.Item label="Slug" name="slug">
            <Input />
          </Form.Item>
          <Form.Item label="Nội dung tóm tắt" name="description">
            <Input.TextArea rows={6} />
          </Form.Item>
        </Col>
      </Row>
      <Col span={24}>
        <Collapse>
          <Panel header="Hình ảnh (Nhập hình tỉ lệ 16:9 là tốt nhất (1600x900)" key="1">
            <UploadFile
              imageSize="original"
              previewImage={previewImage}
              handleUploadImage={handleUploadFile}
              handleDeleteImageSuccess={handleDeleteImage}
              maxWidth={700}
            />
          </Panel>
          <Panel header="Nội dung chính" key="2">
            <CustomEditor
              mainContent={mainContent}
              handleChangeMainContent={handleChangeMainContent}
            />
          </Panel>
          <Panel header="Meta contents" key="3">
            <Form.Item label="Meta Keywords" name="keywords">
              <Input.TextArea rows={6} />
            </Form.Item>
            <Form.Item label="Tags">
              <CustomTag
                tags={article.keywords ? article.keywords.split(',') : []}
                handleUpdateTags={handleDeleteTag}
                showAddButton={false}
              />
            </Form.Item>
          </Panel>
          <Panel header="Bài viết liên quan" key="4">
            <Form.Item label="Tìm bài viết">
              <Select
                size="large"
                showSearch
                optionFilterProp="children"
                allowClear
                notFoundContent={loadArticles ? <Spin size="small" /> : null}
                onSearch={handleSearchArticles}
                placeholder="Nhập tên để tìm"
                onSelect={handleSelectArticle}
                value={selectedArticleId}
              >
                {articles.map((item) => (
                  <Select.Option key={item.id} value={parseInt(item.id)}>
                    {item?.attributes?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <BaseProductsTable
              dataList={relaArticles}
              columns={relatedArticlesColumns}
              sortable
              handleChangePosition={handleChangeDataPosition('articles')}
            />
          </Panel>
          <Panel header="Sản phẩm liên quan" key="5">
            <Form.Item label="Tìm sản phẩm">
              <Select
                size="large"
                showSearch
                optionFilterProp="children"
                allowClear
                notFoundContent={loadProducts ? <Spin size="small" /> : null}
                onSearch={handleSearchProducts}
                placeholder="Nhập để tìm sản phẩm theo SKU/Tên"
                onSelect={handleSelectProduct}
                value={productId}
              >
                {products.map((item) => (
                  <Select.Option key={item.id} value={parseInt(item.id)}>
                    {`${item?.attributes?.sku} - ${item?.attributes?.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <SelectedTable
              data={relaProducts}
              onRemoveSelectedProd={handleRemoveProduct}
              handleChangePosition={handleChangeDataPosition('products')}
              sortable
              className="selected-phone"
              imageWidth={120}
              priceWidth={150}
            />
          </Panel>
        </Collapse>
      </Col>
    </LayoutDetail>
  );
};

export default ArticleDetails;
