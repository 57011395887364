import { message } from 'antd';
import { useFetchProductCategories } from 'hooks/useFetchProductCategories';
import { useFetchSubStatus } from 'hooks/useFetchSubStatus';
import { ICoupon } from 'interfaces/coupon.interface';
import isEmpty from 'lodash/isEmpty';
import { ProductTable } from 'pages/CollectionDetail/components/ProductTable';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { deleteModal } from 'utils/modals';
import { buildParams } from 'utils/utils';
import { API_COUPON_BY_ID } from 'constants/api/coupons.api';
import { AddProductModal } from './AddProductModal';
import { useFetchProductsOfCoupon, useUpdateCoupon } from '../../hooks';
import './styles.scss';

interface Props {
  coupon: ICoupon;
}

export const ProductList: React.FC<Props> = (props) => {
  const { coupon } = props;

  const couponId = coupon?.id;
  const productIdsOfCoupon = coupon?.attributes?.productIds;
  const totalProducts = productIdsOfCoupon?.length;

  const location = useLocation();
  const { mutate } = useSWRConfig();
  const { data: productCategories } = useFetchProductCategories();
  const { data: subStatus } = useFetchSubStatus();
  const {
    isFetchingProductsOfCoupon,
    productsOfCoupon,
    pagination,
    setPagination,
    fetchProductsOfCoupon,
  } = useFetchProductsOfCoupon(couponId);
  const { onUpdate } = useUpdateCoupon();

  const [openAddProductModal, setOpenAddProductModal] = useState<boolean>(false);
  const [sort, setSort] = useState<'desc' | 'asc'>('desc');

  /**
   * An effect that fetches the products of the coupon when the coupon ID changes.
   */
  useEffect((): void => {
    if (couponId) {
      const params = buildParams(queryString.parse(location.search));
      setSort((params?.direction as any) || 'desc');
      fetchProductsOfCoupon(params);
    }
  }, [couponId]);

  /**
   * Toggles the Add Product modal by toggling the state of `openAddProductModal`.
   */
  const onToggleAddProductModal = useCallback((): void => {
    setOpenAddProductModal((prev) => !prev);
  }, []);

  /**
   * A callback function that handles the removal of a product from a coupon.
   *
   * @param {string} productId - The ID of the product to be removed.
   * @throws {Error} - An error that occurred during the product removal.
   */
  const onRemove = useCallback(
    (productId: string): void => {
      deleteModal({
        title: 'Bạn có muốn xóa sản phẩm này?',
        onDeleteCb: async () => {
          try {
            const newData = await onUpdate({
              method: 'put',
              id: couponId,
              data: {
                productIds: productIdsOfCoupon?.filter((p) => p != productId),
              },
            });
            await mutate(API_COUPON_BY_ID.replace('{id}', couponId), newData, {
              revalidate: false,
              rollbackOnError: true,
            });
            message.success(`Xoá sản phẩm thành công`);
            fetchProductsOfCoupon();
          } catch (error: any) {
            message.error(`${error?.message || error}`);
          }
        },
      });
    },
    [couponId, productIdsOfCoupon, fetchProductsOfCoupon],
  );

  /**
   * A callback function that handles page changes in pagination.
   *
   * @param {number} page - The new page number.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchProductsOfCoupon({ page, direction: sort });
    },
    [fetchProductsOfCoupon, sort],
  );

  /**
   * A callback function that handles changes in sorting direction.
   *
   * @param {'desc' | 'asc'} type - The new sorting direction ('desc' for descending, 'asc' for ascending).
   */
  const onSortChange = useCallback(
    async (type: 'desc' | 'asc') => {
      setSort(type);
      await fetchProductsOfCoupon({ page: 1, direction: type });
    },
    [fetchProductsOfCoupon],
  );

  return (
    <div className="coupon__products">
      <div className="coupon__products-header">
        <div className="products-header__title">
          SẢN PHẨM ÁP DỤNG COUPON <span>{`(${totalProducts || '0'})`}</span>
        </div>

        <div className="products-header__toolbar">
          <div className="products-header__toolbar-sort">
            <div
              className={`toolbar-sort__option ${
                sort === 'asc' ? 'toolbar-sort__option--active' : ''
              }`}
              onClick={() => onSortChange('asc')}
            >
              CŨ TRƯỚC
            </div>
            <div className="toolbar-sort__separate">|</div>
            <div
              className={`toolbar-sort__option ${
                sort === 'desc' ? 'toolbar-sort__option--active' : ''
              }`}
              onClick={() => onSortChange('desc')}
            >
              MỚI TRƯỚC
            </div>
          </div>
          <div className="products-header__add-btn" onClick={onToggleAddProductModal}>
            + Thêm sản phẩm
          </div>
        </div>
      </div>
      <ProductTable
        loading={isFetchingProductsOfCoupon}
        subStatus={subStatus}
        data={productsOfCoupon}
        pagination={pagination}
        onRemove={onRemove}
        onPageChange={onPageChange}
      />
      {!isEmpty(coupon) && (
        <AddProductModal
          isOpen={openAddProductModal}
          couponId={couponId}
          productIdsOfCoupon={productIdsOfCoupon}
          productCategories={productCategories}
          subStatus={subStatus}
          onClose={onToggleAddProductModal}
          fetchProductsOfCoupon={async () => await fetchProductsOfCoupon({ direction: sort })}
        />
      )}
    </div>
  );
};
