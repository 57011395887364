import { Checkbox, Empty, Spin } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { IBrand } from 'interfaces/brand.interface';
import { isEmpty } from 'lodash';

interface SelectedStoresProps {
  stores?: IBrand[];
  stdStore?: string[];
  isLoading?: boolean;
  isErrorStores?: boolean;
  onSelect?: (id: string[]) => void;
}

const SelectedStores: React.FC<SelectedStoresProps> = ({
  stores,
  stdStore,
  isLoading,
  isErrorStores,
  onSelect,
}) => {
  const onChange = (checkedValues: CheckboxValueType[]) => {
    onSelect && onSelect(checkedValues as string[]);
  };

  return (
    <div className="selected-stores">
      <h3 className={`ttl ${isErrorStores ? 'text-danger' : ''}`}>
        Danh sách các chi nhánh có hàng trải nghiệm:
      </h3>

      {isLoading && (
        <div className="text-center">
          <Spin />
        </div>
      )}

      {!isEmpty(stores) ? (
        <Checkbox.Group onChange={onChange} value={stdStore}>
          {stores.map((store) => {
            const { id, attributes } = store;
            return (
              <div key={`exp-store-${id}`}>
                <Checkbox value={id}>{attributes.name}</Checkbox>
              </div>
            );
          })}
        </Checkbox.Group>
      ) : (
        <Empty description="Danh sách trống" />
      )}
    </div>
  );
};

export default SelectedStores;
