/* eslint-disable react/jsx-no-bind */
import { Route, Redirect } from 'react-router-dom';
import TokenService from 'services/token.service';

import './PublicPage.scss';

const tokenService = new TokenService();

const PublicPage = ({ children, ...rest }) => {
  const { accessToken } = tokenService.getAccessToken();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!accessToken) {
          return <>{children}</>;
        }
        return <Redirect to={{ pathname: '/', state: { from: location } }} />;
      }}
    />
  );
};

export default PublicPage;
