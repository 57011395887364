import { Form, Row, Col, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { uploadFile } from 'utils/utils';
import './HeaderLogoBlock.scss';

export type RiderHeaderLogoTypes = {
  desktopLogo: any;
  mobileLogo: any;
};

interface HeaderLogoInterface {
  blockId: string;
  data: RiderHeaderLogoTypes;
  onChange: (objData: any) => void;
}

export const HeaderLogoBlock: FC<HeaderLogoInterface> = ({
  blockId,
  data: dataProps,
  onChange,
}) => {
  const [data, setData] = useState<RiderHeaderLogoTypes>({
    desktopLogo: null,
    mobileLogo: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dataProps) {
      setData(dataProps);
    }
  }, []);

  const handleUploadImage = useCallback(
    async ({ isMobileImage, file }: { isMobileImage: boolean; file: RcFile }) => {
      const newData = { ...data };
      setIsLoading(true);

      if (isMobileImage) {
        await uploadFile(file, 'block', blockId, '', '0')
          .then((attr) => {
            newData.mobileLogo = {
              ...attr,
              original: attr?.originUrl,
            };
          })
          .catch(() => {
            newData.mobileLogo = null;
          });
      } else {
        await uploadFile(file, 'block', blockId, '', '0')
          .then((attr) => {
            newData.desktopLogo = {
              ...attr,
              original: attr?.originUrl,
            };
          })
          .catch(() => {
            newData.desktopLogo = null;
          });
      }

      setData(newData);
      onChange(newData);
      setIsLoading(false);
    },
    [data, onChange, blockId],
  );

  const handleDeleteImage = useCallback(
    ({ isMobileImage }: { isMobileImage: boolean }) => {
      const newData = { ...data };
      if (isMobileImage) {
        newData.mobileLogo = null;
      } else {
        newData.desktopLogo = null;
      }

      setData(newData);
      onChange(newData);
    },
    [data, onChange],
  );

  return (
    <div className="header-logo-block">
      {isLoading && (
        <div className="loading-wrapper">
          <Spin />
          <span>Uploading logo...</span>
        </div>
      )}
      <Row gutter={16} className="mt-3 mb-3">
        <Col span={12}>
          <Form.Item label="Desktop Logo">
            <UploadFile
              typeId={uuid()}
              uploadText="Click hoặc kéo thả hình để upload"
              handleUploadImage={(file: RcFile) =>
                handleUploadImage({ isMobileImage: false, file })
              }
              handleDeleteImageSuccess={() => handleDeleteImage({ isMobileImage: false })}
              previewImage={data.desktopLogo}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Mobile Logo">
            <UploadFile
              typeId={uuid()}
              uploadText="Click hoặc kéo thả hình để upload"
              handleUploadImage={(file: RcFile) => handleUploadImage({ isMobileImage: true, file })}
              handleDeleteImageSuccess={() => handleDeleteImage({ isMobileImage: true })}
              previewImage={data.mobileLogo}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
