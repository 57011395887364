import { IAttributeMenuBlock } from 'interfaces/menu-block.interface';

export class MenuBlockModel implements IAttributeMenuBlock {
  title?: string;

  url?: string;

  type?: string;

  group?: number | null;

  position?: number;

  isHot?: boolean;

  isNew?: boolean;

  active?: boolean;

  menuId?: number;

  constructor({
    title,
    url,
    type,
    group,
    position,
    isHot,
    isNew,
    active,
    menuId,
  }: IAttributeMenuBlock) {
    this.title = title || '';
    this.url = url || '';
    this.type = type || '';
    this.group = group || null;
    this.position = position;
    this.active = active !== undefined && active !== null ? active : false;
    this.isHot = isHot !== undefined && isHot !== null ? isHot : false;
    this.isNew = isNew !== undefined && isNew !== null ? isNew : false;
    this.menuId = menuId;
  }
}
