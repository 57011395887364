/* eslint-disable import/order */
import { Button, Col, Row, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { memo, useCallback } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Rating } from 'components/Rating';
import { DateFormat, ReviewStatus, StoreIds } from 'constants/enum';
import { IProduct } from 'interfaces/product.interface';
import { IReview } from 'interfaces/review.interface';
import { getReviewLabelStatus } from 'pages/Members/utils';
import { getFullUrlWithSlug } from 'utils/utils';
import emptyImg from 'assets/img/empty.png';

interface ReviewItemProps {
  review?: IReview;
  includedProducts?: IProduct[];
  idLoading?: string | null;
  hideProductLink?: boolean;
  onUpdate?: (o: IReview, status: string) => void;
  onRemove?: (reviewId: string) => void;
}

export const ReviewItem: React.FC<ReviewItemProps> = memo(
  ({ review, includedProducts, idLoading, hideProductLink, onUpdate, onRemove }) => {
    const {
      content,
      createdAt,
      customer,
      status,
      images,
      productId,
      storeId,
      isBought,
      score,
      posterName,
    } = review.attributes;
    const { name } = customer || {};

    const { id: prodIdParam } = useParams<{ id: string }>(); // productId !== null -> get reviews by product id

    /**
     * Render product front store link
     * @param includedProducts
     * @param productId
     * @returns
     */
    const renderStoreLink = useCallback(() => {
      const filterData = includedProducts.find((item) => Number(item.id) === productId);

      if (!isEmpty(filterData)) {
        const { slug } = filterData.attributes || {};

        return (
          <a
            href={getFullUrlWithSlug(storeId, `/${slug}`)}
            target="_blank"
            rel="noreferrer"
            className="mr-2"
          >
            {storeId === StoreIds.mainguyen ? 'MN Link' : 'RIDER Link'}
          </a>
        );
      }
    }, [includedProducts, productId, storeId]);

    return (
      <div className={`review-item ${prodIdParam ? 'prod-review-item' : ''}`}>
        <Row gutter={24}>
          <Col span={14} className="col-left review-col">
            <div className="text date">
              <span className="lbl">Đăng ngày:</span>{' '}
              {moment(createdAt).format(DateFormat.clientSideWithTime)}
            </div>
            <div className="desc">{content}</div>
            <div className="text user">
              <span className="lbl">User:</span> <b>{name || posterName}</b>
            </div>
            {!hideProductLink && productId && (
              <div className="text">
                <span className="lbl">Link SP ADMIN:</span>{' '}
                <NavLink
                  to={`/san-pham/${productId}`}
                  target="_blank"
                >{`/san-pham/${productId}`}</NavLink>
              </div>
            )}
            {!hideProductLink && productId && storeId !== 0 && (
              <div className="text">
                <span className="lbl">Link SP Front Store:</span> {renderStoreLink()}
              </div>
            )}
          </Col>
          <Col span={10} className="col-right">
            <Row gutter={24}>
              <Col span={10} className="review-col">
                <Rating value={score} isViewOnly />
                {!!images?.length && (
                  <div className="images-lst">
                    {images.map((image) => (
                      <a target="_blank" rel="noreferrer" href={image.originUrl} key={image.id}>
                        <img
                          src={image?.['128x128'] || emptyImg}
                          alt={image.title}
                          loading="lazy"
                        />
                      </a>
                    ))}
                  </div>
                )}
                <ul className="sharing-lst">
                  {isBought && storeId && (
                    <li>
                      Đã mua tại{' '}
                      {Number(storeId) === StoreIds.mainguyen ? 'MN Store' : 'RIDER Store'}
                    </li>
                  )}
                  {/* {isSharing && <li>Đã chia sẻ</li>} */}
                </ul>
              </Col>
              <Col span={6} className="review-col">
                <div>
                  <Button
                    className={`status ${
                      status.toLowerCase() === ReviewStatus.published ? 'success-button' : ''
                    } ${status.toLowerCase() === ReviewStatus.pending ? 'pending-button' : ''} ${
                      status.toLowerCase() === ReviewStatus.locked ? 'locked-button' : ''
                    }`}
                  >
                    {getReviewLabelStatus(status.toLowerCase())}
                  </Button>
                </div>
              </Col>
              <Col span={8} className="review-col action-col">
                {status.toLowerCase() === ReviewStatus.published && (
                  <div>
                    <Tooltip title={'Khóa'} placement="top">
                      <Button
                        loading={idLoading === review.id}
                        className="action secondary-button"
                        onClick={() => onUpdate(review, ReviewStatus.locked)}
                      >
                        <i className="icofont-lock"></i>
                      </Button>
                    </Tooltip>
                  </div>
                )}
                {status.toLowerCase() === ReviewStatus.pending && (
                  <div>
                    <Tooltip title={'Duyệt review'} placement="top">
                      <Button
                        loading={idLoading === review.id}
                        className="action success-button"
                        onClick={() => onUpdate(review, ReviewStatus.published)}
                      >
                        <i className="icofont-checked"></i>
                      </Button>
                    </Tooltip>
                  </div>
                )}
                {status.toLowerCase() === ReviewStatus.locked && (
                  <div>
                    <Tooltip title={'Mở lại'} placement="top">
                      <Button
                        loading={idLoading === review.id}
                        className="action success-outline-button"
                        onClick={() => onUpdate(review, ReviewStatus.published)}
                      >
                        <i className="icofont-refresh"></i>
                      </Button>
                    </Tooltip>
                  </div>
                )}
                {(status.toLowerCase() === ReviewStatus.locked ||
                  status.toLowerCase() === ReviewStatus.published) && (
                  <div>
                    <Button
                      loading={idLoading === review.id}
                      className="action danger-outline-button"
                      onClick={() => onRemove(review.id)}
                    >
                      <i className="icofont-trash"></i>
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  },
);
