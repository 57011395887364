/* eslint-disable import/order */
import { Card, Collapse, Spin } from 'antd';

import { IArticle } from 'interfaces/article.interface';
import { IAttributeGroup } from 'interfaces/attr-group.interface';
import { IAttribute, IAttributeValue } from 'interfaces/attribute.interface';
import { IProduct } from 'interfaces/product.interface';
import { IRiderModels } from 'interfaces/riderType';
import { clone, isEmpty, uniqBy } from 'lodash';
import { useState, useEffect, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetShippingField, setShippingFields } from 'redux/shipping/actions';
import { AppState } from 'redux/store';
import * as api from 'services/api.service';
import {
  API_GET_ATTRIBUTES_IN_GROUP,
  API_GET_ATTRIBUTE_SUB_GROUPS,
} from 'constants/api/attribute.api';
import { ProductSection } from 'constants/enum';

import { AttributeGroupListInterface, FieldList } from './ProductField';
import { ProductRelatedArticles } from './ProductRelatedArtices';
import { SelectedProductList } from './SelectedProductList';
import ProductReview from './ProductReview';
import ProductExperience from './ProductExperience';

const Panel = Collapse.Panel;

const blkColorDesc = `
<div>Admin có thể tự tạo tự động các sản phẩm simple theo thuộc tính, 
ví dụ: Áo Thun có 3 màu Đen mờ, Đen Saphiar, Xám tro | 4 Size: XS, S, M, L</div>
<ul>
  <li>Nhập hình màu </li>
  <li>Sau đó vào chi tiết từng sản phẩm điều chỉnh.</li>
</ul>
`;

interface ProductAttributeListInterface {
  attributesGroupList: IAttributeGroup[];
  attributeValues: IAttributeValue[];
  onChangeAttributesValue: (values: IAttributeValue[]) => void;
  productType: string;
  renderShippingContent?: boolean;
  relatedProducts: IProduct[];
  relatedProducts2: IProduct[];
  crossSellProducts: IProduct[];
  variantProducts: IProduct[];
  secondhandProducts: IProduct[];
  groupedProducts: IProduct[];
  superAttributes?: IAttribute[];
  handleUpdateSelectedProducts: (products: IProduct[], key: string) => void;
  relatedArticles?: IArticle[];
  handleUpdateSelectedArticles?: (articles: IArticle[]) => void;
  handleRefreshVariantProducts?: () => void;
  handleUpdateSelectedRiderModels?: (riderModel: IRiderModels[]) => void;
}

const shippingState = (state: AppState) => state.shippingFields;

export const ProductAttributeList: FC<ProductAttributeListInterface> = ({
  attributesGroupList,
  attributeValues,
  onChangeAttributesValue,
  renderShippingContent = false,
  productType,
  relatedProducts,
  relatedProducts2,
  crossSellProducts,
  variantProducts,
  secondhandProducts,
  groupedProducts,
  superAttributes = [],
  handleUpdateSelectedProducts,
  relatedArticles,
  handleUpdateSelectedArticles,
  handleRefreshVariantProducts,
  handleUpdateSelectedRiderModels,
}) => {
  const { id: productId } = useParams<{ id: string }>();
  const { loading } = useSelector(shippingState);
  const [attrGroups, setAttrGroups] = useState<AttributeGroupListInterface[]>([]);
  const [shippingGroup, setShippingGroup] = useState<AttributeGroupListInterface>(null);
  const [loadingAttrList, setLoadingAttrList] = useState(true);
  const [attrValues, setAttrValues] = useState<IAttributeValue[]>([]);

  const dispatch = useDispatch();

  const fetchAttributeList = useCallback(async (id: string) => {
    return await api
      .get({ endpoint: `${API_GET_ATTRIBUTES_IN_GROUP.replace('{id}', id)}?perPage=all` })
      .then((res) => {
        return res?.data;
      })
      .catch(() => []);
  }, []);

  useEffect(() => {
    setAttrValues(attributeValues);
  }, [attributeValues]);

  useEffect(() => {
    if (renderShippingContent) {
      const clonedShippingGroup: AttributeGroupListInterface[] = clone(
        attributesGroupList.filter((item) => item.attributes.code === 'shipping'),
      ) as AttributeGroupListInterface[];

      clonedShippingGroup?.forEach(async (item) => {
        const data = await fetchAttributeList(item.id).then((res) => res);
        dispatch(setShippingFields(data));
        item.attributeList = data.map((attribute: IAttribute) => {
          const attrValue = attributeValues.find(
            (attr) => attr.attributeId.toString() === attribute.id,
          );
          return {
            ...attribute,
            attributes: {
              ...attribute.attributes,
              value: attrValue ? attrValue.value : undefined,
            },
          };
        });
      });
      if (clonedShippingGroup[0]) {
        setShippingGroup(clonedShippingGroup[0]);
      } else {
        dispatch(setShippingFields([]));
      }
    } else {
      const clonedAttrGroups: AttributeGroupListInterface[] = clone(
        attributesGroupList,
      ) as AttributeGroupListInterface[];

      clonedAttrGroups?.forEach(async (item) => {
        const {
          relationships: { children },
        } = item;
        if (!isEmpty(children.data)) {
          const subGroups: AttributeGroupListInterface[] = await api
            .get({
              endpoint: `${API_GET_ATTRIBUTE_SUB_GROUPS.replace('{id}', item.id)}?perPage=all`,
            })
            .then((res) => {
              const clonedSubGrops: AttributeGroupListInterface[] = clone(res?.data);
              clonedSubGrops.forEach(async (subGroup) => {
                const data = await fetchAttributeList(subGroup.id).then((resp) => resp);
                subGroup.attributeList = data.map((attribute: IAttribute) => {
                  const attrValue = attributeValues.find(
                    (attr) => attr.attributeId.toString() === attribute.id,
                  );
                  return {
                    ...attribute,
                    attributes: {
                      ...attribute.attributes,
                      value: attrValue ? attrValue.value : undefined,
                    },
                  };
                });
              });
              return clonedSubGrops;
            });
          item.subGroups = subGroups;
        } else {
          const data = await fetchAttributeList(item.id).then((res) => res);
          item.attributeList = data.map((attribute: IAttribute) => {
            const attrValue = attributeValues.find(
              (attr) => attr.attributeId.toString() === attribute.id,
            );
            return {
              ...attribute,
              attributes: {
                ...attribute.attributes,
                value: attrValue ? attrValue.value : undefined,
              },
            };
          });
        }
      });
      setTimeout(() => {
        setAttrGroups(clonedAttrGroups);
      }, 500);
      setTimeout(() => {
        setLoadingAttrList(isEmpty(clonedAttrGroups));
      }, 1000);
    }
    return () => {
      dispatch(resetShippingField());
      setAttrGroups([]);
      setAttrValues([]);
      setLoadingAttrList(true);
      setShippingGroup(null);
    };
  }, [attributesGroupList, fetchAttributeList, renderShippingContent, dispatch]);

  const updateValueInAttrGroup = useCallback(
    (id: string, value: any) => {
      const clonedAttrGroups: AttributeGroupListInterface[] = clone(
        attributesGroupList,
      ) as AttributeGroupListInterface[];

      clonedAttrGroups.forEach((item) => {
        const {
          relationships: { children },
        } = item;
        if (!isEmpty(children.data)) {
          item.subGroups = item?.subGroups?.map((subGroup) => {
            return {
              ...subGroup,
              attributeList: subGroup.attributeList?.map((attr) => {
                if (attr.id === id) {
                  return {
                    ...attr,
                    attributes: {
                      ...attr.attributes,
                      value,
                    },
                  };
                }
                return attr;
              }),
            };
          });
        } else {
          item.attributeList = item?.attributeList?.map((attr) => {
            if (attr.id === id) {
              return {
                ...attr,
                attributes: {
                  ...attr.attributes,
                  value,
                },
              };
            }
            return attr;
          });
        }
      });
      setAttrGroups(clonedAttrGroups);
    },
    [attributesGroupList],
  );

  const handleChangeValue = (id: string, values: any) => {
    const attrIndex = attributeValues.findIndex((item) => item.attributeId == id);
    if (attrIndex != -1) {
      const tempAttr = attributeValues.map((item, i) => {
        if (attrIndex === i) {
          return { ...item, ['value']: values };
        } else {
          return item;
        }
      });
      onChangeAttributesValue(tempAttr);
    } else {
      values &&
        onChangeAttributesValue([...attributeValues, { attributeId: parseInt(id), value: values }]);
    }
    updateValueInAttrGroup(id, values);
  };

  const handleSelectedRiderModel = useCallback(
    (productIds) => {
      handleUpdateSelectedRiderModels(productIds);
    },
    [handleUpdateSelectedRiderModels],
  );

  const onUpdateSelectedProducts = useCallback(
    (key: string) => (productList: IProduct[]) => {
      handleUpdateSelectedProducts(productList, key);
    },
    [handleUpdateSelectedProducts],
  );

  return !renderShippingContent ? (
    <>
      {loadingAttrList && (
        <div className="loading-wrapper">
          <Spin />
        </div>
      )}
      <Collapse style={{ visibility: loadingAttrList ? 'hidden' : 'visible' }}>
        {uniqBy(attrGroups, 'id')
          .filter(
            (item) =>
              item.attributes.code !== 'shipping' &&
              item.id !== '55' &&
              item.attributes.code !== 'prod_experience',
          )
          .map((group) => {
            return !isEmpty(group.subGroups) ? (
              <Panel header={group.attributes.name} key={group.id}>
                <Collapse>
                  {group.subGroups.map((subGroup) => {
                    return (
                      <Panel header={subGroup.attributes.name} key={subGroup.id}>
                        <FieldList fields={subGroup?.attributeList} onChange={handleChangeValue} />
                      </Panel>
                    );
                  })}
                </Collapse>
              </Panel>
            ) : (
              group.attributeList?.some(
                (item) => !item.attributes.isFilterable || !item.attributes.isHiddenOnAdmin,
              ) && (
                <Panel
                  header={group.attributes.name}
                  key={group.id}
                  style={{
                    display:
                      group.attributes.code === 'hinh-anh' && productId === 'new'
                        ? 'none'
                        : 'block',
                  }}
                >
                  <FieldList
                    fields={group?.attributeList.filter(
                      (attr) =>
                        attr.attributes.code !== 'new_percent' &&
                        attr.attributes.code !== 'is_secondhand',
                    )}
                    onChange={handleChangeValue}
                    onSelectedRiderModel={handleSelectedRiderModel}
                  />
                </Panel>
              )
            );
          })}
        {
          //productType === 'simple' &&
          uniqBy(attrGroups, 'id')
            .filter((item) => item.attributes.code === 'info')
            .map((group) => {
              return (
                group.attributeList?.some(
                  (item) => !item.attributes.isFilterable || !item.attributes.isHiddenOnAdmin,
                ) && (
                  <Panel header="Sản phẩm dùng rồi" key="is-used">
                    <FieldList
                      fields={group?.attributeList.filter(
                        (attr) =>
                          attr.attributes.code === 'new_percent' ||
                          attr.attributes.code === 'is_secondhand',
                      )}
                      useSwitcher
                      onChange={handleChangeValue}
                    />
                  </Panel>
                )
              );
            })
        }
        {productId !== 'new' && productType === 'configurable' && !isEmpty(superAttributes) && (
          <Panel
            header="Giá sản phẩm theo thuộc tính"
            key="price-by-attr"
            className="blk-variants-prods"
          >
            <SelectedProductList
              sortable
              buttonText="Tạo sản phẩm con"
              selectedProducts={variantProducts}
              productGroupKey={ProductSection.variantProducts}
              modalTitle="Tạo sản phẩm con"
              description={blkColorDesc}
              isVariantProducts
              isShowSaveButton
              handleUpdateSelectedProducts={onUpdateSelectedProducts(
                ProductSection.variantProducts,
              )}
              handleRefreshVariantProducts={handleRefreshVariantProducts}
            />
          </Panel>
        )}
        <Panel header="Sản phẩm tương tự" key="relate-product">
          <SelectedProductList
            selectedProducts={relatedProducts}
            productGroupKey={ProductSection.relatedProducts}
            modalTitle="Thêm danh sách “Sản Phẩm Tương Tự”"
            description="Là những sản phẩm tương đồng hay dùng hãng. ví dụ: Điện thoại cùng tầm giá."
            handleUpdateSelectedProducts={onUpdateSelectedProducts(ProductSection.relatedProducts)}
          />
        </Panel>
        <Panel header="Sản phẩm liên quan" key="relate-product2">
          <SelectedProductList
            selectedProducts={relatedProducts2}
            productGroupKey={ProductSection.relatedProducts2}
            modalTitle="Thêm danh sách “Sản Phẩm liên quan”"
            description="Là những sản phẩm phụ kiện. Sản phẩm chính có thể mua kèm."
            handleUpdateSelectedProducts={onUpdateSelectedProducts(ProductSection.relatedProducts2)}
          />
        </Panel>
        {productType === 'configurable' && (
          <Panel header="Sản phẩm cùng Series" key="grouped-products">
            <SelectedProductList
              selectedProducts={groupedProducts}
              productGroupKey={ProductSection.groupedProducts}
              modalTitle="Thêm danh sách “Sản phẩm cùng Series”"
              description="Là những sản phẩm cùng Series."
              handleUpdateSelectedProducts={onUpdateSelectedProducts(
                ProductSection.groupedProducts,
              )}
            />
          </Panel>
        )}
        <Panel header="Mua kèm rẻ hơn (Cross-Sales)" key="cross-sales">
          <SelectedProductList
            selectedProducts={crossSellProducts}
            productGroupKey={ProductSection.crossSells}
            modalTitle="Thêm danh sách “Mua kèm rẻ hơn”"
            description="Là sản phẩm được xác định là mua kèm theo và giá rẻ hơn khi mua riêng lẻ. Ví dụ: Mua Iphone12 và mùa kèm ốp lưng rẻ hơn 10%"
            handleUpdateSelectedProducts={onUpdateSelectedProducts(ProductSection.crossSells)}
          />
        </Panel>
        <Panel header="Sản phẩm dùng rồi (Secondhand)" key="secondhand">
          <SelectedProductList
            selectedProducts={secondhandProducts}
            productGroupKey={ProductSection.secondhandProducts}
            modalTitle="Thêm danh sách “Sản phẩm dùng rồi”"
            description="Là sản phẩm đã qua sử dụng"
            isShowRemoveButton={productType === 'configurable'}
            isShowAddButton={productType === 'configurable'}
            handleUpdateSelectedProducts={onUpdateSelectedProducts(
              ProductSection.secondhandProducts,
            )}
          />
        </Panel>
        <Panel header="Bài viết liên quan" key="relate-articles">
          <ProductRelatedArticles
            relatedArticles={relatedArticles}
            handleUpdateSelectedArticles={handleUpdateSelectedArticles}
          />
        </Panel>
        <Panel header="Đánh giá sản phẩm ⭐️" key="product-review">
          <ProductReview />
        </Panel>
        <Panel header="Trải nghiệm sản phẩm 📸" key="product-experience">
          <ProductExperience
            attrValues={attributeValues}
            attrList={uniqBy(attrGroups, 'id').find(
              (item) => item.attributes.code === 'prod_experience',
            )}
          />
        </Panel>
      </Collapse>
    </>
  ) : (
    shippingGroup && (
      <Card title="Thông tin Shipping" className="shipping-info-card" loading={loading}>
        <FieldList
          isShippingGroup
          fields={shippingGroup?.attributeList}
          onChange={handleChangeValue}
        />
      </Card>
    )
  );
};
