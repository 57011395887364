import { Modal } from 'antd';
import { IArticle } from 'interfaces/article.interface';
import React from 'react';
import { NewsTable } from '../NewsTable';

interface Props {
  visible: boolean;
  onClose: () => void;
  data: IArticle[];
  onRemove: (articleId: string) => void;
}

export const SelectedNewsModal: React.FC<Props> = (props) => {
  const { visible, data, onRemove, onClose } = props;
  return (
    <Modal
      footer={null}
      visible={visible}
      title="Danh sách bài viết đã chọn"
      onCancel={onClose}
      centered
      width={1000}
      className="base-modal"
    >
      <NewsTable
        loading={false}
        data={data}
        onRemove={onRemove}
        scroll={{ y: 400, x: 500 }}
        pagination={false}
      />
    </Modal>
  );
};
