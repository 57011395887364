import { Col, DatePicker, Form, Input, Row, Switch } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';

import { DateFormat } from 'constants/enum';

interface Props {
  data: CountdownData;
  onChange: (data: string) => void;
}

export interface CountdownData {
  blockId: string;
  endDate: string;
  title: string;
  show: boolean;
}

const INITIAL_DATA: Props['data'] = {
  title: '',
  blockId: '',
  endDate: '',
  show: false,
};

export const CountdownTemplate: React.FC<Props> = (props) => {
  const { data = INITIAL_DATA, onChange } = props;

  const _onChange = useCallback(
    (values: Props['data']) => {
      onChange(JSON.stringify(values));
    },
    [onChange],
  );

  const onValuesChange = useCallback(
    (fieldKey: keyof CountdownData, value: any) => {
      _onChange({ ...data, [fieldKey]: value });
    },
    [data, _onChange],
  );

  return (
    <div className="countdown-template">
      <Row gutter={16} className="mt-3 mb-3">
        <Col span={24}>
          <Form.Item label="Block Id (Optional)">
            <Input
              value={data.blockId}
              onChange={(e) => onValuesChange('blockId', e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Tiêu đề">
            <Input value={data.title} onChange={(e) => onValuesChange('title', e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Ngày kết thúc">
            <DatePicker
              value={
                (data?.endDate ? moment(data?.endDate, DateFormat.clientSideWithTime) : null) as any
              }
              placeholder="Chọn ngày kết thúc"
              format={DateFormat.clientSideWithTime}
              showTime={true}
              disabledDate={(current) => {
                const today = moment().startOf('day');
                return current && current < today;
              }}
              onChange={(value) =>
                onValuesChange('endDate', value.format(DateFormat.clientSideWithTime))
              }
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Hiển thị">
            <Switch checked={data?.show} onChange={(checked) => onValuesChange('show', checked)} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
