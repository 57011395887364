import { IAttributePreOrder } from 'interfaces/pre-order.interface';

export class PreOrderModel implements IAttributePreOrder {
  deliveryDate?: string;

  moneyDeposit?: number;

  productId?: number;

  promotionId?: number;

  active?: boolean;

  branchIds?: number[];

  productIds?: number[];

  prebooking?: boolean;

  id?: string;

  constructor({
    deliveryDate,
    moneyDeposit,
    productId,
    promotionId,
    active,
    branchIds,
    productIds,
    prebooking,
    id,
  }: IAttributePreOrder) {
    this.deliveryDate = deliveryDate || null;
    this.moneyDeposit = moneyDeposit || 0;
    this.productId = productId || null;
    this.promotionId = promotionId || null;
    this.active = active !== undefined || active !== null ? active : false;
    this.branchIds = branchIds || [];
    this.productIds = productIds || [];
    this.prebooking = prebooking !== undefined || prebooking !== null ? prebooking : false;
    this.id = id || '';
  }
}
