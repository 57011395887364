import { DeleteOutlined } from '@ant-design/icons';
import { Col, Form, Input, Collapse, DatePicker, Select, Row, Button, Switch, message } from 'antd';
import { clone, uniqBy } from 'lodash';
import moment from 'moment';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { v4 as uuid } from 'uuid';

import promoCatalogSample from 'assets/img/promo_catalog_sample.png';
import { API_GET_BRANDS } from 'constants/api/brand.api';
import { API_GET_VARIANT_PRODUCTS } from 'constants/api/product.api';
import {
  API_GET_POST_PROMOTIONS,
  API_GET_UPDATE_DELETE_PROMOTION,
  API_GET_PRODUCTS_IN_PROMOTION,
} from 'constants/api/promotion.api';
import { API_GET_SUB_STATUS } from 'constants/api/status.api';
import { DateFormat, DiscountUnit } from 'constants/enum';
import { IProduct } from 'interfaces/product.interface';
import { IAttrPromotion } from 'interfaces/promotion.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { PromotionModel } from 'models/promotion.model';
import { setBrands, setSubStatuses } from 'redux/product/actions';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { SelectProductsSection } from 'ui/components/SelectProductsSection';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';

import '../Promotion/Promotion.scss';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select;

const newPromotion = new PromotionModel({});
const defaultGiftOptions = [
  {
    id: uuid(),
    value: '',
  },
];

const PromotionDetail = () => {
  const { id: promotionId } = useParams<{ id: string }>();
  const isAddNew = promotionId === 'new';
  const search = useLocation().search;

  const [loadingButton, setLoadingButton] = useState(false);
  const [isLoaded, setIsLoaded] = useState(isAddNew ? true : false);
  const [promotion, setPromotion] = useState<IAttrPromotion>(newPromotion);
  const [promotionProducts, setPromotionProducts] = useState<IProduct[]>([]);
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [discount, setDiscount] = useState(null);
  const [discountUnit, setDiscountUnit] = useState(DiscountUnit.VND);
  const [description, setDescription] = useState('');
  const [giftOptions, setGiftOptions] = useState(defaultGiftOptions);
  const [catalogText, setCatalogView] = useState('');
  const [loadingVariantPrds, setLoadingVariantPrds] = useState(false);
  const [brandIds, setBrandIds] = useState<number[]>([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(setIsEditing(false));
    if (!isAddNew) {
      Promise.all([
        getPageData(API_GET_UPDATE_DELETE_PROMOTION.replace('{id}', promotionId), dispatch),
        api.get({ endpoint: API_GET_PRODUCTS_IN_PROMOTION.replace('{id}', promotionId) }),
      ])
        .then(([resPromotion, resPromotionProducts]) => {
          const currentPromotion = new PromotionModel(resPromotion?.data?.attributes);
          setDateRange([
            currentPromotion.startDate
              ? moment(currentPromotion.startDate, DateFormat.serverSideWithTime).format(
                  DateFormat.clientSideWithTime,
                )
              : null,
            currentPromotion.endDate
              ? moment(currentPromotion.endDate, DateFormat.serverSideWithTime).format(
                  DateFormat.clientSideWithTime,
                )
              : null,
          ]);
          setBrandIds(currentPromotion.brandIds);
          setDiscount(currentPromotion.discount);
          setDiscountUnit(currentPromotion.discountUnit);
          setDescription(sanitizeHtml(currentPromotion.description));
          setPromotionProducts(resPromotionProducts?.data);
          setGiftOptions(
            currentPromotion.giftOptions.map((item) => ({
              id: uuid(),
              value: item.replace(/\s/g, ''),
            })),
          );
          setCatalogView(currentPromotion.catalogText);
          setPromotion(currentPromotion);
          localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
          form.setFieldsValue(currentPromotion);
          setIsLoaded(true);
        })
        .catch((err) => {
          showErrorMsg(err);
        });
    } else {
      localStorage.setItem('currentPromotion-new', JSON.stringify(newPromotion));
    }
  }, [promotionId]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('currentPromotion-' + promotionId);
      localStorage.removeItem('currentPromotion-new');
    };
  }, []);

  useEffect(() => {
    Promise.all([
      api.get({ endpoint: `${API_GET_SUB_STATUS}?perPage=all` }),
      api.get({ endpoint: `${API_GET_BRANDS}?perPage=all` }),
    ])
      .then(([resSubStatus, resBrand]) => {
        dispatch(setSubStatuses(resSubStatus?.data));
        dispatch(setBrands(resBrand?.data));
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, []);

  const handleClickAddButton = useCallback(() => {
    setPromotion(newPromotion);
    form.setFieldsValue(newPromotion);
    setDateRange([null, null]);
    setDiscount('');
    setDiscountUnit(DiscountUnit.VND);
    setDescription('');
    setPromotionProducts([]);
    setGiftOptions(defaultGiftOptions);
    history.push('/khuyen-mai/new');
    localStorage.setItem('currentPromotion-new', JSON.stringify(newPromotion));
  }, []);

  const handleValuesChange = useCallback(
    (_, values) => {
      let clonePromotion;
      if (!isAddNew) {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-' + promotionId));
      } else {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-new'));
      }

      const currentPromotion = new PromotionModel({ ...clonePromotion, ...values });
      currentPromotion.name = values?.name || '';
      currentPromotion.limitTotal = values?.limitTotal || null;
      currentPromotion.limitInDay = values?.limitInDay || null;
      setPromotion(currentPromotion);
      form.setFieldsValue(currentPromotion);
      dispatch(setIsEditing(true));
      // set current article
      if (isAddNew) {
        localStorage.setItem('currentPromotion-new', JSON.stringify(currentPromotion));
      } else {
        localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
      }
    },
    [promotion],
  );

  const handleChangeDateRange = useCallback(
    (_, dateStrings: string[]) => {
      dispatch(setIsEditing(true));
      let clonePromotion;
      if (!isAddNew) {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-' + promotionId));
      } else {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-new'));
      }
      const currentPromotion = new PromotionModel(clonePromotion);
      currentPromotion.startDate = moment(dateStrings[0], DateFormat.clientSideWithTime).format(
        DateFormat.serverSideWithTime,
      );
      currentPromotion.endDate = moment(dateStrings[1], DateFormat.clientSideWithTime).format(
        DateFormat.serverSideWithTime,
      );
      setPromotion(currentPromotion);
      setDateRange(dateStrings);
      if (!isAddNew) {
        localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
      } else {
        localStorage.setItem('currentPromotion-new', JSON.stringify(currentPromotion));
      }
    },
    [promotion],
  );

  const handleChangeDiscount = useCallback(
    (key: string) => (e: any) => {
      let clonePromotion;
      if (!isAddNew) {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-' + promotionId));
      } else {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-new'));
      }
      const currentPromotion = new PromotionModel({ ...clonePromotion });
      switch (key) {
        case DiscountUnit.PERCENT:
          setDiscount(e.target.value);
          if (currentPromotion.discount !== parseInt(e.target.value)) {
            dispatch(setIsEditing(true));
          }
          currentPromotion.discount = parseInt(e.target.value);
          break;
        case DiscountUnit.VND:
          setDiscount(e.formattedValue);
          if (
            parseInt(currentPromotion.discount?.toString()) !==
            parseInt(e.formattedValue.replaceAll(',', ''))
          ) {
            dispatch(setIsEditing(true));
          }
          currentPromotion.discount = parseInt(e.formattedValue.replaceAll(',', ''));
          break;
        default:
          break;
      }
      setPromotion(currentPromotion);
      if (!isAddNew) {
        localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
      } else {
        localStorage.setItem('currentPromotion-new', JSON.stringify(currentPromotion));
      }
    },
    [promotion],
  );

  const handleChangeDiscountUnit = useCallback(
    (value: DiscountUnit) => {
      dispatch(setIsEditing(true));
      let clonePromotion;
      if (!isAddNew) {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-' + promotionId));
      } else {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-new'));
      }
      const currentPromotion = new PromotionModel(clonePromotion);
      currentPromotion.discountUnit = value;
      setPromotion(currentPromotion);
      setDiscountUnit(value);
      setDiscount('');
      if (!isAddNew) {
        localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
      } else {
        localStorage.setItem('currentPromotion-new', JSON.stringify(currentPromotion));
      }
    },
    [promotion],
  );

  const handleChangeEditor = useCallback(
    (fieldKey: string) => (data: string) => {
      const currentPromotion = localStorage.getItem('currentPromotion-' + promotionId)
        ? new PromotionModel(JSON.parse(localStorage.getItem('currentPromotion-' + promotionId)))
        : new PromotionModel(promotion);
      currentPromotion[fieldKey] = data;
      switch (fieldKey) {
        case 'description':
          setDescription(data != '<p><br></p>' ? sanitizeHtml(data) : '');
          break;
        case 'catalogText':
          setCatalogView(data);
          break;
        default:
          break;
      }
      setPromotion(currentPromotion);
      if (!isAddNew) {
        localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
      } else {
        localStorage.setItem('currentPromotion-new', JSON.stringify(currentPromotion));
      }
    },
    [promotion],
  );

  const handleAddGiftOptionField = useCallback(() => {
    dispatch(setIsEditing(true));
    const clonedGiftOptions = clone(giftOptions);
    clonedGiftOptions.push({ id: uuid(), value: '' });
    setGiftOptions(clonedGiftOptions);
  }, [giftOptions]);

  const handleDeleteGiftOptionField = useCallback(
    (id: string) => () => {
      dispatch(setIsEditing(true));
      let clonePromotion;
      if (!isAddNew) {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-' + promotionId));
      } else {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-new'));
      }
      const currentPromotion = new PromotionModel(clonePromotion);
      const clonedGiftOptions = clone(giftOptions);
      const index = clonedGiftOptions.findIndex((item) => item.id === id);
      if (index > -1) {
        clonedGiftOptions.splice(index, 1);
      }
      setGiftOptions(clonedGiftOptions);
      currentPromotion.giftOptions = clonedGiftOptions.map((item) => item.value);
      setPromotion(currentPromotion);
      if (!isAddNew) {
        localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
      } else {
        localStorage.setItem('currentPromotion-new', JSON.stringify(currentPromotion));
      }
    },
    [giftOptions, promotion],
  );

  const handleChangeGiftOption = useCallback(
    (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setIsEditing(true));
      let clonePromotion;
      if (!isAddNew) {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-' + promotionId));
      } else {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-new'));
      }
      const currentPromotion = new PromotionModel(clonePromotion);
      const clonedGiftOptions = clone(giftOptions);
      const currentInputIndex = clonedGiftOptions.findIndex((item) => item.id === id);
      if (currentInputIndex > -1) {
        clonedGiftOptions.splice(currentInputIndex, 1, {
          id,
          value: e.target.value.replace(/\s/g, ''),
        });
      }
      setGiftOptions(clonedGiftOptions);
      currentPromotion.giftOptions = clonedGiftOptions.map((item) => item.value);
      setPromotion(currentPromotion);
      if (!isAddNew) {
        localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
      } else {
        localStorage.setItem('currentPromotion-new', JSON.stringify(currentPromotion));
      }
    },
    [giftOptions, promotion],
  );

  const handleAddProduct = useCallback(
    async (products: IProduct[], addedProduct: IProduct) => {
      dispatch(setIsEditing(true));
      const clonedProducts = clone(products);
      const currentPromotion = localStorage.getItem('currentPromotion-' + promotionId)
        ? new PromotionModel(JSON.parse(localStorage.getItem('currentPromotion-' + promotionId)))
        : new PromotionModel(promotion);
      if (addedProduct && addedProduct.attributes.type === 'configurable') {
        setLoadingVariantPrds(true);
        const variantProducts: IProduct[] = await api
          .get({ endpoint: API_GET_VARIANT_PRODUCTS.replace('{id}', addedProduct.id) })
          .then((res) => {
            setLoadingVariantPrds(false);
            return res?.data?.filter((item: IProduct) => item.attributes.active);
          });
        clonedProducts.unshift(...variantProducts);
      }
      currentPromotion.productIds = uniqBy(clonedProducts, 'id').map((item) => parseInt(item.id));
      setPromotionProducts(uniqBy(clonedProducts, 'id'));
      setPromotion(currentPromotion);
      if (!isAddNew) {
        localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
      } else {
        localStorage.setItem('currentPromotion-new', JSON.stringify(currentPromotion));
      }
    },
    [promotion, promotionProducts],
  );

  const handleSelectBrands = useCallback(
    (ids: number[]) => {
      setBrandIds(ids);
      let clonePromotion;
      if (!isAddNew) {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-' + promotionId));
      } else {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-new'));
      }
      const currentPromotion = new PromotionModel(clonePromotion);
      currentPromotion.brandIds = ids;
      setPromotion(currentPromotion);
      if (!isAddNew) {
        localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
      } else {
        localStorage.setItem('currentPromotion-new', JSON.stringify(currentPromotion));
      }
    },
    [promotion, promotionProducts],
  );

  const handleAddMultpleProducts = useCallback(
    (products: IProduct[]) => {
      let clonePromotion;
      if (!isAddNew) {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-' + promotionId));
      } else {
        clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-new'));
      }
      const currentPromotion = new PromotionModel(clonePromotion);
      currentPromotion.productIds = products?.map((item) => parseInt(item.id)) || [];
      setPromotionProducts(uniqBy(products, 'id'));
      setPromotion(currentPromotion);
      if (!isAddNew) {
        localStorage.setItem('currentPromotion-' + promotionId, JSON.stringify(currentPromotion));
      } else {
        localStorage.setItem('currentPromotion-new', JSON.stringify(currentPromotion));
      }
    },
    [promotion],
  );

  const handleBackPromotions = () => {
    history.push('/khuyen-mai' + search);
  };

  const handleSavePromotion = useCallback(async () => {
    let clonePromotion;
    if (!isAddNew) {
      clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-' + promotionId));
    } else {
      clonePromotion = JSON.parse(localStorage.getItem('currentPromotion-new'));
    }
    const clonedPromotion = new PromotionModel({
      ...promotion,
      ...clonePromotion,
    });

    if (
      !moment(clonedPromotion.startDate).isValid() ||
      !moment(clonedPromotion.endDate).isValid()
    ) {
      message.error('Vui lòng điền ngày bắt đầu - ngày kết thúc');
      return;
    } else if (
      (!clonedPromotion.discount ||
        isNaN(clonedPromotion.discount) ||
        clonedPromotion.discount.toString() == '0.00') &&
      !clonedPromotion.description
    ) {
      message.error('Vui lòng điền 1 hoặc 2 fields "Giảm giá", "Nội dung" để tặng quà');
      return;
    }
    try {
      setLoadingButton(true);
      const currentPromotion = new PromotionModel({
        ...clonedPromotion,
        description,
        catalogText,
        brandIds,
      });
      if (isAddNew) {
        await api
          .post({ endpoint: API_GET_POST_PROMOTIONS, data: currentPromotion })
          .then((res) => {
            dispatch(setIsEditing(false));
            history.push(`/khuyen-mai/${res?.data.id}`);
          });
        setLoadingButton(false);
        message.success('Lưu khuyến mãi thành công', 3);
      } else {
        await api.put({
          endpoint: API_GET_UPDATE_DELETE_PROMOTION.replace('{id}', promotionId),
          data: currentPromotion,
        });
        setLoadingButton(false);
        dispatch(setIsEditing(false));
        message.success('Lưu khuyến mãi thành công', 3);
      }
    } catch (err) {
      setLoadingButton(false);
      showErrorMsg(err);
    }
  }, [promotion, promotionId, description, catalogText, brandIds]);

  const handleDeletePromotion = useCallback(() => {
    deleteModal({
      title: 'Bạn có muốn xóa khuyến mãi này?',
      onDeleteCb: async () => {
        try {
          await api.remove({
            endpoint: API_GET_UPDATE_DELETE_PROMOTION.replace('{id}', promotionId),
          });
          dispatch(setIsEditing(false));
          history.push('/khuyen-mai');
        } catch (err) {
          showErrorMsg(err);
        }
      },
    });
  }, [promotionId]);

  return (
    <LayoutDetail
      pageTitle={'Chi tiết'}
      isAddNew={isAddNew}
      pageClassName="promotion-detail-page"
      breadcrumbs={[
        {
          title: 'Khuyến mãi - Promotion',
          route: '/khuyen-mai',
        },
        {
          title: isAddNew ? 'Tạo mới' : 'Chi tiết',
          route: `/khuyen-mai/${promotionId}`,
        },
      ]}
      formHook={form}
      handleValuesChange={handleValuesChange}
      handleClickAddButton={handleClickAddButton}
      handleDelete={handleDeletePromotion}
      handleSave={handleSavePromotion}
      saveLoading={loadingButton}
    >
      <Button className="btn-close-promotion" onClick={handleBackPromotions}>
        {' '}
        ĐÓNG{' '}
      </Button>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Tên chương trình"
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng điền tên chương trình',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={19}>
          {isLoaded && (
            <Form.Item label="Text hiện thị trên catalog view">
              <CustomEditor
                metaContent={false}
                listContent={false}
                editorId="catalogText"
                mainContent={catalogText}
                handleChangeMainContent={handleChangeEditor('catalogText')}
              />
            </Form.Item>
          )}
        </Col>
        <Col span={5}>
          <Form.Item label="Sample (line màu hồng)">
            <img src={promoCatalogSample} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Từ ngày - đến ngày (*)"
            rules={[
              {
                required: true,
                message: 'Vui lòng điền ngày bắt đầu - ngày kết thúc',
              },
            ]}
          >
            <RangePicker
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              format={DateFormat.clientSideWithTime}
              value={
                dateRange[0] && dateRange[1]
                  ? [
                      moment(dateRange[0], DateFormat.clientSideWithTime),
                      moment(dateRange[1], DateFormat.clientSideWithTime),
                    ]
                  : null
              }
              showTime={{
                format: 'HH:mm:ss',
                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
              }}
              onChange={handleChangeDateRange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Giảm giá">
            <Input.Group compact>
              {discountUnit === DiscountUnit.PERCENT ? (
                <Input
                  type="number"
                  className="w-90"
                  value={discount}
                  onChange={handleChangeDiscount(DiscountUnit.PERCENT)}
                />
              ) : (
                <NumberFormat
                  className="ant-input w-90"
                  decimalSeparator="."
                  thousandSeparator
                  decimalScale={0}
                  value={discount}
                  onValueChange={handleChangeDiscount(DiscountUnit.VND)}
                />
              )}
              <Select
                value={discountUnit}
                size="large"
                style={{ width: '10%', marginTop: 1 }}
                onChange={handleChangeDiscountUnit}
              >
                <Option value={DiscountUnit.PERCENT}>%</Option>
                <Option value={DiscountUnit.VND}>đ</Option>
              </Select>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Số lượng khuyến mãi" name="limitTotal">
            <Input type="number" />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item label="Số lượng khuyến mãi (trong ngày)" name="limitInDay">
            <Input type="number" />
          </Form.Item>
        </Col> */}
        <Col span={8}>
          <Form.Item label="Hiển thị" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Button className="mb-3" onClick={handleAddGiftOptionField}>
            Thêm Option
          </Button>
          <span className="d-block mb-3 note-text">
            Tặng theo OPTION:
            <br />
            - Nếu chỉ có 1 Option thì tạo 1 OPT - gift package có nhiều SKU
            <br />- Nếu có 2, 3 OPTIONS thì tạo thêm
          </span>
        </Col>
        {giftOptions.map((item) => (
          <Col span={12} key={item.id}>
            <Form.Item label="SP tặng (Theo Option)">
              <Input.Group compact style={{ height: 48 }}>
                <Input
                  placeholder="Gõ mã sản phẩm cách nhau bởi dấu phẩy. VD: L6868, L6869 ..."
                  value={item.value}
                  onChange={handleChangeGiftOption(item.id)}
                  className="w-90"
                />
                <Button
                  title="Xóa option"
                  style={{ width: '10%' }}
                  icon={<DeleteOutlined />}
                  onClick={handleDeleteGiftOptionField(item.id)}
                />
              </Input.Group>
            </Form.Item>
          </Col>
        ))}

        {isLoaded && (
          <Col span={24}>
            <Form.Item label="Nội dung">
              <CustomEditor
                editorId="description"
                mainContent={description}
                handleChangeMainContent={handleChangeEditor('description')}
              />
            </Form.Item>
            <span className="d-block mb-4 note-text">
              - [#n1] là để đánh số.
              <br />- [#GIFT](sku number) là để chèn Sản Phẩm vào vị trí này.
              <br />- [#OPT1] là phần quà cho option 1. Đánh dấu phần quà 2 bằng [#OPT2] để ra dấu
              check.
            </span>
          </Col>
        )}
        <Col span={24}>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Sản phẩm được áp dụng" key="1">
              <SelectProductsSection
                onSelectProduct={handleAddProduct}
                selectedProducts={promotionProducts}
                loading={loadingVariantPrds}
                addProductsByBrands
                selectedBrands={brandIds}
                onSelectBrands={handleSelectBrands}
                onSelectMultipleProducts={handleAddMultpleProducts}
                isPromotion
              />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </LayoutDetail>
  );
};

export default PromotionDetail;
