import { IComment } from 'interfaces/comment.interface';

export const SET_ALL_COMMENTS = 'SET_ALL_COMMENTS';
export const SET_RELOAD_ALL_COMMENTS = '';

export interface SetAllComments {
  type: typeof SET_ALL_COMMENTS;
  payload: IComment[];
}

export interface SetReloadAllComments {
  type: typeof SET_RELOAD_ALL_COMMENTS;
  payload: boolean;
}

export type CommentAction = SetAllComments | SetReloadAllComments;
