/* eslint-disable import/order */
import { CopyOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Row, Select, Switch } from 'antd';
import { RcFile } from 'antd/lib/upload';
import CopyToClipboard from 'react-copy-to-clipboard';

// Interface
import { BrandImageType, MNStoreList } from 'constants/enum';
import { IBrand } from 'interfaces/brand.interface';
import { IImage } from 'interfaces/image.interface';

// Components
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';

// Helper
import { clickCopyUrlPath } from 'utils/utils';

interface FormBrandProps {
  urlPath?: string;
  slugCode?: string;
  mainLogoPreviewImg?: IImage;
  logoMenuPreviewImg?: IImage;
  bannerPreviewImg?: IImage;
  description?: string;
  brand?: IBrand;
  onChangeEditor?: (val) => void;
  onUploadImage?: (type) => (file: RcFile) => void;
  onDeleteImage?: (type) => () => void;
}

const FormBrand: React.FC<FormBrandProps> = (props) => {
  const {
    urlPath,
    slugCode,
    mainLogoPreviewImg,
    logoMenuPreviewImg,
    bannerPreviewImg,
    description,
    brand,
    onChangeEditor,
    onUploadImage,
    onDeleteImage,
  } = props;
  const { id: brandId } = brand || {};

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Tên thương hiệu"
            rules={[
              {
                required: true,
                message: 'Vui lòng điền tên thương hiệu',
              },
            ]}
          >
            <Input placeholder="Tên thương hiệu" autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12} className="url-path">
          <Form.Item label="Slug">
            <Input autoComplete="off" value={urlPath} />
          </Form.Item>
          <CopyToClipboard text={urlPath} onCopy={clickCopyUrlPath}>
            <Button title="Copy" className="copy-button" icon={<CopyOutlined />} />
          </CopyToClipboard>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="storeIds"
            label="Chọn cửa hàng"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn cửa hàng',
              },
            ]}
          >
            <Select showSearch optionFilterProp="title" mode="multiple" allowClear size="large">
              {MNStoreList.map((item) => {
                const { id, title } = item;
                return (
                  <Select.Option
                    value={id}
                    key={`mn-store-${id}`}
                    title={title}
                    className="select-store"
                  >
                    <SanitizeHtml rawHtml={title} />
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} className="url-path">
          <Form.Item label="Slug Code">
            <Input readOnly value={slugCode} />
          </Form.Item>
          <CopyToClipboard text={slugCode} onCopy={clickCopyUrlPath}>
            <Button title="Copy" className="copy-button" icon={<CopyOutlined />} />
          </CopyToClipboard>
        </Col>
      </Row>
      <Form.Item label="Giới thiệu thương hiệu">
        <CustomEditor mainContent={description} handleChangeMainContent={onChangeEditor} />
      </Form.Item>
      <Form.Item name="active" label="Hiển thị" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Collapse>
        <Collapse.Panel header="Hình ảnh" key="1">
          <Form.Item label="Logo chính (400 x 300)">
            <UploadFile
              typeId={brandId}
              maxWidth={400}
              previewImage={mainLogoPreviewImg}
              handleUploadImage={onUploadImage(BrandImageType.logo)}
              handleDeleteImageSuccess={onDeleteImage(BrandImageType.logo)}
            />
          </Form.Item>
          <Form.Item label="Logo trắng đen trên topmenu (400 x 300)">
            <UploadFile
              typeId={brandId}
              maxWidth={400}
              previewImage={logoMenuPreviewImg}
              handleUploadImage={onUploadImage(BrandImageType.logoMenu)}
              handleDeleteImageSuccess={onDeleteImage(BrandImageType.logoMenu)}
            />
          </Form.Item>
          <Form.Item label="Banner thương hiệu (1500 x 465)">
            <UploadFile
              typeId={brandId}
              maxWidth={600}
              previewImage={bannerPreviewImg}
              imageSize="originUrl"
              handleUploadImage={onUploadImage(BrandImageType.banner)}
              handleDeleteImageSuccess={onDeleteImage(BrandImageType.banner)}
            />
          </Form.Item>
        </Collapse.Panel>
        <Collapse.Panel header="Meta Content" key="2">
          <Form.Item name="metaTitle" label="Meta Title">
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="metaKeywords" label="Meta keywords">
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item label="Meta Description" name="metaDescription">
            <Input.TextArea rows={6} />
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default FormBrand;
