/* eslint-disable @typescript-eslint/no-use-before-define */
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { StatusCode } from 'constants/enum';

export const formatNumber = (value: number) => {
  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'VND',
  });
  return value ? formatted.format(value).replace('₫', '').concat(' đ').split(',').join('.') : '0 đ';
};

export const formatPrice = (value: string) => {
  if (value) {
    if (value.includes(',')) {
      return `${value.split(',').join('.')} đ`;
    }
    if (value.includes('.') && value.split('.').length - 1 > 1) {
      return `${value.split('.').join('')} đ`;
    }
    return formatNumber(parseInt(value));
  }
  return '0 đ';
};

export const calcPercentPromotion = (discountPrice: any, price: any) => {
  const percent = 100 - (parseFloat(discountPrice) * 100) / price;
  return Math.round(percent);
};

export const getBasePrice = (salePrice: any, price: any, discountPrice?: any) => {
  let basePrice = '';
  let oldPrice = '';

  if (discountPrice && salePrice <= 0) {
    // salePrice
    if (typeof discountPrice === 'string') {
      if (parseInt(discountPrice) > 0) {
        basePrice = formatPrice(discountPrice);
      }
    } else if (typeof discountPrice === 'number' && discountPrice > 0) {
      basePrice = formatNumber(discountPrice);
    } else {
      basePrice = '';
    }
    // price
    if (price) {
      if (typeof price === 'string') {
        oldPrice = formatPrice(price);
      } else if (typeof price === 'number') {
        oldPrice = formatNumber(price);
      } else {
        oldPrice = '';
      }
    }
  } else if (salePrice) {
    // salePrice
    if (typeof salePrice === 'string') {
      if (parseInt(salePrice) > 0) {
        basePrice = formatPrice(salePrice);
      }
    } else if (typeof salePrice === 'number') {
      basePrice = formatNumber(salePrice);
    } else {
      basePrice = '';
    }
    // price
    if (price) {
      if (typeof price === 'string') {
        oldPrice = formatPrice(price);
      } else if (typeof price === 'number') {
        oldPrice = formatNumber(price);
      } else {
        oldPrice = '';
      }
    }
  } else {
    // salePrice
    if (price > 0) {
      if (typeof price === 'string') {
        basePrice = formatPrice(price);
      } else if (typeof price === 'number') {
        basePrice = formatNumber(price);
      } else {
        basePrice = '';
      }
    }
  }

  return {
    basePrice,
    oldPrice,
  };
};

export const getDiscountPercent = (product: any) => {
  const { promotion, salePrice, price } = product || {};
  // TODO - preorder later
  // const { percent, discount, discountUnit, discountPrice } = preorder?.promotion || promotion || {};
  const { percent, discount, discountUnit, discountPrice } = promotion || {};

  const { finalSellPrice, originPrice } = getFinalSellPrice(price, salePrice, promotion);

  if (
    (parseFloat(discount) <= 0 &&
      originPrice > 0 &&
      calcPercentPromotion(finalSellPrice, originPrice) > 0) ||
    salePrice > 0
  ) {
    return calcPercentPromotion(finalSellPrice, originPrice);
  }

  if (discountUnit === 'percent' && percent > 0) {
    return percent || parseFloat(discount);
  }

  if (
    parseFloat(discount) > 0 &&
    discountUnit == 'percent' &&
    calcPercentPromotion(discountPrice, price) > 0
  ) {
    return calcPercentPromotion(discountPrice, price);
  }

  if (
    parseFloat(discount) > 0 &&
    discountUnit == 'vnd' &&
    calcPercentPromotion(finalSellPrice, originPrice) > 0
  ) {
    return calcPercentPromotion(finalSellPrice, originPrice);
  }

  return 0;
};

export const getFinalSellPrice = (originPrice: any, salePrice: any, promotion?: any) => {
  let displayFinalSellPrice;
  let displayedOriginPrice;
  let isGiftContent = false;

  if (isEmpty(promotion)) {
    // case khong co promo
    // case khong có sale
    if (salePrice <= 0) {
      displayFinalSellPrice = originPrice;
      displayedOriginPrice = 0;
    } else {
      // case có sale trong detail
      displayFinalSellPrice = salePrice;
      displayedOriginPrice = originPrice;
    }
  } else {
    const { discountPrice, discount, discountUnit, percent, endDate } = promotion;

    // compare promotion endDate and today
    const promoEndDate = moment(endDate);
    const now = moment();
    if (now > promoEndDate) {
      // out of date promotion
      isGiftContent = false;
    } else {
      // promotion is exists
      isGiftContent = true;
    }

    if (salePrice > 0) {
      displayFinalSellPrice = salePrice;
      displayedOriginPrice = originPrice;
    } else if (!discountPrice && discount == '0.00') {
      // case promo chi tặng quà
      if (salePrice <= 0) {
        // case khong có giá sale
        displayFinalSellPrice = originPrice;
        displayedOriginPrice = 0;
      } else {
        // case có sale trong detail
        displayFinalSellPrice = salePrice;
        displayedOriginPrice = originPrice;
      }
    } else if (discountUnit === 'percent') {
      // case có promo giảm giá
      if (salePrice <= 0) {
        // case khong có giá sale
        displayFinalSellPrice = originPrice - originPrice * (percent / 100);
        displayedOriginPrice = originPrice;
      } else {
        // case có sale trong detail
        displayFinalSellPrice = salePrice - salePrice * (percent / 100);
        displayedOriginPrice = originPrice;
      }
    } else if (discountUnit === 'vnd') {
      // case có promo giảm giá
      if (salePrice <= 0) {
        // case khong có giá sale
        displayFinalSellPrice = originPrice - parseFloat(discount);
        displayedOriginPrice = originPrice;
      } else {
        // case có sale trong detail
        displayFinalSellPrice = salePrice - parseFloat(discount);
        displayedOriginPrice = originPrice;
      }
    }
  }

  return {
    finalSellPrice: parseFloat(displayFinalSellPrice) || 0,
    originPrice: parseFloat(displayedOriginPrice) || 0,
    isGiftContent,
  };
};

export const getStickerByStatus = (
  customize: any,
  statusName: string,
  statusCode: string,
  isPreorder?: boolean,
) => {
  // case product pre-order
  if (isPreorder) {
    return {
      background: '#308ADD',
      text: '#fff',
      border: 'transparent',
      title: 'PRE-ORDER',
    };
  }

  if (customize) {
    const { backgroundColor, borderColor, text, textColor } = customize;
    if (backgroundColor && borderColor && text && textColor) {
      return {
        background: backgroundColor,
        text: textColor,
        border: borderColor,
        title: text,
      };
    }
  }

  // status Coming Soon and Outstock
  switch (statusCode && statusCode.split('_')[0]) {
    case StatusCode.OUTSTOCK:
      return {
        background: '#000',
        text: '#fff',
        border: 'transparent',
        title: statusCode.split('_')[1] === 'CALL' ? 'Tạm hết hàng' : 'Hết hàng',
      };
    case StatusCode.COMING:
      return {
        background:
          statusCode.split('_')[1] === 'UP'
            ? 'linear-gradient(270deg, #FF6600 0%, #F15614 100%)'
            : '#fff',
        text: statusCode.split('_')[1] === 'UP' ? '#fff' : '#308ADD',
        border: statusCode.split('_')[1] === 'UP' ? 'transparent' : '#308ADD',
        title: statusCode.split('_')[1] === 'UP' ? statusName : 'COMING SOON',
      };
  }

  // status New & COMING_UP
  switch (statusCode) {
    case StatusCode.INSTOCK_NEW:
      return {
        background: 'linear-gradient(270deg, #db0000 0%, #bd0000 100%)',
        text: '#fff',
        border: 'transparent',
        title: 'NEW',
      };
    default:
      return {};
  }
};

export const convertPriceToHTML = (value: string) => {
  if (value) {
    const priceValue = value.split(' ');
    const htmlMockup = `<span class="txt-price">${priceValue[0]}</span><span class="symb"> ${priceValue[1]}</span>`;
    return htmlMockup;
  }
  return '';
};
