import PageSignIn from 'pages/authentifications/SignIn/SignIn';
import ArticlesPage from 'pages/CMS/Article/Articles';
import ArticleDetailsPage from 'pages/CMS/ArticleDetail/ArticleDetails';
import CategoriesPage from 'pages/CMS/Category/Categories';
import CommentsPage from 'pages/CMS/Comments/Comments';
import GalleryPage from 'pages/CMS/Gallery/Gallery';
import InstagramTokenManagerPage from 'pages/CMS/InstagramTokenManager/InstagramTokenManager';
import SinglePageDetail from 'pages/CMS/SinglePages/Detail/SinglePageDetail';
import SinglePage from 'pages/CMS/SinglePages/SinglePages';
import YoutubeManagerPage from 'pages/CMS/YoutubeManager/YoutubeManager';
import CollectionDetail from 'pages/CollectionDetail/CollectionDetail';
import CollectionsPage from 'pages/Collections/Collections';
import CouponDetailPage from 'pages/CouponDetail/CouponDetail';
import CouponsPage from 'pages/Coupons/Coupons';
import PageDashboard from 'pages/dashboards/Dashboard/Dashboard';
import EventPage from 'pages/EventPage/EventPage';
import EventPageDetail from 'pages/EventPageDetail/EventPageDetail';
import MemberDetailsPage from 'pages/Members/Details';
import MemberComments from 'pages/Members/Details/Comments';
import MemberOrder from 'pages/Members/Details/Order';
import MemberOrders from 'pages/Members/Details/Orders';
import MemberReviews from 'pages/Members/Details/Reviews';
import MemberWishList from 'pages/Members/Details/WishList';
import FeedbackPage from 'pages/Members/Feedback';
import MemberListPage from 'pages/Members/List';
import ManageBirthday from 'pages/Members/ManageBirthday';
import ManageVoucher from 'pages/Members/ManageVoucher';
import MemberPoliciesPage from 'pages/Members/Policies';
import ReviewPage from 'pages/Members/Review';
import MainMenuRider from 'pages/Menus/MainMenuRider/MainMenuRider';
import MenuProductList from 'pages/Menus/ProductsMenu/ProductsMenu';
import OrdersPage from 'pages/Orders';
import OrderDetailPage from 'pages/Orders/Detail';
import PreOrderListPage from 'pages/PreOrder/PreOrderList/PreOrderList';
import SystemSizeGroups from 'pages/ProductAttributes/SystemSize/SizeGroups/SizeGroups';
import SystemSizeItems from 'pages/ProductAttributes/SystemSize/SizeItems/SizeItems';
import AttrGroup from 'pages/Products/AttrGroup/AttrGroup';
import Attributes from 'pages/Products/Attributes/Attributes';
import BrandsPage from 'pages/Products/Brand/Brands';
import OSGroupPage from 'pages/Products/OSGroup/OSGroup';
import ProductCategory from 'pages/Products/ProductCategory/ProductCategory';
import ProductDetail from 'pages/Products/ProductDetail/ProductDetail';
import ProductList from 'pages/Products/ProductsList/ProductsList';
import PromotionPage from 'pages/Products/Promotion/Promotion';
import PromotionDetailPage from 'pages/Products/PromotionDetail/PromotionDetail';
import SubStatus from 'pages/Products/SubStatus/SubStatus';
import SystemColorPage from 'pages/Products/SystemColor/SystemColor';
import RiderModels from 'pages/Rider/RiderModels/RiderModels';
import RiderType from 'pages/Rider/RiderType/RiderType';
import ServiceCenter from 'pages/ServiceCenter/ServiceCenter';
import RoleList from 'pages/Users/RoleList/RoleList';
import UserDetail from 'pages/Users/UserDetail/UserDetail';
import UserList from 'pages/Users/UserList/UserList';
import ArticleDetailPresent from 'pages/WebPresent/ArticleDetailPresent/ArticleDetailPresent';
import CouponPresent from 'pages/WebPresent/CouponPresent/CouponPresent';
import HomePresentPage from 'pages/WebPresent/HomePresent/HomePresent';
import PaymentOrderPresent from 'pages/WebPresent/PaymentOrder/PaymentOrder';
import ProductDetailPresent from 'pages/WebPresent/ProductDetailPresent/ProductDetailPresent';
import RiderSinglePage from 'pages/WebPresent/Rider/SinglePagePresent/SinglePagePresent';
import RiderHeaderFooterPresent from 'pages/WebPresent/RiderHeaderFooterPresent/RiderHeaderFooterPresent';
import RiderHomePresentPage from 'pages/WebPresent/RiderHomePresent/RiderHomePresent';
import RiderProductDetailPresent from 'pages/WebPresent/RiderProductDetailPresent/RiderProductDetailPresent';
import TrackingOrderPresent from 'pages/WebPresent/TrackingOrder/TrackingOrder';
import WarrantyPresent from 'pages/WebPresent/Warranty/Warranty';

export interface IRoute {
  path: string;
  component: any;
}

export const privateRoutes: IRoute[] = [
  {
    path: '/',
    component: PageDashboard,
  },
  {
    path: '/nhom-san-pham',
    component: ProductCategory,
  },
  {
    path: '/khuyen-mai',
    component: PromotionPage,
  },
  {
    path: '/khuyen-mai/:id',
    component: PromotionDetailPage,
  },
  {
    path: '/ma-giam-gia',
    component: CouponsPage,
  },
  {
    path: '/ma-giam-gia/:id',
    component: CouponDetailPage,
  },
  {
    path: '/bo-suu-tap',
    component: CollectionsPage,
  },
  {
    path: '/bo-suu-tap/:id',
    component: CollectionDetail,
  },
  {
    path: '/thuong-hieu',
    component: BrandsPage,
  },
  {
    path: '/quan-ly-youtube',
    component: YoutubeManagerPage,
  },
  {
    path: '/quan-ly-instagram-token',
    component: InstagramTokenManagerPage,
  },
  {
    path: '/he-dieu-hanh',
    component: OSGroupPage,
  },
  {
    path: '/nhom-thuoc-tinh-san-pham',
    component: AttrGroup,
  },
  {
    path: '/thuoc-tinh-san-pham',
    component: Attributes,
  },
  {
    path: '/chuyen-muc',
    component: CategoriesPage,
  },
  {
    path: '/bai-viet',
    component: ArticlesPage,
  },
  {
    path: '/soan-bai-viet',
    component: ArticleDetailsPage,
  },
  {
    path: '/bai-viet/:id',
    component: ArticleDetailsPage,
  },
  {
    path: '/trung-tam-bao-hanh',
    component: ServiceCenter,
  },
  {
    path: '/phong-cach-choi-xe',
    component: RiderType,
  },
  {
    path: '/cac-dong-xe',
    component: RiderModels,
  },
  {
    path: '/phan-quyen',
    component: RoleList,
  },
  {
    path: '/users',
    component: UserList,
  },
  {
    path: '/users/:id',
    component: UserDetail,
  },
  {
    path: '/san-pham',
    component: ProductList,
  },
  {
    path: '/san-pham/:id',
    component: ProductDetail,
  },
  {
    path: '/trang-thai-phu',
    component: SubStatus,
  },
  {
    path: '/menu/danh-muc-san-pham',
    component: MenuProductList,
  },
  {
    path: '/menu/danh-muc-chinh-rider',
    component: MainMenuRider,
  },
  {
    path: '/trinh-bay-trang-home',
    component: HomePresentPage,
  },
  {
    path: '/trinh-bay-trang-rider-home',
    component: RiderHomePresentPage,
  },
  {
    path: '/trinh-bay-trang-coupon',
    component: CouponPresent,
  },
  {
    path: '/trinh-bay-trang-rider-coupon',
    component: CouponPresent,
  },
  {
    path: '/trinh-bay-trang-chi-tiet-san-pham',
    component: ProductDetailPresent,
  },
  {
    path: '/trinh-bay-tra-cuu-don-hang',
    component: TrackingOrderPresent,
  },
  {
    path: '/trinh-bay-thong-tin-bao-hanh',
    component: WarrantyPresent,
  },
  {
    path: '/trinh-bay-thanh-toan-don-hang',
    component: PaymentOrderPresent,
  },
  {
    path: '/trinh-bay-trang-bai-viet',
    component: ArticleDetailPresent,
  },
  {
    path: '/trinh-bay-rider-header-footer',
    component: RiderHeaderFooterPresent,
  },
  {
    path: '/trinh-bay-rider-chi-tiet-san-pham',
    component: RiderProductDetailPresent,
  },
  {
    path: '/trinh-bay-rider-trang-don',
    component: RiderSinglePage,
  },
  {
    path: '/mau-he-thong',
    component: SystemColorPage,
  },
  {
    path: '/he-size',
    component: SystemSizeGroups,
  },
  {
    path: '/sizes',
    component: SystemSizeItems,
  },
  {
    path: '/gallery',
    component: GalleryPage,
  },
  {
    path: '/preorders',
    component: PreOrderListPage,
  },
  {
    path: '/comments',
    component: CommentsPage,
  },
  {
    path: '/trang-don',
    component: SinglePage,
  },
  {
    path: '/trang-don/:id',
    component: SinglePageDetail,
  },
  {
    path: '/event-page',
    component: EventPage,
  },
  {
    path: '/event-page/:id',
    component: EventPageDetail,
  },
  {
    path: '/danh-sach-members',
    component: MemberListPage,
  },
  {
    path: '/danh-sach-members/:id',
    component: MemberDetailsPage,
  },
  {
    path: '/danh-sach-members/:id/reviews',
    component: MemberReviews,
  },
  {
    path: '/danh-sach-members/:id/comments',
    component: MemberComments,
  },
  {
    path: '/danh-sach-members/:id/wishlist',
    component: MemberWishList,
  },
  {
    path: '/danh-sach-members/:id/orders',
    component: MemberOrders,
  },
  {
    path: '/danh-sach-members/:id/orders/:orderId',
    component: MemberOrder,
  },
  {
    path: '/danh-sach-don-hang',
    component: OrdersPage,
  },
  {
    path: '/danh-sach-don-hang/:orderId',
    component: OrderDetailPage,
  },
  {
    path: '/danh-sach-gop-y',
    component: FeedbackPage,
  },
  {
    path: '/member-reviews',
    component: ReviewPage,
  },
  {
    path: '/chinh-sach-thanh-vien',
    component: MemberPoliciesPage,
  },
  {
    path: '/quan-ly-coupon-sinh-nhat',
    component: ManageBirthday,
  },
  {
    path: '/quan-ly-coupon-voucher',
    component: ManageVoucher,
  },
];

export const publicRoutes: IRoute[] = [
  {
    path: '/dang-nhap',
    component: PageSignIn,
  },
];
