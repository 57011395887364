import { IPagination } from 'interfaces/pagination.interface';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePageData } from 'redux/pages/actions';
import { fetcherWithArg, poster, updater } from 'services/swr.utils';
import useSWRMutation from 'swr/mutation';
import { buildParams } from 'utils/utils';
import { API_COLLECTION, API_COLLECTIONS } from 'constants/api/collection.api';
import { defaultPagination } from 'constants/constants';

/**
 * Custom hook that fetches and returns collections data.
 */
export const useFetchCollections = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data, isMutating, trigger } = useSWRMutation(API_COLLECTIONS, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    trigger(buildParams(params));
  }, []);

  const fetchCollections = useCallback(
    async (params?: {
      page?: number;
      title?: string;
      sku?: string;
      storeId?: string;
    }): Promise<void> => {
      const { page = pagination.current, ...rest } = params || {};
      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;

      history.push(
        queryString.stringifyUrl(
          {
            url: location.pathname,
            query: {
              page: calcPage,
              ...rest,
            },
          },
          { skipEmptyString: true, skipNull: true },
        ),
      );

      await trigger(buildParams({ page: calcPage, ...rest }));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetching: isMutating,
    data: data?.data,
    pagination,
    setPagination,
    refetch: fetchCollections,
  };
};

/**
 *
 * @returns
 */
export const useCreateCollection = () => {
  const { isMutating, trigger } = useSWRMutation(API_COLLECTIONS, poster);

  return {
    isCreating: isMutating,
    onCreate: trigger,
  };
};

/**
 *
 * @returns
 */
export const useRemoveCollections = () => {
  const { isMutating, trigger } = useSWRMutation(API_COLLECTION, updater);

  return {
    isRemoving: isMutating,
    onRemove: trigger,
  };
};
