import { isEmpty } from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CouponStatus } from 'constants/enum';

interface Props {
  onChange: (status: CouponStatus) => void;
}

export const StatusFilter: React.FC<Props> = (props) => {
  const { onChange } = props;
  const [statusSelected, setStatusSelected] = useState<CouponStatus | null>(null);

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (!isEmpty(params)) {
      setStatusSelected(params?.status as CouponStatus);
    }
  }, [params]);

  const onSelectStatus = useCallback(
    (status: CouponStatus) => {
      setStatusSelected(status);
      onChange(status);
    },
    [onChange],
  );

  return (
    <div className="status-filter">
      <div
        className={`status-filter__item status-filter__item--active ${
          statusSelected === CouponStatus.active ? 'status-filter__item--selected' : ''
        }`}
        onClick={() => onSelectStatus(CouponStatus.active)}
      >
        Hoạt động
      </div>
      <span>|</span>
      <div
        className={`status-filter__item status-filter__item--draft ${
          statusSelected === CouponStatus.draft ? 'status-filter__item--selected' : ''
        }`}
        onClick={() => onSelectStatus(CouponStatus.draft)}
      >
        Nháp
      </div>
      {/* <span>|</span>
      <div
        className={`status-filter__item status-filter__item--expired ${
          statusSelected === CouponStatus.expired ? 'status-filter__item--selected' : ''
        }`}
        onClick={() => onSelectStatus(CouponStatus.expired)}
      >
        Hết hạn
      </div> */}
      <span>|</span>
      <div
        className={`status-filter__item status-filter__item--close ${
          statusSelected === CouponStatus.close ? 'status-filter__item--selected' : ''
        }`}
        onClick={() => onSelectStatus(CouponStatus.close)}
      >
        Đã đóng
      </div>
    </div>
  );
};
