import { DeleteOutlined } from '@ant-design/icons';
import { Button, Table, TableProps } from 'antd';
import { IArticle, IArticleAttribute } from 'interfaces/article.interface';
import React, { useEffect } from 'react';
import { SortableTable } from 'ui/components/SortableTable/SortableTable';

import './styles.scss';

interface Props extends TableProps<any> {
  loading: boolean;
  data: IArticle[];
  onChangePosition?: (articles: any[]) => void;
  onRemove?: (articleId: string) => void;
  hasSort?: boolean;
}

export const NewsTable: React.FC<Props> = (props) => {
  const { loading, data, onChangePosition, onRemove, hasSort = false, ...rest } = props;

  /**
   * An effect that updates the empty list message when the fetching status of articles changes.
   */
  useEffect((): void => {
    if (!loading) {
      const selector = '.news-table-wrapper table .ant-empty-description';
      const els = document.querySelectorAll(selector);
      if (els?.length) {
        els.forEach((el) => {
          el.innerHTML = 'Danh sách trống';
        });
      }
    }
  }, [loading]);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      width: 70,
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tiêu đề bài viết',
      dataIndex: 'attributes',
      render: (attr: IArticleAttribute) => <div>{attr.name}</div>,
    },
    onRemove
      ? {
          title: 'Thao tác',
          width: 100,
          render: (row: IArticle) => (
            <Button title="Xóa" icon={<DeleteOutlined />} onClick={() => onRemove(row.id)} />
          ),
        }
      : undefined,
  ].filter((c) => !!c);

  return (
    <>
      <div className="news-table-wrapper">
        {hasSort ? (
          <SortableTable
            showHeader={false}
            columns={columns}
            dataList={data}
            rowKey={'id' as any}
            loading={loading}
            onChanged={onChangePosition}
            {...rest}
          />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            rowKey={'id' as any}
            loading={loading}
            {...rest}
          />
        )}
      </div>
    </>
  );
};
