/* eslint-disable @typescript-eslint/no-use-before-define */
import { Checkbox, Col, Row, Spin, message } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RcFile } from 'antd/lib/upload';
import { IAttributeValue } from 'interfaces/attribute.interface';
import { IImage } from 'interfaces/image.interface';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { uploadFile } from 'utils/utils';
import { v4 as uuid } from 'uuid';
import { API_GET_PRODUCT_DETAIL } from 'constants/api/product.api';
import SelectedStores from './components/SelectedStores';
import { useFetchStoresProdExp, useUpdateProdExperience } from './hook';

import './index.styles.scss';

type ProductExperienceProps = {
  attrValues: IAttributeValue[];
  attrList: any;
};

const CODE_PROD_EXP_VIDEO = 'prod_exp_video';
const CODE_PROD_EXP_PUBLISH = 'prod_exp_publish';
const CODE_PROD_EXP_BRANCHES = 'prod_exp_branches';

const ProductExperience: React.FC<ProductExperienceProps> = ({ attrValues, attrList }) => {
  const { id: productId } = useParams<{ id: string }>();

  const { isUpdating, onUpdate } = useUpdateProdExperience();

  const [attrVideoId, setAttrVideoId] = useState<string>(null);
  const [attrPublishId, setAttrPublishId] = useState<string>(null);
  const [attrStoresId, setAttrStoresId] = useState<string>(null);

  const [checked, setChecked] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [previewVideo, setPreviewVideo] = useState<IImage>(null);
  const [storeIds, setStoreIds] = useState<string[]>([]);
  const [isErrorStores, setIsErrorStores] = useState(false);

  const { isFetching: isLoadingStores, data: stores } = useFetchStoresProdExp();

  useEffect(() => {
    if (!isEmpty(attrList)) {
      const videoId =
        attrList?.attributeList?.find((item) => item.attributes.code === CODE_PROD_EXP_VIDEO).id ||
        null;
      const publishId =
        attrList?.attributeList?.find((item) => item.attributes.code === CODE_PROD_EXP_PUBLISH)
          .id || null;
      const storesId =
        attrList?.attributeList?.find((item) => item.attributes.code === CODE_PROD_EXP_BRANCHES)
          .id || null;

      setAttrVideoId(videoId);
      setAttrPublishId(publishId);
      setAttrStoresId(storesId);
    }

    return () => {
      setAttrVideoId(null);
      setAttrPublishId(null);
      setAttrStoresId(null);
    };
  }, [attrList]);

  useEffect(() => {
    if (!isEmpty(attrValues)) {
      // get value prodduct experience video
      const attrVideoValue = attrValues.find((item) => item.attributeCode === CODE_PROD_EXP_VIDEO);
      const video = attrVideoValue?.value?.find((v) => v.title === 'video') || null;
      setPreviewVideo(video);

      handleSetAttrValues(attrValues);
    }

    return () => {
      setPreviewVideo(null);
    };
  }, [attrValues]);

  // TODO: set value for field publish and stores
  const handleSetAttrValues = (values: IAttributeValue[]) => {
    // get value prodduct experience publish
    const attrPublishValue =
      values?.find((item) => item.attributeCode === CODE_PROD_EXP_PUBLISH)?.value || null;
    setChecked(attrPublishValue == 1 ? true : false);

    // get value prodduct experience stores
    const attrStoresValue = JSON.parse(
      values?.find((item) => item.attributeCode === CODE_PROD_EXP_BRANCHES)?.value || null,
    );
    setStoreIds(attrStoresValue);
  };

  // TODO: handle swicth on/off product experience
  const onSwitchProdExp = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  // TODO: handle selected stores
  const onSelectedStores = useCallback((ids: string[]) => {
    setIsErrorStores(false);
    setStoreIds(ids);
  }, []);

  // TODO: call api update attribute
  const callApiUpdateAttibute = async (payload) => {
    try {
      const newData = await onUpdate({
        method: 'put',
        id: productId,
        data: payload,
      });

      await mutate(API_GET_PRODUCT_DETAIL.replace('{id}', productId), newData, {
        revalidate: false,
        rollbackOnError: true,
      });

      message.success('Lưu trải nghiệm sản phẩm thành công', 3);
    } catch (error) {
      message.error('Có lỗi xảy ra, vui lòng thử lại', 3);
    }
  };

  // TODO: Upload video
  const onUploadVideo = useCallback(
    async (value: RcFile) => {
      setUploading(true);
      const resVideo = await uploadFile(
        value,
        'productfile',
        productId,
        'video',
        '',
        attrVideoId.toString(),
      );
      setUploading(false);

      if (resVideo) {
        setPreviewVideo({ ...resVideo?.resize, original: resVideo?.originUrl, id: resVideo?.id });

        const payload = {
          attributeValues: [
            {
              attributeId: attrVideoId,
              value: {
                id: resVideo?.id,
                title: resVideo?.title,
                original: resVideo?.originUrl,
              },
            },
          ],
        };

        callApiUpdateAttibute(payload);
        message.success('Upload video trải nghiệm thành công', 3);
      }
    },
    [attrVideoId, productId],
  );

  // TODO: handle delete video
  const onDeleteVideo = useCallback(() => {
    setPreviewVideo(null);

    const payload = {
      attributeValues: [
        {
          attributeId: attrVideoId,
          value: [],
        },
      ],
    };

    callApiUpdateAttibute(payload);
  }, [attrVideoId]);

  // TODO: handle save product experience block
  const onSaveProdExp = useCallback(async () => {
    if (checked) {
      if (isEmpty(storeIds)) {
        setIsErrorStores(true);
        return;
      }
    }

    const payload = {
      attributeValues: [
        {
          attributeId: attrPublishId,
          value: checked,
        },
        {
          attributeId: attrStoresId,
          value: JSON.stringify(storeIds),
        },
      ],
    };

    callApiUpdateAttibute(payload);
  }, [productId, attrPublishId, checked, attrStoresId, storeIds]);

  return (
    <div className="product-experience">
      <Row className="mb-3">
        <Col span={12}>
          <Checkbox onChange={onSwitchProdExp} checked={checked}>
            Trải nghiệm
          </Checkbox>
        </Col>
        <Col span={12} className="text-right">
          <ThemeButton onClick={onSaveProdExp} loading={isUpdating}>
            Lưu trải nghiệm SP
          </ThemeButton>
        </Col>
      </Row>
      <div className="mb-3 relative">
        {uploading && (
          <div className="loading-wrapper">
            <Spin />
          </div>
        )}

        <UploadFile
          uploadText={`Upload video trải nghiệm, vui lòng dùng file MP4 nén`}
          handleUploadImage={(file) => onUploadVideo(file)}
          typeId={uuid()}
          fileAccept="video/mp4"
          handleDeleteImageSuccess={onDeleteVideo}
          previewImage={previewVideo}
        />
      </div>
      <p>
        Nếu không dùng Video thì có thể nhập hình LifeStyle vào mục hình ảnh làm hiệu ứng chuyển
        ảnh. Nhập hình vuông <b>(Tỉ lệ 1:1)</b>
      </p>
      <SelectedStores
        stores={stores}
        onSelect={onSelectedStores}
        stdStore={storeIds}
        isErrorStores={isErrorStores}
        isLoading={isLoadingStores}
      />
      {isErrorStores && (
        <p className="text-danger mt-1">Vui lòng chọn chi nhánh có hàng trải nghiệm</p>
      )}
    </div>
  );
};

export default ProductExperience;
