import { CloseCircleFilled, DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Radio, Row, Select, Switch, Table } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { clone, isEmpty, sortBy } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { API_GET_BRANDS } from 'constants/api/brand.api';
import {
  API_GET_LINKS_IN_BLOCK,
  API_GET_MENU_BLOCKS_IN_MENU,
  API_POST_MENU_BLOCKS,
  API_POST_MENU_LINK,
  API_UPDATE_DELETE_MENU_BLOCK,
  API_UPDATE_DELETE_MENU_LINK,
} from 'constants/api/menu.api';
import { AlignType, BlockType } from 'constants/enum';
import { IBrand } from 'interfaces/brand.interface';
import { IImage } from 'interfaces/image.interface';
import { IMenuBlock } from 'interfaces/menu-block.interface';
import { IMenuLink, IAttributeMenuLink } from 'interfaces/menu-link.interface';
import { MenuBlockModel } from 'models/menu-block.model';
import { MenuLinkModel } from 'models/menu-link.model';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { CustomModal } from 'ui/components/MaiNguyen/CustomModal';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { CustomTag } from 'ui/components/MaiNguyen/CustomTag';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { deleteModal } from 'utils/modals';
import { filterInt, showErrorMsg, generateSlug, uploadFile } from 'utils/utils';

import './index.scss';

interface SubMenuBlockProps {
  menuId: number;
  menuBlocks: IMenuBlock[];
}

interface CustomBlockProps extends IMenuBlock {
  links: IMenuLink[];
}

const subMenuCol = [1, 2, 3, 4];
const newMenuLink = new MenuLinkModel({});
const newMenuBlock = new MenuBlockModel({});

export const SubMenuBlock: FC<SubMenuBlockProps> = ({ menuId, menuBlocks }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [blocks, setBlocks] = useState<CustomBlockProps[]>([]);
  const [dropBlockId, setDropBlockId] = useState('');
  const [blockType, setBlockType] = useState('');
  const [menuLinks, setMenuLinks] = useState<IMenuLink[]>([]);
  const [menuLink, setMenuLink] = useState<IMenuLink>(null);
  const [openMenuLinkModal, setOpenMenuLinkModal] = useState(false);
  const [menuBlock, setMenuBlock] = useState(newMenuBlock);
  const [blockGroup, setBlockGroup] = useState<number>(null);
  const [blockId, setBlockId] = useState('');
  const [loadingTable, setLoadingTable] = useState(false);
  const [reloadBlocks, setReloadBlocks] = useState(false);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [fileImage, setFileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState<IImage>(null);

  const [formLink] = Form.useForm();
  const [formBlock] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const clonedBlocks: CustomBlockProps[] = clone(menuBlocks as CustomBlockProps[]);
    clonedBlocks.forEach(async (item) => {
      item.links = await api
        .get({ endpoint: API_GET_LINKS_IN_BLOCK.replace('{id}', item.id) })
        .then((res) => res?.data);
    });
    setTimeout(() => {
      setBlocks(clonedBlocks);
    }, 150);
    api.get({ endpoint: `${API_GET_BRANDS}?perPage=all` }).then((res) => {
      setBrands(res?.data);
    });
  }, [menuBlocks]);

  useEffect(() => {
    if (reloadBlocks) {
      api
        .get({ endpoint: API_GET_MENU_BLOCKS_IN_MENU.replace('{id}', menuId.toString()) })
        .then((res) => {
          const clonedBlocks: CustomBlockProps[] = clone(res?.data);
          clonedBlocks.forEach(async (item) => {
            item.links = await api
              .get({ endpoint: API_GET_LINKS_IN_BLOCK.replace('{id}', item.id) })
              .then((linkRes) => linkRes?.data);
          });
          setTimeout(() => {
            setBlocks(clonedBlocks);
          }, 150);
          setReloadBlocks(false);
        });
    }
  }, [reloadBlocks, menuId]);

  const handleOpenMenuLinkModal = useCallback(() => {
    setOpenMenuLinkModal(true);
    setMenuLink({ id: uuid(), type: 'links', attributes: newMenuLink });
    formLink.resetFields();
  }, []);

  const handleCloseMenuLinkModal = () => {
    setOpenMenuLinkModal(false);
  };

  const onOpenSubMenuModal = useCallback(
    (group: number) => () => {
      setOpenSubMenu(true);
      setBlockGroup(group);
    },
    [menuId],
  );

  const onCloseSubMenuModal = () => {
    setOpenSubMenu(false);
    setBlockGroup(null);
  };

  const handleSelectBlockType = useCallback(
    (type: string) => () => {
      setBlockType(type);
      setOpenSubMenu(false);
      setBlockId('');
      newMenuBlock.menuId = menuId;
      newMenuBlock.group = blockGroup;
      newMenuBlock.type = type;
      setMenuBlock(newMenuBlock);
      formBlock.resetFields();
      switch (type) {
        case BlockType.logo:
        case BlockType.banner:
          setMenuLinks([{ id: uuid(), type: 'links', attributes: { ...newMenuLink, menuId } }]);
          break;
        default:
          break;
      }
    },
    [menuId, blockGroup],
  );

  const handleCloseBlock = () => {
    newMenuBlock.menuId = null;
    newMenuBlock.group = null;
    newMenuBlock.type = '';
    setMenuBlock(newMenuBlock);
    setBlockType('');
    setPreviewImage(null);
    setFileImage(null);
    setMenuLinks([]);
    setMenuLink(null);
  };

  const onDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const onDragStart = useCallback(
    (id) => () => {
      setDropBlockId(id);
    },
    [dropBlockId],
  );

  const onDrop = useCallback(
    (newCol: number) => async () => {
      const block = blocks.find((dragBlock) => dragBlock.id === dropBlockId);
      const blockIndex = blocks.indexOf(block);
      block.attributes.group = newCol;
      try {
        const dropBlock = new MenuBlockModel(block.attributes);
        await api.put({
          endpoint: API_UPDATE_DELETE_MENU_BLOCK.replace('{id}', block.id),
          data: dropBlock,
        });
        const clonedSubMenu = clone(blocks);
        clonedSubMenu[blockIndex] = block;
        setBlocks(clonedSubMenu);
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [blocks, dropBlockId],
  );

  const handleChangeValuesLink = useCallback(
    (field, values) => {
      dispatch(setIsEditing(true));
      const { icon } = values;
      const fieldKey = Object.keys(field);
      const currentLink = new MenuLinkModel({ ...menuLink.attributes, ...values });
      currentLink.menuId = menuId;
      if (icon) {
        if (icon === 'hot') {
          currentLink.isHot = true;
          currentLink.isNew = false;
        } else {
          currentLink.isHot = false;
          currentLink.isNew = true;
        }
      } else {
        currentLink.isHot = false;
        currentLink.isNew = false;
      }
      switch (fieldKey[0]) {
        case 'title':
          currentLink.url = generateSlug(currentLink.title.trim());
          break;
        default:
          break;
      }
      formLink.setFieldsValue(currentLink);
      setMenuLink({ ...menuLink, attributes: currentLink });
    },
    [menuLink],
  );

  const handleEditLink = useCallback(
    (row: IMenuLink) => () => {
      dispatch(setIsEditing(true));
      setOpenMenuLinkModal(true);
      setMenuLink(row);
      const currentLink = new MenuLinkModel(row.attributes);

      if (currentLink.isHot) {
        formLink.setFieldsValue({ ...currentLink, icon: 'hot' });
      } else if (currentLink.isNew) {
        formLink.setFieldsValue({ ...currentLink, icon: 'new' });
      } else {
        formLink.setFieldsValue({ ...currentLink, icon: '' });
      }
      formLink.setFieldsValue(currentLink);
    },
    [],
  );

  const handleSaveMenuLink = useCallback(() => {
    const clonedMenuLinks = clone(menuLinks);
    const menuLinkIndex = clonedMenuLinks.findIndex((item) => item.id === menuLink.id);
    if (menuLinkIndex > -1) {
      clonedMenuLinks.splice(menuLinkIndex, 1, menuLink);
    } else {
      clonedMenuLinks.push(menuLink);
    }
    setMenuLinks(clonedMenuLinks);
    setOpenMenuLinkModal(false);
  }, [menuLink, menuLinks]);

  const handleValuesBlockChange = useCallback(
    (field, values) => {
      dispatch(setIsEditing(true));
      const { icon } = values;
      const fieldKey = Object.keys(field);
      const currentBlock = new MenuBlockModel({ ...menuBlock, ...values });
      if (icon) {
        if (icon === 'hot') {
          currentBlock.isHot = true;
          currentBlock.isNew = false;
        } else {
          currentBlock.isHot = false;
          currentBlock.isNew = true;
        }
      } else {
        currentBlock.isHot = false;
        currentBlock.isNew = false;
      }
      switch (fieldKey[0]) {
        case 'title':
          currentBlock.url = generateSlug(currentBlock.title.trim());
          break;
        default:
          break;
      }
      setMenuBlock(currentBlock);
      formBlock.setFieldsValue(currentBlock);
    },
    [menuBlock],
  );

  const handleEditBlock = useCallback(
    (block: IMenuBlock) => async () => {
      const currentBlock = new MenuBlockModel(block.attributes);
      setBlockId(block.id);
      setMenuBlock(currentBlock);

      if (currentBlock.isHot) {
        formBlock.setFieldsValue({ ...currentBlock, icon: 'hot' });
      } else if (currentBlock.isNew) {
        formBlock.setFieldsValue({ ...currentBlock, icon: 'new' });
      } else {
        formBlock.setFieldsValue({ ...currentBlock, icon: '' });
      }
      await api.get({ endpoint: API_GET_LINKS_IN_BLOCK.replace('{id}', block.id) }).then((res) => {
        if (!isEmpty(res?.data)) {
          setMenuLinks(res?.data);
          if (block.attributes.type !== BlockType.list) {
            setPreviewImage(res?.data[0]?.attributes?.image);
          }
        }
      });
      setBlockType(block.attributes.type);
    },
    [menuId],
  );

  const handleDeleteBlock = useCallback(
    (id: string) => () => {
      deleteModal({
        title: 'Bạn có muốn xóa block này?',
        onDeleteCb: async () => {
          try {
            await api.remove({ endpoint: API_UPDATE_DELETE_MENU_BLOCK.replace('{id}', id) });
            dispatch(setIsEditing(false));
            setReloadBlocks(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const handleSaveBlock = useCallback(async () => {
    try {
      if (blockId) {
        await api.put({
          endpoint: API_UPDATE_DELETE_MENU_BLOCK.replace('{id}', blockId),
          data: menuBlock,
        });
        if (!isEmpty(menuLinks)) {
          menuLinks.forEach(async (item) => {
            try {
              const currentLink = new MenuLinkModel(item.attributes);
              if (!isNaN(filterInt(item.id))) {
                await api.put({
                  endpoint: API_UPDATE_DELETE_MENU_LINK.replace('{id}', item.id),
                  data: { ...currentLink, blockId: parseInt(blockId) },
                });
                blockType !== BlockType.list &&
                  uploadFile(fileImage, 'menulink', item.id).then((attr) => {
                    setPreviewImage({ ...attr?.resize, id: attr?.id });
                  });
              } else {
                await api
                  .post({
                    endpoint: API_POST_MENU_LINK,
                    data: { ...currentLink, blockId: parseInt(blockId) },
                  })
                  .then((res) => {
                    blockType !== BlockType.list &&
                      uploadFile(fileImage, 'menulink', res?.data.id).then((attr) => {
                        setPreviewImage({ ...attr?.resize, id: attr?.id });
                      });
                  });
              }
              dispatch(setIsEditing(false));
              setReloadBlocks(true);
              setBlockType('');
              setMenuLinks([]);
            } catch (err) {
              showErrorMsg(err);
            }
          });
        } else {
          dispatch(setIsEditing(false));
          setReloadBlocks(true);
          setBlockType('');
          setMenuLinks([]);
        }
      } else {
        const block = await api.post({ endpoint: API_POST_MENU_BLOCKS, data: menuBlock });
        if (!isEmpty(menuLinks)) {
          menuLinks.forEach(async (item) => {
            try {
              const currentLink = new MenuLinkModel(item.attributes);
              await api
                .post({
                  endpoint: API_POST_MENU_LINK,
                  data: { ...currentLink, blockId: parseInt(block?.data.id) },
                })
                .then((res) => {
                  blockType !== BlockType.list &&
                    uploadFile(fileImage, 'menulink', res?.data.id).then((attr) => {
                      setPreviewImage({ ...attr?.resize, id: attr?.id });
                    });
                });
              dispatch(setIsEditing(false));
              setBlockType('');
              setReloadBlocks(true);
              setMenuLinks([]);
            } catch (err) {
              showErrorMsg(err);
            }
          });
        } else {
          dispatch(setIsEditing(false));
          setReloadBlocks(true);
          setBlockType('');
          setMenuLinks([]);
        }
      }
    } catch (err) {
      showErrorMsg(err);
    }
  }, [menuBlock, menuLinks, blockId, fileImage, blockType]);

  const handleDeleteLink = useCallback(
    (id: string) => async () => {
      setLoadingTable(true);
      try {
        const clonedLinks = clone(menuLinks);
        const index = clonedLinks.findIndex((item) => item.id === id);
        if (index > -1) {
          clonedLinks.splice(index, 1);
        }
        if (!isNaN(filterInt(id))) {
          await api.remove({ endpoint: API_UPDATE_DELETE_MENU_LINK.replace('{id}', id) });
        }
        setMenuLinks(clonedLinks);
        setLoadingTable(false);
      } catch (err) {
        setLoadingTable(false);
      }
    },
    [menuLinks],
  );

  const handleChangeSingLink = useCallback(
    (inputKey: string) => (e) => {
      dispatch(setIsEditing(true));
      const currentMenuLink = new MenuLinkModel(menuLinks[0].attributes);
      switch (inputKey) {
        case 'title':
          currentMenuLink.title = e.target.value;
          currentMenuLink.url = generateSlug(e.target.value);
          break;
        case 'url':
          currentMenuLink.url = e.target.value;
          break;
        case 'brandId':
          currentMenuLink.brandId = e;
          break;
        case 'active':
          currentMenuLink.active = e;
          break;
        case 'params':
          currentMenuLink.params = e;
          break;
        default:
          break;
      }
      setMenuLinks([{ ...menuLinks[0], attributes: currentMenuLink }]);
    },
    [menuLinks],
  );

  const handleSetFileImage = useCallback((file: RcFile) => {
    dispatch(setIsEditing(true));
    setFileImage(file);
  }, []);

  const handleDeleteImage = useCallback(() => {
    setFileImage(null);
    setPreviewImage(null);
  }, []);

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tiêu đề Link',
      dataIndex: 'attributes',
      render: (attr: IAttributeMenuLink, row: IMenuLink) => (
        <div className="menu-link" onClick={handleEditLink(row)}>
          {attr.title}
        </div>
      ),
    },
    {
      title: 'Link',
      dataIndex: 'attributes',
      render: (attr: IAttributeMenuLink, row: IMenuLink) => (
        <div className="menu-link" onClick={handleEditLink(row)}>
          {attr.url}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: '',
      align: AlignType.right,
      render: (_, row: IMenuLink) => (
        <Button title="Xóa link" icon={<DeleteOutlined />} onClick={handleDeleteLink(row.id)} />
      ),
    },
  ];

  const renderMenuBlockContents = () => {
    switch (blockType) {
      case BlockType.list:
        return (
          <>
            <Button className="mb-3" onClick={handleOpenMenuLinkModal}>
              Thêm link
            </Button>
            <Table
              columns={columns}
              dataSource={sortBy(menuLinks, 'attributes.position')}
              rowKey="id"
              loading={loadingTable}
              pagination={false}
            />
          </>
        );
      case BlockType.logo:
      case BlockType.banner:
        return menuLinks.map((item) => (
          <Row gutter={24} key={item.id}>
            <Col span={24}>
              <h4>{blockType === BlockType.logo ? 'Link thương hiệu' : 'Link banner'}</h4>
            </Col>
            <Col span={8}>
              <Form.Item label="Tiêu đề link">
                <Input value={item.attributes.title} onChange={handleChangeSingLink('title')} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Link">
                <Input value={item.attributes.url} onChange={handleChangeSingLink('url')} />
              </Form.Item>
            </Col>
            {blockType === BlockType.logo && (
              <Col span={8}>
                <Form.Item label="Chọn brand">
                  <Select
                    value={item.attributes.brandId}
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    size="large"
                    onChange={handleChangeSingLink('brandId')}
                  >
                    {brands.map((brand) => (
                      <Select.Option key={brand.id} value={parseInt(brand.id)}>
                        {brand.attributes.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item label="Hiển thị" valuePropName="checked">
                <Switch
                  checked={item.attributes.active}
                  onChange={handleChangeSingLink('active')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Params">
                <CustomTag
                  buttonTitle="Thêm param"
                  tags={item.attributes.params}
                  handleUpdateTags={handleChangeSingLink('params')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <UploadFile
                handleUploadImage={handleSetFileImage}
                previewImage={previewImage}
                handleDeleteImageSuccess={handleDeleteImage}
              />
            </Col>
          </Row>
        ));
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="sub-menu">
        {subMenuCol.map((i) => (
          <Card
            key={i}
            className="subMenuCol"
            id={`col1${i}`}
            onDragOver={onDragOver}
            onDrop={onDrop(i)}
          >
            {blocks
              .filter((item) => item.attributes.group === i)
              .map((block) => {
                switch (block.attributes.type) {
                  case BlockType.list:
                    return (
                      <div key={block.id} className="block-wrapper">
                        <div className="delete-icon" onClick={handleDeleteBlock(block.id)}>
                          <CloseCircleFilled />
                        </div>
                        <Card
                          draggable
                          onDragStart={onDragStart(block.id)}
                          className="cursor-pointer"
                          onClick={handleEditBlock(block)}
                        >
                          <strong>{block.attributes.title}</strong>
                          <ul>
                            {block?.links?.map((link) => {
                              return <li key={link.id}>{link.attributes.title}</li>;
                            })}
                          </ul>
                        </Card>
                      </div>
                    );
                  case BlockType.logo:
                  case BlockType.banner:
                    return (
                      <div key={block.id} className="block-wrapper">
                        <div className="delete-icon" onClick={handleDeleteBlock(block.id)}>
                          <CloseCircleFilled />
                        </div>
                        <Card
                          className="cursor-pointer"
                          draggable
                          onDragStart={onDragStart(block.id)}
                          onClick={handleEditBlock(block)}
                        >
                          <div>
                            <strong>{block.attributes.title}</strong>
                          </div>
                          <div
                            className={`${
                              block.attributes.type === BlockType.logo ? 'logo-image' : ''
                            } mt-2`}
                          >
                            <img
                              src={block.links?.[0]?.attributes?.image?.['128x128']}
                              alt={block.links?.[0]?.attributes?.image?.title}
                            />
                          </div>
                        </Card>
                      </div>
                    );
                  default:
                    return <></>;
                }
              })}
            <Button type="primary" onClick={onOpenSubMenuModal(i)}>
              Thêm menu block
            </Button>
          </Card>
        ))}
      </div>
      <CustomModal
        title="Thêm link"
        isOpen={openMenuLinkModal}
        onCloseModal={handleCloseMenuLinkModal}
        onSave={handleSaveMenuLink}
      >
        <Form layout="vertical" form={formLink} onValuesChange={handleChangeValuesLink}>
          <Form.Item label="Tiêu đề link mới" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Link mới" name="url">
            <Input />
          </Form.Item>
          <Form.Item label="Vị trí" name="position">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Chọn 1 trong 2 ICON" name="icon" initialValue="">
            <Radio.Group>
              <Radio key="icon-fire" value="hot">
                <strong>ICON FIRE</strong>
              </Radio>
              <Radio key="icon-new" value="new">
                <strong>ICON NEW</strong>
              </Radio>
              <Radio key="none" value="">
                <strong>NONE</strong>
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Hiển thị" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </CustomModal>
      <CustomPopup
        title="Sub Menu: Level 2"
        isOpen={openSubMenu}
        onCloseModal={onCloseSubMenuModal}
        handleSaveButton={null}
        isShowSaveButton={false}
      >
        <div style={{ textAlign: AlignType.center }}>
          <h5>Chọn dạng block muốn hiển thị!</h5>
        </div>
        <Row gutter={48} justify="space-around">
          <Col span={6}>
            <Card className="subMenuTypeSelect" onClick={handleSelectBlockType(BlockType.list)}>
              <strong>Block Title</strong>
              <ul>
                <li>Samsung</li>
                <li>Apple</li>
                <li>Oppo</li>
                <li>VSmart</li>
                <li>Vivo</li>
              </ul>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="subMenuTypeSelect" onClick={handleSelectBlockType(BlockType.logo)}>
              <h4>LOGO</h4>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="subMenuTypeSelect" onClick={handleSelectBlockType(BlockType.banner)}>
              <h4>BANNER</h4>
            </Card>
          </Col>
        </Row>
      </CustomPopup>
      <CustomPopup
        title={`Block ${blockType}`}
        isOpen={!!blockType}
        formHook={formBlock}
        onCloseModal={handleCloseBlock}
        handleValuesChange={handleValuesBlockChange}
        handleSaveNoEnter={handleSaveBlock}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Tiêu đề block" name="title">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Link" name="url">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Vị trí" name="position">
              <Input type="number" />
            </Form.Item>
          </Col>
          {blockType === BlockType.list && (
            <Col span={8}>
              <Form.Item label="Chọn 1 trong 2 ICON" name="icon" initialValue="">
                <Radio.Group>
                  <Radio key="icon-fire" value="hot">
                    <strong>ICON FIRE</strong>
                  </Radio>
                  <Radio key="icon-new" value="new">
                    <strong>ICON NEW</strong>
                  </Radio>
                  <Radio key="none" value="">
                    <strong>NONE</strong>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item label="Hiển thị" name="active" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        {renderMenuBlockContents()}
      </CustomPopup>
    </>
  );
};
