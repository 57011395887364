import { Col, Form, Row } from 'antd';
import React from 'react';

// Interface
import { IHomeBlock } from 'interfaces/home-block.interface';

// Components
import { RiderTemplateBlock } from 'ui/components/MaiNguyen/RiderTemplateBlock';

interface FormSinglePageProps {
  block?: IHomeBlock;
  handleUploadFileContent?: (file) => void;
  handleDeleteImage?: () => void;
}

const FormSinglePage: React.FC<FormSinglePageProps> = (props) => {
  const { block, handleUploadFileContent, handleDeleteImage } = props;

  const { attributes } = block || {};
  const { name, position, template } = attributes || {};

  return (
    <Row gutter={16}>
      {/* Default Information  */}
      <Col span={8}>
        <Form.Item label="Name">
          <div>{name}</div>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Position">
          <div>{position}</div>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Template">
          <div>{template}</div>
        </Form.Item>
      </Col>
      {/* Form Input */}
      <Col span={24}>
        <RiderTemplateBlock
          block={block}
          template={template}
          onAddImage={handleUploadFileContent}
          onDeleteImage={handleDeleteImage}
        />
      </Col>
    </Row>
  );
};

export default FormSinglePage;
