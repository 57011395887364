import { Card, Col, Row } from 'antd';
import { Order } from 'interfaces/order.interface';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import queryString from 'query-string';
import { useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { formatPrice } from 'utils/productHelpers';
import { genPaymentStatus, genStatusOrder } from 'utils/utils';
import './index.styles.scss';

interface OrderItemProps {
  data: Order;
}

const OrderItem: React.FC<OrderItemProps> = (props) => {
  const { id: memberId } = useParams<{ id: string }>();
  const params = queryString.parse(location.search) as any;

  const { data } = props;

  const { paymentMethod: _paymentMethod, total, createdAt, status, items } = data?.attributes || {};

  const paymentMethod = useMemo(() => {
    return genPaymentStatus(_paymentMethod);
  }, [_paymentMethod]);

  const orderDate = useMemo(() => {
    return moment(createdAt).format('DD/MM/YYYY HH:mm');
  }, [createdAt]);

  // TODO: Find order has coupon
  const counpon = useMemo(() => {
    return items?.find((item) => item?.couponCode.length > 0);
  }, items);

  return (
    <Card className="order-item">
      <NavLink
        to={{
          pathname: `/danh-sach-members/${memberId}/orders/${data?.id}`,
          search: queryString.stringify({ ...params }),
        }}
      >
        <Row className="mb-2">
          <Col span={12}>
            <span className="title">Đơn hàng ngày {orderDate}</span>
          </Col>
          <Col className="text-right" span={12}>
            {status && status !== 'string' && (
              <span className={`status ${genStatusOrder(status, 'value')}`}>
                {genStatusOrder(status)}
              </span>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col span={24} className="order-number">
            Mã đơn: {data?.id}
          </Col>
        </Row>
        <Row>
          <Col span={12}>Hình thức thanh toán: {paymentMethod || '-'}</Col>
          <Col className="text-right" span={12}>
            Tổng số tiền: {formatPrice(total?.toString())}
            {!isEmpty(counpon) && <span className="ml-2 has-coupon">(Có COUPON)</span>}
          </Col>
        </Row>
      </NavLink>
    </Card>
  );
};

export default OrderItem;
