/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useFetchArticles } from 'hooks/useFetchArticles';
import { IArticle } from 'interfaces/article.interface';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsEditing } from 'redux/uiAction/actions';
import { SelectedNewsModal } from './SelectedNewsModal';
import './styles.scss';
import { NewsTable } from '../NewsTable';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  initialArticleIds: string[];
  onChange: (articleIds: string[]) => void;
}

export const AddNewsModal: React.FC<Props> = (props) => {
  const { isOpen, initialArticleIds, onClose, onChange } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { isFetching, data: articles, refetch, reset: resetArticles } = useFetchArticles();

  const [selectedArticles, setSelectedArticles] = useState<IArticle[]>([]);
  const [openSelectedNewsModal, setOpenSelectedNewsModal] = useState<boolean>(false);

  const selectedArticleIds = selectedArticles?.map((p) => p.id);
  const combineArticleIds = [...selectedArticleIds, ...initialArticleIds];

  /**
   * A callback function that performs a search for articles based on the form values.
   */
  const onSearch = useCallback(async (): Promise<void> => {
    const values = form.getFieldsValue();

    await refetch({
      perPage: 20,
      ...queryString.parse(
        queryString.stringify(
          {
            ...values,
          },
          { skipNull: true, skipEmptyString: true },
        ),
      ),
    });
  }, [combineArticleIds]);

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === 'Enter') {
        await onSearch();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onSearch]);

  /**
   * An effect that resets the form fields, articles, and selected arcticle IDs when the modal is closed.
   */
  useEffect((): void => {
    if (!isOpen) {
      form.resetFields();
      resetArticles();
      setSelectedArticles([]);
    }
  }, [isOpen]);

  /**
   * A callback function that selects articles based on the provided article IDs.
   *
   * @param {React.Key[]} articleIds - An array of article IDs to select.
   */
  const onSelectArticles = useCallback((_, _articles: IArticle[]) => {
    setSelectedArticles(_articles);
    dispatch(setIsEditing(true));
  }, []);

  return (
    <Modal
      footer={null}
      visible={isOpen}
      title="Thêm bài viết"
      onCancel={onClose}
      centered
      width={1280}
      className="base-modal"
    >
      <div className="add-news-modal__content">
        <Form form={form} layout="vertical">
          <Row gutter={24}>
            <Col span={20}>
              <Form.Item label="Tiêu đề bài viết" name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label=" " className="form-item__search">
                <Button className="search-btn" onClick={onSearch}>
                  Tìm
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {!!selectedArticleIds?.length && (
          <div className="selected-news-label" onClick={() => setOpenSelectedNewsModal(true)}>
            Xem danh sách đã chọn ({selectedArticleIds.length})
          </div>
        )}

        <NewsTable
          showHeader={false}
          loading={isFetching}
          data={articles}
          rowSelection={{
            selectedRowKeys: selectedArticleIds,
            onChange: onSelectArticles,
          }}
          scroll={{ y: 400 }}
          pagination={false}
        />

        {!!selectedArticles?.length && (
          <div className="save-button__wrapper">
            <Button type="primary" onClick={() => onChange(combineArticleIds)}>
              Lưu
            </Button>
          </div>
        )}

        <SelectedNewsModal
          visible={openSelectedNewsModal}
          onClose={() => setOpenSelectedNewsModal(false)}
          data={selectedArticles}
          onRemove={(articleId) =>
            setSelectedArticles((prev) => prev.filter((p) => p.id !== articleId))
          }
        />
      </div>
    </Modal>
  );
};
