import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { ICoupon, ICouponAttributes } from 'interfaces/coupon.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import moment from 'moment';
import { useUpdateCoupon } from 'pages/CouponDetail/hooks';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';

import './Coupons.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { confirmModal, deleteModal } from 'utils/modals';
import { formatNumber } from 'utils/utils';
import { COUPON_STATUS_LIST } from 'constants/constants';
import { AlignType, CouponStatus, DateFormat, DiscountUnit } from 'constants/enum';
import { CreateCouponModal } from './components/CreateCouponModal';
import { FilterCoupons, FilterView } from './components/FilterView';
import { StatusFilter } from './components/StatusFilter';
import { useFetchCoupons, useRemoveCoupons } from './hooks';

const Coupons: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    isFetching,
    data,
    pagination,
    setPagination,
    refetch: refetchCoupons,
  } = useFetchCoupons();
  const { onRemove } = useRemoveCoupons();
  const { onUpdate } = useUpdateCoupon();

  const [isOpenCreateCouponModal, setIsOpenCreateCouponModal] = useState<boolean>(false);

  /**
   * A callback function that handles page changes in pagination.
   *
   * @param {number} page - The new page number.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await refetchCoupons({ page });
    },
    [refetchCoupons],
  );

  /**
   * A callback function that handles filter changes for coupons.
   *
   * @param {FilterCoupons} filter - The new filter values.
   */
  const onFilterChange = useCallback(
    async (filter: FilterCoupons): Promise<void> => {
      await refetchCoupons({ page: 1, ...filter });
    },
    [refetchCoupons],
  );

  /**
   * A callback function that handles filter changes for coupons.
   *
   * @param {FilterCoupons} filter - The new filter values.
   */
  const onStatusFilterChange = useCallback(
    async (status: CouponStatus): Promise<void> => {
      await refetchCoupons({ page: 1, status });
    },
    [refetchCoupons],
  );

  /**
   * A callback function that handles the removal of a coupon.
   *
   * @param {string} id - The ID of the coupon to remove.
   */
  const onRemoveCoupon = useCallback(
    (id: string): void => {
      deleteModal({
        title: 'Bạn có muốn xóa coupon này?',
        onDeleteCb: async () => {
          try {
            await onRemove({ method: 'remove', id });
            await refetchCoupons();
            message.success('Xoá coupon thành công');
          } catch (error: any) {
            message.error(error?.message || error);
          }
        },
      });
    },
    [refetchCoupons],
  );

  /**
   * A callback function that handles the update of a coupon.
   *
   * @param {string} id - The ID of the coupon to update.
   */
  const onUpdateCouponStatus = useCallback(
    async (
      id: string,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      data: { status: CouponStatus; startDate?: any; endDate?: any },
    ): Promise<void> => {
      try {
        await onUpdate({ method: 'put', id, data });
        message.success('Đã cập nhật coupon thành công');
        await refetchCoupons();
      } catch (error: any) {
        message.error(error?.message || error);
      }
    },
    [refetchCoupons, onUpdate],
  );

  const columns = [
    {
      title: 'Thông tin',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => (
        <div
          className="coupon-cell__info"
          // onClick={() => {
          //   history.push(`/ma-giam-gia/${row.id}`, queryString.parse(location.search));
          // }}
        >
          <div className="coupon-info__title">{o?.title || '-'}</div>
          <div className="coupon-info__desc">{o?.displayTitle || '-'}</div>
          <div className="coupon-info__prefix">{o?.prefixCode || '-'}</div>
        </div>
      ),
    },
    {
      title: 'Số mã',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        const totalCouponAvailable = o?.numberOfCoupon - o?.countActive;
        return (
          <div className="coupon-cell__total">
            <div className="coupon-available">{totalCouponAvailable}</div>
            <div className="coupon-total">{o?.numberOfCoupon}</div>
          </div>
        );
      },
    },
    {
      title: 'Discount',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        return (
          <div className="coupon-cell__discount">
            <div className="coupon-discount">
              {' '}
              {parseFloat(o?.discount as any) > 0
                ? `${
                    o?.discountUnit === DiscountUnit.VND
                      ? formatNumber(o?.discount)
                      : `${o?.discount}%`
                  }`
                : '-'}
            </div>
            <div className="coupon-discount-auto">{o?.rule}</div>
          </div>
        );
      },
    },
    {
      title: 'Ngày hiệu lực',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        return (
          <div className="coupon-cell__date">
            <div className="coupon__start-date">
              {o?.startDate
                ? moment(o?.startDate, DateFormat.serverSideWithTime).format(
                    DateFormat.clientSideWithTime,
                  )
                : '-'}
            </div>
            <div className="coupon__end-date">
              {' '}
              {o?.endDate
                ? moment(o?.endDate, DateFormat.serverSideWithTime).format(
                    DateFormat.clientSideWithTime,
                  )
                : '-'}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Tình trạng',
      dataIndex: 'attributes',
      render: (o: ICouponAttributes) => {
        const isCouponExpired = o?.endDate
          ? moment(o.endDate, DateFormat.serverSideWithTime).isBefore(moment())
          : false;
        const statusLabel = COUPON_STATUS_LIST.find(
          (c) => c.value === (isCouponExpired ? CouponStatus.expired : o?.status),
        )?.label;
        const prefix = isCouponExpired ? CouponStatus.expired : o?.status;
        return <div className={`coupon-status--${prefix}`}>{statusLabel}</div>;
      },
    },
    {
      title: 'Thao tác',
      align: AlignType.right,
      dataIndex: 'attributes',
      render: (o: ICouponAttributes, row: ICoupon) => {
        const isCouponExpired = o?.endDate
          ? moment(o.endDate, DateFormat.serverSideWithTime).isBefore(moment())
          : false;

        // return (
        //   <Button
        //     onClick={() => onRemoveCoupon(row.id)}
        //     title="Xóa"
        //     icon={<CloseCircleOutlined />}
        //   />
        // );

        if (isCouponExpired) {
          return (
            <Button
              onClick={() => {
                confirmModal({
                  title: 'Bạn có muốn đóng coupon này?',
                  buttonText: 'Có',
                  onSaveCb: async () =>
                    onUpdateCouponStatus(row.id, { status: CouponStatus.close }),
                });
              }}
              title="Đóng chương trình"
              icon={<CloseCircleOutlined />}
            />
          );
        }

        if (o?.status === CouponStatus.draft && !isCouponExpired) {
          return (
            <Button
              onClick={() =>
                history.push(`/ma-giam-gia/${row.id}`, queryString.parse(location.search))
              }
              title="Chỉnh sửa"
              icon={<EditOutlined />}
            />
          );
        }

        if (o?.status === CouponStatus.active && !isCouponExpired) {
          return (
            <div>
              <Button
                onClick={() =>
                  history.push(`/ma-giam-gia/${row.id}`, queryString.parse(location.search))
                }
                title="Chỉnh sửa"
                icon={<EditOutlined />}
                style={{ marginRight: 12 }}
              />
              <Button
                onClick={() => {
                  confirmModal({
                    title: 'Bạn có muốn đóng coupon này?',
                    buttonText: 'Có',
                    onSaveCb: async () =>
                      onUpdateCouponStatus(row.id, { status: CouponStatus.close }),
                  });
                }}
                title="Đóng chương trình"
                icon={<CloseCircleOutlined />}
              />
            </div>
          );
        }

        return null;
      },
    },
  ];

  return (
    <div className="coupons-page">
      <LayoutList
        pageTitle="Mã giảm giá - Coupons"
        breadcrumbs={[
          {
            title: 'Mã giảm giá - Coupons',
            route: '/ma-giam-gia',
          },
        ]}
        showAddButton
        handleClickAddButton={() => setIsOpenCreateCouponModal(true)}
        loadingList={isFetching}
        columns={columns}
        dataList={data}
        tableClass="coupons-table"
        pagination={pagination}
        handleChangePage={onPageChange}
        topPagination
        filterNode={<FilterView onFilter={onFilterChange} />}
        rightToolbarComponent={<StatusFilter onChange={onStatusFilterChange} />}
      />
      <CreateCouponModal
        isOpen={isOpenCreateCouponModal}
        onClose={() => setIsOpenCreateCouponModal(false)}
      />
    </div>
  );
};

export default Coupons;
