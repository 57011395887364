import { StoreIds } from 'constants/enum';
import { IAttributeMenu } from 'interfaces/product-menu.interface';

export class MenuModel implements IAttributeMenu {
  active?: boolean;

  isHot?: boolean;

  isNew?: boolean;

  storeId?: number;

  position?: number | null;

  title?: string;

  url?: string;

  constructor({ active, isHot, isNew, storeId, position, title, url }: IAttributeMenu) {
    this.title = title || '';
    this.isHot = isHot !== undefined && isHot !== null ? isHot : false;
    this.isNew = isNew !== undefined && isNew !== null ? isNew : false;
    this.storeId = storeId || StoreIds.mainguyen;
    this.active = active !== undefined && active !== null ? active : false;
    this.url = url || '';
    this.position = position;
  }
}
