import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import React from 'react';
import { Size } from 'constants/enum';

interface ButtonInterface extends ButtonProps {
  buttonSize?: Size;
}

const ThemeButton: React.FC<ButtonInterface> = ({
  buttonSize = Size.medium,
  children,
  loading,
  ...rest
}) => {
  return (
    <Button className={`theme-button ${buttonSize}`} {...rest} loading={loading}>
      {children}
    </Button>
  );
};

export default ThemeButton;
