import { Row, Col, Form } from 'antd';

import { usePresentBlocks } from 'hooks/usePresentBlocks';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { TemplateBlock } from 'ui/components/MaiNguyen/TemplateBlock';

import './ProductDetailPresent.scss';

const ProductDetailPresent = () => {
  const {
    reloadList,
    block,
    isOpen,
    handleClosePopup,
    handleOpenModalBlock,
    handleUploadFileContent,
    handleChangeValues,
    handleSaveBlock,
    handleDeleteImage,
    handleChangeJsonData,
  } = usePresentBlocks();

  const [form] = Form.useForm();

  return (
    <>
      <LayoutDetail
        pageTitle="Trình bày trang Chi tiết sản phẩm"
        breadcrumbs={[
          {
            title: 'Trình bày trang Chi tiết sản phẩm',
            route: '/trinh-bay-trang-chi-tiet-san-pham',
          },
        ]}
        isAddNew
        wrapperCard={false}
        handleValuesChange={null}
        formHook={null}
        pageClassName="product-detail-present"
        saveLoading={reloadList}
      >
        <Row gutter={16}>
          <Col span={14}>
            <div className="block-item h-100">Chi tiết sản phẩm</div>
          </Col>
          <Col span={10}>
            <Row gutter={16}>
              <Col span={24}>
                <div
                  className="good-price block-item"
                  onClick={handleOpenModalBlock('productDetailGoodPrice', 'logo')}
                >{`"Giá tốt" trong khung giá`}</div>
              </Col>
              <Col span={24}>
                <div
                  className="info-block block-item"
                  onClick={handleOpenModalBlock(
                    'productDetailSupportInfo',
                    'productDetailSupportInfo',
                  )}
                >
                  Thông tin hỗ trợ
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </LayoutDetail>
      <CustomPopup
        title="Display Block"
        isOpen={isOpen}
        onCloseModal={handleClosePopup}
        handleSaveButton={handleSaveBlock}
        formHook={form}
        handleValuesChange={handleChangeValues}
      >
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name">
                <div>{block?.attributes?.name}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Position">
                <div>{block?.attributes?.position}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Template">
                <div>{block?.attributes?.template}</div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <TemplateBlock
                block={block}
                template={block?.attributes?.template}
                onAddImage={handleUploadFileContent}
                onDeleteImage={handleDeleteImage}
                onChangeJsonData={handleChangeJsonData}
              />
            </Col>
          </Row>
        </>
      </CustomPopup>
    </>
  );
};

export default ProductDetailPresent;
