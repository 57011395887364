import { Button } from 'antd';
import { FC } from 'react';

import ThemeButton from 'ui/components/ThemeButton/ThemeButton';

interface CommentorGroupProps {
  name: string;
  email: string;
  showAnwserBtn?: boolean;
  isAdmin?: boolean;
  onClickOpenModal?: () => void;
  onApprove?: () => void;
  onDenied?: () => void;
  onDelete?: () => void;
  isShowApprovedBtn?: boolean;
  isShowDeniedBtn?: boolean;
}

export const CommentorGroup: FC<CommentorGroupProps> = ({
  name,
  email,
  showAnwserBtn = false,
  isAdmin = false,
  onClickOpenModal = null,
  onApprove = null,
  onDenied = null,
  onDelete = null,
  isShowApprovedBtn = true,
  isShowDeniedBtn = true,
}) => (
  <div className="commentor">
    {!isAdmin && (
      <>
        <div className="commentor-name">{name}</div>
        <div className="commentor-email">{email}</div>
      </>
    )}
    <div className="btn-group">
      {!isAdmin && (
        <>
          {showAnwserBtn && <Button onClick={onClickOpenModal}>Trả lời</Button>}
          {isShowApprovedBtn && <ThemeButton onClick={onApprove}>Duyệt</ThemeButton>}
          {isShowDeniedBtn && (
            <Button type="primary" className="denie-btn" onClick={onDenied}>
              Từ chối
            </Button>
          )}
        </>
      )}
      <Button type="primary" className="denie-btn" onClick={onDelete}>
        Xóa
      </Button>
    </div>
  </div>
);
