import { Divider } from 'antd';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import OrderHeaderInfo from 'pages/Members/Details/Order/components/HeaderInfo';
import OrderDetail from 'pages/Members/Details/Order/components/OrderDetail';
import '../index.styles.scss';

const OrderDetailPage: React.FC = () => {
  return (
    <LayoutDetail
      pageTitle="Thông tin đơn hàng"
      breadcrumbs={[
        {
          title: 'Danh sách đơn hàng',
          route: '/danh-sach-don-hang',
        },
      ]}
      pageClassName="member-order order-detail-page"
      isAddNew={true}
      formHook={null}
      handleValuesChange={null}
      handleSave={null}
      handleCancel={null}
      handleClickAddButton={null}
      handleDelete={null}
      wrapperCard={false}
    >
      <div className="member-content">
        <OrderHeaderInfo isOrderDetailPage={true} />

        <Divider />

        <OrderDetail />
      </div>
    </LayoutDetail>
  );
};

export default OrderDetailPage;
