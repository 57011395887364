/* eslint-disable import/order */
import { RcFile } from 'antd/lib/upload';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Interface
import { IBrand } from 'interfaces/brand.interface';
import { IImage } from 'interfaces/image.interface';
import { IPagination } from 'interfaces/pagination.interface';

// Constant
import { API_BRAND, API_GET_BRANDS } from 'constants/api/brand.api';
import { defaultPagination } from 'constants/constants';
import { BrandImageType } from 'constants/enum';
import { BrandModel } from 'models/brand.model';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { deleteModal } from 'utils/modals';
import { showErrorMsg, generateSlug, uploadFile } from 'utils/utils';
import { message } from 'antd';

// Hook: get list of brands
export const useGetBrands = (currentPage?: number, searchValue?: string) => {
  const [brands, setBrands] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  // get list brands
  const getBrands = async () => {
    try {
      setReloadList(true);

      const filterByName = searchValue ? `&name=${searchValue}` : '';
      const resBrands = await api.get({
        endpoint: `${API_GET_BRANDS}?page=${currentPage}${filterByName}`,
      });

      const { data, meta } = resBrands;
      const { pagination: pagin } = meta;
      const { currentPage: curPage, perPage, total } = pagin || {};

      setBrands(data);
      setPagination({
        current: curPage,
        pageSize: perPage,
        total: total,
      });
      setReloadList(false);
    } catch (error) {
      showErrorMsg(error);
      setReloadList(false);
    }
  };

  useEffect(() => {
    getBrands();
  }, [currentPage, searchValue]);

  return { brands, reloadList, pagination, getBrands };
};

// Hook: modal and delete brand
export const useModalBrand = (getBrands?: () => void) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  // open modal
  const onOpenModal = () => {
    setIsOpen(true);
  };

  // close modal
  const onCloseModal = () => {
    setIsOpen(false);
  };

  // set modal title
  const changeModalTitle = (title) => {
    setModalTitle(title);
  };

  // delete brand
  const deleteBrand = useCallback((id: string) => {
    deleteModal({
      title: 'Bạn có muốn xóa thương hiệu này?',
      onDeleteCb: async () => {
        if (id) {
          try {
            await api.remove({ endpoint: API_BRAND.replace('{id}', id) });

            onCloseModal();
            // get list of brands
            getBrands();
          } catch (err) {
            showErrorMsg(err);
          }
        }
      },
    });
  }, []);

  return { isOpen, modalTitle, onOpenModal, onCloseModal, changeModalTitle, deleteBrand };
};

// Hook: upload image brand
export const useUploadImagesBrand = () => {
  const dispatch = useDispatch();

  const newBrand = new BrandModel({});
  const [brand, setBrand] = useState(newBrand);

  // upload image states
  /* Main logo */
  const [mainLogoFile, setMainLogoFile] = useState<RcFile>(null);
  const [mainLogoPreviewImg, setMainLogoPreviewImg] = useState<IImage>(null);

  /* Logo menu */
  const [logoMenuFile, setLogoMenuFile] = useState<RcFile>(null);
  const [logoMenuPreviewImg, setLogoMenuPreviewImg] = useState<IImage>(null);

  /* Banner */
  const [bannerFile, setBannerFile] = useState<RcFile>(null);
  const [bannerPreviewImg, setBannerPreviewImg] = useState<IImage>(null);

  // reset upload images
  const resetUploadImages = () => {
    setMainLogoPreviewImg(null);
    setMainLogoFile(null);
    setLogoMenuPreviewImg(null);
    setLogoMenuFile(null);
    setBannerPreviewImg(null);
    setBannerFile(null);
  };

  // upload image
  const onUploadImage = useCallback(
    (imageType: BrandImageType) => (file: RcFile) => {
      dispatch(setIsEditing(true));
      switch (imageType) {
        case BrandImageType.logo:
          setMainLogoFile(file);
          break;
        case BrandImageType.logoMenu:
          setLogoMenuFile(file);
          break;
        case BrandImageType.banner:
          setBannerFile(file);
          break;
        default:
          break;
      }
    },
    [],
  );

  // delete image
  const onDeleteImage = useCallback(
    (imageType: BrandImageType) => () => {
      switch (imageType) {
        case BrandImageType.logo:
          setMainLogoFile(null);
          break;
        case BrandImageType.logoMenu:
          setLogoMenuFile(null);
          break;
        case BrandImageType.banner:
          setBannerFile(null);
          break;
        default:
          break;
      }
    },
    [],
  );

  // submit images
  const submitImages = useCallback(
    async (id: string) => {
      await Promise.all([
        uploadFile(mainLogoFile, 'brand', id, BrandImageType.logo),
        uploadFile(logoMenuFile, 'brand', id, BrandImageType.logoMenu),
        uploadFile(bannerFile, 'brand', id, BrandImageType.banner),
      ])
        .then(([attrLogo, attrLogoMenu, attrBanner]) => {
          if (attrLogo) {
            setMainLogoPreviewImg(attrLogo?.resize);
            setMainLogoFile(null);
          }
          if (attrLogoMenu) {
            setLogoMenuPreviewImg(attrLogoMenu?.resize);
            setLogoMenuFile(null);
          }
          if (attrBanner) {
            setBannerPreviewImg(attrBanner?.resize);
            setBannerFile(null);
          }
        })
        .catch((err) => {
          showErrorMsg(err);
        });
    },
    [mainLogoFile, logoMenuFile, bannerFile],
  );

  // set data brand
  const setDataBrand = (brandItem: IBrand) => {
    const { attributes } = brandItem;
    const { logo } = attributes;

    const mainLogo = logo.find((item: IImage) => item.property === BrandImageType.logo);
    const logoMenu = logo.find((item: IImage) => item.property === BrandImageType.logoMenu);
    const banner = logo.find((item: IImage) => item.property === BrandImageType.banner);

    setBrand(brandItem);
    setMainLogoPreviewImg(mainLogo);
    setLogoMenuPreviewImg(logoMenu);
    setBannerPreviewImg(banner);
  };

  return {
    brand,
    mainLogoPreviewImg,
    logoMenuPreviewImg,
    bannerPreviewImg,
    mainLogoFile,
    logoMenuFile,
    bannerFile,
    resetUploadImages,
    onUploadImage,
    onDeleteImage,
    submitImages,
    setDataBrand,
  };
};

export const useUpdateBrandContent = () => {
  const dispatch = useDispatch();

  const [urlPath, setUrlPath] = useState('');
  const [slugCode, setSlugCode] = useState('');
  const [description, setDescription] = useState('');

  // change description
  const changeDescription = (text: string) => {
    setDescription(text);
    dispatch(setIsEditing(true));
  };

  // reset cotent
  const resetContent = () => {
    setUrlPath('');
    setSlugCode('');
  };

  // change slug
  const onChangeSlug = useCallback((field, values) => {
    let slugPath;
    const fieldKey = Object.keys(field);
    switch (fieldKey[0]) {
      case 'name':
        slugPath = '/thuong-hieu/' + generateSlug(values.name.trim());
        setUrlPath(slugPath);
        setSlugCode(values.name.trim());
        break;
      default:
        break;
    }
  }, []);

  // change description and slug
  const changeContent = (brandItem: IBrand) => {
    const { attributes } = brandItem;
    const { slug, description: desc } = attributes;
    const slugPath = '/thuong-hieu/' + generateSlug(slug.trim());

    setUrlPath(slugPath);
    setSlugCode(slug);
    setDescription(desc);
  };

  return {
    urlPath,
    slugCode,
    description,
    changeDescription,
    resetContent,
    onChangeSlug,
    changeContent,
  };
};

// Hook: Form Brand
export const useFormBrand = (
  brandItem?: IBrand,
  description?: string,
  slugCode?: string,
  getBrands?: () => void,
  onCloseModal?: () => void,
  submitImages?: (id) => void,
) => {
  const dispatch = useDispatch();

  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const onSubmitForm = async (formValues) => {
    try {
      const { id } = brandItem;
      setIsLoadingForm(true);

      const params = { ...formValues, description: description, slug: slugCode };

      if (id) {
        await api.put({ endpoint: API_BRAND.replace('{id}', id), data: params }).then(async () => {
          await submitImages(id);
          onCloseModal();
          getBrands();
          setIsLoadingForm(false);
          message.success('Lưu Thương hiệu thành công', 3);
        });
        dispatch(setIsEditing(false));
      } else {
        await api.post({ endpoint: API_GET_BRANDS, data: params }).then(async (res) => {
          await submitImages(res?.data.id);
          onCloseModal();
          getBrands();
          setIsLoadingForm(false);
          message.success('Tạo mới Thương hiệu thành công', 3);
        });
        dispatch(setIsEditing(false));
      }
    } catch (err) {
      showErrorMsg(err);
      setIsLoadingForm(false);
    }
  };

  return { isLoadingForm, onSubmitForm };
};
