import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import RankForm from './components/Form';
import Menu, { LevelItem } from './components/Menu';

import './index.styles.scss';
import { useFetchLevels } from './hook';

const MemberPoliciesPage: React.FC = () => {
  const { isFetching, data, addUrlParams, refetch: fetchLevels } = useFetchLevels();

  // TODO: receive output code from left menu
  const handleChangeLevel = (level: LevelItem) => {
    const query = {
      levelId: level.id,
      rankId: level.rank.id || null,
    };

    addUrlParams(query);
  };

  return (
    <div className="members-page">
      <LayoutDetail
        pageTitle="Chính sách thành viên"
        breadcrumbs={[
          {
            title: 'Chính sách thành viên',
            route: '/chinh-sach-thanh-vien',
          },
        ]}
        pageClassName="member-policies-page"
        isAddNew={true}
        wrapperCard={false}
      >
        <div className="row">
          <div className="col-12 col-md-4 col-xl-3">
            <Menu menuList={data} isLoading={isFetching} onChange={handleChangeLevel} />
          </div>
          <div className="col-12 col-md-8 col-xl-9">
            <RankForm menuList={data} onRefresh={fetchLevels} />
          </div>
        </div>
      </LayoutDetail>
    </div>
  );
};

export default MemberPoliciesPage;
