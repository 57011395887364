import { Tooltip, message } from 'antd';
import lockImg from 'assets/img/lock.svg';
import unlockImg from 'assets/img/unlock.svg';
import ButtonDownload from 'components/ButtonDownload';
import { Customer, CustomerAttributes } from 'interfaces/customer.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useMemo } from 'react';
import NumericFormat from 'react-number-format';
import { NavLink } from 'react-router-dom';

import './index.styles.scss';
import { confirmModal } from 'utils/modals';
import { addVnCurrency } from 'utils/utils';
import { API_DOWNLOAD_MEMBERS } from 'constants/api/member.api';
import { AlignType, DateFormat } from 'constants/enum';
import { FilterMembersPage, FilterView } from './components/FilterView';
import { useFetchMembers } from './hooks';
import { useUpdateMember } from '../Details/hooks';
import { getCustomerLabelStatus } from '../utils';

const MemberListPage: React.FC = () => {
  const params = queryString.parse(location.search);

  // Download members
  const fileName = `customers-${moment().format('DD-MM-YYYY')}.zip`;
  const urlDownload = useMemo(() => {
    return API_DOWNLOAD_MEMBERS + `?${queryString.stringify({ ...params })}`;
  }, [params]);

  const {
    isFetching,
    data,
    pagination,
    setPagination,
    refetch: fetchMembers,
    totalItems,
  } = useFetchMembers();
  const { onUpdate } = useUpdateMember();

  /**
   * Handle the member of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchMembers({ page });
    },
    [fetchMembers],
  );

  /**
   * Handle the event of changing the filter for members page.
   *
   * @param {FilterMembersPage} filter - The new filter options for members page.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onFilterChange = useCallback(
    async (filter: FilterMembersPage): Promise<void> => {
      await fetchMembers({ page: 1, ...filter });
    },
    [fetchMembers],
  );

  const onUpdateAccountStatus = useCallback(
    async (id: string, status: 'active' | 'inactive') => {
      confirmModal({
        title: `Bạn có chắc là muốn ${status === 'active' ? 'mở khoá' : 'khoá'} tài khoản này?`,
        buttonText: 'Có',
        onSaveCb: async () => {
          try {
            await onUpdate({ id, data: { status } });
            message.success(`${status === 'active' ? 'Mở khoá' : 'Khoá'} tài khoản thành công`);
            await fetchMembers();
          } catch (error: any) {
            const msg = error?.message || error;
            message.error(msg);
          }
        },
      });
    },
    [onUpdate, fetchMembers],
  );

  // column table
  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Thông tin',
      render: (customer: Customer) => (
        <NavLink
          className="member-info"
          to={{
            pathname: `/danh-sach-members/${customer.id}`,
          }}
        >
          <div className="member-info__name">{customer.attributes.name}</div>
          <div className="member-info__email">{customer.attributes.email || '-'}</div>
          <div className="member-info__phone">{customer.attributes.phone || '-'}</div>
          <div className="member-info__dob">
            Ngày sinh:{' '}
            {customer.attributes?.dob
              ? moment(customer.attributes?.dob, DateFormat.serverSide).format(
                  DateFormat.clientSide,
                )
              : '-'}
          </div>
        </NavLink>
      ),
    },
    {
      title: 'Tổng đơn',
      render: (customer: Customer) => (
        <div className="user-purchase">
          <div className="user-purchase__total-amount">
            {addVnCurrency(customer.attributes.totalSpent)}
          </div>
          <div className="user-purchase__total-orders">{customer.attributes.totalOrder} Đơn</div>
        </div>
      ),
    },
    {
      title: 'Số Mã Giảm Giá',
      render: (customer: Customer) => (
        <div className="user-discount-code">
          <div className="user-discount-code__total">
            {customer.attributes.totalAvailableCoupon} Mã
          </div>
          <div className="user-discount-code__available-txt">(còn hiệu lực)</div>
        </div>
      ),
    },
    {
      title: 'Hoạt động',
      render: (customer: Customer) => (
        <div className="user-behavior">
          <NavLink
            to={{
              pathname: `/danh-sach-members/${customer.id}/reviews`,
            }}
            className="user-behavior__reviews"
          >
            {customer.attributes.totalReview} Reviews
          </NavLink>
          <NavLink
            className="user-behavior__comments"
            to={{
              pathname: `/danh-sach-members/${customer.id}/comments`,
            }}
          >
            {customer.attributes.totalComment} Comments
          </NavLink>
        </div>
      ),
    },
    {
      title: 'Tình trạng',
      dataIndex: 'attributes',
      render: (c: CustomerAttributes) => (
        <div className="user-state">
          <div className={`user-state__status user-state__status--${c.status}`}>
            {getCustomerLabelStatus(c.status)}
          </div>
          <div className="user-state__rank">
            <span>Cấp: Thành viên</span>
          </div>
        </div>
      ),
    },
    {
      title: 'Thao tác',
      align: AlignType.right,
      render: (c: Customer) => (
        <div className="user-actions">
          <Tooltip
            title={c.attributes.status === 'inactive' ? 'Mở khoá tài khoản' : 'Khoá tài khoản'}
            placement="left"
          >
            <div
              className="user-actions__block"
              onClick={() =>
                onUpdateAccountStatus(
                  c.id,
                  c.attributes.status === 'inactive' ? 'active' : 'inactive',
                )
              }
            >
              <img src={c.attributes.status === 'inactive' ? unlockImg : lockImg} />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="members-page">
      <LayoutList
        pageTitle="Danh sách Members"
        breadcrumbs={[
          {
            title: 'Danh sách Members',
            route: '/danh-sach-members',
          },
        ]}
        showAddButton={false}
        loadingList={isFetching}
        columns={columns}
        dataList={data}
        tableClass="members-table"
        pagination={pagination}
        handleChangePage={onPageChange}
        topPagination
        filterNode={<FilterView onFilter={onFilterChange} />}
        rightToolbarComponent={
          <div className="total-members">
            <div className="total">
              <NumericFormat
                value={totalItems}
                allowLeadingZeros
                thousandSeparator=","
                displayType="text"
              />{' '}
              thành viên
            </div>

            <ButtonDownload fileName={fileName} urlDownload={urlDownload} />
          </div>
        }
      />
    </div>
  );
};

export default MemberListPage;
