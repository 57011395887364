import { Col, Collapse, Form, Input, Radio, Row, Switch } from 'antd';
import { IImage } from 'interfaces/image.interface';
import isEmpty from 'lodash/isEmpty';
import { UploadImage } from 'pages/CollectionDetail/components/UploadImage';
import React, { useCallback, useState } from 'react';

const { Panel } = Collapse;

interface Props {
  blockId: string;
  data: CollectionsTemplateBlock;
  onChange: (data: CollectionsTemplateBlock) => void;
}

interface CollectionTemplate {
  title: string;
  link: string;
  buttonColor: 'white' | 'black';
  image: IImage;
}

export interface CollectionsTemplateBlock {
  show: boolean;
  data: CollectionTemplate[];
}

const INITITAL_COLLECTIONS: CollectionsTemplateBlock = {
  show: false,
  data: [
    { title: '', link: '', buttonColor: 'white', image: null },
    { title: '', link: '', buttonColor: 'white', image: null },
    { title: '', link: '', buttonColor: 'white', image: null },
  ],
};

export const CollectionsBlock: React.FC<Props> = (props) => {
  const { blockId, data, onChange } = props;

  const [collections, setCollections] = useState<CollectionsTemplateBlock>(
    !isEmpty(data) ? data : INITITAL_COLLECTIONS,
  );

  const handleFieldChange = useCallback(
    (fieldName: keyof CollectionTemplate, value: any, index: number) => {
      const newCollections = { ...collections };
      newCollections.data[index][fieldName] = value;
      setCollections(newCollections);
      onChange(newCollections);
    },
    [collections, onChange],
  );

  return (
    <div>
      <Form.Item label="Hiển thị">
        <Col span={12}>
          <Switch
            checked={collections.show}
            onChange={(checked) => {
              const newCollections = { ...collections, show: checked };
              setCollections(newCollections);
              onChange(newCollections);
            }}
          />
        </Col>
      </Form.Item>
      <Collapse defaultActiveKey={['1', '2', '3']}>
        {collections.data.map((c, i) => {
          return (
            <Panel header={`Bộ sưu tập ${i + 1}`} key={`${i + 1}`}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Tiêu đề">
                    <Input
                      value={c.title}
                      onChange={(e) => handleFieldChange('title', e.target.value, i)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Link">
                    <Input
                      value={c.link}
                      onChange={(e) => handleFieldChange('link', e.target.value, i)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Màu nút">
                    <Radio.Group
                      value={c.buttonColor}
                      onChange={(e) => handleFieldChange('buttonColor', e.target.value, i)}
                    >
                      <Radio value="white">Trắng</Radio>
                      <Radio value="black">Đen</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="Image (760 x 360)">
                    <UploadImage
                      initialImage={c.image}
                      uploadInfo={{
                        type: 'block',
                        typeId: blockId,
                        property: '',
                        override: '0',
                      }}
                      uploadText="Click hoặc kéo thả hình (760 x 360) để upload"
                      onChange={(image) => handleFieldChange('image', image, i)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
