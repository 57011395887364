/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import { API_MEMBERS_ORDER } from 'constants/api/member.api';
import { Order } from 'interfaces/order.interface';
import { fetcherWithArg } from 'services/swr.utils';

/**
 * Custom hook that fetches and returns member wishlist page data.
 */
export const useFetchMemberOrder = () => {
  const { orderId } = useParams<{ orderId: string }>();

  const { data, isMutating, trigger } = useSWRMutation(
    `${API_MEMBERS_ORDER.replace('{id}', orderId)}`,
    fetcherWithArg,
  );

  useEffect(() => {
    fetch();
  }, []);

  const fetch = useCallback(async (): Promise<void> => {
    await trigger();
  }, []);

  return {
    isFetching: isMutating,
    data: data?.data as Order,
  };
};
