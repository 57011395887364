import NotFound from 'pages/errors/NotFound/NotFound';

import './Error.scss';

const ErrorLayout = () => {
  return (
    <div className="error-layout">
      <NotFound />
    </div>
  );
};

export default ErrorLayout;
