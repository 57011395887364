import { Card } from 'antd';
import React from 'react';

// Components
import { NavLink } from 'react-router-dom';

// styles
import './Menu.scss';

const menu = [
  {
    id: 1,
    title: 'Bộ size theo nhóm',
    link: 'he-size',
  },
  {
    id: 2,
    title: 'Size Items',
    link: 'sizes',
  },
];

const GroupSizeMenu: React.FC = () => {
  return (
    <Card className="tabs-card">
      <ul className="lst">
        {menu.map((item) => {
          const { id, title, link } = item;
          return (
            <li key={'mn-size-' + id} className={`menu-size-item`}>
              <NavLink
                to={{
                  pathname: `/${link}`,
                }}
                className="item-link"
                activeClassName="active"
                replace
              >
                {title}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </Card>
  );
};

export default GroupSizeMenu;
