import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, message, Input, SpinProps } from 'antd';
import { RcFile } from 'antd/lib/upload';

import imgEmpty from 'assets/img/empty.png';
import { useUpdateVariantsPosition } from 'hooks/useProductDetail';
import { IImage } from 'interfaces/image.interface';
import { IProduct, IProductAttribute } from 'interfaces/product.interface';
import { clone, isEmpty } from 'lodash';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { setOpenSelectProductSection } from 'redux/product/actions';
import { AppState } from 'redux/store';
import * as api from 'services/api.service';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { deleteModal } from 'utils/modals';
import { addVnCurrency, formatNumber, showErrorMsg, uploadFile } from 'utils/utils';
import { API_GET_PRODUCT_DETAIL } from 'constants/api/product.api';
import { AlignType, ProductSection } from 'constants/enum';

import BaseProductsTable from './BaseProductsTable/BaseProductsTable';

interface SelectedProductListInterface {
  selectedProducts: IProduct[];
  productGroupKey: string;
  buttonText?: string;
  modalTitle?: string;
  description?: string;
  sortable?: boolean;
  isShowAddButton?: boolean;
  isShowRemoveButton?: boolean;
  isShowSaveButton?: boolean;
  handleUpdateSelectedProducts?: (productList: IProduct[]) => void;
  isVariantProducts?: boolean;
  handleRefreshVariantProducts?: () => void;
}

const poroductState = (state: AppState) => state.productReducers;

export const SelectedProductList: FC<SelectedProductListInterface> = ({
  selectedProducts,
  productGroupKey,
  buttonText = 'Thêm vào danh sách',
  modalTitle,
  description,
  sortable = true,
  isShowAddButton = true,
  isShowRemoveButton = true,
  handleUpdateSelectedProducts = null,
  isVariantProducts = false,
  isShowSaveButton = false,
  handleRefreshVariantProducts = null,
}) => {
  const { subStatuses, productDetail } = useSelector(poroductState);
  const [products, setProducts] = useState<IProduct[]>(selectedProducts);
  const [showDiscoutInpput, setShowDiscoutInpput] = useState('');
  const [resetIcon, setResetIcon] = useState(false);
  const [idUploadingImage, setIdUploadingImage] = useState<string | null>(null);
  const dispatch = useDispatch();

  const { id: productId } = useParams<{ id: string }>();

  const { isUpdating: isSortLoading, onUpdate } = useUpdateVariantsPosition(productId);

  const tableLoadingProp: SpinProps =
    idUploadingImage || isSortLoading
      ? {
          spinning: true,
          tip: !isSortLoading ? `Đang tải hình...` : `Đang cập nhật...`,
        }
      : { spinning: false };

  useEffect(() => {
    if (!isEmpty(selectedProducts)) {
      setProducts(selectedProducts);
    }
  }, [selectedProducts]);

  const onToggleModal = useCallback(() => {
    dispatch(
      setOpenSelectProductSection({
        title: modalTitle,
        productGroupKey,
      }),
    );
  }, [dispatch]);

  const onHandleRemove = useCallback(
    async (row: IProduct) => {
      const clonedProducts = clone(products);
      const selectedIndex = clonedProducts.findIndex((item) => item.id === row.id);

      switch (productGroupKey) {
        case ProductSection.variantProducts:
          deleteModal({
            title: 'Bạn có muốn xóa sản phẩm này?',
            onDeleteCb: async () => {
              try {
                await api.remove({ endpoint: API_GET_PRODUCT_DETAIL.replace('{id}', row.id) });
                if (selectedIndex > -1) {
                  clonedProducts.splice(selectedIndex, 1);
                }
                setProducts(clonedProducts);
                handleRefreshVariantProducts && handleRefreshVariantProducts();
                message.success('Xóa sản phẩm thành công', 3);
              } catch (err) {
                showErrorMsg(err);
              }
            },
          });
          break;
        default:
          if (selectedIndex > -1) {
            clonedProducts.splice(selectedIndex, 1);
          }
          setProducts(clonedProducts);
          handleUpdateSelectedProducts && handleUpdateSelectedProducts(clonedProducts);
          break;
      }
    },
    [products, handleUpdateSelectedProducts, handleRefreshVariantProducts, productGroupKey],
  );

  const onHandleUpdate = useCallback(
    async (row: IProduct) => {
      const shortDisplayName = row?.attributes?.attributeValues?.find(
        (item) => item.attributeCode === 'short_display_name',
      );

      if (productGroupKey === ProductSection.variantProducts) {
        try {
          await api.put({
            endpoint: API_GET_PRODUCT_DETAIL.replace('{id}', row.id),
            data: {
              attributeValues: [
                {
                  attributeId: shortDisplayName?.attributeId,
                  value: shortDisplayName?.value,
                },
              ],
            },
          });
          message.success('Lưu sản phẩm Simple thành công', 3);
          handleRefreshVariantProducts && handleRefreshVariantProducts();
        } catch (err) {
          showErrorMsg(err);
        }
      }
    },
    [products, handleRefreshVariantProducts, productGroupKey],
  );

  const handleChangeProductPosition = useCallback(
    async (productList: IProduct[]) => {
      if (productGroupKey !== ProductSection.relatedProducts2) {
        await onUpdate({
          data: { productIds: productList.map((item) => item.id) },
        });
      }

      handleUpdateSelectedProducts && handleUpdateSelectedProducts(productList);
    },
    [handleUpdateSelectedProducts, productGroupKey],
  );

  const handleShowPriceInput = (product: IProduct) => () => {
    setShowDiscoutInpput(product.id);
  };

  const onSaveDisplayName = (product: IProduct) => (e: ChangeEvent<HTMLInputElement>) => {
    const clonedProduct = products.map((item) => {
      if (item.id === product.id) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            displayName: e.target.value,
          },
        };
      }
      return item;
    });
    handleUpdateSelectedProducts && handleUpdateSelectedProducts(clonedProduct);
  };

  const onSaveShortDisplayName = (product: IProduct, e: ChangeEvent<HTMLInputElement>) => {
    const clonedProduct = products.map((item) => {
      const transformAttributeValues = item?.attributes?.attributeValues?.map((attr) => {
        if (attr.attributeCode === 'short_display_name') {
          return {
            ...attr,
            value: e.target.value,
          };
        }
        return attr;
      });

      if (item.id === product.id) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            attributeValues: transformAttributeValues,
          },
        };
      }
      return item;
    });
    handleUpdateSelectedProducts && handleUpdateSelectedProducts(clonedProduct);
  };

  const onSaveNewPrice = (product: IProduct) => (e: ChangeEvent<HTMLInputElement>) => {
    const clonedProduct = products.map((item) => {
      if (item.id === product.id) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            discount: parseInt(e.target.value.replaceAll(',', '')),
          },
        };
      }
      return item;
    });
    setProducts(clonedProduct);
    setShowDiscoutInpput('');
    handleUpdateSelectedProducts && handleUpdateSelectedProducts(clonedProduct);
  };

  const handleUploadImage = useCallback(
    (row: IProduct) => (file: RcFile) => {
      setIdUploadingImage(row.id);

      uploadFile(file, 'producticon', row?.id.toString()).then(async (res) => {
        if (res) {
          const transformProduct = products.map((item) => {
            if (item.id === row.id) {
              return {
                ...item,
                attributes: {
                  ...item.attributes,
                  icon: res?.resize,
                },
              };
            }
            return item;
          });
          setResetIcon(true);
          setProducts(transformProduct);
          setIdUploadingImage(null);
          // handleRefreshVariantProducts && handleRefreshVariantProducts();
          message.success('Cập nhật hình màu thành công', 3);
          setTimeout(() => {
            setResetIcon(false);
          }, 2000);
        }
      });
    },
    [products],
  );

  const columns = [
    {
      title: 'Hình ảnh',
      dataIndex: 'attributes',
      width: 70,
      render: (attr: IProductAttribute) => {
        const imageValues = attr.attributeValues.find((item) => item.attributeCode === 'image');
        const image = imageValues?.value.find((item: IImage) => item.property === 'main');

        return (
          <div className="product-image" style={{ width: 50, height: 50 }}>
            <img
              src={image?.['128x128'] || imgEmpty}
              alt={image?.title}
              style={{ height: '100%' }}
            />
          </div>
        );
      },
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute, row: IProduct) => {
        const status = subStatuses?.find((item) => item.id === attr?.statusId?.toString());
        const shortDisplayName = attr?.attributeValues?.find(
          (item) => item.attributeCode === 'short_display_name',
        );
        const sizeGroup = attr?.attributeValues?.find(
          (item) => item.attributeType === 'size_group',
        );
        const colorImage = attr?.icon?.['original'] || imgEmpty;

        return productGroupKey === ProductSection.groupedProducts ? (
          <>
            <Input defaultValue={attr.displayName} onBlur={onSaveDisplayName(row)} />
            <NavLink to={`/san-pham/${row.id}`} target="_blank" title={attr?.name}>
              <div className="product-name-overflow">{`Type: ${attr?.type}`}</div>
              <p className="product-name-overflow">{status?.attributes?.name}</p>
            </NavLink>
          </>
        ) : (
          <>
            <div className={isVariantProducts ? 'mb-2' : ''}>
              <NavLink to={`/san-pham/${row.id}`} target="_blank" title={attr?.name}>
                <div className="product-name-overflow">{<strong>{attr?.name}</strong>}</div>
                {`SKU: ${attr?.sku}`}
                <div className="product-name-overflow">{`Type: ${attr?.type}`}</div>
                <p className="product-name-overflow">{status?.attributes?.name}</p>
              </NavLink>
            </div>
            {isVariantProducts && (
              <div className="d-flex align-items-center variant-color-blk">
                {colorImage && idUploadingImage !== row.id && (
                  <div className="mr-3 color-img product-image" style={{ width: 50, height: 50 }}>
                    <img src={colorImage} alt={attr?.name} style={{ height: '100%' }} />
                  </div>
                )}
                <div className="mr-3 variant-color-upload">
                  <UploadFile
                    resetFile={resetIcon}
                    handleUploadImage={handleUploadImage(row)}
                    uploadText={attr?.icon ? 'Đổi hình màu' : 'Upload hình màu'}
                  />
                </div>
                <Input
                  defaultValue={shortDisplayName?.value}
                  placeholder="Tên màu sản phẩm"
                  onBlur={(e) => onSaveShortDisplayName(row, e)}
                />
                {sizeGroup && sizeGroup['subValue'] && (
                  <div className="ml-3 variant-size text-uppercase">
                    SIZE: {sizeGroup['subValue']['title']}
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'Giá',
      dataIndex: 'attributes',
      width: 150,
      render: (attr: IProductAttribute, row: IProduct) => {
        const currentPrice = attr?.attributeValues.find(
          (item) => item.attributeCode === 'original_price',
        );

        return (
          <>
            <div title="Giá hiện tại" className="mb-1">
              {addVnCurrency(currentPrice?.value)}
            </div>
            {productGroupKey === ProductSection.crossSells &&
              (showDiscoutInpput !== row.id ? (
                <div
                  title="Giá chỉnh sửa"
                  className={` ${attr?.discount ? 'product-current-price' : 'empty-price'} mt-1`}
                  onClick={handleShowPriceInput(row)}
                >
                  {attr?.discount ? (
                    formatNumber(attr?.discount)
                  ) : (
                    <div>
                      - <EditOutlined />
                    </div>
                  )}
                </div>
              ) : (
                <NumberFormat
                  decimalSeparator="."
                  thousandSeparator
                  className="mt-1 ant-input ant-input-sm"
                  defaultValue={attr?.discount}
                  onBlur={onSaveNewPrice(row)}
                  autoFocus
                />
              ))}
          </>
        );
      },
    },
    {
      title: `${productGroupKey === ProductSection.variantProducts ? '' : 'Thao tác'}`,
      dataIndex: '',
      width: 80,
      align: AlignType.right,
      fixed: 'right',
      render: (row: IProduct) => {
        return (
          <>
            {row.id !== productDetail.id && isShowSaveButton && (
              <Button
                className="mb-3"
                title="Cập nhật sản phẩm Simple"
                icon={<SaveOutlined />}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await onHandleUpdate(row);
                }}
              />
            )}
            {row.id !== productDetail.id && isShowRemoveButton && (
              <Button title="Xoá" icon={<DeleteOutlined />} onClick={() => onHandleRemove(row)} />
            )}
          </>
        );
      },
    },
  ];

  return (
    <BaseProductsTable
      loading={tableLoadingProp}
      columns={columns}
      dataList={products}
      sortable={sortable}
      buttonText={buttonText}
      onAddClick={isShowAddButton ? onToggleModal : null}
      handleChangePosition={handleChangeProductPosition}
      description={description}
    />
  );
};
