import { Card } from 'antd';
import { IPrdCategory } from 'interfaces/product-category.interface';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { TreeView } from 'ui/components/MaiNguyen/TreeView';
import { TreeLevelInterface } from 'utils/utils';

interface CategoryListProps {
  selectedIds: string[];
  data: TreeLevelInterface[];
  isLoading: boolean;
  onSelect: (data: any) => void;
  onCategoryPositionChange: (parentId: number, dragNode: IPrdCategory) => void;
}

export const CategoryList: React.FC<CategoryListProps> = (props) => {
  const { isLoading, data, onSelect, selectedIds, onCategoryPositionChange } = props;
  return (
    <Card className="ant-card-with-scroll">
      {isLoading ? (
        <>
          <Skeleton width="55%" />
          <Skeleton width="60%" />
          <Skeleton width="70%" />
          <Skeleton width="80%" />
          <Skeleton width="90%" />
          <Skeleton width="95%" />
        </>
      ) : (
        <TreeView
          treeData={data}
          handleSelectLeaf={onSelect}
          selectedKeys={selectedIds?.length ? selectedIds : []}
          handleDropContent={onCategoryPositionChange}
        />
      )}
    </Card>
  );
};
