import { IHomeBlock } from 'interfaces/home-block.interface';
import omit from 'lodash/omit';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as api from 'services/api.service';
import { showErrorMsg, uploadFile } from 'utils/utils';
import { API_CREATE_BLOCK, API_GET_BLOCKS, API_UPDATE_BLOCK } from 'constants/api/home-block.api';
import {
  CouponPresentPageTemplates,
  RiderCouponPresentPageTemplates,
  StoreIds,
} from 'constants/enum';

export const useFetchCouponPresent = () => {
  const location = useLocation();

  const [blocks, setBlocks] = useState<IHomeBlock[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const storeId = location.pathname.includes('rider') ? StoreIds.rider : StoreIds.mainguyen;
  const positions =
    storeId === StoreIds.mainguyen
      ? `${CouponPresentPageTemplates.coupons},${CouponPresentPageTemplates.mainSlider},${CouponPresentPageTemplates.seo}`
      : `${RiderCouponPresentPageTemplates.coupons},${RiderCouponPresentPageTemplates.mainSlider},${RiderCouponPresentPageTemplates.seo}`;

  const fetch = useCallback(async () => {
    try {
      setIsFetching(true);
      const result = await api.get({
        endpoint: `${API_GET_BLOCKS}?perPage=all&positions${positions}=&storeId=${storeId}`,
      });
      setBlocks(result?.data);
    } catch (error: any) {
      showErrorMsg(error?.message || error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  return { isFetching, blocks, fetch };
};

export const useCreateBlock = () => {
  const location = useLocation();

  const storeId = location.pathname.includes('rider') ? StoreIds.rider : StoreIds.mainguyen;

  const createBlock = useCallback(
    async (blockAttrs: IHomeBlock['attributes'], onSuccess: (data: IHomeBlock) => void) => {
      try {
        const blockResult = await api.post({
          endpoint: API_CREATE_BLOCK,
          data: omit(
            {
              ...blockAttrs,
              storeId,
              data:
                blockAttrs?.data && typeof blockAttrs?.data !== 'string'
                  ? JSON.stringify(blockAttrs.data)
                  : blockAttrs?.data,
            },
            ['image'],
          ),
        });

        if (blockAttrs?.image instanceof File) {
          await uploadFile(blockAttrs.image as any, 'block', blockResult?.data?.attributes._id);
        }

        onSuccess(blockResult?.data);
      } catch (error: any) {
        showErrorMsg(error?.message || error);
      }
    },
    [storeId],
  );

  return { createBlock };
};

export const useUpdateBlock = () => {
  const location = useLocation();

  const storeId = location.pathname.includes('rider') ? StoreIds.rider : StoreIds.mainguyen;

  const updateBlock = useCallback(
    async (
      blockId: string,
      blockAttrs: IHomeBlock['attributes'],
      onSuccess: (data: IHomeBlock) => void,
    ) => {
      try {
        const blockResult = await api.put({
          endpoint: API_UPDATE_BLOCK.replace('{id}', blockId),
          data: omit(
            {
              ...blockAttrs,
              storeId,
              data:
                blockAttrs?.data && typeof blockAttrs?.data !== 'string'
                  ? JSON.stringify(blockAttrs.data)
                  : blockAttrs?.data,
            },
            ['image'],
          ),
        });

        if (blockAttrs?.image instanceof File) {
          await uploadFile(blockAttrs.image as any, 'block', blockResult?.data?.attributes._id);
        }

        onSuccess(blockResult?.data);
      } catch (error: any) {
        showErrorMsg(error?.message || error);
      }
    },
    [storeId],
  );

  return { updateBlock };
};
