import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col, Radio } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { clone, orderBy } from 'lodash';
import { FC, useCallback, useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { RiderBlockTemplateTypes } from 'constants/enum';
import { IHomeBlock } from 'interfaces/home-block.interface';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { orderByPositionMainSlider, uploadFile } from 'utils/utils';

interface SpotLightInterface {
  dataSlider?: any[];
  uploadTextDesktop?: string;
  uploadTextMobile?: string;
  hasMobileImage?: boolean;
  blockId: string;
  onUpdateSlider: (slider: any[]) => void;
  imageWidthDesktop?: number;
  imageWidthMobile?: number;
  maxCount?: number;
  onChange?: (objData: any) => void;
  block?: IHomeBlock;
  isChangePosition?: boolean;
  template?: string;
}

export const SpotLight: FC<SpotLightInterface> = ({
  dataSlider = [],
  uploadTextDesktop = 'Click hoặc kéo thả hình để upload (2140x660)',
  uploadTextMobile = 'Click hoặc kéo thả hình để upload (size x size)',
  hasMobileImage = true,
  onUpdateSlider,
  blockId,
  imageWidthDesktop = 400,
  imageWidthMobile = 256,
  maxCount = 999,
  isChangePosition = false,
  template,
}) => {
  const [slider, setSlider] = useState([]);
  const [desktopImageTitle, setDesktopImageTitle] = useState('Desktop Image');
  const [mobileImageTitle, setMobileImageTitle] = useState('Mobile Image');
  const [currentSlider, setCurrentSlider] = useState(0);

  useEffect(() => {
    if (isChangePosition) {
      dataSlider.map((item, idx) => {
        item.position = item.position ? item.position : idx;
      });
    }
    setSlider(dataSlider);

    return () => {
      setSlider([]);
    };
  }, []);

  useEffect(() => {
    if (template === RiderBlockTemplateTypes.heroBanner) {
      setDesktopImageTitle('Image (1200x1200)');
    } else {
      setDesktopImageTitle('Desktop Image');
      setMobileImageTitle('Mobile Image');
    }

    return () => {
      setDesktopImageTitle('');
      setMobileImageTitle('');
    };
  }, [blockId]);

  const handleAddMoreSlider = useCallback(() => {
    const tempSlider = clone(slider);
    tempSlider.unshift({
      id: uuid(),
      title: `name_${currentSlider + 1}`,
      link: '/',
      target: 'not',
      fileDesktop: null,
      fileMobile: null,
      position: 0,
    });
    setSlider(tempSlider);
    setCurrentSlider(currentSlider + 1);
    onUpdateSlider(tempSlider);
  }, [slider, currentSlider, onUpdateSlider]);

  const handleDeleteSlider = useCallback(
    (id: number) => () => {
      const tempSlider = clone(slider);
      const selectedSlider = tempSlider.find((item) => id == item.id);
      const selectedSliderIndex = tempSlider.indexOf(selectedSlider);
      if (selectedSliderIndex > -1) {
        tempSlider.splice(selectedSliderIndex, 1);
      }
      setSlider(tempSlider);
      onUpdateSlider(tempSlider);
    },
    [slider, onUpdateSlider],
  );

  const handleUploadImage = useCallback(
    (id: string, isMobile: boolean) => async (file: RcFile) => {
      const tempSlider = clone(slider);
      const curSlider = tempSlider.find((item) => item.id === id);
      if (isMobile) {
        await uploadFile(file, 'block', blockId, '', '0')
          .then((attr) => {
            curSlider.fileMobile = {
              ...attr,
              original: attr?.originUrl,
            };
          })
          .catch(() => {
            curSlider.fileMobile = null;
          });
      } else {
        await uploadFile(file, 'block', blockId, '', '0')
          .then((attr) => {
            curSlider.fileDesktop = {
              ...attr,
              original: attr?.originUrl,
            };
          })
          .catch(() => {
            curSlider.fileDesktop = null;
          });
      }

      const index = tempSlider.indexOf(curSlider);
      if (index > -1) {
        tempSlider.splice(index, 1, curSlider);
      }
      setSlider(tempSlider);
      onUpdateSlider(tempSlider);
    },
    [slider, onUpdateSlider, blockId],
  );

  const handleDeleteImage = useCallback(
    (isMobile: boolean) => (blckId: string) => {
      const tempSlider = clone(slider);
      const curSlider = tempSlider.find((item) => blckId == item.id);
      if (isMobile) {
        curSlider.fileMobile = null;
      } else {
        curSlider.fileDesktop = null;
      }
      const index = tempSlider.indexOf(curSlider);
      if (index > -1) {
        tempSlider.splice(index, 1, curSlider);
      }
      setSlider(tempSlider);
      onUpdateSlider(tempSlider);
    },
    [slider, onUpdateSlider],
  );

  const handleChangeValue = useCallback(
    (id: string, key: string) => (e) => {
      let tempSlider = clone(slider);
      const curSlider = tempSlider.find((item) => item.id === id);
      const index = tempSlider.indexOf(curSlider);
      switch (key) {
        case 'title':
          curSlider.title = e.target.value;
          break;
        case 'link':
          curSlider.link = e.target.value;
          break;
        case 'target':
          curSlider.target = e.target.value;
          break;
        case 'position':
          const upOrder = parseInt(e.target.value);
          orderByPositionMainSlider(tempSlider, upOrder, index);
          break;
        default:
          break;
      }
      if (index > -1) {
        tempSlider.splice(index, 1, curSlider);
      }
      tempSlider = orderBy(tempSlider, ['position'], ['asc']);
      setSlider(tempSlider);
      onUpdateSlider(tempSlider);
    },
    [slider, onUpdateSlider],
  );

  return (
    <>
      {slider?.length < maxCount && (
        <div className="text-right">
          <Button className="mb-3" onClick={handleAddMoreSlider} icon={<PlusOutlined />}>
            Thêm hình
          </Button>
        </div>
      )}
      {slider?.map((item, idx) => (
        <Row gutter={16} className="mt-3 mb-3" key={blockId + '-' + item.id + '-' + idx}>
          <Col span={isChangePosition ? 6 : 8}>
            <Form.Item label="Tiêu đề">
              <Input value={item.title} onChange={handleChangeValue(item.id, 'title')} />
            </Form.Item>
          </Col>
          <Col span={isChangePosition ? 6 : 8}>
            <Form.Item label="Link">
              <Input value={item.link} onChange={handleChangeValue(item.id, 'link')} />
            </Form.Item>
          </Col>
          <Col span={isChangePosition ? 6 : 8}>
            <Form.Item label="Target">
              <Radio.Group value={item.target} onChange={handleChangeValue(item.id, 'target')}>
                <Radio value="_blank">_blank</Radio>
                <Radio value="not">not</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {isChangePosition && (
            <Col span={6}>
              <Form.Item label="Position">
                <Input value={item.position} onChange={handleChangeValue(item.id, 'position')} />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item label={desktopImageTitle}>
              <UploadFile
                uploadText={uploadTextDesktop}
                handleUploadImage={handleUploadImage(item.id, false)}
                handleDeleteImageSuccess={handleDeleteImage(false)}
                typeId={item.id}
                previewImage={item.fileDesktop}
                maxWidth={imageWidthDesktop}
              />
            </Form.Item>
          </Col>
          {hasMobileImage && (
            <Col span={24}>
              <Form.Item label={mobileImageTitle}>
                <UploadFile
                  uploadText={uploadTextMobile}
                  handleUploadImage={handleUploadImage(item.id, true)}
                  handleDeleteImageSuccess={handleDeleteImage(true)}
                  typeId={item.id}
                  previewImage={item.fileMobile}
                  maxWidth={imageWidthMobile}
                />
              </Form.Item>
            </Col>
          )}
          <Col className="text-right" span={24}>
            <Button
              className="mt-2"
              icon={<DeleteOutlined />}
              onClick={handleDeleteSlider(item.id)}
            >
              Xóa
            </Button>
          </Col>
        </Row>
      ))}
    </>
  );
};
