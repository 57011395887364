import { IMenuLink } from 'interfaces/menu-link.interface';

export const SET_MENU_LINKS = 'SET_MENU_LINKS';

export interface SetMenuLinks {
  type: typeof SET_MENU_LINKS;
  payload: IMenuLink[];
}

export type MenuActionType = SetMenuLinks;
