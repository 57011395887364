import { IAttribute } from 'interfaces/attribute.interface';
import { SET_SHIPPING_FIELD, RESET_SHIPPING_FIELDS } from './types';

export const setShippingFields = (payload: IAttribute[]) => ({
  type: SET_SHIPPING_FIELD,
  payload,
});

export const resetShippingField = () => ({
  type: RESET_SHIPPING_FIELDS,
});
