import { domainUrl } from '../constants';

export const API_GET_POST_MENUS = `${domainUrl}menus`;
export const API_UPDATE_DELETE_MENU = `${domainUrl}menus/{id}`;
export const API_GET_MENU_LINKS_IN_MENU = `${domainUrl}menus/{id}/links`;
export const API_GET_MENU_BLOCKS_IN_MENU = `${domainUrl}menus/{id}/blocks`;

export const API_POST_MENU_LINK = `${domainUrl}menu-links`;
export const API_UPDATE_DELETE_MENU_LINK = `${domainUrl}menu-links/{id}`;

export const API_POST_MENU_BLOCKS = `${domainUrl}menu-blocks`;
export const API_UPDATE_DELETE_MENU_BLOCK = `${domainUrl}menu-blocks/{id}`;
export const API_GET_LINKS_IN_BLOCK = `${domainUrl}menu-blocks/{id}/links`;
