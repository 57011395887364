import { Button, Input } from 'antd';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { STATUS_LIST, directionList } from 'constants/enum';
import Sort from '../../../components/Sort';

export interface FilterOrdersProps {
  direction: string;
  status: string;
  code: string;
  column?: string;
  sortBy?: string;
}

interface Props {
  onFilter: (filter: FilterOrdersProps) => void;
}

const INIT_FILTER: FilterOrdersProps = { direction: directionList[0].value, status: '', code: '' };

const Filter: React.FC<Props> = ({ onFilter }) => {
  const [filter, setFilter] = useState<FilterOrdersProps>(INIT_FILTER);

  const params = queryString.parse(location.search) as any;
  useEffect(() => {
    if (!isEmpty(params)) {
      setFilter((prev) => ({
        ...prev,
        direction: params?.direction || directionList[0].value,
        status: params?.status || STATUS_LIST[0].value,
        code: params?.code || '',
      }));
    }
  }, [JSON.stringify(params)]);

  // TODO: filter direction, status change
  const onFilterChange = useCallback(
    (key: keyof FilterOrdersProps, value: any) => {
      const newFilter = { ...filter, [key]: value };
      setFilter(newFilter);
      onFilter(newFilter);
    },
    [filter, onFilter],
  );

  const resetFilter = useCallback(() => {
    setFilter(INIT_FILTER);
    onFilter(INIT_FILTER);
  }, [onFilter]);

  return (
    <div className="filter-member-orders d-flex items-center gap-2">
      <div className="filter-item sort">
        <label className="d-block mb-1">Sắp xếp:</label>
        <Sort
          sortList={directionList}
          defaultValue={filter.direction}
          onChange={(e) => onFilterChange('direction', e)}
        />
      </div>
      <div className="filter-item filter-status">
        <label className="d-block mb-1">Tình trạng:</label>
        <Sort
          sortList={STATUS_LIST}
          defaultValue={filter.status}
          onChange={(e) => onFilterChange('status', e)}
        />
      </div>
      <div className="filter-item">
        <label className="d-block mb-1">Mã đơn hàng:</label>
        <Input
          className="ipt-order"
          placeholder="Nhập mã đơn hàng"
          value={filter.code}
          onChange={(e) => onFilterChange('code', e.target.value)}
          autoComplete="off"
        />
      </div>
      {(filter.status !== '' || filter.code !== '') && (
        <div className="filter-item">
          <label className="d-block mb-1">&nbsp;</label>
          <Button onClick={() => resetFilter()} className="secondary-button">
            Clear
          </Button>
        </div>
      )}
    </div>
  );
};

export default Filter;
