import { CustomerAttributes } from 'interfaces/customer.interface';
import { FeedbackStatus, ReviewStatus } from 'constants/enum';

export const getCustomerLabelStatus = (status: CustomerAttributes['status']) => {
  if (status === 'active') {
    return 'Đang hoạt động';
  }

  if (status === 'inactive') {
    return 'Đã bị khoá';
  }

  return 'Chưa kích hoạt';
};

export const getReviewLabelStatus = (status: string) => {
  let statusName = '';
  switch (status) {
    case ReviewStatus.pending:
      statusName = 'Chờ duyệt';
      break;
    case ReviewStatus.published:
      statusName = 'Đã duyệt';
      break;
    case ReviewStatus.locked:
      statusName = 'Đã khóa';
      break;
  }
  return statusName;
};

export const getCommentLabelStatus = (status: string) => {
  let statusName = '';
  switch (status) {
    case FeedbackStatus.new:
      statusName = 'Mới';
      break;
    case FeedbackStatus.solved:
      statusName = 'Đã xem';
      break;
  }
  return statusName;
};
