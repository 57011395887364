/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
import React from 'react';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { ReviewList } from './components/ReviewList';

const ReviewPage: React.FC = () => {
  return (
    <LayoutDetail
      pageTitle="Đánh giá sản phẩm"
      breadcrumbs={[
        {
          title: 'Review',
          route: '/member-reviews',
        },
      ]}
      pageClassName="review-page"
      isAddNew={true}
      formHook={null}
      handleValuesChange={null}
      handleSave={null}
      handleCancel={null}
      handleClickAddButton={null}
      handleDelete={null}
      wrapperCard={false}
    >
      <ReviewList />
    </LayoutDetail>
  );
};

export default ReviewPage;
