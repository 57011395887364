import { IAttributeMenuLink } from 'interfaces/menu-link.interface';

export class MenuLinkModel implements IAttributeMenuLink {
  active?: boolean;

  blockId?: number | null;

  brandId?: number | null;

  isHot?: boolean;

  isNew?: boolean;

  menuId?: number;

  params?: string[];

  position?: number;

  title?: string;

  url?: string;

  constructor({
    active,
    blockId,
    brandId,
    isHot,
    isNew,
    menuId,
    params,
    position,
    title,
    url,
  }: IAttributeMenuLink) {
    this.active = active !== undefined && active !== null ? active : false;
    this.isHot = isHot !== undefined && isHot !== null ? isHot : false;
    this.isNew = isNew !== undefined && isNew !== null ? isNew : false;
    this.blockId = blockId || null;
    this.brandId = brandId || null;
    this.menuId = menuId;
    this.params = params || [];
    this.position = position;
    this.title = title || '';
    this.url = url || '';
  }
}
