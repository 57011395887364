/* eslint-disable import/order */
import { Divider } from 'antd';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setIsEditing } from 'redux/uiAction/actions';
import MemberDetailsMenu from '../components/Menu';
import { useFetchMember } from '../hooks';
import OrderDetail from './components/OrderDetail';
import './index.styles.scss';
import OrderHeaderInfo from './components/HeaderInfo';

const MemberOrder: React.FC = () => {
  const dispatch = useDispatch();
  const { id: memberId } = useParams<{ id: string }>();
  const { data: memberData } = useFetchMember(memberId);

  const memberAttributes = useMemo(() => {
    return memberData?.attributes;
  }, [memberData]);

  useEffect(() => {
    memberData?.id && dispatch(setIsEditing(false));
  }, [memberData?.id]);

  return (
    <LayoutDetail
      pageTitle={`Member #${memberId || ''}`}
      isAddNew={false}
      pageClassName="member-details member-order"
      breadcrumbs={[
        {
          title: 'Danh sách members',
          route: '/danh-sach-members',
        },
        {
          title: 'Đơn hàng',
          route: `/danh-sach-members/${memberId}/orders`,
        },
      ]}
    >
      {/* Menu */}
      <MemberDetailsMenu
        totalOrder={memberAttributes?.totalOrder}
        totalComment={memberAttributes?.totalComment}
        totalReview={memberAttributes?.totalReview}
        totalWishlist={memberAttributes?.totalWishlist}
      />

      {/* List */}
      <div className="member-content">
        <OrderHeaderInfo />

        <Divider />

        <OrderDetail />
      </div>
    </LayoutDetail>
  );
};

export default MemberOrder;
