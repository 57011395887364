import { IBlockOfEventPage, IEventPage } from 'interfaces/event-page.interface';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import {
  API_EVENT_PAGE_BLOCK,
  API_EVENT_PAGE_BLOCK_BY_BLOCK_ID,
  API_EVENT_PAGE_BY_ID,
} from 'constants/api/event-page.api';

export const useFetchEventPage = (id: string) => {
  const dispatch = useDispatch();
  const { data, isLoading, isValidating, mutate } = useSWR(
    id ? `${API_EVENT_PAGE_BY_ID.replace('{id}', id)}?include=blocks` : null,
    (url: string) => getPageData(url, dispatch),
  );

  useEffect(() => {
    if (data) {
      mutate(null, { revalidate: true });
    }
  }, [id]);

  return {
    isFetching: isLoading || isValidating,
    data: data?.data as IEventPage,
    blocksOfEventPage: data?.included as IBlockOfEventPage[],
  };
};

/**
 *
 */
export const useCreateBlockTemplate = () => {
  const { isMutating, trigger } = useSWRMutation(
    API_EVENT_PAGE_BLOCK,
    async (endpoint: string, { arg }: { arg: { id: string; data: any } }) => {
      const res = await api.post({ endpoint: endpoint.replace('{id}', arg.id), data: arg.data });
      return res?.data;
    },
  );

  return {
    isCreating: isMutating,
    onCreate: trigger,
  };
};

/**
 *
 */
export const useUpdateBlockTemplate = () => {
  const { isMutating, trigger } = useSWRMutation(
    API_EVENT_PAGE_BLOCK_BY_BLOCK_ID,
    async (endpoint: string, { arg }: { arg: { id: string; blockId: string; data: any } }) => {
      const res = await api.put({
        endpoint: endpoint.replace('{id}', arg.id).replace('{block_id}', arg.blockId),
        data: arg.data,
      });
      return res?.data;
    },
  );

  return {
    isUpdating: isMutating,
    onUpdate: trigger,
  };
};
