/* eslint-disable import/order */
import { ICoupon, ICouponHistory } from 'interfaces/coupon.interface';
import { IPagination } from 'interfaces/pagination.interface';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPageData } from 'services/pageData.service';
import { fetcher, fetcherWithArg } from 'services/swr.utils';
import { updater } from 'services/swr.utils';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import {
  API_COUPON_BY_ID,
  API_COUPON_HISTORY_LIST,
  API_ORDER_DETAILS,
  API_PRODUCTS_OF_COUPON,
  API_COUPON_DOWNLOAD,
} from 'constants/api/coupons.api';
import { API_GET_PRODUCTS } from 'constants/api/product.api';
import { defaultPagination } from 'constants/constants';
import { FilterCouponOrderHistory } from './components/OrderHistory/FilterView';
import { buildParams } from 'utils/utils';

export const useFetchCoupon = (id: string) => {
  const dispatch = useDispatch();
  const { data, isLoading, isValidating, mutate } = useSWR(
    id ? API_COUPON_BY_ID.replace('{id}', id) : null,
    (url: string) => getPageData(url, dispatch),
  );

  useEffect(() => {
    if (data) {
      mutate(null, { revalidate: true });
    }
  }, [id]);

  return {
    isFetching: isLoading || isValidating,
    refetch: mutate,
    data: data?.data as ICoupon,
  };
};

/**
 *
 * @returns
 */
export const useUpdateCoupon = () => {
  const { isMutating, trigger } = useSWRMutation(API_COUPON_BY_ID, updater);

  return {
    isUpdating: isMutating,
    onUpdate: trigger,
  };
};

/**
 *
 */
export const useFetchProducts = () => {
  const { isMutating, data, trigger, reset } = useSWRMutation(API_GET_PRODUCTS, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>({
    current: 1,
    pageSize: 15,
    total: 0,
  });

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        pageSize: data.meta.pagination.perPage,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
      }));
    }
  }, [data?.meta?.pagination]);

  const fetchProducts = useCallback(
    async (params?: any): Promise<void> => {
      const { page = pagination.current, ...rest } = params || {};
      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;

      const query = { page: calcPage, ...rest };
      await trigger(query);
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetchingProducts: isMutating,
    products: data?.data || [],
    fetchProducts,
    pagination,
    setPagination,
    reset,
  };
};

/**
 *
 */
export const useFetchOrderDetails = (code: string) => {
  const { isMutating, data, trigger } = useSWRMutation(
    code ? API_ORDER_DETAILS.replace('{code}', code) : null,
    fetcher,
  );

  return {
    isFetching: isMutating,
    data: data || {},
    fetch: trigger,
  };
};

/**
 *
 */
export const useFetchCouponHistory = (couponId: string) => {
  const { isMutating, data, trigger } = useSWRMutation(
    API_COUPON_HISTORY_LIST.replace('{id}', couponId),
    fetcherWithArg,
  );
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        pageSize: data.meta.pagination.perPage,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
      }));
    }
  }, [data?.meta?.pagination]);

  const refetch = useCallback(
    async (
      params?: Partial<FilterCouponOrderHistory> & {
        page?: number;
        direction?: 'desc' | 'asc';
      },
    ): Promise<void> => {
      const { page = pagination.current, direction = 'desc', ...rest } = params || {};
      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;

      const query = { page: calcPage, direction, ...rest };

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetching: isMutating,
    data: (data?.data as ICouponHistory[]) || [],
    refetch,
    pagination,
    setPagination,
  };
};

export const useFetchProductsOfCoupon = (couponId: string) => {
  const history = useHistory();
  const { isMutating, data, trigger } = useSWRMutation(
    API_PRODUCTS_OF_COUPON.replace('{id}', couponId),
    fetcherWithArg,
  );
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        pageSize: data.meta.pagination.perPage,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
      }));
    }
  }, [data?.meta?.pagination]);

  const fetchProductsOfCoupon = useCallback(
    async (params?: { page?: number; direction?: string }): Promise<void> => {
      const { page = pagination.current, direction = 'desc', ...rest } = params || {};
      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;

      const query = { page: calcPage, direction, ...rest };

      history.push(
        queryString.stringifyUrl(
          {
            url: location.pathname,
            query,
          },
          { skipEmptyString: true, skipNull: true },
        ),
      );

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage],
  );

  return {
    isFetchingProductsOfCoupon: isMutating,
    productsOfCoupon: data?.data || [],
    fetchProductsOfCoupon,
    pagination,
    setPagination,
  };
};

export const useDownloadCoupons = (couponId: string, shouldFetch: boolean) => {
  const { isLoading, isValidating, data } = useSWR(
    shouldFetch ? API_COUPON_DOWNLOAD.replace('{id}', couponId) : null,
    fetcher,
  );

  return {
    isFetching: isLoading || isValidating,
    data: data || {},
  };
};
