import { DeleteOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row, Form, FormInstance } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import { FC, ReactNode, useEffect, useState } from 'react';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';

import './CustomPopup.scss';
import React from 'react';

interface CustomPopupInterface {
  title: string;
  isOpen: boolean;
  children: ReactNode;
  onCloseModal: () => void;
  isSaving?: boolean;
  handleSaveButton?: () => void;
  handleSaveNoEnter?: () => void;
  handleDeleteButton?: () => void;
  handleValuesChange?: (changedValues: any, values: any) => void;
  showDeleteButton?: boolean;
  formHook?: FormInstance<any>;
  formLayout?: FormLayout;
  isShowSaveButton?: boolean;
  buttonSaveText?: string;
  containerCls?: string;
  topToolbarComponent?: ReactNode;
}

export const CustomPopup: FC<CustomPopupInterface> = ({
  title,
  isOpen,
  onCloseModal,
  handleSaveButton,
  children,
  formHook = null,
  formLayout = 'vertical',
  showDeleteButton = false,
  handleDeleteButton = null,
  handleValuesChange = null,
  handleSaveNoEnter = null,
  isShowSaveButton = true,
  buttonSaveText = 'Lưu',
  isSaving,
  containerCls,
  topToolbarComponent = null,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const mainContent = document.getElementById('main-content');
    if (isOpen) {
      mainContent.classList.add('no-scroll');
    } else {
      mainContent.classList.remove('no-scroll');
      formHook && formHook.resetFields(); // reset form
    }
    return () => {
      mainContent.classList.remove('no-scroll');
    };
  }, [isOpen]);

  const onSave = async () => {
    setLoading(true);
    await handleSaveButton();
    setLoading(false);
  };

  return isOpen ? (
    <div className={`popup-wrapper ${containerCls || ''}`}>
      <div className="custom-modal">
        <div className="title-wrapper">
          <h3>
            <strong>{title}</strong>
          </h3>
          <div className="close-btn" onClick={onCloseModal}>
            <CloseOutlined style={{ fontSize: 24 }} rev={null} />
          </div>
        </div>
        <div>
          {React.isValidElement(topToolbarComponent) && React.cloneElement(topToolbarComponent)}
        </div>
        {formHook ? (
          <Form
            form={formHook}
            layout={formLayout}
            onValuesChange={handleValuesChange}
            onFinish={onSave}
            style={{ height: 'inherit' }}
          >
            <Row className="h-90">
              <Col span={24} style={{ textAlign: 'right' }} className="w-100">
                <Form.Item>
                  <div className="save-button-wrapper">
                    {showDeleteButton && (
                      <Button
                        className="btn-delete"
                        icon={<DeleteOutlined rev={null} />}
                        onClick={handleDeleteButton}
                      >
                        Xóa
                      </Button>
                    )}
                    <ThemeButton
                      htmlType={handleSaveNoEnter ? 'button' : 'submit'}
                      onClick={handleSaveNoEnter}
                      loading={isSaving || loading}
                    >
                      {buttonSaveText}
                    </ThemeButton>
                  </div>
                </Form.Item>
              </Col>
              <Col span={24} className="content-wrapper">
                {children}
              </Col>
            </Row>
          </Form>
        ) : (
          <Row className="h-90">
            <Col span={24} style={{ textAlign: 'right' }}>
              {isShowSaveButton && (
                <div className="save-button-wrapper">
                  {showDeleteButton && (
                    <Button
                      className="btn-delete"
                      icon={<DeleteOutlined rev={null} />}
                      onClick={handleDeleteButton}
                    >
                      Xóa
                    </Button>
                  )}
                  <ThemeButton
                    htmlType={handleSaveNoEnter ? 'button' : 'submit'}
                    onClick={handleSaveNoEnter}
                    loading={isSaving || loading}
                  >
                    {buttonSaveText}
                  </ThemeButton>
                </div>
              )}
            </Col>
            <Col span={24} className="content-wrapper">
              {children}
            </Col>
          </Row>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};
