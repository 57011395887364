/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { message } from 'antd';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import copyIcon from 'assets/img/copy.svg';

import './index.styles.scss';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { useFetchMember, useUpdateMember } from './hooks';
import moment from 'moment';
import { DateFormat } from 'constants/enum';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { setIsEditing } from 'redux/uiAction/actions';
import { getCustomerLabelStatus } from '../utils';
import { confirmModal } from 'utils/modals';
import MemberDetailsMenu from './components/Menu';
import ButtonBack from './components/ButtonBack';

const MemberDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id: memberId } = useParams<{ id: string }>();

  const { isFetching, data: memberData, mutate } = useFetchMember(memberId);
  const { isUpdating, onUpdate } = useUpdateMember();

  const memberAttributes = useMemo(() => {
    return memberData?.attributes;
  }, [memberData]);

  useEffect(() => {
    memberData?.id && dispatch(setIsEditing(false));
  }, [memberData?.id]);

  const onUpdateAccountStatus = useCallback(
    async (status: 'active' | 'inactive') => {
      confirmModal({
        title: `Bạn có chắc là muốn ${status === 'active' ? 'mở khoá' : 'khoá'} tài khoản này?`,
        buttonText: 'Có',
        onSaveCb: async () => {
          try {
            const newData = await onUpdate({ id: memberData.id, data: { status } });
            await mutate(
              { data: newData },
              {
                revalidate: false,
              },
            );
            message.success(`${status === 'active' ? 'Mở khoá' : 'Khoá'} tài khoản thành công`);
          } catch (error: any) {
            const msg = error?.message || error;
            message.error(msg);
          }
        },
      });
    },
    [memberData?.id, onUpdate],
  );

  if (isFetching || !memberData) return null;

  return (
    <LayoutDetail
      pageTitle={`Member #${memberId || ''}`}
      isAddNew={false}
      pageClassName="member-details"
      breadcrumbs={[
        {
          title: 'Danh sách members',
          route: '/danh-sach-members',
        },
        {
          title: 'Chi tiết',
          route: `/danh-sach-members/${memberId}`,
        },
      ]}
    >
      <MemberDetailsMenu
        totalOrder={memberAttributes?.totalOrder}
        totalComment={memberAttributes?.totalComment}
        totalReview={memberAttributes?.totalReview}
        totalWishlist={memberAttributes?.totalWishlist}
      />

      <div className="member-content">
        <ButtonBack />

        <div className="member-info">
          <div className="member-info__heading">Thông tin khách hàng</div>
          <div className="member-info__content">
            <div className="member-name">{memberAttributes?.name}</div>
            <div className="member-info__item">
              <div className="member-info__item-label">Ngày sinh:</div>
              <div className="member-info__item-value">
                {memberAttributes?.dob
                  ? moment(memberAttributes?.dob, DateFormat.serverSide).format(
                      DateFormat.clientSide,
                    )
                  : '-'}
              </div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-label">Email:</div>
              <div className="member-info__item-value">
                <span>{memberAttributes?.email || '-'}</span>
                {memberAttributes?.email && (
                  <CopyToClipboard
                    text={memberAttributes.email}
                    onCopy={(text, result) => text && result && message.success('Copy thành công')}
                  >
                    <div className="member-info-copy">
                      <img src={copyIcon} alt="copy-icon" />
                    </div>
                  </CopyToClipboard>
                )}
              </div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-label">Phone:</div>
              <div className="member-info__item-value">
                <span>{memberAttributes?.phone || '-'}</span>
                {memberAttributes?.phone && (
                  <CopyToClipboard
                    text={memberAttributes.phone}
                    onCopy={(text, result) => text && result && message.success('Copy thành công')}
                  >
                    <div className="member-info-copy">
                      <img src={copyIcon} alt="copy-icon" />
                    </div>
                  </CopyToClipboard>
                )}
              </div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-label">Tình trạng:</div>
              <div className="member-info__item-value">
                <span className={`member-status member-status--${memberAttributes?.status}`}>
                  {getCustomerLabelStatus(memberAttributes?.status)}
                </span>
                {memberAttributes?.status !== 'inactive' ? (
                  <ThemeButton
                    loading={isUpdating}
                    onClick={() => onUpdateAccountStatus('inactive')}
                  >
                    Khoá Account
                  </ThemeButton>
                ) : (
                  <ThemeButton
                    className="ant-btn theme-button medium theme-button--green"
                    loading={isUpdating}
                    onClick={() => onUpdateAccountStatus('active')}
                  >
                    Mở khoá Account
                  </ThemeButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutDetail>
  );
};

export default MemberDetails;
