import { Card, Pagination, Table } from 'antd';
import emptyImg from 'assets/img/empty.png';
import { IProduct, IProductAttribute } from 'interfaces/product.interface';
import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { useFetchTopProductComments } from '../../hook';
import Title from '../Title';

const TopProductComments: React.FC = () => {
  // Hook get top product comments
  const { isFetching, data, pagination, setPagination, fetchTopProductComments } =
    useFetchTopProductComments();

  /**
   * Handle the product list of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onPageChange = useCallback(
    async (page: number) => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchTopProductComments({ page });
    },
    [fetchTopProductComments],
  );

  // table
  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      width: 40,
      render: (_, __, index: number) => '#' + Number(index + 1),
    },
    {
      title: 'Hình ảnh',
      width: 100,
      dataIndex: 'attributes',
      className: 'text-center',
      render: (attr: IProductAttribute, row: IProduct) => {
        return (
          <NavLink to={`/san-pham/${row.id}`} target="_blank" className="product-image d-block">
            <img src={attr.image || emptyImg} alt={attr.name} />
          </NavLink>
        );
      },
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'attributes',
      render: (attr: IProductAttribute, row: IProduct) => {
        return (
          <NavLink to={`/san-pham/${row.id}`} target="_blank" className="product-link">
            <SanitizeHtml rawHtml={attr.name} className="product-name" title={attr.name} />
            <div className="product-sku">{`SKU: ${attr.sku}`}</div>
          </NavLink>
        );
      },
    },
    {
      title: 'Comment',
      dataIndex: 'attributes',
      className: 'text-center',
      width: 100,
      render: (attr: IProductAttribute) => <div className="text-center">{`${attr.count}`}</div>,
    },
  ];

  return (
    <Card className="dashboard-card">
      <div className="top-product-list">
        <div>
          <div className="dashboard-header mb-2">
            <Title text="Sản phẩm có nhiều comment nhất" />
          </div>

          <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={isFetching}
            pagination={false}
          />
        </div>

        {!!data?.length && (
          <div className="text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default TopProductComments;
