import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Radio, Row, Spin, Switch } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { IImage } from 'interfaces/image.interface';
import React, { useCallback, useState } from 'react';

import './styles.scss';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { uploadFile } from 'utils/utils';
import { v4 as uuid } from 'uuid';

interface Props {
  blockId: string;
  data: {
    blockId: string;
    images: ImageItem[];
  };
  onChange: (data: string) => void;
}

export interface ImageItem {
  title: string;
  link: string;
  target: '_blank' | 'not';
  position: string;
  show: boolean;
  desktopImage: IImage;
  mobileImage: IImage;
}

const INITIAL_DATA: Props['data'] = {
  blockId: '',
  images: [],
};

export const ProductDescriptionTemplate: React.FC<Props> = (props) => {
  const { blockId, data = INITIAL_DATA, onChange } = props;

  const [idUploading, setIdUploading] = useState<number | null>(null);

  const _onChange = useCallback(
    (values: Props['data']) => {
      onChange(JSON.stringify(values));
    },
    [onChange],
  );

  const onAddImageItem = useCallback(() => {
    _onChange({
      ...data,
      images: [
        {
          title: '',
          link: '/',
          position: '',
          target: '_blank',
          show: true,
          desktopImage: null,
          mobileImage: null,
        },
        ...(data?.images || []),
      ],
    });
  }, [data, _onChange]);

  const onValuesChange = useCallback(
    (fieldKey: keyof ImageItem, value: any, itemIndex: number) => {
      const currentImage = data.images[itemIndex];
      currentImage[fieldKey as any] = value;
      _onChange({ ...data, images: [...data.images] });
    },
    [data, _onChange],
  );

  const onUploadImage = useCallback(
    async (fieldKey: keyof ImageItem, value: RcFile, itemIndex: number) => {
      setIdUploading(itemIndex);

      await uploadFile(value, 'block', blockId, '', '0')
        .then((attr) =>
          onValuesChange(
            fieldKey,
            {
              ...attr,
              original: attr?.originUrl,
            },
            itemIndex,
          ),
        )
        .catch(() => onValuesChange(fieldKey, null, itemIndex));
      setIdUploading(null);
    },
    [onValuesChange, blockId],
  );

  return (
    <div className="product-description-template">
      <Col span={24}>
        <Form.Item label="Block Id (Optional)">
          <Input
            value={data.blockId}
            onChange={(e) => _onChange({ ...data, blockId: e.target.value })}
          />
        </Form.Item>
      </Col>

      <div className="text-right">
        <Button onClick={onAddImageItem} icon={<PlusOutlined />}>
          Thêm hình mô tả sản phẩm
        </Button>
      </div>

      {data.images.map((o, i) => {
        return (
          <div className="product-description-template__item" key={`${i}`}>
            {i > 0 && <Divider />}
            <span className="product-description-template__item-label">#{i + 1}</span>
            {idUploading === i && (
              <div className="loading-wrapper">
                <Spin />
                <span>Uploading...</span>
              </div>
            )}
            <Row gutter={16} className="mt-3 mb-3">
              <Col span={6}>
                <Form.Item label="Title">
                  <Input
                    value={o.title}
                    onChange={(e) => onValuesChange('title', e.target.value, i)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Link">
                  <Input
                    value={o.link}
                    onChange={(e) => onValuesChange('link', e.target.value, i)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Target">
                  <Radio.Group
                    onChange={(e) => onValuesChange('target', e.target.value, i)}
                    value={o.target}
                  >
                    <Radio value="_target">_target</Radio>
                    <Radio value="not">not</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Position">
                  <Input
                    value={o.position}
                    onChange={(e) => onValuesChange('position', e.target.value, i)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Hiển thị">
                  <Switch
                    checked={o.show}
                    onChange={(checked) => onValuesChange('show', checked, i)}
                  />
                </Form.Item>
              </Col>

              {blockId && (
                <Col span={24}>
                  <Form.Item label="Desktop Image/Video">
                    <UploadFile
                      uploadText="Click hoặc kéo thả hình/video để upload"
                      handleUploadImage={(file) => onUploadImage('desktopImage', file, i)}
                      typeId={uuid()}
                      fileAccept="image/*, video/*"
                      handleDeleteImageSuccess={() => onValuesChange('desktopImage', null, i)}
                      previewImage={o.desktopImage}
                    />
                  </Form.Item>
                </Col>
              )}

              {blockId && (
                <Col span={24}>
                  <Form.Item label="Mobile Image/Video">
                    <UploadFile
                      uploadText="Click hoặc kéo thả hình/video để upload"
                      handleUploadImage={(file) => onUploadImage('mobileImage', file, i)}
                      typeId={uuid()}
                      fileAccept="image/*, video/*"
                      handleDeleteImageSuccess={() => onValuesChange('mobileImage', null, i)}
                      previewImage={o.mobileImage}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col className="text-right" span={24}>
                <Button
                  className="mt-2"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    _onChange({
                      ...data,
                      images: data.images.filter((_, pi) => pi !== i),
                    });
                  }}
                >
                  Xóa
                </Button>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};
