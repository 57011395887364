import ErrorLayout from 'layouts/Error/Error';
import PrivateLayout from 'layouts/PrivateLayout/PrivateLayout';
import PublicLayout from 'layouts/PublicLayout/PublicLayout';
import PageSignIn from 'pages/authentifications/SignIn/SignIn';
import PublicPage from 'pages/PublicPage/PublicPage';
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppState } from 'redux/store';
import { getMe } from 'services/auth.service';
import TokenService from 'services/token.service';
import { SWRConfig } from 'swr';
import { confirmModal } from 'utils/modals';

import 'react-toastify/dist/ReactToastify.css';

import { privateRoutes } from './routes';

import './App.scss';
import './assets/sass/styles.scss';
import 'react-loading-skeleton/dist/skeleton.css';

const userData = (state: AppState) => state.user;
const tokenService = new TokenService();

const App: React.FC = () => {
  const { accessToken } = tokenService.getAccessToken();
  const { user } = useSelector(userData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  const getUserConfirmation = useCallback((message, callback) => {
    confirmModal({
      title: 'Rời trang hiện tại?',
      content: message,
      buttonText: 'Đồng ý',
      onSaveCb: () => {
        callback(true);
      },
    });
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>MaiNguyen - Back Office System</title>
      </Helmet>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
          }}
        >
          <Switch>
            <PublicPage path="/dang-nhap">
              <PublicLayout>
                <PageSignIn />
              </PublicLayout>
            </PublicPage>
            {user || accessToken ? (
              <PrivateLayout>
                {privateRoutes.map((item) => {
                  return (
                    <Route key={item.path} exact path={item.path} component={item.component} />
                  );
                })}
              </PrivateLayout>
            ) : (
              <Redirect to={{ pathname: '/dang-nhap' }} />
            )}
            <Route path="*" component={ErrorLayout} />
            <Redirect to="*" />
          </Switch>
        </SWRConfig>
      </BrowserRouter>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar closeOnClick />
    </div>
  );
};

export default App;
