import { Row, Col, Form } from 'antd';

// Import Components
import { usePresentBlocks } from 'hooks/usePresentBlocks';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { useCallback } from 'react';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { TemplateBlock } from 'ui/components/MaiNguyen/TemplateBlock';

import '../TrackingOrder/TrackingOrder.scss';

const PaymentOrderPresent = () => {
  const {
    reloadList,
    block,
    isOpen,
    handleClosePopup,
    handleOpenModalBlock,
    handleChangeValues,
    handleSaveBlock,
    handleChangeJsonData,
    handleUploadFileContent,
    handleDeleteImage,
    handleUpdateEditorBlock,
    onUpdatePaymentOrderBlock,
  } = usePresentBlocks();

  const [form] = Form.useForm();

  const handleChangeMainContent = useCallback(
    (value: string) => {
      handleUpdateEditorBlock(value);
    },
    [block],
  );

  return (
    <>
      <LayoutDetail
        pageTitle="Trình bày trang Thanh toán đơn hàng"
        breadcrumbs={[
          {
            title: 'Trình bày trang Thanh toán đơn hàng',
            route: '/trinh-bay-thanh-toan-don-hang',
          },
        ]}
        isAddNew
        wrapperCard={false}
        handleValuesChange={null}
        formHook={null}
        pageClassName="tracking-order-present"
        saveLoading={reloadList}
      >
        <Row gutter={16}>
          <Col span={24}>
            <div
              className="block-item"
              onClick={handleOpenModalBlock('paymentOrderInfo', 'paymentOrderInfo')}
            >
              Thông tin chuyển khoản
            </div>
          </Col>
        </Row>
      </LayoutDetail>
      <CustomPopup
        title="Display Block"
        isOpen={isOpen}
        onCloseModal={handleClosePopup}
        handleSaveButton={handleSaveBlock}
        formHook={form}
        handleValuesChange={handleChangeValues}
      >
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name">
                <div>{block?.attributes?.name}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Position">
                <div>{block?.attributes?.position}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Template">
                <div>{block?.attributes?.template}</div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <TemplateBlock
                block={block}
                template={block?.attributes?.template}
                onChangeJsonData={handleChangeJsonData}
                onAddImage={handleUploadFileContent}
                onDeleteImage={handleDeleteImage}
                handleChangeEditorContent={handleChangeMainContent}
                onUpdatePaymentOrderBlock={onUpdatePaymentOrderBlock}
              />
            </Col>
          </Row>
        </>
      </CustomPopup>
    </>
  );
};

export default PaymentOrderPresent;
