import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Button, Form, Input, Select } from 'antd';
import { clone, isEmpty, uniqBy } from 'lodash';
import { FC, useCallback, useEffect, useState, ChangeEvent } from 'react';
import { v4 as uuid } from 'uuid';

import './BoxColors.scss';

interface BoxColorsProps {
  attributeOptions: any[];
  attributeChildOptions?: any[];
  name?: string;
  onUpdateAttributeOptions?: (attributeOptions: number[]) => void;
}

export const BoxColors: FC<BoxColorsProps> = ({
  attributeOptions,
  attributeChildOptions,
  onUpdateAttributeOptions,
}) => {
  const [attrOptions, setAttrOptions] = useState<any[]>(attributeOptions);
  const [attrChildOptions, setAttrChildOptions] = useState<any[]>(attributeChildOptions);
  const [colors, setColors] = useState([]);
  const [currentColor, setCurrentColor] = useState(0);

  useEffect(() => {
    setAttrOptions(attributeOptions);
    setAttrChildOptions(attributeChildOptions);
  }, [attributeOptions, attributeChildOptions]);

  const handleAddMoreColor = useCallback(() => {
    const tempColor = clone(colors);
    const cloneAttrChildOptions = clone(attrChildOptions);

    tempColor.unshift({
      itemId: uuid(),
      id: '',
      shortDisplayName: '',
      attrType: 'color',
      variantList: cloneAttrChildOptions,
      variants: [],
      position: currentColor + 1,
    });
    setColors(tempColor);
    setCurrentColor(currentColor + 1);
    onUpdateAttributeOptions(tempColor);
  }, [colors, currentColor, attrChildOptions]);

  const handleDeleteColor = useCallback(
    (id: number) => () => {
      const tempColor = clone(colors);
      const selectedColor = tempColor.find((item) => id == item.itemId);
      const selectedColorIndex = tempColor.indexOf(selectedColor);
      if (selectedColorIndex > -1) {
        tempColor.splice(selectedColorIndex, 1);
      }
      setColors(tempColor);
      onUpdateAttributeOptions(tempColor);
    },
    [colors],
  );

  const handleChangeFieldValue = useCallback(
    (id: string, key: string) => (e) => {
      const tempColor = clone(colors);
      const curColor = tempColor.find((item) => item.itemId === id);
      const index = tempColor.indexOf(curColor);
      switch (key) {
        case 'id':
          if (isEmpty(e)) {
            const iptColor = document.querySelector('.ipt-color-' + id);
            const msgColor = document.querySelector('.msg-color-' + id);
            iptColor.classList.remove('err');
            msgColor.classList.remove('shw');
          }
          curColor.id = e;
          break;
        case 'shortDisplayName':
          curColor.shortDisplayName = e.target.value;
          break;
        default:
          break;
      }
      if (index > -1) {
        tempColor.splice(index, 1, curColor);
      }
      setColors(tempColor);
      onUpdateAttributeOptions(tempColor);
    },
    [colors],
  );

  const handleSelectDeselectAttributeOption = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: number, blkColor: any, variant: any) => {
      const clonedAttrOptions = clone(colors);
      const curColor = clonedAttrOptions.find((item) => blkColor.itemId == item.itemId);
      const index = clonedAttrOptions.indexOf(curColor);

      if (e.target.checked) {
        curColor.variants.push({
          attributeId: parseInt(variant.id),
          attributeOptionIds: [],
        });
        curColor.variants.forEach((color) => {
          if (color.attributeId === parseInt(variant.id)) {
            color.attributeOptionIds.push(value);
          }
        });
        curColor.variants = uniqBy(curColor.variants, 'attributeId');
      } else {
        // remove value
        curColor.variants.forEach(function (o) {
          o.attributeOptionIds = o.attributeOptionIds.filter((s) => s != value);
        });
      }

      if (curColor > -1) {
        clonedAttrOptions.splice(index, 1, curColor);
      }
      setColors(clonedAttrOptions);
      onUpdateAttributeOptions(clonedAttrOptions);
    },
    [onUpdateAttributeOptions],
  );

  return (
    <div className="box-attributes box-attributes-colors mb-4 mt-4">
      <div className="text-right">
        <Button className="mb-5" onClick={handleAddMoreColor} icon={<PlusOutlined />}>
          Thêm màu
        </Button>
      </div>
      {colors?.map((field) => {
        return (
          <Row
            gutter={24}
            className={`mb-4 pb-4 blk-color-${field?.itemId} blk-color-item`}
            key={`color-${field?.itemId}`}
          >
            <Col span={24}>
              <h3 className="blk-color-ttl"># SP màu {field?.position}</h3>
            </Col>
            <Col span={8}>
              <Form.Item label="Thuộc nhóm màu">
                <Select
                  size="large"
                  onChange={handleChangeFieldValue(field.itemId, 'id')}
                  className={`ipt-color ipt-color-${field?.itemId}`}
                  placeholder="Vui lòng chọn màu"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  listHeight={450}
                >
                  {attrOptions.map((item) => (
                    <Select.Option
                      key={`color-item-${field?.itemId}-${item?.value}`}
                      value={item.value}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                <span className={`sml-text msg-color-${field?.itemId}`}>
                  Vui lòng chọn màu sản phẩm
                </span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Tên màu">
                <Input
                  value={field?.label}
                  onChange={handleChangeFieldValue(field.itemId, 'shortDisplayName')}
                  placeholder="Tên màu thật của SP"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button
                title="Xóa"
                className="btn-color-del"
                onClick={handleDeleteColor(field?.itemId)}
                icon={<DeleteOutlined />}
              />
            </Col>
            {field?.variantList?.map((item) => {
              return (
                <Col span={24} key={'child-attr-' + item?.id}>
                  <h4 className="attr-title">{item?.title}</h4>
                  <div className="box-content">
                    {item?.attributes?.map((attr, pos) => {
                      return (
                        <div className="chk-item" key={`variant-${field.itemId}-${item.id}-${pos}`}>
                          <input
                            type="checkbox"
                            name={`variant-${field.itemId}-${item.id}-${pos}`}
                            id={`chk-${field.itemId}-${item.id}-${pos}`}
                            onChange={(e) => {
                              handleSelectDeselectAttributeOption(e, attr.value, field, item);
                            }}
                            //disabled={attr.disabled}
                          />
                          <label htmlFor={`chk-${field.itemId}-${item.id}-${pos}`}>
                            <span className="mask"></span>
                            <span className="name">{attr?.name}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
};
