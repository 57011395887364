import { Modal } from 'antd';
import { FC, ReactNode } from 'react';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';

interface CustomModalInterface {
  title: string;
  isOpen: boolean;
  children: ReactNode;
  onCloseModal: () => void;
  onSave?: () => void;
  position?: 'top' | 'center' | 'bottom';
  width?: string | number;
  loadingButton?: boolean;
  showFooter?: boolean;
  customFooter?: ReactNode;
  confirmText?: string;
}

export const CustomModal: FC<CustomModalInterface> = ({
  title,
  isOpen,
  onCloseModal,
  children,
  onSave = null,
  position = 'center',
  loadingButton = false,
  showFooter = true,
  width = 520,
  customFooter = null,
  confirmText = 'Lưu',
}) => {
  const renderPosition = (pos) => {
    switch (pos) {
      case 'top':
        return 'baseline';
      case 'center':
        return 'middle';
      case 'bottom':
        return 'bottom';
      default:
        return '';
    }
  };

  return (
    <Modal
      title={<strong>{title}</strong>}
      visible={isOpen}
      keyboard={false}
      width={width}
      footer={
        showFooter ? (
          customFooter ? (
            customFooter
          ) : (
            <ThemeButton loading={loadingButton} onClick={onSave}>
              {confirmText}
            </ThemeButton>
          )
        ) : null
      }
      maskClosable={false}
      centered
      style={{ verticalAlign: renderPosition(position) }}
      onCancel={onCloseModal}
    >
      {children}
    </Modal>
  );
};
