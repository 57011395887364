import { domainUrl } from '../constants';

export const API_GET_PRODUCTS = `${domainUrl}products`;
export const API_GET_PRODUCT_DETAIL = `${domainUrl}products/{id}`;
export const API_GET_PRODUCT_TYPES = `${domainUrl}products/types`;
export const API_GET_PRODUCT_ATTRIBUTE_GROUPS = `${domainUrl}products/{id}/attribute-groups`;
export const API_GET_RELATED_PRODUCTS = `${domainUrl}products/{id}/related-products`;
export const API_GET_RELATED_ACCESSORIES_PRODUCTS = `${domainUrl}products/{id}/related-products-2`;
export const API_GET_CROSS_SELL_PRODUCTS = `${domainUrl}products/{id}/cross-sell-products`;
export const API_GET_VARIANT_PRODUCTS = `${domainUrl}products/{id}/variant-products`;
export const API_VARIANT_PRODUCTS_POSITIONS = `${domainUrl}products/{id}/variant-positions`;
export const API_GET_SECONDHAND_PRODUCTS = `${domainUrl}products/{id}/secondhand-products`;
export const API_GET_GROUPED_PRODUCTS = `${domainUrl}products/{id}/grouped-products`;
export const API_RESTORE_DELETED_PRODUCT = `${domainUrl}products/{id}/restore`;
export const API_DELETE_PRODUCT_FOREVER = `${domainUrl}products/{id}/force-delete`;
export const API_COPY_PRODUCT = `${domainUrl}products/{id}/copy`;
export const API_GET_RELATED_ARTICLES = `${domainUrl}products/{id}/related-articles`;
export const API_GET_RELATED_RIDER_MODELS = `${domainUrl}products/{id}/related-models`;
// Settings
export const API_SETTINGS = `${domainUrl}settings`;
