import { Modal } from 'antd';
import { IProduct } from 'interfaces/product.interface';
import React from 'react';
import { CouponsTable } from '../CouponsTable';

interface Props {
  visible: boolean;
  onClose: () => void;
  data: IProduct[];
  onRemove: (productId: string) => void;
}

export const SelectedCouponsModal: React.FC<Props> = (props) => {
  const { visible, data, onRemove, onClose } = props;
  return (
    <Modal
      footer={null}
      visible={visible}
      title="Danh sách coupon đã chọn"
      onCancel={onClose}
      centered
      width={1000}
      className="base-modal"
    >
      <CouponsTable loading={false} data={data} onRemove={onRemove} scroll={{ y: 400, x: 500 }} />
    </Modal>
  );
};
