import { Col, Form, Input, Row, message } from 'antd';
import { ICollection } from 'interfaces/collection.interface';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsEditing } from 'redux/uiAction/actions';
import { useSWRConfig } from 'swr';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { API_COLLECTION } from 'constants/api/collection.api';
import { UploadImage } from './UploadImage';
import { useUpdateCollection } from '../hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  collection: ICollection;
}

export const SEOEditorModal: React.FC<Props> = (props) => {
  const { isOpen, collection, onClose } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isUpdating, onUpdate } = useUpdateCollection();
  const { mutate } = useSWRConfig();

  const seoData = collection?.attributes?.seo;

  useEffect(() => {
    if (isOpen) {
      const { keywords, description, image } = seoData || {};
      form.setFieldsValue({ keywords, description, image });
    }
  }, [collection.id, isOpen]);

  const onSubmit = useCallback(async () => {
    try {
      const values = form.getFieldsValue();

      const newData = await onUpdate({
        method: 'put',
        id: collection.id,
        data: { seo: values },
      });

      await mutate(API_COLLECTION.replace('{id}', collection.id), newData, {
        revalidate: false,
        rollbackOnError: true,
      });
      message.success('Cập nhật SEO thành công');
    } catch (error: any) {
      message.error(`${error?.message || error}`);
    } finally {
      dispatch(setIsEditing(false));
    }
  }, [collection.id]);

  return (
    <CustomPopup
      title="Nội Dung SEO"
      formHook={form}
      isOpen={isOpen}
      onCloseModal={onClose}
      isSaving={isUpdating}
      handleSaveButton={onSubmit}
      handleValuesChange={() => dispatch(setIsEditing(true))}
    >
      <Row className="seo-modal__content" gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Mô tả (Giới hạn trong 160 ký tự)"
            name="description"
            rules={[
              {
                required: true,
                max: 160,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject('Vui lòng nhập mô tả');
                  }
                  if (value.length > 160) {
                    return Promise.reject(`Vui lòng nhập mô tả dưới ${160} ký tự`);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Từ khoá"
            name="keywords"
            rules={[{ required: true, message: 'Vui lòng nhập từ khoá' }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="image"
            valuePropName="initialImage"
            trigger="onChange"
            label="OG Image (1200x630)"
          >
            <UploadImage
              uploadInfo={{
                type: 'collection',
                typeId: collection.id,
                property: 'ogImage',
              }}
              uploadText="Click hoặc kéo thả hình để upload OG:Image (1200x630)"
            />
          </Form.Item>
        </Col>
      </Row>
    </CustomPopup>
  );
};
