import React from 'react';
import Logo from 'layouts/components/Logo/Logo';

import './PublicLayout.scss';

const PublicLayout: React.FC = (props) => {
  return (
    <div className="public-layout">
      <div className="bg" />

      <div className="content-box">
        <div className="content-header">
          <Logo light width={250} />
        </div>

        <div className="content-body">{props.children}</div>
      </div>
    </div>
  );
};

export default PublicLayout;
