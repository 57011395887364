import { Button, Empty, SpinProps, Table, TablePaginationConfig } from 'antd';
import { isEmpty } from 'lodash';
import { FC } from 'react';
// Components
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { SortableTable } from 'ui/components/SortableTable/SortableTable';

import './BaseProductsTable.scss';

export const BrandLogoCell: FC<{ image?: string }> = ({ image }) => (
  <span className="brand-logo">
    <img src={image} width="50" height="50" />
  </span>
);

interface Props {
  columns: any[];
  dataList: any[];
  loading?: boolean | SpinProps;
  pagination?: false | TablePaginationConfig;
  showHeader?: boolean;
  sortable?: boolean;
  rowKey?: string;
  description?: string;
  buttonText?: string;
  className?: string;
  rowClassName?: string | ((record: any, index: number) => string);
  editableCell?: any;
  onAddClick?: () => void;
  BrandLogoCell?: (img: string) => JSX.Element;
  handleChangePosition?: (data: any[]) => void;
}

const BaseProductsTable: FC<Props> = ({
  rowKey = 'id',
  sortable = true,
  showHeader = false,
  pagination = false,
  description = '',
  buttonText = 'Thêm vào danh sách',
  loading,
  columns,
  dataList,
  className,
  rowClassName,
  onAddClick,
  editableCell = null,
  handleChangePosition = null,
}) => {
  return (
    <div className="products-table-wrapper">
      {onAddClick && (
        <div className="header-wrapper">
          <SanitizeHtml rawHtml={description} className="desc" />
          <Button onClick={onAddClick}>{buttonText}</Button>
        </div>
      )}

      {isEmpty(dataList) ? (
        <Empty description="Danh sách trống" />
      ) : (
        <>
          {sortable ? (
            <SortableTable
              editableCell={editableCell}
              columns={columns}
              dataList={dataList}
              rowKey={rowKey}
              scroll={{ y: 240, x: 500 }}
              onChanged={handleChangePosition}
              loading={loading}
            />
          ) : (
            <Table
              className={className}
              rowClassName={rowClassName}
              loading={loading}
              showHeader={showHeader}
              columns={columns}
              dataSource={dataList}
              rowKey={rowKey}
              pagination={pagination}
              scroll={{ y: 400, x: 500 }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default BaseProductsTable;
