import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

// Constant
import { API_GET_SIZE_GROUPS, API_SIZE_GROUP } from 'constants/api/size.api';

// Helpers
import { assignFilterValueToURL, createSizeGrp, updateSizeGrp } from 'helpers/systemSizes';

// Models
import { IImage } from 'interfaces/image.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { ISizeGroup } from 'interfaces/size.interface';
import * as api from 'services/api.service';
import { deleteModal } from 'utils/modals';
import { getApiParamsInGroupSystemSize, showErrorMsg } from 'utils/utils';

// Hook: get list of SizeGroups
export const useSizeGroups = (
  currentPage?: number,
  searchValue?: string,
  isPageSizeGrp?: boolean,
  pageSize?: number | string,
) => {
  const location = useLocation();
  const history = useHistory();

  const [sizeGroups, setSizeGroups] = useState<ISizeGroup[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IPagination>();

  // apply filter value to url
  const onAssignFilterValueToURL = useCallback((): void => {
    history.push(
      assignFilterValueToURL({
        url: location.pathname,
        page: currentPage,
        searchValue: searchValue,
        isFilterSizeItems: false,
      }),
    );
  }, [currentPage, searchValue]);

  // get list size groups
  const getSizeGroups = async () => {
    setLoading(true);
    try {
      // url params
      const apiParams = getApiParamsInGroupSystemSize({
        page: currentPage,
        searchName: searchValue,
      });
      const urlParams = pageSize
        ? pageSize !== 'all'
          ? `?${apiParams}&perPage=${pageSize}`
          : `?perPage=${pageSize}` // incase pageSize equals 'all' -> don't apply url param page=...
        : `?${apiParams}`;

      // response api
      const resSizeGroups = await api.get({
        endpoint: `${API_GET_SIZE_GROUPS}${urlParams}`,
      });

      const { data: sizeGroupsData, meta } = resSizeGroups;
      const { pagination: pagin } = meta;
      const { currentPage: curPage, perPage: pPage, total } = pagin || {};

      setSizeGroups(sizeGroupsData);
      setPagination({
        current: curPage,
        pageSize: pPage,
        total: total,
      });
      setLoading(false);
      // apply url params for only page size groups
      if (isPageSizeGrp) {
        onAssignFilterValueToURL();
      }
    } catch (error) {
      showErrorMsg(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSizeGroups();
  }, [currentPage, searchValue]);

  return {
    loading,
    sizeGroups,
    pagination,
    getSizeGroups,
  };
};

// Hook: delete SizeGroups
export const useDelSizeGroup = (getSizeGroups?: () => void) => {
  const [sizeGroup, setSizeGroup] = useState<ISizeGroup>(null);
  const [openModal, setOpenModal] = useState(false);

  // click show modal
  const showModal = (sizeGrp: ISizeGroup) => {
    setOpenModal(true);
    setSizeGroup(sizeGrp);
  };

  // click close modal
  const closeModal = () => {
    setOpenModal(false);
  };

  // delete size group
  const deleteSizeGrp = useCallback((sizeGroupId?: string) => {
    if (sizeGroupId) {
      deleteModal({
        title:
          'Việc xóa 1 GroupSize cần cân nhắc gắn SP size này lại hoặc Ẩn/Xóa SP đó đi! <br/>Bạn có muốn xóa GroupSize này?',
        onDeleteCb: async () => {
          try {
            await api.remove({
              endpoint: API_SIZE_GROUP.replace('{id}', sizeGroupId),
            });

            // refresh list size group
            getSizeGroups();
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    }
  }, []);

  return { sizeGroup, openModal, showModal, closeModal, deleteSizeGrp };
};

// Hook: Form SizeGroups
export const useFormSizeGrp = (sizeGroupItem?: ISizeGroup) => {
  const [errorForm, setErrorForm] = useState({
    content: false,
    uploadFile: false,
  });
  const [slugPath, setSlugPath] = useState('');
  const [description, setDescription] = useState('');
  // upload images
  const [previewImage, setPreviewImage] = useState<IImage>(null);
  const [imageFile, setImageFile] = useState(null);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [isUploadChange, setIsUploadChange] = useState(false);
  // form
  const [form] = Form.useForm();
  const [isSuccessForm, setIsSuccessForm] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  useEffect(() => {
    if (sizeGroupItem) {
      const { attributes } = sizeGroupItem;
      const { content, slug, image } = attributes;

      setSlugPath(slug);
      setDescription(content);
      setPreviewImage(image);
      setImageFile(image);
      setIsUploadFile(image ? true : false);

      form.setFieldsValue(attributes);
    }
  }, [sizeGroupItem]);

  // upload image
  const uploadImage = useCallback((file: RcFile) => {
    setImageFile(file);
    setIsUploadFile(true);
    setIsUploadChange(true);
    // reset validate upload file
    const errorFormState = {
      ...errorForm,
      uploadFile: false,
    };
    setErrorForm(errorFormState);
  }, []);

  // Delete image
  const deleteImage = () => {
    setImageFile(null);
    setPreviewImage(null);
    setIsUploadFile(false);
    setIsUploadChange(true);
  };

  // on change content editor
  const changeEditor = (value) => {
    setDescription(value != '<p><br></p>' ? sanitizeHtml(value) : '');
    // reset validate content
    const errorFormState = {
      ...errorForm,
      content: false,
    };
    setErrorForm(errorFormState);
  };

  // reset form
  const resetForm = () => {
    setImageFile(null);
    setPreviewImage(null);
    setSlugPath('');
    setDescription('');
    setIsUploadFile(false);
    setIsUploadChange(false);
    form.resetFields();
  };

  // submit form
  const submitForm = useCallback(async () => {
    const formValues = form.getFieldsValue();
    const { id } = sizeGroupItem;

    try {
      // validate upload file
      if (!isUploadFile) {
        const errorFormState = {
          ...errorForm,
          uploadFile: true,
        };
        setErrorForm(errorFormState);
        return;
      }

      // validate content
      if (!description) {
        const errorFormState = {
          ...errorForm,
          content: true,
        };
        setErrorForm(errorFormState);
        return;
      }
      setIsSuccessForm(false);
      setIsLoadingForm(true);
      const params = { ...formValues, content: description };

      if (id) {
        // Helper: update sizeGroup
        await updateSizeGrp(params, sizeGroupItem, isUploadFile, isUploadChange, imageFile);

        setIsSuccessForm(true);
        setIsLoadingForm(false);
      } else {
        // Helper: create sizeGroup
        await createSizeGrp(params, imageFile);

        setIsSuccessForm(true);
        setIsLoadingForm(false);
      }
    } catch (err) {
      showErrorMsg(err);
      setIsLoadingForm(false);
    }
  }, [description, sizeGroupItem, isUploadFile, isUploadChange]);

  return {
    form,
    slugPath,
    previewImage,
    errorForm,
    isSuccessForm,
    isLoadingForm,
    resetForm,
    submitForm,
    uploadImage,
    deleteImage,
    changeEditor,
  };
};
