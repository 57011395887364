import {
  CrossSellsProduct,
  IProductAttribute,
  ISuperAttribute,
  SubProduct,
} from 'interfaces/product.interface';

export class ProductModel implements IProductAttribute {
  type?: string;

  name?: string;

  sku?: string;

  slug?: string;

  attributeSetId?: number;

  brandId?: number;

  categoryId?: number;

  position?: number;

  discount?: number;

  active?: boolean;

  statusId?: number;

  storeIds?: Array<number>;

  attributeValues?: any[];

  superAttributes?: ISuperAttribute[];

  crossSells?: CrossSellsProduct[];

  relatedProducts?: SubProduct[];

  relatedProducts2?: SubProduct[];

  secondhandProductIds?: number[];

  groupedProducts?: {
    name: string;
    productId: number;
  }[];

  relatedArticles?: {
    articleId: number;
    position: number;
  }[];

  variantProducts?: {
    artributeCode: string;
    artributeValue: string;
    id: any;
  }[];

  relatedModels?: {
    modelId: number;
    position: number;
  }[];

  constructor({
    type,
    sku,
    name,
    slug,
    attributeSetId,
    brandId,
    storeIds,
    categoryId,
    active,
    position,
    discount,
    statusId,
    attributeValues,
    superAttributes,
    crossSells,
    relatedProducts,
    relatedProducts2,
    secondhandProductIds,
    groupedProducts,
    relatedArticles,
    variantProducts,
    relatedModels,
  }: IProductAttribute) {
    this.type = type || '';
    this.name = name || '';
    this.sku = sku || '';
    this.slug = slug || '';
    this.attributeSetId = attributeSetId || null;
    this.brandId = brandId || null;
    this.storeIds = storeIds || [];
    this.categoryId = categoryId || null;
    this.active = active !== undefined && active !== null ? active : false;
    this.statusId = statusId || null;
    this.attributeValues = attributeValues || [];
    this.superAttributes = superAttributes || [];
    this.crossSells = crossSells || [];
    this.relatedProducts = relatedProducts || [];
    this.relatedProducts2 = relatedProducts2 || [];
    this.relatedArticles = relatedArticles || [];
    this.secondhandProductIds = secondhandProductIds || [];
    this.groupedProducts = groupedProducts || [];
    this.variantProducts = variantProducts || [];
    this.relatedModels = relatedModels || [];
    position && (this.position = position);
    discount && (this.discount = discount);
  }
}
