import { fetcherWithArg } from 'services/swr.utils';
import useSWRMutation from 'swr/mutation';
import { API_GET_POST_ARTICLES } from 'constants/api/article.api';

export const useFetchArticles = () => {
  const { isMutating, data, trigger, reset } = useSWRMutation(
    API_GET_POST_ARTICLES,
    fetcherWithArg,
  );

  return {
    isFetching: isMutating,
    data: data?.data || [],
    refetch: trigger,
    reset,
  };
};
