import { IBrand, IBrandAttribute } from 'interfaces/brand.interface';

export class BrandModel implements IBrand {
  id?: string;

  type?: string;

  attributes?: IBrandAttribute;

  constructor({ id, type, attributes }: IBrand) {
    this.id = id || '';
    this.type = type || '';
    this.attributes = attributes || {
      name: '',
      description: '',
      active: false,
      slug: '',
      storeIds: [],
    };
  }
}
export class BrandModelAttribute implements IBrandAttribute {
  name?: string;

  description?: string;

  active?: boolean;

  slug?: string;

  storeIds?: Array<number>;

  constructor({ name, description, active, slug, storeIds }: IBrandAttribute) {
    this.name = name || '';
    this.description = description || '';
    this.active = active !== undefined && active !== null ? active : true;
    this.slug = slug || '';
    this.storeIds = storeIds || [];
  }
}
