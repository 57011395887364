import { PlusOutlined } from '@ant-design/icons';
import { Input, Tag, Tooltip } from 'antd';
import { clone } from 'lodash';
import { useState, useCallback, ChangeEvent, FC, useEffect } from 'react';

import './CustomTag.scss';

interface TagElementInterface {
  tag: string; // TODO
  index: number;
  handleRemoveTag: (tag: string) => void;
  handleShowEditTag: (tag: string, index: number) => void;
}

interface CustomTagInterface {
  tags: string[];
  handleUpdateTags: (tags: string[]) => void;
  buttonTitle?: string;
  showAddButton?: boolean;
}

const TagElement: FC<TagElementInterface> = ({
  tag,
  index,
  handleRemoveTag,
  handleShowEditTag,
}) => {
  const isLongTag = tag.length > 20;

  const onRemoveTag = useCallback(() => {
    handleRemoveTag(tag);
  }, [handleRemoveTag]);

  const onShowEditTag = useCallback(() => {
    handleShowEditTag(tag, index);
  }, [handleShowEditTag]);

  return !isLongTag ? (
    <Tag className="edit-tag" key={tag} closable onClose={onRemoveTag}>
      <span onClick={onShowEditTag}>{tag}</span>
    </Tag>
  ) : (
    <Tooltip title={tag} key={tag}>
      <Tag className="edit-tag" key={tag} closable onClose={onRemoveTag}>
        <span onClick={onShowEditTag}>{`${tag.slice(0, 20)}...`}</span>
      </Tag>
    </Tooltip>
  );
};

export const CustomTag: FC<CustomTagInterface> = ({
  tags,
  handleUpdateTags,
  buttonTitle = 'Thêm tag',
  showAddButton = true,
}) => {
  const [tagList, setTagList] = useState<string[]>(tags);
  const [showInputAdd, setShowInputAdd] = useState(false);
  const [editTagIndex, setEditTagIndex] = useState(-1);
  const [inputTagValue, setInputTagValue] = useState('');

  useEffect(() => {
    setTagList(tags);
  }, [tags]);

  const handleShowAddInputTag = useCallback(() => {
    setShowInputAdd(true);
  }, []);

  const handleChangeInputTag = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputTagValue(value);
  }, []);

  const handleShowEditableInputTag = useCallback((tag, index: number) => {
    setEditTagIndex(index);
    setInputTagValue(tag);
  }, []);

  const handleConfirmTag = useCallback(() => {
    if (inputTagValue) {
      const tagsList = clone(tagList);
      const editTag = tagsList[editTagIndex];
      if (editTag) {
        tagsList.splice(editTagIndex, 1, inputTagValue);
      } else {
        tagsList.push(inputTagValue);
      }
      handleUpdateTags && handleUpdateTags(tagsList);
      setShowInputAdd(false);
      setInputTagValue('');
      setEditTagIndex(-1);
    }
  }, [handleUpdateTags, inputTagValue, editTagIndex, tagList]);

  const handleDeleteTag = useCallback(
    (index: number) => () => {
      const tagsList = clone(tagList);
      if (index > -1) {
        tagsList.splice(index, 1);
      }
      handleUpdateTags && handleUpdateTags(tagsList);
    },
    [handleUpdateTags, tagList],
  );

  return (
    <div className="custom-tag-wrapper">
      {tagList?.map((tag: string, index: number) => {
        if (editTagIndex === index) {
          return (
            <Input
              key={tag}
              size="small"
              className="tag-input"
              value={inputTagValue}
              onChange={handleChangeInputTag}
              onBlur={handleConfirmTag}
              onPressEnter={handleConfirmTag}
            />
          );
        }
        return (
          <TagElement
            key={tag}
            tag={tag}
            index={index}
            handleRemoveTag={handleDeleteTag(index)}
            handleShowEditTag={handleShowEditableInputTag}
          />
        );
      })}
      {showInputAdd ? (
        <Input
          type="text"
          size="small"
          className="tag-input"
          value={inputTagValue}
          onChange={handleChangeInputTag}
          onBlur={handleConfirmTag}
          onPressEnter={handleConfirmTag}
        />
      ) : (
        showAddButton && (
          <Tag icon={<PlusOutlined />} className="site-tag-plus" onClick={handleShowAddInputTag}>
            {buttonTitle}
          </Tag>
        )
      )}
    </div>
  );
};
