import { Modal } from 'antd';
import { IProduct } from 'interfaces/product.interface';
import { IStatus } from 'interfaces/status.interface';
import { ProductTable } from 'pages/CollectionDetail/components/ProductTable';
import React from 'react';

interface Props {
  visible: boolean;
  onClose: () => void;
  data: IProduct[];
  subStatus: IStatus[];
  onRemove: (productId: string) => void;
}

export const SelectedProductsModal: React.FC<Props> = (props) => {
  const { visible, data, subStatus, onRemove, onClose } = props;
  return (
    <Modal
      footer={null}
      visible={visible}
      title="Danh sách sản phẩm đã chọn"
      onCancel={onClose}
      centered
      width={1000}
      className="base-modal"
    >
      <ProductTable
        loading={false}
        data={data}
        subStatus={subStatus}
        onRemove={onRemove}
        scroll={{ y: 400, x: 500 }}
      />
    </Modal>
  );
};
