import { Form, Row, Col, Input, Switch, Radio } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FC, useCallback } from 'react';
import { defaultJsonData } from 'constants/constants';
import { IHomeBlock } from 'interfaces/home-block.interface';

import { CustomJsonInput } from 'ui/components/MaiNguyen/CustomJsonEditor';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { showErrorMsg } from 'utils/utils';

import { HighEndBlock } from './HighEndBlock';
import { LogoBlock } from './LogoBlock';
import { MenuBlock } from './MenuBlock';
import { BankAccount, PaymentOrderBlock } from './PaymentOrderBlock';
import { ShockPriceBlock } from './ShockPriceBlock';
import { SpotLight } from './SpotLightBlock';
import { TopHeader, TopHeaderMessage } from './TopHeader';
import { CustomEditor } from '../CustomEditor';
import { CollectionsBlock, CollectionsTemplateBlock } from '../RiderTemplateBlock/CollectionsBlock';

interface TemplateBlockInterface {
  template: string;
  block: IHomeBlock;
  onAddImage?: (file: RcFile) => void;
  onDeleteImage?: () => void;
  onChangeJsonData?: (jsonData: any) => void;
  onUpdateSlider?: (slider: any[]) => void;
  onUpdateHighendBlock?: (data: any) => void;
  onUpdateShockPriceBlock?: (data: any) => void;
  onUpdatePaymentOrderBlock?: (data: BankAccount[]) => void;
  handleChangeEditorContent?: (data: any) => void;
  onChangeActive?: (active: any) => void;
  onUpdateMenu?: (data: any) => void;
  handleUpdateBlockLogo?: (data: any) => void;
  handleUpdateCollections?: (data: CollectionsTemplateBlock) => void;
  onUpdateTopHeaderBlock?: (data: TopHeaderMessage[]) => void;
}

export const TemplateBlock: FC<TemplateBlockInterface> = ({
  template,
  block,
  onAddImage,
  onDeleteImage,
  onChangeJsonData,
  onUpdateSlider,
  onUpdateHighendBlock,
  onUpdateShockPriceBlock,
  handleChangeEditorContent,
  onChangeActive,
  onUpdateMenu,
  handleUpdateBlockLogo,
  onUpdatePaymentOrderBlock,
  handleUpdateCollections,
  onUpdateTopHeaderBlock,
}) => {
  const handleChangeJsonValue = useCallback((value) => {
    onChangeJsonData(value);
  }, []);

  const handleChangeActive = useCallback((value) => {
    onChangeActive(value);
  }, []);

  const renderDefaultData = useCallback(
    (key: string) => {
      let data = defaultJsonData[key] || [];
      if (block?.attributes?.data) {
        try {
          data = JSON.parse(block.attributes.data);
        } catch (err) {
          showErrorMsg(err);
        }
      }
      return data;
    },
    [block],
  );

  const renderTemplateContent = useCallback(
    (tmpl: string) => {
      switch (tmpl) {
        case 'logo':
          const data = renderDefaultData('logo');
          const { title, subTitle, target, urlPath } = data || {};
          let imageTitle = 'Image';
          if (block.id === 'homeBannerMid') {
            imageTitle = 'Image (2800x200)';
          } else if (
            block.id === 'bannerMidSmall1' ||
            block.id === 'bannerMidSmall2' ||
            block.id === 'bannerMidSmall3'
          ) {
            imageTitle = 'Image (912x200)';
          }

          if (block.id === 'headerLogo' || block.id === 'footerLogo') {
            return (
              <LogoBlock
                blockId={block?.attributes?._id?.toString()}
                data={data}
                logoData={{
                  blackLogo: data?.blackLogo,
                  whiteLogo: data?.whiteLogo,
                }}
                onChange={handleUpdateBlockLogo}
              />
            );
          }

          return (
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Tiêu đề" name="title" initialValue={title}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Url path" name="urlPath" initialValue={urlPath}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Target" name="target" initialValue={target}>
                  <Radio.Group>
                    <Radio value="_blank">_blank</Radio>
                    <Radio value="not">not</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Tiêu đề phụ" name="subTitle" initialValue={subTitle}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={imageTitle}>
                  <UploadFile
                    previewImage={block?.attributes?.image}
                    handleUploadImage={onAddImage}
                    handleDeleteImageSuccess={onDeleteImage}
                    maxWidth={600}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        case 'homeSpotlightBanner':
        case 'banner':
          return (
            <SpotLight
              blockId={block?.attributes?._id?.toString()}
              dataSlider={renderDefaultData('homeSpotlightRightBanner')}
              onUpdateSlider={onUpdateSlider}
            />
          );
        case 'homeCollections':
          return (
            <CollectionsBlock
              blockId={block?.attributes?._id?.toString()}
              data={renderDefaultData(tmpl)}
              onChange={handleUpdateCollections}
            />
          );
        case 'homeSpotlightRightBanner':
          return (
            <SpotLight
              blockId={block?.attributes._id.toString()}
              dataSlider={renderDefaultData('homeSpotlightRightBanner')}
              onUpdateSlider={onUpdateSlider}
              maxCount={2}
            />
          );
        case 'homeSpotlightBadges':
          return (
            <SpotLight
              blockId={block?.attributes._id.toString()}
              dataSlider={renderDefaultData('homeSpotlightBadges')}
              hasMobileImage={false}
              onUpdateSlider={onUpdateSlider}
              uploadTextDesktop="Click hoặc kéo thả hình để upload (200x200)"
              imageWidthDesktop={200}
              onChange={handleChangeActive}
              block={block}
            />
          );
        case 'productTabs':
          return (
            <HighEndBlock
              blockId={block.id}
              blockName="vuacohang"
              blockData={block?.attributes?.data}
              handleUpdateBlockData={onUpdateHighendBlock}
            />
          );
        case 'homeShockPrice':
          return (
            <ShockPriceBlock
              blockId={block.id}
              blockName="shockPrice"
              blockData={block?.attributes?.data}
              handleUpdateBlockData={onUpdateShockPriceBlock}
            />
          );
        case 'topHeaderMsg':
          return <TopHeader data={renderDefaultData(tmpl)} onChange={onUpdateTopHeaderBlock} />;
        case 'footerMenus':
          return (
            <Form.Item label="Hiển thị" name="active" valuePropName="checked">
              <Switch />
            </Form.Item>
          );
        case 'metaData':
          return (
            <>
              <Form.Item label="Image">
                <UploadFile
                  previewImage={block?.attributes?.image}
                  handleUploadImage={onAddImage}
                  handleDeleteImageSuccess={onDeleteImage}
                  maxWidth={600}
                />
              </Form.Item>
              <CustomJsonInput
                value={renderDefaultData(tmpl)}
                onChange={handleChangeJsonValue}
                template={tmpl}
              />
            </>
          );
        case 'paymentOrderInfo':
          return (
            <PaymentOrderBlock
              blockId={block?.attributes?._id?.toString()}
              data={renderDefaultData(tmpl)}
              onChange={onUpdatePaymentOrderBlock}
            />
          );

        case 'riderCopyright':
          return (
            <>
              <CustomEditor
                mainContent={block?.attributes?.data}
                handleChangeMainContent={handleChangeEditorContent}
              />
            </>
          );
        case 'riderFooterMenu1':
        case 'riderFooterMenu2':
          const { headingTitle, menuItems } = renderDefaultData(tmpl);
          return (
            <MenuBlock
              blockId={block?.attributes?._id?.toString()}
              headingTitle={headingTitle}
              dataMenuItems={menuItems}
              onUpdateMenu={onUpdateMenu}
            />
          );
        default:
          return (
            <CustomJsonInput
              value={renderDefaultData(tmpl)}
              onChange={handleChangeJsonValue}
              template={tmpl}
            />
          );
      }
    },
    [block],
  );

  return renderTemplateContent(template);
};
