import { DeleteOutlined } from '@ant-design/icons';
import { Form, Select, Spin, message, Button } from 'antd';
import { clone, debounce, sortBy, findIndex } from 'lodash';
import { useCallback, useEffect, useMemo, useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  //useHistory,
  useParams,
} from 'react-router-dom';

import { API_GET_POST_ARTICLES } from 'constants/api/article.api';
import { IArticle, IArticleAttribute } from 'interfaces/article.interface';
import { IProduct } from 'interfaces/product.interface';
import { ArticleModel } from 'models/article.model';
import BaseProductsTable from 'pages/Products/ProductDetail/components/BaseProductsTable/BaseProductsTable';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { showErrorMsg } from 'utils/utils';

const newArticle = new ArticleModel({});

const getListWithPosition = (list: any[], sortedList: any[], conditionKey: string) => {
  const clonedList = clone(list);
  clonedList.forEach((item) => {
    if (sortedList.find((srt) => srt[conditionKey] === item.id)) {
      item.position = sortedList.find((srt) => srt[conditionKey] === item.id).position;
    }
  });

  return sortBy(clonedList, 'position');
};

interface ProductRelatedArticlesProps {
  relatedArticles?: IArticle[];
  handleUpdateSelectedArticles?: (articles: IArticle[]) => void;
}

export const ProductRelatedArticles: FC<ProductRelatedArticlesProps> = ({
  relatedArticles,
  handleUpdateSelectedArticles,
}) => {
  const { id: productId } = useParams<{ id: string }>();
  const isAddNew = productId === 'new';

  const [article, setArticle] = useState<IArticleAttribute>(newArticle);
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [selectedArticleId, setSelectedArticleId] = useState<number>(null);
  const [relaArticles, setRelaArticles] = useState<IArticle[]>([]);
  const [loadArticles, setLoadArticles] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAddNew) {
      const sortedRelatedArticles = sortBy(relatedArticles, 'position');
      const currentArticle = new ArticleModel({
        ...relatedArticles['attributes'],
        relatedArticles: sortedRelatedArticles,
      });
      const relateArticles = relatedArticles?.filter((item) => item.type === 'articles') || [];
      setRelaArticles(getListWithPosition(relateArticles, sortedRelatedArticles, 'articleId'));
      setArticle(currentArticle);
      form.setFieldsValue(currentArticle);
    }
  }, [relatedArticles]);

  const handleChangeDataPosition = useCallback(
    (dataType: string) => (changedData: IProduct[] | IArticle[]) => {
      dispatch(setIsEditing(true));
      const currentArticle = new ArticleModel(article);
      switch (dataType) {
        case 'articles':
          setRelaArticles(changedData as IArticle[]);
          handleUpdateSelectedArticles(changedData as IArticle[]);
          currentArticle.relatedArticles = changedData.map((item, index) => ({
            articleId: parseInt(item.id),
            position: index + 1,
          }));
          break;
        default:
          break;
      }
      setArticle(currentArticle);
    },
    [article],
  );

  const handleSearchArticles = useMemo(() => {
    const fetchArticles = async (value: string) => {
      setLoadArticles(true);
      if (value) {
        await api
          .get({
            endpoint: `${API_GET_POST_ARTICLES}?page=1&name=${value}`,
          })
          .then((res) => {
            setLoadArticles(false);
            setArticles(res?.data);
          })
          .catch((err) => {
            setLoadArticles(false);
            setArticles([]);
            showErrorMsg(err);
          });
      } else {
        setLoadArticles(false);
        setArticles([]);
      }
    };
    return debounce(fetchArticles, 800);
  }, []);

  const handleSelectArticle = useCallback(
    (id: number) => {
      dispatch(setIsEditing(true));
      const currentArticle = new ArticleModel(article);
      const clonedRelaArticles = clone(relaArticles);

      if (
        !currentArticle.relatedArticles.find((item) => item.articleId === id) &&
        !clonedRelaArticles.find((item) => parseInt(item.id) === id)
      ) {
        currentArticle.relatedArticles.push({
          articleId: id,
          position: currentArticle.relatedArticles.length + 1,
        });
        clonedRelaArticles.push(articles.find((item) => parseInt(item.id) === id));
        setSelectedArticleId(id);
        setRelaArticles(clonedRelaArticles);
        setArticle(currentArticle);
        handleUpdateSelectedArticles(clonedRelaArticles);
      } else {
        message.error('Bài viết đã tồn tại trong danh sách', 3);
      }
    },
    [article, articles, relaArticles],
  );

  const handleDeleteArticle = useCallback(
    (deleteArticle: IArticle) => () => {
      dispatch(setIsEditing(true));
      const currentArticle = new ArticleModel(article);
      const clonedRelaArticles = clone(relaArticles);
      const relaArticleIndex = clonedRelaArticles.findIndex((item) => item.id === deleteArticle.id);
      const articleRelaProductIndex = findIndex(currentArticle.relatedArticles, function (item) {
        return item['id'] == deleteArticle.id;
      });
      if (relaArticleIndex > -1 && articleRelaProductIndex > -1) {
        clonedRelaArticles.splice(relaArticleIndex, 1);
        currentArticle.relatedArticles.splice(articleRelaProductIndex, 1);
      }
      setRelaArticles(clonedRelaArticles);
      setArticle(currentArticle);
      handleUpdateSelectedArticles(clonedRelaArticles);
    },
    [article, relaArticles],
  );

  const relatedArticlesColumns = [
    {
      title: 'STT',
      dataIndex: 'key',
      width: 70,
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tiêu đề bài viết',
      dataIndex: 'attributes',
      render: (attr: IArticleAttribute) => <SanitizeHtml rawHtml={attr.name} />,
    },
    {
      title: 'Thao tác',
      width: 100,
      render: (row: IArticle) => (
        <Button title="Xóa" icon={<DeleteOutlined />} onClick={handleDeleteArticle(row)} />
      ),
    },
  ];

  return (
    <>
      <Form.Item label="Tìm bài viết">
        <Select
          size="large"
          showSearch
          optionFilterProp="children"
          allowClear
          notFoundContent={loadArticles ? <Spin size="small" /> : null}
          onSearch={handleSearchArticles}
          placeholder="Nhập tên để tìm"
          onSelect={handleSelectArticle}
          value={selectedArticleId}
        >
          {articles.map((item) => (
            <Select.Option key={item.id} value={parseInt(item.id)}>
              {item?.attributes?.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <BaseProductsTable
        dataList={relaArticles}
        columns={relatedArticlesColumns}
        sortable
        handleChangePosition={handleChangeDataPosition('articles')}
      />
    </>
  );
};
