/* eslint-disable @typescript-eslint/no-use-before-define */
import { IPagination } from 'interfaces/pagination.interface';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { updatePageData } from 'redux/pages/actions';
import { fetcherWithArg } from 'services/swr.utils';
import useSWRMutation from 'swr/mutation';
import { buildParams } from 'utils/utils';
import { API_GET_POST_COMMENTS } from 'constants/api/comment.api';
import { defaultPagination } from 'constants/constants';
import { directionList } from 'constants/enum';
import { FilterCommentsPage } from './Components/Filter';

/**
 * Custom hook that fetches and returns events page data.
 */
export const useFetchComments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id: memberId } = useParams<{ id: string }>();
  const { data, isMutating, trigger } = useSWRMutation(API_GET_POST_COMMENTS, fetcherWithArg);

  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  useEffect(() => {
    if (data?.meta?.pagination) {
      setPagination((prev) => ({
        ...prev,
        current: data.meta.pagination.currentPage,
        total: data.meta.pagination.total,
        pageSize: data.meta.pagination.perPage,
      }));
    }
  }, [data?.meta?.pagination]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    dispatch(updatePageData({ loaded: true, fullFilled: true }));
    fetch(buildParams(isEmpty(params) ? {} : params));
  }, []);

  const fetch = useCallback(
    async (
      params?: Partial<Omit<FilterCommentsPage, 'page'>> & {
        page?: number;
      },
    ): Promise<void> => {
      const locationParams = queryString.parse(location.search);
      const {
        page = pagination.current,
        status = locationParams?.status || memberId ? 1 : 0, // case page member comment will show default status = 1
        direction = locationParams?.direction || directionList[0].value,
        ...rest
      } = params || {};

      const calcPage =
        pagination.total % pagination.pageSize === 1 && data?.meta?.pagination?.currentPage > 1
          ? page - 1
          : page;

      const query = {
        ...locationParams,
        page: calcPage,
        perPage: defaultPagination.pageSize,
        status,
        direction,
        customerId: memberId || undefined, // case filter by memberId
        ...rest,
      };

      // handle delete param url
      const _query = query;
      if (!memberId) {
        // case page comments -> remove param direction
        delete _query.direction;
      }

      history.push(
        queryString.stringifyUrl(
          {
            url: location.pathname,
            query: _query,
          },
          { skipEmptyString: true, skipNull: true },
        ),
      );

      await trigger(buildParams(query));
    },
    [pagination.current, pagination.total, data?.meta?.pagination?.currentPage, location.search],
  );

  return {
    isFetching: isMutating,
    data: data?.data || [],
    pagination,
    setPagination,
    fetch,
  };
};
