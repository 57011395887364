import { Form } from 'antd';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Constant
import { API_GET_SIZES, API_SIZE, API_SIZE_GROUP } from 'constants/api/size.api';

// Helpers
import { assignFilterValueToURL } from 'helpers/systemSizes';

// Models
import { IPagination } from 'interfaces/pagination.interface';
import { ISize } from 'interfaces/size.interface';
import { newSizeItem } from 'pages/ProductAttributes/SystemSize/SizeItems/SizeItems';
import * as api from 'services/api.service';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';

export type FormFilterProduct = {
  title: string;
  sizeGroupId: string;
};

export const defaultFilterParams = {
  title: '',
  sizeGroupId: '',
};

// Hook: get list of SizeItems
export const useSizeItems = (
  currentPage?: number,
  formFilter?: FormFilterProduct,
  isReloading?: boolean,
) => {
  const history = useHistory();
  const location = useLocation();

  // list size list
  const [sizeList, setSizeList] = useState<ISize[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IPagination>();

  // get url params
  const getSizesApiParams = useMemo(() => {
    const { title, sizeGroupId } = formFilter;

    return queryString.stringify(
      {
        page: currentPage,
        title: title,
        sizeGroupId: sizeGroupId,
      },
      { skipEmptyString: true },
    );
  }, [currentPage, formFilter]);

  // apply filter value to url
  const onAssignFilterValueToURL = () => {
    const { title, sizeGroupId } = formFilter;

    history.push(
      assignFilterValueToURL({
        url: location.pathname,
        page: currentPage,
        searchValue: title,
        sizeGroupId: sizeGroupId,
        isFilterSizeItems: true,
      }),
    );
  };

  // get page data
  const getSizeItems = async () => {
    try {
      setLoading(true);
      const resSizeItems = await api.get({
        endpoint: `${API_GET_SIZES}?${getSizesApiParams}`,
      });

      const { data: sizeItemsData, meta } = resSizeItems;
      const { pagination: pagin } = meta;
      const { currentPage: curPage, perPage, total } = pagin || {};
      setSizeList(sizeItemsData);
      setPagination({
        current: curPage,
        pageSize: perPage,
        total: total,
      });
      onAssignFilterValueToURL();
      setLoading(false);
    } catch (err) {
      showErrorMsg(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSizeItems();
  }, [currentPage, formFilter]);

  useEffect(() => {
    if (isReloading) {
      getSizeItems();
    }
  }, [isReloading]);

  return {
    sizeList,
    loading,
    pagination,
    getSizeItems,
  };
};

// Hook: modal SizeItem
export const useModalSizeItem = () => {
  const [sizeItem, setSizeItem] = useState<ISize>(null);
  const [openModal, setOpenModal] = useState(false);

  const showModal = (size: ISize) => {
    setSizeItem(size);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return {
    sizeItem,
    openModal,
    showModal,
    closeModal,
  };
};

// Hook: delete SizeItem
export const useDelSizeItem = () => {
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteSizeItem = useCallback((attrId: string) => {
    deleteModal({
      title:
        'Việc xóa 1 Size cần cân nhắc gắn SP size này lại hoặc Ẩn/Xóa SP đó đi! <br/>Bạn có muốn xóa Size này?',
      onDeleteCb: async () => {
        try {
          setIsDeleting(false);
          await api.remove({
            endpoint: API_SIZE.replace('{id}', attrId),
          });

          // refresh list
          setIsDeleting(true);
          setTimeout(() => {
            setIsDeleting(false);
          }, 500);
        } catch (err) {
          showErrorMsg(err);
        }
      },
    });
  }, []);

  return {
    isDeleting,
    deleteSizeItem,
  };
};

// Hook: sort SizeItem
export const useSortSizeItem = () => {
  const [isSorting, setIsSorting] = useState(false);

  const changePosition = useCallback(
    (rowData: ISize) => async (pos: number) => {
      const { id } = rowData;

      try {
        setIsSorting(false);
        await api.put({
          endpoint: API_SIZE.replace('{id}', id),
          data: { position: pos },
        });

        // refresh list
        setIsSorting(true);
        setTimeout(() => {
          setIsSorting(false);
        }, 500);
      } catch (err) {
        showErrorMsg(err);
      }
    },
    [],
  );

  return {
    isSorting,
    changePosition,
  };
};

// Hook: Form SizeItems
export const useFormSizeItem = (sizeItem?: ISize) => {
  const [slugPath, setSlugPath] = useState('');
  const [fieldList, setFieldList] = useState([]);

  // form
  const [form] = Form.useForm();
  const [isSuccessForm, setIsSuccessForm] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  useEffect(() => {
    if (sizeItem) {
      const { slug, data } = sizeItem.attributes;

      setSlugPath(slug);
      setFieldList(data);
      form.setFieldsValue(sizeItem.attributes);
    }
  }, [sizeItem]);

  // click reset form
  const resetForm = () => {
    // reset form
    setFieldList([]);
    form.resetFields();
    form.setFieldsValue(newSizeItem);
  };

  // on change size group
  const changeSizeGroup = useCallback(
    async (id) => {
      setFieldList([]);
      const resSizeGroup = await api.get({ endpoint: `${API_SIZE_GROUP.replace('{id}', id)}` });
      const { attributes } = resSizeGroup.data;
      const { fields } = attributes;

      setFieldList(fields);
      form.setFieldsValue({ ...form.getFieldsValue(), data: fields });
    },
    [fieldList],
  );

  // Submit size group
  const submitForm = useCallback(async () => {
    try {
      const { id } = sizeItem;
      const formValues = form.getFieldsValue();

      setIsSuccessForm(false);
      setIsLoadingForm(true);

      if (id) {
        // case update size item
        await api.put({
          endpoint: API_SIZE.replace('{id}', id),
          data: formValues,
        });

        resetForm();
        setIsSuccessForm(true);
        setIsLoadingForm(false);
      } else {
        // case create size item
        await api.post({ endpoint: API_GET_SIZES, data: formValues });

        resetForm();
        setIsSuccessForm(true);
        setIsLoadingForm(false);
      }
    } catch (err) {
      showErrorMsg(err);
      setIsLoadingForm(false);
    }
  }, [sizeItem]);

  return {
    form,
    slugPath,
    fieldList,
    isLoadingForm,
    isSuccessForm,
    changeSizeGroup,
    submitForm,
    resetForm,
  };
};

export const useFilterSizeItems = (
  formFilter?: FormFilterProduct,
  onFilterChange?: (val) => void,
) => {
  // form
  const [frmFilterSizeItems] = Form.useForm();

  useEffect(() => {
    if (formFilter) {
      frmFilterSizeItems.setFieldsValue(formFilter);
    }
  }, [formFilter]);

  // submit filter
  const submitFilter = () => {
    onFilterChange(frmFilterSizeItems.getFieldsValue());
  };

  // clear filter
  const clearFilter = () => {
    frmFilterSizeItems.resetFields();
    onFilterChange(defaultFilterParams);
  };

  return { frmFilterSizeItems, submitFilter, clearFilter };
};
