import { ISinglePageAttribute } from 'interfaces/single-page.interface';

export class SinglePageModel implements ISinglePageAttribute {
  title?: string;
  slug?: string;
  content?: string;
  keywords?: string;
  metaDescription?: string;
  active?: boolean;

  constructor({ title, slug, content, keywords, metaDescription, active }: ISinglePageAttribute) {
    this.title = title || '';
    this.slug = slug || '';
    this.content = content || '';
    this.keywords = keywords || '';
    this.metaDescription = metaDescription || '';
    this.active = active || true;
  }
}
