import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';

type confirmModalType = {
  title: string;
  buttonText?: string;
  content?: string;
  onSaveCb: () => void;
};

type deleteModalType = {
  title: string;
  onDeleteCb: () => void;
};

export const confirmModal = ({
  title,
  onSaveCb,
  buttonText = 'Lưu',
  content = '',
}: confirmModalType) => {
  Modal.confirm({
    title: <SanitizeHtml rawHtml={title} allowedTags={['strong']} />,
    content: <SanitizeHtml rawHtml={content} />,
    icon: <ExclamationCircleOutlined style={{ color: '#666' }} />,
    okText: buttonText,
    okType: 'default',
    cancelText: 'Không',
    onOk: onSaveCb,
  });
};

export const deleteModal = ({ title, onDeleteCb }: deleteModalType) => {
  Modal.confirm({
    title: <SanitizeHtml rawHtml={title} allowedTags={['strong', 'br']} />,
    icon: <ExclamationCircleOutlined style={{ color: '#666' }} />,
    okText: 'Xóa',
    okType: 'danger',
    cancelText: 'Không',
    onOk: onDeleteCb,
  });
};
