import { DiscountUnit } from 'constants/enum';
import { IAttrPromotion, IFilterPromotion } from 'interfaces/promotion.interface';

export class PromotionModel implements IAttrPromotion {
  name?: string | null;

  description?: string | null;

  catalogText?: string | null;

  startDate?: string | null;

  endDate?: string | null;

  limitTotal?: number | null;

  limitInDay?: number | null;

  discount?: number | null;

  discountUnit?: DiscountUnit;

  giftOptions?: string[];

  active?: boolean;

  productIds?: number[];

  brandIds?: number[];

  constructor({
    name,
    description,
    catalogText,
    startDate,
    endDate,
    limitTotal,
    limitInDay,
    discount,
    discountUnit,
    giftOptions,
    active,
    productIds,
    brandIds,
  }: IAttrPromotion) {
    this.name = name || '';
    this.description = description || '';
    this.catalogText = catalogText || '';
    this.startDate = startDate || null;
    this.endDate = endDate || null;
    this.limitTotal = limitTotal || null;
    this.limitInDay = limitInDay || null;
    this.discount = discount || 0;
    this.discountUnit = discountUnit || DiscountUnit.VND;
    this.giftOptions = giftOptions || [];
    this.active = active !== undefined && active !== null ? active : false;
    this.productIds = productIds || [];
    this.brandIds = brandIds || [];
  }
}

export class FilterPromotionModel implements IFilterPromotion {
  name: string;

  sku: string;

  constructor({ name, sku }: IFilterPromotion) {
    this.name = name || '';
    this.sku = sku || '';
  }
}
