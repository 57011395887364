import { IAttrAttributeOption } from 'interfaces/attr-option.interface';

export class AttributeOptionModel implements IAttrAttributeOption {
  attributeId?: number;

  name?: string;

  position?: number;

  value?: string;

  constructor({ attributeId, name, position, value }: IAttrAttributeOption) {
    this.attributeId = attributeId || null;
    this.name = name || '';
    this.value = value || '';
    this.position = position || 1;
  }
}
