import { Card, Pagination, Table } from 'antd';
import { Customer } from 'interfaces/customer.interface';
import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { addVnCurrency } from 'utils/utils';
import { useFetchCustomerTopOrders } from '../../hook';
import Title from '../Title';

const CustomerTopOrders: React.FC = () => {
  // Hook get top customer orders
  const { isFetching, data, pagination, setPagination, fetchCustomerTopOrders } =
    useFetchCustomerTopOrders();

  /**
   * Handle the top orders list of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onPageChange = useCallback(
    async (page: number) => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchCustomerTopOrders({ page });
    },
    [fetchCustomerTopOrders],
  );

  // table
  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      width: 40,
      render: (_, __, index: number) => '#' + Number(index + 1),
    },
    {
      title: 'Tên khách hàng',
      render: (customer: Customer) => (
        <NavLink
          className="member-info-link"
          to={{
            pathname: `/danh-sach-members/${customer.id}`,
          }}
          target="_blank"
        >
          <div className="member-info__name">{customer.attributes.name}</div>
        </NavLink>
      ),
    },
    {
      title: 'Tổng đơn',
      render: (customer: Customer) => (
        <div className="user-purchase">
          <div className="user-purchase__total-amount">
            {addVnCurrency(customer.attributes.totalSpent)}
          </div>
        </div>
      ),
    },
  ];

  return (
    <Card className="dashboard-card">
      <div className="top-product-list top-orders">
        <div>
          <div className="dashboard-header mb-2">
            <Title text="Thành viên mua nhiều năm 2024" />
          </div>

          <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={isFetching}
            pagination={false}
          />
        </div>

        {!!data?.length && (
          <div className="text-right">
            <Pagination
              {...pagination}
              pageSizeOptions={[]}
              onChange={onPageChange}
              disabled={isFetching}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default CustomerTopOrders;
