import { DeleteOutlined } from '@ant-design/icons';
import { Form, Button, Input, Row, Col, Switch } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { API_GET_DETAIL_RIDER_TYPES, API_GET_RIDER_TYPES } from 'constants/api/riderType.api';
import { defaultPagination } from 'constants/constants';
import { AlignType } from 'constants/enum';
import { IPagination } from 'interfaces/pagination.interface';
import { IRiderTypes, IRiderTypesAttribute } from 'interfaces/riderType';
import { RiderTypesModel } from 'interfaces/riderTypes.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';

const newRiderTypes = new RiderTypesModel({});

const RiderType = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [modalTitle, setModalTile] = useState('Thêm Mới Phong cách');
  const [riderTypesList, setRiderTypesList] = useState<IRiderTypes[]>([]);
  const [riderType, setRiderType] = useState(newRiderTypes);
  const [riderTypeId, setRiderTypeId] = useState('');
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
    setModalTile('Thêm Mới Phong cách');
    setRiderType(newRiderTypes);
    setRiderTypeId('');
    form.setFieldsValue(newRiderTypes);
  }, []);

  useEffect(() => {
    if (firstLoad) {
      getPageData(`${API_GET_RIDER_TYPES}?page=1`, dispatch).then((res) => {
        const pagin = res?.meta?.pagination;
        setRiderTypesList(res?.data);
        setPagination({
          current: pagin?.currentPage,
          pageSize: pagin?.perPage,
          total: pagin?.total,
        });
        setFirstLoad(false);
      });
    }
  }, [firstLoad]);

  useEffect(() => {
    if (reloadList) {
      api
        .get({
          endpoint: `${API_GET_RIDER_TYPES}?page=${pagination.current}`,
        })
        .then((res) => {
          const pagin = res?.meta?.pagination;
          setRiderTypesList(res?.data);
          setPagination({
            current: pagin?.currentPage,
            pageSize: pagin?.perPage,
            total: pagin?.total,
          });
          setReloadList(false);
        });
    }
  }, [reloadList, pagination]);

  const handleEditRiderType = useCallback(
    (rowData: IRiderTypes) => () => {
      const { attributes, id } = rowData;
      const currentRiderType = new RiderTypesModel({
        ...attributes,
      });

      setIsOpen(true);
      setRiderTypeId(id);
      setModalTile(`Phong cách: ${currentRiderType.title}`);
      setRiderType(currentRiderType);
      form.setFieldsValue(currentRiderType);
    },
    [],
  );

  const handleChangeValues = useCallback((_, values) => {
    const currentRiderType = new RiderTypesModel(values);
    setRiderType(currentRiderType);
    form.setFieldsValue(currentRiderType);
    dispatch(setIsEditing(true));
  }, []);

  const handleSaveRiderTypes = useCallback(async () => {
    try {
      if (riderTypeId) {
        await api.put({
          endpoint: API_GET_DETAIL_RIDER_TYPES.replace('{id}', riderTypeId),
          data: riderType,
        });
        setIsOpen(false);
        setReloadList(true);
        dispatch(setIsEditing(false));
      } else {
        await api.post({ endpoint: API_GET_RIDER_TYPES, data: riderType }).then((res) => {
          setRiderTypeId(res.data?.id);
          setModalTile(`Phong cách: ${riderType.title}`);
        });
        setIsOpen(false);
        setReloadList(true);
        dispatch(setIsEditing(false));
      }
    } catch (err) {
      showErrorMsg(err);
    }
  }, [riderType, riderTypeId]);

  const handleDeleteRiderTypes = useCallback(() => {
    deleteModal({
      title: `Ban có muốn xóa phong cách chơi xe: <strong>${riderType.title}</strong> không?`,
      onDeleteCb: async () => {
        try {
          await api.remove({
            endpoint: API_GET_DETAIL_RIDER_TYPES.replace('{id}', riderTypeId),
          });
          setReloadList(true);
          setIsOpen(false);
          dispatch(setIsEditing(false));
        } catch (err) {
          showErrorMsg(err);
        }
      },
    });
  }, [riderTypeId, riderType]);

  const handleDeletOutside = useCallback(
    (rowData: IRiderTypes) => () => {
      deleteModal({
        title: `Ban có muốn xóa phong cách chơi xe: <strong>${rowData.attributes.title}</strong> không?`,
        onDeleteCb: async () => {
          try {
            await api.remove({
              endpoint: API_GET_DETAIL_RIDER_TYPES.replace('{id}', rowData.id),
            });
            setReloadList(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      const pagin = pagination;
      pagin.current = page;
      setPagination(pagin);
      setReloadList(true);
    },
    [pagination],
  );

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Phong cách',
      dataIndex: 'attributes',
      render: (attr: IRiderTypesAttribute, row: IRiderTypes) => (
        <div
          onClick={handleEditRiderType(row)}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {attr.title}
        </div>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      align: AlignType.right,
      render: (rowData: IRiderTypes) => (
        <Button title="Xoá" icon={<DeleteOutlined />} onClick={handleDeletOutside(rowData)} />
      ),
    },
  ];

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    form.resetFields();
    dispatch(setIsEditing(false));
  }, []);

  return (
    <>
      <LayoutList
        pageTitle="Phong cách chơi xe"
        showAddButton
        handleClickAddButton={handleOpenModal}
        columns={columns}
        dataList={riderTypesList}
        breadcrumbs={[
          {
            title: 'Phong cách chơi xe',
            route: '/phong-cach-choi-xe',
          },
        ]}
        pagination={pagination}
        handleChangePage={handleChangePage}
        topPagination
        loadingList={reloadList}
        filterNode={null}
      />
      <CustomPopup
        title={modalTitle}
        isOpen={isOpen}
        onCloseModal={handleCloseModal}
        showDeleteButton={!!riderTypeId}
        handleSaveButton={handleSaveRiderTypes}
        handleValuesChange={handleChangeValues}
        handleDeleteButton={handleDeleteRiderTypes}
        formHook={form}
      >
        <Row gutter={24}>
          {riderTypeId && (
            <Col span={24} className="mb-3">
              <b>ID:</b> {riderTypeId}
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              label="Tên phong cách"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền tên phong cách',
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Hiển thị" name="active" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </CustomPopup>
    </>
  );
};

export default RiderType;
