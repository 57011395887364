/* eslint-disable camelcase */
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { STORE_IDS } from 'constants/constants';

export interface FilterEventPage {
  title: string;
  publish: boolean;
  storeIds: string | string[];
}

interface Props {
  onFilter: (filter: FilterEventPage) => void;
}

export const FilterView: React.FC<Props> = (props) => {
  const { onFilter } = props;
  const [form] = Form.useForm();

  const location = useLocation();
  const params = queryString.parse(location.search, { arrayFormat: 'bracket' });

  useEffect(() => {
    if (!isEmpty(params)) {
      form.setFieldsValue({
        ...params,
        publish: params?.publish === 'true' ? true : false,
        storeIds:
          typeof params?.storeIds !== 'string' && params?.storeIds?.length
            ? (params?.storeIds as string[])?.map((o) => parseInt(o))
            : params?.storeIds
            ? parseInt(params?.storeIds as string)
            : [],
      });
    }
  }, [params]);

  const onClear = useCallback(() => {
    form.resetFields();
    onFilter({
      title: '',
      storeIds: [],
      publish: undefined,
    } as FilterEventPage);
  }, [onFilter]);

  return (
    <Form layout="vertical" form={form} onFinish={onFilter}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Tiêu đề" name="title">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Cửa hàng" name="storeIds">
            <Select mode="multiple" className="ant-select-lg" options={STORE_IDS} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Trạng thái" name="publish" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col
          span={24}
          className="align-items-center justify-content-end"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button htmlType="submit" className="secondary-button">
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={onClear}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
