import { Col, Form, Input, Row, Select, message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { COUPON_RULES, STORE_IDS } from 'constants/constants';
import { CouponRules, CouponStatus, DiscountUnit } from 'constants/enum';
import { useCreateCoupon } from '../hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateCouponModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;
  const [form] = Form.useForm();
  const history = useHistory();

  const { isCreating, onCreate } = useCreateCoupon();

  const onSubmit = useCallback(async () => {
    try {
      const values = form.getFieldsValue();
      const { prefixCode, ...rest } = values || {};
      const { id } = await onCreate({
        data: {
          ...rest,
          prefixCode: prefixCode?.toUpperCase(),
          discount: 0,
          discountUnit: DiscountUnit.PERCENT,
          status: CouponStatus.draft,
        },
      });
      message.success('Thêm coupon thành công');
      history.push(`/ma-giam-gia/${id}`);
    } catch (error: any) {
      if (error?.prefixCode?.length) {
        message.error('Prefix Code đã được sử dụng');
        return;
      }
      message.error(error?.message || error);
    }
  }, []);

  return (
    <CustomPopup
      title="Chương Trình Coupon Mới"
      formHook={form}
      isOpen={isOpen}
      onCloseModal={onClose}
      isSaving={isCreating}
      handleSaveButton={onSubmit}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Tên trương trình Coupon"
            name="title"
            rules={[{ required: true, message: 'Vui lòng nhập tên trương trình Coupon' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Kiểu Coupon"
            name="rule"
            rules={[{ required: true, message: 'Vui lòng chọn kiểu Coupon' }]}
          >
            <Select className="ant-select-lg" options={COUPON_RULES} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Prefix Code"
            name="prefixCode"
            rules={[{ required: true, message: 'Vui lòng nhập Prefix Code' }]}
          >
            <Input style={{ textTransform: 'uppercase' }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Số lượng mã phát hành"
            name="numberOfCoupon"
            rules={[
              {
                required: true,
                validator: (_, value, callback) => {
                  const number = parseFloat(value);
                  if (isNaN(number) || number <= 0) {
                    callback('Vui lòng nhập một số lớn hơn 0');
                  } else {
                    callback();
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Chọn cửa hàng"
            name="storeId"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn cửa hàng',
              },
            ]}
          >
            <Select className="ant-select-lg" options={STORE_IDS} />
          </Form.Item>
        </Col>
      </Row>
    </CustomPopup>
  );
};
