import { Col, Collapse, Form, Input, message, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useKeyboardShortcut from 'use-keyboard-shortcut';

import { API_GET_SINGLE_PAGES, API_GET_SINGLE_PAGE_DETAIL } from 'constants/api/single-page.api';
import { ISinglePageAttribute } from 'interfaces/single-page.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { SinglePageModel } from 'models/single-page.model';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { showErrorMsg, generateSlug } from 'utils/utils';

const { Panel } = Collapse;

const SinglePageDetail = () => {
  const newSinglePage = new SinglePageModel({});
  const { id: pageId } = useParams<{ id: string }>();
  const isAddNew = pageId === 'new';

  const [singlePage, setSinglePage] = useState<ISinglePageAttribute>(newSinglePage);
  const [saveLoading, setSaveLoading] = useState(false);
  const [mainContent, setMainContent] = useState('');

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isAddNew) {
      getPageData(API_GET_SINGLE_PAGE_DETAIL.replace('{id}', pageId), dispatch).then(
        async (res) => {
          dispatch(setIsEditing(false));
          if (res) {
            const currentPage = new SinglePageModel({
              ...res?.data?.attributes,
            });

            setSinglePage(currentPage);
            setMainContent(currentPage?.content);
            form.setFieldsValue({ ...currentPage, slug: `/trang/${currentPage.slug}` });
          }
        },
      );
    }
  }, [pageId]);

  const handleClickAddButton = useCallback(() => {
    history.push('/trang-don/new');
  }, []);

  const handleCancel = () => {
    history.push('/trang-don');
  };

  const handleValuesChange = useCallback(
    async (_, changedValues) => {
      dispatch(setIsEditing(true));
      const currentPage = new SinglePageModel({ ...singlePage, ...changedValues });

      currentPage.slug = generateSlug(currentPage.title.trim());

      setSinglePage(currentPage);
      form.setFieldsValue({ ...currentPage, slug: `/trang/${currentPage.slug}` });
    },
    [singlePage, dispatch],
  );

  const handleSavePage = useCallback(async () => {
    dispatch(setIsEditing(false));
    setSaveLoading(true);
    const currentPage = new SinglePageModel({
      ...singlePage,
      active: true,
    });

    try {
      if (isAddNew) {
        await api.post({ endpoint: API_GET_SINGLE_PAGES, data: currentPage }).then((res) => {
          history.push(`/trang-don/${res?.data.id}`);
        });
        setSaveLoading(false);
        dispatch(setIsEditing(false));
        message.success('Lưu trang đơn thành công', 3);
      } else {
        await api
          .put({
            endpoint: API_GET_SINGLE_PAGE_DETAIL.replace('{id}', pageId),
            data: currentPage,
          })
          .then((res) => {
            const updatedPage = new SinglePageModel(res?.data?.attributes);
            form.setFieldsValue({ ...updatedPage, slug: `/trang/${updatedPage.slug}` });
            setSinglePage(updatedPage);
          });
        setSaveLoading(false);
        dispatch(setIsEditing(false));
        message.success('Lưu trang đơn thành công', 3);
      }
    } catch (err) {
      setSaveLoading(false);
      showErrorMsg(err);
    }
  }, [singlePage, dispatch]);

  useKeyboardShortcut(
    ['Meta', 'S'],
    () => {
      handleSavePage();
    },
    { overrideSystem: true, ignoreInputFields: true },
  );

  useKeyboardShortcut(
    ['Control', 'S'],
    () => {
      handleSavePage();
    },
    { overrideSystem: true, ignoreInputFields: true },
  );

  const handleChangeMainContent = useCallback(
    (value: string) => {
      dispatch(setIsEditing(true));
      const currentPage = new SinglePageModel(singlePage);
      currentPage.content = value;
      form.setFieldsValue({ ...currentPage, slug: `/trang/${currentPage.slug}` });
      setMainContent(value);
      setSinglePage(currentPage);
    },
    [singlePage],
  );

  return (
    <>
      <LayoutDetail
        pageTitle="Soạn nội dung"
        pageClassName="product-detail-page"
        isAddNew
        breadcrumbs={[
          {
            title: 'Trang đơn',
            route: '/trang-don',
          },
          {
            title: isAddNew ? 'Thêm mới' : 'Chi tiết',
            route: `/trang-don/${isAddNew ? 'new' : pageId}`,
          },
        ]}
        formHook={form}
        handleValuesChange={handleValuesChange}
        handleSave={handleSavePage}
        handleClickAddButton={handleClickAddButton}
        handleCancel={handleCancel}
        handleDelete={null}
        saveLoading={saveLoading}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Tiêu đề trang"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng điền tiêu đề trang',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Slug" name="slug">
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Col span={24}>
          <Collapse defaultActiveKey={'2'}>
            <Panel header="Nội dung chính" key="2">
              <CustomEditor
                checkEmpty={!isAddNew}
                mainContent={mainContent}
                handleChangeMainContent={handleChangeMainContent}
              />
            </Panel>
            <Panel header="Meta contents" key="3">
              <Form.Item label="Meta Keywords" name="keywords">
                <Input.TextArea rows={6} />
              </Form.Item>
              <Form.Item label="Meta Description" name="metaDescription">
                <Input.TextArea rows={6} />
              </Form.Item>
            </Panel>
          </Collapse>
        </Col>
      </LayoutDetail>
    </>
  );
};

export default SinglePageDetail;
