import { IToken } from 'interfaces/token.interface';

export class TokenModel implements IToken {
  tokenType: string;

  expiresIn: number;

  accessToken: string;

  refreshToken: string;

  constructor({ tokenType, expiresIn, accessToken, refreshToken }: IToken) {
    this.tokenType = tokenType;
    this.expiresIn = expiresIn;
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }
}
