import { Col, Form, Row } from 'antd';
import { clone } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';

import { IProduct } from 'interfaces/product.interface';

import { SearchedTable } from 'ui/components/SelectProductsSection/SearchedTable';
import { SelectedTable } from 'ui/components/SelectProductsSection/SelectedTable';

import './index.scss';

interface SelectedVariantProductsInterface {
  variantProducts: IProduct[];
  selectedVariantProducts: IProduct[];
  loading: boolean;
  onSelectProduct: (variantProducts: IProduct[]) => void;
}

export const SelectVariantProduct: FC<SelectedVariantProductsInterface> = ({
  variantProducts,
  selectedVariantProducts,
  loading,
  onSelectProduct,
}) => {
  const [selectedProducts, setSelectedProducts] = useState(selectedVariantProducts);

  useEffect(() => {
    setSelectedProducts(selectedVariantProducts);
  }, [selectedVariantProducts]);

  const isDisableRow = (object: IProduct): boolean => {
    const indexs = selectedProducts.map((o) => o.id);
    return indexs.includes(object.id);
  };

  const handleSelectProduct = useCallback(
    (prd: IProduct) => {
      if (!isDisableRow(prd)) {
        setSelectedProducts([prd, ...selectedProducts]);
        onSelectProduct([prd, ...selectedProducts]);
      }
    },
    [isDisableRow, selectedProducts, onSelectProduct],
  );

  const onRemoveSelectedProd = useCallback(
    (prod: IProduct) => {
      const clonedSelectedData = clone(selectedProducts);
      const selectedProductIndex = clonedSelectedData.findIndex((item) => item.id === prod.id);
      if (selectedProductIndex > -1) {
        clonedSelectedData.splice(selectedProductIndex, 1);
      }
      setSelectedProducts(clonedSelectedData);
      onSelectProduct(clonedSelectedData);
    },
    [selectedProducts, setSelectedProducts],
  );

  return (
    <Row gutter={24} className="select-products-section">
      <Col span={12}>
        <Form.Item label="Danh sách sản phẩm con">
          <div className="searched-product-wrap">
            <SearchedTable
              rowClassName={(record) => (isDisableRow(record) ? 'row-disable' : '')}
              data={variantProducts}
              loading={loading}
              onSelectProduct={handleSelectProduct}
            />
          </div>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Danh sách sản phẩm con đã chọn">
          <div style={{ padding: '16px' }}>
            <SelectedTable data={selectedProducts} onRemoveSelectedProd={onRemoveSelectedProd} />
          </div>
        </Form.Item>
      </Col>
    </Row>
  );
};
