import { IFilterRiderModels, IRiderModelsAttribute, IRiderTypesAttribute } from './riderType';

export class RiderTypesModel implements IRiderTypesAttribute {
  title?: string;

  active?: boolean;

  constructor({ title, active }: IRiderTypesAttribute) {
    this.title = title || '';
    this.active = active !== undefined && active !== null ? active : false;
  }
}

export class RiderModelsModel implements IRiderModelsAttribute {
  name?: string;

  slug?: string;

  yearFrom?: any;

  yearTo?: any;

  brandId?: string;

  typeIds?: any[];

  active?: boolean;

  constructor({ name, active, slug, yearFrom, yearTo, brandId, typeIds }: IRiderModelsAttribute) {
    this.name = name || '';
    this.slug = slug || '';
    this.yearFrom = yearFrom || 0;
    this.yearTo = yearTo || 0;
    this.brandId = brandId || '';
    this.typeIds = typeIds || [];
    this.active = active !== undefined && active !== null ? active : false;
  }
}

export class FilterRiderModelsModel implements IFilterRiderModels {
  name?: string;

  yearFrom?: any;

  yearTo?: any;

  brandId?: string;

  typeIds?: any[];

  constructor({ name, yearFrom, yearTo, brandId, typeIds }: IFilterRiderModels) {
    this.name = name || '';
    this.yearFrom = yearFrom || null;
    this.yearTo = yearTo || null;
    this.brandId = brandId || '';
    this.typeIds = typeIds || [];
  }
}
