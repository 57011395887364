import { RcFile } from 'antd/lib/upload';
import isEqual from 'lodash/isEqual';
import Quill from 'quill';
import ImageUploader from 'quill-image-uploader';
import { FC, memo, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';

import { API_GET_POST_GALLERIES } from 'constants/api/gallery.api';
import * as api from 'services/api.service';
import { uploadFile } from 'utils/utils';

Quill.register('modules/imageUploader', ImageUploader);

interface CustomEditorInterface {
  mainContent?: string;
  handleChangeMainContent?: (value: string) => void;
  className?: string;
  metaContent?: boolean;
  listContent?: boolean;
  noVideo?: boolean;
  editorId?: string;
  checkEmpty?: boolean;
  showHeading?: boolean;
  toolbarText?: string[];
}

export const CustomEditor: FC<CustomEditorInterface> = memo(
  ({
    mainContent,
    handleChangeMainContent,
    className = '',
    metaContent = true,
    editorId = 'editor',
    listContent = true,
    noVideo = false,
    checkEmpty = false,
    showHeading = true,
    toolbarText = ['bold', 'italic', 'underline', 'strike'],
  }) => {
    const [content, setContent] = useState(mainContent);
    const [isEmpty, setIsEmpty] = useState(checkEmpty);
    let quill = null;

    const initEditor = () => {
      const container = document.getElementById(editorId);
      quill = new Quill(container, {
        theme: 'snow',
        modules: {
          toolbar: [
            showHeading ? [{ header: [1, 2, 3, 4, 5, 6, false] }] : [],
            toolbarText,
            listContent ? [{ list: 'ordered' }, { list: 'bullet' }] : [],
            metaContent ? ['link', 'image', noVideo ? '' : 'video'] : [],
            listContent ? [{ indent: '-1' }, { indent: '+1' }] : [],
            listContent ? [{ color: [] }, { background: [] }] : [],
            listContent ? [{ align: [] }] : [],
          ],
          imageUploader: {
            upload: (file: RcFile) => {
              return new Promise((resolve, reject) => {
                try {
                  api
                    .post({
                      endpoint: API_GET_POST_GALLERIES,
                      data: { name: file.name, position: 0 },
                    })
                    .then(async (res) => {
                      await uploadFile(file, 'gallery', res?.data?.id, '', '0').then((attr) => {
                        resolve(attr?.originUrl);
                      });
                    });
                } catch (error) {
                  reject(error);
                }
              });
            },
          },
        },
      });
    };

    useEffect(() => {
      if (isEmpty && !isEqual(mainContent, content)) {
        setContent(mainContent);
        initEditor();
        setIsEmpty(false);
      }
    }, [isEmpty, mainContent, content]);

    useEffect(() => {
      if (!checkEmpty) {
        initEditor();
      }
    }, [checkEmpty]);

    useEffect(() => {
      if (quill) {
        quill.root.innerHTML = mainContent || '';
        quill.on('text-change', () => {
          if (quill.root.innerHTML === '<p><br></p>') {
            setContent('');
          } else {
            setContent(quill.root.innerHTML);
          }
        });
      }
    }, [mainContent]);

    useEffect(() => {
      handleChangeMainContent(content);
    }, [content]);

    return (
      <div className={className} id={editorId}>
        <div id="toolbar" />
      </div>
    );
  },
);
