import atdCardImg from 'assets/img/atm_card.png';
import paymentCardImg from 'assets/img/ico-payment-card.png';
import { OrderAttrs } from 'interfaces/order.interface';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { SexType } from 'constants/enum';

interface CustomerInfoProps {
  data: OrderAttrs;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({ data }) => {
  const {
    paymentMethod,
    customerGender,
    customerName,
    customerPhone,
    billingAddress,
    branch,
    paymentStatus,
    additional,
    shippingAddress,
  } = data || {};

  const { callAnother = {}, companyInvoice = {} } = useMemo(() => {
    return !isEmpty(additional) ? additional[0] : {};
  }, [additional]);

  const fullAdress = useMemo(() => {
    return !isEmpty(branch)
      ? `${branch.name} - ${branch.address}`
      : !isEmpty(shippingAddress)
      ? `${shippingAddress?.address} , ${shippingAddress?.ward}, ${shippingAddress?.district}, ${shippingAddress?.city}`
      : '-';
  }, [branch, shippingAddress]);

  return (
    <div className="customer-info">
      <div className="customer-left mb-3">
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={2}>
                <b>Người mua</b>
              </td>
            </tr>
            <tr>
              <td>Họ và tên:</td>
              <td>{`${SexType[customerGender]} ${customerName} - ${customerPhone}`}</td>
            </tr>
            {billingAddress?.address &&
              billingAddress?.ward &&
              billingAddress?.district &&
              billingAddress?.city && (
                <tr>
                  <td>Địa chỉ:</td>
                  <td>
                    {billingAddress?.address} , {billingAddress?.ward}, {billingAddress?.district},{' '}
                    {billingAddress?.city}
                  </td>
                </tr>
              )}
            <tr>
              <td>Điện thoại:</td>
              <td>{customerPhone}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="customer-right">
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={2}>
                <b> {!isEmpty(branch) ? 'Nhận tại cửa hàng' : 'Giao hàng đến'}</b>
              </td>
            </tr>
            {callAnother?.customerName && (
              <tr>
                <td>Người nhận khác:</td>
                <td>{`${callAnother?.customerGender} ${callAnother.customerName}`}</td>
              </tr>
            )}
            <tr>
              <td>Địa chỉ:</td>
              <td>{fullAdress}</td>
            </tr>
            {callAnother?.customerPhone && (
              <tr>
                <td>Số điện thoại:</td>
                <td>{callAnother.customerPhone}</td>
              </tr>
            )}
          </tbody>
        </table>
        {paymentMethod !== null && (
          <table className="table tbl-payment-method mb-3">
            <tbody>
              <tr>
                <td>
                  <b>Hình thức thanh toán</b>
                </td>
                <td>
                  {paymentMethod.toLowerCase() === 'installment' && <span>Mua trả góp</span>}
                  {paymentMethod.toLowerCase() === 'direct' && <span>Mua tại cửa hàng</span>}
                  {paymentMethod.toLowerCase() === 'cod' && (
                    <span className="grp-icons">
                      Thanh toán khi nhận hàng - <b>COD</b>
                    </span>
                  )}
                  {paymentMethod.toLowerCase() === 'transfer' && (
                    <span className="grp-icons">Chuyển khoản ngân hàng</span>
                  )}
                  {paymentMethod.toLowerCase() === 'creditcard' && (
                    <div className="grp-icons">
                      <img src={paymentCardImg} alt="ico-payment-card.png" className="pr-2" />
                      <img src={atdCardImg} alt="atm_card.png" />
                    </div>
                  )}
                  <span className={'ml-3 payment-status ' + paymentMethod.toLowerCase()}>
                    {paymentStatus == 'success'
                      ? 'Đã thanh toán'
                      : paymentMethod.toLowerCase() === 'transfer' ||
                        paymentMethod.toLowerCase() === 'installment'
                      ? 'Đang xử lý'
                      : 'Chưa thanh toán'}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      {!isEmpty(companyInvoice) && (
        <div className="customer-right mt-3 mb-3">
          <table className="table">
            <tbody>
              <tr>
                <td colSpan={2}>
                  <b>Xuất hoá đơn</b>
                </td>
              </tr>
              {companyInvoice?.companyName && (
                <tr>
                  <td>Tên công ty:</td>
                  <td>{companyInvoice.companyName}</td>
                </tr>
              )}
              {companyInvoice?.companyAddress && (
                <tr>
                  <td>Địa chỉ:</td>
                  <td>{companyInvoice.companyAddress}</td>
                </tr>
              )}
              {companyInvoice?.companyMst && (
                <tr>
                  <td>MST:</td>
                  <td>{companyInvoice.companyMst}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CustomerInfo;
