import moment from 'moment';

export const isCurrentDateGreaterThan = (date: string) => {
  if (!moment(date).isValid()) {
    return false;
  }

  const currentDate = moment();

  return currentDate.isAfter(moment(date));
};
