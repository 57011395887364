import { domainUrl } from '../constants';

export const API_MEMBERS = `${domainUrl}auth/customers`;
export const API_MEMBER = `${domainUrl}auth/customers/{id}`;
export const API_MEMBER_WISHLIST = `${domainUrl}auth/customers/favorites/{id}`;
export const API_MEMBERS_ORDERS = `${domainUrl}orders`;
export const API_MEMBERS_ORDER = `${domainUrl}orders/{id}`;
export const API_DOWNLOAD_MEMBERS = `${domainUrl}auth/customers/exports`;
export const API_DOWNLOAD_ORDERS = `${domainUrl}orders/exports`;
export const API_MEMBER_LEVELS = `${domainUrl}customer/customer-levels`;
export const API_MEMBER_GROUP_LEVELS = `${domainUrl}customer/customer-levels/levels`;
export const API_MEMBER_LEVEL_DETAIL = `${domainUrl}customer/customer-levels/{id}`;
