import { Col, Form, Input, Radio, Row, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { IImage } from 'interfaces/image.interface';
import React, { useCallback, useState } from 'react';
import { uploadFile } from 'utils/utils';
import { v4 as uuid } from 'uuid';
import { UploadFile } from '../../UploadFile';
import './LogoBlock.scss';

interface LogoBlockProps {
  blockId: string;
  data: {
    title: string;
    subTitle: string;
    target: string;
    urlPath: string;
    widthOnDesktop: string;
    widthOnMobile: string;
  };
  logoData: LogoDataTypes;
  onChange: (data: LogoDataTypes) => void;
}

type LogoDataTypes = {
  blackLogo: IImage;
  whiteLogo: IImage;
};

export const LogoBlock: React.FC<LogoBlockProps> = (props) => {
  const { blockId, data: initialData, logoData, onChange } = props;
  const { title, subTitle, target, urlPath, widthOnDesktop, widthOnMobile } = initialData || {};

  const [data, setData] = useState<LogoDataTypes>(logoData);
  const [uploadingLogo, setUploadingLogo] = useState<'black' | 'white' | null>(null);

  const onUploadLogo = useCallback(
    async ({ isBlackLogo, file }: { isBlackLogo: boolean; file: RcFile }) => {
      const newData = { ...data };
      setUploadingLogo(isBlackLogo ? 'black' : 'white');
      if (isBlackLogo) {
        await uploadFile(file, 'block', blockId, '', '0')
          .then((attr) => {
            newData.blackLogo = {
              ...attr,
              original: attr?.originUrl,
            };
          })
          .catch(() => {
            newData.blackLogo = null;
          });
      } else {
        await uploadFile(file, 'block', blockId, '', '0')
          .then((attr) => {
            newData.whiteLogo = {
              ...attr,
              original: attr?.originUrl,
            };
          })
          .catch(() => {
            newData.whiteLogo = null;
          });
      }

      setData(newData);
      onChange(newData);
      setUploadingLogo(null);
    },
    [data, onChange, blockId],
  );

  const handleDeleteLogo = useCallback(
    ({ isBlackLogo }: { isBlackLogo: boolean }) => {
      const newData = { ...data };
      if (isBlackLogo) {
        newData.blackLogo = null;
      } else {
        newData.whiteLogo = null;
      }

      setData(newData);
      onChange(newData);
    },
    [data, onChange],
  );

  return (
    <Row className="mai-nguyen-logo-block" gutter={16}>
      <Col span={8}>
        <Form.Item label="Tiêu đề" name="title" initialValue={title}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Url path" name="urlPath" initialValue={urlPath}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Target" name="target" initialValue={target}>
          <Radio.Group>
            <Radio value="_blank">_blank</Radio>
            <Radio value="not">not</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Tiêu đề phụ" name="subTitle" initialValue={subTitle}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Black Logo">
          <UploadFile
            typeId={uuid()}
            previewImage={data.blackLogo}
            handleUploadImage={(file: RcFile) => onUploadLogo({ isBlackLogo: true, file })}
            handleDeleteImageSuccess={() => handleDeleteLogo({ isBlackLogo: true })}
            maxWidth={600}
          />
          {uploadingLogo === 'black' && (
            <div className="loading-wrapper">
              <Spin />
              <span>Uploading logo...</span>
            </div>
          )}
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="White Logo">
          <UploadFile
            typeId={uuid()}
            previewImage={data.whiteLogo}
            handleUploadImage={(file: RcFile) => onUploadLogo({ isBlackLogo: false, file })}
            handleDeleteImageSuccess={() => handleDeleteLogo({ isBlackLogo: false })}
            maxWidth={600}
          />
          {uploadingLogo === 'white' && (
            <div className="loading-wrapper">
              <Spin />
              <span>Uploading logo...</span>
            </div>
          )}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Width on desktop" name="widthOnDesktop" initialValue={widthOnDesktop}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Width on mobile" name="widthOnMobile" initialValue={widthOnMobile}>
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};
