import { Button } from 'antd';
import { ICoupon } from 'interfaces/coupon.interface';
import { isEmpty } from 'lodash';
import { confirmModal } from 'utils/modals';
import { CouponStatus } from 'constants/enum';

type ManageStatusProps = {
  data?: ICoupon;
  isUpdatingStatus?: boolean;
  isCouponExpired?: boolean;
  onUpdateStatus?: (status: CouponStatus) => void;
};

const ManageStatus: React.FC<ManageStatusProps> = ({
  data,
  isUpdatingStatus,
  isCouponExpired,
  onUpdateStatus,
}) => {
  return (
    <>
      {!isEmpty(data) && (
        <div className="btn-group">
          {data?.attributes?.status === CouponStatus.draft && !isCouponExpired && (
            <Button
              loading={isUpdatingStatus}
              className={`detail-toolbar__public-btn ${isUpdatingStatus ? 'show-loading' : ''}`}
              onClick={() => {
                confirmModal({
                  title: 'Bảo đảm thông tin chính xác trước khi phát hành công khai?',
                  buttonText: 'Public',
                  onSaveCb: () => onUpdateStatus(CouponStatus.active),
                });
              }}
            >
              Public
            </Button>
          )}

          {(data?.attributes?.status === CouponStatus.close || isCouponExpired) && (
            <Button
              loading={isUpdatingStatus}
              className={`detail-toolbar__draft-btn ${isUpdatingStatus ? 'show-loading' : ''}`}
              onClick={() => onUpdateStatus(CouponStatus.draft)}
            >
              Draft
            </Button>
          )}
          {data?.attributes?.status === CouponStatus.active && !isCouponExpired && (
            <Button
              loading={isUpdatingStatus}
              className={`detail-toolbar__close-btn ${isUpdatingStatus ? 'show-loading' : ''}`}
              onClick={() => {
                confirmModal({
                  title: 'Bạn có chắc là muốn đóng coupon này?',
                  buttonText: 'Đóng',
                  onSaveCb: () => onUpdateStatus(CouponStatus.close),
                });
              }}
            >
              Đóng Chương Trình
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default ManageStatus;
