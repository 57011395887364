import axios from 'axios';
import { isEmpty } from 'lodash';
import { API_REFRESH_TOKEN } from 'constants/api/auth.api';
import TokenService from './token.service';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

interface ApiMethod {
  endpoint: string;
  data?: any;
  requiresAuth?: boolean;
  params?: any;
  headers?: any;
}

interface ApiRequest extends ApiMethod {
  method: Method;
}

const tokenService = new TokenService();

axios.interceptors.response.use(
  (resp) => resp?.data,
  async (error) => {
    const originalRequest = error?.config;
    if (error?.response?.status === 401 && originalRequest?.url === API_REFRESH_TOKEN) {
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = tokenService.getRefreshToken();
      if (refreshToken) {
        return await axios
          .post(API_REFRESH_TOKEN, { refreshToken: refreshToken })
          .then(async (res) => {
            if (res.status === 201 || res.status === 200) {
              tokenService.setToken(res.data);
              const { accessToken, tokenType } = tokenService.getAccessToken();
              axios.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
              window.location = '/' as any;
              return axios(originalRequest);
            } else {
              tokenService.clearToken();
              window.location = '/dang-nhap' as any;
              return await axios(originalRequest);
            }
          })
          .catch(async () => {
            tokenService.clearToken();
            window.location = '/dang-nhap' as any;
            return await axios(originalRequest);
          });
      } else {
        tokenService.clearToken();
        window.location = '/dang-nhap' as any;
        return await axios(originalRequest);
      }
    }

    if (error?.response?.status === 422 && !isEmpty(error?.response?.data)) {
      return Promise.reject(error?.response?.data);
    }

    return Promise.reject(error);
  },
);

const prepareOption = (
  method: Method,
  data: any,
  requiresAuth: boolean,
  headers: any,
  params: any,
) => {
  const option = {
    method,
    headers: {
      'Access-Control-Allow-Origin': '*',
      ...headers,
    },
    body: {},
  };

  if (requiresAuth) {
    const { accessToken, tokenType } = tokenService.getAccessToken();
    option.headers = {
      'Access-Control-Allow-Origin': '*',
      Authorization: `${tokenType} ${accessToken}`,
      ...headers,
    };
  }

  if (data) {
    if (method === 'GET') {
      option['params'] = { ...(params || {}) };
    } else {
      option['data'] = data;
      if (params) {
        option['params'] = params;
      }
    }
  }

  return option;
};

const apiRequest = ({
  method,
  endpoint,
  data,
  requiresAuth = true,
  params = null,
  headers = {},
}: ApiRequest) => {
  const option = prepareOption(method, data, requiresAuth, headers, params);
  return axios(endpoint, option).then((resp) => resp as any);
};

export const get = ({
  endpoint,
  data = {},
  requiresAuth = true,
  headers = {},
  params = {},
}: ApiMethod) =>
  apiRequest({
    method: 'GET',
    endpoint,
    data,
    requiresAuth,
    headers,
    params,
  });

export const post = ({ endpoint, data, requiresAuth = true, headers }: ApiMethod) =>
  apiRequest({
    method: 'POST',
    endpoint,
    data,
    requiresAuth,
    headers,
  });

export const put = ({ endpoint, data, requiresAuth = true, headers }: ApiMethod) =>
  apiRequest({
    method: 'PUT',
    endpoint,
    data,
    requiresAuth,
    headers,
  });

export const patch = ({ endpoint, data, requiresAuth = true, headers }: ApiMethod) =>
  apiRequest({
    method: 'PATCH',
    endpoint,
    data,
    requiresAuth,
    headers,
  });

export const remove = ({ endpoint, data, requiresAuth = true, headers }: ApiMethod) =>
  apiRequest({
    method: 'DELETE',
    endpoint,
    data,
    requiresAuth,
    headers,
  });
