import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { onSetPageData } from 'services/pageData.service';

import { SingInForm } from './SignInForm/SignInForm';

import './SignIn.scss';

const PageSignIn = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onSetPageData({ loaded: false, fullFilled: true }));
  }, []);

  return (
    <>
      <div className="title-block">
        <h5 className="form-title text-center">Đăng nhập</h5>
      </div>
      <SingInForm />
    </>
  );
};

export default PageSignIn;
