import { Form } from 'antd';
import { IHomeBlock } from 'interfaces/home-block.interface';
import React from 'react';
import { CustomJsonInput } from 'ui/components/MaiNguyen/CustomJsonEditor';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';

type Props = {
  blockAttributes: IHomeBlock['attributes'];
  onChange: (newAttributes: IHomeBlock['attributes']) => void;
};

export const SEOTemplate: React.FC<Props> = (props) => {
  const { blockAttributes, onChange } = props;

  return (
    <>
      <Form.Item label="Image">
        <UploadFile
          previewImage={blockAttributes?.image}
          handleUploadImage={(image) => onChange({ ...blockAttributes, image: image as any })}
          handleDeleteImageSuccess={() => onChange({ ...blockAttributes, image: null })}
          onRemoveImageCallback={() => onChange({ ...blockAttributes, image: null })}
          maxWidth={600}
        />
      </Form.Item>

      <CustomJsonInput
        value={blockAttributes?.data ? JSON.parse(blockAttributes?.data) : null}
        template={blockAttributes?.template}
        onChange={(v) => onChange({ ...blockAttributes, data: JSON.stringify(v) })}
        defaultJsonData={{
          title: '',
          description: '',
          keyword: '',
        }}
      />
    </>
  );
};
