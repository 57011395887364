import { message } from 'antd';
import { useFetchProductCategories } from 'hooks/useFetchProductCategories';
import { useUpdateCoupon } from 'pages/CouponDetail/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsEditing } from 'redux/uiAction/actions';
import { useSWRConfig } from 'swr';

// Components
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { TreeViewCheckbox } from 'ui/components/MaiNguyen/TreeViewCheckbox';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { API_COUPON_BY_ID } from 'constants/api/coupons.api';

interface ModalSelectCategoryProps {
  openModal?: boolean;
  cateIdsOfCoupons?: string[];
  productCategories?: any;
  couponId?: string;
  onCloseModal?: () => void;
  onSelect?: (coupon: any) => void;
  fetchCategoriesOfCoupon: () => Promise<void>;
}

const ModalSelectCategory: React.FC<ModalSelectCategoryProps> = (props) => {
  const {
    openModal,
    cateIdsOfCoupons,
    couponId,
    productCategories,
    onCloseModal,
    fetchCategoriesOfCoupon,
  } = props;

  // const { data: productCategories } = useFetchProductCategories();

  const [stdCate, setStdCate] = useState([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const { onUpdate } = useUpdateCoupon();

  useEffect(() => {
    if (openModal) {
      setStdCate([]);
      setIsSaving(false);
    }

    return () => {
      setStdCate([]);
      setIsSaving(false);
    };
  }, [openModal]);

  // TODO click close modal
  const clickCloseModal = () => {
    onCloseModal && onCloseModal();
  };

  // TODO: handle select change
  const handleSelect = useCallback((cate) => {
    setStdCate(cate.checked);
  }, []);

  // TODO: action save
  const onSave = useCallback(async (): Promise<void> => {
    try {
      setIsSaving(true);
      const newData = await onUpdate({
        method: 'put',
        id: couponId,
        data: { categoryIds: stdCate },
      });
      await mutate(API_COUPON_BY_ID.replace('{id}', couponId), newData, {
        revalidate: false,
        rollbackOnError: true,
      });
      dispatch(setIsEditing(false));
      setStdCate([]);
      await fetchCategoriesOfCoupon();
      message.success(`Cập nhật nhóm áp dụng vào coupon thành công`);
      clickCloseModal();
    } catch (error: any) {
      message.error(`${error?.message || error}`);
    } finally {
      setIsSaving(false);
    }
  }, [stdCate, fetchCategoriesOfCoupon, couponId]);

  return (
    <CustomPopup
      title="Chọn nhóm áp dụng"
      containerCls="select-cate-modal"
      isOpen={openModal}
      onCloseModal={clickCloseModal}
      formHook={null}
      handleSaveButton={null}
      isShowSaveButton={false}
      topToolbarComponent={
        <div className="text-right">
          <ThemeButton htmlType="button" onClick={onSave} loading={isSaving}>
            Lưu
          </ThemeButton>
        </div>
      }
    >
      <TreeViewCheckbox
        treeData={productCategories}
        handleSelecteKeys={handleSelect}
        selectedKeys={cateIdsOfCoupons}
      />
    </CustomPopup>
  );
};

export default ModalSelectCategory;
