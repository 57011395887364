/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';

import { IMenuItem } from 'interfaces/menu.interface';

import MenuLink from './MenuLink/MenuLink';
import WithSub from './WithSub/WithSub';
import './Menu.scss';

interface MenuProps {
  data?: IMenuItem[];
  color?: string;
  contrast?: string;
  accentColor?: string;
  accentContrast?: string;
}

const Menu: React.FunctionComponent<MenuProps> = (props) => {
  const [menu, setMenu] = useState<IMenuItem[]>([]);

  useEffect(() => {
    setMenu(props.data);
  }, [props.data]);

  const groupTitle = (item: IMenuItem, index: number) => (
    <li className="menu-item group" key={index}>
      <span style={{ color: props.accentColor }} className="group-title">
        <strong>{item.title}</strong>
      </span>
    </li>
  );

  const menuItems = menu?.map((item, index) => {
    if (item.groupTitle) {
      return groupTitle(item, index);
    }

    if (item.sub) {
      return (
        <WithSub
          {...item}
          color={props.color}
          contrast={props.contrast}
          accentColor={props.accentColor}
          accentContrast={props.accentContrast}
          key={index}
        />
      );
    }

    return (
      <MenuLink
        {...item}
        color={props.color}
        accentColor={props.accentColor}
        accentContrast={props.accentContrast}
        key={index}
      />
    );
  });

  return (
    <div className="menu">
      <nav className="main-menu-wrap">
        <ul className="menu-ul">{menuItems}</ul>
      </nav>
    </div>
  );
};

export default Menu;
