import { IAttrAttributeGroup } from 'interfaces/attr-group.interface';

export class AttributeGroupModel implements IAttrAttributeGroup {
  name?: string;

  position?: number;

  code?: string;

  parentId?: number | null;

  constructor({ name, position, code, parentId }: IAttrAttributeGroup) {
    this.name = name || '';
    this.position = position || 1;
    this.code = code || '';
    this.parentId = parentId || null;
  }
}
