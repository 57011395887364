import { SET_IS_EDITING, ActionUIActions } from './types';

const initState = {
  isEditing: false,
};

export const uiReducers = (state = initState, actions: ActionUIActions) => {
  switch (actions.type) {
    case SET_IS_EDITING:
      return { ...state, isEditing: actions.payload };
    default:
      return state;
  }
};
