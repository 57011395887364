import { DeleteOutlined } from '@ant-design/icons';
import { Form, Button, Input, Row, Col, Switch, Select, DatePicker } from 'antd';
const { RangePicker } = DatePicker;

import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { API_GET_BRANDS } from 'constants/api/brand.api';
import {
  API_GET_RIDER_MODELS,
  API_GET_DETAIL_RIDER_MODELS,
  API_GET_RIDER_TYPES,
} from 'constants/api/riderType.api';
import { defaultPagination } from 'constants/constants';
import { AlignType, DateFormat } from 'constants/enum';
import { IBrand } from 'interfaces/brand.interface';
import { IPagination } from 'interfaces/pagination.interface';
import {
  IFilterRiderModels,
  IRiderModels,
  IRiderModelsAttribute,
  IRiderTypes,
} from 'interfaces/riderType';
import { FilterRiderModelsModel, RiderModelsModel } from 'interfaces/riderTypes.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';

import './RiderModels.scss';

const newRiderModels = new RiderModelsModel({});
const defaultFilterRiderModels: IFilterRiderModels = {
  name: '',
  brandId: '',
  yearFrom: null,
  yearTo: null,
  typeIds: [],
};

const RiderModels = () => {
  const search = useLocation().search;
  const qsPage = new URLSearchParams(search).get('page');
  const [isOpen, setIsOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [modalTitle, setModalTile] = useState('Thêm Mới Dòng xe');
  const [riderModelsList, setRiderModelsList] = useState<IRiderModels[]>([]);
  const [riderTypesList, setRiderTypesList] = useState<IRiderTypes[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [riderModel, setRiderModel] = useState(newRiderModels);
  const [riderModelId, setRiderModelId] = useState('');
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [filterContent, setFilterContent] = useState<IFilterRiderModels>(defaultFilterRiderModels);
  const [filterDateFrom, setFilterDateFrom] = useState(null);
  const [filterDateTo, setFilterDateTo] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
    setModalTile('Thêm Mới Dòng xe');
    setRiderModel(newRiderModels);
    setRiderModelId('');
    form.setFieldsValue(newRiderModels);
    localStorage.setItem('currentRiderModel-new', JSON.stringify(newRiderModels));
  }, []);

  useEffect(() => {
    if (firstLoad) {
      // set filter
      const qsName = new URLSearchParams(search).get('name');
      const qsBrandId = new URLSearchParams(search).get('brandId');
      const qsTypeIds = new URLSearchParams(search).get('typeIds');
      const qsYearFrom = new URLSearchParams(search).get('yearFrom');
      const qsYearTo = new URLSearchParams(search).get('yearTo');

      const nameFilter = qsName ? `&name=${qsName}` : '';
      const brandFilter = qsBrandId ? `&brandId=${qsBrandId}` : '';
      const typeFilter = qsTypeIds?.length > 0 ? `&typeIds=${qsTypeIds}` : '';
      const yearFromFilter = qsYearFrom ? `&yearFrom=${qsYearFrom}` : '';
      const yearToFilter = qsYearTo ? `&yearTo=${qsYearTo}` : '';

      if (search) {
        setFilterContent({
          name: qsName,
          brandId: qsBrandId,
          yearFrom: qsYearFrom,
          yearTo: qsYearTo,
          typeIds: qsTypeIds ? qsTypeIds.split(',') : [],
        });
        filterForm.setFieldsValue({
          name: qsName,
          brandId: qsBrandId,
          yearFrom: qsYearFrom,
          yearTo: qsYearTo,
          typeIds: qsTypeIds ? qsTypeIds.split(',') : [],
        });
        setFilterDateFrom(qsYearFrom);
        setFilterDateTo(qsYearTo);
      }

      Promise.all([
        getPageData(
          `${API_GET_RIDER_MODELS}?page=${
            qsPage ? qsPage : '1'
          }${nameFilter}${brandFilter}${typeFilter}${yearFromFilter}${yearToFilter}`,
          dispatch,
        ),
        getPageData(`${API_GET_RIDER_TYPES}?perPage=all`, dispatch),
        getPageData(`${API_GET_BRANDS}?perPage=all`, dispatch),
      ]).then(([res, resRiderTypes, resBrand]) => {
        const pagin = res?.meta?.pagination;
        setRiderModelsList(res?.data);
        setRiderTypesList(resRiderTypes?.data);
        setPagination({
          current: pagin?.currentPage,
          pageSize: pagin?.perPage,
          total: pagin?.total,
        });
        setBrands(resBrand?.data.filter((item: IBrand) => item.attributes.active));
        setFirstLoad(false);
      });
    }
  }, [firstLoad]);

  useEffect(() => {
    if (reloadList) {
      const { name, brandId, typeIds } = filterContent;
      const nameFilter = name ? `&name=${name}` : '';
      const brandFilter = brandId ? `&brandId=${brandId}` : '';
      const typeFilter = typeIds?.length > 0 ? `&typeIds=${typeIds.join(',')}` : '';
      const yearFromFilter = filterDateFrom ? `&yearFrom=${filterDateFrom}` : '';
      const yearToFilter = filterDateTo ? `&yearTo=${filterDateTo}` : '';
      api
        .get({
          endpoint: `${API_GET_RIDER_MODELS}?page=${pagination.current}${nameFilter}${brandFilter}${typeFilter}${yearFromFilter}${yearToFilter}`,
        })
        .then((res) => {
          const pagin = res?.meta?.pagination;
          setRiderModelsList(res?.data);
          setPagination({
            current: pagin?.currentPage,
            pageSize: pagin?.perPage,
            total: pagin?.total,
          });
          setReloadList(false);
        });
    }
  }, [reloadList, filterContent]);

  useEffect(() => {
    return () => {
      // remove curent article
      if (!riderModelId) {
        localStorage.removeItem('currentRiderModel-new');
      } else {
        localStorage.removeItem('currentRiderModel-' + riderModelId);
      }
    };
  }, []);

  const handleEditRiderModel = useCallback(
    (rowData: IRiderModels) => () => {
      const { attributes, id } = rowData;
      const currentRiderModel = new RiderModelsModel({
        ...attributes,
      });

      setIsOpen(true);
      setRiderModelId(id);
      setModalTile(`Dòng xe: ${currentRiderModel.name}`);
      setRiderModel(currentRiderModel);
      form.setFieldsValue(currentRiderModel);
      // set current rider model
      if (!riderModelId) {
        localStorage.setItem('currentRiderModel-new', JSON.stringify(currentRiderModel));
      } else {
        localStorage.setItem(
          'currentRiderModel-' + riderModelId,
          JSON.stringify(currentRiderModel),
        );
      }
    },
    [],
  );

  const handleChangeValues = useCallback((_, values) => {
    let cloneRiderModel;
    if (!riderModelId) {
      cloneRiderModel = JSON.parse(localStorage.getItem('currentRiderModel-new'));
    } else {
      cloneRiderModel = JSON.parse(localStorage.getItem('currentRiderModel-' + riderModelId));
    }
    const currentRiderModel = new RiderModelsModel({ ...cloneRiderModel, ...values });
    setRiderModel(currentRiderModel);
    form.setFieldsValue(currentRiderModel);
    dispatch(setIsEditing(true));
    // set current rider model
    if (!riderModelId) {
      localStorage.setItem('currentRiderModel-new', JSON.stringify(currentRiderModel));
    } else {
      localStorage.setItem('currentRiderModel-' + riderModelId, JSON.stringify(currentRiderModel));
    }
  }, []);

  const handleSaveRiderTypes = useCallback(async () => {
    try {
      if (riderModel.yearFrom === 0 || isNaN(riderModel.yearFrom)) {
        const iptColor = document.querySelector('.datepicker-yearfrom');
        const msgColor = document.querySelector('.msg-yearfrom');
        iptColor.classList.add('err');
        msgColor.classList.add('shw');
        return;
      }
      if (riderModelId) {
        await api.put({
          endpoint: API_GET_DETAIL_RIDER_MODELS.replace('{id}', riderModelId),
          data: riderModel,
        });
        setIsOpen(false);
        setReloadList(true);
        dispatch(setIsEditing(false));
      } else {
        await api.post({ endpoint: API_GET_RIDER_MODELS, data: riderModel }).then((res) => {
          setRiderModelId(res.data?.id);
          setModalTile(`Dòng xe: ${riderModel.name}`);
        });
        setIsOpen(false);
        setReloadList(true);
        dispatch(setIsEditing(false));
      }
    } catch (err) {
      showErrorMsg(err);
    }
  }, [riderModel, riderModelId]);

  const handleDeleteRiderTypes = useCallback(() => {
    deleteModal({
      title: `Ban có muốn xóa dòng xe: <strong>${riderModel.name}</strong> không?`,
      onDeleteCb: async () => {
        try {
          await api.remove({
            endpoint: API_GET_DETAIL_RIDER_MODELS.replace('{id}', riderModelId),
          });
          setReloadList(true);
          setIsOpen(false);
          dispatch(setIsEditing(false));
        } catch (err) {
          showErrorMsg(err);
        }
      },
    });
  }, [riderModelId, riderModel]);

  const handleDeletOutside = useCallback(
    (rowData: IRiderModels) => () => {
      deleteModal({
        title: `Ban có muốn xóa dòng xe: <strong>${rowData.attributes.name}</strong> không?`,
        onDeleteCb: async () => {
          try {
            await api.remove({
              endpoint: API_GET_DETAIL_RIDER_MODELS.replace('{id}', rowData.id),
            });
            setReloadList(true);
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    },
    [],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      const pagin = pagination;
      pagin.current = page;
      const { name, brandId, typeIds } = filterContent;
      const nameFilter = name ? `&name=${name}` : '';
      const brandFilter = brandId ? `&brandId=${brandId}` : '';
      const typeFilter = typeIds?.length > 0 ? `&typeIds=${typeIds.join(',')}` : '';
      const yearFromFilter = filterDateFrom ? `&yearFrom=${filterDateFrom}` : '';
      const yearToFilter = filterDateTo ? `&yearTo=${filterDateTo}` : '';
      history.push(
        `/cac-dong-xe?page=${page}${nameFilter}${brandFilter}${typeFilter}${yearFromFilter}${yearToFilter}`,
      );
      setPagination(pagin);
      setReloadList(true);
    },
    [pagination, filterContent, filterDateFrom, filterDateTo],
  );

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Dòng xe',
      dataIndex: 'attributes',
      render: (attr: IRiderModelsAttribute, row: IRiderModels) => (
        <div
          onClick={handleEditRiderModel(row)}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {attr.name}
        </div>
      ),
    },
    {
      title: 'Phong cách',
      dataIndex: 'attributes',
      render: (attr: IRiderModelsAttribute) => {
        const arrTypeName = [];
        riderTypesList?.forEach((rider) => {
          attr?.typeIds.forEach((item) => {
            if (item == parseInt(rider?.id)) {
              arrTypeName.push(rider?.attributes?.title);
              return (attr['typeName'] = arrTypeName);
            }
          });
        });
        return <SanitizeHtml rawHtml={(attr['typeName'] && attr['typeName'].join(', ')) || '-'} />;
      },
    },
    {
      title: 'Thương hiệu',
      dataIndex: 'attributes',
      render: (attr: IRiderModelsAttribute) => {
        const brand = brands.find((item) => item.id === attr?.brandId?.toString());
        return <SanitizeHtml rawHtml={brand?.attributes?.name} />;
      },
    },
    {
      title: 'Năm sản xuất',
      dataIndex: 'attributes',
      render: (attr: IRiderModelsAttribute) => (
        <>
          {attr.yearFrom} - {attr.yearTo || '_'}
        </>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      align: AlignType.right,
      render: (rowData: IRiderModels) => (
        <Button title="Xoá" icon={<DeleteOutlined />} onClick={handleDeletOutside(rowData)} />
      ),
    },
  ];

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    form.resetFields();
    dispatch(setIsEditing(false));
    // remove curent article
    if (!riderModelId) {
      localStorage.removeItem('currentRiderModel-new');
    } else {
      localStorage.removeItem('currentRiderModel-' + riderModelId);
    }
  }, []);

  const handleChangeDate = useCallback((_, date) => {
    let cloneRiderModel;
    if (!riderModelId) {
      cloneRiderModel = JSON.parse(localStorage.getItem('currentRiderModel-new'));
    } else {
      cloneRiderModel = JSON.parse(localStorage.getItem('currentRiderModel-' + riderModelId));
    }

    const currentRiderModel = new RiderModelsModel(cloneRiderModel);
    currentRiderModel.yearFrom = parseInt(date[0]);
    currentRiderModel.yearTo = parseInt(date[1]);
    setRiderModel(currentRiderModel);
    form.setFieldsValue(currentRiderModel);
    dispatch(setIsEditing(true));

    // remove validate
    const iptColor = document.querySelector('.datepicker-yearfrom');
    const msgColor = document.querySelector('.msg-yearfrom');
    iptColor.classList.remove('err');
    msgColor.classList.remove('shw');

    // set current rider model
    if (!riderModelId) {
      localStorage.setItem('currentRiderModel-new', JSON.stringify(currentRiderModel));
    } else {
      localStorage.setItem('currentRiderModel-' + riderModelId, JSON.stringify(currentRiderModel));
    }
  }, []);

  const handleChangeFilterDateFrom = useCallback((_, date) => {
    setFilterDateFrom(date);
  }, []);

  const handleChangeFilterDateTo = useCallback((_, date) => {
    setFilterDateTo(date);
  }, []);

  const handleFilterChange = useCallback((_, values) => {
    const filter = new FilterRiderModelsModel(values);
    setFilterContent(filter);
    filterForm.setFieldsValue(filter);
  }, []);

  const handleClearFilter = useCallback(() => {
    setFilterDateFrom(null);
    setFilterDateTo(null);
    setFilterContent(defaultFilterRiderModels);
    filterForm.setFieldsValue(defaultFilterRiderModels);
    handleChangePage(1);
    // reset url params
    history.replace({
      search: '',
    });
  }, [handleChangePage]);

  const handleFilterRiderModels = useCallback(() => {
    handleChangePage(1);
  }, [handleChangePage]);

  return (
    <>
      <LayoutList
        pageTitle="Các dòng xe"
        showAddButton
        handleClickAddButton={handleOpenModal}
        columns={columns}
        dataList={riderModelsList}
        breadcrumbs={[
          {
            title: 'Các dòng xe',
            route: '/cac-dong-xe',
          },
        ]}
        pagination={pagination}
        handleChangePage={handleChangePage}
        topPagination
        loadingList={reloadList}
        filterNode={
          <Form
            layout="vertical"
            form={filterForm}
            onFinish={handleFilterRiderModels}
            onValuesChange={handleFilterChange}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Nhập tên dòng xe" name="name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Phong cách" name="typeIds">
                  <Select showSearch optionFilterProp="children" mode="multiple" size="large">
                    {riderTypesList.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.attributes.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Chọn thương hiệu" name="brandId">
                  <Select
                    className="ant-select-lg"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    filterOption={(input, option) => {
                      return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  >
                    {brands.map((item) => (
                      <Select.Option
                        key={item.id}
                        value={parseInt(item.id)}
                        title={item.attributes.name}
                      >
                        <SanitizeHtml rawHtml={item.attributes.name} />
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Năm sản xuất - Từ">
                  <DatePicker
                    picker="year"
                    format={DateFormat.clientYear}
                    onChange={handleChangeFilterDateFrom}
                    value={
                      (filterDateFrom
                        ? moment(filterDateFrom, DateFormat.clientYear)
                        : undefined) as any
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Năm sản xuất - Đến">
                  <DatePicker
                    picker="year"
                    format={DateFormat.clientYear}
                    onChange={handleChangeFilterDateTo}
                    value={
                      (filterDateTo
                        ? moment(filterDateTo, DateFormat.clientYear)
                        : undefined) as any
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Form.Item label={<br />}>
                  <Button className="secondary-button" htmlType="submit">
                    Filter
                  </Button>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label={<br />}>
                  <Button onClick={handleClearFilter}>Clear</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
      <CustomPopup
        title={modalTitle}
        isOpen={isOpen}
        onCloseModal={handleCloseModal}
        showDeleteButton={!!riderModelId}
        handleSaveButton={handleSaveRiderTypes}
        handleValuesChange={handleChangeValues}
        handleDeleteButton={handleDeleteRiderTypes}
        formHook={form}
      >
        <Row gutter={24}>
          {riderModelId && (
            <Col span={24} className="mb-3">
              <b>ID:</b> {riderModelId}
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              label="Tên Dòng xe"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền tên dòng xe',
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Hiển thị" name="active" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phong cách"
              name="typeIds"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn phong cách',
                },
              ]}
            >
              <Select showSearch optionFilterProp="children" mode="multiple" size="large">
                {riderTypesList.map((item) => (
                  <Select.Option key={item.id} value={parseInt(item.id)}>
                    {item.attributes.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Thương hiệu"
              name="brandId"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn thương hiệu',
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {brands.map((item) => (
                  <Select.Option
                    key={item.id}
                    value={parseInt(item.id)}
                    title={item.attributes.name}
                  >
                    <SanitizeHtml rawHtml={item.attributes.name} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="Năm sản xuất - Từ">
              <DatePicker
                className={`datepicker-yearfrom`}
                picker="year"
                format={DateFormat.clientYear}
                value={
                  riderModel?.yearFrom
                    ? moment(riderModel?.yearFrom, DateFormat.clientYear)
                    : undefined
                }
                onChange={handleChangeDate}
              />
              
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item label="Năm sản xuất">
              <RangePicker
                allowEmpty={[false, true]}
                className={`datepicker-yearfrom`}
                picker="year"
                placeholder={['Từ', 'Đến']}
                format={DateFormat.clientYear}
                value={
                  [
                    riderModel?.yearFrom
                      ? moment(riderModel?.yearFrom, DateFormat.clientYear)
                      : null,
                    riderModel?.yearTo ? moment(riderModel?.yearTo, DateFormat.clientYear) : null,
                  ] as any
                }
                onChange={handleChangeDate}
              />
              <span className={`sml-text msg-yearfrom`}>Vui lòng chọn năm sản xuất</span>
            </Form.Item>
          </Col>
        </Row>
      </CustomPopup>
    </>
  );
};

export default RiderModels;
