/* eslint-disable react/jsx-no-bind */
import { HomeFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { history } from 'redux/store';

import '../BaseError/BaseErrorPage.scss';

interface BaseErrorProps {
  title: string;
  subTitle: string;
  errorMessage: string;
}

const BaseError: React.FunctionComponent<BaseErrorProps> = (props) => {
  const { title, subTitle, errorMessage } = props;

  const handleButtonClick = () => {
    const route = `/`;
    history.push(route);
  };

  return (
    <div className="error-page">
      <div className="error-card">
        <div className="icon-block">
          <i className="icofont-bulb-alt" />
        </div>

        <div className="card-content">
          <div className="text-block mb-5">
            <h3 className="title">{errorMessage}</h3>

            <h6 className="sub-title">{title}</h6>

            <p className="error-message">{subTitle}</p>
          </div>

          <Button onClick={handleButtonClick} type="primary" block>
            <HomeFilled type="home" />
            Back to home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BaseError;
