/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useFetchProducts } from 'hooks/useFetchProducts';
import { IProduct } from 'interfaces/product.interface';
import { IStatus } from 'interfaces/status.interface';
import { ProductTable } from 'pages/CollectionDetail/components/ProductTable';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsEditing } from 'redux/uiAction/actions';
import { CustomTreeSelect } from 'ui/components/MaiNguyen/CustomTreeSelect';
import { extractNumbersFromString } from 'utils/utils';
import { SelectedProductsModal } from './SelectedProductsModal';
import './styles.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  productCategories: any;
  subStatus: IStatus[];
  initialProductSkus: string[];
  onChange: (productSkus: string[]) => void;
}

export const AddProductModal: React.FC<Props> = (props) => {
  const { isOpen, initialProductSkus, productCategories, subStatus, onClose, onChange } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { isFetchingProducts, products, fetchProducts, reset: resetProducts } = useFetchProducts();

  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);
  const [openSelectedProductsModal, setOpenSelectedProductsModal] = useState<boolean>(false);

  const selectedProductSkus = selectedProducts?.map((p) => p.attributes.sku);
  const combineProductSkus = [...selectedProductSkus, ...initialProductSkus];

  /**
   * A callback function that performs a search for products based on the form values and selected product skus.
   */
  const onSearch = useCallback(async (): Promise<void> => {
    const values = form.getFieldsValue();
    const combineProductIds = combineProductSkus?.map((sku) => extractNumbersFromString(sku));

    await fetchProducts({
      perPage: 20,
      ...queryString.parse(
        queryString.stringify(
          {
            ...values,
            ignoreIds: combineProductIds?.length > 1 ? combineProductIds : undefined,
            ignoreId: combineProductIds?.length === 1 ? combineProductIds[0] : undefined,
          },
          { skipNull: true, skipEmptyString: true },
        ),
      ),
    });
  }, [combineProductSkus]);

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === 'Enter') {
        await onSearch();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onSearch]);

  /**
   * An effect that resets the form fields, products, and selected product IDs when the modal is closed.
   */
  useEffect((): void => {
    if (!isOpen) {
      form.resetFields();
      resetProducts();
      setSelectedProducts([]);
    }
  }, [isOpen]);

  /**
   * A callback function that selects products based on the provided product IDs.
   *
   * @param {React.Key[]} productIds - An array of product IDs to select.
   */
  const onSelectProducts = useCallback((_, _products: IProduct[]) => {
    setSelectedProducts(_products);
    dispatch(setIsEditing(true));
  }, []);

  return (
    <Modal
      footer={null}
      visible={isOpen}
      title="Thêm sản phẩm"
      onCancel={onClose}
      centered
      width={1280}
      className="base-modal"
    >
      <div className="add-product-modal__content">
        <Form form={form} layout="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="SKU / Tên sản phẩm" name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={21}>
              <Form.Item
                label="Nhóm sản phẩm"
                name="categoryId"
                valuePropName="selectedValue"
                trigger="handleSelect"
              >
                <CustomTreeSelect data={productCategories} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label=" " className="form-item__search">
                <Button className="search-btn" onClick={onSearch}>
                  Tìm
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {!!selectedProductSkus?.length && (
          <div
            className="selected-products-label"
            onClick={() => setOpenSelectedProductsModal(true)}
          >
            Xem danh sách đã chọn ({selectedProductSkus.length})
          </div>
        )}

        <ProductTable
          loading={isFetchingProducts}
          data={products}
          subStatus={subStatus}
          rowKey={(r) => r.attributes.sku}
          rowSelection={{
            selectedRowKeys: selectedProductSkus,
            onChange: onSelectProducts,
          }}
          scroll={{ y: 400, x: 500 }}
        />

        {!!selectedProducts?.length && (
          <div className="save-button__wrapper">
            <Button type="primary" onClick={() => onChange(combineProductSkus)}>
              Lưu
            </Button>
          </div>
        )}

        <SelectedProductsModal
          visible={openSelectedProductsModal}
          onClose={() => setOpenSelectedProductsModal(false)}
          data={selectedProducts}
          subStatus={subStatus}
          onRemove={(productId) =>
            setSelectedProducts((prev) => prev.filter((p) => p.id !== productId))
          }
        />
      </div>
    </Modal>
  );
};
