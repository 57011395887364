import { Row, Col, Form } from 'antd';

import { useEffect } from 'react';
import { RiderBlockPositionTypes, RiderBlockTemplateTypes } from 'constants/enum';
import { useRiderPresentBlocks } from 'hooks/useRiderPresentBlocks';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { RiderTemplateBlock } from 'ui/components/MaiNguyen/RiderTemplateBlock';

import './RiderHomePresent.scss';

const Badge = ({ isCircle = false }) => {
  return (
    <div className="badge">
      {isCircle ? <div className="badge-circle" /> : <div className="badge-square" />}
      <div className="badge-line" />
      <div className="badge-line" />
    </div>
  );
};

const ProductItem = () => {
  return (
    <div className="product-item">
      <div className="product-image">photo</div>
      <div className="product-line-1" />
      <div className="product-line-2" />
    </div>
  );
};

const RiderHomePresent = () => {
  const {
    reloadList,
    block,
    isOpen,
    handleClosePopup,
    handleOpenModalBlock,
    handleUploadFileContent,
    handleChangeValues,
    handleSaveBlock,
    handleDeleteImage,
    handleChangeJsonData,
    handleUpdateHighendBlock,
    handleUpdateShockpriceBlock,
    handleUpdateCollections,
    handleUpdateMultipleImages,
  } = useRiderPresentBlocks();

  const [form] = Form.useForm();

  useEffect(() => {
    if (!isOpen) form.resetFields();
  }, [isOpen]);

  return (
    <>
      <LayoutDetail
        pageTitle="Trình bày trang Rider home"
        breadcrumbs={[
          {
            title: 'Trình bày trang rider home',
            route: '/trinh-bay-trang-rider-home',
          },
        ]}
        isAddNew
        wrapperCard={false}
        handleValuesChange={null}
        formHook={null}
        pageClassName="rider-home-present-page"
        saveLoading={reloadList}
      >
        <Row gutter={16}>
          <Col span={24}>
            <div
              className="block-item seo"
              onClick={handleOpenModalBlock(
                RiderBlockPositionTypes.metadataHomePage,
                RiderBlockTemplateTypes.metadataHomePage,
              )}
            >
              Quản lý SEO (home page)
            </div>
          </Col>
        </Row>
        <div className="main-content">
          <Row gutter={16} align="stretch">
            {/* Hero */}
            <Col span={12}>
              <div
                className="hero-banner block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.leftHeroBanners,
                  RiderBlockTemplateTypes.heroBanner,
                )}
              >
                HERO - LEFT BANNER
              </div>
            </Col>
            <Col span={12}>
              <div
                className="hero-banner block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.rightHeroBanners,
                  RiderBlockTemplateTypes.heroBanner,
                )}
              >
                HERO - RIGHT BANNER
              </div>
            </Col>

            {/* Giá sốc hôm nay */}
            <Col span={24}>
              <div
                className="homeShockPrice block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.shockPriceToday,
                  RiderBlockTemplateTypes.shockPriceToday,
                )}
              >
                <div className="shock-price-title">Giá sốc hôm nay</div>
                <div className="shock-price">
                  <ProductItem />
                  <ProductItem />
                  <ProductItem />
                  <ProductItem />
                  <ProductItem />
                </div>
              </div>
            </Col>

            {/* Danh mục sản phẩm */}
            <Col span={24}>
              <div
                className="categoriesIconMenu block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.categoryList,
                  RiderBlockTemplateTypes.categoryList,
                )}
              >
                <div className="category-title">Danh mục sản phẩm</div>
                <div className="category">
                  {Array.from({ length: 14 }, (_, i) => (
                    <Badge key={`${i}`} isCircle />
                  ))}
                </div>
              </div>
            </Col>

            {/* Banner 1 */}
            <Col span={24}>
              <div
                className="homeBannerMid block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.banner1,
                  RiderBlockTemplateTypes.largeBanner,
                )}
              >
                banner 1
              </div>
            </Col>

            {/* Vừa có hàng */}
            <Col span={24}>
              <div
                className="productTabs block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.justInStock,
                  RiderBlockTemplateTypes.justInStock,
                )}
              >
                Vừa có hàng
              </div>
            </Col>

            {/* Collections */}
            <Col span={24}>
              <div
                className="homeVideos block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.riderCollections,
                  RiderBlockTemplateTypes.riderCollections,
                )}
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <div className="main-video">Bộ sưu tập 1</div>
                  </Col>
                  <Col span={8}>
                    <div className="main-video">Bộ sưu tập 2</div>
                  </Col>
                  <Col span={8}>
                    <div className="main-video">Bộ sưu tập 3</div>
                  </Col>
                </Row>
              </div>
            </Col>

            {/* Phụ kiện mới về */}
            <Col span={24}>
              <div className="new-arrival-accessories block-item">Phụ kiện mới về</div>
            </Col>

            {/* Banner 2 */}
            <Col span={24}>
              <div
                className="homeBannerMid block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.banner2,
                  RiderBlockTemplateTypes.largeBanner,
                )}
              >
                banner 2
              </div>
            </Col>

            {/* Danh mục nổi bật */}
            <Col span={24}>
              <div
                className="featured-categories block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.featuredCategories,
                  RiderBlockTemplateTypes.featuredCategories,
                )}
              >
                <div className="featured-category">Danh mục nổi bật 1</div>
                <div className="featured-category">Danh mục nổi bật 2</div>
              </div>
            </Col>

            {/* Mainguyen Rider Instagram */}
            <Col span={24}>
              <div
                className="gallery-block block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.riderInstagram,
                  RiderBlockTemplateTypes.riderInstagram,
                )}
              >
                MAINGUYEN RIDER INSTAGRAM
              </div>
            </Col>

            {/* Youtube videos */}
            <Col span={24}>
              <div
                className="homeVideos block-item"
                onClick={handleOpenModalBlock(
                  RiderBlockPositionTypes.youtubeVideos,
                  RiderBlockTemplateTypes.youtubeVideos,
                )}
              >
                <Row gutter={16}>
                  <Col span={6}>
                    <div className="main-video">video</div>
                  </Col>
                  <Col span={12}>
                    <div className="main-video">video</div>
                  </Col>
                  <Col span={6}>
                    <div className="main-video">video</div>
                  </Col>
                </Row>
              </div>
            </Col>

            {/* Rider blog */}
            <Col span={24}>
              <div className="rider-blog block-item">
                <div className="rider-blog-title">RIDER BLOG</div>
                <div className="rider-blog-contents">
                  <ProductItem />
                  <ProductItem />
                  <ProductItem />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </LayoutDetail>

      <CustomPopup
        isOpen={isOpen}
        title="Display Block"
        onCloseModal={handleClosePopup}
        handleSaveButton={handleSaveBlock}
        formHook={form}
        handleValuesChange={handleChangeValues}
        containerCls="rider-home__temp-popup"
      >
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name">
                <div>{block?.attributes?.name}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Position">
                <div>{block?.attributes?.position}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Template">
                <div>{block?.attributes?.template}</div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <RiderTemplateBlock
                block={block}
                template={block?.attributes?.template}
                onAddImage={handleUploadFileContent}
                onDeleteImage={handleDeleteImage}
                onChangeJsonData={handleChangeJsonData}
                onUpdateHighendBlock={handleUpdateHighendBlock}
                onUpdateShockPriceBlock={handleUpdateShockpriceBlock}
                handleUpdateCollections={handleUpdateCollections}
                onUpdateSlider={handleUpdateMultipleImages}
              />
            </Col>
          </Row>
        </>
      </CustomPopup>
    </>
  );
};

export default RiderHomePresent;
