import { Col, Form, Input, Row, message } from 'antd';
import { IEventPage } from 'interfaces/event-page.interface';
import { UploadImage } from 'pages/CollectionDetail/components/UploadImage';
import { useUpdateEventPage } from 'pages/EventPage/hooks';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsEditing } from 'redux/uiAction/actions';
import { useSWRConfig } from 'swr';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { API_EVENT_PAGE_BY_ID } from 'constants/api/event-page.api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: IEventPage;
}

export const SEOEditorModal: React.FC<Props> = (props) => {
  const { isOpen, data, onClose } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isUpdating, onUpdate } = useUpdateEventPage();
  const { mutate } = useSWRConfig();

  const { ogImage, metaDescription, metaKeywords } = data?.attributes;

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({ ogImage, metaDescription, metaKeywords });
    }
  }, [data.id, isOpen]);

  const onSubmit = useCallback(async () => {
    try {
      const values = form.getFieldsValue();

      const res = await onUpdate({
        method: 'put',
        id: data.id,
        data: values,
      });

      await mutate(
        `${API_EVENT_PAGE_BY_ID.replace('{id}', data.id)}?include=blocks`,
        (currentData) => ({
          ...currentData,
          data: {
            ...currentData.data,
            attributes: res.data.attributes,
          },
        }),
        {
          revalidate: false,
          rollbackOnError: true,
        },
      );

      message.success('Cập nhật SEO thành công');
    } catch (error: any) {
      message.error(`${error?.message || error}`);
    } finally {
      dispatch(setIsEditing(false));
    }
  }, [data.id]);

  return (
    <CustomPopup
      title="Nội Dung SEO"
      formHook={form}
      isOpen={isOpen}
      onCloseModal={onClose}
      isSaving={isUpdating}
      handleSaveButton={onSubmit}
      handleValuesChange={() => dispatch(setIsEditing(true))}
    >
      <Row className="seo-modal__content" gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Mô tả (Giới hạn trong 160 ký tự)"
            name="metaDescription"
            rules={[
              {
                required: true,
                max: 160,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject('Vui lòng nhập mô tả');
                  }
                  if (value.length > 160) {
                    return Promise.reject(`Vui lòng nhập mô tả dưới ${160} ký tự`);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Từ khoá"
            name="metaKeywords"
            rules={[{ required: true, message: 'Vui lòng nhập từ khoá' }]}
          >
            <Input.TextArea style={{ resize: 'none' }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="ogImage"
            valuePropName="initialImage"
            trigger="onChange"
            label="OG Image (1200x630)"
          >
            <UploadImage
              uploadInfo={{
                type: 'landingpage',
                typeId: data.id,
              }}
              uploadText="Click hoặc kéo thả hình để upload OG:Image (1200x630)"
            />
          </Form.Item>
        </Col>
      </Row>
    </CustomPopup>
  );
};
