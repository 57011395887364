import { IAuth } from 'interfaces/auth.interface';
import { START_LOGIN, LOGIN_SUCCESSFUL, LOGIN_FAILED, LoginSuccessful } from './types';

export const startLogin = () => ({
  type: START_LOGIN,
});

export const loginSuccessful = (data: IAuth): LoginSuccessful => ({
  type: LOGIN_SUCCESSFUL,
  payload: data,
});

export const loginFailed = (errorMessage: string) => ({
  type: LOGIN_FAILED,
  payload: errorMessage,
});
