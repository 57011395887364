/* eslint-disable camelcase */
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DateFormat } from 'constants/enum';

export interface FilterFeedbackPage {
  customerPhone: string;
  from: string;
  to: string;
}

interface Props {
  onFilter: (filter: FilterFeedbackPage) => void;
}

const { RangePicker } = DatePicker;

export const FilterView: React.FC<Props> = (props) => {
  const { onFilter } = props;
  const [form] = Form.useForm();

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (!isEmpty(params)) {
      const { from, to, ...rest } = params || {};
      form.setFieldsValue({
        ...rest,
        from:
          from && to
            ? [moment(from, DateFormat.clientSide), moment(to, DateFormat.clientSide)]
            : null,
      });
    }
  }, [params]);

  const onClear = useCallback(() => {
    form.resetFields();
    onFilter({
      customerPhone: '',
      from: '',
      to: '',
    } as FilterFeedbackPage);
  }, [onFilter]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => {
        onFilter({
          ...values,
          from: values?.from?.length ? moment(values.from[0]).format(DateFormat.clientSide) : '',
          to: values?.from?.length ? moment(values.from[1]).format(DateFormat.clientSide) : '',
        });
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Số điện thoại" name="customerPhone">
            <Input placeholder="Nhập SĐT khách hàng" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Từ ngày - đến ngày"
            name="from"
            rules={[
              {
                validator: (_, value: any[]) => {
                  if (!value) return Promise.resolve();

                  const [start, end] = value;
                  const today = moment();

                  if (end && (end < start || end <= today)) {
                    return Promise.reject('Vui lòng chọn ngày kết thúc hợp lệ');
                  }

                  return Promise.resolve();
                },
                validateTrigger: 'onChange',
              },
            ]}
          >
            <RangePicker
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              format={DateFormat.clientSide}
              showTime={false}
            />
          </Form.Item>
        </Col>

        <Col
          span={24}
          className="align-items-center justify-content-end"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button htmlType="submit" className="secondary-button">
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={onClear}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
