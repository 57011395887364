import { Col, Form, Input, Row, Select, message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { StoreIds } from 'constants/enum';
import { useCreateCollection } from '../hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateCollectionModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;
  const [form] = Form.useForm();
  const history = useHistory();

  const { isCreating, onCreate } = useCreateCollection();

  const onSubmit = useCallback(async () => {
    try {
      const values = form.getFieldsValue();
      const { id } = await onCreate({
        data: { ...values, active: false },
      });
      message.success('Thêm collection thành công');
      history.push(`/bo-suu-tap/${id}`);
    } catch (error: any) {
      message.error(error?.message || error);
    }
  }, []);

  return (
    <CustomPopup
      title="Thêm Collection"
      formHook={form}
      isOpen={isOpen}
      onCloseModal={onClose}
      isSaving={isCreating}
      handleSaveButton={onSubmit}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Tiêu đề Collection"
            name="title"
            rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Chọn cửa hàng"
            name="storeId"
            rules={[{ required: true, message: 'Vui lòng chọn cửa hàng' }]}
          >
            <Select
              className="ant-select-lg"
              options={[
                { value: StoreIds.mainguyen, label: 'Mai Nguyen' },
                { value: StoreIds.rider, label: 'Mai Nguyen Rider' },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </CustomPopup>
  );
};
