/* eslint-disable @typescript-eslint/no-shadow */
export const num2Word2 = function () {
  const t = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'],
    r = function (r: any, n: any) {
      let o = '';
      const a = Math.floor(r / 10);
      const e = r % 10;
      return (
        a > 1
          ? ((o = ' ' + t[a] + ' mươi'), 1 == e && (o += ' mốt'))
          : 1 == a
          ? ((o = ' mười'), 1 == e && (o += ' một'))
          : n && e > 0 && (o = ' lẻ'),
        5 == e && a >= 1
          ? (o += ' lăm')
          : 4 == e && a >= 1
          ? (o += ' tư')
          : (e > 1 || (1 == e && 0 == a)) && (o += ' ' + t[e]),
        o
      );
    },
    n = function (n: number, o: number) {
      let a = '';
      const e = Math.floor(n / 100);
      n = n % 100;
      return o || e > 0 ? ((a = ' ' + t[e] + ' trăm'), (a += r(n, !0))) : (a = r(n, !1)), a;
    },
    o = function (t: number, r: any) {
      let o = '';
      const a = Math.floor(t / 1e6);
      t = t % 1e6;
      a > 0 && ((o = n(a, r) + ' triệu'), (r = !0));
      const e = Math.floor(t / 1e3);
      t = t % 1e3;
      return e > 0 && ((o += n(e, r) + ' ngàn'), (r = !0)), t > 0 && (o += n(t, r)), o;
    };
  return {
    convert: function (r: any) {
      if (0 == r) return t[0];
      let n = '',
        a = '',
        ty;
      do
        (ty = r % 1e9),
          (r = Math.floor(r / 1e9)),
          (n = r > 0 ? o(ty, !0) + a + n : o(ty, !1) + a + n),
          (a = ' tỷ');
      while (r > 0);
      return n.trim();
    },
  };
};
