import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Input, Switch, Button, Form, Collapse, Row, Col, Typography, Radio } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { isEmpty, sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  API_GET_POST_MENUS,
  API_GET_MENU_LINKS_IN_MENU,
  API_GET_MENU_BLOCKS_IN_MENU,
  API_UPDATE_DELETE_MENU,
} from 'constants/api/menu.api';
import { AlignType } from 'constants/enum';
import { IImage } from 'interfaces/image.interface';
import { IMenuBlock } from 'interfaces/menu-block.interface';
import { IMenuLink } from 'interfaces/menu-link.interface';
import { IMenu, IAttributeMenu } from 'interfaces/product-menu.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { MenuModel } from 'models/menu.model';
import { setMenuLinks } from 'redux/menu/actions';
import { AppState } from 'redux/store';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { TreeView } from 'ui/components/MaiNguyen/TreeView';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { deleteModal } from 'utils/modals';
import { showErrorMsg, uploadFile } from 'utils/utils';

import { MenuLinksLevel1 } from './MenuLinksLevel1';
import { SubMenuBlock } from './SubMenuBlock';
import './ProductsMenu.scss';

const newMenu = new MenuModel({});

const ProductsMenu = () => {
  const { menuLinks: links } = useSelector((state: AppState) => state.menuStore);
  const [menus, setMenus] = useState<IMenu[]>([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [previewMenuImage, setPreviewImage] = useState<IImage>(null);
  const [menuTitle, setMenuTitle] = useState('');
  const [menu, setMenu] = useState<IAttributeMenu>(newMenu);
  const [menuId, setMenuId] = useState('');
  const [reloadList, setReloadList] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [fileImage, setFileImage] = useState(null);

  // menu link level 1
  const [menuLinks, setMenuLinksList] = useState<IMenuLink[]>([]);
  // menu blocks
  const [menuBlocks, setMenuBlocks] = useState<IMenuBlock[]>([]);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (reloadList) {
      api.get({ endpoint: `${API_GET_POST_MENUS}?perPage=all` }).then((res) => {
        const menuData = sortBy(res?.data, 'attributes.position').map((item: IMenu) => ({
          ...item,
          key: item.id,
          title: item.attributes.title,
        }));
        setMenus(menuData as IMenu[]);
        setReloadList(false);
      });
    }
  }, [reloadList]);

  const handleSelectedTreeKeys = useCallback((keys) => {
    setSelectedKeys(keys);
  }, []);

  const handleAddNewMenu = useCallback(() => {
    setMenu(newMenu);
    setMenuId('');
    setMenuTitle('');
    setPreviewImage(null);
    setSelectedKeys([]);
    setMenuLinksList([]);
    form.resetFields();
  }, []);

  const handleSelectLeaf = useCallback(
    async (node) => {
      const { id, attributes, selected } = node;
      if (!selected) {
        setMenuId(id);
        const currentMenu = new MenuModel(attributes);
        setMenuTitle(currentMenu.title);
        setPreviewImage(attributes.image);

        if (currentMenu.isHot) {
          form.setFieldsValue({ ...currentMenu, icon: 'hot' });
        } else if (currentMenu.isNew) {
          form.setFieldsValue({ ...currentMenu, icon: 'new' });
        } else {
          form.setFieldsValue({ ...currentMenu, icon: '' });
        }
        setMenu(currentMenu);
        await Promise.all([
          api.get({ endpoint: `${API_GET_MENU_LINKS_IN_MENU.replace('{id}', id)}?page=1` }),
          api.get({ endpoint: API_GET_MENU_BLOCKS_IN_MENU.replace('{id}', id) }),
        ]).then(([resLinks, reBlocks]) => {
          setMenuLinksList(resLinks?.data);
          dispatch(setMenuLinks(resLinks?.data));
          setMenuBlocks(reBlocks?.data);
        });
      } else {
        handleAddNewMenu();
      }
    },
    [menus, handleAddNewMenu],
  );

  const handleChangeValueMenu = useCallback(
    (_, values) => {
      dispatch(setIsEditing(true));
      const { icon } = values;
      const currentMenu = new MenuModel({ ...menu, ...values });
      if (icon) {
        if (icon === 'hot') {
          currentMenu.isHot = true;
          currentMenu.isNew = false;
        } else {
          currentMenu.isHot = false;
          currentMenu.isNew = true;
        }
      } else {
        currentMenu.isHot = false;
        currentMenu.isNew = false;
      }
      form.setFieldsValue(currentMenu);
      setMenu(currentMenu);
    },
    [menu],
  );

  const handleDeleteImageSuccess = () => {
    setPreviewImage(null);
    setReloadList(true);
  };

  const handleSaveMenu = useCallback(async () => {
    try {
      const currentMenu = new MenuModel(menu);
      setLoadingBtn(true);
      if (menuId) {
        const linksName = sortBy(links, 'attributes.position').map((item) => item.attributes.title);
        if (!isEmpty(linksName)) {
          currentMenu.title = linksName.join(', ');
          form.setFieldsValue(currentMenu);
        }

        await api.put({
          endpoint: API_UPDATE_DELETE_MENU.replace('{id}', menuId),
          data: currentMenu,
        });
        await uploadFile(fileImage, 'menu', menuId).then((attr) => {
          setPreviewImage({ ...attr?.resize, id: attr?.id });
        });
        dispatch(setIsEditing(false));
        setLoadingBtn(false);
        setReloadList(true);
      } else {
        await api.post({ endpoint: API_GET_POST_MENUS, data: currentMenu }).then(async (res) => {
          setMenuId(res?.data.id);
          await uploadFile(fileImage, 'menu', res?.data.id).then((attr) => {
            setPreviewImage({ ...attr?.resize, id: attr?.id });
          });
        });
        dispatch(setIsEditing(false));
        setLoadingBtn(false);
        setReloadList(true);
      }
    } catch (err) {
      setLoadingBtn(false);
      showErrorMsg(err);
    }
  }, [menu, menuId, fileImage, links]);

  const handleDragChangePosition = useCallback(async (_, menuItem: IMenu, __, position: number) => {
    try {
      const currentMenu = new MenuModel({ ...menuItem.attributes, position });
      await api.put({
        endpoint: API_UPDATE_DELETE_MENU.replace('{id}', menuItem.id),
        data: currentMenu,
      });
      setReloadList(true);
    } catch (err) {
      setReloadList(false);
      showErrorMsg(err);
    }
  }, []);

  const handleDeleteMenu = useCallback(() => {
    if (menuId) {
      deleteModal({
        title: 'Bạn có muốn xóa menu này?',
        onDeleteCb: async () => {
          try {
            await api.remove({ endpoint: API_UPDATE_DELETE_MENU.replace('{id}', menuId) });
            setReloadList(true);
            setMenu(newMenu);
            setMenuId('');
            setMenuTitle('');
            setPreviewImage(null);
            setSelectedKeys([]);
            form.resetFields();
            dispatch(setIsEditing(false));
          } catch (err) {
            showErrorMsg(err);
          }
        },
      });
    }
  }, [menuId]);

  const handleSetFileImage = useCallback((file: RcFile) => {
    setFileImage(file);
    dispatch(setIsEditing(true));
  }, []);

  return (
    <>
      <LayoutDetail
        pageTitle="Hiển thị menu"
        formHook={form}
        handleValuesChange={handleChangeValueMenu}
        isAddNew
        breadcrumbs={[
          {
            title: 'Hiển thị menu',
            route: '/menu/danh-muc-san-pham',
          },
        ]}
        wrapperCard={false}
        pageClassName="display-menu-page"
      >
        <Row gutter={8}>
          <Col span={6}>
            <Card
              title={
                <div className="tree-card">
                  <div className="card-title">
                    <Typography.Title level={3}>Level 1</Typography.Title>
                  </div>
                  <div className="card-button">
                    <Button icon={<PlusOutlined />} onClick={handleAddNewMenu}>
                      Thêm
                    </Button>
                  </div>
                </div>
              }
            >
              <TreeView
                treeData={menus}
                handleSelectLeaf={handleSelectLeaf}
                handleSelecteKeys={handleSelectedTreeKeys}
                handleDropContent={handleDragChangePosition}
                selectedKeys={selectedKeys}
              />
            </Card>
          </Col>
          <Col span={18}>
            <Card
              title={
                <Row justify="space-between" align="middle">
                  <Col span={12}>
                    <Typography.Title level={3}>{menuTitle}</Typography.Title>
                  </Col>
                  <Col span={12} style={{ textAlign: AlignType.right }}>
                    {menuId && (
                      <Button
                        className="mr-3"
                        icon={<DeleteOutlined />}
                        title="Xóa menu"
                        onClick={handleDeleteMenu}
                      >
                        Xóa
                      </Button>
                    )}
                    <ThemeButton loading={loadingBtn} onClick={handleSaveMenu}>
                      Lưu
                    </ThemeButton>
                  </Col>
                </Row>
              }
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Tiêu đề" name="title">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Vị trí" name="position">
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Hiển thị" name="active" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Chọn 1 trong 2 ICON" name="icon" initialValue="">
                    <Radio.Group>
                      <Radio key="icon-fire" value="hot">
                        <strong>ICON FIRE</strong>
                      </Radio>
                      <Radio key="icon-new" value="new">
                        <strong>ICON NEW</strong>
                      </Radio>
                      <Radio key="none" value="">
                        <strong>NONE</strong>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {menuId && (
                <Collapse>
                  <Collapse.Panel key="menu-image" header="Hình ảnh menu">
                    <UploadFile
                      previewImage={previewMenuImage}
                      handleUploadImage={handleSetFileImage}
                      handleDeleteImageSuccess={handleDeleteImageSuccess}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel key="menu-lv1" header="Menu Links (Level 1)">
                    <MenuLinksLevel1 menuLinks={menuLinks} menuId={parseInt(menuId)} />
                  </Collapse.Panel>
                  <Collapse.Panel key="menu-lv2" header="Sub menu (Level 2)">
                    <SubMenuBlock menuBlocks={menuBlocks} menuId={parseInt(menuId)} />
                  </Collapse.Panel>
                </Collapse>
              )}
            </Card>
          </Col>
        </Row>
      </LayoutDetail>
    </>
  );
};

export default ProductsMenu;
