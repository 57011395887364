import { Col, Input, Row, Select, Spin } from 'antd';
import { clone, debounce, uniqBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { API_GET_PRODUCTS } from 'constants/api/product.api';
import { IPrdCategory } from 'interfaces/product-category.interface';
import { IProduct } from 'interfaces/product.interface';
import { AppState } from 'redux/store';
import * as api from 'services/api.service';
import { showErrorMsg } from 'utils/utils';

// Components
import { SearchedTable } from './SearchedTable';
import { SelectedTable } from './SelectedTable';
import { CustomTreeSelect } from '../MaiNguyen/CustomTreeSelect';

import './index.scss';

const { Search } = Input;

interface SelectProductsSectionInterface {
  onSelectProduct: (products: IProduct[], addedProduct?: IProduct) => void;
  selectedProducts: IProduct[];
  searchByType?: string;
  productId?: string;
  loading?: boolean;
  isSecondhand?: boolean;
  addProductsByBrands?: boolean;
  selectedBrands?: number[];
  onSelectBrands?: (brandIds: number[]) => void;
  onSelectMultipleProducts?: (products: IProduct[]) => void;
  isPromotion?: boolean;
  productCategories?: IPrdCategory[];
}

const productReducer = (state: AppState) => state.productReducers;

export const SelectProductsSection: FC<SelectProductsSectionInterface> = ({
  onSelectProduct,
  selectedProducts,
  productId = '',
  loading = false,
  searchByType = '',
  isSecondhand = false,
  addProductsByBrands = false,
  selectedBrands = [],
  onSelectBrands = null,
  onSelectMultipleProducts = null,
  isPromotion = false,
  productCategories,
}) => {
  const { brands } = useSelector(productReducer);
  const [searchedData, setSearchedData] = useState<IProduct[]>([]);
  const [selectedData, setSelectedData] = useState<IProduct[]>(selectedProducts);
  const [isSearching, setIsSearching] = useState(false);
  const [isAddingProducts, setIsAddingProducts] = useState(false);
  const [categoryId, setCategoryId] = useState('');

  useEffect(() => {
    setSelectedData(selectedProducts);
  }, [selectedProducts]);

  const onSearch = useCallback(
    async (value: string) => {
      if (value.length >= 3) {
        setIsSearching(true);
        const productTypeQs = searchByType ? `&type=${searchByType}` : '';
        const secondhandQs = isSecondhand ? `&isSecondhand=1` : '';
        const filterCateId = categoryId ? `&categoryId=${categoryId}` : '';
        await api
          .get({
            endpoint: `${API_GET_PRODUCTS}?name=${value}${productTypeQs}${secondhandQs}${filterCateId}&perPage=30${
              productId ? `&ignoreId=${productId}` : ''
            }`,
          })
          .then((res) => {
            setSearchedData(res?.data.filter((item: IProduct) => item.attributes.active));
            setIsSearching(false);
          })
          .catch((err) => {
            showErrorMsg(err);
          });
      }
    },
    [isSecondhand, searchByType, categoryId],
  );

  const isDisableRow = (object: IProduct): boolean => {
    const indexs = selectedData?.map((o) => o.id);
    return indexs?.includes(object.id);
  };

  const handleSelectProduct = useCallback(
    (prd: IProduct) => {
      if (!isDisableRow(prd)) {
        setSelectedData([prd, ...selectedData]);
        onSelectProduct([prd, ...selectedData], prd);
      }
    },
    [isDisableRow, selectedData, onSelectProduct],
  );

  const onRemoveSelectedProd = useCallback(
    (prod: IProduct) => {
      const clonedSelectedData = clone(selectedData);
      const selectedProductIndex = clonedSelectedData.findIndex((item) => item.id === prod.id);
      if (selectedProductIndex > -1) {
        clonedSelectedData.splice(selectedProductIndex, 1);
      }
      setSelectedData(clonedSelectedData);
      onSelectProduct(clonedSelectedData);
    },
    [selectedData],
  );

  const handleSelectBrand = useMemo(() => {
    const findProductsByBrand = async (brandId: number) => {
      setIsAddingProducts(true);
      try {
        const promotionQs = isPromotion ? '&ignoreStatusId=9' : '';
        const products = await api.get({
          endpoint: `${API_GET_PRODUCTS}?perPage=all&brandId=${brandId}${promotionQs}`,
        });
        setIsAddingProducts(false);
        const productList = uniqBy(selectedData.concat(products?.data), 'id');
        setSelectedData(productList);
        onSelectMultipleProducts && onSelectMultipleProducts(productList);
      } catch (error) {
        setIsAddingProducts(false);
        showErrorMsg(error);
      }
    };

    return debounce(findProductsByBrand, 100);
  }, [selectedData, onSelectMultipleProducts]);

  const handleChangeBrands = (values: number[]) => {
    onSelectBrands && onSelectBrands(values);
    onSelectMultipleProducts && onSelectMultipleProducts(selectedData);
  };

  const handleDeselectBrand = useCallback(
    (brandId: number) => {
      const productList = selectedData.filter((p) => p.attributes.brandId !== brandId);
      setSelectedData(productList);
      onSelectMultipleProducts && onSelectMultipleProducts(productList);
    },
    [selectedData, onSelectMultipleProducts],
  );

  const handleChangeParentId = useCallback((value: string) => {
    setCategoryId(value);
  }, []);

  return (
    <div className="relative">
      <Row gutter={12}>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={12}>
              <b className="mb-1">Nhóm sản phẩm</b>
              <CustomTreeSelect
                data={productCategories}
                selectedValue={categoryId}
                handleSelect={handleChangeParentId}
              />
            </Col>
            <Col span={12}>
              <b className="mb-1">&nbsp;</b>
              <Search placeholder="SKU/Tên sản phẩm" onSearch={onSearch} />
            </Col>
          </Row>
        </Col>
        {addProductsByBrands && (
          <Col span={12}>
            <Select
              showSearch
              mode="multiple"
              optionFilterProp="children"
              size="large"
              placeholder="Chọn thương hiệu để thêm sản phẩm"
              disabled={isAddingProducts}
              value={selectedBrands}
              loading={isAddingProducts}
              onSelect={handleSelectBrand}
              onChange={handleChangeBrands}
              onDeselect={handleDeselectBrand}
            >
              {brands?.map((brand) => (
                <Select.Option key={brand.id} value={parseInt(brand.id)}>
                  {brand.attributes.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        )}
      </Row>
      {loading && (
        <div className="loading-bg">
          <Spin />
        </div>
      )}
      <Row gutter={12} className="select-products-section">
        <Col span={12}>
          <div className="searched-product-wrap">
            <SearchedTable
              rowClassName={(record) => (isDisableRow(record) ? 'row-disable' : '')}
              data={searchedData}
              loading={isSearching}
              onSelectProduct={handleSelectProduct}
            />
          </div>
        </Col>
        <Col className="selected-product-col" span={12}>
          <div style={{ padding: '16px' }}>
            <SelectedTable
              data={selectedData}
              onRemoveSelectedProd={onRemoveSelectedProd}
              loading={isAddingProducts}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
