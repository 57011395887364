import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { CustomEditor } from '../../CustomEditor';

interface TopHeaderProps {
  data?: TopHeaderMessage[];
  onChange?: (data: TopHeaderMessage[]) => void;
}

export interface TopHeaderMessage {
  id: string;
  title: string;
  link: string;
  target: string;
}

export const TopHeader: React.FC<TopHeaderProps> = (props) => {
  const { data, onChange } = props;
  const [messages, setMessages] = useState<TopHeaderMessage[]>(data || []);

  useEffect(() => {
    onChange && onChange(messages);
  }, [JSON.stringify(messages)]);

  const onAddMsg = useCallback(() => {
    setMessages((prev) => [
      {
        id: uuid(),
        title: '',
        link: '/',
        target: 'not',
      },
      ...prev,
    ]);
  }, []);

  const onValuesChange = useCallback(
    (fieldKey: keyof TopHeaderMessage, value: string, itemIndex: number) => {
      const currentBankAccount = messages[itemIndex];
      currentBankAccount[fieldKey] = value as any;
      setMessages([...messages]);
    },
    [messages],
  );

  return (
    <div className="top-header-block">
      <div className="toolbar">
        <div className="text-right">
          <Button onClick={onAddMsg} icon={<PlusOutlined />}>
            Thêm thông báo
          </Button>
        </div>
      </div>

      {messages.map((item, i) => {
        return (
          <div key={`top-header-${item.id}`} className="message-item">
            <Row gutter={16} className="mt-3 mb-3">
              <Col span={24}>
                <Form.Item label="Tiêu đề">
                  <CustomEditor
                    editorId={`top-header-editor-${item.id}`}
                    mainContent={item.title}
                    handleChangeMainContent={(val) => onValuesChange('title', val, i)}
                    metaContent={false}
                    listContent={false}
                    showHeading={false}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Link">
                  <Input
                    value={item.link}
                    onChange={(e) => onValuesChange('link', e.target.value, i)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Target">
                  <Radio.Group
                    value={item.target}
                    onChange={(e) => onValuesChange('target', e.target.value, i)}
                  >
                    <Radio value="_blank">_blank</Radio>
                    <Radio value="not">not</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col className="text-right" span={8}>
                <Button
                  className="mt-2"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setMessages((prev) => prev.filter((_, pi) => pi !== i));
                  }}
                >
                  Xóa
                </Button>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};
