import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPageData } from 'services/pageData.service';
import { poster } from 'services/swr.utils';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { API_SETTINGS } from 'constants/api/product.api';

/**
 *
 * @returns
 */
export const useSaveAccessToken = () => {
  const { isMutating, trigger } = useSWRMutation(API_SETTINGS, poster);

  return {
    isSaving: isMutating,
    onSaveToken: trigger,
  };
};

/**
 *
 * @returns
 */
export const useFetchAccessToken = () => {
  const dispatch = useDispatch();

  const { data, isLoading, mutate } = useSWR(API_SETTINGS, (url: string) =>
    getPageData(url, dispatch),
  );

  useEffect(() => {
    return () => {
      mutate(null, { revalidate: true });
    };
  }, []);

  return {
    isFetching: isLoading,
    refetch: mutate,
    data: data?.data,
  };
};
