import { TreeSelect } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { isEmpty } from 'lodash';
import { useState, useCallback, FC, useEffect } from 'react';
import { filteredTreeData, replaceVie } from 'utils/utils';

interface CustomTreeSelectInterface {
  data: any[];
  selectedValue?: string;
  handleSelect?: (value: string) => void;
  valueKey?: string;
  titleKey?: string;
  filterKey?: string;
  size?: SizeType;
}

export const CustomTreeSelect: FC<CustomTreeSelectInterface> = ({
  data,
  filterKey = 'filterKey',
  size = 'large',
  selectedValue,
  handleSelect,
}) => {
  const [treeData, setTreeData] = useState(data);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (isEmpty(treeData)) {
      setTreeData(data);
    }
  }, [data]);

  const handleChangeSearchValue = useCallback(
    (value: string) => {
      setSearchValue(value);
      setTreeData(filteredTreeData(data, value));
    },
    [data],
  );

  const handleClearData = useCallback(() => {
    handleSelect('');
  }, [handleSelect]);

  return (
    <TreeSelect
      size={size}
      showSearch
      allowClear
      optionFilterProp="filterKey"
      treeNodeFilterProp={filterKey}
      treeDefaultExpandAll
      searchValue={replaceVie(searchValue)}
      value={selectedValue}
      onSearch={handleChangeSearchValue}
      onSelect={handleSelect}
      treeData={treeData}
      loading={isEmpty(treeData)}
      onClear={handleClearData}
    />
  );
};
