import queryString from 'query-string';

// Helper: apply filter to url
export const assignFilterPreOrderValueToURL = (currentState: {
  url?: string;
  page?: number;
  searchValue?: string;
  sku?: string;
}) => {
  const params = {
    url: currentState.url || '',
    page: currentState.page,
    searchValue: currentState.searchValue || '',
    sku: currentState.sku || '',
  };

  const queryParams = {
    page: params.page,
    search: params.searchValue,
    sku: params.sku,
  };

  return queryString.stringifyUrl({
    url: params.url,
    query: queryParams,
  });
};
