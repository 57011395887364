import { IMe } from 'interfaces/me.interface';
import { FETCH_USER, FETCH_USER_SUCCESSFULL, FETCH_USER_FAILED } from './types';

export const fetchUser = () => ({
  type: FETCH_USER,
});

export const fetchUserSuccessful = (payload: IMe) => ({
  type: FETCH_USER_SUCCESSFULL,
  payload,
});

export const fetchUserFailed = () => ({
  type: FETCH_USER_FAILED,
});
