import { IServiceCenterAttribute, IFilterServiceCenter } from 'interfaces/service-center.interface';

export class ServiceCenterModel implements IServiceCenterAttribute {
  name?: string;

  address?: string;

  province?: string;

  phone?: string;

  brandId?: number;

  constructor({ name, address, province, phone, brandId }: IServiceCenterAttribute) {
    this.name = name || '';
    this.address = address || '';
    this.province = province || '';
    this.phone = phone || '000000';
    this.brandId = brandId;
  }
}

export class FilterServiceCenterModel implements IFilterServiceCenter {
  name: string;

  brandId: string;

  constructor({ name, brandId }: IFilterServiceCenter) {
    this.name = name || '';
    this.brandId = brandId || '';
  }
}
