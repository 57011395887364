import { ISize, ISizeAttr } from 'interfaces/size.interface';

export class SizeModel implements ISize {
  id?: string;

  type?: string;

  attributes?: ISizeAttr;

  constructor({ id, type, attributes }: ISize) {
    this.id = id || '';
    this.type = type || '';
    this.attributes = attributes || {
      title: '',
      slug: '',
      sizeGroupId: '',
      active: false,
      data: [],
    };
  }
}

export class SizeModelAttr implements ISizeAttr {
  title?: string;

  sizeGroupId?: string;

  active?: boolean;

  slug?: string;

  data?: any;

  position?: number;

  constructor({ title, sizeGroupId, active, slug, data, position }: ISizeAttr) {
    this.title = title || '';
    this.slug = slug || '';
    this.sizeGroupId = sizeGroupId || '';
    this.active = active !== undefined && active !== null ? active : false;
    this.data = data || [];
    this.position = position || 0;
  }
}
