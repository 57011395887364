import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_GET_ROLES } from 'constants/api/role.api';
import { IRole, IRoleAttribute } from 'interfaces/role.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import { getPageData } from 'services/pageData.service';

const RoleList = () => {
  const [roles, setRoles] = useState<IRole[]>([]);

  const dispatch = useDispatch();

  const columns = [
    {
      title: 'STT',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Role Name',
      dataIndex: 'attributes',
      render: (attr: IRoleAttribute) => <>{attr.displayName}</>,
    },
    {
      title: 'Role',
      dataIndex: 'attributes',
      render: (attr: IRoleAttribute) => <>{attr.name}</>,
    },
  ];

  useEffect(() => {
    getPageData(API_GET_ROLES, dispatch).then((res) => {
      setRoles(res?.data);
    });
  }, []);

  return (
    <LayoutList
      pageTitle="Phân quyền"
      breadcrumbs={[
        {
          title: 'Phân quyền',
          route: 'phan-quyen',
        },
      ]}
      columns={columns}
      dataList={roles}
    />
  );
};

export default RoleList;
