import { useEffect, useState } from 'react';
import { API_GET_POST_BRANCH } from 'constants/api/branch.api';
import { API_GET_POST_PROMOTIONS } from 'constants/api/promotion.api';
import { IBranch } from 'interfaces/branch.interface';
import { IPromotion } from 'interfaces/promotion.interface';
import * as api from 'services/api.service';
import { showErrorMsg } from 'utils/utils';

export const useFetchPreOrderContent = () => {
  const [promotions, setPromotions] = useState<IPromotion[]>([]);
  const [branches, setBranches] = useState<IBranch[]>([]);

  useEffect(() => {
    Promise.all([
      api.get({ endpoint: `${API_GET_POST_PROMOTIONS}?perPage=all` }),
      api.get({ endpoint: `${API_GET_POST_BRANCH}?perPage=all` }),
    ])
      .then(([resPromo, resBranches]) => {
        setPromotions(resPromo?.data);
        setBranches(resBranches?.data);
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, []);

  return { promotions, branches };
};
