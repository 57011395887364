import { Spin, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { IImage } from 'interfaces/image.interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsEditing } from 'redux/uiAction/actions';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { uploadFile } from 'utils/utils';
import { v4 as uuid } from 'uuid';
import './styles.scss';

interface Props {
  initialImage?: IImage;
  uploadText?: string;
  uploadInfo: {
    type: string;
    typeId: string;
    property?: string;
    override?: string;
  };
  onChange?: (image: IImage) => void;
}

export const UploadImage: React.FC<Props> = (props) => {
  const { initialImage, uploadText, uploadInfo, onChange } = props;

  const dispatch = useDispatch();
  const [image, setImage] = useState<IImage>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    if (!image && initialImage) {
      setImage(initialImage);
    }
  }, [initialImage]);

  const onUpload = useCallback(
    async (file: RcFile) => {
      try {
        dispatch(setIsEditing(true));
        setIsUploading(true);
        await uploadFile(
          file,
          uploadInfo.type,
          uploadInfo.typeId,
          uploadInfo?.property,
          uploadInfo?.override,
        )
          .then((attr) => {
            const buildImage = { ...attr, original: attr?.originUrl };
            setImage(buildImage);
            onChange(buildImage);
          })
          .catch(() => {
            setImage(null);
          });
      } catch (error: any) {
        message.error(`${error?.message || error}`);
      } finally {
        setIsUploading(false);
      }
    },
    [uploadInfo, onChange],
  );

  return (
    <div className="upload-image">
      <UploadFile
        typeId={uuid()}
        previewImage={image}
        handleUploadImage={onUpload}
        handleDeleteImageSuccess={() => {
          setImage(null);
          onChange(null);
          dispatch(setIsEditing(true));
        }}
        maxWidth={600}
        uploadText={uploadText}
      />
      {isUploading && (
        <div className="upload-image__loading">
          <Spin />
          <span>Uploading image...</span>
        </div>
      )}
    </div>
  );
};
