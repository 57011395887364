/* eslint-disable camelcase */
import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { memo, useCallback } from 'react';
import { StoreIds, scoreList } from 'constants/enum';
import { ReviewStatus } from 'constants/enum';
import { FilterReviewPage } from 'pages/Members/Review/components/FilterView';
import { getReviewLabelStatus } from 'pages/Members/utils';

interface Props {
  onFilter: (filter: FilterReviewPage) => void;
}

export const ProductFilterReview: React.FC<Props> = memo((props) => {
  const { onFilter } = props;
  const [form] = Form.useForm();

  const onClear = useCallback(() => {
    form.resetFields();
    onFilter({
      customerName: '',
      storeId: '',
      score: '',
      status: null,
    } as FilterReviewPage);
  }, [onFilter]);

  const onSubmitFilter = useCallback(() => {
    onFilter(form.getFieldsValue());
  }, [form, onFilter]);

  return (
    <Form layout="vertical" form={form} className="product-review-filter">
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Tên người dùng" name="customerName">
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Cửa hàng" name="storeId">
            <Select
              className="ant-select-lg"
              options={[
                { value: `${StoreIds.mainguyen}`, label: 'Mai Nguyen' },
                { value: `${StoreIds.rider}`, label: 'Mai Nguyen Rider' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Status" name="status">
            <Select
              className="ant-select-lg"
              options={[
                {
                  value: `${ReviewStatus.pending}`,
                  label: `${getReviewLabelStatus(ReviewStatus.pending)}`,
                },
                {
                  value: `${ReviewStatus.published}`,
                  label: `${getReviewLabelStatus(ReviewStatus.published)}`,
                },
                {
                  value: `${ReviewStatus.locked}`,
                  label: `${getReviewLabelStatus(ReviewStatus.locked)}`,
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Sao" name="score">
            <Select className="ant-select-lg" options={scoreList} />
          </Form.Item>
        </Col>
        <Col
          span={24}
          className="align-items-center justify-content-end"
          style={{ display: 'flex' }}
        >
          <Form.Item>
            <Button onClick={onSubmitFilter} className="secondary-button">
              Filter
            </Button>
          </Form.Item>
          <Form.Item className="ml-2">
            <Button onClick={onClear}>Clear</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
