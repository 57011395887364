import { IArticleCategoryAttribute } from 'interfaces/article.interface';

export class ArticleCategoryModel implements IArticleCategoryAttribute {
  active?: boolean;

  description?: string;

  keywords?: string;

  name?: string;

  slug?: string;

  type?: number;

  parentId?: string;

  constructor({
    active,
    description,
    keywords,
    name,
    slug,
    type,
    parentId,
  }: IArticleCategoryAttribute) {
    this.active = active !== undefined && active !== null ? active : false;
    this.description = description || '';
    this.keywords = keywords || '';
    this.name = name || '';
    this.slug = slug;
    this.type = type || 0;
    this.parentId = parentId || null;
  }
}
