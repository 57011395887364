import { message } from 'antd';
import queryString from 'query-string';

// Constant
import { API_GET_SIZE_GROUPS, API_SIZE_GROUP } from 'constants/api/size.api';
import * as api from 'services/api.service';
import { uploadFile } from 'utils/utils';

// Helper: apply filter to url
export const assignFilterValueToURL = (currentState: {
  url?: string;
  page?: number;
  searchValue?: string;
  sizeGroupId?: string;
  isFilterSizeItems?: boolean;
}) => {
  const params = {
    url: currentState.url || '',
    page: currentState.page,
    searchValue: currentState.searchValue || '',
    sizeGroupId: currentState.sizeGroupId || '',
  };

  let queryParams;
  if (currentState.isFilterSizeItems) {
    // url params for size items
    queryParams = {
      page: params.page,
      search: params.searchValue,
      sizeGroupId: params.sizeGroupId,
    };
  } else {
    // url params for size group
    queryParams = {
      page: params.page,
      search: params.searchValue,
    };
  }

  return queryString.stringifyUrl({
    url: params.url,
    query: queryParams,
  });
};

// Update new size group
export const updateSizeGrp = async (
  params,
  sizeGroupItem,
  isUploadFile,
  isUploadChange,
  imageFile,
) => {
  const { id } = sizeGroupItem;

  await api.put({
    endpoint: API_SIZE_GROUP.replace('{id}', id),
    data: params,
  });
  if (isUploadFile && isUploadChange) {
    await uploadFile(imageFile, 'sizegroup', id, '', '0');
  }

  message.success('Lưu Size Group thành công', 3);
};

// Create new size group
export const createSizeGrp = async (params, imageFile) => {
  const resSizeGroup = await api.post({ endpoint: API_GET_SIZE_GROUPS, data: params });

  const { id } = resSizeGroup.data;
  await uploadFile(imageFile, 'sizegroup', id, '', '0');

  message.success('Tạo mới Size Group thành công', 3);
};
