import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Button, Row, message, Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import { API_GET_ROLES } from 'constants/api/role.api';
import { API_GET_POST_USERS, API_GET_PUT_DELETE_USER_DETAIL } from 'constants/api/users.api';
import { defaultPagination } from 'constants/constants';
import { IPagination } from 'interfaces/pagination.interface';
import { IRole } from 'interfaces/role.interface';
import { IUser, IUserAttribute } from 'interfaces/user.interface';
import LayoutList from 'layouts/LayoutList/LayoutList';
import { UserModel } from 'models/user.model';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';

import './UserList.scss';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleCopyText = useCallback((text: string, result) => {
    if (text && result) {
      message.success('Copy thành công!');
    } else {
      message.error('Copy thất bại!');
    }
  }, []);

  const handleMoveToCreatePage = useCallback(() => {
    history.push('/users/new');
  }, []);

  const getUser = (page: number) => {
    getPageData(`${API_GET_POST_USERS}?page=${page}`, dispatch).then((res) => {
      const pagin = res?.meta?.pagination;
      const userList = res?.data?.map((item: IUser) => new UserModel(item));
      setUsers(userList);
      setPagination({
        current: pagin.currentPage,
        pageSize: pagin.perPage,
        total: pagin.total,
      });
    });
  };

  const handleDeleteUser = useCallback(
    (userId: string) => async () => {
      Modal.confirm({
        title: 'Bạn chắc chắn xóa user này?',
        icon: <ExclamationCircleOutlined style={{ color: '#666' }} />,
        okText: 'Xóa',
        okType: 'danger',
        cancelText: 'Không',
        onOk: async () => {
          try {
            await api.remove({
              endpoint: API_GET_PUT_DELETE_USER_DETAIL.replace('{slug}', userId),
            });
            getUser(pagination.current);
          } catch (err) {
            message.error('Xóa user thất bại!');
          }
        },
      });
    },
    [pagination],
  );

  const handleLockUnlockUser = useCallback(
    (userId: string, active: boolean) => () => {
      Modal.confirm({
        title: `Bạn chắc chắn ${!active ? 'khóa' : 'mở khóa'} user này?`,
        icon: <ExclamationCircleOutlined style={{ color: '#666' }} />,
        okText: `${!active ? 'Khóa' : 'Mở khóa'}`,
        okType: 'danger',
        cancelText: 'Không',
        onOk: async () => {
          try {
            await api.put({
              endpoint: API_GET_PUT_DELETE_USER_DETAIL.replace('{slug}', userId),
              data: {
                active,
              },
            });
            getUser(pagination.current);
          } catch (err) {
            message.error('Thao tác thất bại!');
          }
        },
      });
    },
    [pagination],
  );

  const columns = [
    {
      title: 'STT',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tên',
      dataIndex: 'attributes',
      render: (attributes: IUserAttribute, row: IUser) => (
        <NavLink
          to={{
            pathname: `/users/${row.id}`,
          }}
        >
          {`${attributes.firstName} ${attributes.lastName}`}
        </NavLink>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'attributes',
      render: (attributes: IUserAttribute) => (
        <CopyToClipboard onCopy={handleCopyText} text={attributes.email}>
          <div title="Click để copy" style={{ cursor: 'pointer' }}>
            {attributes.email}
          </div>
        </CopyToClipboard>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'attributes',
      render: (attributes: IUserAttribute) => {
        const { role } = attributes;
        const roleArr = role.map((r) => ({ id: r, title: r }));
        roleArr.forEach((r) => {
          for (let i = 0; i < roles.length; i++) {
            if (r.id === roles[i].attributes.name) {
              r.title = roles[i].attributes.displayName;
            }
          }
        });

        return <>{roleArr?.map((r) => r.title)?.join(', ')}</>;
      },
    },
    {
      title: 'Thao tác',
      render: (row: IUser) => (
        <Row justify="space-around" align="middle">
          {row.attributes.active ? (
            <Button
              title="Khóa"
              className="lock-button"
              onClick={handleLockUnlockUser(row.id, false)}
              icon={<UnlockOutlined style={{ color: '#7cdb86' }} />}
            />
          ) : (
            <Button
              title="Mở khóa"
              onClick={handleLockUnlockUser(row.id, true)}
              icon={<LockOutlined style={{ color: '#DDD' }} />}
            />
          )}
          <Button title="Xóa" onClick={handleDeleteUser(row.id)} icon={<DeleteOutlined />} />
        </Row>
      ),
    },
  ];

  const handleChangePage = useCallback((page: number) => {
    getUser(page);
  }, []);

  useEffect(() => {
    getUser(1);
    getPageData(API_GET_ROLES, dispatch).then((res) => {
      setRoles(res?.data);
    });
  }, []);

  return (
    <div className="user-list-page">
      <LayoutList
        pageTitle="Danh sách"
        showAddButton
        handleClickAddButton={handleMoveToCreatePage}
        breadcrumbs={[
          {
            title: 'Người dùng',
            route: '/users',
          },
        ]}
        dataList={users}
        columns={columns}
        pagination={pagination}
        tableClass="user-list-table"
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default UserList;
