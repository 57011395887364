import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { authReducer } from './auth/reducers';
import { commentReducer } from './comment/reducers';
import { menuReducer } from './menu/reducers';
import { pageDataReducer } from './pages/reducer';
import { productReducers } from './product/reducers';
import { reviewReducer } from './review/reducers';
import { settingsReducer } from './settings/reducer';
import { shippingFieldReducer } from './shipping/reducers';
import { uiReducers } from './uiAction/reducers';
import { userReducer } from './user/reducers';

export const history = createBrowserHistory({ basename: '/' });

const rootReducer = combineReducers({
  settings: settingsReducer,
  pageData: pageDataReducer,
  auth: authReducer,
  user: userReducer,
  shippingFields: shippingFieldReducer,
  productReducers,
  uiActions: uiReducers,
  menuStore: menuReducer,
  router: connectRouter(history),
  comment: commentReducer,
  review: reviewReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  compose(applyMiddleware(routerMiddleware(history), thunk)),
);
