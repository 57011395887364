import { EventPageBlockTemplates } from 'constants/enum';

export const getPositionByTemplate = (template: EventPageBlockTemplates): number => {
  const templates = [
    EventPageBlockTemplates.hero,
    EventPageBlockTemplates.menu,
    EventPageBlockTemplates.countdown,
    EventPageBlockTemplates.productDescription,
    EventPageBlockTemplates.listOfProducts,
    EventPageBlockTemplates.contactUs,
    EventPageBlockTemplates.productImageSlider,
    EventPageBlockTemplates.productVideos,
    EventPageBlockTemplates.comments,
    EventPageBlockTemplates.news,
  ];

  return templates.indexOf(template) + 1;
};
