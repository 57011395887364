import { FC, useCallback, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import ColorUtils from 'utils/ColorUtils';

import PickerField from './PickerField/PickerFileld';

interface PickerColor {
  color: string;
  contrast: string;
}

export type PickerTuple = [PickerColor, PickerColor?];

const getContrast = (hexColor: string) => {
  if (!hexColor) {
    return null;
  }

  const [r, g, b] = ColorUtils.hexToRgb(hexColor);
  return ColorUtils.checkContrastColor(r, g, b);
};

const getColorTuple = (firstColor: string, secondColor: string): PickerTuple => [
  {
    color: firstColor,
    contrast: getContrast(firstColor),
  },
  {
    color: secondColor,
    contrast: getContrast(secondColor),
  },
];

interface ColorPickerProps {
  firstColor?: string;
  secondColor?: string;
  opened?: boolean;
  onColorChange?: (colors: PickerTuple) => void;
  className?: string;
}

const ColorPicker: FC<ColorPickerProps> = ({
  firstColor,
  secondColor,
  opened,
  onColorChange,
  className,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(opened);
  const [secondOpened, setSecondOpened] = useState<boolean>(opened);

  const handlePickerOpen = () => setIsOpen(true);
  const handleOpenSecond = () => setSecondOpened(true);

  const handleFirstColorChange = useCallback(
    (color: ColorResult) => {
      onColorChange && onColorChange(getColorTuple(color.hex, secondColor));
    },
    [onColorChange],
  );

  const handleSecondColorChange = useCallback(
    (color: ColorResult) => {
      onColorChange && onColorChange(getColorTuple(firstColor, color.hex));
    },
    [onColorChange],
  );

  const handleClose = () => {
    setIsOpen(false);
    setSecondOpened(false);
  };

  return (
    <>
      <PickerField
        savedColor={firstColor}
        secondColor={secondColor}
        onOpenPicker={handlePickerOpen}
        onOpenSecond={handleOpenSecond}
        className={className}
      />

      {isOpen && (
        <>
          <div className="picker-cover" onClick={handleClose} />
          <SketchPicker
            onChangeComplete={handleFirstColorChange}
            color={firstColor}
            presetColors={[]}
            disableAlpha
          />
        </>
      )}

      {secondOpened && (
        <>
          <div className="picker-cover" onClick={handleClose} />
          <SketchPicker
            onChangeComplete={handleSecondColorChange}
            color={secondColor}
            presetColors={[]}
            disableAlpha
          />
        </>
      )}
    </>
  );
};

export default ColorPicker;
