import { Form } from 'antd';
import { isEmpty, debounce } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Constant
import { API_GET_PREORDERS, API_GET_PREORDER } from 'constants/api/pre-order.api';
import {
  API_GET_PRODUCTS,
  API_GET_PRODUCT_DETAIL,
  API_GET_VARIANT_PRODUCTS,
} from 'constants/api/product.api';
import { defaultPagination } from 'constants/constants';
import { DateFormat } from 'constants/enum';
import { assignFilterPreOrderValueToURL } from 'helpers/preOrders';

// Interface
import { IPagination } from 'interfaces/pagination.interface';
import { IAttributePreOrder, IPreOrder } from 'interfaces/pre-order.interface';
import { IProduct } from 'interfaces/product.interface';
import { PreOrderModel } from 'models/pre-order.model';
import * as api from 'services/api.service';
import { deleteModal } from 'utils/modals';
import { showErrorMsg } from 'utils/utils';

export const defaultFilterParams = {
  title: '',
  sku: '',
};

export type FormFilterPreOrder = {
  title: string;
  sku: string;
};

export const newPreOrder = new PreOrderModel({});

export const useGetPreOrders = (
  currentPage?: number,
  isReloading?: boolean,
  formFilter?: FormFilterPreOrder,
) => {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [preOrders, setPreOrders] = useState<IPreOrder[]>([]);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  // get url params
  const getPreOrdersApiParams = useMemo(() => {
    const { title, sku } = formFilter;

    return queryString.stringify(
      {
        page: currentPage,
        title: title,
        sku: sku,
      },
      { skipEmptyString: true },
    );
  }, [currentPage, formFilter]);

  // apply filter value to url
  const onAssignFilterValueToURL = () => {
    const { title, sku } = formFilter;

    history.push(
      assignFilterPreOrderValueToURL({
        url: location.pathname,
        page: currentPage,
        searchValue: title,
        sku: sku,
      }),
    );
  };

  // get list preorders
  const getPreorders = async () => {
    try {
      setIsLoading(true);

      const resPreorders = await api.get({
        endpoint: `${API_GET_PREORDERS}?${getPreOrdersApiParams}`,
      });

      const { data, meta } = resPreorders;
      const { pagination: pagin } = meta;
      const { currentPage: curPage, perPage, total } = pagin || {};

      setPreOrders(data);
      setPagination({
        current: curPage,
        pageSize: perPage,
        total: total,
      });
      onAssignFilterValueToURL();
      setIsLoading(false);
    } catch (error) {
      showErrorMsg(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPreorders();
  }, [currentPage, formFilter]);

  useEffect(() => {
    if (isReloading) {
      getPreorders();
    }
  }, [isReloading]);

  return { isLoading, preOrders, pagination, getPreorders };
};

export const useGetVariantProducts = () => {
  const [variantProducts, setVariantProducts] = useState<IProduct[]>([]);
  const [selectedVariantProducts, setSelectedVariantProducts] = useState<IProduct[]>([]);
  const [attrVariantProds, setAttrVariantProds] = useState<IAttributePreOrder['products']>([]);
  const [isLoading, setIsLoading] = useState(false);

  const resetVariantProducts = () => {
    setVariantProducts([]);
    setSelectedVariantProducts([]);
  };

  const getVariantProducts = async (
    productId: string = '',
    variantProds: IAttributePreOrder['products'],
  ) => {
    setIsLoading(true);
    if (productId) {
      await api
        .get({
          endpoint: `${API_GET_VARIANT_PRODUCTS.replace('{id}', productId)}?perPage=all`,
        })
        .then((res) => {
          setVariantProducts(res?.data);
          if (isEmpty(res?.data)) {
            setSelectedVariantProducts([]);
          } else {
            if (isEmpty(variantProds)) {
              setSelectedVariantProducts(res?.data);
              setAttrVariantProds(variantProds);
            } else {
              setSelectedVariantProducts(
                variantProds.map((item) => res?.data?.find((p) => p.id === item.id.toString())),
              );
            }
          }

          setIsLoading(false);
        });
    } else {
      resetVariantProducts();
      setIsLoading(false);
    }
  };

  return {
    variantProducts,
    selectedVariantProducts,
    attrVariantProds,
    isLoading,
    resetVariantProducts,
    getVariantProducts,
  };
};

export const useGetProducts = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const resetProducts = () => {
    setProducts([]);
  };

  const getProductById = async (productId: number) => {
    setIsLoading(true);
    await api
      .get({
        endpoint: API_GET_PRODUCT_DETAIL.replace('{id}', productId.toString()),
      })
      .then((res) => {
        setIsLoading(false);
        setProducts([res?.data]);
      })
      .catch((err) => {
        setProducts([]);
        setIsLoading(false);
        showErrorMsg(err);
      });
  };

  const onSearchProduct = useMemo(() => {
    const fetchProducts = async (value: string) => {
      setIsLoading(true);
      if (value) {
        await api
          .get({ endpoint: `${API_GET_PRODUCTS}?page=1&name=${value}` })
          .then((res) => {
            setIsLoading(false);
            setProducts(res?.data);
          })
          .catch((err) => {
            setIsLoading(false);
            setProducts([]);
            showErrorMsg(err);
          });
      } else {
        setIsLoading(false);
        setProducts([]);
      }
    };
    return debounce(fetchProducts, 800);
  }, []);

  return { products, isLoading, resetProducts, getProductById, onSearchProduct };
};

export const useDeletePreOrder = () => {
  const [isDeleting, setIsDeleting] = useState(false);

  // delete preorder
  const deletePreOrder = useCallback(async (id: string) => {
    setIsDeleting(false);
    deleteModal({
      title: 'Bạn có muốn xóa pre-order này?',
      onDeleteCb: async () => {
        await api.remove({ endpoint: API_GET_PREORDER.replace('{id}', id) });
        // refresh list
        setIsDeleting(true);
        setTimeout(() => {
          setIsDeleting(false);
        }, 500);
      },
    });
  }, []);

  return { isDeleting, deletePreOrder };
};

export const useFormPreOrder = (
  handleSetPreOrder?: (preOrdItem: IAttributePreOrder) => void,
  getProductById?: (productId: number) => void,
  getVariantProducts?: (productId: string, variantProds: IAttributePreOrder['products']) => void,
  preOrder?: IAttributePreOrder,
) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addFormValues = () => {
    form.setFieldsValue(newPreOrder);
  };

  const resetForm = () => {
    form.resetFields();
  };

  const onChangeValue = useCallback(
    async (field, values) => {
      const fieldKey = Object.keys(field);
      const currentPreOrder = new PreOrderModel({ ...preOrder, ...values });
      currentPreOrder.deliveryDate = currentPreOrder.deliveryDate
        ? moment(currentPreOrder.deliveryDate).format(DateFormat.serverSide)
        : null;
      currentPreOrder.moneyDeposit = parseFloat(
        currentPreOrder.moneyDeposit.toString().replaceAll(',', ''),
      );
      form.setFieldsValue(values);
      handleSetPreOrder(currentPreOrder);

      // get variant products
      if (fieldKey[0] === 'productId') {
        await getVariantProducts(currentPreOrder.productId?.toString(), []);
      }
    },
    [preOrder, getVariantProducts],
  );

  const selectedVariantProds = useCallback(
    (vProds: IProduct[]) => {
      const currentPreOrder = new PreOrderModel(preOrder);
      currentPreOrder.productIds = vProds.map((item) => parseInt(item.id));
      handleSetPreOrder(currentPreOrder);
    },
    [preOrder],
  );

  const onEditPreOrder = async (row: IPreOrder) => {
    const { attributes } = row;
    const { products } = attributes;

    const currentPreOrder = new PreOrderModel({
      ...row.attributes,
      productId: row.attributes.product.id,
      productIds: row.attributes.products?.map((item) => item.id),
    });
    currentPreOrder.moneyDeposit = parseInt(
      currentPreOrder.moneyDeposit.toString().replaceAll(',', ''),
    );
    handleSetPreOrder({ ...currentPreOrder, id: row.id });
    form.setFieldsValue({
      ...currentPreOrder,
      deliveryDate: moment(currentPreOrder.deliveryDate),
    });

    await getProductById(currentPreOrder.productId);
    await getVariantProducts(currentPreOrder.productId.toString(), products);
  };

  const onSubmit = async (formValues) => {
    try {
      setIsSubmitting(false);
      if (formValues.id) {
        await api.put({
          endpoint: API_GET_PREORDER.replace('{id}', formValues.id),
          data: formValues,
        });
        // refresh list
        setIsSubmitting(true);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 500);
      } else {
        await api.post({ endpoint: API_GET_PREORDERS, data: formValues });
        // refresh list
        setIsSubmitting(true);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 500);
      }
    } catch (err) {
      showErrorMsg(err);
    }
  };

  return {
    form,
    isSubmitting,
    onSubmit,
    onEditPreOrder,
    addFormValues,
    resetForm,
    onChangeValue,
    selectedVariantProds,
  };
};

export const useSetPreOrder = (
  variantProducts: IProduct[],
  attrVariantProds: IAttributePreOrder['products'],
) => {
  const [preOrder, setPreOrder] = useState<IAttributePreOrder>(newPreOrder);

  // set variant Products
  useEffect(() => {
    if (isEmpty(variantProducts)) {
      const currentPreOrder = new PreOrderModel(preOrder);
      currentPreOrder.productIds = [];
      setPreOrder(currentPreOrder);
    } else {
      if (isEmpty(attrVariantProds)) {
        const currentPreOrder = new PreOrderModel(preOrder);
        currentPreOrder.productIds = variantProducts.map((item) => parseInt(item.id));
        setPreOrder(currentPreOrder);
      }
    }
  }, [variantProducts, attrVariantProds]);

  const createPreOrder = () => {
    setPreOrder(newPreOrder);
  };

  const handleSetPreOrder = (preOrdItem: IAttributePreOrder) => {
    setPreOrder(preOrdItem);
  };

  return { preOrder, createPreOrder, handleSetPreOrder };
};

export const useFilterPreOrders = (
  formFilter?: FormFilterPreOrder,
  onFilterChange?: (val) => void,
) => {
  // form
  const [formFilterPreOrders] = Form.useForm();

  useEffect(() => {
    if (formFilter) {
      formFilterPreOrders.setFieldsValue(formFilter);
    }
  }, [formFilter]);

  // submit filter
  const submitFilter = () => {
    onFilterChange(formFilterPreOrders.getFieldsValue());
  };

  // clear filter
  const clearFilter = () => {
    formFilterPreOrders.resetFields();
    onFilterChange(defaultFilterParams);
  };

  return { formFilterPreOrders, submitFilter, clearFilter };
};
