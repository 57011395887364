/* eslint-disable import/order */
import { FilterFilled } from '@ant-design/icons';
import { Button, Card, Divider, Empty, Pagination, Spin, message } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReviewStatus } from 'constants/enum';
import { IReview } from 'interfaces/review.interface';
import { getReviewLabelStatus } from 'pages/Members/utils';
import { ProductFilterReview } from 'pages/Products/ProductDetail/components/ProductReview/components/Filter';
import { setReloadAllReviews } from 'redux/review/actions';
import { FilterReviewPage, FilterView } from './FilterView';
import { ReviewItem } from '../components/ReviewItem';
import {
  useFetchReviewList,
  usePostStatusPublished,
  usePostStatusPending,
  usePostStatusLocked,
  useRemoveReview,
} from '../hook';
import '../index.styles.scss';
import { confirmModal } from 'utils/modals';
import FilterStatus from './FilterStatus';

interface Props {
  idReviewLoading?: string | null;
}

export const ReviewList: React.FC<Props> = memo(({ idReviewLoading }) => {
  const dispatch = useDispatch();
  const { id: productId } = useParams<{ id: string }>(); // productId !== null -> get reviews by product id

  const {
    isFetching,
    data,
    pagination,
    setPagination,
    fetchReviewList,
    totalItems,
    includedProducts,
  } = useFetchReviewList();

  const { onPostPublished } = usePostStatusPublished();
  const { onPostPending } = usePostStatusPending();
  const { onPostLocked } = usePostStatusLocked();
  const { onRemove } = useRemoveReview();

  const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
  const [idLoading, setIdLoading] = useState<string | null>(null);

  const params = queryString.parse(location.search);

  /**
   * reload review list after submit new review
   */
  const reloadReviewAfterSubmit = async () => {
    await fetchReviewList({ page: 1 });
  };

  useEffect(() => {
    if (idReviewLoading) {
      reloadReviewAfterSubmit();
    }
  }, [idReviewLoading]);

  /**
   * Handle the feedback list of changing the current page in pagination.
   *
   * @param {number} page - The new page number.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setPagination((prev) => ({ ...prev, current: page }));
      await fetchReviewList({ page });
    },
    [fetchReviewList],
  );

  /**
   * Handle the event of changing the filter for feedback page.
   *
   * @param {FilterReviewPage} filter - The new filter options for feedback page.
   * @returns {Promise<void>} - A Promise that doesn't return a value.
   */
  const onFilterChange = useCallback(
    async (filter: FilterReviewPage): Promise<void> => {
      await fetchReviewList({ page: 1, ...filter });
    },
    [fetchReviewList],
  );

  /**
   * Handle the event change status
   */
  const onSetViewMode = useCallback(
    async (mode: ReviewStatus) => {
      await fetchReviewList({ page: 1, status: mode });
    },
    [fetchReviewList],
  );

  /**
   * Handle the event update review status
   */
  const onUpdateStatus = useCallback(
    async (review: IReview, status: string) => {
      try {
        setIdLoading(review.id);

        switch (status) {
          case ReviewStatus.pending:
            await onPostPending({
              data: {
                ids: [review.id],
              },
            });
            break;
          case ReviewStatus.published:
            await onPostPublished({
              data: {
                ids: [review.id],
              },
            });

            await dispatch(setReloadAllReviews(true));

            break;
          case ReviewStatus.locked:
            await onPostLocked({
              data: {
                ids: [review.id],
              },
            });
            break;
        }

        message.success('Cập nhật thành công');
        await fetchReviewList();
      } catch (error: any) {
        message.error(error?.message || error);
      } finally {
        await dispatch(setReloadAllReviews(false));

        setIdLoading(null);
      }
    },
    [onPostPending, onPostPublished, onPostLocked, fetchReviewList],
  );

  const onRemoveReview = useCallback(
    async (reviewId: string) => {
      try {
        confirmModal({
          title: 'Bạn muốn xoá đánh giá này?',
          buttonText: 'Đồng ý',
          onSaveCb: async () => {
            await onRemove({ id: reviewId });
            message.success('Xoá đánh giá thành công');
            await fetchReviewList();
          },
        });
      } catch (error: any) {
        message.error(error?.message || error);
      } finally {
        dispatch(setReloadAllReviews(false));
      }
    },
    [onRemove, fetchReviewList],
  );

  return (
    <div>
      <div className="review-page__filter">
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <Button
            className="filter-button"
            title="Filter"
            icon={<FilterFilled rev={null} />}
            onClick={() => setOpenFilter((prev) => !prev)}
          >
            Filter
          </Button>
          {!productId && <FilterStatus onChange={onSetViewMode} />}
        </div>
        <Card hidden={!isOpenFilter}>
          {!productId && <FilterView onFilter={onFilterChange} />}
          {/* END: FILTER MEMBER REVIEWS */}
          {productId && <ProductFilterReview onFilter={onFilterChange} />}
          {/* END: FILTER PRODUCT REVIEWS */}
        </Card>
      </div>

      <Divider />

      {!!data?.length && (
        <div className="mt-2 mb-3 text-right">
          <Pagination
            {...pagination}
            pageSizeOptions={[]}
            onChange={onPageChange}
            disabled={isFetching}
          />
        </div>
      )}

      <div className="review-main-content">
        <div className={`review-loading ${isFetching ? 'show' : ''}`}>
          <Spin />
        </div>

        {data?.length ? (
          <div className="review-wrap">
            <div className="count-review">
              <NumberFormat
                className="count"
                thousandSeparator
                displayType="text"
                value={totalItems}
              />
              {productId && <>Review trong sản phẩm này</>}
              {!productId && (
                <>Review {getReviewLabelStatus(params?.status as string).toLowerCase()}</>
              )}
            </div>
            <div className="review-list">
              {data.map((o: IReview) => {
                return (
                  <ReviewItem
                    key={`review-item-${o.id}`}
                    review={o}
                    includedProducts={includedProducts}
                    idLoading={idLoading}
                    hideProductLink={productId ? true : false}
                    onUpdate={onUpdateStatus}
                    onRemove={onRemoveReview}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div className="review-empty">
            <Empty description="Danh sách trống" />
          </div>
        )}
      </div>

      {!!data?.length && (
        <div className="mt-3 mb-2 text-right">
          <Pagination
            {...pagination}
            pageSizeOptions={[]}
            onChange={onPageChange}
            disabled={isFetching}
          />
        </div>
      )}
    </div>
  );
});
