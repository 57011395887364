import { Card, Input } from 'antd';
import React, { useCallback } from 'react';

export interface FilterProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
}

export const Filter: React.FC<FilterProps> = (props) => {
  const { searchValue, onSearchChange } = props;

  const _onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearchChange(e.target.value);
    },
    [onSearchChange],
  );

  return (
    <Card>
      <Input placeholder="Nhập để tìm nhóm sản phẩm" value={searchValue} onChange={_onChange} />
    </Card>
  );
};
