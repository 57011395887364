import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { API_CREATE_BLOCK, API_GET_BLOCKS, API_UPDATE_BLOCK } from 'constants/api/home-block.api';
import { IHomeBlock } from 'interfaces/home-block.interface';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { BankAccount } from 'ui/components/MaiNguyen/TemplateBlock/PaymentOrderBlock/PaymentOrderBlock';
import { TopHeaderMessage } from 'ui/components/MaiNguyen/TemplateBlock/TopHeader/TopHeader';
import { showErrorMsg, uploadFile } from 'utils/utils';

export const usePresentBlocks = (defaultDataValue: string = '') => {
  const [reloadList, setReloadList] = useState(false);
  const [blocks, setBlocks] = useState<IHomeBlock[]>([]);
  const [block, setBlock] = useState<IHomeBlock>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [fileContent, setFileContent] = useState<RcFile>(null);
  const [blockData, setBlockData] = useState({ template: '', data: null, active: true });
  const [blockId, setBlockId] = useState('');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getPageData(`${API_GET_BLOCKS}?perPage=all`, dispatch).then((res) => {
      setBlocks(res?.data);
    });
  }, []);

  useEffect(() => {
    if (reloadList) {
      api.get({ endpoint: `${API_GET_BLOCKS}?perPage=all` }).then((res) => {
        setBlocks(res?.data);
        setReloadList(false);
      });
    }
  }, [reloadList]);

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  const showMessageSuccess = () => {
    toast.success('Lưu thành công!', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      progress: null,
    });
  };

  const handleOpenModalBlock = useCallback(
    (position: string, tmpl: string) => (e) => {
      e.stopPropagation();
      const currentBlock = blocks.find((item) => item.attributes.position === position);
      if (currentBlock) {
        setBlock({ ...currentBlock, attributes: { ...currentBlock.attributes, template: tmpl } });
        setBlockId(currentBlock.id);
        if (currentBlock.attributes.data) {
          try {
            const data =
              typeof currentBlock.attributes.data == 'string'
                ? currentBlock.attributes.data
                : JSON.parse(currentBlock.attributes.data);
            if (tmpl === 'homeSpotlightBanner' || tmpl === 'articleDetailBanner') {
              JSON.parse(data).map((item, idx) => {
                item.position = item.position ? item.position : idx;
              });
            }
            setBlockData({ template: tmpl, data, active: true });
            form.setFieldsValue(data);
          } catch (error) {
            showErrorMsg(error);
          }
        } else {
          setBlockData({ template: tmpl, data: '', active: true });
        }
      } else {
        setBlock({
          attributes: { name: position, position: position, template: tmpl, data: null },
        } as any);
        setBlockData({ template: tmpl, data: null, active: true });
        setBlockId(null);
      }
      setIsOpen(true);
    },
    [blocks],
  );

  const handleUploadFileContent = useCallback((file: RcFile) => {
    setFileContent(file);
  }, []);

  const handleChangeValues = useCallback(
    (_, values) => {
      let currentBlockData = null;

      if (block.id === 'headerLogo' || block.id === 'footerLogo') {
        const data =
          typeof blockData.data === 'string' ? JSON.parse(blockData.data) : blockData.data;
        currentBlockData = { ...blockData, data: { ...data, ...values } };
      } else {
        currentBlockData = { ...blockData, data: values };
      }

      setBlockData(currentBlockData);
      form.setFieldsValue(values);
    },
    [block, blockData],
  );

  const handleUpdateBlockLogo = useCallback(
    (logoData) => {
      const data = typeof blockData.data === 'string' ? JSON.parse(blockData.data) : blockData.data;
      setBlockData({ ...blockData, data: { ...data, ...logoData } });
    },
    [blockData],
  );

  const handleChangeJsonData = useCallback(
    (json) => {
      const currentBlockData = { ...blockData, data: json };
      setBlockData(currentBlockData);
    },
    [blockData],
  );

  const handleChangeActive = useCallback(
    (active) => {
      const currentBlockData = { ...blockData, active };
      setBlockData(currentBlockData);
    },
    [blockData],
  );

  const handleUpdateCollections = useCallback(
    (data) => {
      setBlockData({ ...blockData, data });
    },
    [blockData],
  );

  const handleUpdateMultipleImages = useCallback(
    (slider) => {
      if (
        blockData.template === 'homeSpotlightBanner' ||
        blockData.template === 'articleDetailBanner'
      ) {
        slider = orderBy(slider, ['position'], ['asc']);
      }
      setBlockData({ ...blockData, data: slider });
    },
    [blockData],
  );

  const onUpdatePaymentOrderBlock = useCallback(
    (data: BankAccount[]) => {
      setBlockData({ ...blockData, data });
    },
    [blockData],
  );

  const onUpdateTopHeaderBlock = useCallback(
    (data: TopHeaderMessage[]) => {
      setBlockData({ ...blockData, data });
    },
    [blockData],
  );

  const handleUpdateHighendBlock = useCallback(
    (highendData) => {
      setBlockData({ ...blockData, data: highendData });
    },
    [blockData],
  );

  const handleUpdateShockpriceBlock = useCallback(
    (shockPriceData) => {
      setBlockData({ ...blockData, data: shockPriceData });
    },
    [blockData],
  );

  const handleUpdateEditorBlock = useCallback(
    (editorData) => {
      setBlockData({ ...blockData, data: editorData });
    },
    [blockData],
  );

  const handleUpdateMenuItems = useCallback(
    (menuItems) => {
      setBlockData({ ...blockData, data: menuItems });
    },
    [blockData],
  );

  const handleSaveBlock = useCallback(async () => {
    try {
      if (blockId) {
        const postData =
          typeof blockData.data !== 'string' ? JSON.stringify(blockData.data) : blockData.data;
        await api.put({
          endpoint: API_UPDATE_BLOCK.replace('{id}', blockId),
          data: { template: blockData.template, data: postData, active: blockData.active },
        });
        await uploadFile(fileContent, 'block', block.attributes._id.toString());
        setReloadList(true);
        // setIsOpen(false);
        showMessageSuccess();
      } else {
        await api
          .post({
            endpoint: API_CREATE_BLOCK,
            data: {
              ...block.attributes,
              data: blockData.data ? JSON.stringify(blockData.data) : defaultDataValue,
            },
          })
          .then(async (res) => {
            await uploadFile(fileContent, 'block', res?.data?.attributes._id);
          });
        setReloadList(true);
        // setIsOpen(false);
        showMessageSuccess();
      }
    } catch (err) {
      showErrorMsg(err);
    }
  }, [blockData, fileContent, blockId, block]);

  const handleDeleteImage = useCallback(() => {
    setReloadList(true);
  }, []);

  return {
    reloadList,
    blocks,
    block,
    isOpen,
    blockData,
    blockId,
    handleClosePopup,
    handleOpenModalBlock,
    handleUploadFileContent,
    handleChangeValues,
    handleChangeJsonData,
    handleUpdateMultipleImages,
    handleUpdateHighendBlock,
    handleSaveBlock,
    handleDeleteImage,
    handleUpdateShockpriceBlock,
    handleUpdateEditorBlock,
    handleChangeActive,
    handleUpdateBlockLogo,
    handleUpdateMenuItems,
    onUpdatePaymentOrderBlock,
    handleUpdateCollections,
    onUpdateTopHeaderBlock,
  };
};
