/* eslint-disable import/order */
import { CopyOutlined } from '@ant-design/icons';
import { Input, Form, Select, Col, Row, message, Switch, Button } from 'antd';

import { API_GET_STATUS, API_GET_SUB_STATUS } from 'constants/api/status.api';
import { installmentPrice } from 'constants/constants';
import { API_GET_POST_COLOR } from 'constants/api/system-color.api';
import { MNStoreList, ProductSection } from 'constants/enum';
import { IArticle } from 'interfaces/article.interface';
import { IAttributeGroup } from 'interfaces/attr-group.interface';
import { IAttributeSet } from 'interfaces/attr-set.interface';
import { IAttribute, IAttributeValue } from 'interfaces/attribute.interface';
import { IBrand } from 'interfaces/brand.interface';
import { IOs } from 'interfaces/os.interface';
import { IPrdCategory } from 'interfaces/product-category.interface';
import { IProduct, IProductAttribute, ISuperAttribute } from 'interfaces/product.interface';
import { IRiderModels } from 'interfaces/riderType';
import { IStatus } from 'interfaces/status.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { clone, isEmpty, filter, debounce, find } from 'lodash';
import { ProductModel } from 'models/product.model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  setBrands,
  setCategories,
  setOses,
  setStatuses,
  setProductDetail,
  setOpenSelectProductSection,
  setColors,
  setSubStatuses,
} from 'redux/product/actions';
import { AppState } from 'redux/store';
import { setIsEditing } from 'redux/uiAction/actions';
import * as api from 'services/api.service';
import { getPageData } from 'services/pageData.service';
import { CustomPopup } from 'ui/components/MaiNguyen/CustomPopup';
import { CustomTreeSelect } from 'ui/components/MaiNguyen/CustomTreeSelect';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import { SelectProductsSection } from 'ui/components/SelectProductsSection';
import { deleteModal } from 'utils/modals';
import { convertToTreeLevel, showErrorMsg, generateSlug } from 'utils/utils';
import {
  API_GET_POST_ATTRIBUTE_SETS,
  API_GET_ATTRIBUTE_GROUPS,
  API_GET_SUPER_ATTRIBUTES,
  API_GET_ATTRIBUTE_SET_DEFAULT,
} from 'constants/api/attribute.api';
import { API_UPLOAD } from 'constants/api/auth.api';
import { API_GET_BRANDS, API_BRAND } from 'constants/api/brand.api';
import { API_GET_POST_OS } from 'constants/api/os.api';
import { API_PRODUCT_CATEGORIES } from 'constants/api/product-category.api';
import {
  API_GET_PRODUCTS,
  API_GET_PRODUCT_DETAIL,
  API_GET_PRODUCT_TYPES,
  API_GET_PRODUCT_ATTRIBUTE_GROUPS,
  API_GET_RELATED_PRODUCTS,
  API_GET_CROSS_SELL_PRODUCTS,
  API_GET_VARIANT_PRODUCTS,
  API_GET_SECONDHAND_PRODUCTS,
  API_GET_GROUPED_PRODUCTS,
  API_GET_RELATED_ARTICLES,
  API_GET_RELATED_ACCESSORIES_PRODUCTS,
} from 'constants/api/product.api';
import { AddVariantProducts } from './components/AddVariantProducts';
import { ProductAttributeList } from './components/ProductAttributeList';

import './ProductDetail.scss';

const productState = (state: AppState) => state.productReducers;

const ProductDetail = () => {
  const newProduct = new ProductModel({});
  const { id: productId } = useParams<{ id: string }>();
  const { selectProductSection, imageList } = useSelector(productState);
  const isAddNew = productId === 'new';
  const search = useLocation().search;

  const [product, setProduct] = useState<IProductAttribute>(newProduct);
  const [prdDetail, setPrdDetail] = useState<IProduct>(null);
  const [brands, setBrand] = useState<IBrand[]>([]);
  const [productCategories, setProductCategories] = useState<IPrdCategory[]>([]);
  const [productCategoryParentNode, setProductCategoryParentNode] = useState('');
  const [attrSets, setAttrSets] = useState<IAttributeSet[]>([]);
  const [productTypes, setProductTypes] = useState<string[]>([]);
  const [prdType, setPrdType] = useState('');
  const [subStatus, setSubStatus] = useState<IStatus[]>([]);
  const [attrGroups, setAttrGroups] = useState<IAttributeGroup[]>([]);
  const [urlPath, setUrlPath] = useState('');
  const [relateProducts, setRelateProducts] = useState<IProduct[]>([]);
  const [relateProducts2, setRelateProducts2] = useState<IProduct[]>([]);
  const [crossSellProducts, setCrossSellProducts] = useState<IProduct[]>([]);
  const [variantProducts, setVariantProducts] = useState<IProduct[]>([]);
  const [secondhandProducts, setSecondhandProducts] = useState<IProduct[]>([]);
  const [groupedProducts, setGroupedProducts] = useState<IProduct[]>([]);
  const [superAttributes, setSuperAttributes] = useState<IAttribute[]>([]);
  const [superAttributesValues, setSuperAttributesValues] = useState<ISuperAttribute[]>([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [relateArticles, setRelateArticles] = useState<IArticle[]>([]);
  const [relateRiderModels, setRelateRiderModels] = useState<IRiderModels[]>([]);
  const [brandLoading, setBrandLoading] = useState(false);
  const [defaultStatus, setDefaultStatus] = useState(0);
  const [defaultProductType, setDefaultProductType] = useState('');

  // dummy data
  const [relateProductsDumb, setRelateProductsDumb] = useState<IProduct[]>([]);
  const [relateProductsDumb2, setRelateProductsDumb2] = useState<IProduct[]>([]);
  const [crossSellProductsDumb, setCrossSellProductsDumb] = useState<IProduct[]>([]);
  const [secondhandProductsDumb, setSecondhandProductsDumb] = useState<IProduct[]>([]);
  const [groupedProductsDumb, setGroupedProductsDumb] = useState<IProduct[]>([]);

  const [isSavingVariantProducts, setIsSavingVariantProducts] = useState<boolean>(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const getBrands = (filterName) => {
    setBrandLoading(true);
    api
      .get({ endpoint: `${API_GET_BRANDS}?perPage=all&name=${filterName}` })
      .then((brandList) => {
        setBrand(brandList?.data.filter((item: IBrand) => item.attributes.active));
        dispatch(setBrands(brandList?.data.filter((item: IBrand) => item.attributes.active)));
        setBrandLoading(false);
      })
      .catch((err) => {
        setBrandLoading(false);
        showErrorMsg(err);
      });
  };

  const getBrandDetail = (brandId) => {
    setBrandLoading(true);
    api
      .get({ endpoint: `${API_BRAND.replace('{id}', brandId)}` })
      .then((brandList) => {
        const newBrands = [];
        newBrands.push(brandList?.data);
        setBrand(newBrands);
        dispatch(setBrands(newBrands));
        setBrandLoading(false);
      })
      .catch((err) => {
        setBrandLoading(false);
        showErrorMsg(err);
      });
  };

  useEffect(() => {
    if (!isAddNew) {
      getPageData(API_GET_PRODUCT_DETAIL.replace('{id}', productId), dispatch).then(async (res) => {
        dispatch(setIsEditing(false));
        if (res) {
          const prd = new ProductModel(res?.data?.attributes);
          if (!prd.attributeSetId) {
            const attributeSet = await api.get({
              endpoint: API_GET_ATTRIBUTE_SET_DEFAULT,
            });
            prd.attributeSetId = attributeSet?.data?.id;
          }

          dispatch(setProductDetail(res?.data));
          setPrdDetail(res?.data);
          setProduct(prd);
          setUrlPath(prd.slug);
          setPrdType(prd.type);
          setProductCategoryParentNode(prd.categoryId?.toString());
          form.setFieldsValue({
            ...prd,
            storeIds: prd.storeIds ? prd.storeIds : [], // if storeIds is null and then set storeIds = []
          });
          getBrandDetail(prd.brandId);
          Promise.all([
            api.get({
              endpoint: `${API_GET_PRODUCT_ATTRIBUTE_GROUPS.replace(
                '{id}',
                productId,
              )}?include=children,attributes`,
            }),
            api.get({ endpoint: API_GET_RELATED_PRODUCTS.replace('{id}', productId) }),
            api.get({
              endpoint: API_GET_RELATED_ACCESSORIES_PRODUCTS.replace('{id}', productId),
            }),
            api.get({ endpoint: API_GET_CROSS_SELL_PRODUCTS.replace('{id}', productId) }),
            api.get({ endpoint: API_GET_VARIANT_PRODUCTS.replace('{id}', productId) }),
            api.get({ endpoint: API_GET_SECONDHAND_PRODUCTS.replace('{id}', productId) }),
            api.get({ endpoint: API_GET_GROUPED_PRODUCTS.replace('{id}', productId) }),
            api.get({
              endpoint: API_GET_SUPER_ATTRIBUTES.replace('{id}', prd.attributeSetId?.toString()),
            }),
            api.get({ endpoint: API_GET_RELATED_ARTICLES.replace('{id}', productId) }),
          ])
            .then(
              ([
                productGroupData,
                relatedProductData,
                relatedProductData2,
                crossSellProductData,
                variantProductData,
                secondhandProductData,
                groupedProductData,
                superAttributeData,
                relatedArticlesData,
              ]) => {
                setAttrGroups(
                  productGroupData?.data.filter(
                    (item: IAttributeGroup) => !item.attributes.parentId,
                  ),
                );
                setRelateProducts(relatedProductData?.data);
                setCrossSellProducts(crossSellProductData?.data);
                setRelateProducts2(relatedProductData2?.data);
                // set default shortDisplayName
                setVariantProducts(variantProductData?.data);
                setSecondhandProducts(secondhandProductData?.data);
                setGroupedProducts(groupedProductData?.data || []);
                setSuperAttributes(superAttributeData?.data);
                setRelateArticles(relatedArticlesData?.data);

                // dummy data
                setRelateProductsDumb(relatedProductData?.data);
                setRelateProductsDumb2(relatedProductData2?.data);
                setCrossSellProductsDumb(crossSellProductData?.data);
                setSecondhandProductsDumb(secondhandProductData?.data);
                setGroupedProductsDumb(groupedProductData?.data || []);
              },
            )
            .catch((err) => {
              showErrorMsg(err);
            });
        }
      });
    } else {
      // set default status & product type when add new product
      const currentProduct = new ProductModel(product);
      currentProduct.statusId = 6;
      currentProduct.type = 'configurable';
      setDefaultStatus(6);
      setDefaultProductType('configurable');
      form.setFieldsValue(currentProduct);
    }
  }, [productId]);

  useEffect(() => {
    Promise.all([
      api.get({ endpoint: `${API_PRODUCT_CATEGORIES}?perPage=all` }),
      api.get({ endpoint: `${API_GET_PRODUCT_TYPES}?perPage=all` }),
      api.get({ endpoint: `${API_GET_STATUS}?perPage=all` }),
      api.get({ endpoint: `${API_GET_POST_ATTRIBUTE_SETS}?perPage=all` }),
      api.get({ endpoint: `${API_GET_POST_OS}?perPage=all` }),
      api.get({ endpoint: `${API_GET_POST_COLOR}?perPage=all` }),
      api.get({ endpoint: `${API_GET_SUB_STATUS}?perPage=all` }),
    ])
      .then(
        ([
          productCategoriesList,
          productTypeList,
          statusList,
          attrSetList,
          osList,
          colorList,
          subStatusList,
        ]) => {
          const categories = clone(
            convertToTreeLevel(
              productCategoriesList?.data.filter((item: IPrdCategory) => item.attributes.active),
            ),
          );
          categories.forEach((item) => {
            item['title'] = <strong>{item.attributes.name}</strong>;
          });
          setProductCategories(categories as IPrdCategory[]);
          setProductTypes(productTypeList?.data);
          setSubStatus(subStatusList?.data.filter((item: IStatus) => item.attributes.active));
          setAttrSets(attrSetList?.data);
          dispatch(setCategories(productCategoriesList?.data));
          dispatch(setOses(osList?.data.filter((item: IOs) => item.attributes.active)));
          dispatch(setStatuses(statusList?.data));
          dispatch(setColors(colorList?.data));
          dispatch(
            setSubStatuses(subStatusList?.data.filter((item: IStatus) => item.attributes.active)),
          );
        },
      )
      .catch((err) => {
        showErrorMsg(err);
      });
    if (productId === 'new') {
      form.resetFields();
      setUrlPath('');
      setProduct(newProduct);
      setRelateProducts([]);
      setRelateProducts2([]);
      setCrossSellProducts([]);
      setSecondhandProducts([]);
      setGroupedProducts([]);
      setProductCategoryParentNode('');
      setRelateArticles([]);

      setRelateProductsDumb([]);
      setRelateProductsDumb2([]);
      setCrossSellProductsDumb([]);
      setSecondhandProductsDumb([]);
      setGroupedProductsDumb([]);
    }
  }, [productId]);

  const handleCloseSelectProductModal = useCallback(() => {
    dispatch(setOpenSelectProductSection({ title: '', productGroupKey: '' }));
  }, [dispatch]);

  const handleChangeParentId = useCallback(
    (value: string) => {
      const currentProduct = clone(product);
      currentProduct.categoryId = parseInt(value);
      setProductCategoryParentNode(value);
      setProduct(currentProduct);
      form.setFieldsValue(currentProduct);
      dispatch(setProductDetail({ ...prdDetail, attributes: currentProduct }));
      dispatch(setIsEditing(true));
    },
    [product, dispatch, prdDetail],
  );

  const handleClickAddButton = useCallback(() => {
    history.push('/san-pham/new');
  }, []);

  const handleChangeAttrSet = useCallback(async (id: string) => {
    dispatch(setIsEditing(true));
    await Promise.all([
      api.get({
        endpoint: `${API_GET_ATTRIBUTE_GROUPS.replace('{id}', id)}?include=children,attributes`,
      }),
      api.get({ endpoint: API_GET_SUPER_ATTRIBUTES.replace('{id}', id) }),
    ])
      .then(([attrGroupData, superAttrData]) => {
        setAttrGroups(
          attrGroupData?.data.filter((item: IAttributeGroup) => !item.attributes.parentId),
        );
        setSuperAttributes(superAttrData?.data);
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  }, []);

  const handleCopyUrlPath = useCallback((text: string, result) => {
    if (text && result) {
      message.success('Copy thành công!');
    }
  }, []);

  const handleValuesChange = useCallback(
    async (field, changedValues) => {
      dispatch(setIsEditing(true));
      const currentProduct = clone({ ...product, ...changedValues });

      const fieldKey = Object.keys(field);
      if (isAddNew) {
        setPrdType(currentProduct.type);
        currentProduct.slug = generateSlug(currentProduct.name.trim());

        // set default status & product type when add new product
        if (!currentProduct.statusId) {
          currentProduct.statusId = defaultStatus;
        }
        if (!currentProduct.type) {
          currentProduct.type = defaultProductType;
        }
      } else {
        switch (fieldKey[0]) {
          case 'slug':
            currentProduct.slug = generateSlug(currentProduct.slug);
            break;
          default:
            break;
        }
      }
      setUrlPath(currentProduct.slug);
      setProduct(currentProduct);
      setProductCategoryParentNode(currentProduct?.categoryId?.toString());
      form.setFieldsValue(currentProduct);
      if (productId) {
        dispatch(setProductDetail({ ...prdDetail, attributes: currentProduct }));
      }
    },
    [product, dispatch, prdDetail, handleChangeParentId],
  );

  const handleChangeAttributesValue = useCallback(
    (values: IAttributeValue[]) => {
      dispatch(setIsEditing(true));
      const clonedProduct = clone(product);
      clonedProduct.attributeValues = values;
      setProduct(clonedProduct);
      if (productId) {
        dispatch(setProductDetail({ ...prdDetail, attributes: clonedProduct }));
      }
    },
    [product, dispatch, prdDetail],
  );

  const handleSaveProduct = useCallback(async () => {
    dispatch(setIsEditing(false));
    setSaveLoading(true);
    // set installment_percentage if price/salePrice >= 3000000
    const installmentAttr = find(product.attributeValues, function (o) {
      return o.attributeCode == 'installment_percentage';
    });
    const originalPriceAttr = find(product.attributeValues, function (o) {
      return o.attributeCode == 'original_price';
    });
    const salePriceAttr = find(product.attributeValues, function (o) {
      return o.attributeCode == 'sale_price';
    });

    if (
      (originalPriceAttr && originalPriceAttr.value >= installmentPrice) ||
      (salePriceAttr && salePriceAttr.value >= installmentPrice)
    ) {
      if (installmentAttr) {
        let val;

        if (installmentAttr.value === '' || isNaN(installmentAttr.value)) {
          val = 3.5;
        } else {
          val = installmentAttr.value;
        }

        installmentAttr.value = val;
      }
    } else {
      if (installmentAttr) {
        installmentAttr.value = '';
      }
    }
    const currentProduct = clone({
      ...product,
      attributeValues: product.attributeValues.map((item) => ({
        attributeId: item.attributeId,
        value: item.value,
      })),
      relatedProducts: relateProducts.map((item, index) => ({
        productId: parseInt(item.id),
        position: index + 1,
      })),
      relatedProducts2: relateProducts2.map((item, index) => ({
        productId: parseInt(item.id),
        position: index + 1,
      })),
      relatedArticles: relateArticles.map((item, index) => ({
        articleId: parseInt(item.id),
        position: index + 1,
      })),
      crossSells: crossSellProducts.map((item, index) => ({
        productId: parseInt(item.id),
        position: index + 1,
        discount: item.attributes.discount ? item.attributes.discount : 0,
      })),
      secondhandProductIds: secondhandProducts.map((item) => parseInt(item.id)),
      groupedProducts: groupedProducts.map((item) => ({
        name: item.attributes.displayName,
        productId: parseInt(item.id),
      })),
      variantProducts: variantProducts.map((item) => ({
        artributeCode: 'short_display_name',
        artributeValue: item.attributes?.attributeValues?.find(
          (attr) => attr.attributeCode === 'short_display_name',
        )?.value,
        id: item.id,
      })),
      relatedModels: relateRiderModels.map((item, index) => ({
        modelId: parseInt(item.id),
        position: index + 1,
      })),
    });
    try {
      if (isAddNew) {
        await api.post({ endpoint: API_GET_PRODUCTS, data: currentProduct }).then((res) => {
          dispatch(setIsEditing(false));
          setSaveLoading(false);
          message.success('Lưu sản phẩm thành công', 3);

          setTimeout(() => {
            window.location.href = `/san-pham/${res?.data.id}`;
          }, 1000);
        });
      } else {
        !isEmpty(imageList) && (await api.put({ endpoint: API_UPLOAD, data: imageList }));
        await api
          .put({
            endpoint: API_GET_PRODUCT_DETAIL.replace('{id}', productId),
            data: currentProduct,
          })
          .then((res) => {
            if (res?.data) {
              const updatedProduct = new ProductModel(res?.data?.attributes);
              form.setFieldsValue(updatedProduct);
              setProduct(updatedProduct);
            }
          });
        setSaveLoading(false);
        dispatch(setIsEditing(false));
        message.success('Lưu sản phẩm thành công', 3);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      let errDupicateProdName;
      if (err['response'] && err['response']['data']) {
        errDupicateProdName = err['response']['data']['name'];
      }
      setSaveLoading(false);
      if (errDupicateProdName?.[0] == 'The name has already been taken.') {
        showErrorMsg('Tên sản phẩm này đã tồn tại, vui lòng thử lại!');
      } else {
        showErrorMsg(err);
      }
    }
  }, [
    product,
    imageList,
    relateProducts,
    relateProducts2,
    crossSellProducts,
    secondhandProducts,
    groupedProducts,
    dispatch,
    relateArticles,
    variantProducts,
    relateRiderModels,
  ]);

  const handleDeleteProduct = useCallback(() => {
    deleteModal({
      title: 'Bạn có muốn xóa sản phẩm này?',
      onDeleteCb: async () => {
        try {
          await api.remove({ endpoint: API_GET_PRODUCT_DETAIL.replace('{id}', productId) });
          dispatch(setIsEditing(false));
          history.push('/san-pham');
        } catch (err) {
          showErrorMsg(err);
        }
      },
    });
  }, [productId]);

  const getSelectedProducts = useCallback(() => {
    switch (selectProductSection.productGroupKey) {
      case ProductSection.relatedProducts:
        return relateProductsDumb;
      case ProductSection.relatedProducts2:
        return relateProductsDumb2;
      case ProductSection.crossSells:
        return crossSellProductsDumb;
      case ProductSection.secondhandProducts:
        return secondhandProductsDumb;
      case ProductSection.groupedProducts:
        return groupedProductsDumb;
      default:
        return [];
    }
  }, [
    selectProductSection,
    relateProductsDumb,
    relateProductsDumb2,
    crossSellProductsDumb,
    secondhandProductsDumb,
    groupedProductsDumb,
  ]);

  const updateProductsDummy = (productList: IProduct[], key: string) => {
    switch (key) {
      case ProductSection.relatedProducts:
        setRelateProductsDumb(productList);
        break;
      case ProductSection.relatedProducts2:
        setRelateProductsDumb2(productList);
        break;
      case ProductSection.crossSells:
        setCrossSellProductsDumb(productList);
        break;
      case ProductSection.secondhandProducts:
        setSecondhandProductsDumb(productList);
        break;
      case ProductSection.groupedProducts:
        setGroupedProductsDumb(productList);
        break;
      case ProductSection.variantProducts:
        setVariantProducts(productList);
        break;
      default:
        break;
    }
  };

  const setSelectedProducts = useCallback(
    (productList: IProduct[], productGroupKey: string, isOnChange: boolean) => {
      updateProductsDummy(productList, productGroupKey);
      switch (productGroupKey) {
        case ProductSection.relatedProducts:
          if (isOnChange) {
            setRelateProducts(productList);
          } else {
            setRelateProducts([]);
            setTimeout(() => {
              setRelateProducts(productList);
            }, 50);
          }
          break;
        case ProductSection.relatedProducts2:
          if (isOnChange) {
            setRelateProducts2(productList);
          } else {
            setRelateProducts2([]);
            setTimeout(() => {
              setRelateProducts2(productList);
            }, 50);
          }
          break;
        case ProductSection.crossSells:
          if (isOnChange) {
            setCrossSellProducts(productList);
          } else {
            setCrossSellProducts([]);
            setTimeout(() => {
              setCrossSellProducts(productList);
            }, 50);
          }
          break;
        case ProductSection.secondhandProducts:
          if (isOnChange) {
            setSecondhandProducts(productList);
          } else {
            setSecondhandProducts([]);
            setTimeout(() => {
              setSecondhandProducts(productList);
            }, 50);
          }
          break;
        case ProductSection.groupedProducts:
          const currentProduct = productList.find((item) => item.id === prdDetail.id);
          let newGroupedProducts = [...productList];
          if (!currentProduct && newGroupedProducts.length > 0) {
            newGroupedProducts.unshift(prdDetail);
          } else if (currentProduct && newGroupedProducts.length === 1) {
            newGroupedProducts = [];
          }
          if (isOnChange) {
            setGroupedProducts(newGroupedProducts);
          } else {
            setGroupedProducts([]);
            setTimeout(() => {
              setGroupedProducts(newGroupedProducts);
            }, 50);
          }
          updateProductsDummy(newGroupedProducts, productGroupKey);
          break;
        default:
          break;
      }
    },
    [prdDetail, dispatch],
  );

  const handleAddSelectedProducts = useCallback(
    (productList: IProduct[]) => {
      dispatch(setIsEditing(true));
      updateProductsDummy(
        productList.map((item) => ({
          ...item,
          attributes: { ...item.attributes, displayName: item.attributes.name },
        })),
        selectProductSection.productGroupKey,
      );
    },
    [selectProductSection],
  );

  const handleUpdateSelectedProducts = useCallback(
    (productList: IProduct[], key: string) => {
      dispatch(setIsEditing(true));
      setSelectedProducts(productList, key, true);
    },
    [setSelectedProducts, prdDetail],
  );

  const handleUpdateSelectedArticles = useCallback((articles: IArticle[]) => {
    setRelateArticles(articles);
  }, []);

  const handleUpdateSelectedRiderModels = useCallback((riderModels: IRiderModels[]) => {
    setRelateRiderModels(riderModels);
  }, []);

  const getVariantProducts = useCallback(async () => {
    await api.get({ endpoint: API_GET_VARIANT_PRODUCTS.replace('{id}', productId) }).then((res) => {
      setVariantProducts(res?.data);
    });
  }, [productId]);

  const handleSaveSelectedProducts = useCallback(async () => {
    switch (selectProductSection.productGroupKey) {
      case ProductSection.relatedProducts:
        setSelectedProducts(relateProductsDumb, ProductSection.relatedProducts, false);
        break;
      case ProductSection.relatedProducts2:
        setSelectedProducts(relateProductsDumb2, ProductSection.relatedProducts2, false);
        break;
      case ProductSection.crossSells:
        setSelectedProducts(crossSellProductsDumb, ProductSection.crossSells, false);
        break;
      case ProductSection.secondhandProducts:
        setSelectedProducts(secondhandProductsDumb, ProductSection.secondhandProducts, false);
        break;
      case ProductSection.groupedProducts:
        setSelectedProducts(groupedProductsDumb, ProductSection.groupedProducts, false);
        break;
      case ProductSection.variantProducts:
        // validate color
        const dataFilterColor = superAttributesValues.filter(function (element) {
          return element['attrType'] === 'color';
        });

        if (!isEmpty(dataFilterColor)) {
          const iptColor = filter(dataFilterColor, function (o) {
            return o['id'] == '';
          });

          if (!isEmpty(iptColor)) {
            iptColor.forEach((input) => {
              const inputColor = document.querySelector('.ipt-color-' + input['itemId']);
              const msgColor = document.querySelector('.msg-color-' + input['itemId']);
              inputColor.classList.add('err');
              msgColor.classList.add('shw');
            });
            return;
          }
        }
        // remove field
        const superAttributesColorValues = filter(superAttributesValues, function (o) {
          return o['attributeId'] == 43;
        });
        superAttributesColorValues.length > 0 &&
          superAttributesColorValues.map((item) => {
            delete item['id'];
            delete item['itemId'];
            delete item['attrType'];
            delete item['variantList'];
            return item;
          });

        try {
          if (!isEmpty(superAttributesColorValues)) {
            setIsSavingVariantProducts(true);
            await api.post({
              endpoint: API_GET_VARIANT_PRODUCTS.replace('{id}', productId),
              data: {
                superAttributes: superAttributesColorValues,
              },
            });
            await getVariantProducts();
            message.success('Tạo sản phẩm con thành công', 3);
            setIsSavingVariantProducts(false);
          }
        } catch (err) {
          showErrorMsg(err);
          setIsSavingVariantProducts(false);
        }
        break;
      default:
        break;
    }
    dispatch(setOpenSelectProductSection({ title: '', productGroupKey: '' }));
  }, [
    productId,
    selectProductSection,
    product,
    prdDetail,
    variantProducts,
    relateProductsDumb,
    relateProductsDumb2,
    crossSellProductsDumb,
    secondhandProductsDumb,
    groupedProductsDumb,
    dispatch,
    setSelectedProducts,
    superAttributesValues,
    getVariantProducts,
  ]);

  const handleUpdateSuperAttributesValue = useCallback((values) => {
    dispatch(setIsEditing(true));
    setSuperAttributesValues(values);
  }, []);

  const handleSearchBrands = useMemo(() => {
    const loadOptions = (value: string) => {
      if (value.length > 2) {
        getBrands(value);
      }
    };
    return debounce(loadOptions, 400);
  }, []);

  const searchByType = () => {
    switch (selectProductSection.productGroupKey) {
      case ProductSection.secondhandProducts:
        return 'simple';
      case ProductSection.groupedProducts:
        return 'configurable';
      default:
        return '';
    }
  };

  const handleBackList = () => {
    history.push('/san-pham' + search);
  };

  return (
    <>
      <LayoutDetail
        pageTitle={isAddNew ? 'Tạo mới' : 'Chi tiết'}
        pageClassName="product-detail-page"
        isAddNew={isAddNew}
        breadcrumbs={[
          {
            title: 'Sản phẩm',
            route: '/san-pham',
          },
          {
            title: isAddNew ? 'Thêm mới' : 'Chi tiết',
            route: `/san-pham/${isAddNew ? 'new' : productId}`,
          },
        ]}
        formHook={form}
        handleValuesChange={handleValuesChange}
        handleSave={handleSaveProduct}
        handleClickAddButton={handleClickAddButton}
        handleDelete={handleDeleteProduct}
        saveLoading={saveLoading}
      >
        {!isAddNew && (
          <Button className="btn-close-promotion" onClick={handleBackList}>
            ĐÓNG
          </Button>
        )}
        <Row gutter={16}>
          <Col className="main-col" span={!(product?.attributeSetId && product?.type) ? 24 : 16}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Tên sản phẩm"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng điền tên sản phẩm',
                    },
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              {/* <Col span={12} style={{ display: isAddNew ? 'none' : '' }}>
                <Form.Item name="sku" label="SKU">
                  <div className="read-only-field">{prdDetail?.attributes.sku}</div>
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item
                  name="sku"
                  label="SKU"
                  rules={[
                    {
                      required: false,
                      message: 'Vui lòng điền SKU',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} className="url-path" style={{ display: isAddNew ? 'none' : '' }}>
                <Form.Item name="slug" label="Slug">
                  <Input />
                </Form.Item>
                <CopyToClipboard text={urlPath} onCopy={handleCopyUrlPath}>
                  <Button title="Copy" className="copy-button" icon={<CopyOutlined />} />
                </CopyToClipboard>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Nhóm sản phẩm"
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn nhóm sản phẩm',
                    },
                  ]}
                >
                  <CustomTreeSelect
                    data={productCategories}
                    selectedValue={productCategoryParentNode?.toString()}
                    handleSelect={handleChangeParentId}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Thương hiệu"
                  name="brandId"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn thương hiệu',
                    },
                  ]}
                >
                  <Select
                    className="ant-select-lg"
                    showSearch
                    placeholder="Nhập từ 3 ký tự trở lên để tìm"
                    optionFilterProp="children"
                    loading={brandLoading}
                    onSearch={handleSearchBrands}
                    filterOption={(input, option) => {
                      return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  >
                    {brands?.map((brand) => (
                      <Select.Option
                        value={parseInt(brand.id)}
                        key={brand.id}
                        title={brand.attributes.name}
                      >
                        <SanitizeHtml rawHtml={brand.attributes.name} />
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Trạng thái"
                  name="statusId"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn trạng thái',
                    },
                  ]}
                >
                  <Select
                    className="ant-select-lg"
                    showSearch
                    optionFilterProp="children"
                    loading={isEmpty(subStatus)}
                    listHeight={600}
                    defaultValue={defaultStatus}
                  >
                    {subStatus?.map((sts) => (
                      <Select.Option value={parseInt(sts.id)} key={sts.id}>
                        {sts.attributes.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} style={{ display: isAddNew ? 'none' : '' }}>
                <Form.Item label="Số lượng">
                  <div className="read-only-field">{prdDetail?.attributes?.quantity}</div>
                </Form.Item>
              </Col>
              {isAddNew && (
                <>
                  <Col span={12}>
                    <Form.Item label="Nhóm thuộc tính" name="attributeSetId">
                      <Select
                        className="ant-select-lg"
                        value={product?.attributeSetId?.toString()}
                        onChange={handleChangeAttrSet}
                      >
                        {attrSets?.map((attrSet) => (
                          <Select.Option value={parseInt(attrSet.id)} key={attrSet.id}>
                            {attrSet.attributes.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Loại sản phẩm"
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn nhóm loại sản phẩm',
                        },
                      ]}
                    >
                      <Select className="ant-select-lg" defaultValue={defaultProductType}>
                        {productTypes?.map((type) => (
                          <Select.Option value={type} key={type}>
                            {type}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              {product?.attributeSetId && (
                <Col span={24}>
                  <ProductAttributeList
                    attributesGroupList={attrGroups}
                    attributeValues={product?.attributeValues}
                    onChangeAttributesValue={handleChangeAttributesValue}
                    productType={prdType}
                    relatedProducts={relateProducts}
                    relatedProducts2={relateProducts2}
                    crossSellProducts={crossSellProducts}
                    variantProducts={variantProducts}
                    secondhandProducts={secondhandProducts}
                    groupedProducts={groupedProducts}
                    handleUpdateSelectedProducts={handleUpdateSelectedProducts}
                    superAttributes={superAttributes}
                    relatedArticles={relateArticles}
                    handleUpdateSelectedArticles={handleUpdateSelectedArticles}
                    handleRefreshVariantProducts={getVariantProducts}
                    handleUpdateSelectedRiderModels={handleUpdateSelectedRiderModels}
                  />
                </Col>
              )}
            </Row>
          </Col>
          {product?.attributeSetId && product?.type && (
            <Col className="right-col" span={8}>
              <Row gutter={8}>
                <Col span={18}>
                  <Form.Item className="mb-0">
                    <span className="mr-2">Nhóm thuộc tính:</span>
                    <span className="font-weight-bold">
                      {
                        attrSets?.find((item) => item.id === product?.attributeSetId?.toString())
                          ?.attributes?.name
                      }
                    </span>
                  </Form.Item>
                  <Form.Item>
                    <span className="mr-2">Loại sản phẩm:</span>
                    <span className="font-weight-bold" style={{ textTransform: 'capitalize' }}>
                      {prdType}
                    </span>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Hiển thị"
                    name="active"
                    valuePropName="checked"
                    className="pt-1 d-flex align-items-end"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
              {/* Chọn cửa hàng */}
              {!isAddNew && product.type === 'configurable' && (
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item
                      name="storeIds"
                      label="Chọn cửa hàng"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn cửa hàng',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="title"
                        mode="multiple"
                        allowClear
                        size="large"
                      >
                        {MNStoreList.map((item) => {
                          const { id, title } = item;
                          return (
                            <Select.Option
                              value={id}
                              key={`mn-store-${id}`}
                              title={title}
                              className="select-store"
                            >
                              <SanitizeHtml rawHtml={title} />
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <ProductAttributeList
                renderShippingContent
                attributesGroupList={attrGroups}
                attributeValues={product?.attributeValues}
                onChangeAttributesValue={handleChangeAttributesValue}
                productType={prdType}
                relatedProducts={relateProducts}
                relatedProducts2={relateProducts2}
                crossSellProducts={crossSellProducts}
                variantProducts={variantProducts}
                secondhandProducts={secondhandProducts}
                groupedProducts={groupedProducts}
                handleUpdateSelectedProducts={null}
                relatedArticles={relateArticles}
                handleUpdateSelectedArticles={null}
                handleRefreshVariantProducts={getVariantProducts}
              />
            </Col>
          )}
        </Row>
      </LayoutDetail>
      <CustomPopup
        isSaving={isSavingVariantProducts}
        handleSaveNoEnter={handleSaveSelectedProducts}
        title={selectProductSection.title}
        isOpen={!!selectProductSection.productGroupKey}
        onCloseModal={handleCloseSelectProductModal}
        buttonSaveText={
          selectProductSection.productGroupKey === ProductSection.variantProducts
            ? 'Bắt đầu tạo sản phẩm con'
            : 'Lưu'
        }
      >
        {selectProductSection.productGroupKey === ProductSection.variantProducts ? (
          <AddVariantProducts
            variantProducts={variantProducts.filter((item) => item.id !== productId)}
            superAttributes={superAttributes}
            superAttributesValues={superAttributesValues}
            onUpdateSuperAttributesValue={handleUpdateSuperAttributesValue}
            isCreateNewChildProducts={isEmpty(variantProducts)}
          />
        ) : (
          <SelectProductsSection
            onSelectProduct={handleAddSelectedProducts}
            selectedProducts={getSelectedProducts()}
            productId={productId}
            searchByType={searchByType()}
            isSecondhand={
              selectProductSection.productGroupKey === ProductSection.secondhandProducts
            }
            productCategories={productCategories}
          />
        )}
      </CustomPopup>
    </>
  );
};

export default ProductDetail;
