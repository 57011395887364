import { DeleteOutlined, FilterFilled, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button, Pagination, Table, Card, Select } from 'antd';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Models
import { defaultPagination } from 'constants/constants';
import { AlignType } from 'constants/enum';
import { useSizeGroups } from 'hooks/useSizeGroups';
import {
  useSizeItems,
  useDelSizeItem,
  defaultFilterParams,
  FormFilterProduct,
  useModalSizeItem,
  useSortSizeItem,
} from 'hooks/useSizeItems';
import { IPagination } from 'interfaces/pagination.interface';
import { ISizeGroup, ISize, ISizeAttr } from 'interfaces/size.interface';

// Components
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import { SizeModel } from 'models/sizes.model';
import { SanitizeHtml } from 'ui/components/MaiNguyen/SanitizeHtml';
import FiltetSizeItems from './Filter/FilterSizeItems';
import FormSizeItems from './Form/FormSizeItems';
import GroupSizeMenu from '../components/Menu/Menu';

// styles
import '../SizeGroups/SizeGroups.scss';

export const newSizeItem = new SizeModel({});

const SystemSizeItems: React.FC = () => {
  const location = useLocation();

  const parsedSearch = useMemo(() => queryString.parse(location.search), [location]);

  // pagination
  const [paging, setPaging] = useState<IPagination>(() => {
    return {
      ...defaultPagination,
      current: parsedSearch?.page ? Number(parsedSearch?.page) : defaultPagination.current,
    };
  });
  // filter
  const [formFilter, setFormFilter] = useState<FormFilterProduct>(() => {
    return {
      title: (parsedSearch?.search as string) || defaultFilterParams.title,
      sizeGroupId: (parsedSearch?.sizeGroupId as string) || defaultFilterParams.sizeGroupId,
    };
  });
  const [showFilter, setShowFilter] = useState(false);
  const [isReloading, setIsReloading] = useState(false);

  // Hook: get list of sizeGroups
  const { loading: loadingSizeGroups, sizeGroups } = useSizeGroups(1, '', false, 'all');
  // Hook: get list of sizeItems
  const { sizeList, loading, pagination, getSizeItems } = useSizeItems(
    paging.current,
    formFilter,
    isReloading,
  );

  // Hook: Modal sizeItem
  const { sizeItem, openModal, showModal, closeModal } = useModalSizeItem();

  // Hook: delete sizeItem
  const { isDeleting, deleteSizeItem } = useDelSizeItem();

  // Hook: sort sizeItem
  const { isSorting, changePosition } = useSortSizeItem();

  // on change page
  const onChangePage = useCallback(
    (page: number) => {
      setPaging({ ...pagination, current: page });
    },
    [pagination],
  );

  // show/hide block filter
  const clickShowFilter = useCallback(() => {
    setShowFilter(!showFilter);
  }, [showFilter]);

  // Filter size group
  const doFilter = useCallback((val) => {
    setFormFilter(val);
    onChangePage(1);
  }, []);

  // complete change position
  useEffect(() => {
    if (isDeleting || isSorting) {
      setIsReloading(true);
    } else {
      setIsReloading(false);
    }
  }, [isDeleting, isSorting]);

  // config table
  const sizeItemsTable = [
    {
      title: 'STT',
      dataIndex: '',
      render: (_, __, index: number) => index + 1,
    },
    {
      title: 'Tên size',
      dataIndex: 'attributes',
      render: (attr: ISizeAttr, rowData: ISize) => {
        const { title } = attr;
        return (
          <div className="attr-col" onClick={() => showModal(rowData)}>
            {title}
          </div>
        );
      },
    },
    {
      title: 'Thuộc tính',
      dataIndex: 'attributes',
      render: (attr: ISizeAttr) => {
        const { data } = attr;
        return (
          data.length > 0 && (
            <div>
              {data.map((item) => {
                const { label, value, id } = item;
                return (
                  <div key={'size-attr-' + id}>
                    {label}: {value}
                  </div>
                );
              })}
            </div>
          )
        );
      },
    },
    {
      title: 'Nhóm size',
      dataIndex: 'attributes',
      render: (attr: ISizeAttr) => {
        const { sizeGroupId } = attr;
        const sizeGrp = sizeGroups.find((item) => item.id === sizeGroupId.toString());
        const { attributes } = sizeGrp || {};
        const { title } = attributes || {};

        return <SanitizeHtml rawHtml={title} />;
      },
    },
    {
      title: 'Vị trí',
      dataIndex: 'attributes',
      className: `${formFilter.sizeGroupId ? '' : 'd-none'}`, // show column position incase filter by sizeGroupId
      render: (attr: ISizeAttr, row: ISize) => {
        // siseItems length
        const sizesLength = [];
        const { total } = pagination || {};
        for (let i = 0; i < total; i++) {
          sizesLength.push(i);
        }

        return (
          <Select
            defaultValue={attr.position}
            onChange={changePosition(row)}
            showSearch
            allowClear
            optionFilterProp="value"
            listHeight={450}
          >
            {sizesLength.map((_, index) => (
              <Select.Option key={index + 1} value={index + 1}>
                {index + 1}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      align: AlignType.right,
      render: (attr: ISizeGroup) => {
        const { id } = attr;
        return <Button title="Xóa" icon={<DeleteOutlined />} onClick={() => deleteSizeItem(id)} />;
      },
    },
  ];

  return (
    <LayoutDetail
      isAddNew
      pageTitle="Size Items"
      breadcrumbs={[
        {
          title: 'Size Items',
          route: '/sizes',
        },
      ]}
      pageClassName="product-category-page bg-transparent"
    >
      <Row gutter={16}>
        <Col span={6}>
          {/* MENU */}
          <GroupSizeMenu />
        </Col>
        <Col span={18}>
          {/* FILTRR */}
          <Row gutter={24}>
            <Col span={12}>
              <Button
                className="filter-button mb-3"
                title="Filter"
                icon={<FilterFilled />}
                onClick={clickShowFilter}
              >
                Filter
              </Button>
            </Col>
            <Col span={12} className="text-right">
              <Button icon={<PlusOutlined />} onClick={() => showModal(newSizeItem)}>
                Tạo mới
              </Button>
            </Col>
          </Row>
          <Card hidden={!showFilter}>
            <FiltetSizeItems
              sizeGroups={sizeGroups}
              formFilter={formFilter}
              loading={loadingSizeGroups}
              onFilterChange={doFilter}
            />
          </Card>

          {pagination && (
            <div className="layout-list-pagination mb-3">
              <Pagination
                {...pagination}
                pageSizeOptions={[]}
                onChange={onChangePage}
                disabled={loading}
              />
            </div>
          )}
          <Table
            rowKey="id"
            columns={sizeItemsTable}
            dataSource={sizeList}
            pagination={false}
            loading={loading}
          />
          {pagination && (
            <div className="layout-list-pagination mt-3">
              <Pagination
                {...pagination}
                pageSizeOptions={[]}
                onChange={onChangePage}
                disabled={loading}
              />
            </div>
          )}
        </Col>
      </Row>

      {/* Form Add/Edit Size Items */}
      <FormSizeItems
        openModal={openModal}
        sizeGroups={sizeGroups}
        sizeItem={sizeItem}
        onCloseModal={closeModal}
        onRefreshList={getSizeItems}
      />
    </LayoutDetail>
  );
};

export default SystemSizeItems;
