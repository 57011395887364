import { IPagination } from 'interfaces/pagination.interface';
import { CouponRules, CouponStatus, OrderStatus, StoreIds } from './enum';

export const domainUrl = 'https://api.admin.mainguyen.vn/';
export const IS_DEV = false;
export const DEV_KEY = 'dev@42242344SAD';

export const RIDER_STORE_DEV_URL = 'http://store.mainguyenrider.com';
export const RIDER_STORE_PROD_URL = 'https://mainguyenrider.com';
export const MN_STORE_DEV_URL = 'http://store.dev.mainguyenrider.com';
export const MN_STORE_PROD_URL = 'https://mainguyen.vn';

export const ACESS_TOKEN_NAME = 'ACESS_TOKEN_NAME';
export const REFRESH_TOKEN_NAME = 'REFRESH_TOKEN_NAME';
export const TOKEN_TYPE = 'TOKEN_TYPE';

export const defaultPagination: IPagination = {
  current: 1,
  pageSize: 15,
  total: 1,
};

export const defaultDashboardPagination: IPagination = {
  current: 1,
  pageSize: 10,
  total: 1,
};

export const defaultJsonData = {
  metaDataHomePage: {
    title: '',
    description: '',
    keyword: '',
  },
  headerSearch: {
    keywords: ['apple pencil', 'iphone 12 giả rẻ', 'iphone12 dùng rồi', 'nón racing'],
    topEventText: 'SIÊU SALE 7.7 - GIẢM HƠN 50%',
    numberOfTopKeyword: 10,
  },
  categoriesIconMenu: [
    {
      urlPath: 'dien-thoai-c1',
      iconPath: 'https://dfm-public.sgp1.digitaloceanspaces.com/622/mn_cate_phone.png',
      title: 'Điện Thoại',
    },
    {
      urlPath: 'may-tinh-bang-c2',
      iconPath: 'https://dfm-public.sgp1.digitaloceanspaces.com/623/mn_cate_table.png',
      title: 'Máy Tính Bảng',
    },
  ],
  luxuryBlock: [
    {
      blockName: 'luxuryBlock1',
      data: {
        iconPath: 'http://store.mainguyenrider.com/static/images/icons/watch-icon.svg',
        title: 'ĐỒNG HỒ THÔNG MINH',
        subTitle: '+17 hãng smartwatch danh tiếng',
        leftItem: 'D135',
        rightItem: 'P139',
        urlPath: '/karaoke-gia-dinh-c131',
        brands: [],
      },
    },
    {
      blockName: 'luxuryBlock2',
      data: {
        iconPath: 'http://store.mainguyenrider.com/static/images/icons/watch-icon.svg',
        title: 'ÂM THANH CÁ NHÂN',
        subTitle: '+17 hãng âm thanh danh tiếng',
        leftItem: 'D135',
        rightItem: 'P139',
        urlPath: '/karaoke-gia-dinh-c131',
        brands: [],
      },
    },
  ],
  homeVideos: [
    {
      videoUrl: 'youtube url',
      title: 'text',
      isFeature: true,
    },
    {
      videoUrl: 'youtube url',
      title: 'text',
      isFeature: false,
    },
  ],
  productDetailSupportInfo: {
    title: 'Tư vấn / Mua hàng - Gọi Ngay',
    phoneNumners: [
      {
        phone: '38200888',
        displayText: '38.200.888',
        color: '#222222',
      },
      {
        phone: '0981200888',
        displayText: '0981.200.888',
        color: '#bd0000',
      },
    ],
    chatIcons: 'icon url',
    cardIcons: 'icon url',
    cardText: 'MIỄN PHÍ <b>CHARGE THẺ</b>',
    textContent: [
      'Hàng chính hãng, bảo hành toàn quốc',
      'Giao hàng ngay (Nội thành TP.HCM)',
      'Giao trong vòng 2 đến 3 ngày (Toàn quốc)',
      'Gọi lại cho Quý khách trong 5 phút',
      'Xem hàng tại nhà, hài lòng thanh toán',
    ],
  },
  trackingOrderDetail: {
    title: 'Hưỡng dẫn cách tra cứu tình trạng đơn hàng',
    redLabel: 'Cách kiểm tra thông tin bảo hành đối với sản phẩm mua tại Mai Nguyên:',
    guideLabel: 'Nhập mã đơn hàng: <b>Az6h9M</b> (mã số hệ thống đã gửi qua email của bạn)',
  },
  trackingOrderTermsDetail: {
    title: 'Quy định đổi trả trong vòng 15 ngày',
    warrantyTitle: '1. Điều kiện bảo hành:',
    warrantyContent: [
      'Máy còn trong thời gian bảo hành hiệu lực và các vấn đề hỏng hóc do lỗi nhà sản xuất.',
      'Trong thời gian bảo hành hiệu lực, máy chưa từng qua sửa chữa từ bên thứ ba (sửa chữa ngoài) hoặc người dùng chưa tự can thiệp (tự mở máy, can thiệp phần cứng)',
      'Trong thời gian bảo hành hiệu lực, máy điện thoại và phụ kiện do nokia sản xuất không bị tác động của môi trường (thấm nước, hóa chất ăn mòn, bị quá nhiệt do tác động bên ngoài)',
      'Trong thời gian bảo hành hiệu lực máy điện thoại và phụ kiện Nokia không bị rơi, rớt va đập mạnh dẫn tới hỏng hóc vật lý.',
      'Nokia Care không giải quyết các trường hợp trao đổi và mua bán máy',
    ],
  },
  trackingOrderMessageNotFound: {
    message: 'Xin lỗi, chúng tôi không tìm thấy đơn hàng có mã ',
    phoneLabel:
      'Số điện thoại phòng Kinh Doanh online: <a href="tel:0981200888">0981.200.888</a> - <a href="tel:2838200888">(028)38.200.888</a>',
  },
  metaDataTrackingOrder: {
    title: '',
    description: '',
    keyword: '',
  },
  metaDataWarranty: {
    title: '',
    description: '',
    keyword: '',
  },
  warrantyMenu: {
    menuList: ['Thời hạn bảo hành', 'Điều kiện bảo hành', 'Trung tâm bảo hành'],
  },
  warrantyGeneral: {
    title: 'Quy định chung về bảo hành chínhh hãng',
    redLabel: 'Cách kiểm tra thông tin bảo hành đối với sản phẩm mua tại Mai Nguyên:',
    guideLabels: [
      '<b>Đối với điện thoại:</b> Ở màn hình chủ điện thoại, bấm *#06#, số IMEI của điện thoại sẽ hiển thị.',
      '<b>Đối với sản phẩm khác:</b> Tìm số Serial được in trên thân sản phẩm hoặc trên phiếu bảo hành.',
      'Nhập số IMEI / Serial bạn vừa tìm được vào ô bên dưới, gõ Enter hoặc bấm vào nút "Kiểm tra".',
    ],
    notice:
      '<b><u>Lưu ý:</u></b> Chương trình chỉ áp dụng cho những điện thoại &amp; sản phẩm<b> được mua tại Mai Nguyên</b> kể từ ngày 10/01/2021.',
  },
  warrantyTerms: {
    title: 'Điều kiện bảo hành chínhh hãng',
    warrantyTitle: '1. Điều kiện bảo hành:',
    warrantyContent: [
      'Máy còn trong thời gian bảo hành hiệu lực và các vấn đề hỏng hóc do lỗi nhà sản xuất.',
      'Trong thời gian bảo hành hiệu lực, máy chưa từng qua sửa chữa từ bên thứ ba (sửa chữa ngoài) hoặc người dùng chưa tự can thiệp (tự mở máy, can thiệp phần cứng)',
      'Trong thời gian bảo hành hiệu lực, máy điện thoại và phụ kiện do nokia sản xuất không bị tác động của môi trường (thấm nước, hóa chất ăn mòn, bị quá nhiệt do tác động bên ngoài)',
      'Trong thời gian bảo hành hiệu lực máy điện thoại và phụ kiện Nokia không bị rơi, rớt va đập mạnh dẫn tới hỏng hóc vật lý.',
      'Nokia Care không giải quyết các trường hợp trao đổi và mua bán máy',
    ],
    warrantyTitle2: '2. Điều kiện bảo hành:',
    warrantyContent2: [
      'Máy còn trong thời gian bảo hành hiệu lực và các vấn đề hỏng hóc do lỗi nhà sản xuất.',
      'Trong thời gian bảo hành hiệu lực, máy chưa từng qua sửa chữa từ bên thứ ba (sửa chữa ngoài) hoặc người dùng chưa tự can thiệp (tự mở máy, can thiệp phần cứng)',
      'Trong thời gian bảo hành hiệu lực, máy điện thoại và phụ kiện do nokia sản xuất không bị tác động của môi trường (thấm nước, hóa chất ăn mòn, bị quá nhiệt do tác động bên ngoài)',
      'Trong thời gian bảo hành hiệu lực máy điện thoại và phụ kiện Nokia không bị rơi, rớt va đập mạnh dẫn tới hỏng hóc vật lý.',
      'Nokia Care không giải quyết các trường hợp trao đổi và mua bán máy',
    ],
    warrantyTitle3: '3. Điều kiện bảo hành:',
    warrantyContent3: [
      'Máy còn trong thời gian bảo hành hiệu lực và các vấn đề hỏng hóc do lỗi nhà sản xuất.',
      'Trong thời gian bảo hành hiệu lực, máy chưa từng qua sửa chữa từ bên thứ ba (sửa chữa ngoài) hoặc người dùng chưa tự can thiệp (tự mở máy, can thiệp phần cứng)',
      'Trong thời gian bảo hành hiệu lực, máy điện thoại và phụ kiện do nokia sản xuất không bị tác động của môi trường (thấm nước, hóa chất ăn mòn, bị quá nhiệt do tác động bên ngoài)',
      'Trong thời gian bảo hành hiệu lực máy điện thoại và phụ kiện Nokia không bị rơi, rớt va đập mạnh dẫn tới hỏng hóc vật lý.',
      'Nokia Care không giải quyết các trường hợp trao đổi và mua bán máy',
    ],
  },
  warrantyCenter: {
    title: 'Tra cứu trung tâm bảo hành chính hãng',
  },
  footer: {
    blocks: [
      {
        blockName: 'delivery',
        data: {
          items: [
            'Đổi mới trong vòng 15 ngày nếu sản phẩm bị lỗi do nhà sản xuất',
            'Thanh toán trực tiếp hoặc ATM, VISA, MASTER CARD',
            'Gọi ngay Phòng kinh doanh Online - ĐT: (028) 38.200.888 - 0981.200.888 (Viber, Zalo, WhatsApp, iMessage) để được tư vấn và giao hàng tận nơi trên toàn quốc.',
          ],
        },
      },
      {
        blockName: 'hotline',
        data: {
          displayNumber: '0981.200.888',
          phoneNumber: '0981200888',
        },
      },
      {
        blockName: 'orderChecking',
        data: {
          items: [
            {
              displayText: 'KIỂM TRA ĐƠN HÀNG',
              href: '/kiem-tra-don-hang',
            },
            {
              displayText: 'THÔNG TIN BẢO HÀNH',
              href: '/thong-tin-bao-hanh',
            },
          ],
        },
      },
      {
        blockName: 'socialNetwork',
        data: {
          items: [
            {
              iconClass: 'fab fa-facebook-f',
              href: 'https://www.facebook.com/mainguyenluxurymobile/',
            },
            {
              iconClass: 'fab fa-twitter',
              href: 'https://twitter.com/mainguyenluxury',
            },
            {
              iconClass: 'fab fa-youtube',
              href: 'https://www.youtube.com/mainguyenluxury/',
            },
          ],
        },
      },
      {
        blockName: 'footerMenu',
        data: {
          items: [
            {
              displayText: 'TRANG CHỦ',
              href: '/',
            },
            {
              displayText: 'SƠ ĐỒ WEB',
              href: '/',
            },
            {
              displayText: 'QUY TRÌNH SỬ DỤNG',
              href: '/',
            },
            {
              displayText: 'CHÍNH SÁCH BẢO HÀNH',
              href: '/',
            },
            {
              displayText: 'GIỚI THIỆU',
              href: '/',
            },
            {
              displayText: 'LIÊN HỆ',
              href: '/',
            },
            {
              displayText: 'HƯỚNG DẪN ĐẶT HÀNG',
              href: '/',
            },
            {
              displayText: 'TUYỂN DỤNG',
              href: '/',
            },
            {
              displayText: 'CHÍNH SÁCH BẢO MẬT',
              href: '/',
            },
          ],
        },
      },
      {
        blockName: 'copyright',
        data: '© 2022 - Bản quyền thuộc Công ty TNHH Công nghệ di động Mai Nguyên - MaiNguyen.vn<br> Giấy phép số 79/GP-ICP-STTTT do Sở Thông tin và Truyền thông cấp ngày cấp ngày 8/10/2012<br> Địa chỉ: 144 Võ Thị Sáu, P.8, Q.3, TP.HCM. Email: contact@mainguyen.vn. Chịu trách nhiệm nội dung: Đặng Thị Kim Hương',
      },
    ],
  },
  riderQuickConsultation: {
    title: '',
    subtitle: '',
    hotlineDisplay: '',
    hotlineNumber: '',
  },
  riderShowroom: {
    darkText: '',
    name: '',
    address: '',
    phone1: '',
    phone2: '',
    googleMapLink: '',
  },
  riderSocial: {
    facebook: '',
    instagram: '',
    twitter: '',
  },
};

export const defaultDataForRiderHomePresent = {
  metadataHomePage: {
    title: '',
    description: '',
    keyword: '',
  },
  riderHeaderLogo: {
    desktopLogo: {},
    mobileLogo: {},
  },
  headerSearch: {
    keywords: ['phụ kiện hành trình', 'phụ kiện cho xe', 'đồ bảo hộ', 'nón racing'],
    topEventText: 'SIÊU SALE 7.7 - GIẢM HƠN 50%',
    numberOfTopKeyword: 10,
  },
  warrantyInfo: {
    refundContent: '',
    returnAndExchangeContent: '',
    warrantyGuide: '',
  },
  categoryList: [
    {
      urlPath: 'do-bao-ho-c195',
      iconPath: 'https://dfm-public.sgp1.digitaloceanspaces.com/622/mn_cate_phone.png',
      title: 'Đồ bảo hộ',
    },
    {
      urlPath: 'non-bao-hiem-c194',
      iconPath: 'https://dfm-public.sgp1.digitaloceanspaces.com/623/mn_cate_table.png',
      title: 'Nón bảo hiểm',
    },
  ],
  featuredCategories: [
    {
      blockName: 'featuredCategory1',
      data: {
        iconPath: 'http://store.mainguyenrider.com/static/images/icons/watch-icon.svg',
        title: 'THIẾT BỊ CAMPING',
        subTitle: '+17 hãng THIẾT BỊ CAMPING danh tiếng',
        leftItem: 'D135',
        rightItem: 'P139',
        urlPath: '/karaoke-gia-dinh-c131',
        brands: [],
      },
    },
    {
      blockName: 'featuredCategory2',
      data: {
        iconPath: 'http://store.mainguyenrider.com/static/images/icons/watch-icon.svg',
        title: 'DỤNG CỤ TREKKING',
        subTitle: '+17 hãng THIẾT BỊ CAMPING danh tiếng',
        leftItem: 'D135',
        rightItem: 'P139',
        urlPath: '/karaoke-gia-dinh-c131',
        brands: [],
      },
    },
  ],
  riderQuickConsultation: {
    title: '',
    subtitle: '',
    hotlineDisplay: '',
    hotlineNumber: '',
  },
  riderShowroom: {
    darkText: '',
    name: '',
    address: '',
    phone1: '',
    phone2: '',
    googleMapLink: '',
  },
  riderSocial: {
    facebook: '',
    instagram: '',
    twitter: '',
  },
};

export const installmentPrice = 3000000;

export const STORE_IDS = [
  {
    value: StoreIds.mainguyen,
    label: 'Mai Nguyen',
  },
  {
    value: StoreIds.rider,
    label: 'Mai Nguyen Rider',
  },
];

export const COUPON_RULES = [
  {
    value: CouponRules.auto,
    label: 'Mã khác nhau',
  },
  {
    value: CouponRules.one,
    label: 'Mã giống nhau',
  },
];

export const COUPON_STATUS_LIST = [
  {
    value: CouponStatus.active,
    label: 'Hoạt động',
  },
  {
    value: CouponStatus.close,
    label: 'Đã đóng',
  },
  {
    value: CouponStatus.draft,
    label: 'Nháp',
  },
  {
    value: CouponStatus.expired,
    label: 'Hết hạn',
  },
];

export const ORDER_STATUS_LIST = [
  {
    value: OrderStatus.order,
    label: 'Đã đặt hàng',
  },
  {
    value: OrderStatus.processing,
    label: 'Đang xử lý',
  },
  {
    value: OrderStatus.delivered,
    label: 'Đang giao hàng',
  },
  {
    value: OrderStatus.done,
    label: 'Đã hoàn thành',
  },
  {
    value: OrderStatus.cancel,
    label: 'Đã huỷ',
  },
  {
    value: OrderStatus.deposit,
    label: 'Đã đặt cọc',
  },
  {
    value: OrderStatus.preorder,
    label: 'Đã đặt trước',
  },
];

export enum PaymentMethod {
  COD = 'COD',
  TRANSFER = 'TRANSFER',
  DIRECT = 'DIRECT',
  INSTALLMENT = 'INSTALLMENT',
  CREDITCARD = 'CREDITCARD',
  APPLEPAY = 'APPLEPAY',
}

export const PAYMENT_METHOD_LIST = [
  {
    value: PaymentMethod.COD,
    label: 'COD',
  },
  {
    value: PaymentMethod.TRANSFER,
    label: 'Chuyển khoản',
  },
  {
    value: PaymentMethod.DIRECT,
    label: 'Trực tiếp',
  },
  {
    value: PaymentMethod.INSTALLMENT,
    label: 'Mua trả góp',
  },
  {
    value: PaymentMethod.CREDITCARD,
    label: 'Charge thẻ',
  },
  {
    value: PaymentMethod.APPLEPAY,
    label: 'Apple Pay',
  },
];

export const SpentList = [
  {
    label: 'Trên 5tr',
    value: '5000000',
  },
  {
    label: 'Trên 10tr',
    value: '10000000',
  },
  {
    label: 'Trên 20tr',
    value: '20000000',
  },
  {
    label: 'Trên 40tr',
    value: '40000000',
  },
  {
    label: 'Trên 60tr',
    value: '60000000',
  },
  {
    label: 'Trên 80tr',
    value: '80000000',
  },
  {
    label: 'Trên 100tr',
    value: '100000000',
  },
];
