import { IMe } from 'interfaces/me.interface';
import { FETCH_USER, FETCH_USER_SUCCESSFULL, FETCH_USER_FAILED, ActionUserType } from './types';

type InitStateType = {
  user: IMe;
  loading: boolean;
};

const initialState: InitStateType = {
  user: null,
  loading: false,
};

export const userReducer = (state = initialState, action: ActionUserType) => {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, loading: true };
    case FETCH_USER_SUCCESSFULL:
      return { user: action.payload, loading: false };
    case FETCH_USER_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
