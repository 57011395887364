import { Form } from 'antd';

import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import CommentList from './Components/List';

import './Comments.scss';

const Comments = () => {
  const [form] = Form.useForm();

  return (
    <LayoutDetail
      pageTitle="Comments"
      breadcrumbs={[
        {
          title: 'Comments',
          route: '/comments',
        },
      ]}
      pageClassName="comments-page"
      isAddNew={true}
      formHook={form}
      handleValuesChange={null}
      handleSave={null}
      handleCancel={null}
      handleClickAddButton={null}
      handleDelete={null}
      wrapperCard={false}
    >
      <CommentList />
    </LayoutDetail>
  );
};

export default Comments;
