import { IAuth } from 'interfaces/auth.interface';

export const START_LOGIN = 'START_LOGIN';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export interface StartLogin {
  type: typeof START_LOGIN;
}

export interface LoginSuccessful {
  type: typeof LOGIN_SUCCESSFUL;
  payload: IAuth;
}

export interface LoginFailed {
  type: typeof LOGIN_FAILED;
  payload: string;
}

export type AuthActionTypes = StartLogin | LoginSuccessful | LoginFailed;
