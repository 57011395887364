import { IOsAttribute } from 'interfaces/os.interface';

export class OsModel implements IOsAttribute {
  name?: string;

  position?: number;

  active?: boolean;

  parentId?: number | null;

  constructor({ name, position, active, parentId }: IOsAttribute) {
    this.name = name || '';
    this.position = position || 0;
    this.active = active !== undefined && active !== null ? active : true;
    this.parentId = parentId || null;
  }
}
