import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography, message } from 'antd';
import { useFetchProductCategories } from 'hooks/useFetchProductCategories';
import { IPrdCategory } from 'interfaces/product-category.interface';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import React, { useCallback, useEffect, useState } from 'react';

import './ProductCategory.scss';
import { useDebounce } from 'use-debounce';
import { filteredTreeData, showErrorMsg } from 'utils/utils';
import { CategoryList } from './components/CategoryList';
import { CreateForm } from './components/CreateForm';
import { EditForm, ImageTypes } from './components/EditForm';
import { Filter } from './components/Filter';
import { useFetchAttributeGroup, useProductCategory } from './hooks';
import { CreateProductCategory } from './types';

const ProductCategory: React.FC = () => {
  const {
    isFetching: isFetchingProductCategories,
    data: productCategories,
    refetch: refetchProductCategories,
  } = useFetchProductCategories();
  const { data: attributeGroup } = useFetchAttributeGroup();
  const { isCreating, isUpdating, onCreate, onRemove, onUpdate, onUpdateCategoryPosition } =
    useProductCategory();

  /**
   * State management
   */
  const [selectedCategory, setSelectedCategory] = useState<{ id: string; attributes: any }>(null);
  const [filterCategoryByName, setFilterCategoryByName] = useState<string>('');
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [filterCategoryByNameDebounce] = useDebounce(filterCategoryByName, 1000);

  useEffect(() => {
    setIsFiltering(false);
  }, [filterCategoryByNameDebounce]);

  /**
   * Updates the selected category with the provided data.
   */
  const onSelectCategory = useCallback((data: any) => {
    setSelectedCategory({
      id: data.id,
      attributes: { ...data.attributes, parentId: `${data.attributes.parentId}` },
    });
  }, []);

  /**
   * Creates a new product category and refreshes the list of product categories.
   */
  const _onCreate = useCallback(
    async (data: CreateProductCategory) => {
      try {
        const res = await onCreate({ data });
        onSelectCategory({ id: res.id, attributes: res.attributes });
        message.success('Tạo nhóm sản phẩm thành công!');
        await refetchProductCategories();
      } catch (error) {
        showErrorMsg(error);
      }
    },
    [onSelectCategory],
  );

  /**
   * Updates a product category and refreshes the list of product categories.
   */
  const _onUpdate = useCallback(
    async (category: any, images: ImageTypes) => {
      const newCategory = await onUpdate(category, images);
      setSelectedCategory({ id: newCategory?.id, attributes: newCategory?.attributes });
      message.success('Cập nhật nhóm sản phẩm thành công!');
      await refetchProductCategories();
    },
    [onUpdate],
  );

  /**
   * Removes a product category and refreshes the list of product categories.
   */
  const _onRemove = useCallback((category: { id: string; name: string }) => {
    onRemove(category, async () => {
      setSelectedCategory(null);
      message.success('Xoá nhóm sản phẩm thành công!');
      await refetchProductCategories();
    });
  }, []);

  /**
   * Update the position of a product category in the tree view
   */
  const _onUpdateCategoryPosition = useCallback(
    async (parentId: number, dragNode: IPrdCategory) => {
      if (parentId !== dragNode.attributes.parentId) {
        await onUpdateCategoryPosition(parentId, dragNode);
        setSelectedCategory(null);
        message.success('Cập nhật vị trí nhóm sản phẩm thành công!');
        await refetchProductCategories();
      }
    },
    [],
  );

  return (
    <LayoutDetail
      pageTitle="Nhóm sản phẩm"
      breadcrumbs={[
        {
          title: 'Nhóm sản phẩm',
          route: '/nhom-san-pham',
        },
      ]}
      wrapperCard={false}
      isAddNew
      formHook={null}
      handleSave={() => {}}
      handleValuesChange={null}
      handleClickAddButton={() => {}}
      pageClassName="product-category-page"
      saveLoading
    >
      <Button
        className="btn-close-promotion r-15"
        icon={<PlusOutlined />}
        onClick={() => setSelectedCategory(null)}
      >
        Tạo mới
      </Button>
      <Row gutter={16}>
        <Col span={8}>
          <Filter
            searchValue={filterCategoryByName}
            onSearchChange={(value) => {
              setFilterCategoryByName(value);
              setIsFiltering(true);
            }}
          />
          <CategoryList
            isLoading={isFetchingProductCategories || isFiltering}
            selectedIds={selectedCategory?.id ? [selectedCategory.id] : []}
            data={
              filterCategoryByNameDebounce
                ? filteredTreeData(productCategories, filterCategoryByNameDebounce)
                : productCategories
            }
            onSelect={onSelectCategory}
            onCategoryPositionChange={_onUpdateCategoryPosition}
          />
        </Col>
        <Col span={16}>
          <Card
            title={
              <Typography.Title level={3}>
                {selectedCategory ? 'Chi tiết nhóm' : 'Tạo mới nhóm'}
              </Typography.Title>
            }
          >
            {selectedCategory ? (
              <EditForm
                isUpdating={isUpdating}
                selectedCategory={selectedCategory}
                productCategories={productCategories}
                attributeGroup={attributeGroup}
                onFinish={_onUpdate}
                onRemove={_onRemove}
              />
            ) : (
              <CreateForm
                isCreating={isCreating}
                productCategories={productCategories}
                attributeGroup={attributeGroup}
                onFinish={_onCreate}
              />
            )}
          </Card>
        </Col>
      </Row>
    </LayoutDetail>
  );
};

export default ProductCategory;
