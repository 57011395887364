import React from 'react';

import classNames from 'utils/classNames';

import './Footer.scss';

interface FooterProps {
  loaded?: boolean;
  boxed?: boolean;
}

const Footer: React.FunctionComponent<FooterProps> = (props) => {
  const footerClasses = classNames({
    footer: true,
    loaded: props.loaded,
    boxed: props.boxed,
  });

  return (
    <div className={footerClasses}>
      <div className="footer-wrap">
        <div className="row align-items-center">
          <div className="col-12 info">&#169; 2021 v1.0.1. MaiNguyen.vn - Wizardev Team</div>
        </div>

        <div className="footer-skeleton">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 d-md-block">
              <ul className="p-0 page-breadcrumbs">
                <li className="item bg animated-bg" />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
