/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { connect } from 'react-redux';
import { IAppSettings } from 'interfaces/settings';
import { resetSettings } from 'redux/settings/actions';
import { AppState } from 'redux/store';

import SettingsModal from './SettingsModal/SettingsModal';

interface SettingsProps {
  opened: boolean;
  settings?: IAppSettings;
  onClose: () => void;
  onSettingsReset?: () => void;
}

const Settings: React.FC<SettingsProps> = (props) => {
  const { onSettingsReset, onClose, settings, opened } = props;

  return (
    <SettingsModal
      onSettingsReset={onSettingsReset}
      settings={settings}
      visible={opened}
      onClose={() => onClose()}
    />
  );
};

const mapStateToProps = (state: AppState, ownProps) => {
  return {
    opened: ownProps.opened,
    onClose: ownProps.onClose,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSettingsReset: () => dispatch(resetSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
