/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card, Checkbox, Col, Divider, Form, Input, Row, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { ICouponAttributes } from 'interfaces/coupon.interface';
import { IImage } from 'interfaces/image.interface';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { NavLink, useLocation } from 'react-router-dom';
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { Loading } from 'ui/components/MaiNguyen/Loading';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import ThemeButton from 'ui/components/ThemeButton/ThemeButton';
import { uploadFile, validateNoVietnamese, validateNoWhiteSpace } from 'utils/utils';
import ExtraCoupons from './Coupons';
import { LevelItem } from './Menu';
import { useCreateLevel, useFetchLevel, useUpdateLevel } from '../hook';

interface FormProps {
  menuList?: LevelItem[];
  onRefresh?: () => void;
}

const RankForm: React.FC<FormProps> = ({ menuList, onRefresh }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [errorForm, setErrorForm] = useState({
    uploadFile: false,
    coupons: false,
  });

  const [stdLevelId, setStdLevelId] = useState<number>(null);
  const [stdRankId, setStdRankId] = useState<number>(null);
  // upload images
  const [previewImage, setPreviewImage] = useState<IImage>(null);
  const [imageFile, setImageFile] = useState(null);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [isUploadChange, setIsUploadChange] = useState(false);
  const [coupons, setCoupons] = useState<ICouponAttributes[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [desc, setDesc] = useState<string>('');

  const { isFetching, data, refetch: fetchLevelDetail } = useFetchLevel(stdRankId);
  const { isUpdating, onUpdate } = useUpdateLevel();
  const { isCreating, onCreate } = useCreateLevel();

  const [form] = Form.useForm();

  // TODO: get level title
  const levelTitle = useMemo(() => {
    return menuList?.find((item) => item.id === stdLevelId)?.name;
  }, [stdLevelId, menuList]);

  // TODO: reset form
  const resetForm = () => {
    form.resetFields();
    setCoupons([]);
    setDesc('');
    deleteImage();
  };

  // TODO: get url params
  useEffect(() => {
    if (!isEmpty(params)) {
      setStdLevelId(Number(params?.levelId));
      setStdRankId(Number(params?.rankId));
    }

    return () => {
      setStdLevelId(null);
      setStdRankId(null);
    };
  }, [params]);

  // TODO: reset form when change level
  useEffect(() => {
    if (stdLevelId) {
      // reset form
      resetForm();
    }
  }, [stdLevelId]);

  // TODO: fetch level detail
  useEffect(() => {
    if (stdRankId) {
      fetchLevelDetail();
    }
  }, [stdRankId]);

  // TODO: init form
  useEffect(() => {
    if (!isEmpty(data)) {
      const { attributes } = data;
      const {
        name,
        slug,
        min,
        max,
        birthday,
        coupons: _coupons,
        description,
        image,
      } = attributes || {};

      form.setFieldsValue({
        name,
        slug,
        min,
        max,
        birthday,
        description,
      });

      setDesc(description);
      setCoupons(_coupons);
      setPreviewImage(image);
      setIsUploadFile(image ? true : false);
    }

    return () => {
      resetForm();
    };
  }, [data]);

  // TODO: handle upload image
  const handleUploadImage = async (lvId: string) => {
    if (isUploadFile && isUploadChange) {
      setIsUploading(true);
      await uploadFile(imageFile, 'customerlevel', lvId, '', '0');
      setIsUploading(false);
    }

    // reload menu levels
    onRefresh();
    message.success('Lưu hạng thành viên thành công', 3);
  };

  // TODO: upload image change
  const uploadImageChange = useCallback((file: RcFile) => {
    setImageFile(file);
    setIsUploadFile(true);
    setIsUploadChange(true);
    // reset validate upload file
    const errorFormState = {
      ...errorForm,
      uploadFile: false,
    };
    setErrorForm(errorFormState);
  }, []);

  // Delete image
  const deleteImage = () => {
    setImageFile(null);
    setPreviewImage(null);
    setIsUploadFile(false);
    setIsUploadChange(true);
  };

  // TODO: handle change coupons
  const onChangeCoupons = (_coupons: ICouponAttributes[]) => {
    setCoupons(_coupons);

    // reset validate coupons
    if (_coupons.filter((o) => o.title === '').length === 0) {
      // reset validate content
      const errorFormState = {
        ...errorForm,
        coupons: false,
      };
      setErrorForm(errorFormState);
    }
  };

  // TODO: submit save
  const onSave = useCallback(
    (values): Promise<void> => {
      // validate upload file
      if (!isUploadFile) {
        const errorFormState = {
          ...errorForm,
          uploadFile: true,
        };
        setErrorForm(errorFormState);
        return;
      }

      // validate coupons
      if (isEmpty(coupons) || coupons?.filter((o) => o.title === '').length !== 0) {
        const errorFormState = {
          ...errorForm,
          coupons: true,
        };
        setErrorForm(errorFormState);
        return;
      }

      // convert string to number
      if (typeof values.min === 'string') {
        values.min = values.min.replaceAll(',', '');
      }

      if (typeof values.max === 'string') {
        values.max = values.max.replaceAll(',', '');
      }

      const payload = {
        ...values,
        birthday: values.birthday ? 1 : 0,
        level_id: stdLevelId,
        coupon_ids: coupons
          .filter((o) => o.title !== '') // remove empty item
          .map((o) => o.id)
          .reduce(function (a, b) {
            if (a.indexOf(b) < 0) a.push(b);
            return a;
          }, []), // remove duplicate item
      };

      // case update
      if (stdRankId) {
        submitUpdate(payload);
      } else {
        // case create
        submitCreate(payload);
      }
    },
    [isUploadFile, coupons],
  );

  // TODO: handle create update
  const submitCreate = async (payload) => {
    try {
      const { id } = await onCreate({ data: payload });

      // upload image
      handleUploadImage(id);
    } catch (error) {
      message.error('Có lỗi xảy ra, thử lại sau', 3);
    }
  };

  // TODO: handle save update
  const submitUpdate = async (payload) => {
    try {
      const { id } = await onUpdate({ id: stdRankId.toString(), data: payload });

      // upload image
      handleUploadImage(id);
    } catch (error) {
      message.error('Có lỗi xảy ra, thử lại sau', 3);
    }
  };

  return (
    <Card className="member-rank-form">
      <Loading isLoading={isFetching} />

      <Form form={form} layout="vertical" onFinish={onSave} className="frm-rank relative">
        <Row className="items-center mb-4">
          <Col span={12}>
            <h3 className="title mb-0">{levelTitle}</h3>
          </Col>
          <Col span={12} className="text-right">
            <ThemeButton
              htmlType="submit"
              disabled={!stdLevelId || isFetching}
              loading={isUpdating || isCreating || isUploading}
            >
              Cập nhật
            </ThemeButton>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Tên hạng"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập tên hạng',
                    },
                  ]}
                >
                  <Input
                    placeholder="Tên trình bày hạng này"
                    autoComplete="off"
                    disabled={!stdLevelId}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Code định danh"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject('Vui lòng nhập code định danh');
                        }
                        if (!validateNoWhiteSpace(value)) {
                          return Promise.reject('Vui lòng không nhập khoảng trắng');
                        }
                        if (validateNoVietnamese(value)) {
                          return Promise.reject('Vui lòng không nhập dấu');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="Vd: silver"
                    autoComplete="off"
                    className="text-lowercase"
                    disabled={!stdLevelId}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        {stdLevelId && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Mô tả chính sách"
                name="description"
                trigger="handleChangeMainContent"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập mô tả',
                  },
                ]}
              >
                <CustomEditor editorId={`rank-${stdRankId}-desc`} mainContent={desc} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Hình phôi Card"
                className={`${errorForm.uploadFile ? 'form-error' : ''} ${
                  !stdLevelId ? 'form-disabled' : ''
                }`}
              >
                <p className="desc mb-3">Dùng bản 2x png đã tối ưu</p>
                <UploadFile
                  uploadText="Click hoặc kéo thả hình để upload"
                  previewImage={previewImage}
                  imageSize="256x256"
                  maxWidth={256}
                  handleUploadImage={uploadImageChange}
                  onRemoveImageCallback={deleteImage}
                  handleDeleteImageSuccess={deleteImage}
                />
                {errorForm.uploadFile && <span className="msg mt-1">Vui lòng upload hình</span>}
              </Form.Item>
            </Col>

            <Divider />

            <Col span={24} className="mb-3">
              <p className="mb-2">
                <b>Điều kiện</b>
              </p>
              <ul>
                <li>
                  Điều chỉnh điều kiện trong khoảng giá trị khách hàng đã mua, tính trong 12 tháng
                  gần nhất
                </li>
                <li>VD: Cấp 1, thì không set điều kiện gì cả</li>
                <li>
                  Cấp 2, chúng ta set giá trị đã mua cần phải đạt và maximum 5tr - 20tr. Quá 20tr là
                  cấp 3
                </li>
                <li>Nhập số tiền: 1,000,000 = 1Tr đồng</li>
              </ul>
            </Col>

            <Col span={24}>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label="Min"
                    name="min"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập min',
                      },
                    ]}
                  >
                    <NumberFormat
                      className="ant-input"
                      thousandSeparator
                      placeholder="Min"
                      autoComplete="off"
                      disabled={!stdLevelId}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Max"
                    name="max"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập max',
                      },
                    ]}
                  >
                    <NumberFormat
                      className="ant-input"
                      thousandSeparator
                      placeholder="Max"
                      autoComplete="off"
                      disabled={!stdLevelId}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Divider />

            <Col span={24} className="mb-3">
              <p className="mb-2">
                <b>Chính sách ưu đãi</b>
              </p>
              <ul>
                <li>
                  Sinh nhật là chương trình COUPON đặc biệt được gắn với USER khi đến ngày sinh
                  nhật. Soạn giá trị chương trình{' '}
                  <NavLink
                    to={{
                      pathname: `ma-giam-gia`,
                    }}
                    target="_blank"
                  >
                    ở đây
                  </NavLink>
                </li>
                <li>
                  Sau đó là hàng loạt các COUPON khác tuỳ theo cấp độ mà ADMIN cứ gắn vào COUPON có
                  giá trị và ngành hàng
                </li>
              </ul>
            </Col>

            <Col span={12}>
              <Form.Item name="birthday" valuePropName="checked" className="birthday-check">
                <Checkbox disabled={!stdLevelId}>Tặng quà sinh nhật</Checkbox>
              </Form.Item>
            </Col>

            <Col span={24}>
              <ExtraCoupons
                list={coupons}
                onChange={onChangeCoupons}
                isError={errorForm.coupons}
                isDisabledBtn={!stdLevelId}
              />
            </Col>
          </Row>
        )}

        {stdLevelId && (
          <div className="text-right">
            <ThemeButton
              htmlType="submit"
              disabled={!stdLevelId || isFetching}
              loading={isUpdating || isCreating || isUploading}
            >
              Cập nhật
            </ThemeButton>
          </div>
        )}
      </Form>
    </Card>
  );
};

export default RankForm;
