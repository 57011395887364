import { Col, Collapse, Form, FormInstance, Input, Row, Select, Switch } from 'antd';
import { ICoupon } from 'interfaces/coupon.interface';
import moment from 'moment';
import { UploadImage } from 'pages/CollectionDetail/components/UploadImage';
import { useMemo } from 'react';
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { COUPON_STATUS_LIST } from 'constants/constants';
import { CouponStatus, DateFormat, DiscountUnit } from 'constants/enum';
import CategoryList from './CategoryList';

type ManageFormProps = {
  data?: ICoupon;
  isCouponExpired?: boolean;
  form?: FormInstance<any>;
  productCategories?: any;
  refetchCoupon?: () => Promise<void>;
};

const { Panel } = Collapse;

const ManageForm: React.FC<ManageFormProps> = ({
  data,
  isCouponExpired,
  form,
  productCategories,
  refetchCoupon,
}) => {
  const couponId = data?.id;
  const cateIdsOfCoupons = useMemo(() => {
    return data?.relationships?.categories?.data || [];
  }, [data]);

  const totalCouponAvailable = data?.attributes?.countActive;
  const createdAt = moment(data?.attributes?.createdAt, DateFormat.serverSideWithTime).format(
    DateFormat.clientSideWithTime,
  );
  const updatedAt = moment(data?.attributes?.updatedAt, DateFormat.serverSideWithTime).format(
    DateFormat.clientSideWithTime,
  );

  const statusLabel = COUPON_STATUS_LIST.find(
    (c) => c.value === (isCouponExpired ? CouponStatus.expired : data?.attributes?.status),
  )?.label;

  return (
    <div className="coupon-detail">
      <Collapse defaultActiveKey={['1', '2']}>
        <Panel header="Tracking Information" key="1">
          <div className="coupon-tracking-info">
            <div className="coupon-general-info">
              <div className="coupon-title-wrapper">
                <span>Coupon: </span>
                <span className="coupon-title">{data?.attributes?.title}</span>
              </div>

              <div className="coupon-date-range">
                <div>
                  <span>Tạo ngày: </span>
                  <span className="coupon-date-display">{createdAt}</span>
                </div>
                <div>
                  <span>Cập nhật ngày: </span>
                  <span className="coupon-date-display">{updatedAt}</span>
                </div>
              </div>
            </div>
            <div className="coupon-status">
              <span>Tình trạng: </span>
              <span
                className={`coupon-status--${
                  isCouponExpired ? 'expired' : data?.attributes?.status
                }`}
              >
                {statusLabel}
              </span>
            </div>
            <div className="coupon-statistic flex justify-between items-center flex-wrap gap-2">
              <div>
                <span>Đã phát </span>
                <span className="coupon-used">
                  <b>{totalCouponAvailable}</b> lượt
                </span>
              </div>
            </div>
          </div>
        </Panel>

        <Panel header="Coupon" key="2">
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Tên chương trình"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập tên chương trình',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Tiêu đề trình bày ngoài Store"
                name="displayTitle"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập tiêu đề trình bày',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                valuePropName="checked"
                label="Ghi đè promotion"
                name="hasOverridePromotion"
              >
                <Switch />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Prefix Code"
                name="prefixCode"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập Prefix Code',
                  },
                ]}
              >
                <Input disabled style={{ textTransform: 'uppercase' }} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Kiểu giảm giá" name="discountUnit">
                <Select
                  className="ant-select-lg"
                  options={[
                    { value: DiscountUnit.PERCENT, label: '%' },
                    { value: DiscountUnit.VND, label: 'VND' },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Giảm giá"
                name="discount"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập giảm giá',
                  },
                  {
                    validator: (_, value) => {
                      const discountUnit = form.getFieldValue('discountUnit');
                      if (discountUnit === DiscountUnit.PERCENT) {
                        if (value && Number(value) > 100) {
                          return Promise.reject('Phần trăm giảm giá phải nhỏ hơn hoặc bằng 100');
                        }
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Hạn sử dụng (theo tháng)"
                name="limitTime"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập hạn sử dụng',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Mô tả" name="desc" trigger="handleChangeMainContent">
                <CustomEditor mainContent={data?.attributes?.desc} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="image" valuePropName="initialImage" trigger="onChange" label="Logo">
                <UploadImage
                  uploadInfo={{
                    type: 'coupon',
                    typeId: couponId,
                  }}
                  uploadText="Click hoặc kéo thả hình để upload logo"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <CategoryList
                productCategories={productCategories}
                cateIdsOfCoupons={cateIdsOfCoupons?.map((p) => p.id)}
                couponId={couponId}
                fetchCategoriesOfCoupon={refetchCoupon}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};

export default ManageForm;
