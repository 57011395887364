/* eslint-disable import/order */
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { memo, useCallback, useEffect, useState } from 'react';
import { Rating } from 'components/Rating';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { StoreIds } from 'constants/enum';
import { checkMinMaxScore, checkTagHtmlInString } from 'utils/utils';
const { Option } = Select;

export interface ReviewFormValuesProps {
  score?: number;
  posterName?: string;
  content?: string;
  selectedImages?: RcFile[];
  storeId?: number;
}
interface Props {
  isLoading?: boolean;
  isResetForm?: string | null;
  onSubmit?: (values: ReviewFormValuesProps) => void;
}

const initFormValues = {
  score: 0,
  posterName: '',
  content: '',
  selectedImages: [],
  storeId: 1,
};

export const ReviewSeedingForm: React.FC<Props> = memo(({ isLoading, isResetForm, onSubmit }) => {
  const [form] = Form.useForm();
  const [previewImages, setPreviewImages] = useState<{ id: string; url: string }[]>([]);
  const [selectedImages, setSelectedImages] = useState<RcFile[]>([]);

  useEffect(() => {
    // reset form
    if (isResetForm) {
      form.resetFields();
      form.setFieldsValue(initFormValues);
      setPreviewImages([]);
      setSelectedImages([]);
    }
  }, [isResetForm]);

  useEffect(() => {
    form.setFieldsValue(initFormValues);
  }, [form, initFormValues]);

  /**
   * Submit form
   */
  const submitForm = useCallback(async () => {
    try {
      const values = await form.validateFields();
      onSubmit({ ...values });
    } catch (errorInfo) {
      // eslint-disable-next-line no-console
      console.log('Failed:', errorInfo);
    }
  }, [form, selectedImages]);

  /**
   * Handle upload multiple images
   */
  const onUploadImages = useCallback(
    async (file: RcFile) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setPreviewImages((prev) => [
          {
            id: file.uid,
            url: fileReader.result as string,
          },
          ...prev,
        ]);
      };

      setSelectedImages((prev) => [file, ...prev]);
    },
    [setPreviewImages, setSelectedImages],
  );

  /**
   * Handle delete image
   */
  const onDeleteImage = useCallback(
    (id: string) => {
      setPreviewImages((prev) => prev?.filter((s) => s.id !== id));
      setSelectedImages((prev) => prev?.filter((s) => s.uid !== id));
    },
    [setPreviewImages, setSelectedImages],
  );

  useEffect(() => {
    form.setFields([
      {
        name: 'selectedImages',
        value: selectedImages,
        errors: null,
      },
    ]);
  }, [selectedImages, form]);

  return (
    <div className="review-form mb-4">
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="score" label="Chọn số sao" rules={[{ validator: checkMinMaxScore }]}>
              <Rating />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="posterName"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền tên người dùng FAKE',
                },
                { validator: checkTagHtmlInString },
              ]}
            >
              <Input autoComplete="off" placeholder="Tên người dùng FAKE" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="storeId"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn cửa hàng',
                },
              ]}
            >
              <Select
                className="ant-select-lg"
                defaultValue={StoreIds.mainguyen}
                placeholder="Vui lòng chọn cửa hàng"
              >
                <Option value={StoreIds.mainguyen}>Mai Nguyen</Option>
                <Option value={StoreIds.rider}>Mai Nguyen Rider</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="content"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền nội dung',
                },
                { validator: checkTagHtmlInString },
              ]}
            >
              <Input.TextArea
                rows={6}
                placeholder="Viết nội dung khen sản phẩm, sao cho thực tế và đánh vào trọng tâm nhu cầu khách, cũng là giải đáp bận tâm trước khi mua của khách hàng..."
              />
            </Form.Item>
          </Col>
          <Col span={24} className="mb-3">
            {!!previewImages?.length && (
              <div className="review-img-lst mb-3">
                {previewImages.map((image) => (
                  <div key={`prod-review-${image.id}`} className="img-item">
                    <span
                      className="del-img icofont-close"
                      onClick={() => onDeleteImage(image.id)}
                    />
                    <img src={image.url} alt={image.url} />
                  </div>
                ))}
              </div>
            )}
            <Form.Item
              name="selectedImages"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng thêm hình review',
                },
              ]}
            >
              <UploadFile
                multiple
                uploadText="Chọn hoặc kéo 1 hay nhiều hình ảnh để upload"
                fileAccept=".jpg, .png, .jpeg"
                handleUploadMultipleImages={onUploadImages}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="mb-3">
            Hình ảnh chụp thật, đúng như khách hàng chụp không cần đẹp chỉ cần thật tế. Chỉ cần 3
            tấm hình.
          </Col>
          <Col
            span={24}
            className="align-items-center justify-content-end"
            style={{ display: 'flex' }}
          >
            <Form.Item>
              <Button onClick={submitForm} className="secondary-button" loading={isLoading}>
                Đăng Ngay
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});
