import { ICommentAttribute } from 'interfaces/comment.interface';
import { CommentAction, SET_ALL_COMMENTS, SET_RELOAD_ALL_COMMENTS } from './types';

type InitStateType = {
  comments: ICommentAttribute[];
  reloadAllComments: boolean;
  newCommentLength: number;
};

const initState: InitStateType = {
  comments: [],
  reloadAllComments: true,
  newCommentLength: 0,
};

export const commentReducer = (state: InitStateType = initState, action: CommentAction) => {
  switch (action.type) {
    case SET_ALL_COMMENTS:
      let clonedComments = [...action.payload.map((item) => ({ id: item.id, ...item.attributes }))];
      action.payload.forEach((item) => {
        clonedComments = clonedComments.concat(item.attributes.replies as any);
      });
      return {
        ...state,
        comments: clonedComments.filter((item) => item.active === 0),
        newCommentLength: clonedComments.filter((item) => item.active === 0).length,
        reloadAllComments: false,
      };
    case SET_RELOAD_ALL_COMMENTS: {
      return {
        ...state,
        reloadAllComments: true,
      };
    }
    default:
      return state;
  }
};
