import { Col, Row } from 'antd';
import LayoutDetail from 'layouts/LayoutDetail/LayoutDetail';
import CommentList from 'pages/CMS/Comments/Components/List';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setIsEditing } from 'redux/uiAction/actions';
import ButtonBack from '../components/ButtonBack';
import MemberDetailsMenu from '../components/Menu';
import { useFetchMember } from '../hooks';
import '../index.styles.scss';

const MemberComments: React.FC = () => {
  const dispatch = useDispatch();
  const { id: memberId } = useParams<{ id: string }>();
  const { data: memberData } = useFetchMember(memberId);

  const memberAttributes = useMemo(() => {
    return memberData?.attributes;
  }, [memberData]);

  useEffect(() => {
    memberData?.id && dispatch(setIsEditing(false));
  }, [memberData?.id]);

  return (
    <LayoutDetail
      pageTitle={`Member #${memberId || ''}`}
      isAddNew={false}
      pageClassName="member-details member-comments comments-page"
      breadcrumbs={[
        {
          title: 'Danh sách members',
          route: '/danh-sach-members',
        },
        {
          title: 'Comments',
          route: `/danh-sach-members/${memberId}/comments`,
        },
      ]}
    >
      {/* Menu */}
      <MemberDetailsMenu
        totalOrder={memberAttributes?.totalOrder}
        totalComment={memberAttributes?.totalComment}
        totalReview={memberAttributes?.totalReview}
        totalWishlist={memberAttributes?.totalWishlist}
      />

      {/* List */}
      <div className="member-content">
        <div className="member-review-info">
          <Row>
            <Col span={24}>
              <Row className="justify-content-end mb-3">
                <ButtonBack />
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <CommentList />
    </LayoutDetail>
  );
};

export default MemberComments;
